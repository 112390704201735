import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {flexDirection: 'row',paddingBottom: 50,paddingTop: 50, paddingLeft:20, paddingRight:20},
  titolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1,marginTop: 8, fontSize: 12},
  titoloPiccolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1, fontSize: 10},
  titoloPiccoloTop10: {fontFamily: 'Helvetica-Bold',marginLeft: 1, fontSize: 10,marginTop: 10,},
  image: {height: 150,width: 200},
  logo: {height: 60,width: 80,objectFit: 'contain'},
  fotoEsercizio: {
  width: 120,
  height: 120,
  objectFit: 'contain',},
  
  nomesocieta: {fontFamily: 'Helvetica-Bold',marginLeft: 1,marginRight: 8,marginBottom: 9, fontSize: 14},
  datisocieta: {fontFamily: 'Helvetica',marginLeft: 1,marginRight: 8,marginBottom: 7, fontSize: 10},
  viewer: {height: '100vh',width: '150vh'},

  contenitoreTotali: {width: 100/1+"%", marginTop:20, padding:10, backgroundColor:"#ACDDFF"},

  testoNormale: {fontFamily: 'Helvetica', fontSize: 8},
  testoNormale10: {fontFamily: 'Helvetica', fontSize: 10},
  testoNormaleInclinato: {fontFamily: 'Helvetica-Oblique', fontSize: 8},
  testoGrande: {fontFamily: 'Helvetica', fontSize: 12},
  testoCentrato: {textAlign:'center', fontSize: 10},
  testoGrassetto: {fontFamily: 'Helvetica-Bold',marginLeft: 2, fontSize: 10},
  testoGrassettoNoMargin: {fontFamily: 'Helvetica-Bold', fontSize: 10},
  testoGrassettoCentrato: {fontFamily: 'Helvetica-Bold',textAlign:'center', fontSize: 10},
  testoMargine2Sx: {marginLeft: 2, fontSize: 10},
  testoMargine2SxGrassetto: {fontFamily: 'Helvetica-Bold',marginLeft: 2, fontSize: 10},
  testoTotaliGrassetto: {fontFamily: 'Helvetica-Bold', textAlign: 'center', fontSize: 11, paddingBottom:5, color:"#000000"},
  testoTotali: {fontFamily: 'Helvetica', textAlign: 'center', fontSize: 11, padding:1, color:"#000000"},
  
  table: {display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0,marginTop: 10, }, 
  tableNoBordo: {display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0,marginTop: 10, }, 
  tableIntestazione: { display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0,margin: 10,}, 
  tableRowGrigia: { margin: "auto", flexDirection: "row",height: "auto",backgroundColor : "#D0D0D0",}, 
  tableRow: { margin: "auto", flexDirection: "row",height: "auto",}, 
  tableRowIntestazione: { margin: "auto", flexDirection: "row",height: 60,}, 
  tableRowIntestazione70: { margin: "auto", flexDirection: "row",height: 70,}, 
  tableRowBlu: { margin: "auto", flexDirection: "row",backgroundColor : "#ACDDFF",height: "auto",}, 
  tableRowGialla: { margin: "auto", flexDirection: "row",backgroundColor : "#FFA500",height: "auto",},
  tableRowVerdeMenta: { margin: "auto", flexDirection: "row",backgroundColor : "#98ff98",height: "auto",}, //#FFA500
  tableRowGrigiaChiara: { margin: "auto", flexDirection: "row",backgroundColor : "rgba(192,192,192,0.5)",height: "auto",marginTop: 10}, 
  tableColIntestazioneSx: { width: 100/3+"%", borderLeftWidth: 0, borderTopWidth: 0},
  tableColIntestazioneDx: { width: 2*100/3+"%", borderLeftWidth: 0, borderTopWidth: 0 },
  tableCol: { width: 100/4+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol1: { width: 100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol2: { width: 100/2+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol3: { width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol4: { width: 100/4+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol5: { width: 100/5+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol6: { width: 100/6+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol7: { width: 100/7+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol8: { width: 100/8+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol9: { width: 100/9+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol10: { width: 100/10+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol11: { width: 100/11+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol12: { width: 100/12+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol13: { width: 100/13+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol14: { width: 100/14+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol15: { width: 100/15+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  
  tableCol1Di100: { width: 100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol2Di100: { width: 2*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol3Di100: { width: 3*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol4Di100: { width: 4*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol5Di100: { width: 5*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol6Di100: { width: 6*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol7Di100: { width: 7*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol8Di100: { width: 8*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol9Di100: { width: 9*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol10Di100: { width: 10*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
 
  tableCol11Di100: { width: 11*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol12Di100: { width: 12*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol13Di100: { width: 13*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol14Di100: { width: 14*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol15Di100: { width: 15*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol16Di100: { width: 16*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol17Di100: { width: 17*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol18Di100: { width: 18*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol19Di100: { width: 19*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol20Di100: { width: 20*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,},

  tableCol21Di100: { width: 21*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol22Di100: { width: 22*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol23Di100: { width: 23*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol24Di100: { width: 24*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol25Di100: { width: 25*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol26Di100: { width: 26*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol27Di100: { width: 27*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol28Di100: { width: 28*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol29Di100: { width: 29*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol30Di100: { width: 30*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol31Di100: { width: 31*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol32Di100: { width: 32*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol33Di100: { width: 33*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol34Di100: { width: 34*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol35Di100: { width: 35*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol36Di100: { width: 36*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol37Di100: { width: 37*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol38Di100: { width: 38*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol39Di100: { width: 39*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol40Di100: { width: 40*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol41Di100: { width: 41*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol42Di100: { width: 42*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol43Di100: { width: 43*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol44Di100: { width: 44*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol45Di100: { width: 45*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol46Di100: { width: 46*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol47Di100: { width: 47*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol48Di100: { width: 48*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol49Di100: { width: 49*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol50Di100: { width: 50*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol51Di100: { width: 51*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol52Di100: { width: 52*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol53Di100: { width: 53*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol54Di100: { width: 54*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol55Di100: { width: 55*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol56Di100: { width: 56*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol57Di100: { width: 57*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol58Di100: { width: 58*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol59Di100: { width: 59*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol60Di100: { width: 60*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol61Di100: { width: 61*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol62Di100: { width: 62*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol63Di100: { width: 63*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol64Di100: { width: 64*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol65Di100: { width: 65*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol66Di100: { width: 66*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol67Di100: { width: 67*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol68Di100: { width: 68*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol69Di100: { width: 69*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol70Di100: { width: 70*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol71Di100: { width: 71*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol72Di100: { width: 72*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol73Di100: { width: 73*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol74Di100: { width: 74*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol75Di100: { width: 75*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol76Di100: { width: 76*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol77Di100: { width: 77*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol78Di100: { width: 78*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol79Di100: { width: 79*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol80Di100: { width: 80*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol81Di100: { width: 81*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol82Di100: { width: 82*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol83Di100: { width: 83*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol84Di100: { width: 84*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol85Di100: { width: 85*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol86Di100: { width: 86*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol87Di100: { width: 87*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol88Di100: { width: 88*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol89Di100: { width: 89*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol90Di100: { width: 90*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol91Di100: { width: 91*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol92Di100: { width: 92*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol93Di100: { width: 93*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol94Di100: { width: 94*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol95Di100: { width: 95*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol96Di100: { width: 96*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol97Di100: { width: 97*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol98Di100: { width: 98*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },
  tableCol99Di100: { width: 99*100/100+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, },

  tableCol1NoBordo: { width: 100+"%", borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol2NoBordo: { width: 100/2+"%", borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol3NoBordo: { width: 100/3+"%", borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol4NoBordo: { width: 100/4+"%", borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCol2Di3NoBordo: { width: 2*100/3+"%", borderLeftWidth: 0, borderTopWidth: 0, }, 
  tableCell: { margin: "auto", fontSize: 10, },
  tableCellIntestazione: { marginLeft: 0,fontSize: 10},
  tableCellGrassettoAllineatoSinistra: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 2,marginRight: 2, fontSize: 10,marginTop: 3,marginBottom: 3},
  tableCellGrassettoAllineatoSinistraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 2,marginRight: 2, fontSize: 10,marginBottom: 3},
  tableCellGrassettoAllineatoDestra: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginLeft: 2,marginRight: 2, fontSize: 10,marginTop: 3,marginBottom: 3},
  tableCellGrassettoAllineatoDestraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginLeft: 2,marginRight: 2, fontSize: 10,marginBottom: 3},
  tableCellAllineatoSinistra: {textAlign: 'left',marginLeft: 2,marginRight: 2, fontSize: 10,marginTop: 3,marginBottom: 3},
  tableCellAllineatoDestra: {textAlign: 'right',marginLeft: 2,marginRight: 2, fontSize: 10,marginTop: 3,marginBottom: 3},

  titoloTabella: {fontFamily: 'Helvetica-Bold',marginTop: 20}, 
  //grafici: {width: 400,height: 400,},
  //graficiLunghi: {width: 700,height: 350,},
  grafici: {width: 300,height: 300,},
  graficiLunghi: {width: 400,height: 200,},
  pageNumber:{position: "absolute",fontSize: 12,bottom:30,left:0,right:0,textAlign:'center',color:"gray"},

  rigaRicevuta: {marginTop: 20,},
  rigaRicevutaCentrata: {textAlign:'center',marginTop: 20,},

  tesseraNomesocieta: {fontFamily: 'Helvetica-Bold',marginLeft: 2, fontSize: 11},
  tesseraIndirizzoSocieta: {fontFamily: 'Helvetica',marginLeft: 4,marginTop: 1, fontSize: 8},
  tesseraDatiIscrittoGrassetto: {fontFamily: 'Helvetica-Bold',marginLeft: 2,marginBottom: 1,marginTop: 2, fontSize: 10},
  tesseraDatiIscritto: {fontFamily: 'Helvetica',marginLeft: 2,marginBottom: 1, fontSize: 8},
  tesseraLogo: {height: 45,width: 60,objectFit: 'contain',padding: 5},
  tesseraFoto: {height: 60,width: 60,objectFit: 'contain',padding: 5},
  tesseraQrcode: {height: 100,width: 100,objectFit: 'contain',padding: 5},

  tabellaDataFirma: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    marginTop: 20,
  },
  tabellaDataFirmaRow: {
    flexDirection: 'row',
  },
  tabellaDataFirmaCol: {
    width: '50%',
    padding: 5,
  },
  tabellaDataFirmaCellHeader: {
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign:'center', 
    fontSize: 10
  },
  tabellaDataFirmaCellData: {
    marginBottom: 10,
    textAlign:'center', 
    fontSize: 12
  },
  rigaPerFirma: {
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    width: '100%',
    marginTop: 20,
  },

});

export default styles;