import React, {useState,useEffect} from 'react';
import {
    Card, CardBody, CardTitle, Button, Modal, Row, Col
} from 'reactstrap';
import Modale from '../Modale.js';

function ConvocaAggiungiRimuovi(props) {
    const [testoRicercaRaggruppamenti, setTestoRicercaRaggruppamenti] = useState("");
    const [testoRicercaPersone, setTestoRicercaPersone] = useState("");
    
    const [mostraClessidra, setMostraClessidra] = useState(false);

    const [numeroPersoneSelezionate, setNumeroPersoneSelezionate] = useState(0);
    // array di boolean con tanti elementi quante sono tutte le persone: true per le persone selezionate da utente, per aggiungerle o rimuoverle
    const [personeSelezionate, setPersoneSelezionate] = useState([]);
    // array di boolean: true per le persone filtrate dai raggruppamenti (appartengono o non appartengono ai raggruppamenti selezionati)
    const [personeFiltrate, setPersoneFiltrate] = useState([]);
    // array di boolean: true per i raggruppamenti selezionati
    const [raggruppamentiSelezionati, setRaggruppamentiSelezionati] = useState([]);   

    useEffect(() => {
        let f=[];
        let s=[];
        for (let i=0; i<props.persone.length; i++) {
            f[i]=true;
            s[i]=false;
        }
        setPersoneFiltrate(f);
        setPersoneSelezionate(s);
    },[])
        
    // restituisce l'intersezione degli array a e b, che DEVONO essere array con elementi NUMERICI ORDINATI in ordine crescente
    function intersezioneArray(a, b) {
      var ai=0, bi=0, intersezione=[];
      while (ai<a.length && bi<b.length) {
         if (a[ai]<b[bi]) { ai++; }
         else if (a[ai]>b[bi]) { bi++; }
         else { intersezione.push(a[ai]); ai++; bi++ }
      }
      return intersezione;
    }

    function modificaRaggruppamenti(indice) {
        var intersezione=[];
        var ragg=[...raggruppamentiSelezionati];
        ragg[indice]=!ragg[indice];
        setRaggruppamentiSelezionati(ragg);
        var raggAttivi = [];
        for (let i=0; i<ragg.length; i++) {
            if (ragg[i]) {
                console.log("gruppo n."+i+" attivo: aggiungo "+props.raggruppamenti[i].ID+" agli ID dei gruppi attivi");
                raggAttivi.push(props.raggruppamenti[i].ID);
            }
        }
        if (raggAttivi.length>0) {
            raggAttivi.sort(); // non è ordinato a priori e intersezioneArray vuole array ordinati

            for (let i=0; i<raggAttivi.length; i++) {
                console.log("raggAttivi "+i+": "+raggAttivi[i]);
            }

            for (let i=0; i<props.persone.length; i++) {
                intersezione = intersezioneArray(props.persone[i].GRUPPI,raggAttivi);
                if (intersezione.length>0) {
                    personeFiltrate[i] = true; 
                } else {
                    personeFiltrate[i] = false;
                }
            }
        } else {
            // nessun raggruppamento selezionato: tutte le persone attive
            for (let i=0; i<props.stringhePersone.length; i++) {            
                personeFiltrate[i] = true; 
            }            
        }
    }

    var titoloModale="";
    var etichettaElementi="";
    var coloreEvidenziato="";
    switch(props.azioneConvocazioni) {
        case "aggiungiConvocati": titoloModale="Aggiungi convocati"; etichettaElementi="Atleti da convocare per l'evento"; coloreEvidenziato="#84b352"; break;
        case "rimuoviConvocati": titoloModale="Rimuovi convocati"; etichettaElementi="Convocati da eliminare dall'evento"; coloreEvidenziato="#e26e24"; break;
        case "aggiungiStaff": titoloModale="Aggiungi staff"; etichettaElementi="Staff da aggiungere all'evento"; coloreEvidenziato="#84b352"; break;
        case "rimuoviStaff": titoloModale="Rimuovi staff"; etichettaElementi="Staff da eliminare dall'evento"; coloreEvidenziato="#e26e24"; break;
    }

    return (
        <Modale 
            titolo={titoloModale}
            larghezza = "xl"
            flagErrore={false}
            contenuto={<div>
                <Row form>  
                    <Col md={6}>
                        <b>Mostra appartenenti a questi gruppi</b><br/>
                        Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaRaggruppamenti(e.target.value.toLowerCase())}/>
                        <div style={{height:300, backgroundColor:"#EEEEEE", overflow:"auto"}}>
                            {props.raggruppamenti.map((x,i) =>
                                testoRicercaRaggruppamenti==="" || x.DESCRIZIONE.toLowerCase().includes(testoRicercaRaggruppamenti) ?
                                    <div 
                                        key={i}
                                        style={{
                                            marginLeft:5, 
                                            backgroundColor:raggruppamentiSelezionati[i]?"#2222FF":"transparent", 
                                            color:raggruppamentiSelezionati[i]?"#EEEEFF":"#000000",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => modificaRaggruppamenti(i)}
                                    >
                                        {x.DESCRIZIONE}
                                    </div>
                                : ""
                            )}
                        </div>
                    </Col>

                    <Col md={6}>
                        <b>{etichettaElementi}</b><br/>
                        Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaPersone(e.target.value.toLowerCase())}/>
                        <div style={{height:300, backgroundColor:"#EEEEEE", overflow:"auto"}}>
                            {props.persone.map((x,i) =>
                                personeFiltrate[i] 
                            && 
                                (
                                    (props.azioneConvocazioni == "aggiungiConvocati" && props.persone[i].FLAG_STAFF==0 && props.persone[i].CONVOCATA==0) 
                                    ||
                                    (props.azioneConvocazioni == "rimuoviConvocati" && props.persone[i].FLAG_STAFF==0 && props.persone[i].CONVOCATA==1) 
                                    ||
                                    (props.azioneConvocazioni == "aggiungiStaff" && props.persone[i].FLAG_STAFF==1 && props.persone[i].CONVOCATA==0) 
                                    ||
                                    (props.azioneConvocazioni == "rimuoviStaff" && props.persone[i].FLAG_STAFF==1 && props.persone[i].CONVOCATA==1 && props.persone[i].ID!=props.mioId) 
                                )
                            && 
                                (testoRicercaPersone==="" || x.toLowerCase().includes(testoRicercaPersone)) 
                            &&  
                                <div 
                                    key={i}
                                    style={{
                                        marginLeft:5, 
                                        backgroundColor:personeSelezionate[i]?coloreEvidenziato:"transparent", 
                                        color:personeSelezionate[i]?"#FFFFFF":"#000000",
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (personeSelezionate[i]) setNumeroPersoneSelezionate(numeroPersoneSelezionate-1); else setNumeroPersoneSelezionate(numeroPersoneSelezionate+1);                                         
                                        var ps=[...personeSelezionate];                                        
                                        ps[i]=!ps[i];
                                        setPersoneSelezionate(ps);
                                    }}
                                >
                                    {props.stringhePersone[i]}
                                </div>                                
                            )}   
                        </div>                     
                        
                    </Col>                    
                </Row>
                
                <Row form>  
                    <Col md={4}>

                    </Col>
                    <Col md={4}>
                        {numeroPersoneSelezionate>0 ? numeroPersoneSelezionate===1 ? "1 elemento selezionato" : numeroPersoneSelezionate+" elementi selezionati" : "Nessun elemento selezionato" }                        
                    </Col>
                </Row>

                {props.azioneConvocazioni == "aggiungiConvocati" && <>Premendo il bottone verde "convoca i selezionati" saranno immediatamente convocati gli atleti selezionati</>}
                {props.azioneConvocazioni == "rimuoviConvocati" && <>Premendo il bottone arancione "rimuovi i selezionati" saranno immediatamente annullate le convocazioni agli atleti selezionati</>}
                {props.azioneConvocazioni == "aggiungiStaff" && <>Premendo il bottone verde "aggiungi i selezionati" saranno immediatamente aggiunti i membri dello staff selezionati</>}
                {props.azioneConvocazioni == "rimuoviStaff" && <>Premendo il bottone arancione "rimuovi i selezionati" saranno immediatamente rimossi i membri dello staff selezionati</>}
                
            </div>
            }
            bottoni={[
                {
                    "etichetta":props.azioneConvocazioni=="aggiungiConvocati"?"Convoca i selezionati":props.azioneConvocazioni=="RimuoviConvocati"?"Annulla convoc. ai selezionati":props.azioneConvocazioni=="aggiungiStaff"?"Aggiungi i selezionati":"Rimuovi i selezionati",
                    "tipo":props.azioneConvocazioni=="aggiungiConvocati"||props.azioneConvocazioni=="aggiungiStaff"?"success":"warning",
                    callback:() => {
                        var elencoID = [];
                        for (let n=0; n<personeSelezionate.length; n++) {
                            if (personeSelezionate[n]) elencoID.push(props.persone[n].ID);
                        }
                        props.callbackConvocaAggiungiRimuovi(elencoID, props.azioneConvocazioni)
                    }
                },
                {
                    "etichetta":"Seleziona Visibili", 
                    "tipo":"primary",
                    callback:() => {
                        var ps=[...personeSelezionate]; 
                        for (let i=0; i<props.stringhePersone.length; i++) if (personeFiltrate[i]) ps[i] = true; 
                        setPersoneSelezionate(ps);
                    }
                },
                {
                    "etichetta":"Desel. Visibili", 
                    "tipo":"primary",
                    callback:() => {
                        var ps=[...personeSelezionate]; 
                        for (let i=0; i<props.stringhePersone.length; i++) if (personeFiltrate[i]) ps[i] = false; 
                        setPersoneSelezionate(ps);
                    }
                },
                {
                    "etichetta":"Annulla",
                    "tipo":"primary",
                    callback:() => {props.callbackConvocaAggiungiRimuovi([],"")
                    }
                }
            ]}
        />
    )
}

export default ConvocaAggiungiRimuovi;