
const arrayColoriGrafico=([
  "rgba(0, 130, 255, 0.5)",
  "rgba(255, 140, 105, 0.5)",
  "rgba(0, 128, 128, 0.5)",
  "rgba(255, 165, 0, 0.5)",
  "rgba(50, 100, 160, 0.5)",
  "rgba(255, 69, 0, 0.5)",
  "rgba(255, 20, 147, 0.5)",
  "rgba(0, 228, 181, 0.5)",
  "rgba(128, 128, 128, 0.5)",
  "rgba(255, 0, 0, 0.5)",
  "rgba(255, 255, 255, 0.5)",
  "rgba(0, 255, 0, 0.5)",
  "rgba(0, 0, 205, 0.5)",
  "rgba(0, 206, 209, 0.5)",
  "rgba(102, 205, 170, 0.5)",
  "rgba(0, 128, 0, 0.5)",
  "rgba(184, 134, 11, 0.5)",
  "rgba(0, 128, 128, 0.5)",
  "rgba(255, 215, 0, 0.5)",
  "rgba(139, 69, 19, 0.5)",
  "rgba(0, 255, 255, 0.5)",
  "rgba(173, 255, 47, 0.5)",
  "rgba(255, 192, 203, 0.5)",
  "rgba(255, 192, 203, 0.5)",
  "rgba(139, 0, 0, 0.5)",
  "rgba(255, 140, 105, 0.5)",
  "rgba(0, 139, 139, 0.5)",
  "rgba(255, 69, 0, 0.5)",
  "rgba(0, 255, 127, 0.5)",
  "rgba(70, 130, 180, 0.5)",
  "rgba(173, 216, 230, 0.5)",
  "rgba(255, 250, 205, 0.5)",
  "rgba(255, 69, 0, 0.5)",
  "rgba(240, 128, 128, 0.5)",
  "rgba(255, 0, 255, 0.5)",
  "rgba(255, 69, 0, 0.5)",
  "rgba(0, 128, 0, 0.5)",
  "rgba(0, 0, 128, 0.5)",
  "rgba(0, 250, 154, 0.5)",
  "rgba(0, 0, 128, 0.5)",
  "rgba(0, 128, 0, 0.5)",
  "rgba(128, 0, 0, 0.5)",
  "rgba(128, 128, 0, 0.5)",
  "rgba(0, 128, 128, 0.5)",
  "rgba(128, 0, 128, 0.5)",
  "rgba(0, 0, 0, 0.5)",
  "rgba(255, 250, 205, 0.5)",
  "rgba(255, 140, 0, 0.5)",
  "rgba(255, 105, 180, 0.5)",
  "rgba(255, 99, 71, 0.5)",


    "rgba(0, 0, 128, 0.5)",
    "rgba(255,99,132,0.5)",
    "rgba(255,159,64,0.5)",
    "rgba(75,192,192,0.5",
    "rgba(255,205,86,0.5)",
    "rgba(54,162,235,0.5)",
    "rgba(128,128,0,0.5)",
    "rgba(128,0,128,0.5)",
    "rgba(255,0,0,0.5)",
    "rgba(192,192,192,0.5)",
    "rgba(255,255,0,0.5)",
    "rgba(0,128,128,0.5)",
    "rgba(0,255,0,0.5)",
    "rgba(128,0,0,0.5)",
    "rgba(0,0,128,0.5)",
    "rgba(255,255,0,0.5)",
  ]);

export default arrayColoriGrafico;