import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Button,Label,Input} from 'reactstrap';
import TendinaOrariAStep from '../Pagine/TendinaOrariAStep.js';

import Cestino from "../immagini/cestino.png"

function TabellaOrariIndisponibilita(props) {
    
    //in input ho:
    //props.orariIndisponibilita
    //contenenti tutti i valori precedenti
  
    //in output fornisco:
    //props.callbackSetVariazioneCampi(1); qualora andassi a modificare (anche cancellandolo) o ad aggiungere un valore
    //props.callbacksetOrariIndisponibilita mi darà il nuovo array con i valori

    const [orariIndisponibilita,setOrariIndisponibilita]=useState([]);
    const intervalloTendina=5;
    
    useEffect(() => {
        if(props.orariIndisponibilita!== undefined) setOrariIndisponibilita(props.orariIndisponibilita);
    },[])

    function handleCambiamentoMod(event) {
        props.callbackSetVariazioneCampi(1);
    }

    function callbackSetVariazioneCampiOrari(){        
        props.callbackSetVariazioneCampi(1);
    }
    function callbacksetOrarioSelezionatoInizio(valore,props2){
        //console.log("indice dalle"+props2.indice);
        //console.log("valore dalle"+valore);
        let orari = [...orariIndisponibilita];//popolo l'array locale con i dati dell'array di stato
        orari[props2.indice].inizio=valore;
        setOrariIndisponibilita(orari);//aggiorno la var di stato locale
        props.callbackSetOrariIndisponibilita(orari);//aggiorno la var di stato del componente chiamante
    }
    function callbacksetOrarioSelezionatoFine(valore,props2){
        //console.log("indice alle"+props2.indice);
        //console.log("valore alle"+valore);
        let orari = [...orariIndisponibilita];//popolo l'array locale con i dati dell'array di stato
        orari[props2.indice].fine=valore;
        setOrariIndisponibilita(orari);//aggiorno la var di stato locale
        props.callbackSetOrariIndisponibilita(orari);//aggiorno la var di stato del componente chiamante
    }

    function aggiornaData(index,dato){       
        //console.log("aggiornaData",index,dato);
        let orari = [...orariIndisponibilita];//popolo l'array locale con i dati dell'array di stato
        orari[index].data=dato;
        setOrariIndisponibilita(orari);//aggiorno la var di stato locale
        props.callbackSetOrariIndisponibilita(orari);//aggiorno la var di stato del componente chiamante
    }
         
    function aggiungiOrario(props) {
        let orari = [...orariIndisponibilita];//popolo l'array locale con i dati dell'array di stato
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Aggiungi uno perché i mesi vanno da 0 a 11
        const day = String(today.getDate()).padStart(2, '0');        
        orari.push({data:year+"-"+month+"-"+day,inizio:"12:00",fine:"12:00"});//aggiungo un elemento all'array locale
        setOrariIndisponibilita(orari);//aggiorno la var di stato locale
        props.callbackSetOrariIndisponibilita(orari);//aggiorno la var di stato del componente chiamante
    }

    function eliminaOrario(posizione) {
        let orari = [...orariIndisponibilita];//popolo l'array locale con i dati dell'array di stato
        orari.splice(posizione, 1);//cancello l'elemento desiderato
        setOrariIndisponibilita(orari);//aggiorno la var di stato locale
        props.callbackSetOrariIndisponibilita(orari);//aggiorno la var di stato del componente chiamante
    }    

    return (        
      <div>     
            <Row>
                <Col md="12">
                    {orariIndisponibilita.map((elemento,index) => 
                        <div style={{display:"flex", flexDirection:"row"}} key={index}>
                            
                            <div style={{minWidth: 40,paddingRight:10,paddingTop:30}}>
                                <img onClick={() => {props.callbackSetVariazioneCampi(1);eliminaOrario(index)}} src={Cestino}/>
                            </div>
        
                            <div style={{minWidth: 100,paddingRight:10}}>
                                <Label for="giorno">Data&nbsp;</Label><br></br>   
                                <Input 
                                    type="date" 
                                    value={elemento.data}
                                    style={{width: 'auto',height: 36,borderRadius: '5px 5px 5px 5px'}} 
                                    onInput={(e)=>{handleCambiamentoMod(e);aggiornaData(index,e.target.value)}}
                                />
                            </div>
                                                        
                            <div style={{minWidth: 100,paddingRight:10}}>
                                <Label for="orarioInizio">Orario Inizio&nbsp;</Label>
                                    
                                <TendinaOrariAStep
                                    step = {intervalloTendina} //step espresso in minuti tra un orario ed il successivo
                                    orarioDefault= {elemento.inizio}
                                    orarioInizio = "00:00"
                                    orarioFine= "24:00"
                                    indice={index}
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                    callbacksetOrarioSelezionato={callbacksetOrarioSelezionatoInizio}
                                ></TendinaOrariAStep>
                            </div>
                            
                            <div style={{minWidth: 100,paddingRight:10}}>
                                <Label for="orarioFineDisponibilita">Orario Fine&nbsp;</Label>
                                <TendinaOrariAStep
                                    step = {intervalloTendina} //step espresso in minuti tra un orario ed il successivo
                                    orarioDefault= {elemento.fine}
                                    orarioInizio = "00:00"
                                    orarioFine= "24:00"
                                    indice={index}
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                    callbacksetOrarioSelezionato={callbacksetOrarioSelezionatoFine}
                                ></TendinaOrariAStep>
                            </div>

                            <div style={{paddingRight:10}}>
                                <Label for="messaggioErrore">&nbsp;</Label><br></br> 
                                {orariIndisponibilita[index].inizio>=orariIndisponibilita[index].fine && <span style={{color:"#FF0000"}}>orari non validi</span>}
                            </div>

                        </div>
                    )}

                    <Row>
                        <Col md={12}>
                            <Button color="primary" className="mt-2 bottoneMargineADestra" onClick={() => {
                                props.callbackSetVariazioneCampi(1);
                                aggiungiOrario(props)}
                            }>
                                Aggiungi Orario
                            </Button>                              
                        </Col>
                    </Row>
                     
                </Col>   
            </Row>
    </div>
   );
}
export default TabellaOrariIndisponibilita;
