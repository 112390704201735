import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';


const STATO_INDEFINITO = 0;
const STATO_SCARICA_PDF = 1;
const STATO_PREPARA_PDF = 2;

// fornire props.tipo: "associativa" o "abbonamento"
function StampaTessera(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [loading,setLoading]= useState(false);

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    //const [nomeSocieta,setNomeSocieta]=useState('');
    const [nomeSocieta1,setNomeSocieta1]=useState('');
    const [nomeSocieta2,setNomeSocieta2]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');

    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocietaIntestazione,setIndirizzoSocietaIntestazione]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [ricercaLayoutTessere,setRicercaLayoutTessere]=useState('');
    const [arrayLayoutTessere,setArrayLayoutTessere]=useState([]);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);

    const [stringaGruppiPersona,setStringaGruppiPersona]=useState('');
    const [stringaPosizioniPersona,setStringaPosizioniPersona]=useState('');

    const [settataStringaPosizioniPersona,setSettataStringaPosizioniPersona]=useState(0);
    const [settataStringaGruppiPersona,setSettataStringaGruppiPersona]=useState(0);
    const [settataTesseraNumero,setSettataTesseraNumero]=useState(0);
    
    const [coloreSfondo,setColoreSfondo]=useState('');
    const [coloreTesto,setColoreTesto]=useState('');
    const [radiusBordo,setRadiusBordo]=useState('');

    const [tesseraNumero,setTesseraNumero]=useState('');

    const [cognomeNome,setCognomeNome]=useState('');

    const [accediAScarica,setAccediAScarica]=useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
        if(statoPagina === STATO_PREPARA_PDF && ricercaLayoutTessere === 1 && ricercaLogo=== 1 && settataStringaGruppiPersona===1 && settataStringaPosizioniPersona===1){
            //qui entro per settare le ultime cose che derivano dalla lettura dei dati nel db e del completamento conseguente di arrayLayoutTessere:

            setColoreSfondo(arrayLayoutTessere[13]);
            setColoreTesto(arrayLayoutTessere[16]);

            if(arrayLayoutTessere[14] === 0) setRadiusBordo("0px");
            if(arrayLayoutTessere[14] === 1) setRadiusBordo("8px");
            if(arrayLayoutTessere[14] === 2) setRadiusBordo("20px");

            //se ho scelto di vedere la foto devo ridurre ulteriormente i dati che compariranno vicino, quindi
            if(arrayLayoutTessere[10] === 1){
                let massimaLunghezzaStringaGruppi = 70;
                let massimaLunghezzaStringaPosizioni = 37;
                let massimaLunghezzaStringaNumeroTessera = 21;
            
                if(stringaGruppiPersona.length>massimaLunghezzaStringaGruppi) setStringaGruppiPersona(stringaGruppiPersona.substring(0,massimaLunghezzaStringaGruppi) + '...');
                if(stringaPosizioniPersona.length>massimaLunghezzaStringaPosizioni) setStringaPosizioniPersona(stringaPosizioniPersona.substring(0,massimaLunghezzaStringaPosizioni) + '...');
                setTesseraNumero(tesseraNumero.substring(0,massimaLunghezzaStringaNumeroTessera));
            }
            setAccediAScarica(1);
        }
        if(statoPagina === STATO_PREPARA_PDF && accediAScarica===1){
            //ultimo step prima di arrivare al pdf
            //console.log(props.contenutoFileFoto)
            setStatoPagina(STATO_SCARICA_PDF);
            //console.log("Foto"+props.contenutoFileFoto)
        } 

    },[statoPagina,ricercaLayoutTessere,ricercaLogo,settataStringaPosizioniPersona,settataStringaGruppiPersona,settataTesseraNumero,accediAScarica]);

    useEffect(() => {

        //settaggi di massima senza foto:
        let massimaLunghezzaStringaGruppi = 110;
        let massimaLunghezzaStringaPosizioni = 55;
        let massimaLunghezzaStringaNumeroTessera = 38;
        let massimaLunghezzaStringaCognomeNome = 40;
        
        setStatoPagina(STATO_PREPARA_PDF);
        scaricaLogo();
        scaricaLayoutTessere();
        let stringaGruppi='';
        let stringaGruppiCorretta ='';
        for (let i=0; i<props.arrayGruppiPersona.length ; i++) {
            if(stringaGruppi.length!==0) stringaGruppi = stringaGruppi +", ";
            stringaGruppi = stringaGruppi + props.arrayGruppiPersona[i];
        }    
        if(stringaGruppi.length>massimaLunghezzaStringaGruppi) stringaGruppiCorretta = stringaGruppi.substring(0,massimaLunghezzaStringaGruppi) + '...';
        else stringaGruppiCorretta = stringaGruppi
        setStringaGruppiPersona(stringaGruppiCorretta);
        setSettataStringaGruppiPersona(1);

        let stringaPosizioni='';
        let dataInizio='';
        let dataFine='';
        let stringaPosizioniCorretta ='';
       
        for (let i=0; i<props.arrayPosizioniPersona.length ; i++) {
            //controllo che la posizione sia ancora 'attuale' nel momento in cui stampo la tessera:
            dataInizio = props.arrayCampiAssociatiPosizioniValoriDefault[i][props.arrayCampiAssociatiPosizioniNomeDatabase[0]];
            dataFine = props.arrayCampiAssociatiPosizioniValoriDefault[i][props.arrayCampiAssociatiPosizioniNomeDatabase[1]];
            if(dataOggi>=dataInizio && (dataFine==='' || dataOggi<=dataFine)){
                if(stringaPosizioni.length!==0) stringaPosizioni = stringaPosizioni +", ";
                stringaPosizioni = stringaPosizioni + props.arrayPosizioniPersona[i];
            }
        }   
        if(stringaPosizioni.length>massimaLunghezzaStringaPosizioni) stringaPosizioniCorretta = stringaPosizioni.substring(0,massimaLunghezzaStringaPosizioni) + '...';  
        else stringaPosizioniCorretta = stringaPosizioni;
        setStringaPosizioniPersona(stringaPosizioniCorretta);
        setSettataStringaPosizioniPersona(1);
        
        if(props.tesseraNumero.length>massimaLunghezzaStringaNumeroTessera) setTesseraNumero(props.tesseraNumero.substring(0,massimaLunghezzaStringaNumeroTessera) + '...');
        else setTesseraNumero(props.tesseraNumero);
        setSettataTesseraNumero(1);

        let stringaCognomeNome = '';
        stringaCognomeNome = props.cognome;
        if(props.nome !== '') stringaCognomeNome = stringaCognomeNome+" "+props.nome;
        if(stringaCognomeNome.length>massimaLunghezzaStringaCognomeNome) setCognomeNome(stringaCognomeNome.substring(0,massimaLunghezzaStringaCognomeNome) + '...');
        else setCognomeNome(stringaCognomeNome);
    },[]);

    function scaricaLogo() {
        let massimaLunghezzaStringaIndirizzoSocieta = 40;
        let massimaLunghezzaStringaNomeSocieta = 48;
        let massimaLunghezzaStringaNomeSocietaPrimaRiga = 30;
        let massimaLunghezzaStringaNomeSocietaSecondaRiga = 30;
        let arrayNomeSocieta=[];
        let stringaPrimaRigaNomeSocieta='';
        let stringaSecondaRigaNomeSocieta='';
        let rigaAttuale = 1;
        let finito = 0;

        //console.log("scaricaLogo");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {          
              if(valoreDiRitorno.risultatoOperazione===1){
                  if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                    let indirizzoLoc = '';
                    setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);

                    {/*if(valoreDiRitorno.risultatoRitorno[0].NOME.length>massimaLunghezzaStringaNomeSocieta){
                        setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME.substring(0,massimaLunghezzaStringaNomeSocieta) + '...');
                    } else setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);*/}

                    if(valoreDiRitorno.risultatoRitorno[0].NOME.length>massimaLunghezzaStringaNomeSocietaPrimaRiga){
                        //taglio il nome in modo da non troncare le parole:
                        arrayNomeSocieta = valoreDiRitorno.risultatoRitorno[0].NOME.split(" ");
                        //ora aggiungo pezzi alla prima riga fino ad arrivare al massimo concesso:
                        for (let i=0; i<arrayNomeSocieta.length ; i++) {
                            if(i===0 && stringaPrimaRigaNomeSocieta.length+arrayNomeSocieta[i].length>massimaLunghezzaStringaNomeSocietaPrimaRiga){
                                //devo per forza troncare perchè ho solo una parola che supera la lunghezza max riga
                                setNomeSocieta1(arrayNomeSocieta[i].substring(0,massimaLunghezzaStringaNomeSocieta) + '...');
                                finito = 1;
                            }
                            if(((i>0 && stringaPrimaRigaNomeSocieta.length+arrayNomeSocieta[i].length>massimaLunghezzaStringaNomeSocietaPrimaRiga) || rigaAttuale===2) && finito === 0){
                                //passo alla riga 2:
                                rigaAttuale = 2;
                                //controllo di non suparare il limite della seconda riga:
                                if(stringaSecondaRigaNomeSocieta.length+arrayNomeSocieta[i].length<=massimaLunghezzaStringaNomeSocietaSecondaRiga){
                                    stringaSecondaRigaNomeSocieta = stringaSecondaRigaNomeSocieta+" "+arrayNomeSocieta[i];
                                }else{
                                    finito = 1;
                                }
                            }else{
                                if(finito === 0){
                                    //continuo ad aggiungere alla prima riga:
                                    stringaPrimaRigaNomeSocieta = stringaPrimaRigaNomeSocieta+" "+arrayNomeSocieta[i];
                                }
                            }
                        }
                        setNomeSocieta1(stringaPrimaRigaNomeSocieta);
                        setNomeSocieta2(stringaSecondaRigaNomeSocieta);
                    } else{
                        setNomeSocieta1(valoreDiRitorno.risultatoRitorno[0].NOME);
                    } 
                    setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoSocietaIntestazione(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                    setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);


                    if(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO !== ''){
                        indirizzoLoc = valoreDiRitorno.risultatoRitorno[0].INDIRIZZO;
                    }
                    if(valoreDiRitorno.risultatoRitorno[0].CITTA !== ''){
                        if(indirizzoLoc.length>0) indirizzoLoc = indirizzoLoc + " ";
                        indirizzoLoc = indirizzoLoc+valoreDiRitorno.risultatoRitorno[0].CITTA;
                    }

                    if(indirizzoLoc.length>massimaLunghezzaStringaIndirizzoSocieta) setIndirizzoSocieta(indirizzoLoc.substring(0,massimaLunghezzaStringaIndirizzoSocieta) + '...');
                    else setIndirizzoSocieta(indirizzoLoc);
                  }
                  setRicercaLogo(1);
              }
          },
          (error) => {console.log("Errore connessione");}
        );        
    }
   

    function scaricaLayoutTessere(){
        //vado a fare la fetch di ricerca delle impostazioni del layout tessere:
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            op:"impostazioniLayoutTessere",
            ricercaImpostazioni : 1,
            emak: props.emak,
            sessionId: props.sessionId})
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          

            if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale=[];
                arrLocale[0]=parseInt(valoreDiRitorno.risultatoRitorno[0].LOGO_SOCIETA);
                arrLocale[1]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_LOGO_SOCIETA);
                arrLocale[2]=parseInt(valoreDiRitorno.risultatoRitorno[0].DENOMINAZIONE_SOCIETA);
                arrLocale[3]=parseInt(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO_SOCIETA);
                arrLocale[4]=parseInt(valoreDiRitorno.risultatoRitorno[0].COGNOME_NOME_ISCRITTO);
                arrLocale[5]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_NASCITA_ISCRITTO);
                arrLocale[6]=parseInt(valoreDiRitorno.risultatoRitorno[0].GENERE_ISCRITTO);
                arrLocale[7]=parseInt(valoreDiRitorno.risultatoRitorno[0].RAGGRUPPAMENTI_ISCRITTO);
                arrLocale[8]=parseInt(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                arrLocale[9]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONI_SOCIALI_ISCRITTO);
                arrLocale[10]=parseInt(valoreDiRitorno.risultatoRitorno[0].FOTO_ISCRITTO);
                arrLocale[11]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_FOTO_ISCRITTO);
                arrLocale[12]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_RILASCIO_TESSERA);
                arrLocale[13]=valoreDiRitorno.risultatoRitorno[0].COLORE_SFONDO;
                arrLocale[14]=parseInt(valoreDiRitorno.risultatoRitorno[0].BORDO_ARROTONDATO);
                arrLocale[15]=parseInt(valoreDiRitorno.risultatoRitorno[0].ELIMINA_INTESTAZIONE);
                arrLocale[16]=valoreDiRitorno.risultatoRitorno[0].COLORE_TESTO;
                //console.log("Y"+valoreDiRitorno.risultatoRitorno[0].ELIMINA_INTESTAZIONE)
                setArrayLayoutTessere(arrLocale);
                setRicercaLayoutTessere(1);
            }
        },
        (error) => {console.log("Errore connessione");}
        ); 
    }

    return (
        <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>
            {statoPagina === STATO_SCARICA_PDF ? 
                    <div>
                    {/*<PDFViewer style={styles.viewer}>*/}
                    <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
                        <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
                        <Document>
                        <Page size="A4" style={styles.page}>
                        <View style={{flexDirection: "column"}}>

                        {arrayLayoutTessere[15] === 0 ?
                            <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                                <View wrap={false} style={styles.tableRowIntestazione}> 
                                    <View style={styles.tableColIntestazioneSx}> 
                                    <View style={styles.tableCell}> 
                                        {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                    </View> 
                                    </View> 
                                <View style={styles.tableColIntestazioneDx}>   
                                    <View style={styles.tableCellIntestazione}> 
                                    {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                                    {indirizzoSocietaIntestazione !== "" ?
                                    <Text style={styles.datisocieta}>{indirizzoSocietaIntestazione}
                                    {cittaSocieta !== "" ?
                                    capSocieta !== "" ?
                                    " "+capSocieta+" "+cittaSocieta
                                    :" "+cittaSocieta
                                    :""}
                                    </Text>
                                    :<Text></Text>}
                                    {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                    {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                    
                                    </View> 
                                </View> 
                                </View> 
                                <View style={{marginTop: 20,marginLeft: 40}}>
                                    <Text style={styles.titolo}>Tessera Sociale di {props.cognome} {props.nome}</Text>
                                </View>
                            </View> 
                        :""}



                            {/*qui tessera vera e propria*/}
                            <View style={{marginTop: 100,marginLeft: 170,backgroundColor:coloreSfondo,color:coloreTesto,width:230,height:140,flexDirection: "column",border : "1px solid black",borderRadius : radiusBordo}}>
                                <View style={{flex:2,marginLeft: 3,marginRight: 3}}>
                                    {arrayLayoutTessere[0] === 1 && arrayLayoutTessere[1] === 0 ?
                                        <View style={{flexDirection: "row"}}>
                                            <View style={{flex:2}}>
                                                {fileLogo !== "" ? <Image style={styles.tesseraLogo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                            </View>  
                                            <View style={{flex:5,marginTop: 7}}> 
                                                {nomeSocieta1 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta1}</Text>:""}
                                                {nomeSocieta2 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta2}</Text>:""}
                                                {arrayLayoutTessere[3] === 1 ? <Text style={styles.tesseraIndirizzoSocieta}>{indirizzoSocieta}</Text>:""}   
                                            </View>
                                        </View> 
                                    :""}

                                    {arrayLayoutTessere[0] === 1 && arrayLayoutTessere[1] === 1 ?
                                        <View style={{flexDirection: "row"}}>
                                            <View style={{flex:5,marginTop: 7}}> 
                                                {nomeSocieta1 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta1}</Text>:""}
                                                {nomeSocieta2 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta2}</Text>:""}
                                                {arrayLayoutTessere[3] === 1 ? <Text style={styles.tesseraIndirizzoSocieta}>{indirizzoSocieta}</Text>:""}   
                                            </View>
                                            <View style={{flex:2}}>
                                                {fileLogo !== "" ? <Image style={styles.tesseraLogo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                            </View>  
                                        </View> 
                                    :""}

                                    {arrayLayoutTessere[0] === 0 ?
                                        <View style={{marginTop: 7}}> 
                                            {nomeSocieta1 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta1}</Text>:""}
                                            {nomeSocieta2 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta2}</Text>:""}
                                            {arrayLayoutTessere[3] === 1 ? <Text style={styles.tesseraIndirizzoSocieta}>{indirizzoSocieta}</Text>:""}   
                                        </View>
                                    :""}
                                </View>
    
                                <View style={{flex:4,marginLeft: 3,marginRight: 3}}>

                                    <View><Text style={styles.tesseraDatiIscrittoGrassetto}>{cognomeNome}</Text></View>

                                    {arrayLayoutTessere[10] === 1 && props.contenutoFileFoto!==''?
                                        arrayLayoutTessere[11] === 0 ?
                                            <View style={{flexDirection: "row"}}>
                                                <View style={{flex:1}}>
                                                    {props.typeFileFoto.substring(0, 5) === "image" ?
                                                        <Image style={styles.tesseraFoto} src={props.contenutoFileFoto} />
                                                    :""}
                                                    {/*<Image style={styles.tesseraFoto} src={"data:" + props.typeFileFoto + ";base64," + props.contenutoFileFoto} />*/}
                                                </View>
                                                <View style={{flex:2}}>
                                                {arrayLayoutTessere[5] === 1 && props.dataNascita!==''? <View><Text style={styles.tesseraDatiIscritto}>Data di Nascita: <TrasformaDateAUmano data={props.dataNascita}></TrasformaDateAUmano></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[6] === 1 ? <View> {props.sesso==='1' ?<Text style={styles.tesseraDatiIscritto}>Genere: M</Text>:<Text style={styles.tesseraDatiIscritto}>Genere: F</Text>}</View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[7] === 1 && stringaGruppiPersona.length!==0? <View><Text style={styles.tesseraDatiIscritto}>{stringaGruppiPersona}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[9] === 1 && stringaPosizioniPersona.length!==0? <View><Text style={styles.tesseraDatiIscritto}>{stringaPosizioniPersona}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[8] === 1 && tesseraNumero!=='' ? <View><Text style={styles.tesseraDatiIscritto}>Tessera n. {tesseraNumero}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}                   
                                                {arrayLayoutTessere[12] === 1 ? <View><Text style={styles.tesseraDatiIscritto}>Data rilascio: <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                </View>
                                            </View>
                                        :
                                            <View style={{flexDirection: "row"}}>
                                                <View style={{flex:2}}>
                                                {arrayLayoutTessere[5] === 1 && props.dataNascita!==''? <View><Text style={styles.tesseraDatiIscritto}>Data di Nascita: <TrasformaDateAUmano data={props.dataNascita}></TrasformaDateAUmano></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[6] === 1 ? <View> {props.sesso==='1' ?<Text style={styles.tesseraDatiIscritto}>Genere: M</Text>:<Text style={styles.tesseraDatiIscritto}>Genere: F</Text>}</View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[7] === 1 && stringaGruppiPersona.length!==0? <View><Text style={styles.tesseraDatiIscritto}>{stringaGruppiPersona}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[9] === 1 && stringaPosizioniPersona.length!==0? <View><Text style={styles.tesseraDatiIscritto}>{stringaPosizioniPersona}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[8] === 1 && tesseraNumero!=='' ? <View><Text style={styles.tesseraDatiIscritto}>Tessera n. {tesseraNumero}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}                   
                                                {arrayLayoutTessere[12] === 1 ? <View><Text style={styles.tesseraDatiIscritto}>Data rilascio: <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                </View>
                                                <View style={{flex:1}}>
                                                    {props.typeFileFoto.substring(0, 5) === "image" ?
                                                        <Image style={styles.tesseraFoto} src={props.contenutoFileFoto} />
                                                    :""}
                                                    {/*<Image style={styles.tesseraFoto} src={"data:" + props.typeFileFoto + ";base64," + props.contenutoFileFoto} />*/}
                                                </View>
                                            </View>
                                    :
                                        <>
                                        {arrayLayoutTessere[5] === 1 && props.dataNascita!==''? <View><Text style={styles.tesseraDatiIscritto}>Data di Nascita: <TrasformaDateAUmano data={props.dataNascita}></TrasformaDateAUmano></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                        {arrayLayoutTessere[6] === 1 ? <View> {props.sesso==='1' ?<Text style={styles.tesseraDatiIscritto}>Genere: M</Text>:<Text style={styles.tesseraDatiIscritto}>Genere: F</Text>}</View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                        {arrayLayoutTessere[7] === 1 && stringaGruppiPersona.length!==0? <View><Text style={styles.tesseraDatiIscritto}>{stringaGruppiPersona}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                        {arrayLayoutTessere[9] === 1 && stringaPosizioniPersona.length!==0? <View><Text style={styles.tesseraDatiIscritto}>{stringaPosizioniPersona}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                        {arrayLayoutTessere[8] === 1 && tesseraNumero!=='' ? <View><Text style={styles.tesseraDatiIscritto}>Tessera n. {tesseraNumero}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}                   
                                        {arrayLayoutTessere[12] === 1 ? <View><Text style={styles.tesseraDatiIscritto}>Data rilascio: <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                        </>
                                    }
                                </View> 
                                </View>
                            

                            {/*qui lato b della tessera*/}
                            <View style={{marginTop: 100,marginLeft: 170,backgroundColor:coloreSfondo,color:coloreTesto,width:230,height:140,flexDirection: "column",border : "1px solid black",borderRadius : radiusBordo}}>
                                <View style={{flex:1}}>
                                    <View style={{flex:1,marginTop: 19,marginLeft: 64}}>
                                    {/*<Text style={styles.tesseraDatiIscrittoGrassetto}>arriva{props.contenutoFileQrcode}</Text>*/}
                                            
                                            <Image style={styles.tesseraQrcode} src={"data:image/png;base64," + props.contenutoFileQrcode} />
                                        
                                        {/*<Image style={styles.tesseraFoto} src={"data:" + props.typeFileFoto + ";base64," + props.contenutoFileFoto} />*/}
                                    </View>
                                </View>
                            </View>
                        </View>
                        {/*<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />il numero della pagine non serve*/}
                        </Page>
                    </Document>
                    </PDFViewer>
                </div>

            :""}
        </div>
    );
}
export default StampaTessera;
