import React, {useState, useEffect} from 'react';
import Menu from './Menu'
import AppMain from './AppMain'
import PaginaInserimentoCredenziali from './PaginaInserimentoCredenziali'
import PaginaResetPassword from './PaginaResetPassword'
import Parametri from './parametri';
import "./generale.css"
import "./base.css"
import imgMenu from './immagini/menu1.png';
import imgCasa from './immagini/casa1.png';

function App() {
  const [sessionId, setSessionId] = useState([]);
  const [dimensioniApp, setDimensioniApp] = useState({x: 0, y:0, yTitolo:0, yMain:0});
  
  const [flagMenuVisibile, setFlagMenuVisibile] = useState(true);
  const [flagMenuRidotto, setFlagMenuRidotto] = useState(false);
  const [chiudiTuttiSottomenu, setChiudiTuttiSottomenu] = useState(false);

  const [identitaSelezionata, setIdentitaSelezionata] = useState([]);
  const [ricarica, setRicarica] = useState(false);
  const [fileLogo,setFileLogo]=useState('');
  const [typeLogo,setTypeLogo]=useState('');
  const [flagAggiornaFileLogo, setFlagAggiornaFileLogo]=useState(0);

  const emak = "w-HkwNcfPrGuxs0GI2CGPCGflKzg30otG3D+V8Aj0vDc80zmbKo1=.9OOEADH:,F.hXuAzvL|JS,GQ_1mXG3HN1i11GmQJEf?PJI";
  
  // ATTENZIONE ALLE NUMERAZIONI: SE LE CAMBI QUI, DEVI CAMBIARE ANCHE LE PAGINE RICHIAMATE DAI SUGGERIMENTI IN home.php
  const PAGINE = {
    "benvenuto": 0,
    "tornaLogin": 1,
    "resettaPassword": 2,

    "iscritti": 101,
    "direttivo": 102,
    "gruppi": 103,
    "ruoli": 104,
    "clientiFornitori": 105,
    "socAnaloghe": 106,
    "contattiUtili": 107,
    "libroSoci": 108,
    "associaTessera": 109,
    "inviaMessaggio": 110,

    "abbonati": 201,
    "abbonamenti": 202,
    "corsi": 203,
    //"prenotazioneCorsi": 204,
    "prenotazioneCorsi2": 205,
    "accessi": 206,

    "quoteAssociative": 301,
    "primaNota": 302,
    "fatture": 303,
    "spese": 304,
    "rimborsi": 305,
    "incassiDiversi": 306,
    "incassiDiversiIscritti": 307,
    "incassiQuoteAbb": 308,
    "incassiQuoteAss": 309,
    "risorse": 310,
    "vociDiBilancio": 311,
    "fattureElettroniche": 312,

    "federEntiTesseram": 401,
    "tipiTesseram": 402,
    "catTesseram": 403,
    "tesseram": 404,

    "adempimenti": 501,
    "scadenziario": 502,

    "news": 601,

    "eventi": 701,
    "tipiEventi": 702,

    "esercizi": 801,
    "classiEsercizi": 802,
    "schedeAllenamento": 803,
    "assegnazioniSchede": 804,

    "catArticoli": 901,
    "articoli": 902,

    "convenzioni": 1001,
    "entiConvenzioni": 1002,
    "sponsor": 1003,
    "campagneSponsor": 1004,

    "bilancio": 1101,
    "insolventi": 1102,
    "reportTesseramenti": 1103,
    "reportEconPersone": 1104,
    "reportEconEsterni": 1105,
    "reportSponsor": 1106,
    "reportRimborsi": 1107,
    "reportVincoli": 1108,
    "reportPrenotazioniCorsi": 1109,
    "reportEventi": 1110,
    "reportElencoPrenotazioni": 1111,
    "certScad": 1112,
    "docScad": 1113,
    "reportElencoArticoli": 1114,
    "reportAccessi": 1115,
    "privacyScad": 1116,
    "abbonamentiScad": 1117,

    //"verbaliAssemblee": 1201,
    "documenti": 1202,
    "tipiDocumenti": 1203,

    "tipiDocIdentita": 1301,
    "impostazioniSocieta": 1302,
    "mioAbbonamento": 1303,
    "importazioneMassiva": 1304,
    "nomiGruppi": 1305,
    "giorniChiusura": 1306,
    "posizioniSocieta": 1307,
    "cambioPassword": 1308,

    "faq":1401,

    "controlloAccessi": 1501,

    "settaggiCrm": 1601,
    "mezziComunicazCrm": 1602,
    "comunicazioniCrm": 1603,
    "datiCrm": 1604,
    "prospectCrm": 1605,
    "contattatiCrm": 1606, 

  };

  const xMinimaPerMenuNonRidotto = 1000;

  const [pagina, setPagina] = useState(PAGINE.benvenuto);

  const keepSessionAlive = async () => {
    console.log("Keep alive!");
    try {
      const response = await fetch(Parametri("serverURL"), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({op:"noop",emak: emak, sessionId: sessionId})
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Session kept alive:', data);
    } catch (error) {
      console.error('Error keeping session alive:', error);
    }
  };

  function scaricaLogo() {
    console.log("scaricaLogo");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo",emak: emak, sessionId: sessionId})
    };

    fetch(Parametri("serverURL", identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
              }
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

  useEffect(() => {
    console.log("useeffect sessionid", identitaSelezionata.nome_database)
    var intervalId;
    if (identitaSelezionata.nome_database==='EMA18' || identitaSelezionata.nome_database==='EMA48') {
      intervalId = setInterval(keepSessionAlive, 180000); // 180000ms = 3 minuti
    }

    return () => {
      clearInterval(intervalId); // Pulisce l'intervallo quando il componente si smonta
    };    
  },[sessionId])
 
  useEffect(() => {
    if (ricarica) {
      setRicarica(false)
    }
  },[ricarica])

  useEffect(() => {
  
    const calcolaDimensioniApp = () => {      
      const x = document.getElementById("principale").clientWidth;
      const y = document.getElementById("principale").clientHeight;
      var yTitolo = y/20;
      if (yTitolo<40) yTitolo = 40;
      var yMain = y-yTitolo;;
      setDimensioniApp({x:x, y:y, yTitolo:yTitolo, yMain: yMain});
      setFlagMenuRidotto(x<xMinimaPerMenuNonRidotto);
    }
    
    calcolaDimensioniApp();
    window.addEventListener('resize', calcolaDimensioniApp);

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const parametro = url.searchParams.get("djakgfh89724wygr");
    if (parametro) {      
      setPagina("resettaPassword");
    }
    
    return () => {
     window.removeEventListener('resize', calcolaDimensioniApp);
    };    
  }, []);

  useEffect(() => {
    if (identitaSelezionata.societa !== undefined){
      scaricaLogo();
    } 
  },[identitaSelezionata]);
  
  useEffect(() => {
    if (flagAggiornaFileLogo==1){
      setFlagAggiornaFileLogo(0);
      setFileLogo("");
      setTypeLogo("");

      // non scarico subito, perché impostazioni società (che ha cambiato il logo e impostato il flagAggiornaFileLogo) deve avere il tempo di fare l'upload prima che qui venga lanciato scaricaLogo()
      //console.log("setto timeout")
      setTimeout(() => {
        //console.log("scattato timeout")
        scaricaLogo();
      }, 5000); // dopo 5 secondi      

      // cancellare il timeout se il componente viene smontato prima che scada il timeout: se lo faccio, non funziona più il timeout.
      // timeoutId è il return value di setTimeout, cioè const timeoutId = setTimeout(...)
      //return () => clearTimeout(timeoutId);
    } 
  },[flagAggiornaFileLogo]);

  useEffect(() => {
    if (pagina === PAGINE.tornaLogin){
      setSessionId("");
      setIdentitaSelezionata([]); 
      setPagina(PAGINE.benvenuto);     
    }
  },[pagina]);

  if (pagina === "resettaPassword") {
    return (
      <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#000000"}}>
        <PaginaResetPassword
          emak={emak}
        />
      </div>
    )
  }

  if (identitaSelezionata.idpersona === undefined || identitaSelezionata.idpersona === '') {
    return (
      <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#000000"}}>
        <PaginaInserimentoCredenziali
          emak={emak}
          setSessionId={setSessionId}        
          setIdentitaSelezionata={setIdentitaSelezionata}
        />
      </div>
    )
  }

  return (
    <div id="principale" style={{height: "99.9vh", width:"100vw", backgroundColor:"#000000"}}>
      <div style={{height:dimensioniApp.yTitolo, display:"flex", borderBottom: "1px solid #505050"}}>
        <div style={{backgroundColor:"#212a2f", cursor:"pointer"}} onClick={() => {setFlagMenuVisibile(!flagMenuVisibile) }}>
          <img src={imgMenu} height={dimensioniApp.yTitolo} width={dimensioniApp.yTitolo} alt=""/>
        </div>
        <div style={{backgroundColor:"#212a2f", cursor:"pointer"}} onClick={() => { setChiudiTuttiSottomenu(true);setPagina(PAGINE.benvenuto) }}>
          <img src={imgCasa} height={dimensioniApp.yTitolo} width={dimensioniApp.yTitolo} alt=""/>
        </div>
        <div style={{fontSize:dimensioniApp.yTitolo/2.8, backgroundColor: "#E0E0E0", flex:1, textAlign:'center', display:'flex', flexDirection:"column"}}>
          <div style={{fontSize:dimensioniApp.yTitolo/2.4}}>{identitaSelezionata.societa}</div>          
          <div style={{fontSize:dimensioniApp.yTitolo/3.7, marginTop:-2}}>GestioneSportiva.it</div>
        </div>
        {fileLogo !== "" ? 
          <div style={{backgroundColor: "#FFFFFF", borderLeft:"1px solid #505050"}}>
            {typeLogo === "application/pdf" || typeLogo === "text/plain" ? 
              <embed style={{maxHeight:dimensioniApp.yTitolo}} src={"data:" + typeLogo + ";base64," + fileLogo}  type={typeLogo}/>  
            :""}
            {typeLogo.substring(0, 5) === "image" ?
              <img style={{maxHeight:dimensioniApp.yTitolo}} src={"data:" + typeLogo + ";base64," + fileLogo} />
            :""}
          </div>
        :<></>}
      </div>

      <div style={{display:'flex', flexDirection:'row'}}>
        <Menu 
          identitaSelezionata={identitaSelezionata}
          dimensioniApp={dimensioniApp}
          flagVisibile={flagMenuVisibile}
          xMinimaPerMenuNonRidotto={xMinimaPerMenuNonRidotto}          
          flagMenuRidotto={flagMenuRidotto}
          setFlagMenuRidotto={setFlagMenuRidotto}
          PAGINE={PAGINE}
          pagina={pagina}
          setPagina={setPagina}
          setRicarica={setRicarica}
          chiudiTuttiSottomenu={chiudiTuttiSottomenu}
          setChiudiTuttiSottomenu={setChiudiTuttiSottomenu}
        />
        <div style={{flex:1, overflow:"auto", height:dimensioniApp.yMain, display:"flex", flexDirection:"column"}}>
        {ricarica ? <div></div> :
          <AppMain 
            setFlagAggiornaFileLogo={setFlagAggiornaFileLogo}
            dimensioniApp={dimensioniApp}
            identitaSelezionata={identitaSelezionata}
            emak={emak}
            PAGINE={PAGINE}
            pagina={pagina}
            setPagina={setPagina}
            sessionId={sessionId}
          />
        }
        </div>
      </div>

                  
      <div style={{position:'absolute', left:10, bottom:1, fontSize:7, color:"#909090"}}>{Parametri("release")}</div>


    </div>
  )
}

export default App;
