import React, {useState,useEffect,useRef, Fragment} from 'react';
import {Input, Form, FormGroup} from 'reactstrap';

function TendinaOrariAStep(props) {
    //in input ho:
    //props.orarioInizio = "08:30" //valore opzionale: è la partenza della tendina; se non viene passata parte in automatico da 00:00
    //props.orarioFine = "12:30" //valore opzionale: è il termine della tendina; se non viene passata arriva in automatico fino a 23:59
    //props.step = "10" //valore opzionale: è lo step espresso in minuti tra un orario ed il successivo; se non viene passata utilizza il valore di default 1 ovvero un minuto di step tra i valori
    //props.orarioDefault= "09:30" //valore opzionale
 
    //in output fornisco:
    //props.callbackSetVariazioneCampi(1); qualora andassi a modificare (anche cancellandolo) o ad aggiungere un valore
    //props.callbacksetOrarioSelezionato mi darà l'orario selezionato dall'utente
    
    const [orarioDefault,setOrarioDefault]=useState([]);
    const orario   = useRef();

    const [arrayOrari,setArrayOrari]=useState([]);

    useEffect(() => {
        if(props.orarioInizio && props.orarioFine && props.step && props.orarioDefault){
            setOrarioDefault(props.orarioDefault);
            calcolaArray(props.orarioInizio,props.orarioFine,props.step);//compone l'array con tutti gli orari che andranno in tendina
            props.callbacksetOrarioSelezionato(props.orarioDefault,props);//passo alla pagina principale nel caso in cui non vi sia alcuna modifica mi riporta i dati originali
        }
    },[props.orarioDefault,props.orarioInizio,props.orarioFine])

    function handleCambiamentoMod(orario){
        props.callbacksetOrarioSelezionato(orario,props);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 
        props.callbackSetVariazioneCampi(1);
    }

    function calcolaArray(min="00:00",max="24:00",intervallo=1){

        //fornire step in minuti
        let blocca = 0;
        let minutiMin=0;
        let minutiMax= 0;
        const arrMin = min.split(':');
        const arrMax = max.split(':');
        minutiMin = parseInt(arrMin[1]) + parseInt(arrMin[0]*60);
        minutiMax = parseInt(arrMax[1]) + parseInt(arrMax[0]*60);
        intervallo = parseInt(intervallo);

        //let orari = [...arrayOrari];//popolo l'array locale con i dati dell'array di stato
        let orari = [];//no, deve essere vuoto altrimenti raddoppia gli orari

        let step = minutiMin; //il priumo step pèarte dal limite min che è l'orario minimo concesso


        let contatoreSicurezza = 0;
        while (blocca === 0 && contatoreSicurezza < 1500) {
            let ora = 0;
            let minuto = 0;
            let stepOre = 0;
            let stepMinuti = 0;
            stepOre = Math.floor(step/60);
            stepMinuti = step - stepOre * 60;
            if (stepOre<=9) ora = "0"+stepOre.toString();
            else ora = stepOre.toString();
            if (stepMinuti<=9) minuto = "0"+stepMinuti.toString();
            else minuto = stepMinuti.toString();

            step = step + intervallo;//incremento per step successivo
            //console.log("successivo"+step)
            if(step>1440) blocca = 1;
            contatoreSicurezza = contatoreSicurezza + 1;
        
            orari.push(ora+":"+minuto);//aggiungo un elemento (per ora vuoto) all'array locale
        }
        setArrayOrari(orari);//aggiorno nuovamente la var di stato 
    }
    return (
        <Fragment>
        <FormGroup>
     
        
        <select 
        className="inputStandard" 
        onChange={(e) => {handleCambiamentoMod(e.target.value);setOrarioDefault(e.target.value)}}>
            {arrayOrari.map((elemento,index)=>
                <>
                {arrayOrari[index] === orarioDefault ? <option value={arrayOrari[index]} selected="selected">{arrayOrari[index]}</option>
                : <option value={arrayOrari[index]}>{arrayOrari[index]}</option>}
                </>
                )
            }
        </select>


        
    
        </FormGroup>
        </Fragment>
   );
}
export default TendinaOrariAStep;
