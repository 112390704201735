import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Label,Input,Modal } from 'reactstrap';

import Parametri from '../../parametri';
import arrayColoriGrafico from '../../coloriGrafici';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

//per grafici:
//import {Bar} from 'react-chartjs-2';
//import {Pie} from 'react-chartjs-2';

//possibili grafici(possible exports: Bar, Bubble, Chart, Doughnut, Line, Pie, PolarArea, Radar, Scatter, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent)

import {Bar} from 'react-chartjs-2';//istogramma a barre verticali
import {Doughnut} from 'react-chartjs-2';//un grafico a torta con stile ciambella
//import {HorizontalBar} from 'react-chartjs-2';//istogramma a barre orizzontali
import {Line} from 'react-chartjs-2';//grafico con linea su piano cartesiano
import {Pie} from 'react-chartjs-2';//la classica torta
import {PolarArea} from 'react-chartjs-2';//tipo torta ma con un aspetto grafico più ricercato e tecnologico
import {Radar} from 'react-chartjs-2';//tipo radar con ai vertici le voci; è possibile rappresentare più dataset
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

//per pdf:
//import html2canvas from "html2canvas";//(tentativo 1)
//import { jsPDF } from "jspdf";//(tentativo 1)

//import html2pdf from 'html2pdf.js'//npm install --save html2pdf.js (https://github.com/eKoopmans/html2pdf.js) (tentativo 2)

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import styles from '../stiliPdf.js'

import { calcolaAnnoOggi } from '../../funzioni.js';

//import Canvas from '../Canvas.js';

//import html2canvas from 'html2canvas';

//import PDF, { Text, AddPage, Image, Html } from 'jspdf-react' //npm install --save jspdf-react (tentativo 3) https://www.npmjs.com/package/jspdf-react provato nom non và!

/*const dataProva = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
      {
          label: 'My First dataset',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: [65, 59, 80, 81, 56, 55, 40]
      }
  ]
};*/


//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;


const COLORE_TITOLI_REPORT="#a5a5a4";

function TabellaReportRimborsi(props) {


  /*var draw = (context) => {
    context.fillStyle = "rgb(200, 0, 0)";
    context.fillRect(10, 10, 50, 50);
  
    context.fillStyle = "rgba(0, 0, 200, 0.5)";
    context.fillRect(30, 30, 50, 50);
  }*/

  
    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);

    const [ricercaCompletata,setRicercaCompletata]=useState(0);

    const rimborsiGraficoGiorniCanvas = useRef();
    const rimborsiGraficoCanvas = useRef();

    /*const [coloriGraficoSfondoIva,setColoriGraficoSfondoIva]=useState(['']);
    const [coloriGraficoBordoIva,setColoriGraficoBordoIva]=useState(['']);
    const [coloriGraficoSfondoNetti,setColoriGraficoSfondoNetti]=useState(['']);
    const [coloriGraficoBordoNetti,setColoriGraficoBordoNetti]=useState(['']);*/

    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);
    const [topDefault,setTopDefault]=useState('10');
    const top = useRef();

    const coloriGraficoSfondoGiorni=arrayColoriGrafico;
    const coloriGraficoSfondo=arrayColoriGrafico;

    const coloriGraficoBordo='rgb(0,0,0)';
    const coloriGraficoBordoGiorni='rgb(0,0,0)';
    const larghezzaBordoGrafico=1;
    
    const larghColonnaDati=2;
    const larghColonnaIscritti=8;//modificato da 4 a 8 in seguito all'eliminazione delle colonne iva e imponibili
    
    /*const flagVisualizzaImportiNetti   = useRef();
  const flagVisualizzaImportiIva   = useRef();*/
    //const flagVisualizzaImportiLordi   = useRef();
    //const flagVisualizzaGiorni   = useRef();

    /*const [flagVisualizzaImportiNettiDefault,setflagVisualizzaImportiNettiDefault]=useState(1);
const [flagVisualizzaImportiIvaDefault,setflagVisualizzaImportiIvaDefault]=useState(1);*/
    const flagVisualizzaImportiLordiDefault=1;
    const flagVisualizzaGiorniDefault=1;

    const  radioPeriodo = useRef();
    const [radioPeriodoDefault,setRadioPeriodoDefault]=useState("1");

    const operazione = "reportRimborsi";

    //const  intervallo = useRef();
    const  dataPartenza = useRef();
    
    const [risultatoServer,setRisultatoServer]=useState('');

    //const [entiScadenza,setEntiScadenza]=useState('');
    
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    //const [dataPartenzaDefault,setDataPartenzaDefault]=useState(today.getFullYear());
  
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    //per grafici:
    /*const [dataRimborsiNetti,setDataRimborsiNetti]=useState(['']);
const [dataRimborsiIva,setDataRimborsiIva]=useState(['']);*/
    const [dataRimborsi,setDataRimborsi]=useState([]);
    const [dataRimborsiGiorni,setDataRimborsiGiorni]=useState([]);

    //DATI DA VISIONARE:
    const [arrayIscritti,setArrayIscritti]=useState([]);
    const [arrayRimborsiImporti,setArrayRimborsiImporti]=useState([]);
    /*const [arrayRimborsiImportiNetti,setArrayRimborsiImportiNetti]=useState(['']);
const [arrayRimborsiImportiIva,setArrayRimborsiImportiIva]=useState(['']);*/
    const [arrayRimborsiGiorni,setArrayRimborsiGiorni]=useState([]);

    const [statoTotaleRimborsiAnno,setStatoTotaleRimborsiAnno]=useState(0);
    /*const [statoTotaleRimborsiNettiAnno,setStatoTotaleRimborsiNettiAnno]=useState(0);
const [statoTotaleRimborsiIvaAnno,setStatoTotaleRimborsiIvaAnno]=useState(0);*/
    const [statoTotaleRimborsiGiorniAnno,setStatoTotaleRimborsiGiorniAnno]=useState(0);

    //consensi visualizzazione grafici:
    const [visualizzaRimborsi,setVisualizzaRimborsi]=useState(1);

    const [graficoImporti,setGraficoImporti]=useState(0);
    const [graficoGiorni,setGraficoGiorni]=useState(0);

    //tipologie di grafici per ogni sezione:
    const [tipoGraficoRimborsi,setTipoGraficoRimborsi]=useState("Bar");

    //consensi visualizzazione tabelle:
    const [visualizzaTab,setVisualizzaTab]=useState(1);

    const [arrayIscrittiTopImporti,setArrayIscrittiTopImporti]=useState([]);
    const [arrayRimborsiTopImporti,setArrayRimborsiTopImporti]=useState([]);
    const [arrayIscrittiTopGiorni,setArrayIscrittiTopGiorni]=useState([]);
    const [arrayRimborsiTopGiorni,setArrayRimborsiTopGiorni]=useState([]);

    const [visualizzaAltro,setVisualizzaAltro]=useState('0');
    const  visualizzaAltroRif = useRef();
    
    //const [visualizzaInPdf,setVisualizzaInPdf]=useState(false);

    //nomi dei tipi di grafici: (se cambi vai a modificare anche le select dei tipi che ora sono in ordine alfabetico secondo quete deciture)
    let descriDoughnut = "Ciambella";
    let descriLine = "Funzione";
    //let descriHorizontalBar = "Istogramma orizzontale";
    let descriBar = "Istogramma verticale";
    let descriPolarArea = "Polare";
    let descriRadar = "Radar";
    let descriPie =  "Torta";

    const  TGRimborsi = useRef();

    //modale per attivar le impostazioni:
    const [modaleImpostazioni, setModaleImpostazioni] = React.useState(false);

    /*const styles = StyleSheet.create({
      page: {flexDirection: 'row',paddingBottom: 50,paddingTop: 50,},
      titolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1, fontSize: 18},
      titoloPiccolo: {fontFamily: 'Helvetica-Bold',marginLeft: 10, fontSize: 10},
      image: {height: 150,width: 200},
      logo: {height: 60,width: 80,objectFit: 'contain'},
      nomesocieta: {fontFamily: 'Helvetica-Bold',marginLeft: 1,marginBottom: 5, fontSize: 22,},
      viewer: {height: '100vh',width: '150vh'},

      table: {display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0,margin: 10, }, 
      tableIntestazione: { display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0,margin: 10,}, 
      tableRowGrigia: { margin: "auto", flexDirection: "row",height: "auto",backgroundColor : "silver",}, 
      tableRow: { margin: "auto", flexDirection: "row",height: "auto",}, 
      tableRowIntestazione: { margin: "auto", flexDirection: "row",height: 60,}, 
      tableRowBlu: { margin: "auto", flexDirection: "row",backgroundColor : "#99cbff",height: "auto",}, 
      tableColIntestazioneSx: { width: 100/3+"%", borderLeftWidth: 0, borderTopWidth: 0},
      tableColIntestazioneDx: { width: 2*100/3+"%", borderLeftWidth: 0, borderTopWidth: 0 },
      tableCol: { width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
      tableCell: { margin: "auto", fontSize: 10, },
      tableCellIntestazione: { marginLeft: 0,fontSize: 10},
      tableCellGrassettoAllineatoSinistra: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoDestra: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellAllineatoSinistra: {textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellAllineatoDestra: {textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},

      grafici: {width: 300,height: 300,},
      graficiLunghi: {width: 400,height: 200,},
      pageNumber:{position: "absolute",fontSize: 12,bottom:30,left:0,right:0,textAlign:'center',color:"gray"}
    });*/

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [cambiamento,setCambiamento] = useState(0);
    const [ricercaImpostazioniReport,setRicercaImpostazioniReport] = useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
      if(ricercaCompletata === 1
        && dataRimborsi
        && dataRimborsiGiorni 
        && ricercaLogo === 1
        && ricercaImpostazioniReport === 1
        ){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
        setRicercaCompletata(0);
        //setRicercaLogo(0);
      }
    },[ricercaCompletata,ricercaLogo,ricercaImpostazioniReport])

    /*useEffect(() => {
      if(visualizzaInPdf === true){
        setStatoPagina(STATO_SCARICA_PDF);
      }
    },[visualizzaInPdf]);*/

    useEffect(() => {
      if(statoPagina === STATO_PREPARA_PDF){
          setStatoPagina(STATO_SCARICA_PDF);
      }
    },[statoPagina]);

    useEffect(() => { 
      //grafico Rimborsi
      /*setDataRimborsiNetti({labels: [''],
          datasets: [{label: 'Rimborsi (Imponibili)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataRimborsiIva({labels: [''],
          datasets: [{label: 'Rimborsi (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
    hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});*/

      setDataRimborsi({labels: [''],
          datasets: [{label: 'Rimborsi (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataRimborsiGiorni({labels: [''],
          datasets: [{label: 'Rimborsi (Numero Giorni Prestazione)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      scaricaLogo();
      trovaImpostazioniReport();

      },[])
/*
    useEffect(() => {
      //grafico Rimborsi Importi Netti
      if(arrayIscritti.length>0){
      setDataRimborsiNetti({labels: arrayIscritti,
      datasets: [
        {
          label: 'Imponibili Rimborsi',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: 'rgba(152,251,152,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayRimborsiImportiNetti
      },
      ]});
    }
    },[arrayRimborsiImportiNetti]);

    useEffect(() => {
      //grafico Rimborsi Importi Iva
      if(arrayIscritti.length>0){
      setDataRimborsiIva({labels: arrayIscritti,
      datasets: [
        {
          label: 'IVA Rimborsi',
          backgroundColor: coloriGraficoSfondoIva,
          borderColor: coloriGraficoBordoIva,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: 'rgba(255,105,180,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayRimborsiImportiIva
      },
      ]});
    }
    },[arrayRimborsiImportiIva]);
  */


    useEffect(() => {
      //grafico Rimborsi Importi Lordi
      if(arrayIscrittiTopImporti.length>0){
      setDataRimborsi({labels: arrayIscrittiTopImporti,

      datasets: [
        {
          label: '',
          backgroundColor :  coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayRimborsiTopImporti,
      },
      ]});
    }
    },[arrayRimborsiTopImporti]);

    useEffect(() => {
      //grafico Rimborsi Giorni
      if(arrayIscrittiTopGiorni.length>0){
      setDataRimborsiGiorni({labels: arrayIscrittiTopGiorni,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoGiorni,
          borderColor: coloriGraficoBordoGiorni,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayRimborsiTopGiorni
      },
      ]});
    }
    },[arrayRimborsiTopGiorni]);

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI && ricercaImpostazioniReport===1){
        setGraficoImporti(0);
        setGraficoGiorni(0);

        // parametri per inviare i dati al server:
        //console.log("RICERCA");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            visualizzaAltro:visualizzaAltro,
            sogliaTop:topDefault,
            dataPartenza:dataPartenzaDefault,
            radioPeriodo:radioPeriodoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              
              //console.log("RISULTATO"+valoreDiRitorno.risultatoOperazione);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale1=[];//Rimborsi -> iscritti
                let arrLocale2=[];//Rimborsi -> importi lordi
                /*let arrLocale3=[];//Rimborsi -> importi netti
                let arrLocale4=[];//Rimborsi -> importi iva*/
                let arrLocale5=[];//Rimborsi -> giorni

                let totaleRimborsiAnno = 0;
                /*let totaleRimborsiNettiAnno = 0;
                let totaleRimborsiIvaAnno = 0;*/
                let totaleRimborsiGiorniAnno = 0;

                let arrLocale1TopImporti=[];
                let arrLocale5TopImporti=[];
                let arrLocale1TopGiorni=[];
                let arrLocale5TopGiorni=[];

                for (let i=0; i<valoreDiRitorno.risultatoRitorno[0].dati.length ; i++) {
                  arrLocale1[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].ISCRITTO;
                  setArrayIscritti(arrLocale1);
                  arrLocale2[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI;
                  setArrayRimborsiImporti(arrLocale2);
                  /*arrLocale3[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_RIMBORSI;
                  setArrayRimborsiImportiNetti(arrLocale3);
                  arrLocale4[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IVA_RIMBORSI;
                  setArrayRimborsiImportiIva(arrLocale4);*/
                  arrLocale5[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].GIORNI_RIMBORSI;
                  setArrayRimborsiGiorni(arrLocale5);
                  //totali:
                  totaleRimborsiAnno = totaleRimborsiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_RIMBORSI);
                  /*totaleRimborsiNettiAnno = totaleRimborsiNettiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_RIMBORSI);
                  totaleRimborsiIvaAnno = totaleRimborsiIvaAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IVA_RIMBORSI);*/
                  totaleRimborsiGiorniAnno = totaleRimborsiGiorniAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].GIORNI_RIMBORSI);
                }
                for (let i1=0; i1<valoreDiRitorno.risultatoRitorno[1].topImporti.length ; i1++) {
                  arrLocale1TopImporti[i1]=valoreDiRitorno.risultatoRitorno[1].topImporti[i1].ISCRITTO;
                  setArrayIscrittiTopImporti(arrLocale1TopImporti);
                  arrLocale5TopImporti[i1]=parseFloat(valoreDiRitorno.risultatoRitorno[1].topImporti[i1].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[1].topImporti[i1].IMPORTI) !== 0) setGraficoImporti(1);
                  setArrayRimborsiTopImporti(arrLocale5TopImporti);
                }
                for (let i2=0; i2<valoreDiRitorno.risultatoRitorno[2].topGiorni.length ; i2++) {
                  
                  arrLocale1TopGiorni[i2]=valoreDiRitorno.risultatoRitorno[2].topGiorni[i2].ISCRITTO;
                  setArrayIscrittiTopGiorni(arrLocale1TopGiorni);
                  arrLocale5TopGiorni[i2]=valoreDiRitorno.risultatoRitorno[2].topGiorni[i2].GIORNI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[2].topGiorni[i2].GIORNI) !== 0) setGraficoGiorni(1);
                  setArrayRimborsiTopGiorni(arrLocale5TopGiorni);
                }
                

                setInizioPeriodo(valoreDiRitorno.risultatoRitorno[3].periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.risultatoRitorno[3].periodo[0].DATA_FINE_PERIODO);
                
                //ora setto le var di stato dei totali:
                setStatoTotaleRimborsiAnno(totaleRimborsiAnno);
                /*setStatoTotaleRimborsiNettiAnno(totaleRimborsiNettiAnno);
                setStatoTotaleRimborsiIvaAnno(totaleRimborsiIvaAnno);*/
                setStatoTotaleRimborsiGiorniAnno(totaleRimborsiGiorniAnno);

                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
                setRicercaCompletata(1);
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina,ricercaImpostazioniReport])
    //},[props.numPagina])

    useEffect(() => {
      if(necessitaRicalcolo===1){
        //resetto tutte le var di stato:
        setArrayIscritti(['']);
        setArrayRimborsiImporti(['']);
        /*setArrayRimborsiImportiNetti(['']);
        setArrayRimborsiImportiIva(['']);*/
        setArrayRimborsiGiorni(['']);

        setStatoTotaleRimborsiAnno(0);
        /*setStatoTotaleRimborsiNettiAnno(0);
        setStatoTotaleRimborsiIvaAnno(0);*/
        setStatoTotaleRimborsiGiorniAnno(0);

        setStatoPagina(STATO_RICERCA_DATI);   
        setNecessitaRicalcolo(0);
      }
    },[necessitaRicalcolo])

    function trovaImpostazioniReport() {
      //vado a fare la fetch di ricerca delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          ricercaImpostazioni : 1,
          report : 1,
          emak: props.emak,
          sessionId: props.sessionId})
      };

      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              setVisualizzaTab(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB));
              setVisualizzaRimborsi(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_RIMBORSI));
              setTipoGraficoRimborsi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_RIMBORSI);

              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
              setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    ); 
    }

    function aggiornaImpostazioniReport() {
      setCambiamento(0);
      //vado a fare la fetch di aggiornamento delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          modificaImpostazioni : 1,
          report : 1,
          visualizzaTabella:visualizzaTab,
          visualizzaGrafici:visualizzaRimborsi,
          tipoGrafici:tipoGraficoRimborsi,
          periodoConsiderato:radioPeriodoDefault,
          elementiSignificativi:topDefault,
          visualizzaAltro:visualizzaAltro,
          emak: props.emak,
          sessionId: props.sessionId})
      };

      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1 && valoreDiRitorno.risultatoRitorno){
              setVisualizzaTab(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB);
              setVisualizzaRimborsi(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_RIMBORSI);
              setTipoGraficoRimborsi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_RIMBORSI);
              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
            
              //setRicercaImpostazioniReport(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );


    }

    function chiudiModaleImpostazioni() {
      setModaleImpostazioni(false);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
      if(cambiamento === 1) aggiornaImpostazioniReport();
      //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
    }
    function ResettaImpostazioni() {
      setTipoGraficoRimborsi("Bar");
    }

    function funzioneTrasformaDateAUmano(data) {
      var dataOutput='';
      //da formato aaaa-mm-gg a gg/mm/aaaa
      if(data){
          const arrdata = data.split('-');
          let anno = arrdata[0];
          let mese = arrdata[1];
          let giorno = arrdata[2];
          dataOutput = giorno+"/"+mese+"/"+anno;
      }else dataOutput = '';
  
      return dataOutput;
   
  }
  function scaricaLogo() {
    //console.log("scaricaLogo");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
              }
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

  return (
      <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>

      {statoPagina === STATO_SCARICA_PDF ? 
        <div>
          {/*<PDFViewer style={styles.viewer}>*/}
          <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
          <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={{width:"100%"}} >
                  <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                    <View wrap={false} style={styles.tableRowIntestazione}> 
                      <View style={styles.tableColIntestazioneSx}> 
                        <View style={styles.tableCell}> 
                          {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}
                          
                        </View> 
                      </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                          capSocieta !== "" ?
                          " "+capSocieta+" "+cittaSocieta
                          :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                          <Text style={styles.titolo}>
                            Report Rimborsi {dataPartenzaDefault} 
                          </Text>
                          
                        </View> 
                      </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View>
                    <Text style={styles.titoloPiccolo}>
                      Periodo dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
                    </Text>
                  </View>
                    
                  {visualizzaTab === 1 ?
                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol3}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Iscritto</Text> 
                      </View> 
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo Rimborsi</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaGiorniDefault === 1 ?
                        <View style={styles.tableCol3}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>Numero Giorni Prestazione</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>
                    
                    {arrayIscritti.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={styles.tableCol3}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayIscritti[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol3}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayRimborsiImporti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaGiorniDefault === 1 ?
                        <View style={styles.tableCol3}>
                          <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(arrayRimborsiGiorni[index])}</Text>
                        </View>  
                        :<Text></Text>}                    
                    </View>)}
                   

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={styles.tableCol3}>
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={styles.tableCol3}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleRimborsiAnno)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaGiorniDefault === 1 ?
                          <View style={styles.tableCol3}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleRimborsiGiorniAnno)}</Text>
                          </View> 
                        :<Text></Text>}      
                    </View> 
                  </View>
                  :""}
                
                  {flagVisualizzaImportiLordiDefault === 1 && rimborsiGraficoCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                        <Image style={tipoGraficoRimborsi === "Line" || tipoGraficoRimborsi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + rimborsiGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}

                  {flagVisualizzaGiorniDefault === 1 && rimborsiGraficoGiorniCanvas.current?
                    <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                      <Image style={tipoGraficoRimborsi === "Line" || tipoGraficoRimborsi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + rimborsiGraficoGiorniCanvas.current.canvas.toDataURL("image/png")}/>
                  </View>:<Text></Text>}

                </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            </Page>
          </Document>
        </PDFViewer>
      </div>

      :""}

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Rep_Rim</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}
      {statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
            <Clessidra loading={true}/>
          </div> 
        </div> :""}
        
        {/*<div style={{minHeight: '100%'}}> */}
      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
           <div className="schedaSpazioCentrale" style={{minHeight: '100%',display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
            <Row>
              <Col lg="12">
                    <Card className="main-card mb-3" >
                    <CardBody>
                            <FormGroup>
                            <div id="reportIntestazione">
                            <CardTitle>
                            Report Rimborsi &nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                              {[...Array(11)].map((elemento, indice) => (
                                <option key={indice} value={annoOggi + 1 - indice}>
                                  {annoOggi + 1 - indice}
                                </option>
                              ))}                              
                            </select>      
                            &nbsp; dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>                  
                            </CardTitle> 
                              <Button color="success" className="bottoneMargineASinistra bottoneMargineADestra" onClick={() => {setModaleImpostazioni(true);}}>Impostazioni</Button>
                              {/*<Button color="success" className="mt-2 bottoneMargineASinistra" onClick={(e)=>{handleEsportaPDF(e,dataPartenzaDefault,inizioPeriodo,finePeriodo);}}>Esporta il PDF</Button>*/}
                              <Button color="success" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
                            

                            <div id='idDivGenerazionePdf' style={{display:'none'}}>Generazione PDF in corso...</div>

                            {modaleImpostazioni === true ?
                            <Modale 
                            larghezza = "lg"
                            titolo="Impostazioni layout report rimborsi"
                            flagErrore={false}
                            contenuto={<div>
                              
                                <CardBody>
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>
                                                    
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi e Numero Giorni Prestazione</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTab" 
                                      defaultChecked = {visualizzaTab === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTab === 1 ? setVisualizzaTab(0) : setVisualizzaTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaRimborsi" 
                                      defaultChecked = {visualizzaRimborsi === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaRimborsi === 1 ? setVisualizzaRimborsi(0) : setVisualizzaRimborsi(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGRimborsi" id="TGRimborsi" innerRef={TGRimborsi} onChange={(e)=>{setTipoGraficoRimborsi(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoRimborsi === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoRimborsi === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoRimborsi === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoRimborsi === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoRimborsi === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoRimborsi === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                </CardBody>
                                
                                <CardBody>
                                {/*settaggio tipo periodo*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col>
                                </Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(annoOggi)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </Col>
                                <Col>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(new Date().getFullYear())}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio Top N*/}
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                             
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(2);setCambiamento(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                            </div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {chiudiModaleImpostazioni()}
                                },    
                                {
                                  "etichetta":"Resetta Impostazioni Grafici",
                                  "tipo":"danger",
                                  callback:() => {ResettaImpostazioni();setCambiamento(1)}
                              },  
                            ]}
                            />
                            :""}

                            </div>
                            </FormGroup>

                            <div id="titolo" style={{display:"none"}}>
                            Report Rimborsi {dataPartenzaDefault} calcolato dal {funzioneTrasformaDateAUmano(inizioPeriodo)} al {funzioneTrasformaDateAUmano(finePeriodo)} 
                            </div>

                            {risultatoServer !== undefined && risultatoServer !== "" ?
                            <div id="reportRimborsiGlob">  
                                <div id="reportRimborsi"  style={{width:'100%'}}>
                                {visualizzaTab === 1 || visualizzaRimborsi === 1 ?
                                 <Row><Col><CardTitle>Riepilogo</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="Tabella"  style={{width:'100%'}}>  
                                  {((arrayRimborsiImporti.length>0 || arrayRimborsiGiorni.length>0))
                                  ?
                                    visualizzaTab === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col><b>Iscritto</b></Col>
                                    {/*{flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>Imponibili Rimborsi</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>IVA Rimborsi</b></Col> :""}*/}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo Rimborsi</b></Col> :""}
                                    {flagVisualizzaGiorniDefault === 1 ? <Col align="right"><b>Numero Giorni Prestazione</b></Col> :""}

                                    </Row>

                                    {arrayIscritti.map((elemento,index) => <Row form className="border" key={index} >
                                      {arrayIscritti[index]!=='' ? 
                                        <Col>{arrayIscritti[index]}</Col>
                                      : <Col>Non specificato</Col>}
                                      
                                      {/*{flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayRimborsiImportiNetti[index])}</Col> :""} 
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayRimborsiImportiIva[index])}</Col> :""}*/}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayRimborsiImporti[index])}</Col> :""}
                                      {flagVisualizzaGiorniDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayRimborsiGiorni[index])}</Col> :""}
                                      </Row>)
                                    }
                                    {/*riga dei totali in fondo alla tabella*/}
                                    <Row form className="border">
                                    <Col><b>TOTALE</b></Col>
                                    {/*{flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleRimborsiNettiAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleRimborsiIvaAnno)}</b></Col> :""}*/}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleRimborsiAnno)}</b></Col> :""}
                                    {flagVisualizzaGiorniDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleRimborsiGiorniAnno)}</b></Col> :""}
                                    </Row>

                                    </Fragment>
                                    :""
                                  : ""}
                                  
                                  </div>

                                  {/*
                                  {flagVisualizzaImportiNettiDefault === 1 ?
                                  <div id="rimborsiGraficoNetti" style={{width:'95%'}}>
                                  {tipoGraficoRimborsi==="Bar" && arrayRimborsiImportiNetti.length>0  && visualizzaRimborsi === 1?
                                    <Bar data={dataRimborsiNetti}  options={{  maintainAspectRatio: true  }} />: ""}
                                  {tipoGraficoRimborsi==="Doughnut" && arrayRimborsiImportiNetti.length>0 && visualizzaRimborsi === 1?
                                    <Doughnut data={dataRimborsiNetti} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="HorizontalBar" && arrayRimborsiImportiNetti.length>0 && visualizzaRimborsi === 1?
                                    <HorizontalBar data={dataRimborsiNetti} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Line" && arrayRimborsiImportiNetti.length>0 && visualizzaRimborsi === 1?
                                    <Line data={dataRimborsiNetti} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Pie" && arrayRimborsiImportiNetti.length>0 && visualizzaRimborsi === 1?
                                    <Pie data={dataRimborsiNetti} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Polar" && arrayRimborsiImportiNetti.length>0  && visualizzaRimborsi === 1?
                                    <Polar data={dataRimborsiNetti} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Radar" && arrayRimborsiImportiNetti.length>0 && visualizzaRimborsi === 1?
                                    <Radar data={dataRimborsiNetti} options={{  maintainAspectRatio: true  }} /> : ""}
                                  </div>
                                  :""}}*/}

{/*
                                  {flagVisualizzaImportiIvaDefault === 1 ?
                                  <div id="rimborsiGraficoIva" style={{width:'95%'}}>
                                  {tipoGraficoRimborsi==="Bar" && arrayRimborsiImportiIva.length>0  && visualizzaRimborsi === 1?
                                    <Bar data={dataRimborsiIva}  options={{  maintainAspectRatio: true  }} />: ""}
                                  {tipoGraficoRimborsi==="Doughnut" && arrayRimborsiImportiIva.length>0 && visualizzaRimborsi === 1?
                                    <Doughnut data={dataRimborsiIva} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="HorizontalBar" && arrayRimborsiImportiIva.length>0 && visualizzaRimborsi === 1?
                                    <HorizontalBar data={dataRimborsiIva} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Line" && arrayRimborsiImportiIva.length>0 && visualizzaRimborsi === 1?
                                    <Line data={dataRimborsiIva} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Pie" && arrayRimborsiImportiIva.length>0 && visualizzaRimborsi === 1?
                                    <Pie data={dataRimborsiIva} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Polar" && arrayRimborsiImportiIva.length>0  && visualizzaRimborsi === 1?
                                    <Polar data={dataRimborsiIva} options={{  maintainAspectRatio: true  }} /> : ""}
                                  {tipoGraficoRimborsi==="Radar" && arrayRimborsiImportiIva.length>0 && visualizzaRimborsi === 1?
                                    <Radar data={dataRimborsiIva} options={{  maintainAspectRatio: true  }} /> : ""}
                                  </div>
                                  :""}*/}

                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoRimborsi==="Bar" && arrayRimborsiTopImporti.length>0  && visualizzaRimborsi === 1?
                                    <div id="rimborsiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataRimborsi} ref={rimborsiGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                    
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoRimborsi==="Doughnut" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                    
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoRimborsi==="Line" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                    
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoRimborsi==="Pie" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Pie data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                    
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoImporti === 1 && tipoGraficoRimborsi==="PolarArea" && arrayRimborsiTopImporti.length>0  && visualizzaRimborsi === 1?
                                    <div id="rimborsiGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && tipoGraficoRimborsi==="Radar" && arrayRimborsiTopImporti.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGrafico" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataRimborsi} ref={rimborsiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Rimborsi (Top '+topDefault+')'}},}} /></div>: ""}
                                 
                                  {flagVisualizzaGiorniDefault === 1 && graficoGiorni === 1 && tipoGraficoRimborsi==="Bar" && arrayRimborsiTopGiorni.length>0  && visualizzaRimborsi === 1?
                                    <div id="rimborsiGraficoGiorni" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataRimborsiGiorni}  ref={rimborsiGraficoGiorniCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Numero Giorni Prestazione (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaGiorniDefault === 1 && graficoGiorni === 1 && tipoGraficoRimborsi==="Doughnut" && arrayRimborsiTopGiorni.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGraficoGiorni" style={{width:'50vh',margin: 'auto'}}>
                                    <Doughnut data={dataRimborsiGiorni} ref={rimborsiGraficoGiorniCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Numero Giorni Prestazione (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaGiorniDefault === 1 && graficoGiorni === 1 && tipoGraficoRimborsi==="Line" && arrayRimborsiTopGiorni.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGraficoGiorni" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataRimborsiGiorni} ref={rimborsiGraficoGiorniCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Numero Giorni Prestazione (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaGiorniDefault === 1 && graficoGiorni === 1 && tipoGraficoRimborsi==="Pie" && arrayRimborsiTopGiorni.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGraficoGiorni" style={{width:'50vh',margin: 'auto'}}><center>
                                    <Pie data={dataRimborsiGiorni}  ref={rimborsiGraficoGiorniCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Numero Giorni Prestazione (Top '+topDefault+')'}},}} /></center></div>: ""}
                                  {flagVisualizzaGiorniDefault === 1 && graficoGiorni === 1 && tipoGraficoRimborsi==="PolarArea" && arrayRimborsiTopGiorni.length>0  && visualizzaRimborsi === 1?
                                    <div id="rimborsiGraficoGiorni" style={{width:'50vh',margin: 'auto'}}>
                                    <PolarArea data={dataRimborsiGiorni} ref={rimborsiGraficoGiorniCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Numero Giorni Prestazione (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaGiorniDefault === 1 && graficoGiorni === 1 && tipoGraficoRimborsi==="Radar" && arrayRimborsiTopGiorni.length>0 && visualizzaRimborsi === 1?
                                    <div id="rimborsiGraficoGiorni" style={{width:'50vh',margin: 'auto'}}>
                                    <Radar data={dataRimborsiGiorni} ref={rimborsiGraficoGiorniCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Numero Giorni Prestazione (Top '+topDefault+')'}},}} /></div>: ""}
                              </div>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
      :""}
    </div>
   );
}
export default TabellaReportRimborsi;