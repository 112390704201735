import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setvariazioneCampi]=useState(0);

    const [flagAttivaModaleGruppoNonCancellabile, setFlagAttivaModaleGruppoNonCancellabile] = useState(0);
    const [flagAttivaModaleGruppoNonModificabile, setFlagAttivaModaleGruppoNonModificabile] = useState(0);
    const [flagAttivaModaleGruppoNonInseribile, setFlagAttivaModaleGruppoNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);

    const descrizione   = useRef();
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [risultatoServer,setRisultatoServer]=useState('');

    //let descrizioneDefault = "";

    const [descrizioneDefault,setDescrizioneDefault]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
    },[]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("gruppiIns");
        if(props.ope==='modifica') setOperazione("gruppiMod");
        if(props.ope==='cancella') setOperazione("gruppiCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"gruppiRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                        setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                        }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagAttivaModaleGruppoNonCancellabile(0);
        setFlagAttivaModaleGruppoNonModificabile(0);
        setFlagAttivaModaleGruppoNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setvariazioneCampi(1);
    }

    function fetchOperazioneServer(permessoCanc=0,bypassaControlli=0) {
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
        }

        if(controlli===0){ 
            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
            //console.log("eccolo"+permessoCanc);
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                setLoading(true);
                // parametri per inviare i dati al server:
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                        })
                        : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        permessoCanc:permessoCanc,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Gruppo non cancellabile: presente in persone") !== -1){
                                    setFlagAttivaModaleGruppoNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Gruppo non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleGruppoNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Gruppo non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleGruppoNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
             }
        }
    }

    //JSON.stringify(risultatoServer)
    /*defaultValue={{ label: {descrizioneGruppoDefault}, value: {gruppoDefault} }}*/
        return (
            <Fragment>
                
                {/*eccola {props.sessionId}*/}
                
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Gru {error.message} {JSON.stringify(risultatoServer)}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

           

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleGruppoNonCancellabile===1 ?
                        <Modale 
                            titolo="Informativa raggruppamento non cancellabile"
                            flagErrore={true}
                            contenuto={<div>Il raggruppamento risulta associato ad uno o più iscritti. 
                                Procedendo con la cancellazione verranno eliminate anche tutte le associazioni del raggruppamento in esame con gli iscritti</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);fetchOperazioneServer(1)}
                                },  
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }   
                            ]}
                        />
                    :""}

                    {flagAttivaModaleGruppoNonModificabile===1 ?
                        <Modale 
                            titolo="Informativa gruppo non modificabile"
                            flagErrore={true}
                            contenuto={<div>La descrizione del raggruppamento non è modificabile in {descrizione.current.value} poichè nel database è già presente un raggruppamento con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleGruppoNonInseribile===1 ?
                        <Modale 
                            titolo="Informativa gruppo non inseribile"
                            flagErrore={true}
                            contenuto={<div>Il raggruppamento {descrizione.current.value} non è inseribile in poichè nel database è già presente un raggruppamento con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                      
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Raggruppamento</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Raggruppamento</CardTitle> :""}
                      
                            
                            <Clessidra loading={loading}/>

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione del raggruppamento <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :


                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione Raggruppamento*</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input  maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value);}}/> 
                                            :""}
                                        </FormGroup>
                                    </Col>
                                </Row>

                            }
                               
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica")?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
  
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
