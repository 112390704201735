import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Resizer from "react-image-file-resizer";

import Parametri from '../../parametri';
import TabellaOrari from '../TabellaOrari.js';
import TabellaOrariIndisponibilita from '../TabellaOrariIndisponibilita.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';

import VisualizzaAllegato from '../VisualizzaAllegato.js';

import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import FinestraDialogo from '../FinestraDialogo.js';

import TendinaEditabile from '../TendinaEditabile.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    var now = today.toLocaleString();//ti restituisce roba tipo 9:30:00 PM
    let arrNow1 = now.split(",");
    let arrNow = arrNow1[1].split(":");
    let sOra = arrNow[0].trim();
    if(sOra.length === 1) sOra = "0"+sOra;
    let sMinuto = arrNow[1].trim();
    if(sMinuto.length === 1) sMinuto = "0"+sMinuto;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;

    const dataOggiCompleta = today.getFullYear()+'-'+sMese+'-'+sGiorno+"T"+sOra+":"+sMinuto;
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagModaleInfoFoto, setFlagModaleInfoFoto] = React.useState(false);
    const [flagModaleConfermaCancFoto, setFlagModaleConfermaCancFoto] = React.useState(false);
    const [flagAttivaModaleCorsoNonCancellabile, setFlagAttivaModaleCorsoNonCancellabile] = useState(0);
    const [flagAttivaModaleCorsoNonModificabile, setFlagAttivaModaleCorsoNonModificabile] = useState(0);
    const [flagAttivaModaleCorsoNonInseribile, setFlagAttivaModaleCorsoNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciNome, setFlagAttivaModaleInserisciNome] = useState(0);
    const [flagAttivaModaleDimensioneFoto, setFlagAttivaModaleDimensioneFoto] = useState(0);
    const [flagAttivaModaleInserisciDataAttivaDal, setFlagAttivaModaleInserisciDataAttivaDal] = useState(0);
    //const [flagAttivaModaleDurataTipicaTroppoElevata, setFlagAttivaModaleDurataTipicaTroppoElevata] = useState(0);
    const [flagAttivaModaleDurataMinimaTroppoElevata, setFlagAttivaModaleDurataMinimaTroppoElevata] = useState(0);
    const [flagAttivaModaleDurataMinimaErr, setFlagAttivaModaleDurataMinimaErr] = useState(0);
    
    const [fineSpazio, setFineSpazio] = useState(0);

    const nome   = useRef();
    const descrizione   = useRef();
    const maxPartecipanti   = useRef();
    const minPartecipanti   = useRef();
    const durataTipica   = useRef();
    const durataMinima   = useRef();
    const attivoDal   = useRef();
    const attivoAl   = useRef();
    const flagPrenotabileStaff   = useRef();
  

    const flagEliminaFotoRif   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    // orari di prenotabilità
    const [orariGiorni,setOrariGiorni]=useState([]);
    const [orariDalle,setOrariDalle]=useState([]);
    const [orariAlle,setOrariAlle]=useState([]);
    const [orariPosti,setOrariPosti]=useState([]);
    const [prenMin,setPrenMin]=useState([]);

    const [orariIndisponibilita,setOrariIndisponibilita]=useState([]);

    const [contenutoFile,setContenutoFile]=useState([]);
    const [dimensioneFoto,setDimensioneFoto]=useState('');
    const [dimensioneMassimaFoto,setDimensioneMassimaFoto]=useState('');
    const [flagEliminaFoto,setFlagEliminaFoto]=useState('');
    const [typeFoto,setTypeFoto]=useState('');
    const [estensioneFileFoto, setEstensioneFileFoto] = useState('');
    const [flagFotoModificata, setFlagFotoModificata] = useState(false);

    const [nomeDefault,setNomeDefault]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [maxPartecipantiDefault,setMaxPartecipantiDefault]=useState('');
    const [minPartecipantiDefault,setMinPartecipantiDefault]=useState('');
    //const [durataTipicaDefault,setDurataTipicaDefault]=useState(30);
    const [durataMinimaDefault,setDurataMinimaDefault]=useState(30);
    const [attivoDalDefault,setAttivoDalDefault]=useState(dataOggi);
    const [attivoAlDefault,setAttivoAlDefault]=useState('');

    const [flagPrenotabileStaffDefault,setFlagPrenotabileStaffDefault]=useState('');

    const [idAbbonamento,setIdAbbonamento]=useState(0);
    const nomeAbbonamentoVuoto   = "Nessuno";
    const [nomeAbbonamento,setNomeAbbonamento]=useState(nomeAbbonamentoVuoto);

    const [numeroMaxPartecipanti,setNumeroMaxPartecipanti]=useState(0);
    const [numeroMinPartecipanti,setNumeroMinPartecipanti]=useState(0);

    //const [risultatoVerificaDurate,setRisultatoVerificaDurate]=useState(-1);//risultato della verifica su durata tipica e durata minima
    
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [statoRicercaDati,setStatoRicercaDati]=useState(false);
    const [statoTendinaAbbonamento,setStatoTendinaAbbonamento]=useState(false);

    const [risultatoServerDatiAbbonamento,setRisultatoServerDatiAbbonamento]=useState([]);

    function parseStringaJson(str) {
        if (!str) {
          return []; // Ritorna un oggetto vuoto di tipo array se la stringa è vuota
        }
        try {
          return JSON.parse(str);
        } catch (error) {
          console.error('Stringa JSON non valida', error);
          return null; // Ritorna null o un valore appropriato in caso di errore
        }
    }

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
    },[]);

    useEffect(() => {
        if(statoRicercaDati === true && statoTendinaAbbonamento === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoRicercaDati,statoTendinaAbbonamento]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("corsiIns");
        if(props.ope==='modifica') setOperazione("corsiMod");
        if(props.ope==='cancella') setOperazione("corsiCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"corsiRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {  
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    setNumeroMaxPartecipanti(valoreDiRitorno.risultatoRitorno[0].MAX_PARTECIPANTI);
                    setNumeroMinPartecipanti(valoreDiRitorno.risultatoRitorno[0].MIN_PARTECIPANTI);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setTypeFoto(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setContenutoFile(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setEstensioneFileFoto(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FOTO);
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setMaxPartecipantiDefault(valoreDiRitorno.risultatoRitorno[0].MAX_PARTECIPANTI);
                    setMinPartecipantiDefault(valoreDiRitorno.risultatoRitorno[0].MIN_PARTECIPANTI);
                    //setDurataTipicaDefault(valoreDiRitorno.risultatoRitorno[0].DURATA_TIPICA);
                    setDurataMinimaDefault(valoreDiRitorno.risultatoRitorno[0].DURATA_MINIMA);
                    setAttivoDalDefault(valoreDiRitorno.risultatoRitorno[0].ATTIVO_DAL_GIORNO);
                    setAttivoAlDefault(valoreDiRitorno.risultatoRitorno[0].ATTIVO_AL_GIORNO);
                    setFlagPrenotabileStaffDefault(valoreDiRitorno.risultatoRitorno[0].FLAG_PRENOTABILE_STAFF);
                    setIdAbbonamento(valoreDiRitorno.risultatoRitorno[0].IDABBONAMENTO);
                    if(valoreDiRitorno.risultatoRitorno[0].IDABBONAMENTO != 0) setNomeAbbonamento(valoreDiRitorno.risultatoRitorno[0].NOME_ABBONAMENTO);
                    
                    setOrariIndisponibilita(valoreDiRitorno.risultatoRitorno[0].INDISPONIBILITA);
                  
                    let arrLocale=[];
                    let arrLocale1=[];
                    let arrLocale2=[];
                    let arrLocale3=[];
                    let arrLocale4=[];
                    for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                        if(valoreDiRitorno.risultatoRitorno[i].GIORNO_SETTIMANA!==''){
                            arrLocale[i]=valoreDiRitorno.risultatoRitorno[i].GIORNO_SETTIMANA;
                            arrLocale1[i]=valoreDiRitorno.risultatoRitorno[i].DALLE_ORE;
                            arrLocale2[i]=valoreDiRitorno.risultatoRitorno[i].ALLE_ORE;
                            arrLocale3[i]=valoreDiRitorno.risultatoRitorno[i].POSTI;
                            arrLocale4[i]=valoreDiRitorno.risultatoRitorno[i].PREN_MIN;
                            setOrariGiorni(arrLocale);
                            setOrariDalle(arrLocale1);
                            setOrariAlle(arrLocale2);
                            setOrariPosti(arrLocale3);
                            setPrenMin(arrLocale4);
                        }
                    } 
                    ricercaDatiAbbonamento();
                    setStatoRicercaDati(true);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            ricercaDatiAbbonamento();
            setStatoRicercaDati(true);
            //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[props.ope]);

    useEffect(() => { 
        if(flagEliminaFoto === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaFoto]);

    function callbacksetValoriAbbonamento(valore,pos,valoreNonVisibile){
        setNomeAbbonamento(valore);
        setIdAbbonamento(valoreNonVisibile);
    }

    function ricercaDatiAbbonamento() {
        //ricerco tra i Abbonamenti che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoAbbonamenti",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiAbbonamento(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaAbbonamento(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagModaleInfoFoto(false);
        setFlagModaleConfermaCancFoto(false);
        setFlagAttivaModaleCorsoNonCancellabile(0);
        setFlagAttivaModaleCorsoNonModificabile(0);
        setFlagAttivaModaleCorsoNonInseribile(0);
        setFlagAttivaModaleInserisciNome(0);
        setFlagAttivaModaleDimensioneFoto(0);
        setFlagAttivaModaleInserisciDataAttivaDal(0);
        //setRisultatoVerificaDurate(-1);
        //setFlagAttivaModaleDurataTipicaTroppoElevata(0);
        setFlagAttivaModaleDurataMinimaTroppoElevata(0);
        setFlagAttivaModaleDurataMinimaErr(0);
        setFineSpazio(0);
    }

    function scaricaFile(nomeFile, contenuto) {
        const link = document.createElement('a');
        link.href = contenuto;
        link.setAttribute('download',nomeFile);     
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();      
        // Clean up and remove the link
        link.parentNode.removeChild(link);
  }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function callbacksetOrari(orariGiorni,orariDalle,orariAlle,orariPosti,prenMin){
        setOrariGiorni(orariGiorni);
        setOrariDalle(orariDalle);
        setOrariAlle(orariAlle);
        setOrariPosti(orariPosti);
        setPrenMin(prenMin);
    }

    function callbackSetVariazioneCampi(variazioneCampi){
        handleCambiamentoMod();
    }

    function aggiornaFile(valore){
        setVariazioneCampi(1);
        let typeFile = '';
        if (valore.type.substring(0, 5) === "image") {
            var nuovoTipo = valore.type.substring(6);
            if (nuovoTipo.toUpperCase()!="JPG" && nuovoTipo.toUpperCase()!="JPEG" && nuovoTipo.toUpperCase()!="PNG") {
                nuovoTipo = "JPEG";
                typeFile = "image/jpeg";
            }else{
                typeFile = valore.type;
            }

            try {
                Resizer.imageFileResizer(
                    valore,
                    600,
                    600,
                    nuovoTipo,
                    100,
                    0,
                    (uri) => {
                        setContenutoFile(uri);
                    },
                    "base64"
                );                
            } catch (err) {
                // console.log(err);
                alert("Si è verificato un errore nel ridimensionamento dell'immagine, contattare l'assistenza tecnica");
            }
        } else {            
            setContenutoFile(valore)
        }
        const estensione = valore.name.split('.').pop();
        setFlagFotoModificata(true); setFlagEliminaFoto(""); setTypeFoto(typeFile); setEstensioneFileFoto(estensione);
    }

    function funzioneEliminaFoto(valore){
        //setVariazioneCampi(1);
        handleCambiamentoMod();
        setFlagEliminaFoto(valore);
        setTypeFoto('');
        setContenutoFile('');
    }
  

    function fetchOperazioneServer(bypassaControlli=0) {
        
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(nome.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciNome(1);
            }
            if(attivoDal.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDataAttivaDal(1);
            }
        }
        {/*
        if(durataTipica.current && durataMinima.current){
            //prima controllo il minite massimo per entrambe:
            if(parseInt(durataTipica.current.value) > 1440){
                controlli = 1;
                setFlagAttivaModaleDurataTipicaTroppoElevata(1);
            }
            if(parseInt(durataMinima.current.value) > 1440){
                controlli = 1;
                setFlagAttivaModaleDurataMinimaTroppoElevata(1);
            }
            //se ho passato questi controlli ne faccio di più approfonditi:
            if(controlli===0){ 
                let divisore = durataTipica.current.value;
                let dividendo = durataMinima.current.value;
                if(dividendo === 0) dividendo = 30; //è che se avessi impostato la mezzora
                if(divisore === 0 ) divisore = 30; //è che se avessi impostato la mezzora
                var resto = divisore % dividendo;
                if(resto!==0){
                    setRisultatoVerificaDurate(0);
                    controlli = 1;
                } else {
                    setRisultatoVerificaDurate(1);
                } 
            }
        }*/}
        {/*if(durataMinima.current){
            //la durata minima deve essere un multiplo di 5 minuti:
            let rem = durataMinima.current.value%5;
            if(durataMinima.current.value%5 !== 0){
                //setFlagAttivaModaleDurataMinimaErr(1);
                //controlli = 1;
            }   
        }*/}

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in realtà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                //aggiorno con post senza json:
                setLoading(true);

                const formData = new FormData();
                if (props.ope!=='cancella'){
                    formData.append('op', operazione);
                    formData.append('nome',nome.current.value);
                    if (flagFotoModificata) {
                        formData.append('contenutoFileFoto', contenutoFile);
                        formData.append('estensioneFileFoto', estensioneFileFoto);
                        formData.append('typeFileFoto', typeFoto);
                    }
                    formData.append('flagEliminaFoto', flagEliminaFoto);
                    formData.append('descrizione',descrizione.current.value);
                    //formData.append('durataTipica', durataTipica.current.value);
                    formData.append('durataMinima', durataMinima.current.value);
                    formData.append('maxPartecipanti', maxPartecipanti.current.value);
                    if(minPartecipanti.current) formData.append('minPartecipanti', minPartecipanti.current.value);
                    else formData.append('minPartecipanti', 0);
                    formData.append('attivoDal', attivoDal.current.value);
                    formData.append('attivoAl', attivoAl.current.value);

                    for (var i = 0; i < orariGiorni.length; i++) {
                        formData.append('orariGiorni[]', orariGiorni[i]);
                    }
                    for (var i1 = 0; i1 < orariDalle.length; i1++) {
                        formData.append('orariDalle[]', orariDalle[i1]);
                    }
                    for (var i2 = 0; i2 < orariAlle.length; i2++) {
                        formData.append('orariAlle[]', orariAlle[i2]);
                    }
                    for (var i3 = 0; i3 < orariPosti.length; i3++) {
                        formData.append('orariPosti[]', orariPosti[i3]);
                    }
                    for (var i4 = 0; i4 < prenMin.length; i4++) {
                        formData.append('prenMin[]', prenMin[i4]);
                    }
                    formData.append('flagPrenotabileStaff',flagPrenotabileStaff.current.checked);

                    formData.append('indisponibilita', JSON.stringify(orariIndisponibilita));
                    
                    formData.append('idElemento', props.id);
                    formData.append('emak', props.emak);
                    
                    formData.append('sessionId', props.sessionId);
                }else{
                    formData.append('op', operazione);
                    formData.append('idElemento', props.id);
                    formData.append('dataOggiCompleta', dataOggiCompleta);
                    formData.append('emak', props.emak);
                    formData.append('sessionId', props.sessionId);
                }
                formData.append('idAbbonamento', idAbbonamento);
                formData.append('nomeAbbonamento', nomeAbbonamento);

                const requestOptions = {
                    method: 'POST',
                    body: formData
                }
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Corso non cancellabile: presente in prenotazioni") !== -1){
                                    setFlagAttivaModaleCorsoNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Corso non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleCorsoNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Corso non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleCorsoNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Nome non valorizzato") !== -1){
                                    setFlagAttivaModaleInserisciNome(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data Attiva dal non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataAttivaDal(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione foto corso troppo elevata") !== -1){
                                    setFlagAttivaModaleDimensioneFoto(1);
                                    setDimensioneFoto(valoreDiRitorno.dimFoto);
                                    setDimensioneMassimaFoto(valoreDiRitorno.dimMaxFoto);
                                    setTypeFoto('');
                                    setContenutoFile('');
                                }
                                if(valoreDiRitorno.mess.indexOf("Spazio Terminato") !== -1){
                                    setFineSpazio(1);
                                }

                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(true);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }
    
    //console.log(props.dimensioniApp.yMain);
    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
         
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Cors {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {fineSpazio === 1 ?
                        <Modale 
                        titolo="Informativa spazio terminato"
                        flagErrore={true}
                        contenuto={<div>Lo spazio dati è terminato</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleDimensioneFoto=== 1 ?
                        <Modale 
                        titolo="Dimensione eccessiva foto"
                        flagErrore={true}
                        contenuto={<div>La foto ha una dimensione troppo elevata:<br/>rilevata {dimensioneFoto} KB, massima accettata {dimensioneMassimaFoto} KB</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciNome===1 ?
                        <Modale 
                            titolo="Richiesta nome"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato il nome, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleInserisciDataAttivaDal===1 ?
                        <Modale 
                            titolo="Richiesta Data Inizio Attivazione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data di inizio attivazione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleDurataMinimaTroppoElevata===1 ?
                        <Modale 
                            titolo="Durata Minima Troppo Elevata"
                            flagErrore={true}
                            contenuto={<div>La durata minima è troppo elevata: valore massimo accettato 1440 minuti</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleDurataMinimaErr===1 ?
                        <Modale 
                            titolo="Durata Minima Errata"
                            flagErrore={true}
                            contenuto={<div>La durata minima deve essere un multiplo di 5</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""} 



                    {/*{flagAttivaModaleDurataTipicaTroppoElevata===1 ?
                        <Modale 
                            titolo="Durata Tipica Troppo Elevata"
                            flagErrore={true}
                            contenuto={<div>La durata tipica è troppo elevata: valore massimo accettato 1440 minuti</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                        :""} */}

                    {flagAttivaModaleCorsoNonCancellabile===1 ?
                        <Modale 
                            titolo="Richiesta nome"
                            flagErrore={true}
                            contenuto={<div>La prestazione selezionata non è cancellabile in quanto associata ad uno o più prenotazioni</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaElenco()}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleCorsoNonModificabile===1 ?
                        <Modale 
                            titolo="Richiesta nome"
                            flagErrore={true}
                            contenuto={<div>Il nome della prestazione non è modificabile in {descrizione.current.value} poichè nel database è già presente una prestazione con lo stesso nome</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleCorsoNonModificabile===1 ?
                        <Modale 
                            titolo="Richiesta nome"
                            flagErrore={true}
                            contenuto={<div>La prestazione {descrizione.current.value} non è inseribile in poichè nel database è già presente una prestazione con lo stesso nome</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""} 

                    {/*{(props.ope === "inserisci" || props.ope === "modifica") && variazioneCampi === 1 && risultatoVerificaDurate === 0 ? 

                        <Modale 
                        titolo="Informativa durata minima errata"
                        flagErrore={true}
                        contenuto={<div>Errore nella compilazione delle durate: la durata minima deve essere sottomultiplo della durata tipica</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                        />
                    :""}*/}

                    {flagModaleInfoFoto === true ?
                        <Modale 
                            titolo="Foto della prestazione / attrezzatura"
                            flagErrore={false}
                            contenuto="La foto può essere inserita in formato: jpg, jpeg, gif, png, webp"
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleConfermaCancFoto === true ?
                    <Modale 
                        titolo="Conferma eliminazione"
                        flagErrore={false}
                        contenuto="Confermi l'eliminazione della foto?"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setFlagModaleConfermaCancFoto(false); funzioneEliminaFoto("on")}
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                            }
                        ]}
                    />
                    :""}

                    <div className="schedaSpazioCentrale">
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Prestazione / Attrezzatura Prenotabile</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Prestazione / Attrezzatura Prenotabile</CardTitle> :""}
                         
                            
                            <Clessidra loading={loading}/>

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della prestazione / attrezzatura prenotabile <b>{nomeDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :

            
                            <>
                            {/* div contenente dati della prestazione / attrezzatura e foto */}
                            <div style={{display:"flex", flexDirection:"row"}}>
                                {/* div contenente dati della prestazione / attrezzatura */}
                                <div style={{flex:1}}>
                                        <Row form>  
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="nome">Nome*</Label>
                                                    <Input maxLength={100} type="text" name="nome" id="nome"
                                                    innerRef={nome} 
                                                    placeholder=""
                                                    defaultValue={nomeDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setNomeDefault(nome.current.value);}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>  
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="descrizione">Note</Label>
                                                    <Input maxLength={255} type="text" name="descrizione" innerRef={descrizione}  id="descrizione"
                                                    placeholder=""
                                                    defaultValue={descrizioneDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setNomeDefault(descrizione.current.value);}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>  
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="maxPartecipanti">Numero Massimo Prenotazioni Contemporanee</Label>
                                                    <Input maxLength={11} type="text" name="maxPartecipanti" innerRef={maxPartecipanti}  id="maxPartecipanti"
                                                    placeholder=""
                                                    defaultValue={maxPartecipantiDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setMaxPartecipantiDefault(maxPartecipanti.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {props.identitaSelezionata.nome_database=='EMA48' ?
                                            <Row form> 
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label for="minPartecipanti">Numero Minimo Prenotazioni </Label>
                                                        <Input maxLength={11} type="text" name="minPartecipanti" innerRef={minPartecipanti}  id="minPartecipanti"
                                                        placeholder=""
                                                        defaultValue={minPartecipantiDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setMinPartecipantiDefault(minPartecipanti.current.value)}}/> 
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        :""}

                                        <Row form>  
                                            {/*<Col md={6}>
                                                <FormGroup>
                                                    <Label for="durataTipica">Durata Tipica [minuti]</Label>
                                                    <Input maxLength={11} type="text" name="durataTipica" innerRef={durataTipica} id="durataTipica"
                                                    placeholder="30"
                                                    defaultValue={durataTipicaDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setDurataTipicaDefault(durataTipica.current.value)}}/>
                                                </FormGroup>
                                            </Col>*/}
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="durataMinima">Durata Minima [minuti]</Label>
                                                    <Input maxLength={11} type="text" name="durataMinima" innerRef={durataMinima} id="durataMinima"
                                                    placeholder="30"
                                                    defaultValue={durataMinimaDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setDurataMinimaDefault(durataMinima.current.value)}}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="attivoDal">Attiva dal Giorno*</Label>
                                                    <Input maxLength={10} type="date" name="attivoDal" innerRef={attivoDal} id="attivoDal"
                                                    placeholder=""
                                                    defaultValue={attivoDalDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setAttivoDalDefault(attivoDal.current.value)}}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="attivoAl">Fino al Giorno</Label>
                                                    <Input maxLength={10} type="date" name="attivoAl" innerRef={attivoAl} id="attivoAl"
                                                    placeholder=""
                                                    defaultValue={attivoAlDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setAttivoAlDefault(attivoAl.current.value)}}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        



                                        <Row form>  
                                            <Col md={12}>
                                                <FormGroup>
                                                <Label>Abbonamento necessario:</Label>
                                                
                                                    <TendinaEditabile
                                                        //titolo={"Abbonamento*"}
                                                        nomeCampo="NOME"
                                                        nomeCampoNonVisibile ="ID"
                                                        valoreDefault={nomeAbbonamento}
                                                        arrayValori={risultatoServerDatiAbbonamento}
                                                        bloccoInserimento="1"
                                                        labelCampoVuoto={nomeAbbonamentoVuoto}
                                                        callbackSetVariazioneCampi={setVariazioneCampi}
                                                        callbackSetValoreTendEditabile={callbacksetValoriAbbonamento} 
                                                    ></TendinaEditabile>
                                        
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Input className="ml-2 mt-2" type="checkbox" name="flagPrenotabileStaff" id="flagPrenotabileStaff"
                                                    defaultChecked = {flagPrenotabileStaffDefault === "1" ?  'checked' :""}
                                                    innerRef={flagPrenotabileStaff}                                 
                                                    placeholder=""
                                                    onInput={(e)=>{handleCambiamentoMod(e);setFlagPrenotabileStaffDefault(e.target.value);}}/>  
                                                    <Label className="ml-4 mt-2" for="flagPrenotabileStaff">Prenotabile solo da Staff</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div> {/* fine div contenente dati prestazione / attrezzatura */}


                                    <div style={{paddingLeft:10}}> {/* div contenente foto*/}
                                            <Label for="foto">
                                                Foto Prestazione / Attrezzatura
                                                <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoFoto(true)}/>
                                            </Label>
                                            <VisualizzaAllegato
                                                cssContenitore="btnimg"
                                                cssImmagine="immagineAnagrafica"
                                                cssPdf="pdfAnagrafica"
                                                cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                                scalaPdf={props.dimensioniApp.x/3000}
                                                contenutoFile={contenutoFile}
                                                tipoFile={typeFoto}
                                                estensioneFile={estensioneFileFoto}
                                                estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp"}
                                                flagContenutoModificato={flagFotoModificata}
                                                callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile(e.target.files[0]);}}
                                                callbackScarica={() => scaricaFile("Foto." + estensioneFileFoto,contenutoFile)}
                                                callbackElimina={() => setFlagModaleConfermaCancFoto(true)}
                                            /> 
                                    </div>
                                </div>    

                                <div className='schedaSottotitolo'>
                                    Orari di Prenotabilità
                                </div>
                                {/*qui metto la tabella con gli orari giorno per giorno*/}
                                <Row form>  
                                    <Col md={12}>
                                        <TabellaOrari
                                        nomedb={props.identitaSelezionata.nome_database}
                                        maxPartecipantiDefault={maxPartecipantiDefault}
                                        minPartecipantiDefault={minPartecipantiDefault}
                                        callbacksetOrari={callbacksetOrari} 
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi} 
                                        orariGiorniDefault={orariGiorni}
                                        orariDalleDefault={orariDalle}
                                        orariAlleDefault={orariAlle}
                                        orariPostiDefault={orariPosti}
                                        prenMinDefault={prenMin}
                                        durataMinima = {durataMinimaDefault}
                                        ></TabellaOrari>
                                    </Col>
                                </Row>

                                <div className='schedaSottotitolo'>
                                    Orari di Indisponibilità Straordinarie
                                </div>
                                Inserisci qui date e orari in cui {nomeDefault} è straordinariamente non disponibile.<br/>
                                <b>Usa questa funzione per annullare una specifica lezione di un corso</b>.<br/>
                                Le prenotazioni già effettuate che ricadano nei periodo qui indicati, saranno cancellate automaticamente.<br/>
                                <Row form>  
                                    <Col md={12}>
                                        <TabellaOrariIndisponibilita                                        
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi} 
                                        callbackSetOrariIndisponibilita={setOrariIndisponibilita} 
                                        orariIndisponibilita = {orariIndisponibilita}
                                        ></TabellaOrariIndisponibilita>
                                    </Col>
                                </Row>

                                <br/>
                                </>
                                }                             

                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1 ? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                            
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {props.ope === "cancella" ? <Fragment>Torna all'elenco</Fragment>: 
                                   props.ope === "inserisci" ?
                                        <Fragment>Annulla</Fragment>
                                   :
                                    variazioneCampi === 1 ?
                                        <Fragment>Annulla</Fragment>
                                    : <Fragment>Torna all'elenco</Fragment>
                                }
                                </Button>
                                </div>
                        
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
