import React, {useState,useEffect,Suspense} from 'react';
import { Table, Card, CardBody, CardTitle } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaIscritto/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';

import Clessidra from '../Clessidra.js';
import controllaDate from '../controllaDate';
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;


function TabellaCertScad(props) {
    
    var today = new Date();

    // calcolo data di oggi
    var mese = today.getMonth()+1; // il mese di getMonth() parte da zero
    var anno = today.getFullYear();
    var sAnno = String(anno);
    var sMese = String(mese).padStart(2, "0");    
    var sGiorno = String(today.getDate()).padStart(2, "0");
    const dataOggi = sAnno+'-'+sMese+'-'+sGiorno;

    // calcolo data tra un mese
    mese++;
    if (mese>12) {
      mese=1;
      anno++;
    }
    sAnno = String(anno);
    sMese = String(mese).padStart(2, "0");
    let dataCorretta = controllaDate(sAnno,sMese,sGiorno);
    const dataTraUnMese = dataCorretta;

    const [dataArrivoDefault,setDataArrivoDefault]=useState(dataTraUnMese);

    //const operazione = props.tipo==="cert" ? "reportCertMedici" : "reportDocIdentita";

    const operazione = props.tipo==="cert" ? "reportCertMedici" 
                      : props.tipo==="doc" ? "reportDocIdentita"
                      : props.tipo==="privacy" ? "reportDocPrivacy"
                      : "reportScadAbbonamenti"


    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
  
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonneCertificatiMedici = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Emissione Certificato Medico',
        accessor: 'DATA_INIZIO'
      },
      {
        etichetta: 'Scadenza Certificato Medico',
        accessor: 'DATA_FINE'
      },
    ];
    const arrayTipiDatiCertificatiMedici=[];
    arrayTipiDatiCertificatiMedici[4]="data";
    arrayTipiDatiCertificatiMedici[5]="data";

    const colonneDocIdentita = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Scadenza Documento Identità',
        accessor: 'DATA_FINE'
      },
    ];    
    const arrayTipiDatiDocIdentita=[];
    arrayTipiDatiDocIdentita[4]="data";

    const colonneDocPrivacy = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Scadenza Documento Privacy',
        accessor: 'DATA_PRIVACY'
      },
    ];    
    const arrayTipiDatiDocPrivacy=[];
    arrayTipiDatiDocPrivacy[4]="data";

    const colonneAbbonamenti= [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Scadenza Abbonamento',
        accessor: 'DATA_FINE'
      },
      {
        etichetta: 'Nome Abbonamento',
        accessor: 'NOME_ABBONAMENTO'
      },
    ];    
    const arrayTipiDatiAbbonamenti=[];
    arrayTipiDatiAbbonamenti[4]="data";

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            dataArrivo:dataArrivoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServer(valoreDiRitorno.risultatoRitorno);setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

    useEffect(() => {
      setStatoPagina(STATO_RICERCA_DATI);    
    },[dataArrivoDefault])
  
  return (
    <div>
      

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda iscritto...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_CertScad</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          <CardBody>
            <CardTitle>
              Iscritti con&nbsp;
              {props.tipo==="cert" ? "Certificato medico":""}
              {props.tipo==="doc" ? "Documento di Identità":""}
              {props.tipo==="privacy" ? "Documento Privacy":""}
              {props.tipo==="abbonamenti" ? "Abbonamento":""}
              
              &nbsp;che scade entro il&nbsp;
              <input type="date" defaultValue={dataArrivoDefault} onChange={(e)=>{setDataArrivoDefault(e.target.value)}}/>
            </CardTitle>
            <div style={{display:"flex", flexDirection: "row"}}>
              <div>Legenda:</div>  
              <div className="evidenziatoAllarme" style={{border:"0.5px solid #202020", marginLeft:"5px"}}>scaduto o mancante</div>
              <div className="evidenziatoAvviso" style={{border:"0.5px solid #202020", marginLeft:"5px"}}>scade entro un mese</div>
              <div className="evidenziatoNormale" style={{border:"0.5px solid #202020", marginLeft:"5px"}}>scade tra più di un mese</div>
            </div>

          </CardBody>
          
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca
              columns={props.tipo==="cert" ? colonneCertificatiMedici 
                        : props.tipo==="doc" ? colonneDocIdentita 
                        : props.tipo==="privacy" ? colonneDocPrivacy
                        : colonneAbbonamenti
                        }
              data={risultatoServer}
              arrayTipiDati={props.tipo==="cert" ? arrayTipiDatiCertificatiMedici 
                        : props.tipo==="doc" ? arrayTipiDatiDocIdentita
                        : props.tipo==="privacy" ? arrayTipiDatiDocPrivacy
                        : arrayTipiDatiAbbonamenti
                        }
              campoChiave={0}
              nascondiColonna={0}
              callbackSelezionaElemento={VaiModifica}
              campoOrdinamento={props.tipo==="cert" ? 5 : 4}
              ordinamentoDecrescente={false}
              campoAllarme={props.tipo==="cert" ? 5 : 4}
              condizioneAllarme={"<"}
              valoreAllarme={dataOggi}
              campoAvviso={props.tipo==="cert" ? 5 : 4}
              condizioneAvviso={"<"}
              valoreAvviso={dataTraUnMese}
            />
          : 
            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr>
                <th>Nessun dato presente</th>
              </tr>
            </thead>
            </Table>
          }
        </div>
      :""}
    </div>
   );
}
export default TabellaCertScad;