import React, {Fragment,useState,useEffect,useRef} from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import VisualizzaAllegato from '../VisualizzaAllegato.js';

import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Resizer from "react-image-file-resizer";
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagModaleInfoFoto, setFlagModaleInfoFoto] = React.useState(false);
    const [flagModaleConfermaCancFoto, setFlagModaleConfermaCancFoto] = React.useState(false);
    const [flagAttivaModaleInserisciDenominazione, setFlagAttivaModaleInserisciDenominazione] = useState(0);
    const [flagAttivaModaleDimensioneDocumento, setFlagAttivaModaleDimensioneDocumento] = useState(0);

    const denominazione   = useRef();
    const descrizione   = useRef();
    const data   = useRef();
    const note   = useRef();
    //const file   = useRef();
    const flagEliminaFotoRif = useRef();

    const [fineSpazio, setFineSpazio] = useState(0);

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaTipo,setStatoTendinaTipo]=useState(false);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');
    
    const [risultatoServerDatiTipoDocumento,setRisultatoServerDatiTipoDocumento]=useState('');

    const [denominazioneDefault,setDenominazioneDefault]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [dataDefault,setDataDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');
    const [documentoDefault,setDocumentoDefault]=useState('');
    const [estensioneFileFoto, setEstensioneFileFoto] = useState('');
    const [flagFotoModificata, setFlagFotoModificata] = useState(false);

    const [insTipoDocumentoEdi,setInsTipoDocumentoEdi]=useState('');
    //const [defaultTipoDocumento,setDefaultTipoDocumento]=useState('');

    //per allegato:
    const [contenutoFile,setContenutoFile]=useState('');
    const [dimensioneDocumento,setDimensioneDocumento]=useState('');
    const [dimensioneMassimaDocumento,setDimensioneMassimaDocumento]=useState('');
    const [flagEliminaFoto,setFlagEliminaFoto]=useState('');
    const [typeFoto,setTypeFoto]=useState('');

    const [anteprima,setAnteprima]=useState('');
    const [typeAnteprima,setTypeAnteprima]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    var today = new Date();

    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    //const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno+'T00:00:00';
    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaTipo(false);
        setDataDefault(dataOggi);
    },[]);

    useEffect(() => {
        if(statoTendinaTipo === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaTipo]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("documentiIns");
        if(props.ope==='modifica') setOperazione("documentiMod");
        if(props.ope==='cancella') setOperazione("documentiCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"documentiRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setInsTipoDocumentoEdi(valoreDiRitorno.risultatoRitorno[0].DESCR_TIPO_DOCUMENTO);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                    
                    //setDefaultTipoDocumento(valoreDiRitorno.risultatoRitorno[0].DESCR_TIPO_DOCUMENTO);
                    setDenominazioneDefault(valoreDiRitorno.risultatoRitorno[0].DENOMINAZIONE);
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setDataDefault(valoreDiRitorno.risultatoRitorno[0].DATA);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
                    setDocumentoDefault(valoreDiRitorno.risultatoRitorno[0].DOCUMENTO);
                    //setDocumentoCorrettoDefault(valoreDiRitorno.risultatoRitorno[0].DOCUMENTO_CORRETTO);
                    setTypeFoto(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setEstensioneFileFoto(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FOTO);
                    setContenutoFile(valoreDiRitorno.risultatoRitorno[0].FILE);

                    ricercaDatiTipoDocumento(props.ope);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiTipoDocumento(props.ope);
        }
    },[props.ope]);

    useEffect(() => { 
        if(flagEliminaFoto === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaFoto]);


    function ricercaDatiTipoDocumento(operazione) {
        //ricerco gli enti convenzioni che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoTipiDocumenti",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTipoDocumento(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaTipo(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagModaleInfoFoto(false);
        setFlagModaleConfermaCancFoto(false);
        setFlagAttivaModaleInserisciDenominazione(0);
        setFlagAttivaModaleDimensioneDocumento(0);
        setFineSpazio(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function scaricaFile(nomeFile, contenuto) {
        const link = document.createElement('a');
        link.href = contenuto;
        link.setAttribute('download',nomeFile);     
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();      
        // Clean up and remove the link
        link.parentNode.removeChild(link);
  }
  function aggiornaFile(valore){
    setVariazioneCampi(1);
        let typeFile = '';
        if (valore.type.substring(0, 5) === "image") {
            var nuovoTipo = valore.type.substring(6);
            if (nuovoTipo.toUpperCase()!="JPG" && nuovoTipo.toUpperCase()!="JPEG" && nuovoTipo.toUpperCase()!="PNG") {
                nuovoTipo = "JPEG";
                typeFile = "image/jpeg";
            }else{
                typeFile = valore.type;
            }

            try {
                Resizer.imageFileResizer(
                    valore,
                    600,
                    600,
                    nuovoTipo,
                    100,
                    0,
                    (uri) => {
                        setContenutoFile(uri);
                    },
                    "base64"
                );                
            } catch (err) {
                // console.log(err);
                alert("Si è verificato un errore nel ridimensionamento dell'immagine, contattare l'assistenza tecnica");
            }
        } else {            
            setContenutoFile(valore)
        }
    const estensione = valore.name.split('.').pop();
    setFlagFotoModificata(true); setFlagEliminaFoto(""); setTypeFoto(typeFile); setEstensioneFileFoto(estensione);
    
    denominazione.current.value = valore.name;
    }
    function funzioneEliminaFoto(valore){
        //setVariazioneCampi(1);
        handleCambiamentoMod();
        setFlagEliminaFoto(valore);
        setTypeFoto('');
        setContenutoFile('');
    }

    /*function aggiornaFile(valore){
        setVariazioneCampi(1);
        setContenutoFile(valore);
        setDocumentoDefault("");
        //setDocumentoCorrettoDefault("");
        setTypeFoto('');
        setFlagEliminaDocumento("");
        setAnteprima(URL.createObjectURL(valore));
        setTypeAnteprima(valore.type);
    }
    function funzioneEliminaDocumentoAllegato(valore){
        setFlagEliminaDocumento(valore);
        setContenutoFile('');
        setDocumentoDefault('');
        //setDocumentoCorrettoDefault('');
        setTypeFoto('');
        setContenutoFile('');
        setAnteprima('');
        setTypeAnteprima('');
        setStatoPagina(STATO_MEMORIZZAZIONE);
    }*/

    function fetchOperazioneServer(bypassaControlli=0) {

        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){

            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }
            
            if(denominazione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDenominazione(1);
            }
        }

        if(controlli===0){ 
            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
    
                /*const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        denominazione:denominazione.current.value,
                        tipoDocumento:insTipoDocumentoEdi,
                        descrizione:descrizione.current.value,
                        data:data.current.value,
                        note:note.current.value,
                        file:file.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
                */
                setLoading(true);
                const formData = new FormData();
                if (props.ope!=='cancella'){
                    formData.append('op', operazione);
                    formData.append('denominazione',denominazione.current.value);
                    formData.append('tipoDocumento',insTipoDocumentoEdi);
                    formData.append('descrizione',descrizione.current.value);
                    formData.append('data',data.current.value);
                    formData.append('note',note.current.value);
                    if (flagFotoModificata) {
                        formData.append('contenutoFileFoto', contenutoFile);
                        formData.append('estensioneFileFoto', estensioneFileFoto);
                        formData.append('typeFileFoto', typeFoto);
                    }
                    //formData.append('contenutoFile', contenutoFile);
                    formData.append('flagEliminaFoto', flagEliminaFoto);
                    formData.append('idElemento', props.id);
                    formData.append('emak', props.emak);
                    
                    formData.append('sessionId', props.sessionId); 
                }else{
                    formData.append('op', operazione);
                    formData.append('idElemento', props.id);
                    formData.append('emak', props.emak);
                    formData.append('sessionId', props.sessionId);
                }

                const requestOptions = {
                    method: 'POST',
                    body: formData
                }
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Denominazione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDenominazione(1);
                                    return;
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione eccessiva") !== -1){
                                    alert("Documento troppo grande, accettati documenti fino a 10 MB");
                                    setLoading(false);
                                    setDisabilitaBottoni(false);
                                    return;
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione documento troppo elevata") !== -1){
                                    setFlagAttivaModaleDimensioneDocumento(1);
                                    setDimensioneDocumento(valoreDiRitorno.dimFoto);
                                    setDimensioneMassimaDocumento(valoreDiRitorno.dimMaxFoto);
                                    setContenutoFile('');
                                    setDocumentoDefault('');
                                    //setDocumentoCorrettoDefault('');
                                    setTypeFoto('');
                                    setContenutoFile('');
                                    setAnteprima('');
                                    setTypeAnteprima('');
                                    return;
                                }
                                if(valoreDiRitorno.mess.indexOf("Spazio Terminato") !== -1){
                                    setFineSpazio(1);
                                    return;
                                }

                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
                
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                    </div>
                    </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Doc {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore generico</div> :""}
                {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                    </div>
                    </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {fineSpazio === 1 ?
                        <Modale 
                        titolo="Informativa spazio terminato"
                        flagErrore={true}
                        contenuto={<div>Lo spazio dati è terminato</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleDimensioneDocumento === 1 ?
                        <Modale 
                            titolo="Dimensione eccessiva documento"
                            flagErrore={true}
                            contenuto={<div>Il documento ha una dimensione troppo elevata:<br/>rilevata {dimensioneDocumento} KB, massima accettata {dimensioneMassimaDocumento} KB</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDenominazione===1 ?
                        <Modale 
                            titolo="Richiesto nome del file"
                            flagErrore={true}
                            contenuto={<div>Inserire il nome del file</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}
                    {flagModaleInfoFoto === true ?
                        <Modale 
                            titolo="Informativa Documento"
                            flagErrore={false}
                            contenuto="Il documento può essere inserito in formato: jpg, jpeg, gif, png, webp, pdf, odt, doc, docx"
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleConfermaCancFoto === true ?
                    <Modale 
                        titolo="Conferma eliminazione"
                        flagErrore={false}
                        contenuto="Confermi l'eliminazione del documento?"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setFlagModaleConfermaCancFoto(false); funzioneEliminaFoto("on")}
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                            }
                        ]}
                    />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Documento</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Documento</CardTitle> :""}                                              

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione del documento <b>{denominazioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                            <> 
                            {/* div contenente dati documento e allegato */}
                            
                            <div style={{display:"flex", flexDirection:"row"}}>
                                {/* div contenente dati documento */}
                                <div style={{flex:1}}>

                                    <Row form>  
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="denominazione">Nome del file (compilato in automatico quando scegli il documento)*</Label>
                                                <Input maxLength={200} type="text" name="denominazione" id="denominazione"
                                                    innerRef={denominazione} 
                                                    placeholder=""
                                                    defaultValue={denominazioneDefault}
                                                    onInput={(e)=>{handleCambiamentoMod(e);setDenominazioneDefault(denominazione.current.value)}}/>                       
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>  
                                        <Col md={12}>
                                            <FormGroup>
                                            <Label for="descrizione">Descrizione</Label>
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>  
                                        <Col md={12}>
                                            <FormGroup>
                                            <Label>Tipo Documento</Label>    
                                            <TendinaEditabile
                                                //titolo={"Tipo Documento"}
                                                nomeCampo="DESCRIZIONE"
                                                valoreDefault={insTipoDocumentoEdi}
                                                arrayValori={risultatoServerDatiTipoDocumento}
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={setInsTipoDocumentoEdi}
                                            ></TendinaEditabile>
                                            </FormGroup>
                                        </Col>   
                                    </Row>
                                    <Row form>
                                        <Col md={12}>
                                        <FormGroup>
                                            <Label for="data">Data</Label>
                                                <Input maxLength={10} type="date" name="data" id="data"
                                                innerRef={data}
                                                placeholder="" 
                                                defaultValue={dataDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataDefault(data.current.value)}}/> 
                                        </FormGroup>
                                        </Col>
                                    </Row>  
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="note">Note</Label>
                                                <Input type="textarea" name="note" id="note"  defaultValue={noteDefault} innerRef={note}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value)}}/> 
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    </div> {/* fine div contenente dati documento */}

                                    <div style={{paddingLeft:10}}> {/* div contenente foto*/}
                                            <Label for="foto">
                                                Documento (max 10MB)
                                                <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoFoto(true)}/>
                                            </Label>
                                            <VisualizzaAllegato
                                                cssContenitore="btnimg"
                                                cssImmagine="immagineAnagrafica"
                                                cssPdf="pdfAnagrafica"
                                                cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                                scalaPdf={props.dimensioniApp.x/3000}
                                                contenutoFile={contenutoFile}
                                                tipoFile={typeFoto}
                                                estensioneFile={estensioneFileFoto}
                                                estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp,.pdf,.odt,.doc,.docx"}
                                                flagContenutoModificato={flagFotoModificata}
                                                callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile(e.target.files[0]);}}
                                                callbackScarica={() => scaricaFile(denominazione.current.value,contenutoFile)}
                                                callbackElimina={() => setFlagModaleConfermaCancFoto(true)}
                                            /> 
                                    </div>
                                    
                                    </div>  
                                    </>  
                                } 

                        </div>
                                

                        <div className="schedaSpazioInferiore">
                            {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                            {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                            <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                            {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                            </Button>
                        </div>
                            
                        <Clessidra loading={loading}/>
                    </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
