import React, {Fragment,useState,useEffect} from 'react';
import { Table,Row, Col,Button,Label,Input } from 'reactstrap';
import TendinaEditabile from './TendinaEditabile.js';

import TrasformaDateAUmano from './TrasformaDateAUmano.js';

import Cestino from "../immagini/cestino.png"

const STATO_VISUALIZZA = 0;
const STATO_NON_VISUALIZZA = 1;

function TabellaTendine(props) {
    
    //in input ho:
    //props.arrayValoriDefault contenente tutti i valori di default per ogni singola tendina
    //props.arrayDatiTendine contenente i valori utili alla popolazione delle tendine
    
    //in output fornisco:
    //props.callbackSetVariazioneCampi(1); qualora andassi a modificare una tendina o ad aggiungerne una la pagina principale lo deve sapere
    //props.callbacksetArrayValoriDefault mi darà il nuovo array con i valori dei default per ogni tendina
    
    const [arrayValDefault,setArrayValDefault]=useState([]);

    const [rivisualizza,setRivisualizza]=useState(0);

    const [posizioneCampoConDefaultLegatoValoreTendina,setPosizioneCampoConDefaultLegatoValoreTendina]=useState(0);

    const [statoPagina,setStatoPagina]=useState(STATO_NON_VISUALIZZA);

    useEffect(() => {
        setArrayValDefault(props.arrayValoriDefault);
        props.callbacksetArrayValoriDefault(props.arrayValoriDefault);//passo alla pagina principale nel caso in cui non vi sia alcuna modifica mi riuporta i dati originali
        
        if(props.arrayCampiAssociati){
            for (let i=0; i<props.arrayCampiAssociatiNomeDatabase.length ; i++) {
                if(props.arrayCampiAssociatiNomeDatabase[i]===props.nomeCampoConDefaultLegatoValoreTendina){
                    setPosizioneCampoConDefaultLegatoValoreTendina(i);
                    break;
                }
            }
        }


        setStatoPagina(STATO_VISUALIZZA);
    },[props.arrayValoriDefault])

    useEffect(() => {
        setStatoPagina(STATO_VISUALIZZA);
    },[rivisualizza])
      

    function aggiungiTendina() {
        let tendine = [...arrayValDefault];//popolo l'array locale con i dati dell'array di stato
        tendine.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayValDefault(tendine);//aggiorno nuovamente la var di stato
        props.callbacksetArrayValoriDefault(tendine);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 
    
        //se ci sono anche campi associati, dovranno scalare pure loro per cui:
        if(props.arrayCampiAssociati){
            //console.log("ELEMENTI"+props.arrayCampiAssociatiValoriDefault.length)
            let pos = props.arrayCampiAssociatiValoriDefault.length;
            //console.log("aggiungo in posizione"+pos)
            props.arrayCampiAssociatiValoriDefault[pos]=[];
            //console.log("i campi sono"+props.arrayCampiAssociatiNomeDatabase.length)
            for (let i=0; i<props.arrayCampiAssociatiNomeDatabase.length ; i++) {
                //console.log("aggiungo in"+props.arrayCampiAssociatiNomeDatabase[i])
                props.arrayCampiAssociatiValoriDefault[pos][props.arrayCampiAssociatiNomeDatabase[i]] = '';
            }
        }
    }
    function eliminaTendina(posizione) {
        let tendine = [...arrayValDefault];//popolo l'array locale con i dati dell'array di stato
        tendine.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayValDefault(tendine);//aggiorno nuovamente la var di stato
        props.callbacksetArrayValoriDefault(tendine);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 

        //se ci sono anche campi associati, dovranno scalare pure loro per cui:
        if(props.arrayCampiAssociati){
            props.arrayCampiAssociatiValoriDefault.splice(posizione, 1);//cancello l'elemento desiderato
        }
        props.callbackAggiornaCampo(posizione,posizioneCampoConDefaultLegatoValoreTendina,'',1);

        setRivisualizza(rivisualizza+1);
    }
    function spostaTendina(posizione,spostamento){
        const toIndex = parseInt(posizione)+spostamento;
        if(toIndex>=0){
            const fromIndex = parseInt(posizione); 
            let tend= [...arrayValDefault];//popolo l'array locale con i dati dell'array di stato
            //console.log("inizio_0_tend"+tend[0])
            //console.log("inizio_1_tend"+tend[1])
            const elementTend = tend.splice(fromIndex, 1)[0];
            tend.splice(toIndex, 0, elementTend);
            //console.log("fine_0_tend"+tend[0])
            //console.log("fine_1_tend"+tend[1])
            setArrayValDefault(tend);//aggiorno nuovamente la var di stato
            props.callbacksetArrayValoriDefault(tend);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 

            //se ci sono anche campi associati, dovranno scalare pure loro per cui:
            /*if(props.arrayCampiAssociati){
                const elementVal= props.arrayCampiAssociatiValoriDefault.splice(fromIndex, 1);
                props.arrayCampiAssociatiValoriDefault.splice(toIndex, 0, elementVal);
            }*/

        }
        setRivisualizza(rivisualizza+1);
    }

    function callbackSetValoreTendEditabile(elemento,posizione){
        let tendine = [...arrayValDefault];//popolo l'array locale con i dati dell'array di stato
        tendine.splice(posizione, 1, elemento);//modifico l'elemento desiderato
        setArrayValDefault(tendine);//aggiorno nuovamente la var di stato
        props.callbacksetArrayValoriDefault(tendine);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 

        //devo aggiornare anche i valori di default in conseguenza della scelta fatta in tendina:
        let defaultNuovo = 0;
        for (let i=0; i<props.arrayDatiTendine.length ; i++) {
            if(elemento === props.arrayDatiTendine[i][props.nomeCampoTendine]){
                defaultNuovo = props.arrayDatiTendine[i][props.nomeCampoConDefaultLegatoValoreTendina];
                break;
            }
        }
        //ora lo vado a mettere nella riga giusta (posizione) e per il numero di campo corretto (posizioneCampoConDefaultLegatoValoreTendina):
        //console.log("CAMPO in pos"+posizioneCampoConDefaultLegatoValoreTendina);
        //console.log("CAMPO con nome"+props.arrayCampiAssociatiNomeDatabase[posizioneCampoConDefaultLegatoValoreTendina]);
        //console.log("CAMPO in riga"+posizione);
        //console.log("CAMPO new"+defaultNuovo);

        if(props.arrayCampiAssociati) props.callbackAggiornaCampo(posizione,posizioneCampoConDefaultLegatoValoreTendina,defaultNuovo);

    }
    
    return (
      <div>
        {statoPagina === STATO_NON_VISUALIZZA ? <div>Memorizzazione</div> :""}
        {statoPagina === STATO_VISUALIZZA ?
        <Fragment>
     
            <Row>
                <Col lg="12">
                    <Table>
                        {props.titoloTendine!==undefined && props.arrayValoriDefault.length>0 ?
                        <tr>
                            {(props.flagModifica === 0) ? "" : <td style={{border:0}}></td>}
                            <td className='titoloTabella'>{props.titoloTendine}</td>
                            {props.arrayCampiAssociatiDescrizione.map((elemento, indice) => 
                                <td className='titoloTabella'>{elemento}</td>
                            )}
                        </tr>
                        :""}
                        <tbody>
    
                        {arrayValDefault.map((elemento,index) => <tr key={index}>
                            {(props.flagModifica === 0) ? 
                                <td><Label>{arrayValDefault[index]}</Label></td>
                            :
                                <>
                                    <td style={{border:0, width:1}}>
                                        <img 
                                        src={Cestino}
                                        onClick={() => {
                                            props.callbackSetVariazioneCampi(1);
                                            setStatoPagina(STATO_NON_VISUALIZZA);
                                            eliminaTendina(index);
                                        }}
                                        />
                                    </td>
                                    <td style={{border:0}}>
                                        <TendinaEditabile
                                            //titolo={props.titoloTendine}
                                            nomeCampo={props.nomeCampoTendine}
                                            valoreDefault={arrayValDefault[index]}
                                            arrayValori={props.arrayDatiTendine}
                                            labelCampoVuoto={props.labelCampoVuoto}
                                            posizioneTendina={index}
                                            callbackSetVariazioneCampi={props.callbackSetVariazioneCampi}
                                            callbackSetValoreTendEditabile={callbackSetValoreTendEditabile}
                                        ></TendinaEditabile>
                                    </td>
                                </>
                            }
                            
                            {props.arrayCampiAssociati ?
                                <>
                                    {props.arrayCampiAssociati.map((elemento,indexCampi) => <td key={indexCampi} style={{border:props.flagModifica===0?"":0}}>
                                    {/*qui metto tutti i campi associati*/}

                                            {(props.flagModifica === 0) ? 
                                            
                                            props.arrayCampiAssociatiType[indexCampi] === 'date' ?
                                                <Label><TrasformaDateAUmano data={props.arrayCampiAssociatiValoriDefault[index][props.arrayCampiAssociatiNomeDatabase[indexCampi]]}></TrasformaDateAUmano></Label>
                                            :
                                                <Label>{props.arrayCampiAssociatiValoriDefault[index][props.arrayCampiAssociatiNomeDatabase[indexCampi]]}</Label>
                                            
                                        :
                                            <Fragment>
                                        
                                            {props.arrayCampiAssociatiType[indexCampi] === 'date' || props.arrayCampiAssociatiType[indexCampi] === 'text' ?
                                                props.arrayCampiAssociatiValoriDefault[index] ? 
                                                <Input type={props.arrayCampiAssociatiType[indexCampi]} name={props.arrayCampiAssociati[indexCampi]} id={props.arrayCampiAssociati[indexCampi]}
                                                innerRef={props.arrayCampiAssociati[indexCampi]} 
                                                placeholder=""
                                                value={props.arrayCampiAssociatiValoriDefault[index][props.arrayCampiAssociatiNomeDatabase[indexCampi]]}
                                                onInput={(e)=>{props.callbackSetVariazioneCampi(1);props.callbackAggiornaCampo(index,indexCampi,e.target.value);}}/> 
                                                :
                                                <Input type={props.arrayCampiAssociatiType[indexCampi]} name={props.arrayCampiAssociati[indexCampi]} id={props.arrayCampiAssociati[indexCampi]}
                                                innerRef={props.arrayCampiAssociatiType[indexCampi]} 
                                                placeholder=""
                                                value='' 
                                                onInput={(e)=>{props.callbackSetVariazioneCampi(1);props.callbackAggiornaCampo(index,indexCampi,e.target.value);}}/>
                                            :""}

                                            {props.arrayCampiAssociatiType[indexCampi] === 'select' ?
                                            <Fragment>
                                                {props.arrayCampiAssociatiValoriDefault[index] ?
                                                    <select value={props.arrayCampiAssociatiValoriDefault[index][props.arrayCampiAssociatiNomeDatabase[indexCampi]]}                    
                                                            className="form-control"  
                                                            innerRef={props.arrayCampiAssociati[indexCampi]} 
                                                            onInput={(e)=>{
                                                            props.callbackSetVariazioneCampi(1);
                                                            props.callbackAggiornaCampo(index,indexCampi,e.target.value);}}>

                                                        {props.arrayCampiAssociatiValoriPossibili[indexCampi].map((elementoPossibile,indexPossibile) =>
                                                            <option key={indexPossibile} value={props.arrayCampiAssociatiValoriPossibili[indexCampi][indexPossibile]} >
                                                                {props.arrayCampiAssociatiValoriPossibili[indexCampi][indexPossibile]}
                                                            </option>                                                   
                                                        )};
                                                    </select>
                                                :
                                                    <select value=''   
                                                            className="form-control" 
                                                            innerRef={props.arrayCampiAssociati[indexCampi]}                 
                                                            onInput={(e)=>{
                                                            props.callbackSetVariazioneCampi(1);
                                                            props.callbackAggiornaCampo(index,indexCampi,e.target.value);}}>

                                                        {props.arrayCampiAssociatiValoriPossibili[indexCampi].map((elementoPossibile,indexPossibile) =>
                                                            <option key={indexPossibile} value={props.arrayCampiAssociatiValoriPossibili[indexCampi][indexPossibile]} >
                                                                {props.arrayCampiAssociatiValoriPossibili[indexCampi][indexPossibile]}
                                                            </option>                                                   
                                                        )};
                                                    </select>
                                                }
                                                </Fragment>
                                            :""}
                                            </Fragment>
                                        }
                                        
                                    </td>)
                                    }
                                </>
                                :""}

                                {(props.flagModifica === 0) ? "" :
                                    <Fragment>
                                    {props.arrayCampiAssociati ? "" :
                                        <Fragment>
                                        <td style={{border:0, width:1}}><Button color="primary" className="mt-2" style={{whiteSpace: "nowrap"}} onClick={() => {
                                        props.callbackSetVariazioneCampi(1);
                                        ;setStatoPagina(STATO_NON_VISUALIZZA);
                                        spostaTendina(index,-1)}}>Sposta su</Button></td> 

                                        <td style={{border:0, width:1}}><Button color="primary" className="mt-2" style={{whiteSpace: "nowrap"}} onClick={() => {
                                        props.callbackSetVariazioneCampi(1);
                                        ;setStatoPagina(STATO_NON_VISUALIZZA);
                                        spostaTendina(index,1)}}>Sposta giù</Button></td> 
                                        </Fragment>
                                    }
                                    </Fragment>
                                }
                            </tr>)
                        }
                        </tbody>
                    </Table>    
                    {(props.flagModifica === 0) ? "" :
                        <tr><td><Button color="success" className="mt-2" onClick={() => {
                            props.callbackSetVariazioneCampi(1);
                            aggiungiTendina()}} 
                        >
                        {props.labelBottoneAggiungi ? props.labelBottoneAggiungi : "Aggiungi"}
                        </Button></td></tr>
                    }
                     
                </Col>   
            </Row>
          
        </Fragment>
       :""}
    </div>
   );
}
export default TabellaTendine;
