import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import {Row, Col,
  Card, CardBody,
  CardTitle,Button,Form,Label,Input } from 'reactstrap';

import FormGridFormRow from '../SchedaIscritto/FormGridFormRow';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaNumeroVideo from '../FormattaNumeroVideo.js';

import Clessidra from '../Clessidra.js';

//import html2pdf from 'html2pdf.js';//per estrazione pdf

import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';

let larghColonna = 2;
let larghColonnaStretta = 1;

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_RICERCA_DATI_DETTAGLIO= 5;
//const STATO_VAI_IN_INSERISCI= 6;
//const STATO_OPERAZIONE_CORRETTA2= 7;

const STATO_SCARICA_PDF = 9;

function TabellaElencoArticoli(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);

    const [risultatoServerDatiCategorie,setRisultatoServerDatiCategorie]=useState('');
    const [risultatoServerDatiArticoli,setRisultatoServerDatiArticoli]=useState('');
    const [risultatoServerDatiReport,setRisultatoServerDatiReport]=useState('');

    const [statoTendinaCategoria,setStatoTendinaCategoria]=useState(false);
    const [statoTendinaArticoli,setStatoTendinaArticoli]=useState(false);

    const [defaultCategoria,setDefaultCategoria]=useState('Tutti');
    const [defaultArticolo,setDefaultArticolo]=useState('Tutti');

    const [error,setError]=useState('');

    const [loading,setLoading]= useState(false);

    const [calcola,setCalcola]=useState(false);
    const [visualizza,setVisualizza]=useState(false);
    const [visualizzaInPdf,setVisualizzaInPdf]=useState(false);

    //per il logo:
    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    //per i risultati del report:
    const [risultatiCategoria,setRisultatiCategoria]=useState([]);
    const [risultatiArticolo,setRisultatiArticolo]=useState([]);
    const [risultatiNumero,setRisultatiNumero]=useState([]);
    const [risultatiPrezzo,setRisultatiPrezzo]=useState([]);
    const [risultatiIVA,setRisultatiIVA]=useState([]);
    const [risultatiDisponibileVendita,setRisultatiDisponibileVendita]=useState([]);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    //var MyDocument = '';

    useEffect(() => {
      setStatoTendinaCategoria(false);
      ricercaDatiCategorie();
      setStatoTendinaArticoli(false);
      ricercaDatiArticoli();
      setDefaultCategoria("Tutti");
      setDefaultArticolo("Tutti");
      scaricaLogo();
    },[]);
 
    useEffect(() => {
      setVisualizza(false);
      setCalcola(false);
    },[defaultCategoria,defaultArticolo]);

    useEffect(() => {
      if(visualizzaInPdf === true) {
        //handleEsportaPDF();
        setStatoPagina(STATO_SCARICA_PDF);
      }
    },[visualizzaInPdf]);

    useEffect(() => {
      if(statoTendinaArticoli === true && statoTendinaCategoria === true  && ricercaLogo === 1){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
      }
    },[statoTendinaCategoria,statoTendinaArticoli,ricercaLogo]);

    useEffect(() => {
      if(calcola === true){
        ricercaDatiReport();
      }
    },[calcola]);

    //funzioni:

  function scaricaLogo() {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {     
        setLoading(false);     
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
              }
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

  function ricercaDatiCategorie() {
    //ricerco i dati delle categorie che mi servono per popolare le tendine:
    setLoading(true);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"elencoCatArticoli",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {
            setLoading(false);
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
            }
            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
            if(valoreDiRitorno.risultatoOperazione===1){
            setRisultatoServerDatiCategorie(valoreDiRitorno.risultatoRitorno);
            setStatoTendinaCategoria(true);
            }
        },
        (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
  }
  function ricercaDatiArticoli(defaultCat='') {
    //ricerco i dati degli articoli che mi servono per popolare le tendine:
    setLoading(true);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"elencoArticoli",recordPartenza:1,defaultCategoria:defaultCat,intervallo:'',emak: props.emak,sessionId:props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {
            setLoading(false);
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
            }
            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
            if(valoreDiRitorno.risultatoOperazione===1){
              if(defaultCat==='Tutti' || defaultCat===''){
                setDefaultArticolo("Tutti")
                setDisabilitaBottoni(false);
              }else{
                //metto come default il primo articolo della cat selezionmata:
                if(valoreDiRitorno.risultatoRitorno[0]!== undefined){
                  setDefaultArticolo('Tutti');
                  setDisabilitaBottoni(false);
                }else{
                  setDefaultArticolo('Nessun articolo presente per la categoria selezionata');
                  setDisabilitaBottoni(true);
                }
              }
              setRisultatoServerDatiArticoli(valoreDiRitorno.risultatoRitorno);
              setStatoTendinaArticoli(true);
            
            }
        },
        (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
  }
  function ricercaDatiReport(){
    
    setLoading(true);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"reportElencoArticoli",
        defaultCategoria:defaultCategoria,
        defaultArticolo:defaultArticolo,
        emak: props.emak,
        sessionId:props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {
            setLoading(false);
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                      let arrLocale1=[];//categoria
                      let arrLocale2=[];//articolo
                      let arrLocale3=[];//numero
                      let arrLocale4=[];//prezzo
                      let arrLocale5=[];//iva
                      let arrLocale6=[];//disponibile vendita

                      for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                        arrLocale1[i]=valoreDiRitorno.risultatoRitorno[i].CATEGORIA;
                        arrLocale2[i]=valoreDiRitorno.risultatoRitorno[i].ARTICOLO;
                        arrLocale3[i]=valoreDiRitorno.risultatoRitorno[i].NUMERO;
                        arrLocale4[i]=valoreDiRitorno.risultatoRitorno[i].PREZZO;
                        arrLocale5[i]=valoreDiRitorno.risultatoRitorno[i].IVA;
                        arrLocale6[i]=valoreDiRitorno.risultatoRitorno[i].FLAG_DISP_VENDITA;
                      }

                      setRisultatiCategoria(arrLocale1);
                      setRisultatiArticolo(arrLocale2);
                      setRisultatiNumero(arrLocale3);
                      setRisultatiPrezzo(arrLocale4);
                      setRisultatiIVA(arrLocale5);
                      setRisultatiDisponibileVendita(arrLocale6);
                      setRisultatoServerDatiReport(valoreDiRitorno.risultatoRitorno);
                      setVisualizza(true);
                      
                    //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
              }
        },
        (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
}

    function callbackSetVariazioneCampi(variazioneCampi){
      setVariazioneCampi(variazioneCampi);
    }
    function callbackSetValoreTendEditabileCategoria(valore,pos,valoreNonVisibile){
        setDefaultCategoria(valore);
        setStatoTendinaArticoli(false);
        ricercaDatiArticoli(valore);
    }
    function callbackSetValoreTendEditabileArticolo(valore,pos,valoreNonVisibile){
        setDefaultArticolo(valore);
        setStatoTendinaCategoria(false);
        //setRisultatoServerDatiCategorie([]);
        //setDefaultCategoria("");
        //setInsIdPrestazioneEdi(valoreNonVisibile);
    }
    //fine funzioni
    function scriviFlag(valore){
      if(valore==='1') return "Si";
      else return "No";
    }

  return (
    <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}><Clessidra loading={loading}/>
 
  {statoPagina === STATO_SCARICA_PDF ? 
      <div>
      {/*<PDFViewer style={styles.viewer}>*/}
      <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
          <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={{width:"100%"}} >
              <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                          </View> 
                        </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                          capSocieta !== "" ?
                          " "+capSocieta+" "+cittaSocieta
                          :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                          <Text style={styles.titolo}>Elenco Articoli
                          </Text>
                          
                      </View> 
                    </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View>
                    <Text style={styles.titoloPiccolo}>Report del <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>
                  </View>
                  <View wrap={false}> 
                      {defaultCategoria !=='' ?
                        <View>
                          <Text style={styles.titoloPiccolo}>Categoria: {defaultCategoria}</Text>
                        </View>
                      :""}
                      {defaultArticolo !==''?
                        <View>
                          <Text style={styles.titoloPiccolo}>Articolo: {defaultArticolo}</Text>
                        </View>
                      :""} 
                    </View> 

                <View style={styles.table}>
                  <View wrap={false} style={styles.tableRowBlu}>
                          <View style={styles.tableCol5Di100}> 
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}></Text> 
                          </View>  
                          <View style={styles.tableCol20Di100}>
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Categoria</Text> 
                          </View> 
                          <View style={styles.tableCol30Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Articolo</Text> 
                            </View>
                            <View style={styles.tableCol12Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoDestra}>N.oggetti</Text>
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>in</Text>  
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>giacenza</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoDestra}>Prezzo</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoDestra}>IVA</Text> 
                            </View> 
                            <View style={styles.tableCol13Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoDestra}>Disponibile</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>alla</Text> 
                              <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>vendita</Text> 
                            </View> 
                      </View>

                  {risultatiArticolo.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>

                    <View style={styles.tableCol5Di100}>
                    <Text style={styles.tableCell}> {index+1}</Text>
                  </View>                 
                  <View style={styles.tableCol20Di100}>
                    <Text style={styles.tableCellAllineatoSinistra}> {risultatiCategoria[index]}</Text>
                  </View>
                  <View style={styles.tableCol30Di100}>
                    <Text style={styles.tableCellAllineatoSinistra}> {risultatiArticolo[index]}</Text>
                  </View>
                  <View style={styles.tableCol12Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {FormattaNumeroVideo(risultatiNumero[index])}</Text>
                  </View>                       
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(risultatiPrezzo[index])}</Text>
                  </View>
                  <View style={styles.tableCol10Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {FormattaImportoVideo(risultatiIVA[index])}</Text>
                  </View>
                  <View style={styles.tableCol13Di100}>
                    <Text style={styles.tableCellAllineatoDestra}> {scriviFlag(risultatiDisponibileVendita[index])}</Text>
                  </View>
                  </View>)}
       
                  </View>
              </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            </Page>
          </Document>
      </PDFViewer>
    </div>

    :""}


      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_R_EleArt</div> :""}  
      {statoPagina === STATO_RICERCA_DATI || statoPagina === STATO_RICERCA_DATI_DETTAGLIO ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            
            
            <Row>
              <Col lg="12">
                  <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                      {visualizzaInPdf === true ?
                        <div id='idDivGenerazionePdf' style={{display:'none'}}>Generazione PDF in corso...</div>
                      :""}

                            <CardTitle id="titolo">Elenco Articoli</CardTitle>
                            
                                <Row form>  
                                <Col md={5}>    
                                <Label for="categoria">Categoria</Label>  
                                  
                                <TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="DESCRIZIONE"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={defaultCategoria}
                                    arrayValori={risultatoServerDatiCategorie}
                                    inserisciCampoTutti = "1"
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCategoria}
                                ></TendinaEditabile>
                                </Col>       
                                <Col md={5}>
                                <Label for="articolo">Articolo</Label>
                                {defaultCategoria !== 'Tutti' ?
                                  <TendinaEditabile
                                      nomeCampo="DESCRIZIONE"
                                      nomeCampoNonVisibile="ID"
                                      bloccoInserimento = "1"
                                      valoreDefault={defaultArticolo}
                                      arrayValori={risultatoServerDatiArticoli}
                                      callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                      callbackSetValoreTendEditabile={callbackSetValoreTendEditabileArticolo}
                                  ></TendinaEditabile>
                                :
                                  <TendinaEditabile
                                      nomeCampo="DESCRIZIONE"
                                      nomeCampoNonVisibile="ID"
                                      bloccoInserimento = "1"
                                      valoreDefault={defaultArticolo}
                                      arrayValori={risultatoServerDatiArticoli}
                                      inserisciCampoTutti = "1"
                                      callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                      callbackSetValoreTendEditabile={callbackSetValoreTendEditabileArticolo}
                                  ></TendinaEditabile>
                                }
                                </Col>
                                
                                
                                {visualizza !== true ?
                                  <Col md={2}>
                                    <div style={{paddingTop:24}}> 
                                      <Button color="success" disabled={disabilitaBottoni} className="mt-2" onClick={() => {setCalcola(true);setVisualizzaInPdf(false)}}>Calcola</Button>
                                    </div> 
                                  </Col>
                                : 
                                <Col md={2}>
                                  <div style={{paddingTop:24}}> 
                                    <Button color="success" className="mt-2" onClick={(e)=>{setVisualizzaInPdf(true)}}>Esporta il PDF</Button>
                                  </div> 
                                </Col>
                                }
                                </Row>
                             

                            <div id="dettaglioReport">
                            {visualizza === true ?
                              risultatoServerDatiReport.length === 0 ?
                                <Fragment >
                                  <Row form className="border">
                                  <Col md='12'><b>Non risultano articoli che soddisfino le condizioni impostate</b></Col>
                                  </Row>
                                </Fragment>
                              :
                              
                              <Fragment>
                                
                              <Row form style={{marginTop:10, marginBottom:10}}> 
                                <Col >  
                                  <b>Report del <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></b>
                                </Col> 
                              </Row>  
                              
                              <Row form className="rigaTitoloTabellaReport">
                                <Col md={larghColonnaStretta} className="titoloTabellaReport"><b></b></Col>
                                <Col md={larghColonna} className="titoloTabellaReport">Categoria</Col>
                                <Col md={larghColonna} className="titoloTabellaReport">Articolo</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport" align="right">N.oggetti<br></br>in giacenza</Col>
                                

                                <Col md={larghColonna} className="titoloTabellaReport" align="right">Prezzo</Col>
                                <Col md={larghColonna} className="titoloTabellaReport" align="right">IVA</Col>
                                <Col md={larghColonna} className="titoloTabellaReport" align="right">Disponibile<br></br>alla vendita</Col>
                              </Row>
                            
                              {risultatiArticolo.map((elemento,index) => <Row className="rigaTabellaReport" key={index}>

                                  <Col md={larghColonnaStretta}><center>{index+1}</center></Col>
                                  <Col md={larghColonna}>{risultatiCategoria[index]}</Col>
                                  <Col md={larghColonna}>{risultatiArticolo[index]}</Col>
                                  <Col md={larghColonnaStretta} align="right">{FormattaNumeroVideo(risultatiNumero[index])}</Col>
                                
                                <Col md={larghColonna} align="right">{FormattaImportoVideo(risultatiPrezzo[index])}</Col>
                                <Col md={larghColonna} align="right">{FormattaImportoVideo(risultatiIVA[index])}</Col>
                                <Col md={larghColonna} align="right">{scriviFlag(risultatiDisponibileVendita[index])}</Col>

                              </Row>)}
                              </Fragment>
                            :""}
                        </div>                            
                      
                    </div>
                </Col>
            </Row>
        </div>
      :""}
    </div>
   );
}
export default TabellaElencoArticoli;