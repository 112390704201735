import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Form,
} from 'reactstrap';

import Parametri from '../../parametri';
import Clessidra from '../Clessidra.js';


const STATO_INDEFINITO = 0;
//const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    //const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    var today = new Date();
    const annoOggi=today.getFullYear();

    //const [modalePeriodoQuoteGiaInserite, setModalePeriodoQuoteGiaInserite] = React.useState(false);

    //const [flagAttivaModalePeriodoQuoteGiaInserite, setFlagAttivaModalePeriodoQuoteGiaInserite]  = useState(0);

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [dataOrigineDefault,setDataOrigineDefault]=useState(annoOggi);
    //const [risultatoServerDatiPosizioni,setRisultatoServerDatiPosizioni]=useState('');

    const dataOrigine   = useRef();
    
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        
        if(controlli===0){ 
                // parametri per inviare i dati al server:
                setLoading(true);
                
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: 
                        JSON.stringify({op:"copiaQuoteAssociative",
                        dataOrigine:dataOrigineDefault,
                        dataDestinazione:props.dataDestinazione,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            /*if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess==="Periodo con quote già inserite"){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModalePeriodoQuoteGiaInserite(true);
                                    setFlagAttivaModalePeriodoQuoteGiaInserite(1);
                                } 
                            }*/
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } 
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ?  
                    <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                        <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                        </div>
                    </div> 
                :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1CpQAss {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ?  
                    <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                        <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                        </div>
                    </div> 
                :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                    <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                        <div className="schedaSpazioCentrale">
                          
                            <CardTitle>Copia Quote Associative dall' Anno&nbsp; 
                            <select name="dataOrigine" id="dataOrigine" innerRef={dataOrigine} onChange={(e)=>{setDataOrigineDefault(e.target.value)}}>
                            {parseInt(dataOrigineDefault) === annoOggi+1 ? <option selected value={annoOggi+1}>{annoOggi+1}</option> : <option value={annoOggi+1}>{annoOggi+1}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi ? <option selected value={annoOggi}>{annoOggi}</option> : <option value={annoOggi}>{annoOggi}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-1 ? <option selected value={annoOggi-1}>{annoOggi-1}</option> : <option value={annoOggi-1}>{annoOggi-1}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-2 ? <option selected value={annoOggi-2}>{annoOggi-2}</option> : <option value={annoOggi-2}>{annoOggi-2}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-3 ? <option selected value={annoOggi-3}>{annoOggi-3}</option> : <option value={annoOggi-3}>{annoOggi-3}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-4 ? <option selected value={annoOggi-4}>{annoOggi-4}</option> : <option value={annoOggi-4}>{annoOggi-4}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-5 ? <option selected value={annoOggi-5}>{annoOggi-5}</option> : <option value={annoOggi-5}>{annoOggi-5}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-6 ? <option selected value={annoOggi-6}>{annoOggi-6}</option> : <option value={annoOggi-6}>{annoOggi-6}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-7 ? <option selected value={annoOggi-7}>{annoOggi-7}</option> : <option value={annoOggi-7}>{annoOggi-7}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-8 ? <option selected value={annoOggi-8}>{annoOggi-8}</option> : <option value={annoOggi-8}>{annoOggi-8}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-9 ? <option selected value={annoOggi-9}>{annoOggi-9}</option> : <option value={annoOggi-9}>{annoOggi-9}</option>}
                            {parseInt(dataOrigineDefault) === annoOggi-10 ? <option selected value={annoOggi-10}>{annoOggi-10}</option> : <option value={annoOggi-10}>{annoOggi-10}</option>}
                            </select> all'anno {props.dataDestinazione}
                            </CardTitle>
                           
                            
                            <Clessidra loading={loading}/>
                            Premendo il bottone Conferma, tutte le quote associative presenti nell'anno di origine verranno copiate nell'anno di destinazione
                            
                            </div>

                            <div className="schedaSpazioInferiore">
                        
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                                <Fragment> Torna all'elenco </Fragment>
                                </Button>
                                <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Conferma</Button>
                              
                            
                        </div>
                    </div>
                
                :""}
            </div>
        );
}
export default FormGridFormRow;
