import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png'

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');
    const [risultatoServerDatiPersona,setRisultatoServerDatiPersona]=useState('');
    const [statoTendinaIscritto,setStatoTendinaIscritto]=useState(false);

    const [insPersonaEdi,setInsPersonaEdi]=useState('');
    const [insIdPersonaEdi,setInsIdPersonaEdi]=useState('');
    //const [defaultPersona,setDefaultPersona]=useState('');

    //const cognome  = useRef();
    //const nome   = useRef();
    //const tesseraNumero   = useRef();
    const flagStaff   = useRef();
    const flagAmministrazione  = useRef();
    
    const [cognomeDefault,setCognomeDefault]=useState('');
    const [nomeDefault,setNomeDefault]=useState('');
    const [tesseraNumeroDefault,setTesseraNumeroDefault]=useState('');
    const [flagStaffDefault,setFlagStaffDefault]=useState('0');
    const [flagAmministrazioneDefault,setFlagAmministrazioneDefault]=useState('0');

    const [flagAttivaModaleInfoFlagStaff, setFlagAttivaModaleInfoFlagStaff] = useState(0);
    const [flagAttivaModaleInfoFlagAmministrazione, setFlagAttivaModaleInfoFlagAmministrazione] = useState(0);
    const [flagAttivaModaleInserisciIscritto, setFlagAttivaModaleInserisciIscritto] = useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaIscritto(false);
    },[]);


    useEffect(() => {
        if(statoTendinaIscritto === true) setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[statoTendinaIscritto]);


    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("permessiPersoneIns");
        if(props.ope==='modifica') setOperazione("permessiPersoneMod");
        if(props.ope==='cancella') setOperazione("permessiPersoneCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"permessiPersoneRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setTesseraNumeroDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                    setFlagStaffDefault(valoreDiRitorno.risultatoRitorno[0].FLAG_STAFF);
                    setFlagAmministrazioneDefault(valoreDiRitorno.risultatoRitorno[0].FLAG_AMMINISTRAZIONE);

                    let componiPersona="";
                    if(valoreDiRitorno.risultatoRitorno[0].COGNOME !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME;
                    if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined && componiPersona !== "" ) componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined ) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME;
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined) componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                    setInsPersonaEdi(componiPersona);
                    //setDefaultPersona(componiPersona);

                    ricercaDatiPersona();
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiPersona();
        }
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function callbacksetValoriPersona(valore,posizione,valoreNonVisibile){
        setInsPersonaEdi(valore);
        setInsIdPersonaEdi(valoreNonVisibile);
    }

    function attivaModaleInfoFlagStaff(){
        setFlagAttivaModaleInfoFlagStaff(1);
    }

    function attivaModaleInfoFlagAmministrazione(){
        setFlagAttivaModaleInfoFlagAmministrazione(1);
    }

    function tornaScheda() {
        setFlagAttivaModaleInfoFlagStaff(0);
        setFlagAttivaModaleInfoFlagAmministrazione(0);
        setFlagAttivaModaleInserisciIscritto(0);
    }

    function ricercaDatiPersona() {
        //ricerco tra gli iscritti che mi servono per popolare la tendina (solo quelli che no hanno già un flag staff o amministrazione):
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",filtro:"soloNonStaffOAmministrazione",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaIscritto(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(insPersonaEdi === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciIscritto(1);
            }
        }

        if(controlli===0){

            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                let idIscritto = 0;
                let fStaff = 0;
                let fAmministrazione = 0;
                
                if(props.ope==='inserisci') idIscritto = insIdPersonaEdi;
                else idIscritto = props.id;

                if(props.ope==='inserisci' || props.ope==='modifica') fStaff = flagStaff.current.checked;
                if(props.ope==='inserisci' || props.ope==='modifica') fAmministrazione = flagAmministrazione.current.checked;
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: 
                    JSON.stringify({op:operazione,
                        flagStaff:fStaff,
                        flagAmministrazione:fAmministrazione,
                        idElemento:idIscritto,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })       
                };
                
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
              
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1ScPersonePermessi {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciIscritto===1 ?
                        <Modale 
                            titolo="Richiesta iscritto"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'iscritto, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInfoFlagStaff===1 ?
                        <Modale 
                            titolo="Informativa sul flag staff"
                            flagErrore={true}
                            contenuto={<div>L'iscritto cui viene abilitato il flag 'Staff' avrà la possibilità di operare negli ambiti relativi a.....</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInfoFlagAmministrazione===1 ?
                        <Modale 
                            titolo="Informativa sul flag ammistrazione"
                            flagErrore={true}
                            contenuto={<div>L'iscritto cui viene abilitato il flag 'Amministrazione' avrà la possibilità di operare negli ambiti relativi a.....</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                
                        <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Aggiungi Iscritto con Permessi Staff e/o Amministrazione</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Iscritto con Permessi Staff e/o Amministrazione</CardTitle> :""}
                  
                          
                            <Clessidra loading={loading}/>
                                {props.ope === "cancella" ?
                                    <FinestraDialogo 
                                        titolo="Conferma eliminazione"
                                        flagErrore={true}                                
                                        contenuto={<div>
                                            Confermi l'eliminazione dell' iscritto <b>{insPersonaEdi}</b> dalla lista degli iscritti con permessi ?<br/>                                    
                                        </div>}
                                        bottoni={[
                                            {
                                                "etichetta":"Conferma",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                            },
                                            {
                                                "etichetta":"Annulla",
                                                "tipo":"primary",
                                                callback: () => {tornaElenco()}
                                            }
                                        ]}
                                    />                        

                                :

                                <>
                                <Row form>
                                {props.ope === "inserisci" ?
                                
                                    <Col md={9}>
                                        <Label>Iscritto*</Label>  
                                        <TendinaEditabile
                                                //titolo={"Iscritto*"}
                                                nomeCampo="COGNOME"
                                                nomeCampo2="NOME"
                                                nomeCampoParentesi="NUMERO_TESSERA"
                                                nomeCampoNonVisibile = "ID"
                                                bloccoInserimento = "1"
                                                valoreDefault={insPersonaEdi}
                                                arrayValori={risultatoServerDatiPersona}
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={callbacksetValoriPersona} 
                                        ></TendinaEditabile>
                                    </Col>
                               
                                :
                                    <Fragment>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="cognome">Cognome</Label>
                                            <Label>: {cognomeDefault}</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="nome">Nome</Label>
                                            <Label>: {nomeDefault}</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="nome">N. Tessera</Label>
                                            <Label>: {tesseraNumeroDefault}</Label>
                                        </FormGroup>
                                    </Col>
                                    </Fragment>
                                }
                           
                                    <Col md={1}>
                                        <FormGroup>

                                        <Input type="checkbox" name="flagStaff" id="flagStaff"
                                            defaultChecked = {flagStaffDefault === "1" ?  'checked' :""}
                                            innerRef={flagStaff}                                 
                                            placeholder=""
                                            onInput={(e)=>{handleCambiamentoMod(e);setFlagStaffDefault(e.target.value)}}/>  
                                        
                                        <Label for="flagStaff">Staff</Label>
                                        <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoFlagStaff(true)}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                        
                                        
                                        <Input type="checkbox" name="flagAmministrazione" id="flagAmministrazione"
                                            defaultChecked = {flagAmministrazioneDefault === "1" ?  'checked' :""}
                                            innerRef={flagAmministrazione}                                 
                                            placeholder=""
                                            onInput={(e)=>{handleCambiamentoMod(e);setFlagAmministrazioneDefault(e.target.value)}}/>  
                                        
                                        <Label for="flagStaff">Amministrazione</Label>
                                        <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoFlagAmministrazione(true)}/>
            
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }
                                

                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" && insIdPersonaEdi !== '' && insIdPersonaEdi!==-1 ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                              
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            

                    </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
