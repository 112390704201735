
function controllaDate(anno,mese,giorno){
    let controlloDate = 0;
    while (controlloDate === 0) {
      if(isNaN(Date.parse(anno+'-'+mese+'-'+giorno))){
        giorno = giorno - 1;
      }else{
        controlloDate = 1;
      }
    }
    return anno+'-'+mese+'-'+giorno;
  }
  export default controllaDate;