import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input , Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import TendinaEditabile from '../TendinaEditabile.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import Interrogativo from '../../immagini/interrogativo-bianco.png';
import InterrogativoAzzurro from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
//const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record
const STATO_CONFERMA_E_SELEZIONA = 7;


function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;
    
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const [flagAttivaModaleInserisciIscritto, setFlagAttivaModaleInserisciIscritto] = useState(0);
    const [flagAttivaModaleInserisciEnte, setFlagAttivaModaleInserisciEnte] = useState(0);
    const [flagAttivaModaleInserisciCategoria, setFlagAttivaModaleInserisciCategoria] = useState(0);
    const [flagAttivaModaleErroreCategoria, setFlagAttivaModaleErroreCategoria] = useState(0);
    const [flagAttivaModaleInserisciTipo, setFlagAttivaModaleInserisciTipo] = useState(0);
    const [flagAttivaModaleErroreTipo, setFlagAttivaModaleErroreTipo] = useState(0);
    const [flagAttivaModaleErroreEnte, setFlagAttivaModaleErroreEnte] = useState(0);
    const [flagAttivaModaleInfoGeneriche, setFlagAttivaModaleInfoGeneriche] = useState(0);
    const [flagAttivaModaleInfoCategorie, setFlagAttivaModaleInfoCategorie] = useState(0);
    const [flagAttivaModaleInfoTipo, setFlagAttivaModaleInfoTipo] = useState(0);
    const [flagAttivaModaleInfoNumTesseraEnte, setFlagAttivaModaleInfoNumTesseraEnte] = useState(0);
    const [flagAttivaModaleInfoCriterioScelta, setFlagAttivaModaleInfoCriterioScelta] = useState(0);

    const [statoRicercaEnti,setStatoRicercaEnti]=useState(false);
    const [statoRicercaPersone,setStatoRicercaPersone]=useState(false);

    const [risultatoServerDatiEnti,setRisultatoServerDatiEnti]=useState(false);
    const [risultatoServerDatiPersone,setRisultatoServerDatiPersone]=useState(false);

    const [statoTendinaCategorie,setStatoTendinaCategorie]=useState(false);
    const [statoTendinaTipi,setStatoTendinaTipi]=useState(false);
    const [risultatoServerDatiCategorie,setRisultatoServerDatiCategorie]=useState('');
    const [risultatoServerDatiTipi,setRisultatoServerDatiTipi]=useState('');

    const [personeSelezionate, setPersoneSelezionate] = useState([]);
    const [personeFiltrate, setPersoneFiltrate] = useState([]);
    const [numeroPersoneSelezionate, setNumeroPersoneSelezionate] = useState(0);

    //const [entiSelezionati, setEntiSelezionati] = useState([]);

    const [enteSelezionato, setEnteSelezionato] = useState('');
    const [idEnteSelezionato, setIdEnteSelezionato] = useState([]);
    
    //const [testoRicercaEnti, setTestoRicercaEnti] = useState("");
    const [testoRicercaPersone, setTestoRicercaPersone] = useState("");

    const  dataTesseramento = useRef();
    const [dataTesseramentoDefault,setDataTesseramentoDefault]=useState(dataOggi);

    const [arrayNumeroTesseraEnte,setArrayNumeroTesseraEnte]=useState(['']);
    const [arrayTipoTesseramento,setArrayTipoTesseramento]=useState(['']);
    const [arrayCategoriaTesseramento,setArrayCategoriaTesseramento]=useState(['']);
    const [arrayIdTipoTesseramento,setArrayIdTipoTesseramento]=useState(['']);
    const [arrayIdCategoriaTesseramento,setArrayIdCategoriaTesseramento]=useState(['']);

    //const [ritornaScheda,setRitornaScheda]=useState(0);
    //const [ritornoPrimaPagina,setRitornoPrimaPagina]=useState(0);
    //const [ritornoSecondaPagina,setRitornoSecondaPagina]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
//console.log("chiamo lo useeffect su personeSelezionate")
        var c=0;
        for (let i=0; i<personeSelezionate.length; i++) if (personeSelezionate[i]) c++;
        setNumeroPersoneSelezionate(c);        
    },[personeSelezionate])

    /*useEffect(() => {
        if(ritornoPrimaPagina === 1){       
            setFlagAttivaModaleErroreEnte(1);
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
            setRitornoPrimaPagina(0);
        }
    },[ritornoPrimaPagina])*/

    useEffect(() => {
        if(statoRicercaEnti === true && statoRicercaPersone === true && statoTendinaTipi === true && statoTendinaCategorie === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
        if(statoRicercaPersone === true){
            let fs=[];
            for (let i=0; i<risultatoServerDatiPersone.length; i++) {
                fs[i]=true;
            }
            setPersoneFiltrate(fs);
        }
    },[statoRicercaEnti,statoRicercaPersone,statoTendinaCategorie,statoTendinaTipi]);


    /*useEffect(() => {
        setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
    },[ritornaScheda]);*/

    useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI);
        ricercaDatiEnti();
        ricercaDatiPersone();
        ricercaDatiCategorie();
        ricercaDatiTipi();
    },[props.ope]);

    

    function annullaInserimentoDatiNuovi(parametro){

        setFlagAttivaModaleInserisciIscritto(0);
        setFlagAttivaModaleInserisciEnte(0);
        setFlagAttivaModaleInserisciCategoria(0);
        setFlagAttivaModaleErroreCategoria(0);
        setFlagAttivaModaleInserisciTipo(0);
        setFlagAttivaModaleErroreTipo(0);
        setFlagAttivaModaleErroreEnte(0);
        setFlagAttivaModaleInfoGeneriche(0);
        setFlagAttivaModaleInfoCategorie(0);
        setFlagAttivaModaleInfoTipo(0);
        setFlagAttivaModaleInfoNumTesseraEnte(0);
        setFlagAttivaModaleInfoCriterioScelta(0);
        //a seconda del parametro ritorno in nella pagina iniziale di selezione o in quella successiva con il prospetto dei selezionati
        if(parametro === 0) setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        if(parametro === 1) setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
    }
    function attivaModaleInfoGeneriche(){
        setFlagAttivaModaleInfoGeneriche(1);
    }
    function attivaModaleInfoCategorie(){
        setFlagAttivaModaleInfoCategorie(1);
    }
    function attivaModaleInfoTipo(){
        setFlagAttivaModaleInfoTipo(1);
    }
    function attivaModaleInfoNumTesseraEnte(){
        setFlagAttivaModaleInfoNumTesseraEnte(1);
    }
    function attivaModaleInfoCriterioScelta(){
        setFlagAttivaModaleInfoCriterioScelta(1);
    }

    function ricercaDatiEnti() {
        //ricerco gli enti e le federazioni:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoFederEntiTesser",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiEnti(valoreDiRitorno.risultatoRitorno);
                setStatoRicercaEnti(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiPersone() {
        //ricerco tutti gli iscritti:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"reportTesseramenti",tuttiDati:"1",elencaSoloAttivi:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiPersone(valoreDiRitorno.risultatoRitorno);
                let arrLocale=[];
                let arrLocale1=[];
                let arrLocale2=[];
                let arrLocale3=[];
                let arrLocale4=[];
                for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                    arrLocale[i]=valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA_ENTE;
                    setArrayNumeroTesseraEnte(arrLocale);
                    arrLocale1[i]=valoreDiRitorno.risultatoRitorno[i].IDTIPO_TESSERAMENTO;
                    setArrayIdTipoTesseramento(arrLocale1);
                    arrLocale2[i]=valoreDiRitorno.risultatoRitorno[i].IDCATEGORIA;
                    setArrayIdCategoriaTesseramento(arrLocale2);
                    arrLocale3[i]=valoreDiRitorno.risultatoRitorno[i].CATEGORIA;
                    setArrayCategoriaTesseramento(arrLocale3);
                    arrLocale4[i]=valoreDiRitorno.risultatoRitorno[i].TIPO_TESSERAMENTO;
                    setArrayTipoTesseramento(arrLocale4);
                }   
                setStatoRicercaPersone(true);
            }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

      function callbackSetValoreTendEditabileEnti(ente,posizione,idente){
        
        let presenteEnte=0;
        for (let ix=0; ix<risultatoServerDatiEnti.length ; ix++) {
            if(risultatoServerDatiEnti[ix]["ID"] === idente){
                presenteEnte=1;
                break;
            } 
        }
        if(presenteEnte === 1){
            setIdEnteSelezionato(idente);
            setEnteSelezionato(ente);
        //ora prendo le persone legate a questo ente e le metto nelle selezionate:
            var perSel = [];
            for (let i=0; i<risultatoServerDatiPersone.length; i++) {
                if(risultatoServerDatiPersone[i].IDENTE === idente) perSel[i]=i+1;//metto +1 così ha sempre un valore >0 e non rompe
            }
            setPersoneSelezionate(perSel);
        }else{
            //console.log("NPEnte pe->"+posizione+"v->"+ente);
            //attivo la modale di avviso
            if(ente !== ''){
                //setStatoPagina(STATO_MEMORIZZAZIONE);
                setEnteSelezionato('');
                setIdEnteSelezionato('');
                setPersoneSelezionate([""]);
                //setRitornoPrimaPagina(1);
            }
        }
    }

    function ricercaDatiCategorie(operazione) {
        //ricerco gli enti e le federazioni che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoCatTesseramento",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiCategorie(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaCategorie(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiTipi(operazione) {
        //ricerco gli enti e le federazioni che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoTipiTesseram",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTipi(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaTipi(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function callbackSetVariazioneCampi(){}

    /*function aggiornaCategorie(campoid,posizione){
        let dati1 = [...arrayIdCategoriaTesseramento];//popolo l'array locale con i dati dell'array di stato
        dati1.splice(posizione,1,campoid);//modifico l'elemento desiderato
        setArrayIdCategoriaTesseramento(dati1);//aggiorno nuovamente la var di stato
    }*/

    function callbackSetValoreTendEditabileCategorie(valore,posizione,campoid){
        /*let presenteCategoria=0;
        for (let ix=0; ix<risultatoServerDatiCategorie.length ; ix++) {
            if(risultatoServerDatiCategorie[ix]["ID"] === campoid){
                presenteCategoria=1;
                break;
            } 
        }*/
        //if(presenteCategoria === 1){
            let dati = [...arrayCategoriaTesseramento];//popolo l'array locale con i dati dell'array di stato
            dati.splice(posizione,1,valore);//modifico l'elemento desiderato
            setArrayCategoriaTesseramento(dati);//aggiorno nuovamente la var di stato

            let dati1 = [...arrayIdCategoriaTesseramento];//popolo l'array locale con i dati dell'array di stato
            dati1.splice(posizione,1,campoid);//modifico l'elemento desiderato
            setArrayIdCategoriaTesseramento(dati1);//aggiorno nuovamente la var di stato
        //}
    }
    function callbackSetValoreTendEditabileTipi(valore,posizione,campoid){
        /*let presenteTipo=0;

        for (let ix=0; ix<risultatoServerDatiTipi.length ; ix++) {
            if(risultatoServerDatiTipi[ix]["ID"] === campoid){
                presenteTipo=1;
                break;
            } 
        }*/
        //if(presenteTipo === 1){
            let dati = [...arrayTipoTesseramento];//popolo l'array locale con i dati dell'array di stato
            dati.splice(posizione,1,valore);//modifico l'elemento desiderato
            setArrayTipoTesseramento(dati);//aggiorno nuovamente la var di stato

            let dati1 = [...arrayIdTipoTesseramento];//popolo l'array locale con i dati dell'array di stato
            dati1.splice(posizione,1,campoid);//modifico l'elemento desiderato
            setArrayIdTipoTesseramento(dati1);//aggiorno nuovamente la var di stato
        //}
    }
    function aggiornaNumeroTesseraEnte(valore,posizione){
        let dati = [...arrayNumeroTesseraEnte];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
//console.log("posizione"+posizione);
//console.log("NT"+valore);
        setArrayNumeroTesseraEnte(dati);//aggiorno nuovamente la var di stato
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //controllo l'ente selezionato se fà parte davvero di quelli presenti nel db:
        let presenteEnte = -1;
        if(idEnteSelezionato !== ''){
            presenteEnte = 0;
            //controllo se la scheda allenamento non è presente nell'elenco attuale:
            for (let i=0; i<risultatoServerDatiEnti.length ; i++) {
                if(risultatoServerDatiEnti[i].ID === idEnteSelezionato){
                    presenteEnte=1;
                    break;
                } 
            }
            if(presenteEnte === 0){
                //setStatoPagina(STATO_MEMORIZZAZIONE);
                setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
                setFlagAttivaModaleErroreEnte(1);
                controlli = 1;
            } 
        }

        //controllo che tutte le categorie siano state valorizzate
        let presenteCategoria = -1;

        for (let i=0; i<arrayIdCategoriaTesseramento.length; i++) {
            if (personeSelezionate[i]){
                presenteCategoria = 0;

                if(arrayCategoriaTesseramento[i]===''){
                    controlli = 1;
                    setFlagAttivaModaleInserisciCategoria(1);
                }else{
                    for (let ix=0; ix<risultatoServerDatiCategorie.length ; ix++) {
                        if(risultatoServerDatiCategorie[ix]["DESCRIZIONE"] === arrayCategoriaTesseramento[i]){
                            presenteCategoria=1;
                            break;
                        } 
                    }
                    if(presenteCategoria === 0){
                        //setStatoPagina(STATO_MEMORIZZAZIONE);
                        setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
                        //aggiorno i dati togliendo quello errato:
                        let dati = [...arrayCategoriaTesseramento];//popolo l'array locale con i dati dell'array di stato
                        dati.splice(i,1,"");//modifico l'elemento desiderato
                        setArrayCategoriaTesseramento(dati);//aggiorno nuovamente la var di stato
                        let dati1 = [...arrayIdCategoriaTesseramento];//popolo l'array locale con i dati dell'array di stato
                        dati1.splice(i,1,"");//modifico l'elemento desiderato mettendo vuoto
                        setArrayIdCategoriaTesseramento(dati1);//aggiorno nuovamente la var di stato
                        setFlagAttivaModaleErroreCategoria(1);
                        controlli = 1;
                    } 
                }
            }
        }
        let presenteTipo = -1;
        //controllo che tutti i tipi siano stati valorizzati
       
        for (let i=0; i<arrayIdTipoTesseramento.length; i++) {
            if (personeSelezionate[i]){
                presenteTipo = 0;
                if(arrayTipoTesseramento[i]===''){
                    controlli = 1;
                    setFlagAttivaModaleInserisciTipo(1);
                }else{
                    for (let ix=0; ix<risultatoServerDatiTipi.length ; ix++) {
                        if(risultatoServerDatiTipi[ix]["DESCRIZIONE"] === arrayTipoTesseramento[i]){
                            presenteTipo=1;
                            break;
                        } 
                    }
                    if(presenteTipo === 0){
                    
                        //aggiorno i dati togliendo quello errato:
                        let dati = [...arrayTipoTesseramento];//popolo l'array locale con i dati dell'array di stato
                        dati.splice(i,1,"");//modifico l'elemento desiderato
                        setArrayTipoTesseramento(dati);//aggiorno nuovamente la var di stato
                        let dati1 = [...arrayIdTipoTesseramento];//popolo l'array locale con i dati dell'array di stato
                        dati1.splice(i,1,"");//modifico l'elemento desiderato mettendo vuoto
                        setArrayIdTipoTesseramento(dati1);//aggiorno nuovamente la var di stato
                        setFlagAttivaModaleErroreTipo(1);
                        controlli = 1;
                    } 
                }
            }
        }
            
        
        if(controlli===0){ 
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body:  
                    JSON.stringify({op:"tesseramMassivo",
                        risultatoServerDatiPersone: risultatoServerDatiPersone,
                        personeSelezionate : personeSelezionate,
                        idEnte:idEnteSelezionato,
                        numTesseraEnte: arrayNumeroTesseraEnte,
                        idCategoria:arrayIdCategoriaTesseramento,
                        idTipo:arrayIdTipoTesseramento,
                        dataInizio:dataTesseramento.current.value,
                        emak: props.emak,
                        sessionId:props.sessionId
                    }) 
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1){
                            //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                            //tornaElenco();
                            props.callbackRinnTesseramenti();
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Indicare l'iscritto da tendina") !== -1){
                                    //setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
                                    setFlagAttivaModaleInserisciIscritto(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Indicare l'ente da tendina") !== -1){
                                    //setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
                                    setFlagAttivaModaleInserisciEnte(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Indicare la categoria da tendina") !== -1){
                                    //setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
                                    setFlagAttivaModaleInserisciCategoria(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Indicare il tipo da tendina") !== -1){
                                    //setStatoPagina(STATO_CONFERMA_E_SELEZIONA);
                                    setFlagAttivaModaleInserisciTipo(1);
                                }  
                                
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } 
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            
        }
    }

    function trasformaDateAUmano(data) {
        let dataOutput='';
        //da formato aaaa-mm-gg a gg/mm/aaaa oppure date senza anno del tipo mm-gg le rigiro in gg/mm
        if(data){
          //console.log("Arriva"+props.data)
          //controllo il tipo di dato:
          if(data.length === 10){
            //console.log("Arriva"+props.data)
            const arrdata = data.split('-');
            let anno = arrdata[0];
            let mese = arrdata[1];
            let giorno = arrdata[2];
    
            dataOutput = giorno+"/"+mese+"/"+anno;
          }
          if(data.length === 5){
            const arrdata = data.split('-');
            let mese = arrdata[0];
            let giorno = arrdata[1];
            dataOutput = giorno+"/"+mese;
          }
          //console.log("RITORNO"+dataOutput)
          if(data.length !== 10 && data.length !== 5 ) return -1;
    
        }else dataOutput = '';
    
        return dataOutput;
     
    }
   
        return (
            <Fragment>
             
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1RinnTess {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}


                {statoPagina === STATO_CONFERMA_E_SELEZIONA ?
                    <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    {flagAttivaModaleInserisciIscritto===1 ?
                        <Modale 
                            titolo="Richiesta iscritto"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'iscritto, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciEnte===1 ?
                        <Modale 
                            titolo="Richiesta federazione / ente di tesseramento"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la federazione / ente di tesseramento, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""}
                    {flagAttivaModaleInserisciTipo===1 ?
                        <Modale 
                            titolo="Richiesta tipo di tesseramento"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato il tipo di tesseramento, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleErroreTipo===1 ?
                        <Modale 
                            titolo="Informativa errore tipo di tesseramento"
                            flagErrore={true}
                            contenuto={<div>Il tipo di tesseramento deve essere valorizzato scegliendo tra uno dei valori presenti in tendina</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleErroreEnte===1 ?
                        <Modale 
                            titolo="Informativa errore federazione / ente di tesseramento"
                            flagErrore={true}
                            contenuto={<div>La federazione / ente di tesseramento deve essere valorizzata scegliendo tra uno dei valori presenti in tendina</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""} 
                               
                    {flagAttivaModaleInserisciCategoria===1 ?
                        <Modale 
                            titolo="Richiesta categoria"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la categoria, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleErroreCategoria===1 ?
                        <Modale 
                            titolo="Info errore categoria"
                            flagErrore={true}
                            contenuto={<div>La categoria deve essere valorizzata scegliendo tra uno dei valori presenti in tendina.</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""} 
                    

                    {flagAttivaModaleInfoGeneriche=== 1 ?
                        <Modale 
                            titolo="Informativa generica"
                            flagErrore={false}
                            contenuto={<div>La data di fine tesseramento verrà impostata in modo automatico a distanza di un anno rispetto alla data di inizio tesseramento indicata</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInfoCategorie=== 1 ?
                        <Modale 
                            titolo="Informativa categorie"
                            flagErrore={false}
                            contenuto={<div>Nel caso di iscritti che abbiano già indicata la categoria in quanto già precedentemente iscritti all' ente in esame, qualora non vi fosse una modifica del dato nel prospetto presentato, questa rimane costante anche nel nuovo tesseramento</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInfoTipo=== 1 ?
                        <Modale 
                            titolo="Informativa tipi di tesseramento"
                            flagErrore={false}
                            contenuto={<div>Nel caso di iscritti che abbiano già indicata il tipo di tesseramento in quanto già precedentemente iscritti all' ente in esame, qualora non vi fosse una modifica del dato nel prospetto presentato, questo rimane costante anche nel nuovo tesseramento</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInfoNumTesseraEnte=== 1 ?
                        <Modale 
                            titolo="Informativa numero tessera ente"
                            flagErrore={false}
                            contenuto={<div>Il numero di tessera presentato nel prospetto è quello associato all'iscritto nell'ultimo tesseramento riscontrato per l'ente in esame. Qualora venga variato tale dato, il nuovo numero di tesseramento a tale ente verrà applicato al nuovo tesseramento lasciando inalterati i dati relativi a tesseramenti precedenti</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(1)}
                                }    
                            ]}
                        />
                    :""}

                    

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            <CardTitle>Tesseramenti per {enteSelezionato} in data 
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoGeneriche(true)}/>
                            <FormGroup>
                                <Input type="date" name="dataTesseramento" id="dataTesseramento"  defaultValue={dataTesseramentoDefault} innerRef={dataTesseramento}
                                placeholder="" onInput={(e)=>{setDataTesseramentoDefault(dataTesseramento.current.value)}}></Input>
                            </FormGroup>
                            </CardTitle>
                            
                            
                            <Clessidra loading={loading}/>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup> 
                                            <Table bordered className="mb-0">
                                            <tbody>  
                                            {/*<tr><td>Cognome</td><td>Nome</td><td>N. Tessera</td><td>Ente</td><td>Num. Tessera Ente</td><td>Tipo</td><td>Categoria</td><td>Data Inizio</td><td>Data Fine</td></tr>*/}
                                            <tr><td>Cognome</td><td>Nome</td><td>N. Tessera</td>
                                            <td>Num. Tessera Ente
                                            <img src={InterrogativoAzzurro} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoNumTesseraEnte(true)}/>
                                            </td>
                                            <td>Categoria*
                                            <img src={InterrogativoAzzurro} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoCategorie(true)}/>
                                            </td>
                                            <td>Tipo*
                                            <img src={InterrogativoAzzurro} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoTipo(true)}/>
                                            </td></tr>

                                            {risultatoServerDatiPersone.map((elemento,index) => <Fragment key={index}>
                                                
                                                {personeSelezionate[index] ?   
                                                <tr>
                                                <td>{elemento.COGNOME}</td>
                                                <td>{elemento.NOME}</td>
                                                <td>{elemento.NUMERO_TESSERA}</td>
                                                {elemento.ENTE === enteSelezionato ?
                                                    <Fragment>
                                                    <td><Input maxLength={100} type="text" name="numeroTesseraEnte" id="numeroTesseraEnte" defaultValue={elemento.NUMERO_TESSERA_ENTE}
                                                    placeholder="" onInput={(e)=>{aggiornaNumeroTesseraEnte(e.target.value,index)}}/></td>    
                                                

                                                    {/*<td>
                                                    <select name={arrayCategoriaTesseramento[index]} onChange={(e)=>{aggiornaCategorie(e.target.value,index)}}>
                                                    {risultatoServerDatiCategorie.map((elemento,index2) => 
                                                        <option key={index2} value={risultatoServerDatiCategorie[index2].ID} 
                                                        selected={risultatoServerDatiCategorie[index2].ID == arrayIdCategoriaTesseramento[index]}>
                                                        {risultatoServerDatiCategorie[index2].DESCRIZIONE}</option>
                                                    )};
                                                    </select>
                                                    </td>*/}
                                                    <td><TendinaEditabile
                                                        titolo={""}
                                                        nomeCampo="DESCRIZIONE"
                                                        nomeCampoNonVisibile="ID"
                                                        bloccoInserimento="1"
                                                        valoreDefault={elemento.CATEGORIA}
                                                        arrayValori={risultatoServerDatiCategorie}
                                                        posizioneTendina={index}
                                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCategorie}
                                                        ></TendinaEditabile>
                                                        {risultatoServerDatiCategorie.length===0 ? <Fragment> Esempi di Categorie di Tesseramento: Esordienti, Ragazzi, Cadetti ecc... oppure Pallanuoto Under 12, Pallanuoto Under 14, Nuoto Esordienti A, Nuoto Esordienti B ecc...</Fragment>:""}
                                                    </td>

                                                    
                                                    <td><TendinaEditabile
                                                        titolo={""}
                                                        nomeCampo="DESCRIZIONE"
                                                        nomeCampoNonVisibile="ID"
                                                        bloccoInserimento="1"
                                                        valoreDefault={elemento.TIPO_TESSERAMENTO}
                                                        arrayValori={risultatoServerDatiTipi}
                                                        posizioneTendina={index}
                                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileTipi}
                                                    ></TendinaEditabile>
                                                    {risultatoServerDatiTipi.length===0 ? <Fragment> Esempi di Tipi di Tesseramento: Propaganda, Preagonista, Agonista ecc...</Fragment>:""}
                                                    </td></Fragment>
                                                :
                                                    <Fragment>
                                                    <td><Input maxLength={100} type="text" name="numeroTesseraEnte" id="numeroTesseraEnte" defaultValue={arrayNumeroTesseraEnte[index]}
                                                    placeholder="" onInput={(e)=>{aggiornaNumeroTesseraEnte(e.target.value,index)}}/></td>
                                                    

                                                    {/*<td>
                                                    <select name={arrayCategoriaTesseramento[index]} onChange={(e)=>{aggiornaCategorie(e.target.value,index)}}>
                                                    {risultatoServerDatiCategorie.map((elemento,index2) => 
                                                        <option key={index2} value={risultatoServerDatiCategorie[index2].ID} 
                                                        selected={risultatoServerDatiCategorie[index2].ID == arrayIdCategoriaTesseramento[index]}>
                                                        {risultatoServerDatiCategorie[index2].DESCRIZIONE}</option>
                                                    )};
                                                    </select>
                                                    </td>*/}

                                                    <td><TendinaEditabile
                                                        titolo={""}
                                                        nomeCampo="DESCRIZIONE"
                                                        nomeCampoNonVisibile="ID"
                                                        bloccoInserimento="1"
                                                        posizioneTendina={index}
                                                        valoreDefault={arrayCategoriaTesseramento[index]}
                                                        arrayValori={risultatoServerDatiCategorie}
                                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCategorie}
                                                    ></TendinaEditabile>
                                                    {risultatoServerDatiCategorie.length===0 ? <Fragment> Esempi di Categorie di Tesseramento: Esordienti, Ragazzi, Cadetti ecc... oppure Pallanuoto Under 12, Pallanuoto Under 14, Nuoto Esordienti A, Nuoto Esordienti B ecc...</Fragment>:""}
                                                    </td>


                                                    <td><TendinaEditabile
                                                        titolo={""}
                                                        nomeCampo="DESCRIZIONE"
                                                        nomeCampoNonVisibile="ID"
                                                        bloccoInserimento="1"
                                                        posizioneTendina={index}
                                                        valoreDefault={arrayTipoTesseramento[index]}
                                                        arrayValori={risultatoServerDatiTipi}
                                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileTipi}
                                                    ></TendinaEditabile>
                                                    {risultatoServerDatiTipi.length===0 ? <Fragment> Esempi di Tipi di Tesseramento: Propaganda, Preagonista, Agonista ecc...</Fragment>:""}
                                                    </td></Fragment>
                                                }
                                                </tr>
                                                :""}
                                                 </Fragment>
                                            )}
                                            </tbody> 
                                            </Table>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            
                        </div>

                        <div className="schedaSpazioInferiore">
                            <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Conferma</Button>
                            <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => {setDisabilitaBottoni(true);props.callbackRinnTesseramenti()}}>Annulla</Button>
                        </div>   
                </div>
                :""
                }


                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleErroreEnte===1 ?
                        <Modale 
                            titolo="Informativa numero tessera ente"
                            flagErrore={true}
                            contenuto={<div>La federazione / ente di tesseramento deve essere valorizzata scegliendo tra uno dei valori presenti in tendina</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(0)}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleInfoCriterioScelta=== 1 ?
                        <Modale 
                            titolo="Informativa criterio scelta iscritti"
                            flagErrore={false}
                            contenuto={<div>Selezionando un ente, in automatico vengono selezionati tutti gli iscritti ad esso tesserati resettando le eventuali selezioni eseguite in precedenza. A seguito di tale operazione è possibile andare ad aggiungere eventuali ulteriori iscritti per i quali si tratterebbe quindi di primo nuovo tesseramento all'ente precedentemente scelto.
                            Per una selezione/deselezione più rapida è possibile utilizzare i bottoni Sel.Visibili e Desel.Visibili</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi(0)}
                                }    
                            ]}
                        />
                    :""}

                    {risultatoServerDatiEnti.length===0 ?
                       <Modale 
                       titolo="Feder. / Ente di Tesseramento non presenti"
                       flagErrore={true}
                       contenuto={<div>E' necessario popolare l'elenco di Feder. / Ente di Tesseramento recandosi nel menù principale sezione Tesseramenti, Feder. / Ente di Tesseramento</div>}
                       bottoni={[
                           {
                               "etichetta":"OK",
                               "tipo":"primary",
                               callback:() => {props.callbackRinnTesseramenti()}
                           }    
                       ]}
                   /> 
                    :""}

                    {risultatoServerDatiCategorie.length===0 ?
                       <Modale 
                       titolo="Categorie di Tesseramento non presenti"
                       flagErrore={true}
                       contenuto={<div>E' necessario popolare l'elenco delle Categorie di Tesseramento recandosi nel menù principale sezione Tesseramenti, Categorie di Tesseramento</div>}
                       bottoni={[
                           {
                               "etichetta":"OK",
                               "tipo":"primary",
                               callback:() => {props.callbackRinnTesseramenti()}
                           }    
                       ]}
                   /> 
                    :""}

                    {risultatoServerDatiTipi.length===0 ?
                       <Modale 
                       titolo="Tipi di Tesseramento non presenti"
                       flagErrore={true}
                       contenuto={<div>E' necessario popolare l'elenco dei Tipi di Tesseramento recandosi nel menù principale sezione Tesseramenti, Tipi di Tesseramento</div>}
                       bottoni={[
                           {
                               "etichetta":"OK",
                               "tipo":"primary",
                               callback:() => {props.callbackRinnTesseramenti()}
                           }    
                       ]}
                   /> 
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "rinnova" ? <CardTitle>Inserimento e/o Rinnovamento Massivo Tesseramenti 
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoCriterioScelta()}/>
                            </CardTitle> :""}
                            
                            <Clessidra loading={loading}/>
                            
                                        
                                        <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                                        {/*<div style={{flex:1}}>*/}
                                        <div style={{flex:2}}>
                                        <Label>Feder. / Ente di Tesseramento*</Label>
                                        <TendinaEditabile
                                            //titolo={"Feder. / Ente di Tesseramento*"}
                                            nomeCampo="COGNOME"
                                            nomeCampoParentesi="NOME"
                                            nomeCampoNonVisibile="ID"
                                            bloccoInserimento="1"
                                            valoreDefault={enteSelezionato}
                                            arrayValori={risultatoServerDatiEnti}
                                            callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                            callbackSetValoreTendEditabile={callbackSetValoreTendEditabileEnti}
                                         ></TendinaEditabile>

                                        

                                        {/*<div style={{flex:1}}>*/}
                                        

                                        <br/><b>Filtro Iscritti e relativi tesseramenti</b><br/>
                                            Cerca per Cognome che contiene: <input type='text' style={{width:"100%"}} onChange={(e) => setTestoRicercaPersone(e.target.value.toLowerCase())}/>
                                            <div style={{height:300, backgroundColor:"#EEEEEE", overflow:"auto"}}>
                                            {risultatoServerDatiPersone.map((x,i) => 
                                                personeFiltrate[i] && (testoRicercaPersone==="" || x.COGNOME.toLowerCase().includes(testoRicercaPersone)) ?
                                                <div 
                                                    style={{
                                                        marginLeft:5, 
                                                        backgroundColor:personeSelezionate[i]?"#2222FF":"transparent", 
                                                        color:personeSelezionate[i]?"#EEEEFF":"#000000",
                                                        cursor: 'pointer'   
                                                    }}
                                                    onClick={() => {var ps=[...personeSelezionate];ps[i]=!ps[i];setPersoneSelezionate(ps)}}
                                                >

                                                {risultatoServerDatiPersone[i].COGNOME} {risultatoServerDatiPersone[i].NOME}
                                                {risultatoServerDatiPersone[i].NUMERO_TESSERA !=='' ? " ("+risultatoServerDatiPersone[i].NUMERO_TESSERA+")" : ""}
                                                {risultatoServerDatiPersone[i].ENTE !=='' ? " "+risultatoServerDatiPersone[i].ENTE : " non tesserato"}
                                                {risultatoServerDatiPersone[i].TIPO_TESSERAMENTO !=='' ? " "+risultatoServerDatiPersone[i].TIPO_TESSERAMENTO : " "}
                                                {risultatoServerDatiPersone[i].CATEGORIA !=='' ? " "+risultatoServerDatiPersone[i].CATEGORIA : " "}
                                                
                                                {risultatoServerDatiPersone[i].DATA_INIZIO !=='' || risultatoServerDatiPersone[i].DATA_FINE !=='' ? 
                                                <>&nbsp;(
                                               
                                                {risultatoServerDatiPersone[i].DATA_INIZIO !=='' ? 
                                                    "da "+ trasformaDateAUmano(risultatoServerDatiPersone[i].DATA_INIZIO)
                                                : 
                                                    "da - "}

                                                {risultatoServerDatiPersone[i].DATA_FINE !=='' ? 
                                                    " a "+ trasformaDateAUmano(risultatoServerDatiPersone[i].DATA_FINE)
                                                : 
                                                    "a - "}
                                                
                                                )
                                                </>
                                                : ""}

                                                </div>
                                                : ""
                                            )}   
                                            </div>
                                            </div>
                                        </div>

                                        <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                                        <div style={{flex:2}}>
                                            {numeroPersoneSelezionate>0 ? numeroPersoneSelezionate===1 ? "1 tesseramento selezionato" : numeroPersoneSelezionate+" tesseramenti selezionati" : "Nessun tesseramento selezionato" }
                                        </div>
                                        <div style={{flex:1}}>
                                        <Button color="success" className="mt-2" onClick={() => {
                                            var ps=[...personeSelezionate]; 
                                            for (let i=0; i<risultatoServerDatiPersone.length; i++) if (personeFiltrate[i]) ps[i] = true; 
                                            setPersoneSelezionate(ps);
                                        }}>Seleziona Visibili</Button>
                                        </div>
                                        <div style={{flex:1}}>
                                        <Button color="danger" className="mt-2" onClick={() => {
                                            var ps=[...personeSelezionate]; 
                                            for (let i=0; i<risultatoServerDatiPersone.length; i++) if (personeFiltrate[i]) ps[i] = false; 
                                            setPersoneSelezionate(ps);
                                        }}>Deseleziona Visibili</Button>
                                        </div>
                                        </div>
                                        
                          
                                
                            </div>

                            <div className="schedaSpazioInferiore">
                                {enteSelezionato!==''?
                                    <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(false);setStatoPagina(STATO_CONFERMA_E_SELEZIONA)}}>Inserisci</Button>
                                :""}
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => {setDisabilitaBottoni(true);props.callbackRinnTesseramenti()}}>Torna all'elenco</Button>
                            
                            
                            </div> 
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
