import React, {useEffect, useState,useRef} from 'react';
import { Button,Input } from 'reactstrap';
import Parametri from '../../parametri';
import TrasformaDateAUmano from '../TrasformaDateAUmano';
import FormattaImportoVideo from '../FormattaImportoVideo';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';

const STATO_RICERCA_DATI = 0;

const STATO_VISUALIZZA = 1;
const STATO_OPERAZIONE_FALLITA = 2;
const STATO_INVIO_RIUSCITO = 3;

function SchedaInviaMessaggio(props) {
    const [statoPagina,setStatoPagina]=useState(STATO_VISUALIZZA);
    const [idpersona,setIdpersona]=useState(-1);
    const [cognomeNome,setCognomeNome]=useState("");

    const [messaggio,setMessaggio]=useState("");
    const [elenco,setElenco]=useState([]);

    const testo   = useRef();
    const [testoDefault,setTestoDefault]=useState('');

    const [flagAttivaModaleInserisciTesto, setFlagAttivaModaleInserisciTesto] = useState(0);
    const [flagAttivaModaleInserisciDestinatario, setFlagAttivaModaleInserisciDestinatario] = useState(0);

    useEffect(() => {
        elencoPersone();
    },[]);

    function inviaMessaggio() {

        let controlli=0;
        if(testo.current.value=== ''){
            controlli = 1;
            setFlagAttivaModaleInserisciTesto(1);
        }
        if(idpersona == '-1'){
            controlli = 1;
            setFlagAttivaModaleInserisciDestinatario(1);
        }

        if(controlli===0){ 
            setStatoPagina(STATO_RICERCA_DATI);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: 
                    JSON.stringify({op:"inviaMessaggio",
                    idpersona: idpersona,
                    messaggio: testo.current.value,
                    emak: props.emak,
                    sessionId:props.sessionId
                })       
            };

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione===undefined) { 
                        alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                        setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        return;
                    }
                    if(valoreDiRitorno.risultatoOperazione==-1) {
                        alert("Persona non selezionata, selezionare la persona e poi premere Invia");
                        setStatoPagina(STATO_VISUALIZZA);
                        return;
                    }
                    if(valoreDiRitorno.risultatoOperazione==-2) {
                        alert("La persona selezionata non ha una email valorizzata");
                        setStatoPagina(STATO_VISUALIZZA);
                        return;
                    }
                    if(valoreDiRitorno.risultatoOperazione==-3) {
                        alert("Invio non riuscito");
                        setStatoPagina(STATO_VISUALIZZA);
                        return;
                    }
                    //setElenco(valoreDiRitorno.risultatoRitorno);
                    setStatoPagina(STATO_INVIO_RIUSCITO);
                },
                (error) => {
                    alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                }
            );
        }
    }

    function elencoPersone() {
        setStatoPagina(STATO_RICERCA_DATI);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                JSON.stringify({op:"elencoPersone",
                ridotto: 1,
                emak: props.emak,
                sessionId:props.sessionId
            })       
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===undefined) { 
                    alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione<=0) {
                    alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }            
                setElenco(valoreDiRitorno.risultatoRitorno);                
                setStatoPagina(STATO_VISUALIZZA);
            },
            (error) => {
                alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                setStatoPagina(STATO_OPERAZIONE_FALLITA);
            }
        );
    }

    if (statoPagina===STATO_RICERCA_DATI) {
        return (<Clessidra loading={true}/>)
    }

    if (statoPagina===STATO_OPERAZIONE_FALLITA) {
        return (<div>Operazione fallita</div>)
    }

    if (statoPagina==STATO_VISUALIZZA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                        {flagAttivaModaleInserisciTesto === 1 ?
                        <Modale 
                            titolo="Informativa Testo Messaggio"
                            flagErrore={true}
                            contenuto={<div>Il messaggio ha testo vuoto</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setFlagAttivaModaleInserisciTesto(0)}
                                }    
                            ]}
                        />
                    :""}

                {flagAttivaModaleInserisciDestinatario === 1 ?
                        <Modale 
                            titolo="Informativa Destinatario Messaggio"
                            flagErrore={true}
                            contenuto={<div>Il messaggio non ha destinatario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setFlagAttivaModaleInserisciDestinatario(0)}
                                }    
                            ]}
                        />
                    :""}

                    <div className='schedaSottotitolo'>
                        Invia Messaggio
                    </div>

                    <div style={{display:"flex", flexDirection:"row"}}>
                       
                    <div style={{flex:1}}>
                    Seleziona a chi inviare il messaggio, inserisci il messaggio e premi "Invia" in fondo alla pagina:<br/>

                    <select value={idpersona+","+cognomeNome} onChange={(e) => {
                        const parts = e.target.value.split(',');
                        setIdpersona(parts[0]);
                        setCognomeNome(parts[1]);
                  
                    }}><option value="-1,"></option>
                    {elenco.map((elemento, indice)=> (
                        <option key={indice} value={elemento.ID+","+elemento.COGNOME+" "+elemento.NOME}>{elemento.COGNOME} {elemento.NOME} ({elemento.NUMERO_TESSERA})</option>
                    ))}
                    </select>
                    </div>
                    <div style={{flex:1}}>
                    Testo del messaggio
                    <Input rows="10" maxLength={200} type="textarea" name="testo" id="testo"
                    innerRef={testo} 
                    placeholder=""
                    defaultValue={testoDefault}
                    onInput={(e)=>{setTestoDefault(testo.current.value)}}/> 
                    </div> 
                    </div>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="mt-2" color="primary" onClick={() => { inviaMessaggio() }}>Invia</Button>
                </div>
            </div>             
        )
    }        

    if (statoPagina===STATO_INVIO_RIUSCITO) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Invio Riuscito
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:"#80F080", border:"1px solid #509050", textAlign:'center', padding:"10px"}}>
                        {/*<div style={{backgroundColor:"#206020", color:"#FFFFFF"}}>Invio completato</div>*/}
                        Messaggio: {testoDefault} <br/><br/>inviato a {cognomeNome}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>Errore 1 SchedaInviaMessaggio</div>
    )
}
export default SchedaInviaMessaggio;
