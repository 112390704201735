import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Label,Input,Modal } from 'reactstrap';
import Parametri from '../../parametri';
import arrayColoriGrafico from '../../coloriGrafici';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

//per grafici:
import {Bar} from 'react-chartjs-2';//istogramma a barre verticali
import {Doughnut} from 'react-chartjs-2';//un grafico a torta con stile ciambella
import {Line} from 'react-chartjs-2';//grafico con linea su piano cartesiano
import {Pie} from 'react-chartjs-2';//la classica torta
import {PolarArea} from 'react-chartjs-2';//tipo torta ma con un aspetto grafico più ricercato e tecnologico
import {Radar} from 'react-chartjs-2';//tipo radar con ai vertici le voci; è possibile rappresentare più dataset

import {Chart as ChartJS} from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';

import styles from '../stiliPdf.js';

import logoApp from './../../logoTitolo.jpeg';

import { calcolaAnnoOggi } from '../../funzioni.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;
const COLORE_TITOLI_REPORT="#a5a5a4";

function TabellaBilancio(props) {
  /*
    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;
    */

    const liquiditaInizioAnnoGraficoNettiCanvas = useRef();
    const liquiditaInizioAnnoGraficoIvaCanvas = useRef();
    const liquiditaInizioAnnoGraficoCanvas = useRef();
    const creditiInizioAnnoGraficoNettiCanvas = useRef();
    const creditiInizioAnnoGraficoIvaCanvas = useRef();
    const creditiInizioAnnoGraficoCanvas = useRef();
    const debitiInizioAnnoGraficoNettiCanvas = useRef();
    const debitiInizioAnnoGraficoIvaCanvas = useRef();
    const debitiInizioAnnoGraficoCanvas = useRef();
    const entrateGraficoNettiCanvas = useRef();
    const entrateGraficoIvaCanvas = useRef();
    const entrateGraficoCanvas = useRef();
    const usciteGraficoNettiCanvas = useRef();
    const usciteGraficoIvaCanvas = useRef();
    const usciteGraficoCanvas = useRef();
    const liquiditaFineAnnoGraficoNettiCanvas = useRef();
    const liquiditaFineAnnoGraficoIvaCanvas = useRef();
    const liquiditaFineAnnoGraficoCanvas = useRef();
    const creditiFineAnnoGraficoNettiCanvas = useRef();
    const creditiFineAnnoGraficoIvaCanvas = useRef();
    const creditiFineAnnoGraficoCanvas = useRef();
    const debitiFineAnnoGraficoNettiCanvas = useRef();
    const debitiFineAnnoGraficoIvaCanvas = useRef();         
    const debitiFineAnnoGraficoCanvas = useRef();  

    //larghezza colonne report:

    //tabella soci web:
    const larghColonnaCognomeTS = 3;
    const larghColonnaNomeTS = 3;
    const larghColonnaTesseraTS = 2;
    const larghColonnaPosizioniTS = 2;
    const larghColonnaDataIngressoTS = 1;
    const larghColonnaDataUscitaTS = 1;

    //tabella liquidita inizio anno web, tabella liquidita fine anno web:
    const larghColonnaRisorseLiquidita = 5;
    //tabella crediti inizio anno web, tabella crediti fine anno web:
    const larghColonnaMovimentiCrediti = 5;
    //tabella debiti inizio anno web, tabella debiti fine anno web:
    const larghColonnaMovimentiDebiti = 5;
    //tabella entrate web:
    const larghColonnaVoceEntrate= 5;
    //tabella uscite web:
    const larghColonnaVoceUscite = 5;

    //tabella movimenti web:
    const larghColonnaDataMov = 1;
    const larghColonnaDescrizioneMov = 2;
    const larghColonnaRisorsaMov = 2;
    const larghColonnaVoceMov = 2;

    const [ricercaCompletata,setRicercaCompletata]=useState(0);
 
    const [larghColonnaRisorse,setLarghColonnaRisorse]=useState(12);
    const [larghColonnaTipiFatture,setLarghColonnaTipiFatture]=useState(12);
    const [larghColonnaVoci,setLarghColonnaVoci]=useState(12);
    const larghColonnaDatiRisorse=1;
    const larghColonnaDatiTipiFatture=1;
    const larghColonnaDatiVoci=1;

    const larghColonnaDataMovimenti=1;
    const [larghColonnaDescrizioneMovimenti,setLarghColonnaDescrizioneMovimenti]=useState(2);
    const larghColonnaRisorseMovimenti=1;
    const larghColonnaVociMovimenti=2;
    const larghColonnaDatiMovimenti=2;
    
    const flagVisualizzaImportiNetti   = useRef();
    const flagVisualizzaImportiIva   = useRef();
    const flagVisualizzaImportiLordi   = useRef();
    const [flagVisualizzaImportiNettiDefault,setflagVisualizzaImportiNettiDefault]=useState(1);
    const [flagVisualizzaImportiIvaDefault,setflagVisualizzaImportiIvaDefault]=useState(1);
    const [flagVisualizzaImportiLordiDefault,setflagVisualizzaImportiLordiDefault]=useState(1);

    const  radioPeriodo = useRef();
    const [radioPeriodoDefault,setRadioPeriodoDefault]=useState("1");
    const [visualizzaAltro,setVisualizzaAltro]=useState(0);
    const  visualizzaAltroRif = useRef();
    const operazione = "reportBilancio";
    const  dataPartenza = useRef();
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);

    const [visualizzaInPdf,setVisualizzaInPdf]=useState(false);

    //per paginato:
    const [numeroPagina, setNumeroPagina]=useState(1);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    //const [dataPartenzaDefault,setDataPartenzaDefault]=useState(today.getFullYear());
    const [arrayLiquiditaInizioAnnoRisorse,setArrayLiquiditaInizioAnnoRisorse]=useState([]);
    const [arrayLiquiditaInizioAnnoImporti,setArrayLiquiditaInizioAnnoImporti]=useState([]);
    const [arrayLiquiditaInizioAnnoIva,setArrayLiquiditaInizioAnnoIva]=useState([]);
    const [arrayLiquiditaInizioAnnoImportiNetti,setArrayLiquiditaInizioAnnoImportiNetti]=useState([]);

    const [arrayDebitiInizioAnnoTipiFatture,setArrayDebitiInizioAnnoTipiFatture]=useState([]);
    const [arrayDebitiInizioAnnoImporti,setArrayDebitiInizioAnnoImporti]=useState([]);
    const [arrayDebitiInizioAnnoIva,setArrayDebitiInizioAnnoIva]=useState([]);
    const [arrayDebitiInizioAnnoImportiNetti,setArrayDebitiInizioAnnoImportiNetti]=useState([]);

    const [arrayCreditiInizioAnnoTipiFatture,setArrayCreditiInizioAnnoTipiFatture]=useState([]);
    const [arrayCreditiInizioAnnoImporti,setArrayCreditiInizioAnnoImporti]=useState([]);
    const [arrayCreditiInizioAnnoIva,setArrayCreditiInizioAnnoIva]=useState([]);
    const [arrayCreditiInizioAnnoImportiNetti,setArrayCreditiInizioAnnoImportiNetti]=useState([]);

    const [arrayEntrateAnnoCorsoVoci,setArrayEntrateAnnoCorsoVoci]=useState([]);
    const [arrayEntrateAnnoCorsoImporti,setArrayEntrateAnnoCorsoImporti]=useState([]);
    const [arrayEntrateAnnoCorsoIva,setArrayEntrateAnnoCorsoIva]=useState([]);
    const [arrayEntrateAnnoCorsoImportiNetti,setArrayEntrateAnnoCorsoImportiNetti]=useState([]);

    const [arrayUsciteAnnoCorsoVoci,setArrayUsciteAnnoCorsoVoci]=useState([]);
    const [arrayUsciteAnnoCorsoImporti,setArrayUsciteAnnoCorsoImporti]=useState([]);
    const [arrayUsciteAnnoCorsoIva,setArrayUsciteAnnoCorsoIva]=useState([]);
    const [arrayUsciteAnnoCorsoImportiNetti,setArrayUsciteAnnoCorsoImportiNetti]=useState([]);

    const [arrayLiquiditaFineAnnoRisorse,setArrayLiquiditaFineAnnoRisorse]=useState([]);
    const [arrayLiquiditaFineAnnoImporti,setArrayLiquiditaFineAnnoImporti]=useState([]);
    const [arrayLiquiditaFineAnnoIva,setArrayLiquiditaFineAnnoIva]=useState([]);
    const [arrayLiquiditaFineAnnoImportiNetti,setArrayLiquiditaFineAnnoImportiNetti]=useState([]);

    const [arrayDebitiFineAnnoTipiFatture,setArrayDebitiFineAnnoTipiFatture]=useState([]);
    const [arrayDebitiFineAnnoImporti,setArrayDebitiFineAnnoImporti]=useState([]);
    const [arrayDebitiFineAnnoIva,setArrayDebitiFineAnnoIva]=useState([]);
    const [arrayDebitiFineAnnoImportiNetti,setArrayDebitiFineAnnoImportiNetti]=useState([]);

    const [arrayCreditiFineAnnoTipiFatture,setArrayCreditiFineAnnoTipiFatture]=useState([]);
    const [arrayCreditiFineAnnoImporti,setArrayCreditiFineAnnoImporti]=useState([]);
    const [arrayCreditiFineAnnoIva,setArrayCreditiFineAnnoIva]=useState([]);
    const [arrayCreditiFineAnnoImportiNetti,setArrayCreditiFineAnnoImportiNetti]=useState([]);

    const [arrayMovimentiData,setArrayMovimentiData]=useState([]);
    const [arrayMovimentiDescrizione,setArrayMovimentiDescrizione]=useState([]);
    const [arrayMovimentiRisorsa,setArrayMovimentiRisorsa]=useState([]);
    const [arrayMovimentiVoce,setArrayMovimentiVoce]=useState([]);
    const [arrayMovimentiImporto,setArrayMovimentiImporto]=useState([]);
    const [arrayMovimentiIva,setArrayMovimentiIva]=useState([]);
    const [arrayMovimentiImportoNetto,setArrayMovimentiImportoNetto]=useState([]);

    const [arraySociCognome,setArraySociCognome]=useState([]);
    const [arraySociNome,setArraySociNome]=useState([]);
    const [arraySociTessera,setArraySociTessera]=useState([]);
    const [arraySociPosizioni,setArraySociPosizioni]=useState([]);
    const [arraySociDataIn,setArraySociDataIn]=useState([]);
    const [arraySociDataOut,setArraySociDataOut]=useState([]);

    const [statoTotaleLiquiditaInizioAnno,setStatoTotaleLiquiditaInizioAnno]=useState(0);
    const [statoTotaleLiquiditaInizioAnnoIva,setStatoTotaleLiquiditaInizioAnnoIva]=useState(0);
    const [statoTotaleLiquiditaInizioAnnoImportiNetti,setStatoTotaleLiquiditaInizioAnnoImportiNetti]=useState(0);

    const [statoTotaleCreditiInizioAnno,setStatoTotaleCreditiInizioAnno]=useState(0);
    const [statoTotaleCreditiInizioAnnoIva,setStatoTotaleCreditiInizioAnnoIva]=useState(0);
    const [statoTotaleCreditiInizioAnnoImportiNetti,setStatoTotaleCreditiInizioAnnoImportiNetti]=useState(0);

    const [statoTotaleDebitiInizioAnno,setStatoTotaleDebitiInizioAnno]=useState(0);
    const [statoTotaleDebitiInizioAnnoIva,setStatoTotaleDebitiInizioAnnoIva]=useState(0);
    const [statoTotaleDebitiInizioAnnoImportiNetti,setStatoTotaleDebitiInizioAnnoImportiNetti]=useState(0);
    
    const [statoTotaleCreditiDebitiInizioAnno,setStatoTotaleCreditiDebitiInizioAnno]=useState(0);
    const [statoTotaleCreditiDebitiInizioAnnoIva,setStatoTotaleCreditiDebitiInizioAnnoIva]=useState(0);
    const [statoTotaleCreditiDebitiInizioAnnoImportiNetti,setStatoTotaleCreditiDebitiInizioAnnoImportiNetti]=useState(0);

    const [statoTotaleEntrate,setStatoTotaleEntrate]=useState(0);
    const [statoTotaleEntrateIva,setStatoTotaleEntrateIva]=useState(0);
    const [statoTotaleEntrateImportiNetti,setStatoTotaleEntrateImportiNetti]=useState(0);

    const [statoTotaleUscite,setStatoTotaleUscite]=useState(0);
    const [statoTotaleUsciteIva,setStatoTotaleUsciteIva]=useState(0);
    const [statoTotaleUsciteImportiNetti,setStatoTotaleUsciteImportiNetti]=useState(0);

    const [statoTotaleEntrateUscite,setStatoTotaleEntrateUscite]=useState(0);
    const [statoTotaleEntrateUsciteIva,setStatoTotaleEntrateUsciteIva]=useState(0);
    const [statoTotaleEntrateUsciteImportiNetti,setStatoTotaleEntrateUsciteImportiNetti]=useState(0);

    const [statoTotaleLiquiditaFineAnno,setStatoTotaleLiquiditaFineAnno]=useState(0);
    const [statoTotaleLiquiditaFineAnnoIva,setStatoTotaleLiquiditaFineAnnoIva]=useState(0);
    const [statoTotaleLiquiditaFineAnnoImportiNetti,setStatoTotaleLiquiditaFineAnnoImportiNetti]=useState(0);

    const [statoTotaleCreditiFineAnno,setStatoTotaleCreditiFineAnno]=useState(0);
    const [statoTotaleCreditiFineAnnoIva,setStatoTotaleCreditiFineAnnoIva]=useState(0);
    const [statoTotaleCreditiFineAnnoImportiNetti,setStatoTotaleCreditiFineAnnoImportiNetti]=useState(0);

    const [statoTotaleDebitiFineAnno,setStatoTotaleDebitiFineAnno]=useState(0);
    const [statoTotaleDebitiFineAnnoIva,setStatoTotaleDebitiFineAnnoIva]=useState(0);
    const [statoTotaleDebitiFineAnnoImportiNetti,setStatoTotaleDebitiFineAnnoImportiNetti]=useState(0);

    const [statoTotaleCreditiDebitiFineAnno,setStatoTotaleCreditiDebitiFineAnno]=useState(0);
    const [statoTotaleCreditiDebitiFineAnnoIva,setStatoTotaleCreditiDebitiFineAnnoIva]=useState(0);
    const [statoTotaleCreditiDebitiFineAnnoImportiNetti,setStatoTotaleCreditiDebitiFineAnnoImportiNetti]=useState(0);

    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    //per grafici:
    const [dataLiquiditaAdInizioAnnoNetti,setDataLiquiditaAdInizioAnnoNetti]=useState([]);
    const [dataLiquiditaAdInizioAnnoIva,setDataLiquiditaAdInizioAnnoIva]=useState([]);
    const [dataLiquiditaAdInizioAnno,setDataLiquiditaAdInizioAnno]=useState([]);
    const [dataCreditiAdInizioAnnoNetti,setDataCreditiAdInizioAnnoNetti]=useState([]);
    const [dataCreditiAdInizioAnnoIva,setDataCreditiAdInizioAnnoIva]=useState([]);
    const [dataCreditiAdInizioAnno,setDataCreditiAdInizioAnno]=useState([]);
    const [dataDebitiAdInizioAnnoNetti,setDataDebitiAdInizioAnnoNetti]=useState([]);
    const [dataDebitiAdInizioAnnoIva,setDataDebitiAdInizioAnnoIva]=useState([]);
    const [dataDebitiAdInizioAnno,setDataDebitiAdInizioAnno]=useState([]);
    const [dataEntrateNetti,setDataEntrateNetti]=useState([]);
    const [dataEntrateIva,setDataEntrateIva]=useState([]);
    const [dataEntrate,setDataEntrate]=useState([]);
    const [dataUsciteNetti,setDataUsciteNetti]=useState([]);
    const [dataUsciteIva,setDataUsciteIva]=useState([]);
    const [dataUscite,setDataUscite]=useState([]);
    const [dataLiquiditaAFineAnnoNetti,setDataLiquiditaAFineAnnoNetti]=useState([]);
    const [dataLiquiditaAFineAnnoIva,setDataLiquiditaAFineAnnoIva]=useState([]);
    const [dataLiquiditaAFineAnno,setDataLiquiditaAFineAnno]=useState([]);
    const [dataCreditiAFineAnnoNetti,setDataCreditiAFineAnnoNetti]=useState([]);
    const [dataCreditiAFineAnnoIva,setDataCreditiAFineAnnoIva]=useState([]);
    const [dataCreditiAFineAnno,setDataCreditiAFineAnno]=useState([]);
    const [dataDebitiAFineAnnoNetti,setDataDebitiAFineAnnoNetti]=useState([]);
    const [dataDebitiAFineAnnoIva,setDataDebitiAFineAnnoIva]=useState([]);
    const [dataDebitiAFineAnno,setDataDebitiAFineAnno]=useState([]);

    //consensi visualizzazione grafici:
    const [visualizzaTuttiGrafici,setVisualizzaTuttiGrafici]=useState(1);
    const [visualizzaLiquiditaAdInizioAnno,setVisualizzaLiquiditaAdInizioAnno]=useState(1);
    const [visualizzaCreditiAdInizioAnno,setVisualizzaCreditiAdInizioAnno]=useState(1);
    const [visualizzaDebitiAdInizioAnno,setVisualizzaDebitiAdInizioAnno]=useState(1);
    const [visualizzaEntrate,setVisualizzaEntrate]=useState(1);
    const [visualizzaUscite,setVisualizzaUscite]=useState(1);
    const [visualizzaLiquiditaAFineAnno,setVisualizzaLiquiditaAFineAnno]=useState(1);
    const [visualizzaCreditiAFineAnno,setVisualizzaCreditiAFineAnno]=useState(1);
    const [visualizzaDebitiAFineAnno,setVisualizzaDebitiAFineAnno]=useState(1);

    //tipologie di grafici per ogni sezione:
    const [tipoGraficoLiquiditaAdInizioAnno,setTipoGraficoLiquiditaAdInizioAnno]=useState("");
    const [tipoGraficoCreditiAdInizioAnno,setTipoGraficoCreditiAdInizioAnno]=useState("");
    const [tipoGraficoDebitiAdInizioAnno,setTipoGraficoDebitiAdInizioAnno]=useState("");
    const [tipoGraficoEntrate,setTipoGraficoEntrate]=useState("");
    const [tipoGraficoUscite,setTipoGraficoUscite]=useState("");
    const [tipoGraficoLiquiditaAFineAnno,setTipoGraficoLiquiditaAFineAnno]=useState("");
    const [tipoGraficoCreditiAFineAnno,setTipoGraficoCreditiAFineAnno]=useState("");
    const [tipoGraficoDebitiAFineAnno,setTipoGraficoDebitiAFineAnno]=useState("");
    
    //consensi visualizzazione tabelle:
    const [visualizzaTutteTabelle,setVisualizzaTutteTabelle]=useState(1);
    const [visualizzaLiquiditaAdInizioAnnoTab,setVisualizzaLiquiditaAdInizioAnnoTab]=useState(1);
    const [visualizzaCreditiAdInizioAnnoTab,setVisualizzaCreditiAdInizioAnnoTab]=useState(1);
    const [visualizzaDebitiAdInizioAnnoTab,setVisualizzaDebitiAdInizioAnnoTab]=useState(1);
    const [visualizzaEntrateTab,setVisualizzaEntrateTab]=useState(1);
    const [visualizzaUsciteTab,setVisualizzaUsciteTab]=useState(1);
    const [visualizzaLiquiditaAFineAnnoTab,setVisualizzaLiquiditaAFineAnnoTab]=useState(1);
    const [visualizzaCreditiAFineAnnoTab,setVisualizzaCreditiAFineAnnoTab]=useState(1);
    const [visualizzaDebitiAFineAnnoTab,setVisualizzaDebitiAFineAnnoTab]=useState(1);
    const [visualizzaMovimentiTab,setVisualizzaMovimentiTab]=useState(1);
    const [visualizzaSociTab,setVisualizzaSociTab]=useState(1);

    const [arrayRisorseTopImportiLiquiditaInizioAnno,setArrayRisorseTopImportiLiquiditaInizioAnno]=useState([]);
    const [arrayTopImportiLiquiditaInizioAnno,setArrayTopImportiLiquiditaInizioAnno]=useState([]);
    const [arrayRisorseTopIvaLiquiditaInizioAnno,setArrayRisorseTopIvaLiquiditaInizioAnno]=useState([]);
    const [arrayTopIvaLiquiditaInizioAnno,setArrayTopIvaLiquiditaInizioAnno]=useState([]);
    const [arrayRisorseTopNettoLiquiditaInizioAnno,setArrayRisorseTopNettoLiquiditaInizioAnno]=useState([]);
    const [arrayTopNettoLiquiditaInizioAnno,setArrayTopNettoLiquiditaInizioAnno]=useState([]);

    const [arrayTipologieTopImportiCreditiInizioAnno,setArrayTipologieTopImportiCreditiInizioAnno]=useState([]);
    const [arrayTopImportiCreditiInizioAnno,setArrayTopImportiCreditiInizioAnno]=useState([]);
    const [arrayTipologieTopIvaCreditiInizioAnno,setArrayTipologieTopIvaCreditiInizioAnno]=useState([]);
    const [arrayTopIvaCreditiInizioAnno,setArrayTopIvaCreditiInizioAnno]=useState([]);
    const [arrayTipologieTopNettoCreditiInizioAnno,setArrayTipologieTopNettoCreditiInizioAnno]=useState([]);
    const [arrayTopNettoCreditiInizioAnno,setArrayTopNettoCreditiInizioAnno]=useState([]);

    const [arrayTipologieTopImportiDebitiInizioAnno,setArrayTipologieTopImportiDebitiInizioAnno]=useState([]);
    const [arrayTopImportiDebitiInizioAnno,setArrayTopImportiDebitiInizioAnno]=useState([]);
    const [arrayTipologieTopIvaDebitiInizioAnno,setArrayTipologieTopIvaDebitiInizioAnno]=useState([]);
    const [arrayTopIvaDebitiInizioAnno,setArrayTopIvaDebitiInizioAnno]=useState([]);
    const [arrayTipologieTopNettoDebitiInizioAnno,setArrayTipologieTopNettoDebitiInizioAnno]=useState([]);
    const [arrayTopNettoDebitiInizioAnno,setArrayTopNettoDebitiInizioAnno]=useState([]);

    const [arrayVociTopImportiEntrate,setArrayVociTopImportiEntrate]=useState([]);
    const [arrayTopImportiEntrate,setArrayTopImportiEntrate]=useState([]);
    const [arrayVociTopIvaEntrate,setArrayVociTopIvaEntrate]=useState([]);
    const [arrayTopIvaEntrate,setArrayTopIvaEntrate]=useState([]);
    const [arrayVociTopNettoEntrate,setArrayVociTopNettoEntrate]=useState([]);
    const [arrayTopNettoEntrate,setArrayTopNettoEntrate]=useState([]);

    const [arrayVociTopImportiUscite,setArrayVociTopImportiUscite]=useState([]);
    const [arrayTopImportiUscite,setArrayTopImportiUscite]=useState([]);
    const [arrayVociTopIvaUscite,setArrayVociTopIvaUscite]=useState([]);
    const [arrayTopIvaUscite,setArrayTopIvaUscite]=useState([]);
    const [arrayVociTopNettoUscite,setArrayVociTopNettoUscite]=useState([]);
    const [arrayTopNettoUscite,setArrayTopNettoUscite]=useState([]);

    const [arrayRisorseTopImportiLiquiditaFineAnno,setArrayRisorseTopImportiLiquiditaFineAnno]=useState([]);
    const [arrayTopImportiLiquiditaFineAnno,setArrayTopImportiLiquiditaFineAnno]=useState([]);
    const [arrayRisorseTopIvaLiquiditaFineAnnoUscite,setArrayRisorseTopIvaLiquiditaFineAnno]=useState([]);
    const [arrayTopIvaLiquiditaFineAnno,setArrayTopIvaLiquiditaFineAnno]=useState([]);
    const [arrayRisorseTopNettoLiquiditaFineAnno,setArrayRisorseTopNettoLiquiditaFineAnno]=useState([]);
    const [arrayTopNettoLiquiditaFineAnno,setArrayTopNettoLiquiditaFineAnno]=useState([]);

    const [arrayTipologieTopImportiCreditiFineAnno,setArrayTipologieTopImportiCreditiFineAnno]=useState([]);
    const [arrayTopImportiCreditiFineAnno,setArrayTopImportiCreditiFineAnno]=useState([]);
    const [arrayTipologieTopIvaCreditiFineAnno,setArrayTipologieTopIvaCreditiFineAnno]=useState([]);
    const [arrayTopIvaCreditiFineAnno,setArrayTopIvaCreditiFineAnno]=useState([]);
    const [arrayTipologieTopNettoCreditiFineAnno,setArrayTipologieTopNettoCreditiFineAnno]=useState([]);
    const [arrayTopNettoCreditiFineAnno,setArrayTopNettoCreditiFineAnno]=useState([]);

    const [arrayTipologieTopImportiDebitiFineAnno,setArrayTipologieTopImportiDebitiFineAnno]=useState([]);
    const [arrayTopImportiDebitiFineAnno,setArrayTopImportiDebitiFineAnno]=useState([]);
    const [arrayTipologieTopIvaDebitiFineAnno,setArrayTipologieTopIvaDebitiFineAnno]=useState([]);
    const [arrayTopIvaDebitiFineAnno,setArrayTopIvaDebitiFineAnno]=useState([]);
    const [arrayTipologieTopNettoDebitiFineAnno,setArrayTipologieTopNettoDebitiFineAnno]=useState([]);
    const [arrayTopNettoDebitiFineAnno,setArrayTopNettoDebitiFineAnno]=useState([]);

    const [cambiamento,setCambiamento] = useState(0);
    const [ricercaImpostazioniReport,setRicercaImpostazioniReport] = useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);
    
    //nomi dei tipi di grafici: (se cambi vai a modificare anche le select dei tipi che ora sono in ordine alfabetico secondo quete deciture)
    let descriDoughnut = "Ciambella";
    let descriLine = "Funzione";
    let descriBar = "Istogramma verticale";
    let descriPolarArea = "Polare";
    let descriRadar = "Radar";
    let descriPie =  "Torta";

    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);
 
    const [topDefault,setTopDefault]=useState('10');
    const top = useRef();

    const coloriGraficoSfondo = arrayColoriGrafico;
    const coloriGraficoSfondoIva = arrayColoriGrafico;
    const coloriGraficoSfondoNetti = arrayColoriGrafico;

    const coloriGraficoBordo = 'rgb(0,0,0)';
    const coloriGraficoBordoIva = 'rgb(0,0,0)';
    const coloriGraficoBordoNetti = 'rgb(0,0,0)';

    const larghezzaBordoGrafico = 1;
    
    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const  TGLiqIni = useRef();
    const  TGCreIni = useRef();
    const  TGDebIni = useRef();
    const  TGEntr = useRef();
    const  TGUsc = useRef();
    const  TGLiqFine = useRef();
    const  TGCreFine = useRef();
    const  TGDebFine = useRef();

    //modale per attivar le impostazioni:
    const [modaleImpostazioni, setModaleImpostazioni] = React.useState(false);

    const [loading,setLoading]= useState(false);

    const [graficoLiquiditaInizioAnnoNetti,setGraficoLiquiditaInizioAnnoNetti]=useState(0);
    const [graficoLiquiditaInizioAnnoIva,setGraficoLiquiditaInizioAnnoIva]=useState(0);
    const [graficoLiquiditaInizioAnno,setGraficoLiquiditaInizioAnno]=useState(0);
    const [graficoCreditiInizioAnnoNetti,setGraficoCreditiInizioAnnoNetti]=useState(0);
    const [graficoCreditiInizioAnnoIva,setGraficoCreditiInizioAnnoIva]=useState(0);
    const [graficoCreditiInizioAnno,setGraficoCreditiInizioAnno]=useState(0);
    const [graficoDebitiInizioAnnoNetti,setGraficoDebitiInizioAnnoNetti]=useState(0);
    const [graficoDebitiInizioAnnoIva,setGraficoDebitiInizioAnnoIva]=useState(0);
    const [graficoDebitiInizioAnno,setGraficoDebitiInizioAnno]=useState(0);
    const [graficoEntrateAnnoInCorsoNetti,setGraficoEntrateAnnoInCorsoNetti]=useState(0);
    const [graficoEntrateAnnoInCorsoIva,setGraficoEntrateAnnoInCorsoIva]=useState(0);
    const [graficoEntrateAnnoInCorso,setGraficoEntrateAnnoInCorso]=useState(0);
    const [graficoUsciteAnnoInCorsoNetti,setGraficoUsciteAnnoInCorsoNetti]=useState(0);
    const [graficoUsciteAnnoInCorsoIva,setGraficoUsciteAnnoInCorsoIva]=useState(0);
    const [graficoUsciteAnnoInCorso,setGraficoUsciteAnnoInCorso]=useState(0);
    const [graficoLiquiditaFineAnnoNetti,setGraficoLiquiditaFineAnnoNetti]=useState(0);
    const [graficoLiquiditaFineAnnoIva,setGraficoLiquiditaFineAnnoIva]=useState(0);
    const [graficoLiquiditaFineAnno,setGraficoLiquiditaFineAnno]=useState(0);
    const [graficoCreditiFineAnnoNetti,setGraficoCreditiFineAnnoNetti]=useState(0);
    const [graficoCreditiFineAnnoIva,setGraficoCreditiFineAnnoIva]=useState(0);
    const [graficoCreditiFineAnno,setGraficoCreditiFineAnno]=useState(0);
    const [graficoDebitiFineAnnoNetti,setGraficoDebitiFineAnnoNetti]=useState(0);
    const [graficoDebitiFineAnnoIva,setGraficoDebitiFineAnnoIva]=useState(0);
    const [graficoDebitiFineAnno,setGraficoDebitiFineAnno]=useState(0);
    
    const [tableColStato,setTableColStato]=useState(0);
    const [tableColStatoMovimenti,setTableColStatoMovimenti]=useState(0);


    {/*const styles = StyleSheet.create({
      page: {flexDirection: 'row',paddingBottom: 50,paddingTop: 50,},
      titolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1, fontSize: 18},
      titoloPiccolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1.8, fontSize: 10},
      image: {height: 150,width: 200},
      logo: {height: 60,width: 80,objectFit: 'contain'},
      nomesocieta: {fontFamily: 'Helvetica-Bold',marginLeft: 1,marginBottom: 5, fontSize: 22,},
      viewer: {height: '100vh',width: '150vh'},

      table: {display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0,margin: 10, }, 
      tableIntestazione: { display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0,margin: 10,}, 
      tableRowGrigia: { margin: "auto", flexDirection: "row",height: "auto",backgroundColor : "silver",}, 
      tableRow: { margin: "auto", flexDirection: "row",height: "auto",}, 
      tableRowIntestazione: { margin: "auto", flexDirection: "row",height: 60,}, 
      tableRowBlu: { margin: "auto", flexDirection: "row",backgroundColor : "#99cbff",height: "auto",}, 
      tableColIntestazioneSx: { width: 100/3+"%", borderLeftWidth: 0, borderTopWidth: 0},
      tableColIntestazioneDx: { width: 2*100/3+"%", borderLeftWidth: 0, borderTopWidth: 0 },
      tableCol: { width: 100/4+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
      tableCell: { margin: "auto", fontSize: 10, },
      tableCellIntestazione: { marginLeft: 0,fontSize: 10},
      tableCellGrassettoAllineatoSinistra: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoSinistraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginBottom: 3},
      tableCellGrassettoAllineatoDestra: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoDestraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginBottom: 3},
      tableCellAllineatoSinistra: {textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellAllineatoDestra: {textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},

      titoloTabella: {fontFamily: 'Helvetica-Bold',margin: 10}, 

      grafici: {width: 300,height: 300,},
      graficiLunghi: {width: 400,height: 200,},
      pageNumber:{position: "absolute",fontSize: 12,bottom:30,left:0,right:0,textAlign:'center',color:"gray"}
    });*/}

    useEffect(() => {
      let sommaColonne=1;
        let sommaColonneMovimenti=4;//nella tabella dei movimenti ho fisse le colonne data/descrizione/risorsa/voce di bilancio
        let larghColonnaRisorseLocale = 12;
        let larghColonnaTipiFattureLocale = 12;
        let larghColonnaVociLocale = 12;

        if(flagVisualizzaImportiNettiDefault === 1){
          sommaColonne = sommaColonne + 1;
          sommaColonneMovimenti = sommaColonneMovimenti + 1;
          larghColonnaRisorseLocale = larghColonnaRisorseLocale - 1;
          larghColonnaTipiFattureLocale = larghColonnaTipiFattureLocale - 1;
          larghColonnaVociLocale = larghColonnaVociLocale - 1;
        } 
        if(flagVisualizzaImportiIvaDefault === 1){
          sommaColonne = sommaColonne + 1;
          sommaColonneMovimenti = sommaColonneMovimenti + 1;
          larghColonnaRisorseLocale = larghColonnaRisorseLocale - 1;
          larghColonnaTipiFattureLocale = larghColonnaTipiFattureLocale - 1;
          larghColonnaVociLocale = larghColonnaVociLocale - 1;
        } 
        if(flagVisualizzaImportiLordiDefault === 1){
          sommaColonne = sommaColonne + 1;
          sommaColonneMovimenti = sommaColonneMovimenti + 1;
          larghColonnaRisorseLocale = larghColonnaRisorseLocale - 1;
          larghColonnaTipiFattureLocale = larghColonnaTipiFattureLocale - 1;
          larghColonnaVociLocale = larghColonnaVociLocale - 1;
        } 
        setTableColStato(sommaColonne);
        setTableColStatoMovimenti(sommaColonneMovimenti);
        setLarghColonnaRisorse(larghColonnaRisorseLocale);
        setLarghColonnaTipiFatture(larghColonnaTipiFattureLocale);
        setLarghColonnaVoci(larghColonnaVociLocale);

      if(statoPagina === STATO_PREPARA_PDF){
        setStatoPagina(STATO_SCARICA_PDF);
      }
    },[statoPagina,flagVisualizzaImportiNettiDefault,flagVisualizzaImportiIvaDefault,flagVisualizzaImportiLordiDefault]);

    useEffect(() => {
      if(visualizzaInPdf === true){
        //handleEsportaPDF(dataPartenzaDefault,inizioPeriodo,finePeriodo);
      }
    },[visualizzaInPdf]);

    useEffect(() => { 
      //grafico liquidità inizio anno
      setDataLiquiditaAdInizioAnnoNetti({labels: [''],
          datasets: [{label: 'Liquidità ad inizio anno (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataLiquiditaAdInizioAnnoIva({labels: [''],
          datasets: [{label: 'Liquidità ad inizio anno (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataLiquiditaAdInizioAnno({labels: [''],
          datasets: [{label: 'Liquidità ad inizio anno (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataCreditiAdInizioAnnoNetti({labels: [''],
          datasets: [{label: 'Crediti ad inizio anno (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataCreditiAdInizioAnnoIva({labels: [''],
          datasets: [{label: 'Crediti ad inizio anno (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataCreditiAdInizioAnno({labels: [''],
          datasets: [{label: 'Crediti ad inizio anno (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataDebitiAdInizioAnnoNetti({labels: [''],
          datasets: [{label: 'Debiti ad inizio anno (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataDebitiAdInizioAnnoIva({labels: [''],
          datasets: [{label: 'Debiti ad inizio anno (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataDebitiAdInizioAnno({labels: [''],
          datasets: [{label: 'Debiti ad inizio anno (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataEntrateNetti({labels: [''],
          datasets: [{label: 'Entrate (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataEntrateIva({labels: [''],
          datasets: [{label: 'Entrate (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataEntrate({labels: [''],
          datasets: [{label: 'Entrate (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataUsciteNetti({labels: [''],
          datasets: [{label: 'Uscite (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataUsciteIva({labels: [''],
          datasets: [{label: 'Uscite (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataUscite({labels: [''],
          datasets: [{label: 'Uscite (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataLiquiditaAFineAnnoNetti({labels: [''],
          datasets: [{label: 'Liquidità a fine anno (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataLiquiditaAFineAnnoIva({labels: [''],
          datasets: [{label: 'Liquidità a fine anno (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataLiquiditaAFineAnno({labels: [''],
          datasets: [{label: 'Liquidità a fine anno (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataCreditiAFineAnnoNetti({labels: [''],
          datasets: [{label: 'Crediti a fine anno (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataCreditiAFineAnnoIva({labels: [''],
          datasets: [{label: 'Crediti a fine anno (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataCreditiAFineAnno({labels: [''],
          datasets: [{label: 'Crediti a fine anno (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      setDataDebitiAFineAnnoNetti({labels: [''],
          datasets: [{label: 'Debiti a fine anno (Importo Netto)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataDebitiAFineAnnoIva({labels: [''],
          datasets: [{label: 'Debiti a fine anno (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataDebitiAFineAnno({labels: [''],
          datasets: [{label: 'Debiti a fine anno (Importo Lordo)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
          
      scaricaLogo();
      trovaImpostazioniReport();      
      },[])

      useEffect(() => {
        if(ricercaCompletata === 1
          && dataLiquiditaAdInizioAnnoNetti
          && dataLiquiditaAdInizioAnnoIva
          && dataLiquiditaAdInizioAnno
          && dataCreditiAdInizioAnnoNetti
          && dataCreditiAdInizioAnnoIva
          && dataCreditiAdInizioAnno
          && dataDebitiAdInizioAnnoNetti
          && dataDebitiAdInizioAnnoIva
          && dataDebitiAdInizioAnno
          && dataEntrateNetti
          && dataEntrateIva
          && dataEntrate
          && dataUsciteNetti
          && dataUsciteIva
          && dataUscite
          && dataLiquiditaAFineAnnoNetti
          && dataLiquiditaAFineAnnoIva
          && dataLiquiditaAFineAnno
          && dataCreditiAFineAnnoNetti
          && dataCreditiAFineAnnoIva
          && dataCreditiAFineAnno
          && dataDebitiAFineAnnoNetti
          && dataDebitiAFineAnnoIva
          && dataDebitiAFineAnno
          && ricercaLogo === 1
          && ricercaImpostazioniReport === 1
          ){
          setStatoPagina(STATO_OPERAZIONE_CORRETTA);
          setRicercaCompletata(0);
        }
      },[ricercaCompletata,ricercaLogo,ricercaImpostazioniReport])

      useEffect(() => {
        //grafico liquidità inizio anno
        if(arrayRisorseTopNettoLiquiditaInizioAnno.length>0){
        setDataLiquiditaAdInizioAnnoNetti({labels: arrayRisorseTopNettoLiquiditaInizioAnno,
        datasets: [
        {
            label: '',
            backgroundColor: coloriGraficoSfondoNetti,
            borderColor: coloriGraficoBordoNetti,
            borderWidth: larghezzaBordoGrafico,
            hoverBackgroundColor: arrayColoriGrafico,
            hoverBorderColor: 'rgba(255,99,132,1)',
            borderCapStyle: 'round',
            data: arrayTopNettoLiquiditaInizioAnno
        }
        ]});
      }
      },[arrayTopNettoLiquiditaInizioAnno])

      useEffect(() => {
        //grafico liquidità inizio anno
        if(arrayRisorseTopIvaLiquiditaInizioAnno.length>0){
        setDataLiquiditaAdInizioAnnoIva({labels: arrayRisorseTopIvaLiquiditaInizioAnno,
        datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoIva,
          borderColor: coloriGraficoBordoIva,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopIvaLiquiditaInizioAnno
      },
        ]});
      }
      },[arrayTopIvaLiquiditaInizioAnno])

      useEffect(() => {
        //grafico liquidità inizio anno
        if(arrayRisorseTopImportiLiquiditaInizioAnno.length>0){
        setDataLiquiditaAdInizioAnno({labels: arrayRisorseTopImportiLiquiditaInizioAnno,
        datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondo,
        borderColor: coloriGraficoBordo,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopImportiLiquiditaInizioAnno
    }
        ]});
      }
      },[arrayTopImportiLiquiditaInizioAnno])

    useEffect(() => {
      //grafico crediti inizio anno
      if(arrayTipologieTopNettoCreditiInizioAnno.length>0){

      setDataCreditiAdInizioAnnoNetti({labels: arrayTipologieTopNettoCreditiInizioAnno,
      datasets: [
      {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopNettoCreditiInizioAnno
      }
      ]});
    }
    },[arrayTopNettoCreditiInizioAnno])

    useEffect(() => {
      //grafico crediti inizio anno
      if(arrayTipologieTopIvaCreditiInizioAnno.length>0){
      
      setDataCreditiAdInizioAnnoIva({labels: arrayTipologieTopIvaCreditiInizioAnno,
      datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondoIva,
        borderColor: coloriGraficoBordoIva,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopIvaCreditiInizioAnno
    }
      ]});
    }
    },[arrayTopIvaCreditiInizioAnno])

    useEffect(() => {
      //grafico crediti inizio anno
      if(arrayTipologieTopImportiCreditiInizioAnno.length>0){

      setDataCreditiAdInizioAnno({labels: arrayTipologieTopImportiCreditiInizioAnno,
      datasets: [
      
    {
      label: '',
      backgroundColor: coloriGraficoSfondo,
      borderColor: coloriGraficoBordo,
      borderWidth: larghezzaBordoGrafico,
      hoverBackgroundColor: arrayColoriGrafico,
      hoverBorderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'round',
      data: arrayTopImportiCreditiInizioAnno
  }
      ]});
    }
    },[arrayTopImportiCreditiInizioAnno])

    useEffect(() => {
      //grafico debiti inizio anno
      if(arrayTipologieTopNettoDebitiInizioAnno.length>0){
      setDataDebitiAdInizioAnnoNetti({labels: arrayTipologieTopNettoDebitiInizioAnno,
      datasets: [
      {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopNettoDebitiInizioAnno
      }
      ]});
    }
    },[arrayTopNettoDebitiInizioAnno])

    useEffect(() => {
      //grafico debiti inizio anno
      if(arrayTipologieTopIvaDebitiInizioAnno.length>0){
      setDataDebitiAdInizioAnnoIva({labels: arrayTipologieTopIvaDebitiInizioAnno,
      datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondoIva,
        borderColor: coloriGraficoBordoIva,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopIvaDebitiInizioAnno
    }
      ]});
    }
    },[arrayTopIvaDebitiInizioAnno])

    useEffect(() => {
      //grafico debiti inizio anno
      if(arrayTipologieTopImportiDebitiInizioAnno.length>0){
      setDataDebitiAdInizioAnno({labels: arrayTipologieTopImportiDebitiInizioAnno,
      datasets: [
    {
      label: '',
      backgroundColor: coloriGraficoSfondo,
      borderColor: coloriGraficoBordo,
      borderWidth: larghezzaBordoGrafico,
      hoverBackgroundColor: arrayColoriGrafico,
      hoverBorderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'round',
      data: arrayTopImportiDebitiInizioAnno
  }
      ]});
    }
    },[arrayTopImportiDebitiInizioAnno])

    useEffect(() => {
      //grafico entrate anno in corso
      if(arrayVociTopNettoEntrate.length>0){
      setDataEntrateNetti({labels: arrayVociTopNettoEntrate,
      datasets: [
      {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopNettoEntrate
      }
      ]});
    }
    },[arrayTopNettoEntrate])

    useEffect(() => {
      //grafico entrate anno in corso
      if(arrayVociTopIvaEntrate.length>0){
      setDataEntrateIva({labels: arrayVociTopIvaEntrate,
      datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondoIva,
        borderColor: coloriGraficoBordoIva,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopIvaEntrate
    }
      ]});
    }
    },[arrayTopIvaEntrate])

    useEffect(() => {
      //grafico entrate anno in corso
      if(arrayVociTopImportiEntrate.length>0){
      setDataEntrate({labels: arrayVociTopImportiEntrate,
      datasets: [
    {
      label: '',
      backgroundColor: coloriGraficoSfondo,
      borderColor: coloriGraficoBordo,
      borderWidth: larghezzaBordoGrafico,
      hoverBackgroundColor: arrayColoriGrafico,
      hoverBorderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'round',
      data: arrayTopImportiEntrate
  },
      ]});
    }
    },[arrayTopImportiEntrate])

    useEffect(() => {
      //grafico uscite anno in corso
      if(arrayVociTopNettoUscite.length>0){
      setDataUsciteNetti({labels: arrayVociTopNettoUscite,
      datasets: [
      {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopNettoUscite
      }
      ]});
    }
    },[arrayTopNettoUscite])

    useEffect(() => {
      //grafico uscite anno in corso
      if(arrayVociTopIvaUscite.length>0){
      setDataUsciteIva({labels: arrayVociTopIvaUscite,
      datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondoIva,
        borderColor: coloriGraficoBordoIva,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopIvaUscite
    }
      ]});
    }
    },[arrayTopIvaUscite])

    useEffect(() => {
      //grafico uscite anno in corso
      if(arrayVociTopImportiUscite.length>0){
      setDataUscite({labels: arrayVociTopImportiUscite,
      datasets: [
    {
      label: '',
      backgroundColor: coloriGraficoSfondo,
      borderColor: coloriGraficoBordo,
      borderWidth: larghezzaBordoGrafico,
      hoverBackgroundColor: arrayColoriGrafico,
      hoverBorderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'round',
      data: arrayTopImportiUscite
  }
      ]});
    }
    },[arrayTopImportiUscite])

    useEffect(() => {
      //grafico liquidità fine anno
      if(arrayRisorseTopNettoLiquiditaFineAnno.length>0){
      setDataLiquiditaAFineAnnoNetti({labels: arrayRisorseTopNettoLiquiditaFineAnno,
      datasets: [
      {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopNettoLiquiditaFineAnno
      }
      ]});
    }
    },[arrayTopNettoLiquiditaFineAnno])

    useEffect(() => {
      //grafico liquidità fine anno
      if(arrayRisorseTopIvaLiquiditaFineAnnoUscite.length>0){
      setDataLiquiditaAFineAnnoIva({labels: arrayRisorseTopIvaLiquiditaFineAnnoUscite,
      datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondoIva,
        borderColor: coloriGraficoBordoIva,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopIvaLiquiditaFineAnno
    }
      ]});
    }
    },[arrayTopIvaLiquiditaFineAnno])

    useEffect(() => {
      //grafico liquidità fine anno
      if(arrayRisorseTopImportiLiquiditaFineAnno.length>0){
      setDataLiquiditaAFineAnno({labels: arrayRisorseTopImportiLiquiditaFineAnno,
      datasets: [
    {
      label: '',
      backgroundColor: coloriGraficoSfondo,
      borderColor: coloriGraficoBordo,
      borderWidth: larghezzaBordoGrafico,
      hoverBackgroundColor: arrayColoriGrafico,
      hoverBorderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'round',
      data: arrayTopImportiLiquiditaFineAnno
  },
      ]});
    }
    },[arrayTopImportiLiquiditaFineAnno])

    useEffect(() => {
      //grafico crediti fine anno
      if(arrayTipologieTopNettoCreditiFineAnno.length>0){
      setDataCreditiAFineAnnoNetti({labels: arrayTipologieTopNettoCreditiFineAnno,
      datasets: [
      {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopNettoCreditiFineAnno
      }
      ]});
    }
    },[arrayTopNettoCreditiFineAnno])

    useEffect(() => {
      //grafico crediti fine anno
      if(arrayTipologieTopIvaCreditiFineAnno.length>0){
      setDataCreditiAFineAnnoIva({labels: arrayTipologieTopIvaCreditiFineAnno,
      datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondoIva,
        borderColor: coloriGraficoBordoIva,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopIvaCreditiFineAnno
    }
      ]});
    }
    },[arrayTopIvaCreditiFineAnno])

    useEffect(() => {
      //grafico crediti fine anno
      if(arrayTipologieTopImportiCreditiFineAnno.length>0){
      setDataCreditiAFineAnno({labels: arrayTipologieTopImportiCreditiFineAnno,
      datasets: [
    {
      label: '',
      backgroundColor: coloriGraficoSfondo,
      borderColor: coloriGraficoBordo,
      borderWidth: larghezzaBordoGrafico,
      hoverBackgroundColor: arrayColoriGrafico,
      hoverBorderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'round',
      data: arrayTopImportiCreditiFineAnno
  },
      ]});
    }
    },[arrayTopImportiCreditiFineAnno])

    useEffect(() => {
      //grafico debiti fine anno
      if(arrayTipologieTopNettoDebitiFineAnno.length>0){
      setDataDebitiAFineAnnoNetti({labels: arrayTipologieTopNettoDebitiFineAnno,
      datasets: [
      {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTopNettoDebitiFineAnno
      }
      ]});
    }
    },[arrayTopNettoDebitiFineAnno])

    useEffect(() => {
      //grafico debiti fine anno
      if(arrayTipologieTopIvaDebitiFineAnno.length>0){
      setDataDebitiAFineAnnoIva({labels: arrayTipologieTopIvaDebitiFineAnno,
      datasets: [
      {
        label: '',
        backgroundColor: coloriGraficoSfondoIva,
        borderColor: coloriGraficoBordoIva,
        borderWidth: larghezzaBordoGrafico,
        hoverBackgroundColor: arrayColoriGrafico,
        hoverBorderColor: 'rgba(255,99,132,1)',
        borderCapStyle: 'round',
        data: arrayTopIvaDebitiFineAnno
    }
      ]});
    }
    },[arrayTopIvaDebitiFineAnno])

    useEffect(() => {
      //grafico debiti fine anno
      if(arrayTipologieTopImportiDebitiFineAnno.length>0){
      setDataDebitiAFineAnno({labels: arrayTipologieTopImportiDebitiFineAnno,
      datasets: [
    {
      label: '',
      backgroundColor: coloriGraficoSfondo,
      borderColor: coloriGraficoBordo,
      borderWidth: larghezzaBordoGrafico,
      hoverBackgroundColor: arrayColoriGrafico,
      hoverBorderColor: 'rgba(255,99,132,1)',
      borderCapStyle: 'round',
      data: arrayTopImportiDebitiFineAnno
  }
      ]});
    }
    },[arrayTopImportiDebitiFineAnno])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI && ricercaImpostazioniReport === 1){
        setGraficoLiquiditaInizioAnnoNetti(0);
        setGraficoLiquiditaInizioAnnoIva(0);
        setGraficoLiquiditaInizioAnno(0);
        setGraficoCreditiInizioAnnoNetti(0);
        setGraficoCreditiInizioAnnoIva(0);
        setGraficoCreditiInizioAnno(0);
        setGraficoDebitiInizioAnnoNetti(0);
        setGraficoDebitiInizioAnnoIva(0);
        setGraficoDebitiInizioAnno(0);
        setGraficoEntrateAnnoInCorsoNetti(0);
        setGraficoEntrateAnnoInCorsoIva(0);
        setGraficoEntrateAnnoInCorso(0);
        setGraficoUsciteAnnoInCorsoNetti(0);
        setGraficoUsciteAnnoInCorsoIva(0);
        setGraficoUsciteAnnoInCorso(0);
        setGraficoLiquiditaFineAnnoNetti(0);
        setGraficoLiquiditaFineAnnoIva(0);
        setGraficoLiquiditaFineAnno(0);
        setGraficoCreditiFineAnnoNetti(0);
        setGraficoCreditiFineAnnoIva(0);
        setGraficoCreditiFineAnno(0);
        setGraficoDebitiFineAnnoNetti(0);
        setGraficoDebitiFineAnnoIva(0);
        setGraficoDebitiFineAnno(0);

        // parametri per inviare i dati al server:
        //console.log("RICERCA");
        setLoading(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            sogliaTop:topDefault,
            visualizzaAltro:visualizzaAltro,
            recordPartenza:numeroPagina,
            dataPartenza:dataPartenzaDefault,
            radioPeriodo:radioPeriodoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              setLoading(false);
              //console.log("RISULTATO"+valoreDiRitorno.risultatoOperazione);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale1=[];//liquiditaInizioAnno -> risorse
                let arrLocale2=[];//liquiditaInizioAnno -> importi
                let arrLocale2a=[];//liquiditaInizioAnno -> iva
                let arrLocale2b=[];//liquiditaInizioAnno -> importi netti

                let arrLocale3=[];//debitiInizioAnno -> tipo quota
                let arrLocale4=[];//debitiInizioAnno -> importi
                let arrLocale4a=[];//debitiInizioAnno -> iva
                let arrLocale4b=[];//debitiInizioAnno -> importi netti

                let arrLocale5=[];//creditiInizioAnno -> tipo quota
                let arrLocale6=[];//creditiInizioAnno -> importi
                let arrLocale6a=[];//creditiInizioAnno -> iva
                let arrLocale6b=[];//creditiInizioAnno -> importi netti

                let arrLocale7=[];//entrateAnnoInCorso -> voce di entrata
                let arrLocale8=[];//entrateAnnoInCorso -> importi
                let arrLocale8a=[];//entrateAnnoInCorso -> iva
                let arrLocale8b=[];//entrateAnnoInCorso -> importi netti

                let arrLocale9=[];//usciteAnnoInCorso ->  voce di uscita
                let arrLocale10=[];//usciteAnnoInCorso -> importi
                let arrLocale10a=[];//usciteAnnoInCorso -> iva
                let arrLocale10b=[];//usciteAnnoInCorso -> importi netti

                let arrLocale11=[];//liquiditaFineAnno -> risorse
                let arrLocale12=[];//liquiditaFineAnno -> importi
                let arrLocale12a=[];//liquiditaFineAnno -> iva
                let arrLocale12b=[];//liquiditaFineAnno -> importi netti

                let arrLocale13=[];//debitiFineAnno -> tipo quota
                let arrLocale14=[];//debitiFineAnno -> importi
                let arrLocale14a=[];//debitiFineAnno -> iva
                let arrLocale14b=[];//debitiFineAnno -> importi netti

                let arrLocale15=[];//creditiFineAnno -> tipo quota
                let arrLocale16=[];//creditiFineAnno -> importi
                let arrLocale16a=[];//creditiFineAnno -> iva
                let arrLocale16b=[];//creditiFineAnno -> importi netti

                let arrLocale17=[];//movimentiAnnoInCorso -> data
                let arrLocale18=[];//movimentiAnnoInCorso -> descrizione movimento
                let arrLocale19=[];//movimentiAnnoInCorso -> risorsa
                let arrLocale20=[];//movimentiAnnoInCorso -> voce
                let arrLocale21=[];//movimentiAnnoInCorso -> importo
                let arrLocale21a=[];//movimentiAnnoInCorso -> iva
                let arrLocale21b=[];//movimentiAnnoInCorso -> importo netti

                let arrLocale22=[];//sociAnnoInCorso -> cognome
                let arrLocale23=[];//sociAnnoInCorso -> nome
                let arrLocale24=[];//sociAnnoInCorso -> numero tessera
                let arrLocale25=[];//sociAnnoInCorso -> data in
                let arrLocale26=[];//sociAnnoInCorso -> data out
                let arrLocale27=[];//sociAnnoInCorso -> posizioni

                //per i grafici:
                let arrLocale1topImportiLiquiditaInizioAnno=[];
                let arrLocale5TopImportiLiquiditaInizioAnno=[];
                let arrLocale1topIvaLiquiditaInizioAnno=[];
                let arrLocale5TopIvaLiquiditaInizioAnno=[];
                let arrLocale1topNettoLiquiditaInizioAnno=[];
                let arrLocale5TopNettoLiquiditaInizioAnno=[];
                let arrLocale1topImportiCreditiInizioAnno=[];
                let arrLocale5TopImportiCreditiInizioAnno=[];
                let arrLocale1topIvaCreditiInizioAnno=[];
                let arrLocale5TopIvaCreditiInizioAnno=[];
                let arrLocale1topNettoCreditiInizioAnno=[];
                let arrLocale5TopNettoCreditiInizioAnno=[];
                let arrLocale1topImportiDebitiInizioAnno=[];
                let arrLocale5TopImportiDebitiInizioAnno=[];
                let arrLocale1topIvaDebitiInizioAnno=[];
                let arrLocale5TopIvaDebitiInizioAnno=[];
                let arrLocale1topNettoDebitiInizioAnno=[];
                let arrLocale5TopNettoDebitiInizioAnno=[];
                let arrLocale1topImportiEntrate=[];
                let arrLocale5TopImportiEntrate=[];
                let arrLocale1topIvaEntrate=[];
                let arrLocale5TopIvaEntrate=[];
                let arrLocale1topNettoEntrate=[];
                let arrLocale5TopNettoEntrate=[];
                let arrLocale1topImportiUscite=[];
                let arrLocale5TopImportiUscite=[];
                let arrLocale1topIvaUscite=[];
                let arrLocale5TopIvaUscite=[];
                let arrLocale1topNettoUscite=[];
                let arrLocale5TopNettoUscite=[];
                let arrLocale1topImportiLiquiditaFineAnno=[];
                let arrLocale5TopImportiLiquiditaFineAnno=[];
                let arrLocale1topIvaLiquiditaFineAnno=[];
                let arrLocale5TopIvaLiquiditaFineAnno=[];
                let arrLocale1topNettoLiquiditaFineAnno=[];
                let arrLocale5TopNettoLiquiditaFineAnno=[];
                let arrLocale1topImportiCreditiFineAnno=[];
                let arrLocale5TopImportiCreditiFineAnno=[];
                let arrLocale1topIvaCreditiFineAnno=[];
                let arrLocale5TopIvaCreditiFineAnno=[];
                let arrLocale1topNettoCreditiFineAnno=[];
                let arrLocale5TopNettoCreditiFineAnno=[];
                let arrLocale1topImportiDebitiFineAnno=[];
                let arrLocale5TopImportiDebitiFineAnno=[];
                let arrLocale1topIvaDebitiFineAnno=[];
                let arrLocale5TopIvaDebitiFineAnno=[];
                let arrLocale1topNettoDebitiFineAnno=[];
                let arrLocale5TopNettoDebitiFineAnno=[];

                let totaleLiquiditaInizioAnno = 0;
                let totaleLiquiditaInizioAnnoIva = 0;
                let totaleLiquiditaInizioAnnoImportiNetti = 0;

                let totaleCreditiInizioAnno = 0;
                let totaleCreditiInizioAnnoIva = 0;
                let totaleCreditiInizioAnnoImportiNetti = 0;

                let totaleDebitiInizioAnno = 0;
                let totaleDebitiInizioAnnoIva = 0;
                let totaleDebitiInizioAnnoImportiNetti = 0;

                let totaleCreditiDebitiInizioAnno = 0;
                let totaleCreditiDebitiInizioAnnoIva = 0;
                let totaleCreditiDebitiInizioAnnoImportiNetti = 0;

                let totaleEntrate = 0;
                let totaleEntrateIva = 0;
                let totaleEntrateImportiNetti = 0;

                let totaleUscite = 0;
                let totaleUsciteIva = 0;
                let totaleUsciteImportiNetti = 0;

                let totaleEntrateUscite = 0;
                let totaleEntrateUsciteIva = 0;
                let totaleEntrateUsciteImportiNetti = 0;

                let totaleLiquiditaFineAnno = 0;
                let totaleLiquiditaFineAnnoIva = 0;
                let totaleLiquiditaFineAnnoImportiNetti = 0;

                let totaleCreditiFineAnno = 0;
                let totaleCreditiFineAnnoIva = 0;
                let totaleCreditiFineAnnoImportiNetti = 0;

                let totaleDebitiFineAnno = 0;
                let totaleDebitiFineAnnoIva = 0;
                let totaleDebitiFineAnnoImportiNetti = 0;

                let totaleCreditiDebitiFineAnno = 0;
                let totaleCreditiDebitiFineAnnoIva = 0;
                let totaleCreditiDebitiFineAnnoImportiNetti = 0;

                for (let i=0; i<valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno.length ; i++) {
                  arrLocale1[i]=valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].RISORSA_INIZIO_ANNO;
                  //console.log("LEGGO"+valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].RISORSA_INIZIO_ANNO);
                  setArrayLiquiditaInizioAnnoRisorse(arrLocale1);
                  //datiLiquiditaInizioAnno.labels=[...arrLocale1];
                  arrLocale2[i]=valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].SALDO_INIZIO_ANNO;
                  setArrayLiquiditaInizioAnnoImporti(arrLocale2);
                  arrLocale2a[i]=valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].IVA_INIZIO_ANNO;
                  setArrayLiquiditaInizioAnnoIva(arrLocale2a);
                  arrLocale2b[i]=valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].IMPORTO_NETTO_INIZIO_ANNO;
                  setArrayLiquiditaInizioAnnoImportiNetti(arrLocale2b);

                  //datiLiquiditaInizioAnno.datasets.data=[...arrLocale2]; 
                  totaleLiquiditaInizioAnno = totaleLiquiditaInizioAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].SALDO_INIZIO_ANNO);
                  totaleLiquiditaInizioAnnoIva = totaleLiquiditaInizioAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].IVA_INIZIO_ANNO);
                  totaleLiquiditaInizioAnnoImportiNetti = totaleLiquiditaInizioAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[0].liquiditaInizioAnno[i].IMPORTO_NETTO_INIZIO_ANNO);
                }

                for (let i1=0; i1<valoreDiRitorno.risultatoRitorno[1].topImportiLiquiditaInizioAnno.length ; i1++) {
                  arrLocale1topImportiLiquiditaInizioAnno[i1]=valoreDiRitorno.risultatoRitorno[1].topImportiLiquiditaInizioAnno[i1].RISORSA;
                  setArrayRisorseTopImportiLiquiditaInizioAnno(arrLocale1topImportiLiquiditaInizioAnno);
                  arrLocale5TopImportiLiquiditaInizioAnno[i1]=parseFloat(valoreDiRitorno.risultatoRitorno[1].topImportiLiquiditaInizioAnno[i1].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[1].topImportiLiquiditaInizioAnno[i1].IMPORTI) !== 0) setGraficoLiquiditaInizioAnno(1);
                  setArrayTopImportiLiquiditaInizioAnno(arrLocale5TopImportiLiquiditaInizioAnno);
                }
                for (let i2=0; i2<valoreDiRitorno.risultatoRitorno[2].topIvaLiquiditaInizioAnno.length ; i2++) {
                  arrLocale1topIvaLiquiditaInizioAnno[i2]=valoreDiRitorno.risultatoRitorno[2].topIvaLiquiditaInizioAnno[i2].RISORSA;
                  setArrayRisorseTopIvaLiquiditaInizioAnno(arrLocale1topIvaLiquiditaInizioAnno);
                  arrLocale5TopIvaLiquiditaInizioAnno[i2]=parseFloat(valoreDiRitorno.risultatoRitorno[2].topIvaLiquiditaInizioAnno[i2].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[2].topIvaLiquiditaInizioAnno[i2].IMPORTI) !== 0) setGraficoLiquiditaInizioAnnoIva(1);
                  setArrayTopIvaLiquiditaInizioAnno(arrLocale5TopIvaLiquiditaInizioAnno);
                }
                for (let i3=0; i3<valoreDiRitorno.risultatoRitorno[3].topNettoLiquiditaInizioAnno.length ; i3++) {
                  arrLocale1topNettoLiquiditaInizioAnno[i3]=valoreDiRitorno.risultatoRitorno[3].topNettoLiquiditaInizioAnno[i3].RISORSA;
                  setArrayRisorseTopNettoLiquiditaInizioAnno(arrLocale1topNettoLiquiditaInizioAnno);
                  arrLocale5TopNettoLiquiditaInizioAnno[i3]=parseFloat(valoreDiRitorno.risultatoRitorno[3].topNettoLiquiditaInizioAnno[i3].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[3].topNettoLiquiditaInizioAnno[i3].IMPORTI) !== 0) setGraficoLiquiditaInizioAnnoNetti(1);
                  setArrayTopNettoLiquiditaInizioAnno(arrLocale5TopNettoLiquiditaInizioAnno);
                }
                
                for (let i4=0; i4<valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno.length ; i4++) {
  
                  /*if(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA === "debito_quote_abbonamento") arrLocale3[i4]="Quote abbonamento";
                  if(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA === "debito_quote_associative") arrLocale3[i4]="Quote associative";
                  if(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA === "debito_spese") arrLocale3[i4]="Spese";
                  if(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA === "debito_rimborsi") arrLocale3[i4]="Rimborsi";
                  if(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA === "debito_sponsor") arrLocale3[i4]="Campagne Sponsor";
                  if(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA === "debito_incassi_esterni") arrLocale3[i4]="Incassi Diversi da Esterni";
                  if(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA === "debito_incassi_iscritti") arrLocale3[i4]="Incassi Diversi da Iscritti";*/
                  arrLocale3[i4] = valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].TIPOLOGIA;
                  setArrayDebitiInizioAnnoTipiFatture(arrLocale3);

                  arrLocale4[i4]=valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IMPORTO;
                  setArrayDebitiInizioAnnoImporti(arrLocale4);
                  arrLocale4a[i4]=valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IVA;
                  setArrayDebitiInizioAnnoIva(arrLocale4a);
                  arrLocale4b[i4]=valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IMPONIBILE;
                  setArrayDebitiInizioAnnoImportiNetti(arrLocale4b);

                  totaleDebitiInizioAnno = totaleDebitiInizioAnno + parseFloat(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IMPORTO);
                  totaleDebitiInizioAnnoIva = totaleDebitiInizioAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IVA);
                  totaleDebitiInizioAnnoImportiNetti = totaleDebitiInizioAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IMPONIBILE);

                  totaleCreditiDebitiInizioAnno = totaleCreditiDebitiInizioAnno - parseFloat(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IMPORTO);
                  totaleCreditiDebitiInizioAnnoIva = totaleCreditiDebitiInizioAnnoIva - parseFloat(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IVA);
                  totaleCreditiDebitiInizioAnnoImportiNetti = totaleCreditiDebitiInizioAnnoImportiNetti - parseFloat(valoreDiRitorno.risultatoRitorno[4].debitiInizioAnno[i4].IMPONIBILE);
                }

                for (let i5=0; i5<valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno.length ; i5++) {
                  /*if(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA === "credito_quote_abbonamento") arrLocale5[i5]="Quote abbonamento";
                  if(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA === "credito_quote_associative") arrLocale5[i5]="Quote associative";
                  if(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA === "credito_spese") arrLocale5[i5]="Spese";
                  if(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA === "credito_rimborsi") arrLocale5[i5]="Rimborsi";
                  if(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA === "credito_sponsor") arrLocale5[i5]="Campagne Sponsor";
                  if(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA === "credito_incassi_esterni") arrLocale5[i5]="Incassi Diversi da Esterni";
                  if(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA === "credito_incassi_iscritti") arrLocale5[i5]="Incassi Diversi da Iscritti";*/
               
                  arrLocale5[i5] = valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].TIPOLOGIA;
                  setArrayCreditiInizioAnnoTipiFatture(arrLocale5);
                  arrLocale6[i5]=valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IMPORTO
                  setArrayCreditiInizioAnnoImporti(arrLocale6);
                  arrLocale6a[i5]=valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IVA;
                  setArrayCreditiInizioAnnoIva(arrLocale6a);
                  arrLocale6b[i5]=valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IMPONIBILE;
                  setArrayCreditiInizioAnnoImportiNetti(arrLocale6b);

                  totaleCreditiInizioAnno = totaleCreditiInizioAnno + parseFloat(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IMPORTO);
                  totaleCreditiInizioAnnoIva = totaleCreditiInizioAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IVA);
                  totaleCreditiInizioAnnoImportiNetti = totaleCreditiInizioAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IMPONIBILE);
                  totaleCreditiDebitiInizioAnno = totaleCreditiDebitiInizioAnno + parseFloat(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IMPORTO);
                  totaleCreditiDebitiInizioAnnoIva = totaleCreditiDebitiInizioAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IVA);
                  totaleCreditiDebitiInizioAnnoImportiNetti = totaleCreditiDebitiInizioAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[5].creditiInizioAnno[i5].IMPONIBILE);
                }

                for (let i6=0; i6<valoreDiRitorno.risultatoRitorno[6].topImportiCreditiInizioAnno.length ; i6++) {
                  arrLocale1topImportiCreditiInizioAnno[i6]=valoreDiRitorno.risultatoRitorno[6].topImportiCreditiInizioAnno[i6].TIPOLOGIA;
                  setArrayTipologieTopImportiCreditiInizioAnno(arrLocale1topImportiCreditiInizioAnno);
                  arrLocale5TopImportiCreditiInizioAnno[i6]=parseFloat(valoreDiRitorno.risultatoRitorno[6].topImportiCreditiInizioAnno[i6].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[6].topImportiCreditiInizioAnno[i6].IMPORTI) !== 0) setGraficoCreditiInizioAnno(1);
                  setArrayTopImportiCreditiInizioAnno(arrLocale5TopImportiCreditiInizioAnno);
                }
                for (let i7=0; i7<valoreDiRitorno.risultatoRitorno[7].topIvaCreditiInizioAnno.length ; i7++) {
                  arrLocale1topIvaCreditiInizioAnno[i7]=valoreDiRitorno.risultatoRitorno[7].topIvaCreditiInizioAnno[i7].TIPOLOGIA;
                  setArrayTipologieTopIvaCreditiInizioAnno(arrLocale1topIvaCreditiInizioAnno);
                  arrLocale5TopIvaCreditiInizioAnno[i7]=parseFloat(valoreDiRitorno.risultatoRitorno[7].topIvaCreditiInizioAnno[i7].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[7].topIvaCreditiInizioAnno[i7].IMPORTI) !== 0) setGraficoCreditiInizioAnnoIva(1);
                  setArrayTopIvaCreditiInizioAnno(arrLocale5TopIvaCreditiInizioAnno);
                }
                for (let i8=0; i8<valoreDiRitorno.risultatoRitorno[8].topNettoCreditiInizioAnno.length ; i8++) {
                  arrLocale1topNettoCreditiInizioAnno[i8]=valoreDiRitorno.risultatoRitorno[8].topNettoCreditiInizioAnno[i8].TIPOLOGIA;
                  setArrayTipologieTopNettoCreditiInizioAnno(arrLocale1topNettoCreditiInizioAnno);
                  arrLocale5TopNettoCreditiInizioAnno[i8]=parseFloat(valoreDiRitorno.risultatoRitorno[8].topNettoCreditiInizioAnno[i8].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[8].topNettoCreditiInizioAnno[i8].IMPORTI) !== 0) setGraficoCreditiInizioAnnoNetti(1);
                  setArrayTopNettoCreditiInizioAnno(arrLocale5TopNettoCreditiInizioAnno);
                }

                for (let i9=0; i9<valoreDiRitorno.risultatoRitorno[9].topImportiDebitiInizioAnno.length ; i9++) {
                  arrLocale1topImportiDebitiInizioAnno[i9]=valoreDiRitorno.risultatoRitorno[9].topImportiDebitiInizioAnno[i9].TIPOLOGIA;
                  setArrayTipologieTopImportiDebitiInizioAnno(arrLocale1topImportiDebitiInizioAnno);
                  arrLocale5TopImportiDebitiInizioAnno[i9]=parseFloat(valoreDiRitorno.risultatoRitorno[9].topImportiDebitiInizioAnno[i9].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[9].topImportiDebitiInizioAnno[i9].IMPORTI) !== 0) setGraficoDebitiInizioAnno(1);
                  setArrayTopImportiDebitiInizioAnno(arrLocale5TopImportiDebitiInizioAnno);
                }
                for (let i10=0; i10<valoreDiRitorno.risultatoRitorno[10].topIvaDebitiInizioAnno.length ; i10++) {
                  arrLocale1topIvaDebitiInizioAnno[i10]=valoreDiRitorno.risultatoRitorno[10].topIvaDebitiInizioAnno[i10].TIPOLOGIA;
                  setArrayTipologieTopIvaDebitiInizioAnno(arrLocale1topIvaDebitiInizioAnno);
                  arrLocale5TopIvaDebitiInizioAnno[i10]=parseFloat(valoreDiRitorno.risultatoRitorno[10].topIvaDebitiInizioAnno[i10].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[10].topIvaDebitiInizioAnno[i10].IMPORTI) !== 0) setGraficoDebitiInizioAnnoIva(1);
                  setArrayTopIvaDebitiInizioAnno(arrLocale5TopIvaDebitiInizioAnno);
                }
                for (let i11=0; i11<valoreDiRitorno.risultatoRitorno[11].topNettoDebitiInizioAnno.length ; i11++) {
                  arrLocale1topNettoDebitiInizioAnno[i11]=valoreDiRitorno.risultatoRitorno[11].topNettoDebitiInizioAnno[i11].TIPOLOGIA;
                  setArrayTipologieTopNettoDebitiInizioAnno(arrLocale1topNettoDebitiInizioAnno);
                  arrLocale5TopNettoDebitiInizioAnno[i11]=parseFloat(valoreDiRitorno.risultatoRitorno[11].topNettoDebitiInizioAnno[i11].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[11].topNettoDebitiInizioAnno[i11].IMPORTI) !== 0) setGraficoDebitiInizioAnnoNetti(1);
                  setArrayTopNettoDebitiInizioAnno(arrLocale5TopNettoDebitiInizioAnno);
                }
              
                let indiceEntrate = 0;
                let indiceUscite = 0;
                for (let i12=0; i12<valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso.length ; i12++) {                 
                  if(valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].VOCE_ENTRATA_ANNO_CORSO==="") arrLocale7[indiceEntrate]="Voce di Bilancio non specificata";
                  else arrLocale7[indiceEntrate]=valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].VOCE_ENTRATA_ANNO_CORSO;                 
                  setArrayEntrateAnnoCorsoVoci(arrLocale7);
                  arrLocale8[indiceEntrate]=valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].SALDO_E;
                  setArrayEntrateAnnoCorsoImporti(arrLocale8);
                  arrLocale8a[indiceEntrate]=valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].IVA_E;
                  setArrayEntrateAnnoCorsoIva(arrLocale8a);
                  arrLocale8b[indiceEntrate]=valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].IMPORTO_NETTO_E;
                  setArrayEntrateAnnoCorsoImportiNetti(arrLocale8b);
                  totaleEntrate = totaleEntrate + parseFloat(valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].SALDO_E);
                  totaleEntrateIva = totaleEntrateIva + parseFloat(valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].IVA_E);
                  totaleEntrateImportiNetti = totaleEntrateImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].IMPORTO_NETTO_E);                
                  totaleEntrateUscite = totaleEntrateUscite + parseFloat(valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].SALDO_E);
                  totaleEntrateUsciteIva = totaleEntrateUsciteIva + parseFloat(valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].IVA_E);
                  totaleEntrateUsciteImportiNetti = totaleEntrateUsciteImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[12].entrateAnnoInCorso[i12].IMPORTO_NETTO_E);
                  indiceEntrate = indiceEntrate + 1;                 
                }

                for (let i13=0; i13<valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso.length ; i13++) {                 
                  if(valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].VOCE_USCITA_ANNO_CORSO==="") arrLocale9[indiceUscite]="Voce di Bilancio non specificata";
                  else arrLocale9[indiceUscite]=valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].VOCE_USCITA_ANNO_CORSO;
                  setArrayUsciteAnnoCorsoVoci(arrLocale9);
                  arrLocale10[indiceUscite]=valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].SALDO_U;
                  setArrayUsciteAnnoCorsoImporti(arrLocale10);
                  arrLocale10a[indiceUscite]=valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].IVA_U;
                  setArrayUsciteAnnoCorsoIva(arrLocale10a);
                  arrLocale10b[indiceUscite]=valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].IMPORTO_NETTO_U;
                  setArrayUsciteAnnoCorsoImportiNetti(arrLocale10b);
                  totaleUscite = totaleUscite + parseFloat(valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].SALDO_U);
                  totaleUsciteIva = totaleUsciteIva + parseFloat(valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].IVA_U);
                  totaleUsciteImportiNetti = totaleUsciteImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].IMPORTO_NETTO_U);
                  totaleEntrateUscite = totaleEntrateUscite - parseFloat(valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].SALDO_U);
                  totaleEntrateUsciteIva = totaleEntrateUsciteIva - parseFloat(valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].IVA_U);
                  totaleEntrateUsciteImportiNetti = totaleEntrateUsciteImportiNetti - parseFloat(valoreDiRitorno.risultatoRitorno[13].usciteAnnoInCorso[i13].IMPORTO_NETTO_U);
                  indiceUscite = indiceUscite + 1;                 
                }

                for (let i14=0; i14<valoreDiRitorno.risultatoRitorno[14].topImportiEntrate.length ; i14++) {
                  arrLocale1topImportiEntrate[i14]=valoreDiRitorno.risultatoRitorno[14].topImportiEntrate[i14].VOCE;
                  setArrayVociTopImportiEntrate(arrLocale1topImportiEntrate);
                  arrLocale5TopImportiEntrate[i14]=parseFloat(valoreDiRitorno.risultatoRitorno[14].topImportiEntrate[i14].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[14].topImportiEntrate[i14].IMPORTI) !== 0) setGraficoEntrateAnnoInCorso(1);
                  setArrayTopImportiEntrate(arrLocale5TopImportiEntrate);
                }
                for (let i15=0; i15<valoreDiRitorno.risultatoRitorno[15].topIvaEntrate.length ; i15++) {
                  arrLocale1topIvaEntrate[i15]=valoreDiRitorno.risultatoRitorno[15].topIvaEntrate[i15].VOCE;
                  setArrayVociTopIvaEntrate(arrLocale1topIvaEntrate);
                  arrLocale5TopIvaEntrate[i15]=parseFloat(valoreDiRitorno.risultatoRitorno[15].topIvaEntrate[i15].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[15].topIvaEntrate[i15].IMPORTI) !== 0) setGraficoEntrateAnnoInCorsoIva(1);
                  setArrayTopIvaEntrate(arrLocale5TopIvaEntrate);
                }
                for (let i16=0; i16<valoreDiRitorno.risultatoRitorno[16].topNettoEntrate.length ; i16++) {
                  arrLocale1topNettoEntrate[i16]=valoreDiRitorno.risultatoRitorno[16].topNettoEntrate[i16].VOCE;
                  setArrayVociTopNettoEntrate(arrLocale1topNettoEntrate);
                  arrLocale5TopNettoEntrate[i16]=parseFloat(valoreDiRitorno.risultatoRitorno[16].topNettoEntrate[i16].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[16].topNettoEntrate[i16].IMPORTI) !== 0) setGraficoEntrateAnnoInCorsoNetti(1);
                  setArrayTopNettoEntrate(arrLocale5TopNettoEntrate);
                }

                for (let i17=0; i17<valoreDiRitorno.risultatoRitorno[17].topImportiUscite.length ; i17++) {
                  arrLocale1topImportiUscite[i17]=valoreDiRitorno.risultatoRitorno[17].topImportiUscite[i17].VOCE;
                  setArrayVociTopImportiUscite(arrLocale1topImportiUscite);
                  arrLocale5TopImportiUscite[i17]=parseFloat(valoreDiRitorno.risultatoRitorno[17].topImportiUscite[i17].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[17].topImportiUscite[i17].IMPORTI) !== 0) setGraficoUsciteAnnoInCorso(1);
                  setArrayTopImportiUscite(arrLocale5TopImportiUscite);
                }
                for (let i18=0; i18<valoreDiRitorno.risultatoRitorno[18].topIvaUscite.length ; i18++) {
                  arrLocale1topIvaUscite[i18]=valoreDiRitorno.risultatoRitorno[18].topIvaUscite[i18].VOCE;
                  setArrayVociTopIvaUscite(arrLocale1topIvaUscite);
                  arrLocale5TopIvaUscite[i18]=parseFloat(valoreDiRitorno.risultatoRitorno[18].topIvaUscite[i18].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[18].topIvaUscite[i18].IMPORTI) !== 0) setGraficoUsciteAnnoInCorsoIva(1);
                  setArrayTopIvaUscite(arrLocale5TopIvaUscite);
                }
                for (let i19=0; i19<valoreDiRitorno.risultatoRitorno[19].topNettoUscite.length ; i19++) {
                  arrLocale1topNettoUscite[i19]=valoreDiRitorno.risultatoRitorno[19].topNettoUscite[i19].VOCE;
                  setArrayVociTopNettoUscite(arrLocale1topNettoUscite);
                  arrLocale5TopNettoUscite[i19]=parseFloat(valoreDiRitorno.risultatoRitorno[19].topNettoUscite[i19].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[19].topNettoUscite[i19].IMPORTI) !== 0) setGraficoUsciteAnnoInCorsoNetti(1);
                  setArrayTopNettoUscite(arrLocale5TopNettoUscite);
                }

                for (let i20=0; i20<valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno.length ; i20++) {
                  arrLocale11[i20]=valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno[i20].RISORSA_FINE_ANNO;
                  setArrayLiquiditaFineAnnoRisorse(arrLocale11);
                  arrLocale12[i20]=valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno[i20].SALDO_FINE_ANNO;
                  setArrayLiquiditaFineAnnoImporti(arrLocale12);
                  arrLocale12a[i20]=valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno[i20].IVA_FINE_ANNO;
                  setArrayLiquiditaFineAnnoIva(arrLocale12a);
                  arrLocale12b[i20]=valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno[i20].IMPORTO_NETTO_FINE_ANNO;
                  setArrayLiquiditaFineAnnoImportiNetti(arrLocale12b);
                  totaleLiquiditaFineAnno = totaleLiquiditaFineAnno + parseFloat(valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno[i20].SALDO_FINE_ANNO);
                  totaleLiquiditaFineAnnoIva = totaleLiquiditaFineAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno[i20].IVA_FINE_ANNO);
                  totaleLiquiditaFineAnnoImportiNetti = totaleLiquiditaFineAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[20].liquiditaFineAnno[i20].IMPORTO_NETTO_FINE_ANNO);
                }

                for (let i21=0; i21<valoreDiRitorno.risultatoRitorno[21].topImportiLiquiditaFineAnno.length ; i21++) {
                  arrLocale1topImportiLiquiditaFineAnno[i21]=valoreDiRitorno.risultatoRitorno[21].topImportiLiquiditaFineAnno[i21].RISORSA;
                  setArrayRisorseTopImportiLiquiditaFineAnno(arrLocale1topImportiLiquiditaFineAnno);
                  arrLocale5TopImportiLiquiditaFineAnno[i21]=parseFloat(valoreDiRitorno.risultatoRitorno[21].topImportiLiquiditaFineAnno[i21].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[21].topImportiLiquiditaFineAnno[i21].IMPORTI) !== 0) setGraficoLiquiditaFineAnno(1);
                  setArrayTopImportiLiquiditaFineAnno(arrLocale5TopImportiLiquiditaFineAnno);
                }
                for (let i22=0; i22<valoreDiRitorno.risultatoRitorno[22].topIvaLiquiditaFineAnno.length ; i22++) {
                  arrLocale1topIvaLiquiditaFineAnno[i22]=valoreDiRitorno.risultatoRitorno[22].topIvaLiquiditaFineAnno[i22].RISORSA;
                  setArrayRisorseTopIvaLiquiditaFineAnno(arrLocale1topIvaLiquiditaFineAnno);
                  arrLocale5TopIvaLiquiditaFineAnno[i22]=parseFloat(valoreDiRitorno.risultatoRitorno[22].topIvaLiquiditaFineAnno[i22].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[22].topIvaLiquiditaFineAnno[i22].IMPORTI) !== 0) setGraficoLiquiditaFineAnnoIva(1);
                  setArrayTopIvaLiquiditaFineAnno(arrLocale5TopIvaLiquiditaFineAnno);
                }
                for (let i23=0; i23<valoreDiRitorno.risultatoRitorno[23].topNettoLiquiditaFineAnno.length ; i23++) {
                  arrLocale1topNettoLiquiditaFineAnno[i23]=valoreDiRitorno.risultatoRitorno[23].topNettoLiquiditaFineAnno[i23].RISORSA;
                  setArrayRisorseTopNettoLiquiditaFineAnno(arrLocale1topNettoLiquiditaFineAnno);
                  arrLocale5TopNettoLiquiditaFineAnno[i23]=parseFloat(valoreDiRitorno.risultatoRitorno[23].topNettoLiquiditaFineAnno[i23].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[23].topNettoLiquiditaFineAnno[i23].IMPORTI) !== 0) setGraficoLiquiditaFineAnnoNetti(1);
                  setArrayTopNettoLiquiditaFineAnno(arrLocale5TopNettoLiquiditaFineAnno);
                }
                
                for (let i24=0; i24<valoreDiRitorno.risultatoRitorno[24].debitiFineAnno.length ; i24++) {
                  /*if(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA === "debito_quote_abbonamento") arrLocale13[i24]="Quote abbonamento";
                  if(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA === "debito_quote_associative") arrLocale13[i24]="Quote associative";
                  if(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA === "debito_spese") arrLocale13[i24]="Spese";
                  if(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA === "debito_rimborsi") arrLocale13[i24]="Rimborsi";
                  if(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA === "debito_sponsor") arrLocale13[i24]="Campagne Sponsor";
                  if(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA === "debito_incassi_esterni") arrLocale13[i24]="Incassi Diversi da Esterni";
                  if(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA === "debito_incassi_iscritti") arrLocale13[i24]="Incassi Diversi da Iscritti";
                  */
                
                  arrLocale13[i24] = valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].TIPOLOGIA;
                  setArrayDebitiFineAnnoTipiFatture(arrLocale13);
                  arrLocale14[i24]=valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IMPORTO;
                  setArrayDebitiFineAnnoImporti(arrLocale14);
                  arrLocale14a[i24]=valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IVA;
                  setArrayDebitiFineAnnoIva(arrLocale14a);
                  arrLocale14b[i24]=valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IMPONIBILE;
                  setArrayDebitiFineAnnoImportiNetti(arrLocale14b);
                  totaleDebitiFineAnno = totaleDebitiFineAnno + parseFloat(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IMPORTO);
                  totaleDebitiFineAnnoIva = totaleDebitiFineAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IVA);
                  totaleDebitiFineAnnoImportiNetti = totaleDebitiFineAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IMPONIBILE);
                  totaleCreditiDebitiFineAnno = totaleCreditiDebitiFineAnno - parseFloat(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IMPORTO);
                  totaleCreditiDebitiFineAnnoIva = totaleCreditiDebitiFineAnnoIva - parseFloat(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IVA);
                  totaleCreditiDebitiFineAnnoImportiNetti = totaleCreditiDebitiFineAnnoImportiNetti - parseFloat(valoreDiRitorno.risultatoRitorno[24].debitiFineAnno[i24].IMPONIBILE);
                
                }
                for (let i25=0; i25<valoreDiRitorno.risultatoRitorno[25].creditiFineAnno.length ; i25++) {
    
                  /*if(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA === "credito_quote_abbonamento") arrLocale15[i25]="Quote abbonamento";
                  if(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA === "credito_quote_associative") arrLocale15[i25]="Quote associative";
                  if(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA === "credito_spese") arrLocale15[i25]="Spese";
                  if(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA === "credito_rimborsi") arrLocale15[i25]="Rimborsi";
                  if(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA === "credito_sponsor") arrLocale15[i25]="Campagne Sponsor";
                  if(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA === "credito_incassi_esterni") arrLocale15[i25]="Incassi Diversi da Esterni";
                  if(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA === "credito_incassi_iscritti") arrLocale15[i25]="Incassi Diversi da Iscritti";*/
                  
                  arrLocale15[i25] = valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].TIPOLOGIA;
                  setArrayCreditiFineAnnoTipiFatture(arrLocale15);

                  arrLocale16[i25]=valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IMPORTO;
                  setArrayCreditiFineAnnoImporti(arrLocale16); 
                  arrLocale16a[i25]=valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IVA;
                  setArrayCreditiFineAnnoIva(arrLocale16a); 
                  arrLocale16b[i25]=valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IMPONIBILE;
                  setArrayCreditiFineAnnoImportiNetti(arrLocale16b); 
                  totaleCreditiFineAnno = totaleCreditiFineAnno + parseFloat(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IMPORTO);
                  totaleCreditiFineAnnoIva = totaleCreditiFineAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IVA);
                  totaleCreditiFineAnnoImportiNetti = totaleCreditiFineAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IMPONIBILE);
                  totaleCreditiDebitiFineAnno = totaleCreditiDebitiFineAnno + parseFloat(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IMPORTO);
                  totaleCreditiDebitiFineAnnoIva = totaleCreditiDebitiFineAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IVA);
                  totaleCreditiDebitiFineAnnoImportiNetti = totaleCreditiDebitiFineAnnoImportiNetti + parseFloat(valoreDiRitorno.risultatoRitorno[25].creditiFineAnno[i25].IMPONIBILE);
                }
                for (let i26=0; i26<valoreDiRitorno.risultatoRitorno[26].topImportiCreditiFineAnno.length ; i26++) {
                  arrLocale1topImportiCreditiFineAnno[i26]=valoreDiRitorno.risultatoRitorno[26].topImportiCreditiFineAnno[i26].TIPOLOGIA;
                  setArrayTipologieTopImportiCreditiFineAnno(arrLocale1topImportiCreditiFineAnno);
                  arrLocale5TopImportiCreditiFineAnno[i26]=parseFloat(valoreDiRitorno.risultatoRitorno[26].topImportiCreditiFineAnno[i26].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[26].topImportiCreditiFineAnno[i26].IMPORTI) !== 0) setGraficoCreditiFineAnno(1);
                  setArrayTopImportiCreditiFineAnno(arrLocale5TopImportiCreditiFineAnno);
                }
                for (let i27=0; i27<valoreDiRitorno.risultatoRitorno[27].topIvaCreditiFineAnno.length ; i27++) {
                  arrLocale1topIvaCreditiFineAnno[i27]=valoreDiRitorno.risultatoRitorno[27].topIvaCreditiFineAnno[i27].TIPOLOGIA;
                  setArrayTipologieTopIvaCreditiFineAnno(arrLocale1topIvaCreditiFineAnno);
                  arrLocale5TopIvaCreditiFineAnno[i27]=parseFloat(valoreDiRitorno.risultatoRitorno[27].topIvaCreditiFineAnno[i27].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[27].topIvaCreditiFineAnno[i27].IMPORTI) !== 0) setGraficoCreditiFineAnnoIva(1);
                  setArrayTopIvaCreditiFineAnno(arrLocale5TopIvaCreditiFineAnno);
                }
                for (let i28=0; i28<valoreDiRitorno.risultatoRitorno[28].topNettoCreditiFineAnno.length ; i28++) {
                  arrLocale1topNettoCreditiFineAnno[i28]=valoreDiRitorno.risultatoRitorno[28].topNettoCreditiFineAnno[i28].TIPOLOGIA;
                  setArrayTipologieTopNettoCreditiFineAnno(arrLocale1topNettoCreditiFineAnno);
                  arrLocale5TopNettoCreditiFineAnno[i28]=parseFloat(valoreDiRitorno.risultatoRitorno[28].topNettoCreditiFineAnno[i28].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[28].topNettoCreditiFineAnno[i28].IMPORTI) !== 0) setGraficoCreditiFineAnnoNetti(1);
                  setArrayTopNettoCreditiFineAnno(arrLocale5TopNettoCreditiFineAnno);
                }
              
                for (let i29=0; i29<valoreDiRitorno.risultatoRitorno[29].topImportiDebitiFineAnno.length ; i29++) {
                  arrLocale1topImportiDebitiFineAnno[i29]=valoreDiRitorno.risultatoRitorno[29].topImportiDebitiFineAnno[i29].TIPOLOGIA;
                  setArrayTipologieTopImportiDebitiFineAnno(arrLocale1topImportiDebitiFineAnno);
                  arrLocale5TopImportiDebitiFineAnno[i29]=parseFloat(valoreDiRitorno.risultatoRitorno[29].topImportiDebitiFineAnno[i29].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[29].topImportiDebitiFineAnno[i29].IMPORTI) !== 0) setGraficoDebitiFineAnno(1);
                  setArrayTopImportiDebitiFineAnno(arrLocale5TopImportiDebitiFineAnno);
                }
                for (let i30=0; i30<valoreDiRitorno.risultatoRitorno[30].topIvaDebitiFineAnno.length ; i30++) {
                  arrLocale1topIvaDebitiFineAnno[i30]=valoreDiRitorno.risultatoRitorno[30].topIvaDebitiFineAnno[i30].TIPOLOGIA;
                  setArrayTipologieTopIvaDebitiFineAnno(arrLocale1topIvaDebitiFineAnno);
                  arrLocale5TopIvaDebitiFineAnno[i30]=parseFloat(valoreDiRitorno.risultatoRitorno[30].topIvaDebitiFineAnno[i30].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[30].topIvaDebitiFineAnno[i30].IMPORTI) !== 0) setGraficoDebitiFineAnnoIva(1);
                  setArrayTopIvaDebitiFineAnno(arrLocale5TopIvaDebitiFineAnno);
                }
                for (let i31=0; i31<valoreDiRitorno.risultatoRitorno[31].topNettoDebitiFineAnno.length ; i31++) {
                  arrLocale1topNettoDebitiFineAnno[i31]=valoreDiRitorno.risultatoRitorno[31].topNettoDebitiFineAnno[i31].TIPOLOGIA;
                  setArrayTipologieTopNettoDebitiFineAnno(arrLocale1topNettoDebitiFineAnno);
                  arrLocale5TopNettoDebitiFineAnno[i31]=parseFloat(valoreDiRitorno.risultatoRitorno[31].topNettoDebitiFineAnno[i31].IMPORTI);
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[31].topNettoDebitiFineAnno[i31].IMPORTI) !== 0) setGraficoDebitiFineAnnoNetti(1);
                  setArrayTopNettoDebitiFineAnno(arrLocale5TopNettoDebitiFineAnno);
                }

                for (let i32=0; i32<valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso.length ; i32++) {
                  arrLocale17[i32]=valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso[i32].DATA_PAGAMENTO;
                  setArrayMovimentiData(arrLocale17);
                  arrLocale18[i32]=valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso[i32].DESCRIZIONE_MOVIMENTO;
                  setArrayMovimentiDescrizione(arrLocale18); 
                  arrLocale19[i32]=valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso[i32].DESCRIZIONE_RISORSA;
                  setArrayMovimentiRisorsa(arrLocale19); 
                  arrLocale20[i32]=valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso[i32].DESCRIZIONE_VOCE;
                  setArrayMovimentiVoce(arrLocale20); 
                  arrLocale21[i32]=valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso[i32].SALDO_MOVIMENTO;
                  setArrayMovimentiImporto(arrLocale21);
                  arrLocale21a[i32]=valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso[i32].IVA_MOVIMENTO;
                  setArrayMovimentiIva(arrLocale21a);
                  arrLocale21b[i32]=valoreDiRitorno.risultatoRitorno[32].movimentiAnnoInCorso[i32].IMPORTO_NETTO_MOVIMENTO;
                  setArrayMovimentiImportoNetto(arrLocale21b);
                }

                for (let i33=0; i33<valoreDiRitorno.risultatoRitorno[33].sociAnnoInCorso.length ; i33++) {
                  arrLocale22[i33]=valoreDiRitorno.risultatoRitorno[33].sociAnnoInCorso[i33].COGNOME;
                  setArraySociCognome(arrLocale22);
                  arrLocale23[i33]=valoreDiRitorno.risultatoRitorno[33].sociAnnoInCorso[i33].NOME;
                  setArraySociNome(arrLocale23); 
                  arrLocale24[i33]=valoreDiRitorno.risultatoRitorno[33].sociAnnoInCorso[i33].NUMERO_TESSERA;
                  setArraySociTessera(arrLocale24); 
                  arrLocale25[i33]=valoreDiRitorno.risultatoRitorno[33].sociAnnoInCorso[i33].DATA_INGRESSO_SOC;
                  setArraySociDataIn(arrLocale25); 
                  arrLocale26[i33]=valoreDiRitorno.risultatoRitorno[33].sociAnnoInCorso[i33].DATA_USCITA_SOC;
                  setArraySociDataOut(arrLocale26);
                  arrLocale27[i33]=valoreDiRitorno.risultatoRitorno[33].sociAnnoInCorso[i33].POSIZIONI;
                  setArraySociPosizioni(arrLocale27);
                }
                setInizioPeriodo(valoreDiRitorno.risultatoRitorno[34].periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.risultatoRitorno[34].periodo[0].DATA_FINE_PERIODO);
                
                //ora setto le var di stato dei totali:
                setStatoTotaleLiquiditaInizioAnno(totaleLiquiditaInizioAnno);
                setStatoTotaleLiquiditaInizioAnnoIva(totaleLiquiditaInizioAnnoIva);
                setStatoTotaleLiquiditaInizioAnnoImportiNetti(totaleLiquiditaInizioAnnoImportiNetti);

                setStatoTotaleCreditiInizioAnno(totaleCreditiInizioAnno);
                setStatoTotaleCreditiInizioAnnoIva(totaleCreditiInizioAnnoIva);
                setStatoTotaleCreditiInizioAnnoImportiNetti(totaleCreditiInizioAnnoImportiNetti);

                setStatoTotaleDebitiInizioAnno(totaleDebitiInizioAnno);
                setStatoTotaleDebitiInizioAnnoIva(totaleDebitiInizioAnnoIva);
                setStatoTotaleDebitiInizioAnnoImportiNetti(totaleDebitiInizioAnnoImportiNetti);

                setStatoTotaleCreditiDebitiInizioAnno(totaleCreditiDebitiInizioAnno);
                setStatoTotaleCreditiDebitiInizioAnnoIva(totaleCreditiDebitiInizioAnnoIva);
                setStatoTotaleCreditiDebitiInizioAnnoImportiNetti(totaleCreditiDebitiInizioAnnoImportiNetti);

                setStatoTotaleEntrate(totaleEntrate);
                setStatoTotaleEntrateIva(totaleEntrateIva);
                setStatoTotaleEntrateImportiNetti(totaleEntrateImportiNetti);

                setStatoTotaleUscite(totaleUscite);
                setStatoTotaleUsciteIva(totaleUsciteIva);
                setStatoTotaleUsciteImportiNetti(totaleUsciteImportiNetti);

                setStatoTotaleEntrateUscite(totaleEntrateUscite);
                setStatoTotaleEntrateUsciteIva(totaleEntrateUsciteIva);
                setStatoTotaleEntrateUsciteImportiNetti(totaleEntrateUsciteImportiNetti);

                setStatoTotaleLiquiditaFineAnno(totaleLiquiditaFineAnno);
                setStatoTotaleLiquiditaFineAnnoIva(totaleLiquiditaFineAnnoIva);
                setStatoTotaleLiquiditaFineAnnoImportiNetti(totaleLiquiditaFineAnnoImportiNetti);

                setStatoTotaleCreditiFineAnno(totaleCreditiFineAnno);
                setStatoTotaleCreditiFineAnnoIva(totaleCreditiFineAnnoIva);
                setStatoTotaleCreditiFineAnnoImportiNetti(totaleCreditiFineAnnoImportiNetti);

                setStatoTotaleDebitiFineAnno(totaleDebitiFineAnno);
                setStatoTotaleDebitiFineAnnoIva(totaleDebitiFineAnnoIva)
                setStatoTotaleDebitiFineAnnoImportiNetti(totaleDebitiFineAnnoImportiNetti)

                setStatoTotaleCreditiDebitiFineAnno(totaleCreditiDebitiFineAnno);
                setStatoTotaleCreditiDebitiFineAnnoIva(totaleCreditiDebitiFineAnnoIva);
                setStatoTotaleCreditiDebitiFineAnnoImportiNetti(totaleCreditiDebitiFineAnnoImportiNetti);

                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
                setRicercaCompletata(1);
              }
            },
          (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina,ricercaImpostazioniReport])
    
    useEffect(() => {
      //resetto tutte le var di stato:
      if(necessitaRicalcolo===1){
        setArrayLiquiditaInizioAnnoRisorse([]);
        setArrayLiquiditaInizioAnnoImporti([]);
        setArrayLiquiditaInizioAnnoIva([]);
        setArrayLiquiditaInizioAnnoImportiNetti([]);

        setArrayDebitiInizioAnnoTipiFatture([]);
        setArrayDebitiInizioAnnoImporti([]);
        setArrayDebitiInizioAnnoIva([]);
        setArrayDebitiInizioAnnoImportiNetti([]);

        setArrayCreditiInizioAnnoTipiFatture([]);
        setArrayCreditiInizioAnnoImporti([]);
        setArrayCreditiInizioAnnoIva([]);
        setArrayCreditiInizioAnnoImportiNetti([]);

        setArrayEntrateAnnoCorsoVoci([]);
        setArrayEntrateAnnoCorsoImporti([]);
        setArrayEntrateAnnoCorsoIva([]);
        setArrayEntrateAnnoCorsoImportiNetti([]);

        setArrayUsciteAnnoCorsoVoci([]);
        setArrayUsciteAnnoCorsoImporti([]);
        setArrayUsciteAnnoCorsoIva([]);
        setArrayUsciteAnnoCorsoImportiNetti([]);

        setArrayLiquiditaFineAnnoRisorse([]);
        setArrayLiquiditaFineAnnoImporti([]);
        setArrayLiquiditaFineAnnoIva([]);
        setArrayLiquiditaFineAnnoImportiNetti([]);

        setArrayDebitiFineAnnoTipiFatture([]);
        setArrayDebitiFineAnnoImporti([]);
        setArrayDebitiFineAnnoIva([]);
        setArrayDebitiFineAnnoImportiNetti([]);

        setArrayCreditiFineAnnoTipiFatture([]);
        setArrayCreditiFineAnnoImporti([]);
        setArrayCreditiFineAnnoIva([]);
        setArrayCreditiFineAnnoImportiNetti([]);
    
        setArrayMovimentiData([]);
        setArrayMovimentiDescrizione([]);
        setArrayMovimentiRisorsa([]);
        setArrayMovimentiVoce([]);
        setArrayMovimentiImporto([]);
        setArrayMovimentiIva([]);
        setArrayMovimentiImportoNetto([]);

        setArraySociCognome([]);          
        setArraySociNome([]);        
        setArraySociTessera([]); 
        setArraySociPosizioni([]);            
        setArraySociDataIn([]);        
        setArraySociDataOut([]);
    
        setStatoTotaleLiquiditaInizioAnno(0);
        setStatoTotaleLiquiditaInizioAnnoIva(0);
        setStatoTotaleLiquiditaInizioAnnoImportiNetti(0);

        setStatoTotaleCreditiInizioAnno(0);
        setStatoTotaleCreditiInizioAnnoIva(0);
        setStatoTotaleCreditiInizioAnnoImportiNetti(0);

        setStatoTotaleDebitiInizioAnno(0);
        setStatoTotaleDebitiInizioAnnoIva(0);
        setStatoTotaleDebitiInizioAnnoImportiNetti(0);

        setStatoTotaleCreditiDebitiInizioAnno(0);
        setStatoTotaleCreditiDebitiInizioAnnoIva(0);
        setStatoTotaleCreditiDebitiInizioAnnoImportiNetti(0);

        setStatoTotaleEntrate(0);
        setStatoTotaleEntrateIva(0);
        setStatoTotaleEntrateImportiNetti(0);

        setStatoTotaleUscite(0);
        setStatoTotaleUsciteIva(0);
        setStatoTotaleUsciteImportiNetti(0);

        setStatoTotaleEntrateUscite(0);
        setStatoTotaleEntrateUsciteIva(0);
        setStatoTotaleEntrateUsciteImportiNetti(0);

        setStatoTotaleLiquiditaFineAnno(0);
        setStatoTotaleLiquiditaFineAnnoIva(0);
        setStatoTotaleLiquiditaFineAnnoImportiNetti(0);

        setStatoTotaleCreditiFineAnno(0);
        setStatoTotaleCreditiFineAnnoIva(0);
        setStatoTotaleCreditiFineAnnoImportiNetti(0);

        setStatoTotaleDebitiFineAnno(0);
        setStatoTotaleDebitiFineAnnoIva(0);
        setStatoTotaleDebitiFineAnnoImportiNetti(0);

        setStatoTotaleCreditiDebitiFineAnno(0);
        setStatoTotaleCreditiDebitiFineAnnoIva(0);
        setStatoTotaleCreditiDebitiFineAnnoImportiNetti(0);

        setDataLiquiditaAdInizioAnno([]);
        setDataCreditiAdInizioAnno([]);
        setDataDebitiAdInizioAnno([]);
        setDataEntrate([]);
        setDataUscite([]);
        setDataLiquiditaAFineAnno([]);
        setDataCreditiAFineAnno([]);
        setDataDebitiAFineAnno([]);

        setStatoPagina(STATO_RICERCA_DATI); 
   
        setNecessitaRicalcolo(0);   
      }
    },[necessitaRicalcolo])

    function trovaImpostazioniReport() {
      //vado a fare la fetch di ricerca delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          ricercaImpostazioni : 1,
          report : 5,
          emak: props.emak,
          sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
              setVisualizzaLiquiditaAdInizioAnnoTab(parseInt(valoreDiRitorno.risultatoRitorno[0].LIQ_INIZIO_TAB));
              setVisualizzaLiquiditaAdInizioAnno(parseInt(valoreDiRitorno.risultatoRitorno[0].LIQ_INIZIO_GRA));
              setTipoGraficoLiquiditaAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].LIQ_INIZIO_TIPO_GRA);

              setVisualizzaCreditiAdInizioAnnoTab(parseInt(valoreDiRitorno.risultatoRitorno[0].CREDITI_INIZIO_TAB));
              setVisualizzaCreditiAdInizioAnno(parseInt(valoreDiRitorno.risultatoRitorno[0].CREDITI_INIZIO_GRA));
              setTipoGraficoCreditiAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].CREDITI_INIZIO_TIPO_GRA);

              setVisualizzaDebitiAdInizioAnnoTab(parseInt(valoreDiRitorno.risultatoRitorno[0].DEBITI_INIZIO_TAB));
              setVisualizzaDebitiAdInizioAnno(parseInt(valoreDiRitorno.risultatoRitorno[0].DEBITI_INIZIO_GRA));
              setTipoGraficoDebitiAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].DEBITI_INIZIO_TIPO_GRA);
              
              setVisualizzaEntrateTab(parseInt(valoreDiRitorno.risultatoRitorno[0].ENTRATE_TAB));
              setVisualizzaEntrate(parseInt(valoreDiRitorno.risultatoRitorno[0].ENTRATE_GRA));
              setTipoGraficoEntrate(valoreDiRitorno.risultatoRitorno[0].ENTRATE_TIPO_GRA);

              setVisualizzaUsciteTab(parseInt(valoreDiRitorno.risultatoRitorno[0].USCITE_TAB));
              setVisualizzaUscite(parseInt(valoreDiRitorno.risultatoRitorno[0].USCITE_GRA));
              setTipoGraficoUscite(valoreDiRitorno.risultatoRitorno[0].USCITE_TIPO_GRA);

              setVisualizzaLiquiditaAFineAnnoTab(parseInt(valoreDiRitorno.risultatoRitorno[0].LIQ_FINE_TAB));
              setVisualizzaLiquiditaAFineAnno(parseInt(valoreDiRitorno.risultatoRitorno[0].LIQ_FINE_GRA));
              setTipoGraficoLiquiditaAFineAnno(valoreDiRitorno.risultatoRitorno[0].LIQ_FINE_TIPO_GRA);
       
              setVisualizzaCreditiAFineAnnoTab(parseInt(valoreDiRitorno.risultatoRitorno[0].CREDITI_FINE_TAB));
              setVisualizzaCreditiAFineAnno(parseInt(valoreDiRitorno.risultatoRitorno[0].CREDITI_FINE_GRA));
              setTipoGraficoCreditiAFineAnno(valoreDiRitorno.risultatoRitorno[0].CREDITI_FINE_TIPO_GRA);

              setVisualizzaDebitiAFineAnnoTab(parseInt(valoreDiRitorno.risultatoRitorno[0].DEBITI_FINE_TAB));
              setVisualizzaDebitiAFineAnno(parseInt(valoreDiRitorno.risultatoRitorno[0].DEBITI_FINE_GRA));
              setTipoGraficoDebitiAFineAnno(valoreDiRitorno.risultatoRitorno[0].DEBITI_FINE_TIPO_GRA);

              setVisualizzaMovimentiTab(parseInt(valoreDiRitorno.risultatoRitorno[0].MOVIMENTI_TAB));
              setVisualizzaSociTab(parseInt(valoreDiRitorno.risultatoRitorno[0].SOCI_TAB));
          
              setflagVisualizzaImportiNettiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_NETTI));
              setflagVisualizzaImportiIvaDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_IVA));
              setflagVisualizzaImportiLordiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_LORDI));
              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);

              setRicercaImpostazioniReport(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      ); 
    }
    function aggiornaImpostazioniReport() {
      setCambiamento(0);
      //vado a fare la fetch di aggiornamento delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          modificaImpostazioni : 1,
          report : 5,
          visualizzaTabellaLiquiditaAdInizioAnno:visualizzaLiquiditaAdInizioAnnoTab,
          visualizzaGraficiLiquiditaAdInizioAnno:visualizzaLiquiditaAdInizioAnno,
          tipoGraficiLiquiditaAdInizioAnno:tipoGraficoLiquiditaAdInizioAnno,

          visualizzaTabellaCreditiAdInizioAnno:visualizzaCreditiAdInizioAnnoTab,
          visualizzaGraficiCreditiAdInizioAnno:visualizzaCreditiAdInizioAnno,
          tipoGraficiCreditiAdInizioAnno:tipoGraficoCreditiAdInizioAnno,

          visualizzaTabellaDebitiAdInizioAnno:visualizzaDebitiAdInizioAnnoTab,
          visualizzaGraficiDebitiAdInizioAnno:visualizzaDebitiAdInizioAnno,
          tipoGraficiDebitiAdInizioAnno:tipoGraficoDebitiAdInizioAnno,
          
          visualizzaTabellaEntrate:visualizzaEntrateTab,
          visualizzaGraficiEntrate:visualizzaEntrate,
          tipoGraficiEntrate:tipoGraficoEntrate,

          visualizzaTabellaUscite:visualizzaUsciteTab,
          visualizzaGraficiUscite:visualizzaUscite,
          tipoGraficiUscite:tipoGraficoUscite,

          visualizzaTabellaLiquiditaAFineAnno:visualizzaLiquiditaAFineAnnoTab,
          visualizzaGraficiLiquiditaAFineAnno:visualizzaLiquiditaAFineAnno,
          tipoGraficiLiquiditaAFineAnno:tipoGraficoLiquiditaAFineAnno,
       
          visualizzaTabellaCreditiAFineAnno:visualizzaCreditiAFineAnnoTab,
          visualizzaGraficiCreditiAFineAnno:visualizzaCreditiAFineAnno,
          tipoGraficiCreditiAFineAnno:tipoGraficoCreditiAFineAnno,

          visualizzaTabellaDebitiAFineAnno:visualizzaDebitiAFineAnnoTab,
          visualizzaGraficiDebitiAFineAnno:visualizzaDebitiAFineAnno,
          tipoGraficiDebitiAFineAnno:tipoGraficoDebitiAFineAnno,

          visualizzaTabellaMovimenti:visualizzaMovimentiTab,
          visualizzaTabellaSoci:visualizzaSociTab,
          
          importiNetti:flagVisualizzaImportiNettiDefault,
          importiIva:flagVisualizzaImportiIvaDefault,
          importiLordi:flagVisualizzaImportiLordiDefault,
          periodoConsiderato:radioPeriodoDefault,
          elementiSignificativi:topDefault,
          visualizzaAltro:visualizzaAltro,
          emak: props.emak,
          sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1  && valoreDiRitorno.risultatoRitorno){

              setVisualizzaLiquiditaAdInizioAnnoTab(valoreDiRitorno.risultatoRitorno[0].LIQ_INIZIO_TAB);
              setVisualizzaLiquiditaAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].LIQ_INIZIO_GRA);
              setTipoGraficoLiquiditaAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].LIQ_INIZIO_TIPO_GRA);

              setVisualizzaCreditiAdInizioAnnoTab(valoreDiRitorno.risultatoRitorno[0].CREDITI_INIZIO_TAB);
              setVisualizzaCreditiAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].CREDITI_INIZIO_GRA);
              setTipoGraficoCreditiAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].CREDITI_INIZIO_TIPO_GRA);

              setVisualizzaDebitiAdInizioAnnoTab(valoreDiRitorno.risultatoRitorno[0].DEBITI_INIZIO_TAB);
              setVisualizzaDebitiAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].DEBITI_INIZIO_GRA);
              setTipoGraficoDebitiAdInizioAnno(valoreDiRitorno.risultatoRitorno[0].DEBITI_INIZIO_TIPO_GRA);
              
              setVisualizzaEntrateTab(valoreDiRitorno.risultatoRitorno[0].ENTRATE_TAB);
              setVisualizzaEntrate(valoreDiRitorno.risultatoRitorno[0].ENTRATE_GRA);
              setTipoGraficoEntrate(valoreDiRitorno.risultatoRitorno[0].ENTRATE_TIPO_GRA);

              setVisualizzaUsciteTab(valoreDiRitorno.risultatoRitorno[0].USCITE_TAB);
              setVisualizzaUscite(valoreDiRitorno.risultatoRitorno[0].USCITE_GRA);
              setTipoGraficoUscite(valoreDiRitorno.risultatoRitorno[0].USCITE_TIPO_GRA);

              setVisualizzaLiquiditaAFineAnnoTab(valoreDiRitorno.risultatoRitorno[0].LIQ_FINE_TAB);
              setVisualizzaLiquiditaAFineAnno(valoreDiRitorno.risultatoRitorno[0].LIQ_FINE_GRA);
              setTipoGraficoLiquiditaAFineAnno(valoreDiRitorno.risultatoRitorno[0].LIQ_FINE_TIPO_GRA);
       
              setVisualizzaCreditiAFineAnnoTab(valoreDiRitorno.risultatoRitorno[0].CREDITI_FINE_TAB);
              setVisualizzaCreditiAFineAnno(valoreDiRitorno.risultatoRitorno[0].CREDITI_FINE_GRA);
              setTipoGraficoCreditiAFineAnno(valoreDiRitorno.risultatoRitorno[0].CREDITI_FINE_TIPO_GRA);

              setVisualizzaDebitiAFineAnnoTab(valoreDiRitorno.risultatoRitorno[0].DEBITI_FINE_TAB);
              setVisualizzaDebitiAFineAnno(valoreDiRitorno.risultatoRitorno[0].DEBITI_FINE_GRA);
              setTipoGraficoDebitiAFineAnno(valoreDiRitorno.risultatoRitorno[0].DEBITI_FINE_TIPO_GRA);

              setVisualizzaMovimentiTab(valoreDiRitorno.risultatoRitorno[0].MOVIMENTI_TAB);
              setVisualizzaSociTab(valoreDiRitorno.risultatoRitorno[0].SOCI_TAB);
          
              setflagVisualizzaImportiNettiDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTI_NETTI);
              setflagVisualizzaImportiIvaDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTI_IVA);
              setflagVisualizzaImportiLordiDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTI_LORDI);
              setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
              if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
              setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
              setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
            }
        },
        (error) => {console.log("Errore connessione");}
      );
    }

    function scaricaLogo() {
      //console.log("scaricaLogo");
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
                if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                  setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                  setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                  setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                  setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                  setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                  setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                  setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                  setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                }
                setRicercaLogo(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      );        
    }

    function chiudiModaleImpostazioni() {
      setModaleImpostazioni(false);
      //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
      if(cambiamento === 1) aggiornaImpostazioniReport();
    }
    function ResettaImpostazioni() {
      visualizzaTutteTabelleFun();
      visualizzaTuttiGraficiFun();

      setTipoGraficoLiquiditaAdInizioAnno("Bar");
      setTipoGraficoCreditiAdInizioAnno("Pie");
      setTipoGraficoDebitiAdInizioAnno("Pie");
      setTipoGraficoEntrate("Pie");
      setTipoGraficoUscite("Pie");
      setTipoGraficoLiquiditaAFineAnno("Bar");
      setTipoGraficoCreditiAFineAnno("Pie");
      setTipoGraficoDebitiAFineAnno("Pie");
    }

  function visualizzaTuttiGraficiFun(){
    setVisualizzaLiquiditaAdInizioAnno(1);
    setVisualizzaCreditiAdInizioAnno(1);
    setVisualizzaDebitiAdInizioAnno(1);
    setVisualizzaEntrate(1);
    setVisualizzaUscite(1);
    setVisualizzaLiquiditaAFineAnno(1);
    setVisualizzaCreditiAFineAnno(1);
    setVisualizzaDebitiAFineAnno(1);
  }
  function nascondiTuttiGraficiFun(){
    setVisualizzaLiquiditaAdInizioAnno(0);
    setVisualizzaCreditiAdInizioAnno(0);
    setVisualizzaDebitiAdInizioAnno(0);
    setVisualizzaEntrate(0);
    setVisualizzaUscite(0);
    setVisualizzaLiquiditaAFineAnno(0);
    setVisualizzaCreditiAFineAnno(0);
    setVisualizzaDebitiAFineAnno(0);
  }
  function visualizzaTutteTabelleFun(){
    setVisualizzaLiquiditaAdInizioAnnoTab(1);
    setVisualizzaCreditiAdInizioAnnoTab(1);
    setVisualizzaDebitiAdInizioAnnoTab(1);
    setVisualizzaEntrateTab(1);
    setVisualizzaUsciteTab(1);
    setVisualizzaLiquiditaAFineAnnoTab(1);
    setVisualizzaCreditiAFineAnnoTab(1);
    setVisualizzaDebitiAFineAnnoTab(1);
    setVisualizzaMovimentiTab(1);
    setVisualizzaSociTab(1);
  }
  function nascondiTutteTabelleFun(){
    setVisualizzaLiquiditaAdInizioAnnoTab(0);
    setVisualizzaCreditiAdInizioAnnoTab(0);
    setVisualizzaDebitiAdInizioAnnoTab(0);
    setVisualizzaEntrateTab(0);
    setVisualizzaUsciteTab(0);
    setVisualizzaLiquiditaAFineAnnoTab(0);
    setVisualizzaCreditiAFineAnnoTab(0);
    setVisualizzaDebitiAFineAnnoTab(0);
    setVisualizzaMovimentiTab(0);
    setVisualizzaSociTab(0);
  }
  function aggiornaVistaImporti(tipo){
    if(tipo === 'netti'){
        if(flagVisualizzaImportiNettiDefault === 1){
          setflagVisualizzaImportiNettiDefault(0); 
          setLarghColonnaRisorse(larghColonnaRisorse+larghColonnaDatiRisorse);  
          setLarghColonnaTipiFatture(larghColonnaTipiFatture+larghColonnaDatiTipiFatture); 
          setLarghColonnaVoci(larghColonnaVoci+larghColonnaDatiVoci); 
          setLarghColonnaDescrizioneMovimenti(larghColonnaDescrizioneMovimenti+larghColonnaDatiMovimenti);
        }  
        if(flagVisualizzaImportiNettiDefault === 0){
          setflagVisualizzaImportiNettiDefault(1); 
          setLarghColonnaRisorse(larghColonnaRisorse-larghColonnaDatiRisorse);  
          setLarghColonnaTipiFatture(larghColonnaTipiFatture-larghColonnaDatiTipiFatture); 
          setLarghColonnaVoci(larghColonnaVoci-larghColonnaDatiVoci); 
          setLarghColonnaDescrizioneMovimenti(larghColonnaDescrizioneMovimenti-larghColonnaDatiMovimenti);
        } 
    }
    if(tipo === 'iva'){
      if(flagVisualizzaImportiIvaDefault === 1){
        setflagVisualizzaImportiIvaDefault(0); 
        setLarghColonnaRisorse(larghColonnaRisorse+larghColonnaDatiRisorse);  
        setLarghColonnaTipiFatture(larghColonnaTipiFatture+larghColonnaDatiTipiFatture); 
        setLarghColonnaVoci(larghColonnaVoci+larghColonnaDatiVoci); 
        setLarghColonnaDescrizioneMovimenti(larghColonnaDescrizioneMovimenti+larghColonnaDatiMovimenti);
      } 
      if(flagVisualizzaImportiIvaDefault === 0){
        setflagVisualizzaImportiIvaDefault(1); 
        setLarghColonnaRisorse(larghColonnaRisorse-larghColonnaDatiRisorse);  
        setLarghColonnaTipiFatture(larghColonnaTipiFatture-larghColonnaDatiTipiFatture); 
        setLarghColonnaVoci(larghColonnaVoci-larghColonnaDatiVoci);  
        setLarghColonnaDescrizioneMovimenti(larghColonnaDescrizioneMovimenti-larghColonnaDatiMovimenti);
      } 
    }
    if(tipo === 'lordi'){
      if(flagVisualizzaImportiLordiDefault === 1){
        setflagVisualizzaImportiLordiDefault(0);
        setLarghColonnaRisorse(larghColonnaRisorse+larghColonnaDatiRisorse);  
        setLarghColonnaTipiFatture(larghColonnaTipiFatture+larghColonnaDatiTipiFatture); 
        setLarghColonnaVoci(larghColonnaVoci+larghColonnaDatiVoci);  
        setLarghColonnaDescrizioneMovimenti(larghColonnaDescrizioneMovimenti+larghColonnaDatiMovimenti);
      }  
      if(flagVisualizzaImportiLordiDefault === 0){
        setflagVisualizzaImportiLordiDefault(1);
        setLarghColonnaRisorse(larghColonnaRisorse-larghColonnaDatiRisorse);  
        setLarghColonnaTipiFatture(larghColonnaTipiFatture-larghColonnaDatiTipiFatture); 
        setLarghColonnaVoci(larghColonnaVoci-larghColonnaDatiVoci);  
        setLarghColonnaDescrizioneMovimenti(larghColonnaDescrizioneMovimenti-larghColonnaDatiMovimenti);
      }
    }
  }

  if (statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF) {
    var etichettaImportoNetto = "Importo Netto";
    var etichettaImportoIVA = "IVA";
    var etichettaImportoLordo = "Importo Lordo";
    var etichettaImportoNettoPerTotali = "Netto: ";
    var etichettaImportoIVAPerTotali = "IVA: ";
    var etichettaImportoLordoPerTotali = "Lordo: ";
    var etichettaImportoNettoTop = "Importo Netto Top ";
    var etichettaImportoIVATop = "IVA Top";
    var etichettaImportoLordoTop = "Importo Lordo Top ";
    if (flagVisualizzaImportiNettiDefault === 0 && flagVisualizzaImportiIvaDefault === 0) {
      etichettaImportoLordo = "Importo";
      etichettaImportoLordoPerTotali = "";
      etichettaImportoLordoTop = "Top ";      
    }
  }

  return (
    <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>
      {statoPagina === STATO_SCARICA_PDF ? 
        <div>
          <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
          <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
          {/*<PDFViewer style={styles.viewer}>*/}

            <Document>
              <Page size="A4" style={styles.page}>
                <View style={{width:"100%"}} >
                  <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione70}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Image style={styles.logo} src={logoApp} />}
                            
                          </View> 
                        </View> 
                        <View style={styles.tableColIntestazioneDx}>   
                          <View style={styles.tableCellIntestazione}> 
                            {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                            {indirizzoSocieta !== "" ?
                            <Text style={styles.datisocieta}>{indirizzoSocieta}
                            {cittaSocieta !== "" ?
                            capSocieta !== "" ?
                            " "+capSocieta+" "+cittaSocieta
                            :" "+cittaSocieta
                            :""}
                            </Text>
                            :<Text></Text>}
                            {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                            {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                            <Text style={styles.titolo}>
                              Bilancio Anno {dataPartenzaDefault} </Text>
                              <Text style={styles.titoloPiccoloTop10}>Periodo dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
                            </Text>
                          </View> 
                        </View> 
                      </View> 
                    </View> {/*chiude table intestazione*/}

                    {visualizzaLiquiditaAdInizioAnnoTab === 1 ?
                    <View>
                    <View wrap={false} style={{width:"100%"}} ><Text style={styles.titoloTabella}>Liquidità ad Inizio Anno</Text></View>
                    
                    <View style={styles.table}>{/*apre table dati*/} 
                    
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Risorsa</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayLiquiditaInizioAnnoRisorse.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayLiquiditaInizioAnnoRisorse[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayLiquiditaInizioAnnoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayLiquiditaInizioAnnoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayLiquiditaInizioAnnoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View> 
                  </View>
                  </View>
                  :""}

                  {/*Grafici liquidità inizio anno pdf*/}    
                  {flagVisualizzaImportiNettiDefault === 1 && liquiditaInizioAnnoGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoLiquiditaAdInizioAnno === "Line" || tipoGraficoLiquiditaAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + liquiditaInizioAnnoGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && liquiditaInizioAnnoGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoLiquiditaAdInizioAnno === "Line" || tipoGraficoLiquiditaAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + liquiditaInizioAnnoGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>     
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && liquiditaInizioAnnoGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoLiquiditaAdInizioAnno === "Line" || tipoGraficoLiquiditaAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + liquiditaInizioAnnoGraficoCanvas.current.canvas.toDataURL("image/png")}/> 
                </View>:<Text></Text>}

                {visualizzaCreditiAdInizioAnnoTab === 1 ?
                  <View>
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Crediti ad Inizio Anno</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Causale</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayCreditiInizioAnnoTipiFatture.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayCreditiInizioAnnoTipiFatture[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayCreditiInizioAnnoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayCreditiInizioAnnoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayCreditiInizioAnnoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleCreditiInizioAnnoImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleCreditiInizioAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleCreditiInizioAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View>
                  </View>
                  </View>:""}
    
                  {/*GRAFICI CREDITI INIZIO ANNO */}
                  {flagVisualizzaImportiNettiDefault === 1 && creditiInizioAnnoGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoCreditiAdInizioAnno === "Line" || tipoGraficoCreditiAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + creditiInizioAnnoGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && creditiInizioAnnoGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoCreditiAdInizioAnno === "Line" || tipoGraficoCreditiAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + creditiInizioAnnoGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>      
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && creditiInizioAnnoGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoCreditiAdInizioAnno === "Line" || tipoGraficoCreditiAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + creditiInizioAnnoGraficoCanvas.current.canvas.toDataURL("image/png")}/> 
                </View>:<Text></Text>}
                  
                  {visualizzaDebitiAdInizioAnnoTab === 1 ?
                  <View>
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Debiti ad Inizio Anno</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Causale</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayDebitiInizioAnnoTipiFatture.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayDebitiInizioAnnoTipiFatture[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayDebitiInizioAnnoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayDebitiInizioAnnoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayDebitiInizioAnnoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleDebitiInizioAnnoImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleDebitiInizioAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleDebitiInizioAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View>
                  </View>
                  </View>
                  :""}
                  {/*GRAFICI Debiti INIZIO ANNO */}

                  {flagVisualizzaImportiNettiDefault === 1 && debitiInizioAnnoGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoDebitiAdInizioAnno === "Line" || tipoGraficoDebitiAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + debitiInizioAnnoGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && debitiInizioAnnoGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoDebitiAdInizioAnno === "Line" || tipoGraficoDebitiAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + debitiInizioAnnoGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>     
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && debitiInizioAnnoGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoDebitiAdInizioAnno === "Line" || tipoGraficoDebitiAdInizioAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + debitiInizioAnnoGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {visualizzaCreditiAdInizioAnnoTab === 1 && visualizzaDebitiAdInizioAnnoTab === 1 &&
                  <View wrap={false} style={{width:"100%"}} >
                        <View style={styles.contenitoreTotali}>
                          <Text style={styles.testoTotaliGrassetto}>Totale Crediti e Debiti ad Inizio Anno</Text> 

                          {flagVisualizzaImportiNettiDefault === 1 &&
                              <Text style={styles.testoTotali}>{etichettaImportoNettoPerTotali}{FormattaImportoVideo(statoTotaleCreditiDebitiInizioAnnoImportiNetti)} €</Text> 
                          }

                          {flagVisualizzaImportiIvaDefault === 1 &&
                              <Text style={styles.testoTotali}>{etichettaImportoIVAPerTotali}{FormattaImportoVideo(statoTotaleCreditiDebitiInizioAnnoIva)} €</Text> 
                          }

                          {flagVisualizzaImportiLordiDefault === 1 &&                   
                              <Text style={styles.testoTotali}>{etichettaImportoLordoPerTotali}{FormattaImportoVideo(statoTotaleCreditiDebitiInizioAnno)} €</Text> 
                          }

                    </View> 
                  </View> 
                }

                <View break/>
            
                {visualizzaEntrateTab === 1 ?
                  <View>
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Entrate</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Voce di Bilancio</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayEntrateAnnoCorsoVoci.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayEntrateAnnoCorsoVoci[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayEntrateAnnoCorsoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayEntrateAnnoCorsoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayEntrateAnnoCorsoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleEntrateImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleEntrateIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleEntrate)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View>
                  </View>
                  </View> 
                  :""}

                  {/*GRAFICI ENTRATE ANNO IN CORSO*/}
                {flagVisualizzaImportiNettiDefault === 1 && entrateGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoEntrate === "Line" || tipoGraficoEntrate === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + entrateGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && entrateGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoEntrate === "Line" || tipoGraficoEntrate === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + entrateGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && entrateGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                  <Image style={tipoGraficoEntrate === "Line" || tipoGraficoEntrate === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + entrateGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {visualizzaUsciteTab === 1 ?
                  <View>
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Uscite</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Voce di Bilancio</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayUsciteAnnoCorsoVoci.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayUsciteAnnoCorsoVoci[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayUsciteAnnoCorsoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayUsciteAnnoCorsoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayUsciteAnnoCorsoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleUsciteImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleUsciteIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleUscite)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View>
                  </View>
                  </View>
                  :""}

                  {/*GRAFICI USCITE ANNO IN CORSO*/}
                  {flagVisualizzaImportiNettiDefault === 1 && usciteGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoUscite === "Line" || tipoGraficoUscite === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + usciteGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && usciteGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoUscite === "Line" || tipoGraficoUscite === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + usciteGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && usciteGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoUscite === "Line" || tipoGraficoUscite === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + usciteGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {visualizzaUsciteTab === 1 && visualizzaEntrateTab === 1 &&
                  <View wrap={false} style={{width:"100%"}} >
                        <View style={styles.contenitoreTotali}>
                          <Text style={styles.testoTotaliGrassetto}>Totale Entrate ed Uscite dell'anno</Text> 

                          {(arrayUsciteAnnoCorsoImportiNetti.length>0 || arrayEntrateAnnoCorsoImportiNetti.length>0) && flagVisualizzaImportiNettiDefault === 1 &&
                              <Text style={styles.testoTotali}>{etichettaImportoNettoPerTotali}{FormattaImportoVideo(statoTotaleEntrateUsciteImportiNetti)} €</Text> 
                          }

                          {(arrayUsciteAnnoCorsoIva.length>0 || arrayEntrateAnnoCorsoIva.length>0) && flagVisualizzaImportiIvaDefault === 1 &&
                              <Text style={styles.testoTotali}>{etichettaImportoIVAPerTotali}{FormattaImportoVideo(statoTotaleEntrateUsciteIva)} €</Text> 
                          }

                          {(arrayUsciteAnnoCorsoImporti.length>0 || arrayEntrateAnnoCorsoImporti.length>0) && flagVisualizzaImportiLordiDefault === 1 &&                   
                              <Text style={styles.testoTotali}>{etichettaImportoLordoPerTotali}{FormattaImportoVideo(statoTotaleEntrateUscite)} €</Text> 
                          }

                    </View> 
                  </View> 
                }

                <View break/>

                  {visualizzaLiquiditaAFineAnnoTab === 1 ?
                  <View> 
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Liquidità a Fine Anno</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Risorsa</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayLiquiditaFineAnnoRisorse.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayLiquiditaFineAnnoRisorse[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayLiquiditaFineAnnoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayLiquiditaFineAnnoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayLiquiditaFineAnnoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaFineAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View> 
                  </View>
                  </View>
                  :""}

                    {/*GRAFICI LIQUIDITA FINE*/}
                  {flagVisualizzaImportiNettiDefault === 1 && liquiditaFineAnnoGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoLiquiditaAFineAnno === "Line" || tipoGraficoLiquiditaAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + liquiditaFineAnnoGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && liquiditaFineAnnoGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoLiquiditaAFineAnno === "Line" || tipoGraficoLiquiditaAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + liquiditaFineAnnoGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && liquiditaFineAnnoGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoLiquiditaAFineAnno === "Line" || tipoGraficoLiquiditaAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + liquiditaFineAnnoGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {visualizzaCreditiAFineAnnoTab === 1 ?
                  <View>
                    <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Crediti a Fine Anno</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Causale</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayCreditiFineAnnoTipiFatture.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayCreditiFineAnnoTipiFatture[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayCreditiFineAnnoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayCreditiFineAnnoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayCreditiFineAnnoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleCreditiFineAnnoImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleCreditiFineAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleCreditiFineAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View>
                  </View>
                  </View>:""}

                  {/*GRAFICI CREDITI FINE ANNO*/}
                  {flagVisualizzaImportiNettiDefault === 1 && creditiFineAnnoGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoCreditiAFineAnno === "Line" || tipoGraficoCreditiAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + creditiFineAnnoGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && creditiFineAnnoGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoCreditiAFineAnno === "Line" || tipoGraficoCreditiAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + creditiFineAnnoGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && creditiFineAnnoGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoCreditiAFineAnno === "Line" || tipoGraficoCreditiAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + creditiFineAnnoGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {visualizzaDebitiAFineAnnoTab === 1 ?
                  <View>
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Debiti a Fine Anno</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Causale</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>

                    {arrayDebitiFineAnnoTipiFatture.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayDebitiFineAnnoTipiFatture[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayDebitiFineAnnoImportiNetti[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayDebitiFineAnnoIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayDebitiFineAnnoImporti[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleDebitiFineAnnoImportiNetti)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleDebitiFineAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}     
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleDebitiFineAnno)}</Text>
                          </View> 
                        :<Text></Text>}    
                    </View>
                  </View>
                  </View>
                  :""}

                  {/*GRAFICI Debiti Fine ANNO*/}
                  {flagVisualizzaImportiNettiDefault === 1 && debitiFineAnnoGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoDebitiAFineAnno === "Line" || tipoGraficoDebitiAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + debitiFineAnnoGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiIvaDefault === 1 && debitiFineAnnoGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoDebitiAFineAnno === "Line" || tipoGraficoDebitiAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + debitiFineAnnoGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                {flagVisualizzaImportiLordiDefault === 1 && debitiFineAnnoGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoDebitiAFineAnno === "Line" || tipoGraficoDebitiAFineAnno === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + debitiFineAnnoGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {visualizzaCreditiAFineAnnoTab === 1 && visualizzaDebitiAFineAnnoTab === 1 &&
                  <View wrap={false} style={{width:"100%"}} >
                        <View style={styles.contenitoreTotali}>
                          <Text style={styles.testoTotaliGrassetto}>Totale Crediti e Debiti a Fine Anno</Text> 

                          {flagVisualizzaImportiNettiDefault === 1 &&
                              <Text style={styles.testoTotali}>{etichettaImportoNettoPerTotali}{FormattaImportoVideo(statoTotaleCreditiDebitiFineAnnoImportiNetti)} €</Text> 
                          }

                          {flagVisualizzaImportiIvaDefault === 1 &&
                              <Text style={styles.testoTotali}>{etichettaImportoIVAPerTotali}{FormattaImportoVideo(statoTotaleCreditiDebitiFineAnnoIva)} €</Text> 
                          }

                          {flagVisualizzaImportiLordiDefault === 1 &&                   
                              <Text style={styles.testoTotali}>{etichettaImportoLordoPerTotali}{FormattaImportoVideo(statoTotaleCreditiDebitiFineAnno)} €</Text> 
                          }

                    </View> 
                  </View> 
                }                
                  
                  {visualizzaMovimentiTab === 1 ?
                  <View>
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Movimenti</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                  
                      <View style={tableColStatoMovimenti === 7 ? styles.tableCol10Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol10Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol10Di100
                        : styles.tableCol10Di100
                        }>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Data</Text> 
                      </View> 
                      <View style={tableColStatoMovimenti === 7 ? styles.tableCol23Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol30Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol35Di100
                        : styles.tableCol40Di100
                        }>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Descrizione</Text> 
                      </View>
                      <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }>   
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Risorsa</Text> 
                      </View> 
                      <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }>   
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Voce Bilancio</Text> 
                      </View> 
                      {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>   
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoNetto}</Text> 
                        </View>
                      :<Text></Text>}
                      {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>    
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoIVA}</Text> 
                        </View> 
                      :<Text></Text>}
                      {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{etichettaImportoLordo}</Text> 
                        </View> 
                      :<Text></Text>}
                    </View>
                    {/*metto come primo record la liquidità di inizio anno*/}
                    <View wrap={false} style={styles.tableRowGrigia} >
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol10Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol10Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol10Di100
                        : styles.tableCol10Di100
                        }>                    
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}><TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano></Text>                        
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol23Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol30Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol35Di100
                        : styles.tableCol40Di100
                        }>                     
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Inizio Anno</Text>                        
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }>                    
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}></Text>                        
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }>                     
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}></Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>   
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoImportiNetti)}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoIva)}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnno)}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>

                    {arrayMovimentiImporto.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol10Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol10Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol10Di100
                        : styles.tableCol10Di100
                        }>                    
                            <Text style={styles.tableCellAllineatoSinistra}><TrasformaDateAUmano data={arrayMovimentiData[index]}></TrasformaDateAUmano></Text>                        
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol23Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol30Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol35Di100
                        : styles.tableCol40Di100
                        }> 
                            {arrayMovimentiDescrizione[index]!=='' &&
                              <Text style={styles.tableCellAllineatoSinistra}>{arrayMovimentiDescrizione[index]}</Text>       
                            }
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }> 
                            {arrayMovimentiRisorsa[index]!=='' &&
                              <Text style={styles.tableCellAllineatoSinistra}>{arrayMovimentiRisorsa[index]}</Text>     
                            }
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }> 
                            {arrayMovimentiVoce[index]!=='' &&                    
                              <Text style={styles.tableCellAllineatoSinistra}>{arrayMovimentiVoce[index]}</Text>   
                            }
                        </View>

                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayMovimentiImportoNetto[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }> 
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayMovimentiIva[index])}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>  
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayMovimentiImporto[index])}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>)}

                    {/*metto come ultimo record la liquidità di fine anno*/}
                    <View wrap={false} style={styles.tableRowGrigia}>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol10Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol10Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol10Di100
                        : styles.tableCol10Di100
                        }>                   
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}><TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano></Text>                        
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol23Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol30Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol35Di100
                        : styles.tableCol40Di100
                        }>                    
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Fine Anno</Text>                        
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }>                    
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}></Text>                        
                        </View>
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol17Di100 
                        : tableColStatoMovimenti === 6 ? styles.tableCol19Di100
                        : tableColStatoMovimenti === 5 ? styles.tableCol22Di100
                        : styles.tableCol25Di100
                        }>                   
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}></Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }> 
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoImportiNetti)}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoIva)}</Text>
                        </View>  
                        :<Text></Text>}      
                        {flagVisualizzaImportiLordiDefault === 1 ?
                        <View style={tableColStatoMovimenti === 7 ? styles.tableCol11Di100 
                          : tableColStatoMovimenti === 6 ? styles.tableCol11Di100
                          : tableColStatoMovimenti === 5 ? styles.tableCol11Di100
                          : styles.tableCol11Di100
                          }>  
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleLiquiditaFineAnno)}</Text>
                        </View>  
                        :<Text></Text>}                
                    </View>
                  </View>
                  </View>
                  :""}

                {visualizzaSociTab === 1 ?
                  <View>
                  <View wrap={false} break style={{width:"100%"}} ><Text style={styles.titoloTabella}>Soci</Text></View>
                    <View style={styles.table}>{/*apre table dati*/} 
                    <View wrap={false} style={styles.tableRowBlu}> 
                      <View style={styles.tableCol22Di100}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Cognome</Text> 
                      </View> 
                      <View style={styles.tableCol22Di100}>  
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Nome</Text> 
                      </View>
                      <View style={styles.tableCol10Di100}>
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Tessera</Text> 
                      </View> 
                      <View style={styles.tableCol26Di100}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Posizioni</Text> 
                      </View> 
                      <View style={styles.tableCol10Di100}>
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Data</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistraNoBorderTop}>Ingresso</Text>
                        <Text style={styles.tableCellGrassettoAllineatoSinistraNoBorderTop}>Società</Text> 
                      </View> 
                      <View style={styles.tableCol10Di100}> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistra}>Data</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistraNoBorderTop}>Uscita</Text> 
                        <Text style={styles.tableCellGrassettoAllineatoSinistraNoBorderTop}>Società</Text> 
                      </View> 
                    </View>

                    {arraySociCognome.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={styles.tableCol22Di100}>                    
                            {arraySociCognome[index]!=='' ?                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arraySociCognome[index]}</Text>       
                            :<Text style={styles.tableCellAllineatoSinistra}></Text>                        
                            }
                        </View>
                        <View style={styles.tableCol22Di100}>
                            {arraySociNome[index]!=='' ?                    
                            <Text style={styles.tableCellAllineatoSinistra}>{arraySociNome[index]}</Text>       
                            :<Text style={styles.tableCellAllineatoSinistra}></Text>                
                            }
                        </View>
                        <View style={styles.tableCol10Di100}>  
                            {arraySociTessera[index]!=='' ?           
                            <Text style={styles.tableCellAllineatoSinistra}>{arraySociTessera[index]}</Text>     
                            :<Text style={styles.tableCellAllineatoSinistra}></Text>                  
                            }
                        </View>
                        <View style={styles.tableCol26Di100}>  
                            {arraySociPosizioni[index]!=='' ?           
                            <Text style={styles.tableCellAllineatoSinistra}>{arraySociPosizioni[index]}</Text>     
                            :<Text style={styles.tableCellAllineatoSinistra}></Text>                  
                            }
                        </View>
                        <View style={styles.tableCol10Di100}>
                            {arraySociDataIn[index]!=='' ?                    
                            <Text style={styles.tableCellAllineatoSinistra}><TrasformaDateAUmano data={arraySociDataIn[index]}></TrasformaDateAUmano></Text>   
                            :<Text style={styles.tableCellAllineatoSinistra}></Text>                       
                            }
                        </View>
                        <View style={styles.tableCol10Di100}> 
                            {arraySociDataOut[index]!=='' ?                    
                            <Text style={styles.tableCellAllineatoSinistra}><TrasformaDateAUmano data={arraySociDataOut[index]}></TrasformaDateAUmano></Text>   
                            :<Text style={styles.tableCellAllineatoSinistra}></Text>                       
                            }
                        </View>               
                    </View>)}

                  </View>{/*chiude table dati*/}
                  </View>
                  :""} 
                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
              </Page>
            </Document>
        </PDFViewer>
      </div>

      :""}

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Bil</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}
      {statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
            <Clessidra loading={true}/>
          </div> 
        </div> :""}


      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaSpazioCentrale" style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
      
            <Row>
              <Col lg="12">
                    <Card className="main-card mb-3">
                    <CardBody>
                            <FormGroup>
                            {/*<div id="test1" className="test1">Prova 1</div>
                            <div id="test2" className="test1">Prova 2</div>*/}
                            <Clessidra loading={loading}/>
                     
                            <div id="reportBilancioIntestazione">
                            
                            <CardTitle>
                            Bilancio &nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                              {[...Array(11)].map((elemento, indice) => (
                                <option key={indice} value={annoOggi + 1 - indice}>
                                  {annoOggi + 1 - indice}
                                </option>
                              ))}                              
                            </select>    
                            &nbsp; dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>                  
                            </CardTitle>
                              <Button color="success" className="bottoneMargineASinistra" onClick={() => {setModaleImpostazioni(true)}}>Impostazioni</Button>
                              <Button color="success" className="bottoneMargineASinistra" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
                             
                            
                            {modaleImpostazioni === true ?
                              <Modale 
                                  larghezza = "lg"
                                  titolo="Impostazioni layout report bilancio"
                                  flagErrore={false}
                                  contenuto={<div>
                                <CardBody>
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Liquidità ad Inizio Anno</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLiquiditaAdInizioAnnoTab" 
                                      defaultChecked = {visualizzaLiquiditaAdInizioAnnoTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaLiquiditaAdInizioAnnoTab === 1 ? setVisualizzaLiquiditaAdInizioAnnoTab(0) : setVisualizzaLiquiditaAdInizioAnnoTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLiquiditaAdInizioAnno" 
                                      defaultChecked = {visualizzaLiquiditaAdInizioAnno === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaLiquiditaAdInizioAnno === 1 ? setVisualizzaLiquiditaAdInizioAnno(0) : setVisualizzaLiquiditaAdInizioAnno(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGLiqIni" id="TGLiqIni" innerRef={TGLiqIni} onChange={(e)=>{setTipoGraficoLiquiditaAdInizioAnno(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoLiquiditaAdInizioAnno === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoLiquiditaAdInizioAnno === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoLiquiditaAdInizioAnno === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoLiquiditaAdInizioAnno === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoLiquiditaAdInizioAnno === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoLiquiditaAdInizioAnno === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Crediti ad Inizio Anno</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaCreditiAdInizioAnnoTab" 
                                      defaultChecked = {visualizzaCreditiAdInizioAnnoTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaCreditiAdInizioAnnoTab === 1 ? setVisualizzaCreditiAdInizioAnnoTab(0) : setVisualizzaCreditiAdInizioAnnoTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLiquiditaAdInizioAnno" 
                                      defaultChecked = {visualizzaCreditiAdInizioAnno === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaCreditiAdInizioAnno === 1 ? setVisualizzaCreditiAdInizioAnno(0) : setVisualizzaCreditiAdInizioAnno(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGCreIni" id="TGCreIni" innerRef={TGCreIni} onChange={(e)=>{setTipoGraficoCreditiAdInizioAnno(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoCreditiAdInizioAnno=== "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoCreditiAdInizioAnno === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoCreditiAdInizioAnno === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoCreditiAdInizioAnno === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoCreditiAdInizioAnno === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoCreditiAdInizioAnno === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Debiti ad Inizio Anno</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaDebitiAdInizioAnnoTab" 
                                      defaultChecked = {visualizzaDebitiAdInizioAnnoTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaDebitiAdInizioAnnoTab === 1 ? setVisualizzaDebitiAdInizioAnnoTab(0) : setVisualizzaDebitiAdInizioAnnoTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaDebitiAdInizioAnno" 
                                      defaultChecked = {visualizzaDebitiAdInizioAnno === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaDebitiAdInizioAnno === 1 ? setVisualizzaDebitiAdInizioAnno(0) : setVisualizzaDebitiAdInizioAnno(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGDebIni" id="TGDebIni" innerRef={TGDebIni} onChange={(e)=>{setTipoGraficoDebitiAdInizioAnno(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoDebitiAdInizioAnno=== "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoDebitiAdInizioAnno === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoDebitiAdInizioAnno === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoDebitiAdInizioAnno === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoDebitiAdInizioAnno === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoDebitiAdInizioAnno === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Entrate</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaEntrateTab" 
                                      defaultChecked = {visualizzaEntrateTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaEntrateTab === 1 ? setVisualizzaEntrateTab(0) : setVisualizzaEntrateTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaEntrate" 
                                      defaultChecked = {visualizzaEntrate === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaEntrate === 1 ? setVisualizzaEntrate(0) : setVisualizzaEntrate(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGEntr" id="TGEntr" innerRef={TGEntr} onChange={(e)=>{setTipoGraficoEntrate(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoEntrate=== "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoEntrate === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoEntrate === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoEntrate === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoEntrate === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoEntrate === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Uscite</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaUsciteTab" 
                                      defaultChecked = {visualizzaUsciteTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaUsciteTab === 1 ? setVisualizzaUsciteTab(0) : setVisualizzaUsciteTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaUscite" 
                                      defaultChecked = {visualizzaUscite === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaUscite === 1 ? setVisualizzaUscite(0) : setVisualizzaUscite(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGUsc" id="TGUsc" innerRef={TGUsc} onChange={(e)=>{setTipoGraficoUscite(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoUscite=== "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoUscite === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoUscite === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoUscite === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoUscite === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoUscite === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Liquidità a Fine Anno</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLiquiditaAFineAnnoTab" 
                                      defaultChecked = {visualizzaLiquiditaAFineAnnoTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaLiquiditaAFineAnnoTab === 1 ? setVisualizzaLiquiditaAFineAnnoTab(0) : setVisualizzaLiquiditaAFineAnnoTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLiquiditaAFineAnno" 
                                      defaultChecked = {visualizzaLiquiditaAFineAnno === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaLiquiditaAFineAnno === 1 ? setVisualizzaLiquiditaAFineAnno(0) : setVisualizzaLiquiditaAFineAnno(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGLiqFine" id="TGLiqFine" innerRef={TGLiqFine} onChange={(e)=>{setTipoGraficoLiquiditaAFineAnno(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoLiquiditaAFineAnno=== "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoLiquiditaAFineAnno === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoLiquiditaAFineAnno === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoLiquiditaAFineAnno === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoLiquiditaAFineAnno === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoLiquiditaAFineAnno === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Crediti a Fine Anno</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaCreditiAFineAnnoTab" 
                                      defaultChecked = {visualizzaCreditiAFineAnnoTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaCreditiAFineAnnoTab === 1 ? setVisualizzaCreditiAFineAnnoTab(0) : setVisualizzaCreditiAFineAnnoTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaCreditiAFineAnno" 
                                      defaultChecked = {visualizzaCreditiAFineAnno === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaCreditiAFineAnno === 1 ? setVisualizzaCreditiAFineAnno(0) : setVisualizzaCreditiAFineAnno(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGCreFine" id="TGCreFine" innerRef={TGCreFine} onChange={(e)=>{setTipoGraficoCreditiAFineAnno(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoCreditiAFineAnno === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoCreditiAFineAnno === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoCreditiAFineAnno === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoCreditiAFineAnno === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoCreditiAFineAnno === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoCreditiAFineAnno === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Debiti a Fine Anno</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaDebitiAFineAnnoTab" 
                                      defaultChecked = {visualizzaDebitiAFineAnnoTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaDebitiAFineAnnoTab === 1 ? setVisualizzaDebitiAFineAnnoTab(0) : setVisualizzaDebitiAFineAnnoTab(1);setCambiamento(1)}}/></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaDebitiAFineAnno" 
                                      defaultChecked = {visualizzaDebitiAFineAnno === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaDebitiAFineAnno === 1 ? setVisualizzaDebitiAFineAnno(0) : setVisualizzaDebitiAFineAnno(1);setCambiamento(1)}}/></center></Col>
                                <Col>
                                <center><select style={{marginTop:10}} name="TGDebFine" id="TGDebFine" innerRef={TGDebFine} onChange={(e)=>{setTipoGraficoDebitiAFineAnno(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoDebitiAFineAnno === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoDebitiAFineAnno === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoDebitiAFineAnno === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoDebitiAFineAnno === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoDebitiAFineAnno === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoDebitiAFineAnno === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center> 
                                </Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col style={{marginTop:5,marginBottom:5}}>Movimenti</Col>
                                <Col style={{marginLeft:5,marginRight:20}}><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaMovimentiTab" 
                                      defaultChecked = {visualizzaMovimentiTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaMovimentiTab === 1 ? setVisualizzaMovimentiTab(0) : setVisualizzaMovimentiTab(1);setCambiamento(1)}}/></center></Col>
                                <Col></Col>
                                <Col>
                                </Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col style={{marginTop:5,marginBottom:5}}>Soci</Col>
                                <Col style={{marginLeft:5,marginRight:20}}><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaSociTab" 
                                      defaultChecked = {visualizzaSociTab=== 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaSociTab === 1 ? setVisualizzaSociTab(0) : setVisualizzaSociTab(1);setCambiamento(1)}}/></center></Col>
                                <Col></Col>
                                <Col>
                                </Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio importi da visionare*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Importi da visionare</center></b></Col>
                                </Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiNetti" id="flagVisualizzaImportiNetti"
                                defaultChecked = {flagVisualizzaImportiNettiDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiNetti}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("netti");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiNetti">Visualizza Importi Netti</Label>
                                </center> </Col>
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiIva" id="flagVisualizzaImportiIva"
                                defaultChecked = {flagVisualizzaImportiIvaDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiIva}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("iva");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiIva">Visualizza Importi IVA</Label>
                                </center> </Col>
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiLordi" id="flagVisualizzaImportiLordi"
                                defaultChecked = {flagVisualizzaImportiLordiDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiLordi}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("lordi");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiLordi">Visualizza Importi Lordi</Label>
                                </center> </Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio tipo periodo*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col></Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(annoOggi)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                <Col><center>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(new Date().getFullYear())}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                </Row>
                                </CardBody>
                                <CardBody>
                                {/*settaggio Top N*/}
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(2);setCambiamento(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                                  </div>}
                                  bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {chiudiModaleImpostazioni()}
                                },    
                                {
                                  "etichetta":"Resetta Impostazioni Grafici",
                                  "tipo":"danger",
                                  callback:() => {ResettaImpostazioni();setCambiamento(1)}
                              },  
                            ]}
                              />
                          :""}
                            
                            </div>
                            </FormGroup>

                            {risultatoServer !== undefined && risultatoServer !== "" ?
                            <div id="reportBilancio">  
                                <div id="liquiditaInizioAnnoIntestazione"  style={{width:'100%'}}>
                                {visualizzaLiquiditaAdInizioAnnoTab === 1 || visualizzaLiquiditaAdInizioAnno === 1?
                                 <Row><Col><CardTitle>Liquidità ad inizio anno</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="liquiditaInizioAnnoTabella"  style={{width:'100%'}}>  
                                  {arrayLiquiditaInizioAnnoImporti.length>0 || arrayLiquiditaInizioAnnoIva.length>0 || arrayLiquiditaInizioAnnoImportiNetti.length>0 ?
                                    visualizzaLiquiditaAdInizioAnnoTab === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col md={larghColonnaRisorseLiquidita}><b>Risorsa</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""}
                                    </Row>
                                    {arrayLiquiditaInizioAnnoRisorse.map((elemento,index) => <Row form className="border" key={index} >
                                      {arrayLiquiditaInizioAnnoRisorse[index]!=='' ? 
                                        <Col md={larghColonnaRisorseLiquidita}>{arrayLiquiditaInizioAnnoRisorse[index]}</Col>
                                      : <Col md={larghColonnaRisorseLiquidita}>Risorsa non specificata</Col>}
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayLiquiditaInizioAnnoImportiNetti[index])}</Col> :""}
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayLiquiditaInizioAnnoIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayLiquiditaInizioAnnoImporti[index])}</Col> :""}
                                      </Row>)
                                    }
                                    </Fragment>
                                    :""
                                  : ""}
                                  {visualizzaLiquiditaAdInizioAnnoTab === 1 ?
                                    <Row form className="border"><Col md={larghColonnaRisorseLiquidita}><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoImportiNetti)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoIva)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnno)}</b></Col> :""}
                                    </Row>                                
                                  :""}
                                  </div>

                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaInizioAnnoNetti === 1 && tipoGraficoLiquiditaAdInizioAnno==="Bar" && arrayTopNettoLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataLiquiditaAdInizioAnnoNetti} ref={liquiditaInizioAnnoGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaInizioAnnoNetti === 1 && tipoGraficoLiquiditaAdInizioAnno==="Doughnut" && arrayTopNettoLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataLiquiditaAdInizioAnnoNetti} ref={liquiditaInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaInizioAnnoNetti === 1 && tipoGraficoLiquiditaAdInizioAnno==="Line" && arrayTopNettoLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataLiquiditaAdInizioAnnoNetti} ref={liquiditaInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaInizioAnnoNetti === 1 && tipoGraficoLiquiditaAdInizioAnno==="Pie" && arrayTopNettoLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataLiquiditaAdInizioAnnoNetti} ref={liquiditaInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaInizioAnnoNetti === 1 && tipoGraficoLiquiditaAdInizioAnno==="PolarArea" && arrayTopNettoLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataLiquiditaAdInizioAnnoNetti} ref={liquiditaInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaInizioAnnoNetti === 1 && tipoGraficoLiquiditaAdInizioAnno==="Radar" && arrayTopNettoLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataLiquiditaAdInizioAnnoNetti} ref={liquiditaInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaInizioAnnoIva === 1 && tipoGraficoLiquiditaAdInizioAnno==="Bar" && arrayTopIvaLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataLiquiditaAdInizioAnnoIva} ref={liquiditaInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaInizioAnnoIva === 1 && tipoGraficoLiquiditaAdInizioAnno==="Doughnut" && arrayTopIvaLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataLiquiditaAdInizioAnnoIva} ref={liquiditaInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaInizioAnnoIva === 1 && tipoGraficoLiquiditaAdInizioAnno==="Line" && arrayTopIvaLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataLiquiditaAdInizioAnnoIva} ref={liquiditaInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaInizioAnnoIva === 1 && tipoGraficoLiquiditaAdInizioAnno==="Pie" && arrayTopIvaLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataLiquiditaAdInizioAnnoIva} ref={liquiditaInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaInizioAnnoIva === 1 && tipoGraficoLiquiditaAdInizioAnno==="PolarArea" && arrayTopIvaLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataLiquiditaAdInizioAnnoIva} ref={liquiditaInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaInizioAnnoIva === 1 && tipoGraficoLiquiditaAdInizioAnno==="Radar" && arrayTopIvaLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataLiquiditaAdInizioAnnoIva} ref={liquiditaInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                    
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaInizioAnno === 1 && tipoGraficoLiquiditaAdInizioAnno==="Bar" && arrayTopImportiLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataLiquiditaAdInizioAnno} ref={liquiditaInizioAnnoGraficoCanvas}   options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaInizioAnno === 1 && tipoGraficoLiquiditaAdInizioAnno==="Doughnut" && arrayTopImportiLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataLiquiditaAdInizioAnno} ref={liquiditaInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaInizioAnno === 1 && tipoGraficoLiquiditaAdInizioAnno==="Line" && arrayTopImportiLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                  <Line data={dataLiquiditaAdInizioAnno} ref={liquiditaInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaInizioAnno === 1 && tipoGraficoLiquiditaAdInizioAnno==="Pie" && arrayTopImportiLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataLiquiditaAdInizioAnno} ref={liquiditaInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaInizioAnno === 1 && tipoGraficoLiquiditaAdInizioAnno==="PolarArea" && arrayTopImportiLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataLiquiditaAdInizioAnno} ref={liquiditaInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaInizioAnno === 1 && tipoGraficoLiquiditaAdInizioAnno==="Radar" && arrayTopImportiLiquiditaInizioAnno.length>0 && visualizzaLiquiditaAdInizioAnno === 1?
                                  <div id="liquiditaInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataLiquiditaAdInizioAnno} ref={liquiditaInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  


                                {visualizzaLiquiditaAdInizioAnno === 1 || visualizzaLiquiditaAdInizioAnnoTab === 1 ? <br></br> :""}
                                <div id="creditiInizioAnnoIntestazione" style={{width:'100%'}}>
                                {visualizzaCreditiAdInizioAnnoTab === 1 || visualizzaCreditiAdInizioAnno === 1?
                                  <Row><Col><CardTitle>Crediti ad inizio anno</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="creditiInizioAnnoTabella" style={{width:'100%'}}>
                                {arrayCreditiInizioAnnoImporti.length>0 || arrayCreditiInizioAnnoIva.length>0 || arrayCreditiInizioAnnoImportiNetti.length>0 ?
                                  visualizzaCreditiAdInizioAnnoTab === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col md={larghColonnaMovimentiCrediti}><b>Causale</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""}
                                    </Row>
                                    {arrayCreditiInizioAnnoTipiFatture.map((elemento,index) => <Row form className="border" key={index}>
                                    <Col md={larghColonnaMovimentiCrediti}>{arrayCreditiInizioAnnoTipiFatture[index]}</Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayCreditiInizioAnnoImportiNetti[index])}</Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayCreditiInizioAnnoIva[index])}</Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayCreditiInizioAnnoImporti[index])}</Col> :""}
                                    </Row>)
                                    }
                                    </Fragment>
                                    :""
                                  : ""}
                                  {visualizzaDebitiAdInizioAnnoTab === 1 ?
                                  <Row form className="border"><Col md={larghColonnaMovimentiCrediti}><b>TOTALE</b></Col>
                                  {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiInizioAnnoImportiNetti)}</b></Col> :""}
                                  {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiInizioAnnoIva)}</b></Col> :""}
                                  {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiInizioAnno)}</b></Col> :""}
                                  </Row>
                                  :""}
                                  </div>

 
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiInizioAnnoNetti === 1 && tipoGraficoCreditiAdInizioAnno==="Bar" &&  arrayTopNettoCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataCreditiAdInizioAnnoNetti} ref={creditiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiInizioAnnoNetti === 1 && tipoGraficoCreditiAdInizioAnno==="Doughnut" &&  arrayTopNettoCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataCreditiAdInizioAnnoNetti} ref={creditiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiInizioAnnoNetti === 1  && tipoGraficoCreditiAdInizioAnno==="Line" &&  arrayTopNettoCreditiInizioAnno.length>0  && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataCreditiAdInizioAnnoNetti} ref={creditiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiInizioAnnoNetti === 1  && tipoGraficoCreditiAdInizioAnno==="Pie" &&  arrayTopNettoCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataCreditiAdInizioAnnoNetti} ref={creditiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiInizioAnnoNetti === 1  && tipoGraficoCreditiAdInizioAnno==="PolarArea" &&  arrayTopNettoCreditiInizioAnno.length>0  && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataCreditiAdInizioAnnoNetti} ref={creditiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiInizioAnnoNetti === 1  && tipoGraficoCreditiAdInizioAnno==="Radar" &&  arrayTopNettoCreditiInizioAnno.length>0 & visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataCreditiAdInizioAnnoNetti} ref={creditiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
             
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiInizioAnnoIva === 1 && tipoGraficoCreditiAdInizioAnno==="Bar" &&  arrayTopIvaCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataCreditiAdInizioAnnoIva} ref={creditiInizioAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiInizioAnnoIva === 1 && tipoGraficoCreditiAdInizioAnno==="Doughnut" &&  arrayTopIvaCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataCreditiAdInizioAnnoIva} ref={creditiInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiInizioAnnoIva === 1 && tipoGraficoCreditiAdInizioAnno==="Line" &&  arrayTopIvaCreditiInizioAnno.length>0  && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataCreditiAdInizioAnnoIva} ref={creditiInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiInizioAnnoIva === 1 && tipoGraficoCreditiAdInizioAnno==="Pie" &&  arrayTopIvaCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataCreditiAdInizioAnnoIva} ref={creditiInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiInizioAnnoIva === 1 && tipoGraficoCreditiAdInizioAnno==="PolarArea" &&  arrayTopIvaCreditiInizioAnno.length>0  && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataCreditiAdInizioAnnoIva} ref={creditiInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiInizioAnnoIva === 1 && tipoGraficoCreditiAdInizioAnno==="Radar" &&  arrayTopIvaCreditiInizioAnno.length>0 & visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataCreditiAdInizioAnnoIva} ref={creditiInizioAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiInizioAnno === 1 && tipoGraficoCreditiAdInizioAnno==="Bar" &&  arrayTopImportiCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataCreditiAdInizioAnno} ref={creditiInizioAnnoGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiInizioAnno === 1 && tipoGraficoCreditiAdInizioAnno==="Doughnut" &&  arrayTopImportiCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataCreditiAdInizioAnno} ref={creditiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiInizioAnno === 1 && tipoGraficoCreditiAdInizioAnno==="Line" &&  arrayTopImportiCreditiInizioAnno.length>0  && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataCreditiAdInizioAnno} ref={creditiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiInizioAnno === 1 && tipoGraficoCreditiAdInizioAnno==="Pie" && arrayTopImportiCreditiInizioAnno.length>0 && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataCreditiAdInizioAnno} ref={creditiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiInizioAnno === 1 && tipoGraficoCreditiAdInizioAnno==="PolarArea" &&  arrayTopImportiCreditiInizioAnno.length>0  && visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataCreditiAdInizioAnno} ref={creditiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiInizioAnno === 1 && tipoGraficoCreditiAdInizioAnno==="Radar" &&  arrayTopImportiCreditiInizioAnno.length>0 & visualizzaCreditiAdInizioAnno === 1 ?
                                  <div id="creditiInizioAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataCreditiAdInizioAnno} ref={creditiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                 
                                {visualizzaCreditiAdInizioAnno === 1 || visualizzaCreditiAdInizioAnnoTab === 1 ? <br></br> :""}

                                <div id="debitiInizioAnnoIntestazione" style={{width:'100%'}}>
                                {visualizzaDebitiAdInizioAnnoTab === 1 || visualizzaDebitiAdInizioAnno === 1?
                                  <Row><Col><CardTitle>Debiti ad inizio anno</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="debitiInizioAnnoTabella" style={{width:'100%'}}>
                                {arrayDebitiInizioAnnoImporti.length>0 || arrayDebitiInizioAnnoIva.length>0 || arrayDebitiInizioAnnoImportiNetti.length>0 ?
                                  visualizzaDebitiAdInizioAnnoTab === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col md={larghColonnaMovimentiDebiti}><b>Causale</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""} 
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""} 
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""} 
                                    </Row>
                                    {arrayDebitiInizioAnnoTipiFatture.map((elemento,index) => <Row form className="border" key={index}>
                                    <Col md={larghColonnaMovimentiDebiti}>{arrayDebitiInizioAnnoTipiFatture[index]}</Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayDebitiInizioAnnoImportiNetti[index])}</Col> :""} 
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayDebitiInizioAnnoIva[index])}</Col> :""} 
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayDebitiInizioAnnoImporti[index])}</Col> :""} 
                                    </Row>)
                                    }
                                    </Fragment>
                                  :""
                                  : ""}
                                  {visualizzaDebitiAdInizioAnnoTab === 1 ?
                                  <Row form className="border">
                                    <Col md={larghColonnaMovimentiDebiti}><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleDebitiInizioAnnoImportiNetti)}</b></Col> :""} 
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleDebitiInizioAnnoIva)}</b></Col> :""} 
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleDebitiInizioAnno)}</b></Col> :""} 
                                    </Row>
                                  :""}
                                 </div>
                                 {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiInizioAnnoNetti === 1 && tipoGraficoDebitiAdInizioAnno==="Bar" && arrayTopNettoDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                 <div id="debitiInizioAnnoGraficoNetti"  style={{width:'99vh',margin: 'auto'}}>
                                  <Bar data={dataDebitiAdInizioAnnoNetti} ref={debitiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiInizioAnnoNetti === 1 && tipoGraficoDebitiAdInizioAnno==="Doughnut" && arrayTopNettoDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                  <Doughnut data={dataDebitiAdInizioAnnoNetti} ref={debitiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiInizioAnnoNetti === 1 && tipoGraficoDebitiAdInizioAnno==="Line" && arrayTopNettoDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoNetti"  style={{width:'99vh',margin: 'auto'}}>
                                  <Line data={dataDebitiAdInizioAnnoNetti} ref={debitiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiInizioAnnoNetti === 1 && tipoGraficoDebitiAdInizioAnno==="Pie" && arrayTopNettoDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                  <Pie data={dataDebitiAdInizioAnnoNetti} ref={debitiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiInizioAnnoNetti === 1 && tipoGraficoDebitiAdInizioAnno==="PolarArea" && arrayTopNettoDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                  <PolarArea data={dataDebitiAdInizioAnnoNetti} ref={debitiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiInizioAnnoNetti === 1 && tipoGraficoDebitiAdInizioAnno==="Radar" && arrayTopNettoDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                  <Radar data={dataDebitiAdInizioAnnoNetti} ref={debitiInizioAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  
                                 {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiInizioAnnoIva === 1 && tipoGraficoDebitiAdInizioAnno==="Bar" && arrayTopIvaDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                 <div id="debitiInizioAnnoGraficoIva"  style={{width:'99vh',margin: 'auto'}}>
                                  <Bar data={dataDebitiAdInizioAnnoIva} ref={debitiInizioAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiInizioAnnoIva === 1 && tipoGraficoDebitiAdInizioAnno==="Doughnut" && arrayTopIvaDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoIva"  style={{width:'70vh',margin: 'auto'}}>
                                  <Doughnut data={dataDebitiAdInizioAnnoIva} ref={debitiInizioAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiInizioAnnoIva === 1 && tipoGraficoDebitiAdInizioAnno==="Line" && arrayTopIvaDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoIva"  style={{width:'99vh',margin: 'auto'}}>
                                  <Line data={dataDebitiAdInizioAnnoIva} ref={debitiInizioAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiInizioAnnoIva === 1 && tipoGraficoDebitiAdInizioAnno==="Pie" && arrayTopIvaDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoIva"  style={{width:'70vh',margin: 'auto'}}>
                                  <Pie data={dataDebitiAdInizioAnnoIva} ref={debitiInizioAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiInizioAnnoIva === 1 && tipoGraficoDebitiAdInizioAnno==="PolarArea" && arrayTopIvaDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoIva"  style={{width:'70vh',margin: 'auto'}}>
                                  <PolarArea data={dataDebitiAdInizioAnnoIva} ref={debitiInizioAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiInizioAnnoIva === 1 && tipoGraficoDebitiAdInizioAnno==="Radar" && arrayTopIvaDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGraficoIva"  style={{width:'70vh',margin: 'auto'}}>
                                  <Radar data={dataDebitiAdInizioAnnoIva} ref={debitiInizioAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  
                                 {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiInizioAnno === 1 && tipoGraficoDebitiAdInizioAnno==="Bar" && arrayTopImportiDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                 <div id="debitiInizioAnnoGrafico"  style={{width:'99vh',margin: 'auto'}}>
                                  <Bar data={dataDebitiAdInizioAnno} ref={debitiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiInizioAnno === 1 && tipoGraficoDebitiAdInizioAnno==="Doughnut" && arrayTopImportiDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGrafico"  style={{width:'70vh',margin: 'auto'}}>
                                  <Doughnut data={dataDebitiAdInizioAnno} ref={debitiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiInizioAnno === 1 && tipoGraficoDebitiAdInizioAnno==="Line" && arrayTopImportiDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGrafico"  style={{width:'99vh',margin: 'auto'}}>
                                  <Line data={dataDebitiAdInizioAnno} ref={debitiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiInizioAnno === 1 && tipoGraficoDebitiAdInizioAnno==="Pie" && arrayTopImportiDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGrafico"  style={{width:'70vh',margin: 'auto'}}>
                                  <Pie data={dataDebitiAdInizioAnno} ref={debitiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiInizioAnno === 1 && tipoGraficoDebitiAdInizioAnno==="PolarArea" && arrayTopImportiDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGrafico"  style={{width:'70vh',margin: 'auto'}}>
                                  <PolarArea data={dataDebitiAdInizioAnno} ref={debitiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiInizioAnno === 1 && tipoGraficoDebitiAdInizioAnno==="Radar" && arrayTopImportiDebitiInizioAnno.length>0 && visualizzaDebitiAdInizioAnno === 1  ?
                                  <div id="debitiInizioAnnoGrafico"  style={{width:'70vh',margin: 'auto'}}>
                                  <Radar data={dataDebitiAdInizioAnno} ref={debitiInizioAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti ad inizio anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  
                                                      
                                {((arrayDebitiInizioAnnoImportiNetti.length>0 || arrayCreditiInizioAnnoImportiNetti.length>0) && 
                                visualizzaCreditiAdInizioAnnoTab === 1 && visualizzaDebitiAdInizioAnnoTab === 1 && flagVisualizzaImportiNettiDefault === 1)
                                ||
                                ((arrayDebitiInizioAnnoIva.length>0 || 
                                arrayCreditiInizioAnnoIva.length>0) && 
                                visualizzaCreditiAdInizioAnnoTab === 1 && visualizzaDebitiAdInizioAnnoTab === 1 && flagVisualizzaImportiIvaDefault === 1)
                                ||
                                ((arrayDebitiInizioAnnoImporti.length>0 || 
                                arrayCreditiInizioAnnoImporti.length>0) && 
                                visualizzaCreditiAdInizioAnnoTab === 1 && visualizzaDebitiAdInizioAnnoTab === 1 && flagVisualizzaImportiLordiDefault === 1) ?
                                <br></br>
                                :""}
                                
                                <div id="creditiDebitiInizioAnnoTabella" style={{width:'100%'}}>
                                {(arrayDebitiInizioAnnoImportiNetti.length>0 || 
                                arrayCreditiInizioAnnoImportiNetti.length>0) && 
                                visualizzaCreditiAdInizioAnnoTab === 1 && visualizzaDebitiAdInizioAnnoTab === 1 && flagVisualizzaImportiNettiDefault === 1 ?
                                  <Row form className="border"><Col><b>TOTALE CREDITI E DEBITI AD INIZIO ANNO {etichettaImportoNettoPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiDebitiInizioAnnoImportiNetti)}</b></Col></Row>
                                :""}
                                {(arrayDebitiInizioAnnoIva.length>0 || 
                                arrayCreditiInizioAnnoIva.length>0) && 
                                visualizzaCreditiAdInizioAnnoTab === 1 && visualizzaDebitiAdInizioAnnoTab === 1 && flagVisualizzaImportiIvaDefault === 1 ?
                                  <Row form className="border"><Col><b>TOTALE CREDITI E DEBITI AD INIZIO ANNO {etichettaImportoIVAPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiDebitiInizioAnnoIva)}</b></Col></Row>
                                :""}
                                {(arrayDebitiInizioAnnoImporti.length>0 || 
                                arrayCreditiInizioAnnoImporti.length>0) && 
                                visualizzaCreditiAdInizioAnnoTab === 1 && visualizzaDebitiAdInizioAnnoTab === 1 && flagVisualizzaImportiLordiDefault === 1 ?
                                  <Row form className="border"><Col><b>TOTALE CREDITI E DEBITI AD INIZIO ANNO {etichettaImportoLordoPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiDebitiInizioAnno)}</b></Col></Row>
                                :""}
                                </div>

                                {visualizzaDebitiAdInizioAnno === 1 || visualizzaDebitiAdInizioAnnoTab === 1 ? <br></br> :""}

                                <div id="entrateIntestazione" style={{width:'100%'}}>
                                {visualizzaEntrateTab === 1 || visualizzaEntrate === 1 ?
                                  <Row><Col><CardTitle>Entrate</CardTitle></Col></Row>
                                :""}
                                </div>

                                <div id="entrateTabella" style={{width:'100%'}}>
                                {arrayEntrateAnnoCorsoImporti.length>0 || arrayEntrateAnnoCorsoIva.length>0 || arrayEntrateAnnoCorsoImportiNetti.length>0 ? 
                                  visualizzaEntrateTab === 1 ?
                                  <Fragment>
                                  <Row form className="border">
                                    <Col md={larghColonnaVoceEntrate}><b>Voce di Bilancio</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""}
                                    </Row>
                                  {arrayEntrateAnnoCorsoVoci.map((elemento,index) => <Row form className="border" key={index}>
                                    {arrayEntrateAnnoCorsoVoci[index]!=='' ? 
                                      <Col md={larghColonnaVoceEntrate}>{arrayEntrateAnnoCorsoVoci[index]}</Col>
                                    : <Col md={larghColonnaVoceEntrate}>Voce di Bilancio non specificata</Col>}  
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayEntrateAnnoCorsoImportiNetti[index])}</Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayEntrateAnnoCorsoIva[index])}</Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayEntrateAnnoCorsoImporti[index])}</Col> :""}
                                    </Row>)
                                  }
                                  </Fragment>
                                  :""
                                  : ""}
                                  {visualizzaEntrateTab === 1 ?
                                    <Row form className="border"><Col md={larghColonnaVoceEntrate}><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleEntrateImportiNetti)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleEntrateIva)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleEntrate)}</b></Col> :""}
                                    </Row>
                                   
                                  :""}
                                  </div>

                                 {flagVisualizzaImportiNettiDefault === 1  && graficoEntrateAnnoInCorsoNetti === 1 && tipoGraficoEntrate === "Bar" && arrayTopNettoEntrate.length>0 && visualizzaEntrate === 1 ?
                                 <div id="entrateGraficoNetti"  style={{width:'99vh',margin: 'auto'}}>
                                   <Bar data={dataEntrateNetti} ref={entrateGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiNettiDefault === 1  && graficoEntrateAnnoInCorsoNetti === 1 && tipoGraficoEntrate === "Doughnut" && arrayTopNettoEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                   <Doughnut data={dataEntrateNetti} ref={entrateGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiNettiDefault === 1  && graficoEntrateAnnoInCorsoNetti === 1 && tipoGraficoEntrate === "Line" && arrayTopNettoEntrate.length>0 &&  visualizzaEntrate === 1 ?
                                <div id="entrateGraficoNetti"  style={{width:'99vh',margin: 'auto'}}>
                                   <Line data={dataEntrateNetti} ref={entrateGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiNettiDefault === 1  && graficoEntrateAnnoInCorsoNetti === 1 && tipoGraficoEntrate === "Pie" && arrayTopNettoEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                   <Pie data={dataEntrateNetti} ref={entrateGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiNettiDefault === 1  && graficoEntrateAnnoInCorsoNetti === 1 && tipoGraficoEntrate === "PolarArea" && arrayTopNettoEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                   <PolarArea data={dataEntrateNetti} ref={entrateGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiNettiDefault === 1  && graficoEntrateAnnoInCorsoNetti === 1 && tipoGraficoEntrate === "Radar" && arrayTopNettoEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                   <Radar data={dataEntrateNetti} ref={entrateGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                
                                 {flagVisualizzaImportiIvaDefault === 1 && graficoEntrateAnnoInCorsoIva === 1 && tipoGraficoEntrate === "Bar" && arrayTopIvaEntrate.length>0 && visualizzaEntrate === 1 ?
                                 <div id="entrateGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                   <Bar data={dataEntrateIva} ref={entrateGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiIvaDefault === 1 && graficoEntrateAnnoInCorsoIva === 1 && tipoGraficoEntrate === "Doughnut" && arrayTopIvaEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                   <Doughnut data={dataEntrateIva} ref={entrateGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiIvaDefault === 1 && graficoEntrateAnnoInCorsoIva === 1 && tipoGraficoEntrate === "Line" && arrayTopIvaEntrate.length>0 &&  visualizzaEntrate === 1 ?
                                <div id="entrateGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                   <Line data={dataEntrateIva} ref={entrateGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiIvaDefault === 1 && graficoEntrateAnnoInCorsoIva === 1 && tipoGraficoEntrate === "Pie" && arrayTopIvaEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                   <Pie data={dataEntrateIva} ref={entrateGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiIvaDefault === 1 && graficoEntrateAnnoInCorsoIva === 1 && tipoGraficoEntrate === "PolarArea" && arrayTopIvaEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                   <PolarArea data={dataEntrateIva} ref={entrateGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiIvaDefault === 1 && graficoEntrateAnnoInCorsoIva === 1 && tipoGraficoEntrate === "Radar" && arrayTopIvaEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                   <Radar data={dataEntrateIva} ref={entrateGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                               
                                 {flagVisualizzaImportiLordiDefault === 1 && graficoEntrateAnnoInCorso === 1  && tipoGraficoEntrate === "Bar" && arrayTopImportiEntrate.length>0 && visualizzaEntrate === 1 ?
                                 <div id="entrateGrafico" style={{width:'99vh',margin: 'auto'}}>
                                   <Bar data={dataEntrate} ref={entrateGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiLordiDefault === 1 && graficoEntrateAnnoInCorso === 1 && tipoGraficoEntrate === "Doughnut" && arrayTopImportiEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGrafico" style={{width:'70vh',margin: 'auto'}}>
                                   <Doughnut data={dataEntrate} ref={entrateGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiLordiDefault === 1 && graficoEntrateAnnoInCorso === 1 && tipoGraficoEntrate === "Line" && arrayTopImportiEntrate.length>0 &&  visualizzaEntrate === 1 ?
                                <div id="entrateGrafico" style={{width:'99vh',margin: 'auto'}}>
                                   <Line data={dataEntrate} ref={entrateGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiLordiDefault === 1 && graficoEntrateAnnoInCorso === 1 && tipoGraficoEntrate === "Pie" && arrayTopImportiEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGrafico" style={{width:'70vh',margin: 'auto'}}>
                                   <Pie data={dataEntrate} ref={entrateGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiLordiDefault === 1 && graficoEntrateAnnoInCorso === 1 && tipoGraficoEntrate === "PolarArea" && arrayTopImportiEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGrafico" style={{width:'70vh',margin: 'auto'}}>
                                   <PolarArea data={dataEntrate} ref={entrateGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Entrate ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                {flagVisualizzaImportiLordiDefault === 1 && graficoEntrateAnnoInCorso === 1 && tipoGraficoEntrate === "Radar" && arrayTopImportiEntrate.length>0 && visualizzaEntrate === 1 ?
                                <div id="entrateGrafico" style={{width:'70vh',margin: 'auto'}}>
                                   <Radar data={dataEntrate} ref={entrateGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Entrate ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                
                                {visualizzaEntrate === 1 || visualizzaEntrateTab === 1 ? <br></br> :""}

                                <div id="usciteIntestazione" style={{width:'100%'}}>
                                {visualizzaUsciteTab === 1  || visualizzaUscite === 1 ?
                                  <Row><Col><CardTitle>Uscite</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="usciteTabella" style={{width:'100%'}}>
                                {arrayUsciteAnnoCorsoImporti.length>0 || arrayUsciteAnnoCorsoIva.length>0 || arrayUsciteAnnoCorsoImportiNetti.length>0 ?
                                  visualizzaUsciteTab === 1 ?
                                  <Fragment>
                                  <Row form className="border">
                                    <Col md={larghColonnaVoceUscite}><b>Voce di Bilancio</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""} 
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""} 
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""} 
                                    </Row>
                                  {arrayUsciteAnnoCorsoVoci.map((elemento,index) => <Row form className="border" key={index}>
                                    {arrayUsciteAnnoCorsoVoci[index]!=='' ? 
                                      <Col md={larghColonnaVoceUscite}>{arrayUsciteAnnoCorsoVoci[index]}</Col>
                                    : <Col md={larghColonnaVoceUscite}>Voce di Bilancio non specificata</Col>}  
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayUsciteAnnoCorsoImportiNetti[index])}</Col> :""} 
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayUsciteAnnoCorsoIva[index])}</Col> :""} 
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayUsciteAnnoCorsoImporti[index])}</Col> :""} 
                                    </Row>)
                                  }
                                  </Fragment>
                                  :""
                                  : ""}
                                  {visualizzaUsciteTab === 1 ?
                                    <Row form className="border">
                                      <Col md={larghColonnaVoceUscite}><b>TOTALE</b></Col>
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleUsciteImportiNetti)}</b></Col> :""} 
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleUsciteIva)}</b></Col> :""} 
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleUscite)}</b></Col> :""} 
                                      </Row>
                                  :""}
                                  </div>


                                  {flagVisualizzaImportiNettiDefault === 1 && graficoUsciteAnnoInCorsoNetti === 1 && tipoGraficoUscite === "Bar" && arrayTopNettoUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoNetti"  style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataUsciteNetti} ref={usciteGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoUsciteAnnoInCorsoNetti === 1 && tipoGraficoUscite === "Doughnut" && arrayTopNettoUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataUsciteNetti} ref={usciteGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoUsciteAnnoInCorsoNetti === 1 && tipoGraficoUscite === "Line" && arrayTopNettoUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoNetti"  style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataUsciteNetti} ref={usciteGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoUsciteAnnoInCorsoNetti === 1 && tipoGraficoUscite === "Pie" && arrayTopNettoUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataUsciteNetti} ref={usciteGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoUsciteAnnoInCorsoNetti === 1 && tipoGraficoUscite === "PolarArea" && arrayTopNettoUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataUsciteNetti} ref={usciteGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoUsciteAnnoInCorsoNetti === 1 && tipoGraficoUscite === "Radar" && arrayTopNettoUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoNetti"  style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataUsciteNetti} ref={usciteGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                          
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoUsciteAnnoInCorsoIva === 1 && tipoGraficoUscite === "Bar" && arrayTopIvaUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataUsciteIva} ref={usciteGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoUsciteAnnoInCorsoIva === 1 && tipoGraficoUscite === "Doughnut" && arrayTopIvaUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataUsciteIva} ref={usciteGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoUsciteAnnoInCorsoIva === 1 && tipoGraficoUscite === "Line" && arrayTopIvaUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataUsciteIva} ref={usciteGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoUsciteAnnoInCorsoIva === 1 && tipoGraficoUscite === "Pie" && arrayTopIvaUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataUsciteIva} ref={usciteGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoUsciteAnnoInCorsoIva === 1 && tipoGraficoUscite === "PolarArea" && arrayTopIvaUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataUsciteIva} ref={usciteGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoUsciteAnnoInCorsoIva === 1 && tipoGraficoUscite === "Radar" && arrayTopIvaUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataUsciteIva} ref={usciteGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                     
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoUsciteAnnoInCorso === 1 && tipoGraficoUscite === "Bar" && arrayTopImportiUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataUscite} ref={usciteGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoUsciteAnnoInCorso === 1 && tipoGraficoUscite === "Doughnut" && arrayTopImportiUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataUscite} ref={usciteGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoUsciteAnnoInCorso === 1 && tipoGraficoUscite === "Line" && arrayTopImportiUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataUscite} ref={usciteGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoUsciteAnnoInCorso === 1 && tipoGraficoUscite === "Pie" && arrayTopImportiUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataUscite} ref={usciteGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoUsciteAnnoInCorso === 1 && tipoGraficoUscite === "PolarArea" && arrayTopImportiUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataUscite} ref={usciteGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Uscite ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoUsciteAnnoInCorso === 1 && tipoGraficoUscite === "Radar" && arrayTopImportiUscite.length>0 && visualizzaUscite === 1  ?
                                  <div id="usciteGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataUscite} ref={usciteGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Uscite ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                 

                                {((arrayUsciteAnnoCorsoImportiNetti.length>0  || arrayEntrateAnnoCorsoImportiNetti.length>0 ) && 
                                visualizzaUsciteTab === 1 && visualizzaEntrateTab === 1 && flagVisualizzaImportiNettiDefault === 1)
                                ||
                                ((arrayUsciteAnnoCorsoIva.length>0  || arrayEntrateAnnoCorsoIva.length>0 ) &&
                                  visualizzaUsciteTab === 1 && visualizzaEntrateTab === 1  && flagVisualizzaImportiIvaDefault === 1)
                                ||
                                ((arrayUsciteAnnoCorsoImporti.length>0  || 
                                  arrayEntrateAnnoCorsoImporti.length>0 ) &&
                                  visualizzaUsciteTab === 1 && visualizzaEntrateTab === 1  && flagVisualizzaImportiLordiDefault === 1)?
                                <br></br>
                                :""}
                                
                                <div id="entrateUsciteTabella" style={{width:'100%'}}> 
                                {(arrayUsciteAnnoCorsoImportiNetti.length>0  || 
                                arrayEntrateAnnoCorsoImportiNetti.length>0 ) && 
                                visualizzaUsciteTab === 1 && visualizzaEntrateTab === 1 && flagVisualizzaImportiNettiDefault === 1?
                                <Row form className="border"><Col><b>TOTALE ENTRATE ED USCITE {etichettaImportoNettoPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleEntrateUsciteImportiNetti)}</b></Col></Row>
                                :""}
                                {(arrayUsciteAnnoCorsoIva.length>0  || 
                                arrayEntrateAnnoCorsoIva.length>0 ) &&
                                visualizzaUsciteTab === 1 && visualizzaEntrateTab === 1  && flagVisualizzaImportiIvaDefault === 1?
                                <Row form className="border"><Col><b>TOTALE ENTRATE ED USCITE {etichettaImportoIVAPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleEntrateUsciteIva)}</b></Col></Row>
                                :""} 
                                {(arrayUsciteAnnoCorsoImporti.length>0  || 
                                arrayEntrateAnnoCorsoImporti.length>0 ) &&
                                visualizzaUsciteTab === 1 && visualizzaEntrateTab === 1  && flagVisualizzaImportiLordiDefault === 1?
                                <Row form className="border"><Col><b>TOTALE ENTRATE ED USCITE {etichettaImportoLordoPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleEntrateUscite)}</b></Col></Row>
                                :""}
                                </div>

                                {visualizzaUscite === 1 || visualizzaUsciteTab === 1 ? <br></br> :""}
                                <div id="liquiditaFineAnnoIntestazione" style={{width:'100%'}}> 
                                {visualizzaLiquiditaAFineAnnoTab === 1 || visualizzaLiquiditaAFineAnno === 1 ?
                                <Row><Col><CardTitle>Liquidità a fine anno</CardTitle></Col></Row>
                                :""} 
                                </div>

                                <div id="liquiditaFineAnnoTabella" style={{width:'100%'}}> 
                                {arrayLiquiditaFineAnnoImporti.length>0 || arrayLiquiditaFineAnnoIva.length>0 || arrayLiquiditaFineAnnoImportiNetti.length>0 ?
                                  visualizzaLiquiditaAFineAnnoTab === 1 ?
                                    <Fragment>
                                    <Row form className="border">
                                    <Col md={larghColonnaRisorseLiquidita}><b>Risorsa</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""}
                                    </Row>
                                    {arrayLiquiditaFineAnnoRisorse.map((elemento,index) => <Row form className="border" key={index}>
                                      {arrayLiquiditaFineAnnoRisorse[index]!=='' ? 
                                      <Col md={larghColonnaRisorseLiquidita}>{arrayLiquiditaFineAnnoRisorse[index]}</Col>
                                      : <Col md={larghColonnaRisorseLiquidita}>Risorsa non specificata</Col>}
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayLiquiditaFineAnnoImportiNetti[index])}</Col> :""}
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayLiquiditaFineAnnoIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayLiquiditaFineAnnoImporti[index])}</Col> :""}
                                      </Row>)
                                    }
                                    </Fragment>
                                    :""
                                    : ""}

                                    {visualizzaLiquiditaAFineAnnoTab === 1 ?
                                    <Row form className="border">
                                    <Col md={larghColonnaRisorseLiquidita}><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoImportiNetti)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoIva)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaFineAnno)}</b></Col> :""}
                                    </Row>
                                    
                                    :""}
                                   </div>

                                   {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaFineAnnoNetti === 1 && tipoGraficoLiquiditaAFineAnno ==="Bar" && arrayTopNettoLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                   <div id="liquiditaFineAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}> 
                                    <Bar data={dataLiquiditaAFineAnnoNetti} ref={liquiditaFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaFineAnnoNetti === 1 && tipoGraficoLiquiditaAFineAnno ==="Doughnut" && arrayTopNettoLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}> 
                                    <Doughnut data={dataLiquiditaAFineAnnoNetti} ref={liquiditaFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaFineAnnoNetti === 1 && tipoGraficoLiquiditaAFineAnno ==="Line" && arrayTopNettoLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}> 
                                    <Line data={dataLiquiditaAFineAnnoNetti} ref={liquiditaFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaFineAnnoNetti === 1 && tipoGraficoLiquiditaAFineAnno ==="Pie" && arrayTopNettoLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}> 
                                    <Pie data={dataLiquiditaAFineAnnoNetti} ref={liquiditaFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaFineAnnoNetti === 1 && tipoGraficoLiquiditaAFineAnno ==="PolarArea" && arrayTopNettoLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}> 
                                    <PolarArea data={dataLiquiditaAFineAnnoNetti} ref={liquiditaFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoLiquiditaFineAnnoNetti === 1 && tipoGraficoLiquiditaAFineAnno ==="Radar" && arrayTopNettoLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}> 
                                    <Radar data={dataLiquiditaAFineAnnoNetti} ref={liquiditaFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                          
                                   {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaFineAnnoIva === 1 && tipoGraficoLiquiditaAFineAnno ==="Bar" && arrayTopIvaLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                   <div id="liquiditaFineAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataLiquiditaAFineAnnoIva} ref={liquiditaFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaFineAnnoIva === 1 && tipoGraficoLiquiditaAFineAnno ==="Doughnut" && arrayTopIvaLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataLiquiditaAFineAnnoIva} ref={liquiditaFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaFineAnnoIva === 1 && tipoGraficoLiquiditaAFineAnno ==="Line" && arrayTopIvaLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataLiquiditaAFineAnnoIva} ref={liquiditaFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaFineAnnoIva === 1 && tipoGraficoLiquiditaAFineAnno ==="Pie" && arrayTopIvaLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataLiquiditaAFineAnnoIva} ref={liquiditaFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaFineAnnoIva === 1 && tipoGraficoLiquiditaAFineAnno ==="PolarArea" && arrayTopIvaLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataLiquiditaAFineAnnoIva} ref={liquiditaFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoLiquiditaFineAnnoIva === 1 && tipoGraficoLiquiditaAFineAnno ==="Radar" && arrayTopIvaLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataLiquiditaAFineAnnoIva} ref={liquiditaFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  
                                   {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaFineAnno === 1 && tipoGraficoLiquiditaAFineAnno ==="Bar" && arrayTopImportiLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                   <div id="liquiditaFineAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataLiquiditaAFineAnno} ref={liquiditaFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaFineAnno === 1 && tipoGraficoLiquiditaAFineAnno ==="Doughnut" && arrayTopImportiLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataLiquiditaAFineAnno} ref={liquiditaFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaFineAnno === 1 && tipoGraficoLiquiditaAFineAnno ==="Line" && arrayTopImportiLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataLiquiditaAFineAnno} ref={liquiditaFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaFineAnno === 1 && tipoGraficoLiquiditaAFineAnno ==="Pie" && arrayTopImportiLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataLiquiditaAFineAnno} ref={liquiditaFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaFineAnno === 1 && tipoGraficoLiquiditaAFineAnno ==="PolarArea" && arrayTopImportiLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataLiquiditaAFineAnno} ref={liquiditaFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoLiquiditaFineAnno === 1 && tipoGraficoLiquiditaAFineAnno ==="Radar" && arrayTopImportiLiquiditaFineAnno.length>0 && visualizzaLiquiditaAFineAnno === 1  ?
                                  <div id="liquiditaFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataLiquiditaAFineAnno} ref={liquiditaFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Liquidità a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  
                                {visualizzaLiquiditaAFineAnno === 1 || visualizzaLiquiditaAFineAnnoTab === 1 ? <br></br> :""}

                                <div id="creditiFineAnnoIntestazione" style={{width:'100%'}}>
                                {visualizzaCreditiAFineAnnoTab === 1  || visualizzaCreditiAFineAnno=== 1 ?
                                  <Row><Col><CardTitle>Crediti a fine anno</CardTitle></Col></Row>
                                :""}
                                </div>

                                <div id="creditiFineAnnoTabella" style={{width:'100%'}}>
                                {arrayCreditiFineAnnoImporti.length>0 || arrayCreditiFineAnnoIva.length>0 || arrayCreditiFineAnnoImportiNetti.length>0 ?
                                  visualizzaCreditiAFineAnnoTab === 1 ?
                                  <Fragment>
                                  <Row form className="border">
                                  <Col md={larghColonnaMovimentiCrediti}><b>Causale</b></Col>
                                  {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""} 
                                  {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""} 
                                  {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""} 
                                  </Row>

                                  {arrayCreditiFineAnnoTipiFatture.map((elemento,index) => <Row form className="border" key={index}>
                                    <Col md={larghColonnaMovimentiCrediti}>{arrayCreditiFineAnnoTipiFatture[index]}</Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayCreditiFineAnnoImportiNetti[index])}</Col> :""} 
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayCreditiFineAnnoIva[index])}</Col> :""} 
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayCreditiFineAnnoImporti[index])}</Col> :""} 
                                    </Row>)
                                  }
                                  </Fragment>
                                  :""
                                  : ""}
                                  {visualizzaCreditiAFineAnnoTab === 1 ?
                                  <Row form className="border">
                                  <Col md={larghColonnaMovimentiCrediti}><b>TOTALE</b></Col>
                                  {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiFineAnnoImportiNetti)}</b></Col> :""} 
                                  {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiFineAnnoIva)}</b></Col> :""} 
                                  {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiFineAnno)}</b></Col> :""} 
                                  </Row>
   
                                  :""}
                                  </div>
 

                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiFineAnnoNetti === 1 && tipoGraficoCreditiAFineAnno === "Bar" && arrayTopNettoCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataCreditiAFineAnnoNetti} ref={creditiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiFineAnnoNetti === 1 && tipoGraficoCreditiAFineAnno === "Doughnut" && arrayTopNettoCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataCreditiAFineAnnoNetti} ref={creditiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiFineAnnoNetti === 1 && tipoGraficoCreditiAFineAnno === "Line" && arrayTopNettoCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataCreditiAFineAnnoNetti} ref={creditiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiFineAnnoNetti === 1 && tipoGraficoCreditiAFineAnno === "Pie" && arrayTopNettoCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataCreditiAFineAnnoNetti} ref={creditiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiFineAnnoNetti === 1 && tipoGraficoCreditiAFineAnno === "PolarArea" && arrayTopNettoCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataCreditiAFineAnnoNetti} ref={creditiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoCreditiFineAnnoNetti === 1 && tipoGraficoCreditiAFineAnno === "Radar" && arrayTopNettoCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataCreditiAFineAnnoNetti} ref={creditiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}

                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiFineAnnoIva === 1 && tipoGraficoCreditiAFineAnno === "Bar" && arrayTopIvaCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataCreditiAFineAnnoIva} ref={creditiFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiFineAnnoIva === 1 && tipoGraficoCreditiAFineAnno === "Doughnut" && arrayTopIvaCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataCreditiAFineAnnoIva} ref={creditiFineAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiFineAnnoIva === 1 && tipoGraficoCreditiAFineAnno === "Line" && arrayTopIvaCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataCreditiAFineAnnoIva} ref={creditiFineAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiFineAnnoIva === 1 && tipoGraficoCreditiAFineAnno === "Pie" && arrayTopIvaCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataCreditiAFineAnnoIva} ref={creditiFineAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiFineAnnoIva === 1 && tipoGraficoCreditiAFineAnno === "PolarArea" && arrayTopIvaCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataCreditiAFineAnnoIva} ref={creditiFineAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoCreditiFineAnnoIva === 1 && tipoGraficoCreditiAFineAnno === "Radar" && arrayTopIvaCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataCreditiAFineAnnoIva} ref={creditiFineAnnoGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiFineAnno === 1 && tipoGraficoCreditiAFineAnno === "Bar" && arrayTopImportiCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataCreditiAFineAnno} ref={creditiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiFineAnno === 1 && tipoGraficoCreditiAFineAnno === "Doughnut" && arrayTopImportiCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataCreditiAFineAnnoIva} ref={creditiFineAnnoGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiFineAnno === 1 && tipoGraficoCreditiAFineAnno === "Line" && arrayTopImportiCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataCreditiAFineAnno} ref={creditiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiFineAnno === 1 && tipoGraficoCreditiAFineAnno === "Pie" && arrayTopImportiCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataCreditiAFineAnno} ref={creditiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiFineAnno === 1 && tipoGraficoCreditiAFineAnno === "PolarArea" && arrayTopImportiCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataCreditiAFineAnno} ref={creditiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoCreditiFineAnno === 1 && tipoGraficoCreditiAFineAnno === "Radar" && arrayTopImportiCreditiFineAnno.length>0 && visualizzaCreditiAFineAnno === 1  ?
                                  <div id="creditiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataCreditiAFineAnno} ref={creditiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Crediti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                              

                                {visualizzaCreditiAFineAnno === 1 || visualizzaCreditiAFineAnnoTab === 1 ? <br></br> :""}

                                <div id="debitiFineAnnoIntestazione" style={{width:'100%'}}>
                                {visualizzaDebitiAFineAnnoTab === 1   || visualizzaDebitiAFineAnno === 1 ?
                                <Row><Col><CardTitle>Debiti a fine anno</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="debitiFineAnnoTabella" style={{width:'100%'}}>
                                {arrayDebitiFineAnnoImporti.length>0 || arrayDebitiFineAnnoIva.length>0 || arrayDebitiFineAnnoImportiNetti.length>0 ?
                                  visualizzaDebitiAFineAnnoTab === 1 ?
                                  <Fragment>
                                  <Row form className="border">
                                    <Col md={larghColonnaMovimentiDebiti}><b>Causale</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""}
                                    </Row>
                             
                                    {arrayDebitiFineAnnoTipiFatture.map((elemento,index) => <Row form className="border" key={index}>
                                      <Col md={larghColonnaMovimentiDebiti}>{arrayDebitiFineAnnoTipiFatture[index]}</Col>
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayDebitiFineAnnoImportiNetti[index])}</Col> :""}
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayDebitiFineAnnoIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayDebitiFineAnnoImporti[index])}</Col> :""}
                                      </Row>)
                                    }
                                    </Fragment>
                                    :""
                                    : ""}
                                    {visualizzaDebitiAFineAnnoTab === 1 ?
                                    <Row form className="border">
                                    <Col md={larghColonnaMovimentiDebiti}><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleDebitiFineAnnoImportiNetti)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleDebitiFineAnnoIva)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleDebitiFineAnno)}</b></Col> :""}
                                    </Row>
                                   
                                    :""}
                                  </div>

                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiFineAnnoNetti === 1 && tipoGraficoDebitiAFineAnno ==="Bar" && arrayTopNettoDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataDebitiAFineAnnoNetti} ref={debitiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiFineAnnoNetti === 1 && tipoGraficoDebitiAFineAnno ==="Doughnut" && arrayTopNettoDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataDebitiAFineAnnoNetti} ref={debitiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiFineAnnoNetti === 1 && tipoGraficoDebitiAFineAnno ==="Line" && arrayTopNettoDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataDebitiAFineAnnoNetti} ref={debitiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiFineAnnoNetti === 1 && tipoGraficoDebitiAFineAnno ==="Pie" && arrayTopNettoDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataDebitiAFineAnnoNetti} ref={debitiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiFineAnnoNetti === 1 && tipoGraficoDebitiAFineAnno ==="PolarArea" && arrayTopNettoDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataDebitiAFineAnnoNetti} ref={debitiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoDebitiFineAnnoNetti === 1 && tipoGraficoDebitiAFineAnno ==="Radar" && arrayTopNettoDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataDebitiAFineAnnoNetti} ref={debitiFineAnnoGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoNettoTop+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiFineAnnoIva === 1 && tipoGraficoDebitiAFineAnno ==="Bar" && arrayTopIvaDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataDebitiAFineAnnoIva} ref={debitiFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiFineAnnoIva === 1 && tipoGraficoDebitiAFineAnno ==="Doughnut" && arrayTopIvaDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataDebitiAFineAnnoIva} ref={debitiFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiFineAnnoIva === 1 && tipoGraficoDebitiAFineAnno ==="Line" && arrayTopIvaDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataDebitiAFineAnnoIva} ref={debitiFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiFineAnnoIva === 1 && tipoGraficoDebitiAFineAnno ==="Pie" && arrayTopIvaDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataDebitiAFineAnnoIva} ref={debitiFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiFineAnnoIva === 1 && tipoGraficoDebitiAFineAnno ==="PolarArea" && arrayTopIvaDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataDebitiAFineAnnoIva} ref={debitiFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoDebitiFineAnnoIva === 1 && tipoGraficoDebitiAFineAnno ==="Radar" && arrayTopIvaDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataDebitiAFineAnnoIva} ref={debitiFineAnnoGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoIVATop+topDefault+')'}},}} /></div>: ""}


                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiFineAnno === 1 && tipoGraficoDebitiAFineAnno ==="Bar" && arrayTopImportiDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataDebitiAFineAnno} ref={debitiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiFineAnno === 1 && tipoGraficoDebitiAFineAnno ==="Doughnut" && arrayTopImportiDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataDebitiAFineAnno} ref={debitiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiFineAnno === 1 && tipoGraficoDebitiAFineAnno ==="Line" && arrayTopImportiDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataDebitiAFineAnno} ref={debitiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiFineAnno === 1 && tipoGraficoDebitiAFineAnno ==="Pie" && arrayTopImportiDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataDebitiAFineAnno} ref={debitiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiFineAnno === 1 && tipoGraficoDebitiAFineAnno ==="PolarArea" && arrayTopImportiDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataDebitiAFineAnno} ref={debitiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoDebitiFineAnno === 1 && tipoGraficoDebitiAFineAnno ==="Radar" && arrayTopImportiDebitiFineAnno.length>0 && visualizzaDebitiAFineAnno === 1  ?
                                  <div id="debitiFineAnnoGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataDebitiAFineAnno} ref={debitiFineAnnoGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Debiti a fine anno ('+etichettaImportoLordoTop+topDefault+')'}},}} /></div>: ""}
                            
                                {((arrayDebitiFineAnnoImportiNetti.length>0 || arrayCreditiFineAnnoImportiNetti.length>0) &&
                                visualizzaCreditiAFineAnnoTab === 1 && visualizzaDebitiAFineAnnoTab === 1 && flagVisualizzaImportiNettiDefault === 1)
                                ||
                                ((arrayDebitiFineAnnoIva.length>0 || arrayCreditiFineAnnoIva.length>0) &&
                                  visualizzaCreditiAFineAnnoTab === 1 && visualizzaDebitiAFineAnnoTab === 1 && flagVisualizzaImportiIvaDefault === 1)
                                ||
                                ((arrayDebitiFineAnnoImporti.length>0 || arrayCreditiFineAnnoImporti.length>0) &&
                                  visualizzaCreditiAFineAnnoTab === 1 && visualizzaDebitiAFineAnnoTab === 1 && flagVisualizzaImportiLordiDefault === 1) ?
                                <br></br>
                                :""}

                                <div id="creditiDebitiFineAnnoTabella" style={{width:'100%'}}>
                                {(arrayDebitiFineAnnoImportiNetti.length>0 || 
                                arrayCreditiFineAnnoImportiNetti.length>0) &&
                                visualizzaCreditiAFineAnnoTab === 1 && visualizzaDebitiAFineAnnoTab === 1 && flagVisualizzaImportiNettiDefault === 1?
                                <Row form className="border"><Col><b>TOTALE CREDITI E DEBITI A FINE ANNO {etichettaImportoNettoPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiDebitiFineAnnoImportiNetti)}</b></Col></Row>
                                :""}
                                {(arrayDebitiFineAnnoIva.length>0 || 
                                arrayCreditiFineAnnoIva.length>0) &&
                                visualizzaCreditiAFineAnnoTab === 1 && visualizzaDebitiAFineAnnoTab === 1 && flagVisualizzaImportiIvaDefault === 1?
                                <Row form className="border"><Col><b>TOTALE CREDITI E DEBITI A FINE ANNO {etichettaImportoIVAPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiDebitiFineAnnoIva)}</b></Col></Row>
                                :""}
                                {(arrayDebitiFineAnnoImporti.length>0 || 
                                arrayCreditiFineAnnoImporti.length>0) &&
                                visualizzaCreditiAFineAnnoTab === 1 && visualizzaDebitiAFineAnnoTab === 1 && flagVisualizzaImportiLordiDefault === 1?
                                <Row form className="border"><Col><b>TOTALE CREDITI E DEBITI A FINE ANNO {etichettaImportoLordoPerTotali}</b></Col><Col align="right"><b>{FormattaImportoVideo(statoTotaleCreditiDebitiFineAnno)}</b></Col></Row>
                                :""}
                                </div>
                                

                                {visualizzaDebitiAFineAnno === 1 || visualizzaDebitiAFineAnnoTab === 1 ? <br></br> :""}

                                <Row form className="border"><Col>
                                <div id="movimentiIntestazione" style={{width:'100%'}}>
                                {visualizzaMovimentiTab === 1 ? <Row><Col><CardTitle>Movimenti</CardTitle></Col></Row>:""}
                                </div>

                                <div id="movimentiTabella" style={{width:'100%'}}>
                                {visualizzaMovimentiTab === 1 ?
                                arrayMovimentiImporto.length>0 || arrayMovimentiIva.length>0 || arrayMovimentiImportoNetto.length>0?
                                  <Fragment>
                                  <Row form className="border">
                                    <Col md={larghColonnaDataMov}><b>Data</b></Col>
                                    <Col md={larghColonnaDescrizioneMov}><b>Descrizione</b></Col>
                                    <Col md={larghColonnaRisorsaMov}><b>Risorsa</b></Col>
                                    <Col md={larghColonnaVoceMov}><b>Voce di Bilancio</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{etichettaImportoNetto}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{etichettaImportoIVA}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{etichettaImportoLordo}</b></Col> :""}
                                    </Row>
                                  {/*metto come primo record la liquidità di inizio anno*/}
                                  <Row form className="border">
                                    <Col md={larghColonnaDataMov}><b><TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano></b></Col>
                                    <Col md={larghColonnaDescrizioneMov}><b>-</b></Col>
                                    <Col md={larghColonnaRisorsaMov}><b>-</b></Col>
                                    <Col md={larghColonnaVoceMov}><b>-</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoImportiNetti)}</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnnoIva)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaInizioAnno)}</b></Col> :""}
                                    </Row>
                                  {arrayMovimentiImporto.map((elemento,index) => <Row form className="border" key={index}>
                                  <Col md={larghColonnaDataMov}><TrasformaDateAUmano data={arrayMovimentiData[index]}></TrasformaDateAUmano></Col>
                                  {arrayMovimentiDescrizione[index]!=='' ?
                                    <Col md={larghColonnaDescrizioneMov}>{arrayMovimentiDescrizione[index]}</Col>
                                  : <Col md={larghColonnaDescrizioneMov}>-</Col>}
                                  {arrayMovimentiRisorsa[index]!=='' ?
                                    <Col md={larghColonnaRisorsaMov}>{arrayMovimentiRisorsa[index]}</Col>
                                  : <Col md={larghColonnaRisorsaMov}>-</Col>}
                                  {arrayMovimentiVoce[index]!=='' ?
                                    <Col md={larghColonnaVoceMov}>{arrayMovimentiVoce[index]}</Col>
                                  : <Col md={larghColonnaVoceMov}>-</Col>}
                                  {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayMovimentiImportoNetto[index])}</Col> :""}
                                  {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayMovimentiIva[index])}</Col> :""}
                                  {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayMovimentiImporto[index])}</Col> :""}
                                  </Row>)
                                  }
                                  {/*metto come ultimo record la liquidità di fine anno*/}
                                  <Row form className="border">
                                  <Col md={larghColonnaDataMov}><b><TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano></b></Col>
                                  <Col md={larghColonnaDescrizioneMov}><b>-</b></Col>
                                  <Col md={larghColonnaRisorsaMov}><b>-</b></Col>
                                  <Col md={larghColonnaVoceMov}><b>-</b></Col>
                                  {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoImportiNetti)}</b></Col> :""}
                                  {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaFineAnnoIva)}</b></Col> :""}
                                  {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleLiquiditaFineAnno)}</b></Col> :""}
                                  </Row>
                                  </Fragment>
                                  : "Nessun dato presente"
                                :""}    
                                </div>
                                </Col>
                                </Row>
                                {visualizzaMovimentiTab === 1 ? <br></br> :""}

                                <Row form className="border"><Col>
                                <div id="sociIntestazione" style={{width:'100%'}}>
                                {visualizzaSociTab === 1 ?
                                  <Row><Col><CardTitle>Soci</CardTitle></Col></Row>
                                :""}
                                </div>

                                <div id="sociTabella" style={{width:'100%'}}>
                                {visualizzaSociTab === 1 ?
                                  arraySociCognome.length>0 ?
                                  <Fragment>


                                  <Row form className="border">
                                    <Col md={larghColonnaCognomeTS}><b>Cognome</b></Col>
                                    <Col md={larghColonnaNomeTS}><b>Nome</b></Col>
                                    <Col md={larghColonnaTesseraTS}><b>Numero Tessera</b></Col>
                                    <Col md={larghColonnaPosizioniTS}><b>Posizioni</b></Col>
                                    <Col md={larghColonnaDataIngressoTS}><b>Data Ingresso Società</b></Col>
                                    <Col md={larghColonnaDataUscitaTS}><b>Data Uscita Società</b></Col></Row>
                                  {arraySociCognome.map((elemento,index) => <Row form className="border" key={index}>
                                  {arraySociCognome[index]!=='' ?
                                    <Col md={larghColonnaCognomeTS}>{arraySociCognome[index]}</Col>
                                  : <Col md={larghColonnaCognomeTS}>-</Col>}
                                  {arraySociNome[index]!=='' ?
                                    <Col md={larghColonnaNomeTS}>{arraySociNome[index]}</Col>
                                  : <Col md={larghColonnaNomeTS}>-</Col>}
                                  {arraySociTessera[index]!=='' ?
                                    <Col md={larghColonnaTesseraTS}>{arraySociTessera[index]}</Col>
                                  : <Col md={larghColonnaTesseraTS}>-</Col>}
                                  {arraySociPosizioni[index]!=='' ?
                                    <Col md={larghColonnaPosizioniTS}>{arraySociPosizioni[index]}</Col>
                                  : <Col md={larghColonnaPosizioniTS}>-</Col>}
                                  {arraySociDataIn[index]!=='' ?
                                    <Col md={larghColonnaDataIngressoTS}><TrasformaDateAUmano data={arraySociDataIn[index]}></TrasformaDateAUmano></Col>
                                  : <Col md={larghColonnaDataIngressoTS}>-</Col>}
                                  {arraySociDataOut[index]!=='' ?
                                    <Col md={larghColonnaDataUscitaTS}><TrasformaDateAUmano data={arraySociDataOut[index]}></TrasformaDateAUmano></Col>
                                  : <Col md={larghColonnaDataUscitaTS}>-</Col>}
                                  </Row>)
                                  }
                                  {visualizzaSociTab === 1 ?
                                    <Row form className="border"><Col><b>Numero Totale Soci: {arraySociCognome.length}</b></Col><Col></Col><Col></Col><Col></Col><Col></Col></Row>
                                  :""}
                                  </Fragment>
                                  : "Nessun dato presente"
                                :""}
                                </div>
                                </Col>
                                </Row>
                              </div>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
      :""}
    </div>
   );
}
export default TabellaBilancio;