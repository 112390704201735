import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import TendinaEditabile from '../TendinaEditabile.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;
const STATO_SCHEDA_SINGOLA_COMUNICAZIONE = 7;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;
    const [dataInvioDefault,setDataInvioDefault]=useState(dataOggi);

    const dataInvio   = useRef();
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [statoTendinaIscritti,setStatoTendinaIscritti]=useState(false);
    const [statoTendinaProspect,setStatoTendinaProspect]=useState(false);
    const [statoTendinaMezziComunicazione,setStatoTendinaMezziComunicazione]=useState(false);

    const [modaleNoMezzo,setModaleNoMezzo]=useState(false);
    const [modaleNoData,setModaleNoData]=useState(false);

    const [recuperatoDatiComunicazione,setRecuperatoDatiComunicazione]=useState(false);
    
    const [risultatoServerDatiIscritti,setRisultatoServerDatiIscritti]=useState('');
    const [risultatoServerDatiProspect,setRisultatoServerDatiProspect]=useState('');
    const [risultatoServerDatiMezziComunicazione,setRisultatoServerDatiMezziComunicazione]=useState('');

    const [risultatoServer,setRisultatoServer]=useState('');
    

    const [iscrittiFiltrati, setIscrittiFiltrati] = useState([]);
    const [prospectFiltrati, setProspectFiltrati] = useState([]);

    const [testoRicercaIscritti, setTestoRicercaIscritti] = useState("");
    const [testoRicercaProspect, setTestoRicercaProspect] = useState("");

    const coloreEvidenziato="#84b352";

    const [numeroIscrittiSelezionati, setNumeroIscrittiSelezionati] = useState(0);
    const [numeroProspectSelezionati, setNumeroProspectSelezionati] = useState(0);

    const [iscrittiSelezionati, setIscrittiSelezionati] = useState([]);
    const [prospectSelezionati, setProspectSelezionati] = useState([]);

    const testo   = useRef();
    const [testoDefault,setTestoDefault]=useState('');

    const risposta   = useRef();
    const [rispostaDefault, setRispostaDefault] = useState('');

    const [insMezzoEdi, setInsMezzoEdi] = useState('');
    const [insIdMezzoEdi, setInsIdMezzoEdi] = useState(0);

    const [destinatariIscrittiDefault, setDestinatariIscrittiDefault] = useState([]);//contiene eventualmente tutti gli ID degli iscritti che sono stati destinatari della comunicazione
    const [destinatariProspectDefault, setDestinatariProspectDefault] = useState([]);//contiene eventualmente tutti gli ID dei prospect che sono stati destinatari della comunicazione

    const [arrayEsitiPossibili, setArrayEsitiPossibili] = useState(['Neutro','Positivo','Negativo']);

    const [esitiIscritti, setEsitiIscritti] = useState([]);//ha chiave ID_ISCRITTO
    const [esitiProspect, setEsitiProspect] = useState([]);//ha chiave ID_PROSPECT

    const [risposteIscritti, setRisposteIscritti] = useState([]);//ha chiave ID_ISCRITTO
    const [risposteProspect, setrisposteProspect] = useState([]);//ha chiave ID_PROSPECT

    const [cognomeDefault, setCognomeDefault] = useState('');
    const [nomeDefault, setNomeDefault] = useState('');
    const [tesseraDefault, setTesseraDefault] = useState('');
    const [esitoDefault, setEsitoDefault] = useState('');
    

    const [expandedIndexRispostaIscritti, setExpandedIndexRispostaIscritti] = useState(null);
    const [expandedIndexRispostaProspect, setExpandedIndexRispostaProspect] = useState(null);

    const handleMouseEnterRispostaIscritti = (index) => {setExpandedIndexRispostaIscritti(index);};
    const handleMouseLeaveRispostaIscritti = () => {setExpandedIndexRispostaIscritti(null);};
    const textareaStyleRispostaIscritti = (index) => ({
        // Aggiungi la logica di stile condizionale qui
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: 'inherit',
        width: '100%', // La textarea riempie tutta la larghezza del <td>
        height: index === expandedIndexRispostaIscritti ? '200px' : '50px', // Adatta l'altezza in base all'espansione
        transition: 'height 0.3s ease',
    });

    const handleMouseEnterRispostaProspect = (index) => {setExpandedIndexRispostaProspect(index);};
    const handleMouseLeaveRispostaProspect = () => {setExpandedIndexRispostaProspect(null);};
    const textareaStyleRispostaProspect = (index) => ({
        // Aggiungi la logica di stile condizionale qui
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: 'inherit',
        lineHeight: 'inherit',
        width: '100%', // La textarea riempie tutta la larghezza del <td>
        height: index === expandedIndexRispostaProspect ? '200px' : '50px', // Adatta l'altezza in base all'espansione
        transition: 'height 0.3s ease',
    });
    
    
    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaIscritti(false);
        setStatoTendinaProspect(false);
        setStatoTendinaMezziComunicazione(false);
        setModaleNoMezzo(false);
        setModaleNoData(false);
        
        ricercaIscritti();//ricerca iscritti ed ex-iscritti che abbiano data di uscita valorizzata
        ricercaProspect();
        ricercaMezziComunicazione();
    },[]);

    useEffect(() => {
        //console.log("statoTendinaIscritti"+statoTendinaIscritti)
        //console.log("statoTendinaProspect"+statoTendinaProspect)
        //console.log("statoTendinaMezziComunicazione"+statoTendinaMezziComunicazione)
        let visual = 0;


        if(statoTendinaIscritti === true && statoTendinaProspect === true && statoTendinaMezziComunicazione === true){
            if(props.ope==='inserisci'){
                visual = 1;
            }else{
                if(recuperatoDatiComunicazione === true){
                    visual = 1;
                }
            }
        }  
        if(visual ==1){  
            //ora che ho tutti i dati posso vedere quali eventualmente sono i selezionati sia tra gli iscritti che tra i prospect:
            let f=[];
            let s=[];
            for (let i=0; i<destinatariIscrittiDefault.length; i++) {
                f[i]=true;
                s[i]=false;
            }
            setIscrittiFiltrati(f);
            setIscrittiSelezionati(s);

            let fp=[];
            let sp=[];
            for (let ip=0; ip<destinatariProspectDefault.length; ip++) {
                fp[ip]=true;
                sp[ip]=false;
            }
            setProspectFiltrati(fp);
            setProspectSelezionati(sp);

            if(props.ope==='inserisci'){
                setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
            }else{
                setStatoPagina(STATO_SCHEDA_SINGOLA_COMUNICAZIONE);
            }
        }
    },[statoTendinaIscritti,statoTendinaProspect,statoTendinaMezziComunicazione]);


    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("comunicazioniCrmIns");
        if(props.ope==='modifica') setOperazione("comunicazioniCrmMod");
        if(props.ope==='cancella') setOperazione("comunicazioniCrmCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"comunicazioniCrmRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                   
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    //console.log("RISULTATO".valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    
                    setTestoDefault(valoreDiRitorno.risultatoRitorno[0].CONTENUTO);
                    setRispostaDefault(valoreDiRitorno.risultatoRitorno[0].RISPOSTA);
                    setInsIdMezzoEdi(valoreDiRitorno.risultatoRitorno[0].IDMEZZO);
                    setInsMezzoEdi(valoreDiRitorno.risultatoRitorno[0].MEZZO);
                    setDataInvioDefault(valoreDiRitorno.risultatoRitorno[0].DATA);
                    setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setTesseraDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                    if(valoreDiRitorno.risultatoRitorno[0].ESITO != ''){
                        setEsitoDefault(valoreDiRitorno.risultatoRitorno[0].ESITO);
                    }
                    
                    setRecuperatoDatiComunicazione(true);
                    
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    //setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }
    },[props.ope]);

    function ricercaIscritti() {
      
        //ricerco tra gli iscritti che mi servono per popolare lo spazio dei destinatari iscritti o ex-iscritti:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",ridotto:1,elencaDisdetti:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiIscritti(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaIscritti(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaProspect() {

        //ricerco tra i prospect:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoProspectCrm",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiProspect(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaProspect(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaMezziComunicazione() {
        //ricerco tra i mezzi di comunicazione con i quali si possono mandare le comunicazioni:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoMezziComunicazCrm",emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiMezziComunicazione(valoreDiRitorno.risultatoRitorno);
                    setStatoTendinaMezziComunicazione(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
      }
      function callbackSetValoreTendEditabileMezzoComunicazione(valore,posizioneTendina,valoreNonVisibile){
      if(valoreNonVisibile !== undefined &&  valoreNonVisibile !== '' && valoreNonVisibile !== -1){
          setInsMezzoEdi(valore);
          setInsIdMezzoEdi(valoreNonVisibile);
      }
    }

    function callbackSetValoreTendEditabileIscritti(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        //let dati = [...esitiIscritti];//popolo l'array locale con i dati dell'array di stato
        //dati.splice(index,1,valore);//);
        //setEsitiIscritti(dati);//aggiorno nuovamente la var di stato
        esitiIscritti[index]=valore;
    }

    function callbackSetValoreRisposteIscritti(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        risposteIscritti[index]=valore;
    }
    function callbackSetValoreRisposteProspect(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        risposteProspect[index]=valore;
    }

    function callbackSetValoreTendEditabileProspect(valore,index,valoreNonVisibile){
        if (index === undefined) return;
        //let dati = [...esitiProspect];//popolo l'array locale con i dati dell'array di stato
        //dati.splice(index,1,valore);//);
        //setEsitiProspect(dati);//aggiorno nuovamente la var di stato
        if(valore == '') valore = 'Neutro';
        esitiProspect[index]=valore;
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function fetchOperazioneServerModCancSingola(bypassaControlli=0) {
        let controlli=0;


        if(controlli===0){ 
            //invio l'azione da fare sul server
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        //cognome:cognome.current.value,
                        data:dataInvio.current.value,
                        esito:esitoDefault,
                        idmezzo:insIdMezzoEdi,
                        testo:testoDefault,
                        risposta:rispostaDefault,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                    JSON.stringify({op:operazione,
                    idElemento:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }

    }

    function fetchOperazioneServer(bypassaControlli=0) {
        
        let controlli=0;

        //controllo che ci sia il mezzo di comunicazione:
        if(insIdMezzoEdi == ''){
            setModaleNoMezzo(true);
            controlli=1;
        }
        if(dataInvio.current.value == ''){
            setModaleNoData(true);
            controlli=1;
        }

        var elencoIscrittiID = [];
        var esitiIscrittiESITO = [];
        var risposteIscrittiRISPOSTA = [];
        //var elencoIscrittiCOGNOME = [];
        //var elencoIscrittiNOME = [];
        //var elencoIscrittiTESSERA = [];

        

        for (let ni=0; ni<iscrittiSelezionati.length; ni++) {
            if (iscrittiSelezionati[ni]){
                elencoIscrittiID.push(risultatoServerDatiIscritti[ni].ID);
                if(esitiIscritti[risultatoServerDatiIscritti[ni].ID] === undefined || esitiIscritti[risultatoServerDatiIscritti[ni].ID] ===''){
                    esitiIscritti[risultatoServerDatiIscritti[ni].ID]= 'Neutro';
                } 
                esitiIscrittiESITO.push(esitiIscritti[risultatoServerDatiIscritti[ni].ID]);
                risposteIscrittiRISPOSTA.push(risposteIscritti[risultatoServerDatiIscritti[ni].ID]);
                //console.log("ISCRITTO"+risultatoServerDatiIscritti[ni].ID);
                //console.log("ESITO"+esitiIscritti[risultatoServerDatiIscritti[ni].ID]);
                //console.log("RISPOSTA"+risposteIscritti[risultatoServerDatiIscritti[ni].ID]);
            }
             
            //if (iscrittiSelezionati[ni]) elencoIscrittiCOGNOME.push(risultatoServerDatiIscritti[ni].COGNOME);
            //if (iscrittiSelezionati[ni]) elencoIscrittiNOME.push(risultatoServerDatiIscritti[ni].NOME);
            //if (iscrittiSelezionati[ni]) elencoIscrittiTESSERA.push(risultatoServerDatiIscritti[ni].NUMERO_TESSERA);
        }

        var elencoProspectID = [];
        var esitiProspectESITO  = [];
        var risposteProspectRISPOSTA = [];
        //var elencoProspectCOGNOME = [];
        //var elencoProspectNOME = [];

    
        for (let np=0; np<prospectSelezionati.length; np++) {
            if (prospectSelezionati[np]){
                elencoProspectID.push(risultatoServerDatiProspect[np].ID);
                if(esitiProspect[risultatoServerDatiProspect[np].ID] === undefined || esitiProspect[risultatoServerDatiProspect[np].ID] ===''){
                    esitiProspect[risultatoServerDatiProspect[np].ID] = 'Neutro';
                } 
                esitiProspectESITO.push(esitiProspect[risultatoServerDatiProspect[np].ID]);
                risposteProspectRISPOSTA.push(risposteProspect[risultatoServerDatiProspect[np].ID]);
            } 
            //if (prospectSelezionati[np]) elencoProspectCOGNOME.push(risultatoServerDatiProspect[np].COGNOME);
            //if (prospectSelezionati[np]) elencoProspectNOME.push(risultatoServerDatiProspect[np].NOME);
        }


        if(controlli===0){ 

            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        //cognome:cognome.current.value,
                        idiscritti:elencoIscrittiID,
                        idprospect:elencoProspectID,
                        esitiIscritti:esitiIscrittiESITO,
                        esitiProspect:esitiProspectESITO,
                        risposteIscritti:risposteIscrittiRISPOSTA,
                        risposteProspect:risposteProspectRISPOSTA,
                        data:dataInvio.current.value,
                        idmezzo:insIdMezzoEdi,
                        testo:testoDefault,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                    JSON.stringify({op:operazione,
                    idElemento:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }


    //console.log(statoPagina);
    //console.log(JSON.stringify(risultatoServer))
        return (
            <Fragment>
               
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1scComCrm {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_SCHEDA_SINGOLA_COMUNICAZIONE ?
                    <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
        
                        {props.ope === "modifica" ? <CardTitle>Modifica Comunicazione</CardTitle> :""}
       
        
                        <Clessidra loading={loading}/>
                        {props.ope === "cancella" ?
                            <FinestraDialogo 
                                titolo="Conferma eliminazione"
                                flagErrore={true}                                
                                contenuto={<div>
                                    Confermi l'eliminazione della comunicazione selezionata ?<br/>                                    
                                </div>}
                                bottoni={[
                                    {
                                        "etichetta":"Conferma",
                                        "tipo":"primary",
                                        callback:() => {setDisabilitaBottoni(true);fetchOperazioneServerModCancSingola()}
                                    },
                                    {
                                        "etichetta":"Annulla",
                                        "tipo":"primary",
                                        callback: () => {tornaElenco()}
                                    }
                                ]}
                            />                        
                            
                        :
                        <>
                        <Row form>  
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="descrizione">Destinatario:&nbsp;</Label> 
                                    <b> {cognomeDefault} {nomeDefault} {tesseraDefault}</b>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form> 
                            <Col md={4}>
                                <FormGroup>
                                    Data*
                                        {props.ope === "modifica" ?
                                            <Input maxLength={10} type="date" name="dataInvio" id="dataInvio"
                                                innerRef={dataInvio} 
                                                placeholder=""
                                                defaultValue={dataInvioDefault}
                                                value={dataInvioDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataInvioDefault(dataInvio.current.value);}}/> 
                                            :""}     
                                </FormGroup>
                            </Col>

           
                            <Col md={4}> 
                                <FormGroup>
                                {props.ope === "modifica" ? (
                            <div>
                                Esito
                                <select
                                    className="form-control"
                                    onInput={(e) => {
                                        callbackSetVariazioneCampi(1);
                                        setEsitoDefault(e.target.value);
                                    }}
                                >
                                    <option value=''></option>
                                    {arrayEsitiPossibili.map((elementoPossibile, indexPossibile) => (
                                        <option
                                            key={indexPossibile}
                                            value={arrayEsitiPossibili[indexPossibile]}
                                            selected={esitoDefault !== undefined && arrayEsitiPossibili[indexPossibile] === esitoDefault}
                                        >
                                            {arrayEsitiPossibili[indexPossibile]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            esitoDefault
                        )}
                                </FormGroup>
                            </Col>
                            
                            <Col md={4}>    
                                <FormGroup>
                                    Mezzo di Comunicazione*
                                    <TendinaEditabile
                                        //titolo={"Iscritto"}
                                        nomeCampo="DESCRIZIONE"
                                        nomeCampoNonVisibile="ID"
                                        bloccoInserimento = "1"
                                        valoreDefault={insMezzoEdi}
                                        arrayValori={risultatoServerDatiMezziComunicazione}
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileMezzoComunicazione}
                                    ></TendinaEditabile>
                                </FormGroup>
                            </Col>   
                        </Row>
                        {/*testo*/}
                        <Row form> 
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="testo">Testo</Label>
                                        <Input maxLength={200} type="textarea" name="testo" id="testo"  defaultValue={testoDefault} innerRef={testo}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setTestoDefault(testo.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form> 
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="risposta">Risposta</Label>
                                        <Input maxLength={200} type="textarea" name="risposta" id="risposta"  defaultValue={rispostaDefault} innerRef={risposta}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setRispostaDefault(risposta.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row>
                        </>
                        }
            
                    </div>

                            <div className="schedaSpazioInferiore">
                            {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServerModCancSingola()}}>Memorizza Modifiche</Button>:""}
                    
                            <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                            {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                            </Button>
                            </div>
                        
                </div>
                :""}




                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    
                    <div className="schedaSpazioCentrale">
                            {modaleNoMezzo===true ?
                                <Modale 
                                    titolo="Richiesto Mezzo di Comunicazione"
                                    flagErrore={true}
                                    contenuto={<div>Non è stato indicato il mezzo di comunicazione, che è necessario</div>}
                                    bottoni={[
                                        {
                                            "etichetta":"OK",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(false);setModaleNoMezzo(false)}
                                        }    
                                    ]}
                                />
                            :""}

                            {modaleNoData===true ?
                                    <Modale 
                                        titolo="Richiesta Data"
                                        flagErrore={true}
                                        contenuto={<div>Non è stata indicata la data, che è necessaria</div>}
                                        bottoni={[
                                            {
                                                "etichetta":"OK",
                                                "tipo":"primary",
                                                callback:() => {setDisabilitaBottoni(false);setModaleNoData(false)}
                                            }    
                                        ]}
                                    />
                                :""}

                            {props.ope === "inserisci" ? <CardTitle>Inserimento Comunicazione</CardTitle> :""}
                            {/*{props.ope === "modifica" ? <CardTitle>Modifica Comunicazione</CardTitle> :""}*/}
             
                          
                            <Clessidra loading={loading}/>  

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della comunicazione selezionata ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            : 
                            
                            <Fragment>
                            <Row form>  
                            <Col md={6}>
                                <b>Elenco destinatari tra gli Iscritti (attivi e disdetti) {risultatoServerDatiIscritti.len}</b><br/>
                                Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaIscritti(e.target.value.toLowerCase())}/>
                                <div style={{height:500, backgroundColor:"#EEEEEE", overflow:"auto"}}>
                                {/*iscrittiFiltrati[i] 
                                    && 
                                        (setTestoRicercaIscritti==="" || x.toLowerCase().includes(testoRicercaIscritti)) 
                                    &&  */}
                                    {risultatoServerDatiIscritti.map((x,i) =>
                                    (testoRicercaIscritti==="" || (x.COGNOME.toLowerCase()+" "+x.NOME.toLowerCase()+" "+x.NUMERO_TESSERA.toLowerCase()).includes(testoRicercaIscritti)) 
                                    && 
                                    
                                        <div style={{display:"flex",flexDirection:"row",backgroundColor:iscrittiSelezionati[i]?coloreEvidenziato:"transparent"}}>
                                        <div 
                                            key={i}
                                            style={{
                                                marginLeft:5, 
                                                flex:2, 
                                                backgroundColor:iscrittiSelezionati[i]?coloreEvidenziato:"transparent", 
                                                color:iscrittiSelezionati[i]?"#FFFFFF":"#000000",
                                                cursor: 'pointer'
                                            }}
                                           
                                            onClick={() => {
                                                // Update the count of selected items
                                                if (iscrittiSelezionati[i]) {
                                                    setNumeroIscrittiSelezionati(numeroIscrittiSelezionati - 1);
                                                } else {
                                                    setNumeroIscrittiSelezionati(numeroIscrittiSelezionati + 1);
                                                }
                                        
                                                // Update the selection state
                                                var ps = [...iscrittiSelezionati]; // Correctly spreading iscrittiSelezionati array
                                                ps[i] = !ps[i];
                                                setIscrittiSelezionati(ps);
                                            }}
                                        >
                                            {x.COGNOME} {x.NOME} {x.NUMERO_TESSERA}
                                        </div >   
                                            {iscrittiSelezionati[i]?
                                           
                                            <div style={{flex:3,display:"flex",flexDirection:"row"}}> 
                                            <div style={{flex:1,marginRight: '10px'}}>
                                            <span style={{ color: iscrittiSelezionati[i] ? "#FFFFFF" : "#000000" }}>Esito</span>
                                            <select                  
                                                    className="form-control"  
                                                    style={{ width: '100%' }}
                                                    onInput={(e)=>{
                                                    callbackSetVariazioneCampi(1);
                                                    callbackSetValoreTendEditabileIscritti(e.target.value,x.ID);}}>
                                                    
                                                    {arrayEsitiPossibili.map((elementoPossibile,indexPossibile) =>

                                                    esitiIscritti[x.ID] !== undefined && arrayEsitiPossibili[indexPossibile] === esitiIscritti[x.ID] ? 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]} selected>{arrayEsitiPossibili[indexPossibile]}</option> 
                                                    : 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]}>{arrayEsitiPossibili[indexPossibile]}</option>
        
                                                )};
                                            </select>
                                            </div > 
                                            <div style={{flex:2,marginRight: '10px'}}>
                                            <span style={{ color: iscrittiSelezionati[i] ? "#FFFFFF" : "#000000" }}>Risposta</span>
                                            <textarea
                                                        key={i}
                                                        maxLength={200}
                                                        placeholder=""
                                                        style={textareaStyleRispostaIscritti(i)} // Applica lo stile condizionale
                                                        onChange={(e) => {
                                                            callbackSetValoreRisposteIscritti(e.target.value,x.ID)
                                                            handleCambiamentoMod(e);
                                                        }}
                                                        onMouseEnter={() => handleMouseEnterRispostaIscritti(i)}
                                                        onMouseLeave={handleMouseLeaveRispostaIscritti}
                                                    />
                                            </div>
                                            </div>
                                            :""}


                                        </div>                                
                                    )}   
                                </div> 
                                
                                                    
                            </Col> 

                            <Col md={6}>
                                <b>Elenco destinatari tra i Contatti / Prospect</b><br/>
                                Cerca: <input type='text' style={{width:"80%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaProspect(e.target.value.toLowerCase())}/>
                                <div style={{height:500, backgroundColor:"#EEEEEE", overflow:"auto"}}>


                                {/*prospectFiltrati[i]
                                    && 
                                        (setTestoRicercaProspect==="" || x.toLowerCase().includes(testoRicercaProspect)) 
                                    &&  */}
                                    {risultatoServerDatiProspect.map((x,i) =>

                                    (testoRicercaProspect==="" || (x.COGNOME.toLowerCase()+" "+x.NOME.toLowerCase()).includes(testoRicercaProspect)) 
                                    &&  

                                        <div style={{display:"flex",flexDirection:"row",backgroundColor:prospectSelezionati[i]?coloreEvidenziato:"transparent"}}>
                                    
                                        <div 
                                            key={i}
                                            style={{
                                                marginLeft:5, 
                                                flex:2,
                                                backgroundColor:prospectSelezionati[i]?coloreEvidenziato:"transparent", 
                                                color:prospectSelezionati[i]?"#FFFFFF":"#000000",
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                // Update the count of selected items
                                                if (prospectSelezionati[i]) {
                                                    setNumeroProspectSelezionati(numeroProspectSelezionati - 1);
                                                } else {
                                                    setNumeroProspectSelezionati(numeroProspectSelezionati + 1);
                                                }
                                        
                                                // Update the selection state
                                                var ps = [...prospectSelezionati]; // Correctly spreading prospectSelezionati array
                                                ps[i] = !ps[i];
                                                setProspectSelezionati(ps);
                                            }}
                                        >
                                            {x.COGNOME} {x.NOME} 
                                        </div> 
                                            
                                            {prospectSelezionati[i]?
                                            <div style={{flex:3,display:"flex",flexDirection:"row"}}> 
                                            <div style={{flex:1,marginRight: '10px'}}> 
                                            <span style={{ color: prospectSelezionati[i] ? "#FFFFFF" : "#000000" }}>Esito</span>
                                            <select                   
                                                    className="form-control"  
                                                    style={{ width: '100%' }}
                                                    onClick={(e) => e.stopPropagation()}  // Stop propagation on click
                                                    onInput={(e)=>{
                                                    callbackSetVariazioneCampi(1);
                                                    callbackSetValoreTendEditabileProspect(e.target.value,x.ID);}}>
                                                   
                                                    {arrayEsitiPossibili.map((elementoPossibile,indexPossibile) =>

                                                    esitiProspect[x.ID] !== undefined && arrayEsitiPossibili[indexPossibile] === esitiProspect[x.ID] ? 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]} selected>{arrayEsitiPossibili[indexPossibile]}</option> : 
                                                    <option key={indexPossibile} value={arrayEsitiPossibili[indexPossibile]}>{arrayEsitiPossibili[indexPossibile]}</option>
                                                                        
                                                )};
                                            </select>
                                            </div>
                                            <div style={{flex:2,marginRight: '10px'}}> 
                                            <span style={{ color: prospectSelezionati[i] ? "#FFFFFF" : "#000000" }}>Risposta</span>
                                            <textarea
                                                        key={i}
                                                        maxLength={200}
                                                        placeholder=""
                                                        style={textareaStyleRispostaProspect(i)} // Applica lo stile condizionale
                                                        onChange={(e) => {
                                                            callbackSetValoreRisposteProspect(e.target.value,x.ID)
                                                            handleCambiamentoMod(e);
                                                        }}
                                                        onMouseEnter={() => handleMouseEnterRispostaProspect(i)}
                                                        onMouseLeave={handleMouseLeaveRispostaProspect}
                                                    />
                                            </div>
                                            </div>
                                            :""}
                                           
                                        </div>                            
                                    )}   
                                </div>                     
                            </Col>              
                        </Row>  
                        <br></br>
                        {/*data*/}
                        <Row form>  
                            <Col md={6}>
                                <FormGroup>
                                    Data*
                                        {/*{props.ope === "inserisci" || props.ope === "modifica" ?*/}
                                        <Input maxLength={10} type="date" name="dataInvio" id="dataInvio"
                                            innerRef={dataInvio} 
                                            placeholder=""
                                            defaultValue={dataInvioDefault}
                                            value={dataInvioDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setDataInvioDefault(dataInvio.current.value);}}/> 
                                        {/*}:""}     */}
                                </FormGroup>
                            </Col>
                        

                        {/*mezzo di comunicazione*/}
                        
                            <Col md={6}>    
                                <FormGroup>
                                Mezzo di Comunicazione* 
                                <TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="DESCRIZIONE"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={insMezzoEdi}
                                    arrayValori={risultatoServerDatiMezziComunicazione}
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabileMezzoComunicazione}
                                ></TendinaEditabile>
                                </FormGroup>
                            </Col>   
                        </Row>

                        {/*testo*/}
                        <Row form> 
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="testo">Testo</Label>
                                        <Input maxLength={200} type="textarea" name="testo" id="testo"  defaultValue={testoDefault} innerRef={testo}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setTestoDefault(testo.current.value)}}/> 
                                </FormGroup>
                            </Col>
                        </Row>

                        </Fragment>




                    }  
                                
                                
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}

                       
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                <Fragment>Torna all'elenco</Fragment>
                                </Button>
                                </div>
                  
                        
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
