import React, {Fragment,useState,useRef,Suspense} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card,
    CardTitle, Button, Form, FormGroup, Label, Input, CardBody
} from 'reactstrap';
import Resizer from "react-image-file-resizer";

import Parametri from '../../parametri';

import TabellaTendine from '../TabellaTendine.js';
import TendinaEditabile from '../TendinaEditabile.js';

import TabellaTesseram from '../TabellaTesseram/Tabella.js';
import TabellaRimborsi from '../TabellaRimborsi/Tabella.js';

//import FormGridFormRowQuotaStorica from '../SchedaQuotaStoricaPersona/FormGridFormRow';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
//import FormattaImportoDb from '../FormattaImportoDb.js';

import FormGridFormRowAbb  from '../SchedaIncassiAbbAss/SchedaIncassiAbbAss';
import FormGridFormRowAss  from '../SchedaIncassiAbbAss/SchedaIncassiAbbAss';
import TabellaInsolventi from '../TabellaInsolventi/Tabella';
import StampaTessera from '../StampaTessera/StampaTessera';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import VisualizzaAllegato from '../VisualizzaAllegato.js';

import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Cestino from "../../immagini/cestino.png";

import { calcolaAnnoOggi } from '../../funzioni.js';//serve per calcolare l'anno in corso tenendo corso della data di chiusura esercizio (utile ad es per trovare gli abbonamenti attivi di un iscritto nell'anno di esercizio in corso)

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

const STATO_VAI_IN_INSERISCI_QUOTA_ABB = 13;
const STATO_VAI_IN_INSERISCI_QUOTA_ASS = 14;

const STATO_VAI_IN_MODIFICA_TESSERAMENTI = 15;


const STATO_VAI_IN_MODIFICA_RIMBORSI = 17;

const STATO_VAI_DETTAGLIO_QUOTE = 18;

const STATO_VAI_STAMPA_TESSERA = 19;

const NASCONDI_TESSERAMENTI = 1;
const VISUALIZZA_TESSERAMENTI = 2;

const NASCONDI_RIMBORSI = 1;
const VISUALIZZA_RIMBORSI = 2;

const NASCONDI_QUOTE = 1;
const VISUALIZZA_QUOTE = 2;

const NASCONDI_ABBONAMENTI = 1;
const VISUALIZZA_ABBONAMENTI = 2;

function FormGridFormRow(props) {

    let coloreRosso = "#D83D00"; //(PER DEBITI)
    let coloreVerde = "#43BC6A"; //(PER CREDITI)
    
    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    const [arrayGruppi,setArrayGruppi]=useState([]);
    const [arrayGruppiPersona,setArrayGruppiPersona]=useState([]);

    const [arrayPosizioni,setArrayPosizioni]=useState([]);
    const [arrayPosizioniPersona,setArrayPosizioniPersona]=useState([]);
    const [arrayPosizioniPersonaDataInizio,setArrayPosizioniPersonaDataInizio]=useState([]);
    const [arrayPosizioniPersonaDataFine,setArrayPosizioniPersonaDataFine]=useState([]);
    const [arrayPosizioniPersonaPeriodicita,setArrayPosizioniPersonaPeriodicita]=useState([]);

    const arrayCampiAssociatiPosizioni=["dataInizio","dataFine","periodicita"];
    const arrayCampiAssociatiPosizioniNomeDatabase=["DATA_INIZIO","DATA_FINE","PERIODICITA"];
    const arrayCampiAssociatiPosizioniType=["date","date","select"];
    const arrayCampiAssociatiPosizioniDescrizione=["Data Inizio*","Data Fine","Rateizzazione Quote"];
    const [arrayCampiAssociatiPosizioniValoriDefault,setArrayCampiAssociatiPosizioniValoriDefault]=useState([]);
    const periodicitaPossibili=["Annuale","Semestrale","Quadrimestrale","Trimestrale","Bimestrale","Mensile"];
    const arrayCampiAssociatiPosizioniValoriPossibili=[];
    arrayCampiAssociatiPosizioniValoriPossibili[2] = [...periodicitaPossibili];

    const [flagModificaPosizioni,setFlagModificaPosizioni]=useState(0);
    const [flagModificaRaggruppamenti,setFlagModificaRaggruppamenti]=useState(0);

    const [arrayRiferimentiPersonaCognome,setArrayRiferimentiPersonaCognome]=useState([]);
    const [arrayRiferimentiPersonaNome,setArrayRiferimentiPersonaNome]=useState([]);
    const [arrayRiferimentiPersonaCodFiscale,setArrayRiferimentiPersonaCodFiscale]=useState([]);
    const [arrayRiferimentiPersonaCellulare,setArrayRiferimentiPersonaCellulare]=useState([]);
    const [arrayRiferimentiPersonaEmail,setArrayRiferimentiPersonaEmail]=useState([]);
    const [arrayRiferimentiPersonaEmailPrecedente,setArrayRiferimentiPersonaEmailPrecedente]=useState([]);
    const [arrayRiferimentiFlagInviaPasswordApp,setArrayRiferimentiFlagInviaPasswordApp]=useState([]);
    const [arrayRiferimentiPersonaNote,setArrayRiferimentiPersonaNote]=useState([]);

    const [arrayRiferimentiPersonaIndirizzo,setArrayRiferimentiPersonaIndirizzo]=useState([]);
    const [arrayRiferimentiPersonaCitta,setArrayRiferimentiPersonaCitta]=useState([]);
    const [arrayRiferimentiPersonaProvincia,setArrayRiferimentiPersonaProvincia]=useState([]);
    const [arrayRiferimentiPersonaCap,setArrayRiferimentiPersonaCap]=useState([]);
    const [arrayRiferimentiPersonaDataNascita,setArrayRiferimentiPersonaDataNascita]=useState([]);
    const [arrayRiferimentiPersonaLuogoNascita,setArrayRiferimentiPersonaLuogoNascita]=useState([]);

    const [flagModaleEsitoInvioPassApp, setFlagModaleEsitoInvioPassApp] = useState(false);
        
    const [flagModaleTesseramenti, setFlagModaleTesseramenti] = React.useState(false);
    const [flagModaleInfoTesseramenti, setFlagModaleInfoTesseramenti] = React.useState(false);
    const [flagModaleInfoAbbonamenti, setFlagModaleInfoAbbonamenti] = React.useState(false);

    const [flagModaleRimborsi, setFlagModaleRimborsi] = React.useState(false);
    const [flagModaleInfoRimborsi, setFlagModaleInfoRimborsi] = React.useState(false);

    const [flagModaleInfoFoto, setFlagModaleInfoFoto] = React.useState(false);
    const [flagModaleInfoDocumento, setFlagModaleInfoDocumento] = React.useState(false);
    const [flagModaleInfoCertMedico, setFlagModaleInfoCertMedico] = React.useState(false);
    const [flagModaleInfoManleva, setFlagModaleInfoManleva] = React.useState(false);
    const [flagModaleConfermaCancFoto, setFlagModaleConfermaCancFoto] = React.useState(false);
    const [flagModaleConfermaCancDocumento, setFlagModaleConfermaCancDocumento] = React.useState(false);
    const [flagModaleConfermaCancCertMedico, setFlagModaleConfermaCancCertMedico] = React.useState(false);
    const [flagModaleConfermaCancManleva, setFlagModaleConfermaCancManleva] = React.useState(false);
    const [flagModaleInfoStampaTessera, setFlagModaleInfoStampaTessera] = React.useState(false);

    const [flagModaleInvioPasswordApp, setFlagModaleInvioPasswordApp] = useState(false);
    const [flagModaleRigeneraQrcode, setFlagModaleRigeneraQrcode] = useState(false);

    const [flagAttivaModaleInfoPosizioni, setFlagAttivaModaleInfoPosizioni] = useState(0);
    const [flagAttivaModaleInfoRiferimenti, setFlagAttivaModaleInfoRiferimenti] = useState(0);
    const [flagAttivaModaleInfoRaggruppamenti, setFlagAttivaModaleInfoRaggruppamenti] = useState(0);
    const [flagAttivaModaleInfoRiceveNotifiche, setFlagAttivaModaleInfoRiceveNotifiche] = useState(0);
    const [flagAttivaModaleDateIngressoUscitaSocNonConsecutive, setFlagAttivaModaleDateIngressoUscitaSocNonConsecutive] = useState(0);
    const [flagAttivaModaleDateCertMedNonConsecutive, setFlagAttivaModaleDateCertMedNonConsecutive] = useState(0);
    const [flagAttivaModaleGruppiDoppi, setFlagAttivaModaleGruppiDoppi] = useState(0);
    const [flagAttivaModaleEmailRiferimentiDoppie, setFlagAttivaModaleEmailRiferimentiDoppie] = useState(0);
    const [flagAttivaModaleDimensioneFoto, setFlagAttivaModaleDimensioneFoto] = useState(0);
    const [flagAttivaModaleDimensioneDocumento, setFlagAttivaModaleDimensioneDocumento] = useState(0);
    const [flagAttivaModaleDimensioneCertMedico, setFlagAttivaModaleDimensioneCertMedico] = useState(0);
    const [flagAttivaModaleDimensioneManleva, setFlagAttivaModaleDimensioneManleva] = useState(0);
    const [flagAttivaModalePosizionePrivaDataInizio, setFlagAttivaModalePosizionePrivaDataInizio] = useState(0);

    const [flagAttivaModalePersonaNonCancellabile, setFlagAttivaModalePersonaNonCancellabile] = useState(0);
    const [flagAttivaModalePersonaNonModificabile, setFlagAttivaModalePersonaNonModificabile] = useState(0);
    const [flagAttivaModalePersonaNonInseribile, setFlagAttivaModalePersonaNonInseribile] = useState(0);
    const [flagAttivaModaleDocumentoNonInseribile, setFlagAttivaModaleDocumentoNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciCognome, setFlagAttivaModaleInserisciCognome] = useState(0);
    const [flagAttivaModalePosizioniNuove, setFlagAttivaModalePosizioniNuove] = useState(0);

    const [risultatoServerCompensiTotaliIscritto,setRisultatoServerCompensiTotaliIscritto]=useState('');

    const [fineSpazio, setFineSpazio] = useState(0);
    const [fineIscritti, setFineIscritti] = useState(0);

    //const [flagAttivaModaleInserisciTessera, setFlagAttivaModaleInserisciTessera] = useState(0);

    //const [rivisualizza,setRivisualizza]=useState(0);

    const [personaPresentePrimaNota,setPersonaPresentePrimaNota] = useState(0);
    const [personaPresenteFatture,setPersonaPresenteFatture] = useState(0);
    const [personaPresenteTesseramenti,setPersonaPresenteTesseramenti] = useState(0);
    const [personaPresentePrenCorsi,setPersonaPresentePrenCorsi] = useState(0);
    const [personaPresentePrenBeni,setPersonaPresentePrenBeni] = useState(0);
    const [personaPresentePartEventi,setPersonaPresentePartEventi] = useState(0);
    const [personaPresenteAbbonati,setPersonaPresenteAbbonati] = useState(0);

    const [emailInvioPasswordApp, setEmailInvioPasswordApp] = useState("");
    
    const cognome  = useRef();
    const nome   = useRef();
    const codFiscale   = useRef();
    //const foto  = useRef();
    const sesso  = useRef();
    const citta   = useRef();
    const indirizzo   = useRef();
    const cap = useRef();
    const provincia  = useRef();
    const tipoDocIdentita  = useRef();
    const dataScadenzaDocIdentita  = useRef();
    const numeroDocIdentita  = useRef();
    const provinciaNascita  = useRef();
    const comuneNascita  = useRef();
    const dataNascita  = useRef();
    const email   = useRef();
    const sitoWeb   = useRef();
    const cellulare   = useRef();
    const telefonoFisso   = useRef();
    const tesseraNumero   = useRef();
    const tesseraInternaNumero   = useRef();
    const dataIngresso   = useRef();
    const dataUscita   = useRef();
    const dataInizioCertMedico   = useRef();
    const dataFineCertMedico   = useRef();
    const dataFinePrivacy   = useRef();
    const flagRiceveNotifiche   = useRef();
    const note   = useRef();
    const noteMediche   = useRef();
    const noteIntolleranze   = useRef();
    const dataNascitaRif   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoVisioneTesseramenti,setStatoVisioneTesseramenti]=useState(STATO_INDEFINITO);
    const [statoVisioneRimborsi,setStatoVisioneRimborsi]=useState(STATO_INDEFINITO);

    const [statoVisioneQuote,setStatoVisioneQuote]=useState(STATO_INDEFINITO);

    const [statoVisioneAbbonamenti,setStatoVisioneAbbonamenti]=useState(STATO_INDEFINITO);

    const [statoTendinaGruppiDefault,setStatoTendinaGruppiDefault]=useState(false);
    const [statoTendinaGruppi,setStatoTendinaGruppi]=useState(false);
    const [statoTendinaTipiDocIdentita,setStatoTendinaTipiDocIdentita]=useState(false);
    const [statoTendinaPosizioniDefault,setStatoTendinaPosizioniDefault]=useState(false);
    const [statoTendinaPosizioni,setStatoTendinaPosizioni]=useState(false);
    const [statoRicercaDatiTesseramentiPersona,setStatoRicercaDatiTesseramentiPersona]=useState(false);
    const [statoRicercaDatiTuttiCompensiIscritto,setStatoRicercaDatiTuttiCompensiIscritto]=useState(false);
    const [statoRicercaVociPrivacy,setStatoRicercaVociPrivacy]=useState(false);

    const [statoRicercaDatiQuotePersona,setStatoRicercaDatiQuotePersona]=useState(false);

    const [statoRicercaDatiAbbonamentiPersona,setStatoRicercaDatiAbbonamentiPersona]=useState(false);

    const [statoDatiRiferimentiDefault,setStatoDatiRiferimentiDefault]=useState(false);
    
    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');
    const [risultatoServerDatiDocIdentita,setRisultatoServerDatiDocIdentita]=useState('');
    const [risultatoServerDatiTesseramentiPersona,setRisultatoServerDatiTesseramentiPersona]=useState('');
    //per lo schema relativo alle quote:

    const [risultatoServerDatiDovutoQuoteAbbIscritto,setRisultatoServerDatiDovutoQuoteAbbIscritto]=useState('');
    const [risultatoServerDatiDovutoQuoteAssIscritto,setRisultatoServerDatiDovutoQuoteAssIscritto]=useState('');
    const [risultatoServerDatiVersatoQuoteAbbIscritto,setRisultatoServerDatiVersatoQuoteAbbIscritto]=useState('');
    const [risultatoServerDatiVersatoQuoteAssIscritto,setRisultatoServerDatiVersatoQuoteAssIscritto]=useState('');

    const [risultatoServerDatiDovutoQuoteIscritto,setRisultatoServerDatiDovutoQuoteIscritto]=useState([]);
    const [risultatoServerDatiVersatoQuoteIscritto,setRisultatoServerDatiVersatoQuoteIscritto]=useState('');

    const [risultatoServerDatiAbbonamentiIscritto,setRisultatoServerDatiAbbonamentiIscritto]=useState([]);
 
    const [insTipoDocIdentitaEdi,setInsTipoDocIdentitaEdi]=useState('');
    //const [defaultTipoDocIdentita,setDefaultTipoDocIdentita]=useState('');

    const [dataScadenzaDocIdentitaDefault,setDataScadenzaDocIdentitaDefault]=useState('');
    
    const [ingrigisciSpecchiettoQuote,setIngrigisciSpecchiettoQuote]=useState("main-card mb-3");

    const [ritornoDaModificaQuote,setRitornoDaModificaQuote]=useState(0);
    const [ritornoDaVisualizzaDettagliQuote,setRitornoDaVisualizzaDettagliQuote]=useState(0);

    //var today = new Date();
    const annoOggi=today.getFullYear();
    const dataPartenzaDefault=annoOggi;

    const  dataPartenzaCalcolataSuDataEsercizio  = useRef();

    const annoOggiCalcolatoSuDataEsercizio = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaCalcolataSuDataEsercizioDefault,setDataPartenzaCalcolataSuDataEsercizioDefault]=useState(annoOggiCalcolatoSuDataEsercizio);

    const [cognomeDefault,setCognomeDefault]=useState('');
    const [nomeDefault,setNomeDefault]=useState('');
    const [codFiscaleDefault,setCodFiscaleDefault]=useState('');
    const [sessoDefault,setSessoDefault]=useState('');
    const [cittaDefault,setCittaDefault]=useState('');
    const [indirizzoDefault,setIndirizzoDefault]=useState('');
    const [capDefault,setCapDefault]=useState('');
    const [provinciaDefault,setProvinciaDefault]=useState('');
    const [numeroDocIdentitaDefault,setNumeroDocIdentitaDefault]=useState('');
    const [provinciaNascitaDefault,setProvinciaNascitaDefault]=useState('');
    const [comuneNascitaDefault,setComuneNascitaDefault]=useState('');
    const [dataNascitaDefault,setDataNascitaDefault]=useState('');
    const [emailDefault,setEmailDefault]=useState('');
    const [emailPrecedente,setEmailPrecedente]=useState('');
    const [sitoWebDefault,setSitoWebDefault]=useState('');
    const [cellulareDefault,setCellulareDefault]=useState('');
    const [telefonoFissoDefault,setTelefonoFissoDefault]=useState('');
    const [tesseraNumeroDefault,setTesseraNumeroDefault]=useState('');
    const [tesseraInternaNumeroDefault,setTesseraInternaNumeroDefault]=useState('');
    const [dataIngressoDefault,setDataIngressoDefault]=useState(dataOggi);
    const [dataUscitaDefault,setDataUscitaDefault]=useState('');
    const [dataInizioCertMedicoDefault,setDataInizioCertMedicoDefault]=useState('');
    const [dataFineCertMedicoDefault,setDataFineCertMedicoDefault]=useState('');
    const [dataFinePrivacyDefault,setDataFinePrivacyDefault]=useState('');
    const [impostazioniPrivacy,setImpostazioniPrivacy]=useState([0,0,0,0,0,0,0,0,0,0]);//metto i 10 vuoti per l'insert, poi ogni modifica andrà a sostituire i valori default 0 con eventualmente 1
    const [flagRiceveNotificheDefault,setFlagRiceveNotificheDefault]=useState('');
    const [flagStaff,setFlagStaff]=useState(false);
    const [flagRichiestaCertificato,setFlagRichiestaCertificato]=useState(true);
    const [flagRichiestaPrivacy,setFlagRichiestaPrivacy]=useState(true);
    const [flagRichiestaDoc,setFlagRichiestaDoc]=useState(true);
    const [flagAmministrazione,setFlagAmministrazione]=useState(false);
    const [noteDefault,setNoteDefault]=useState('');
    const [noteMedicheDefault,setNoteMedicheDefault]=useState('');
    const [noteIntolleranzeDefault,setNoteIntolleranzeDefault]=useState('');
    
    const [flagInviaPasswordApp, setFlagInviaPasswordApp]=useState(true);
    const [esitoInvioPassApp, setEsitoInvioPassApp] = useState("");
    
    const [saldoTotaleQuote,setSaldoTotaleQuote]=useState(0);
    const [saldoTotaleQuoteAbb,setSaldoTotaleQuoteAbb]=useState(0);
    const [saldoTotaleQuoteAss,setSaldoTotaleQuoteAss]=useState(0);

    const [calcolatoSaldoTotaleQuote,setCalcolatoSaldoTotaleQuote]=useState(0);
    const [calcolatoSaldoTotaleQuoteAbb,setCalcolatoSaldoTotaleQuoteAbb]=useState(0);
    const [calcolatoSaldoTotaleQuoteAss,setCalcolatoSaldoTotaleQuoteAss]=useState(0);

    const [insSesso,setSesso]=useState('');

    const [arrayVociPrivacy,setArrayVociPrivacy]=useState([]);

    //per le foto:
    const [contenutoFileFoto,setContenutoFileFoto]=useState('');
    const [contenutoFileQrcode,setContenutoFileQrcode]=useState('');
    const [typeFileFoto,setTypeFileFoto]=useState('');
    const [estensioneFileFoto, setEstensioneFileFoto] = useState('');
    const [flagFotoModificata, setFlagFotoModificata] = useState(false);
    const [dimensioneFoto,setDimensioneFoto]=useState('');
    const [dimensioneMassimaFoto,setDimensioneMassimaFoto]=useState('');
    const [flagEliminaFoto,setFlagEliminaFoto]=useState(''); // contiene "" oppure "on" perché deriva da una checkbox, che ora è diventata un bottone. Il backend richiede questi valori.
    
    //per il documento di identità
    const [contenutoFileDocumento,setContenutoFileDocumento]=useState('');
    const [typeFileDocumento,setTypeFileDocumento]=useState('');
    const [estensioneFileDocumento, setEstensioneFileDocumento] = useState('');
    const [flagDocumentoModificato, setFlagDocumentoModificato] = useState(false);
    const [dimensioneDocumento,setDimensioneDocumento]=useState('');
    const [dimensioneMassimaDocumento,setDimensioneMassimaDocumento]=useState('');
    const [flagEliminaDocumento,setFlagEliminaDocumento]=useState(''); // contiene "" oppure "on" perché deriva da una checkbox, che ora è diventata un bottone. Il backend richiede questi valori.

    //per il certificato medico
    const [contenutoFileCertMedico,setContenutoFileCertMedico]=useState('');
    const [typeFileCertMedico,setTypeFileCertMedico]=useState('');
    const [estensioneFileCertMedico, setEstensioneFileCertMedico] = useState('');
    const [flagCertMedicoModificato, setFlagCertMedicoModificato] = useState(false);
    const [dimensioneCertMedico,setDimensioneCertMedico]=useState('');
    const [dimensioneMassimaCertMedico,setDimensioneMassimaCertMedico]=useState('');
    const [flagEliminaCertMedico,setFlagEliminaCertMedico]=useState(''); // contiene "" oppure "on" perché deriva da una checkbox, che ora è diventata un bottone. Il backend richiede questi valori.

    //per la manleva
    const [contenutoFileManleva,setContenutoFileManleva]=useState('');
    const [typeFileManleva,setTypeFileManleva]=useState('');
    const [estensioneFileManleva, setEstensioneFileManleva] = useState('');
    const [flagManlevaModificata, setFlagManlevaModificata] = useState(false);
    const [dimensioneManleva,setDimensioneManleva]=useState('');
    const [dimensioneMassimaManleva,setDimensioneMassimaManleva]=useState('');
    const [flagEliminaManleva,setFlagEliminaManleva]=useState(''); // contiene "" oppure "on" perché deriva da una checkbox, che ora è diventata un bottone. Il backend richiede questi valori.

    const [richiestaModificaPermesso, setRichiestaModificaPermesso] = useState(0);
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoVisioneTesseramenti(NASCONDI_TESSERAMENTI);
        setStatoVisioneRimborsi(NASCONDI_RIMBORSI);
        setStatoVisioneQuote(NASCONDI_QUOTE);
        setStatoVisioneAbbonamenti(NASCONDI_ABBONAMENTI);
        setSesso(1)
        setStatoTendinaGruppiDefault(false);
        setStatoTendinaGruppi(false);
        setStatoTendinaPosizioniDefault(false);
        setStatoTendinaPosizioni(false);
        setStatoTendinaTipiDocIdentita(false);
        setStatoRicercaDatiTesseramentiPersona(false);
        setStatoRicercaDatiTuttiCompensiIscritto(false);
        setStatoRicercaVociPrivacy(false);
        setStatoRicercaDatiQuotePersona(false);
        setStatoRicercaDatiAbbonamentiPersona(false);
        setSaldoTotaleQuote(0);
        setSaldoTotaleQuoteAbb(0);
        setSaldoTotaleQuoteAss(0);
        setCalcolatoSaldoTotaleQuote(0);
        setCalcolatoSaldoTotaleQuoteAbb(0);
        setCalcolatoSaldoTotaleQuoteAss(0);
    },[]);

    /*useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[rivisualizza])*/

    useEffect(() => {
        if(statoTendinaGruppiDefault === true 
            && statoTendinaGruppi === true 
            && statoTendinaPosizioniDefault === true 
            && statoTendinaPosizioni === true
            && statoTendinaTipiDocIdentita === true
            && statoDatiRiferimentiDefault === true
            && statoRicercaVociPrivacy === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaGruppiDefault,statoTendinaGruppi,statoTendinaPosizioniDefault,statoTendinaPosizioni,statoTendinaTipiDocIdentita,statoDatiRiferimentiDefault,statoRicercaVociPrivacy]);

    useEffect(() => {
        if(statoRicercaDatiTesseramentiPersona === true){
            setStatoVisioneTesseramenti(VISUALIZZA_TESSERAMENTI);
        }
    },[statoRicercaDatiTesseramentiPersona]);

    useEffect(() => {
        if(statoRicercaDatiTuttiCompensiIscritto === true){
            setStatoVisioneRimborsi(VISUALIZZA_RIMBORSI);
        }
    },[statoRicercaDatiTuttiCompensiIscritto]);

    useEffect(() => {
        if(statoRicercaDatiQuotePersona === true){
            setStatoVisioneQuote(VISUALIZZA_QUOTE);
            /*
            if(statoRicercaDatiQuotePersona=== true && risultatoServerDatiDovutoQuoteAbbIscritto!== undefined  && (risultatoServerDatiDovutoQuoteAbbIscritto[0].SALDO === '' || risultatoServerDatiDovutoQuoteAbbIscritto[0].SALDO === undefined)){
                risultatoServerDatiDovutoQuoteAbbIscritto[0].SALDO = 0
            }
            if(statoRicercaDatiQuotePersona=== true && risultatoServerDatiDovutoQuoteAssIscritto!== undefined  && (risultatoServerDatiDovutoQuoteAssIscritto[0].SALDO === '' || risultatoServerDatiDovutoQuoteAssIscritto[0].SALDO === undefined)){
                risultatoServerDatiDovutoQuoteAssIscritto[0].SALDO = 0
            }
            if(statoRicercaDatiQuotePersona=== true && risultatoServerDatiVersatoQuoteAbbIscritto!== undefined  && (risultatoServerDatiVersatoQuoteAbbIscritto[0].SALDO === '' || risultatoServerDatiVersatoQuoteAbbIscritto[0].SALDO === undefined)){
                risultatoServerDatiVersatoQuoteAbbIscritto[0].SALDO = 0
            }
            if(statoRicercaDatiQuotePersona=== true && risultatoServerDatiVersatoQuoteAssIscritto!== undefined  && (risultatoServerDatiVersatoQuoteAssIscritto[0].SALDO === '' || risultatoServerDatiVersatoQuoteAssIscritto[0].SALDO === undefined)){
                risultatoServerDatiVersatoQuoteAssIscritto[0].SALDO = 0
            }
    
            if(statoRicercaDatiQuotePersona === true && risultatoServerDatiDovutoQuoteAbbIscritto!== undefined && risultatoServerDatiVersatoQuoteAbbIscritto[0]!== undefined){ 
                setCalcolatoSaldoTotaleQuoteAbb(1);
                setSaldoTotaleQuoteAbb(parseFloat(risultatoServerDatiVersatoQuoteAbbIscritto[0].SALDO) - parseFloat(risultatoServerDatiDovutoQuoteAbbIscritto[0].SALDO));
            }
            if(statoRicercaDatiQuotePersona === true && risultatoServerDatiDovutoQuoteAssIscritto!== undefined && risultatoServerDatiVersatoQuoteAssIscritto[0]!== undefined){
                setCalcolatoSaldoTotaleQuoteAss(1);
                setSaldoTotaleQuoteAss(parseFloat(risultatoServerDatiVersatoQuoteAssIscritto[0].SALDO) - parseFloat(risultatoServerDatiDovutoQuoteAssIscritto[0].SALDO));
            }
            if(statoRicercaDatiQuotePersona === true){
                setCalcolatoSaldoTotaleQuote(1);
                setSaldoTotaleQuote(
                    parseFloat(risultatoServerDatiVersatoQuoteAbbIscritto[0].SALDO) - parseFloat(risultatoServerDatiDovutoQuoteAbbIscritto[0].SALDO) 
                    + parseFloat(risultatoServerDatiVersatoQuoteAssIscritto[0].SALDO) - parseFloat(risultatoServerDatiDovutoQuoteAssIscritto[0].SALDO));
            }
            */
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }else{
            setStatoVisioneQuote(NASCONDI_QUOTE);
        }
    },[statoRicercaDatiQuotePersona]);

    useEffect(() => {
        if(statoRicercaDatiAbbonamentiPersona === true){
            setStatoVisioneAbbonamenti(VISUALIZZA_ABBONAMENTI);
            
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }else{
            setStatoVisioneAbbonamenti(NASCONDI_ABBONAMENTI);
        }
    },[statoRicercaDatiAbbonamentiPersona]);

    useEffect(() => {
        if(calcolatoSaldoTotaleQuoteAbb === 1 && calcolatoSaldoTotaleQuoteAss === 1 && calcolatoSaldoTotaleQuote === 1){
            setCalcolatoSaldoTotaleQuoteAbb(0);
            setCalcolatoSaldoTotaleQuoteAss(0);
            setCalcolatoSaldoTotaleQuote(0);
            setStatoVisioneQuote(VISUALIZZA_QUOTE);
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[saldoTotaleQuote,saldoTotaleQuoteAbb,saldoTotaleQuoteAss]);

    useEffect(() => {

        if(props.ope==='inserisci') setOperazione("personeIns");
        if(props.ope==='modifica') setOperazione("personeMod");
        if(props.ope==='cancella') setOperazione("personeCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            // interrogo backend per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"personeRecuperaDati",
                    emak: props.emak,
                    id:props.id,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);     
                    setInsTipoDocIdentitaEdi(valoreDiRitorno.risultatoRitorno[0].TIPO_DOC_IDENTITA);   
                    //setDefaultTipoDocIdentita(valoreDiRitorno.risultatoRitorno[0].TIPO_DOC_IDENTITA);
                    setDataScadenzaDocIdentitaDefault(valoreDiRitorno.risultatoRitorno[0].DATA_DOC_IDENTITA);   
                    //setIdIscrittoDefault(valoreDiRitorno.risultatoRitorno[0].ID);
                    setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCodFiscaleDefault(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                    
                    setContenutoFileFoto(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeFileFoto(valoreDiRitorno.risultatoRitorno[0].TYPE_FILE_FOTO);
                    setContenutoFileDocumento(valoreDiRitorno.risultatoRitorno[0].FILE_DOCUMENTO);
                    setTypeFileDocumento(valoreDiRitorno.risultatoRitorno[0].TYPE_FILE_DOCUMENTO);
                    setContenutoFileCertMedico(valoreDiRitorno.risultatoRitorno[0].FILE_CERT_MEDICO);
                    setTypeFileCertMedico(valoreDiRitorno.risultatoRitorno[0].TYPE_FILE_CERT_MEDICO);
                    setContenutoFileManleva(valoreDiRitorno.risultatoRitorno[0].FILE_MANLEVA_DATI_MINORE);
                    setTypeFileManleva(valoreDiRitorno.risultatoRitorno[0].TYPE_FILE_MANLEVA);
                    setEstensioneFileFoto(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FILE_FOTO);
                    setEstensioneFileDocumento(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FILE_DOCUMENTO);
                    setEstensioneFileCertMedico(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FILE_CERTMEDICO);
                    setEstensioneFileManleva(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FILE_MANLEVA);

                    setSessoDefault(valoreDiRitorno.risultatoRitorno[0].SESSO);
                    setCittaDefault(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoDefault(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setCapDefault(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setProvinciaDefault(valoreDiRitorno.risultatoRitorno[0].PROVINCIA);
                    setNumeroDocIdentitaDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_DOC_IDENTITA);
                    setProvinciaNascitaDefault(valoreDiRitorno.risultatoRitorno[0].PROVINCIA_NASCITA);
                    setComuneNascitaDefault(valoreDiRitorno.risultatoRitorno[0].COMUNE_NASCITA);
                    setDataNascitaDefault(valoreDiRitorno.risultatoRitorno[0].DATA_NASCITA);
                    setEmailDefault(valoreDiRitorno.risultatoRitorno[0].EMAIL);
                    setEmailPrecedente(valoreDiRitorno.risultatoRitorno[0].EMAIL);
                    setSitoWebDefault(valoreDiRitorno.risultatoRitorno[0].SITO_WEB);
                    setCellulareDefault(valoreDiRitorno.risultatoRitorno[0].CELLULARE);
                    setTelefonoFissoDefault(valoreDiRitorno.risultatoRitorno[0].TELEFONO);
                    setTesseraNumeroDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                    setTesseraInternaNumeroDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA_INTERNA);
                    setDataIngressoDefault(valoreDiRitorno.risultatoRitorno[0].DATA_INGRESSO_SOC);
                    setDataUscitaDefault(valoreDiRitorno.risultatoRitorno[0].DATA_USCITA_SOC);
                    setDataInizioCertMedicoDefault(valoreDiRitorno.risultatoRitorno[0].DATA_INIZIO_CERT_MEDICO);
                    setDataFineCertMedicoDefault(valoreDiRitorno.risultatoRitorno[0].DATA_FINE_CERT_MEDICO);
                    setDataFinePrivacyDefault(valoreDiRitorno.risultatoRitorno[0].DATA_PRIVACY);
                    setImpostazioniPrivacy(valoreDiRitorno.risultatoRitorno[0].IMPOSTAZIONI_PRIVACY.split(','));
                    setFlagRiceveNotificheDefault(valoreDiRitorno.risultatoRitorno[0].FLAG_ALERT_NOTIFICHE);
                    setFlagStaff(valoreDiRitorno.risultatoRitorno[0].FLAG_STAFF==1);
                    setFlagRichiestaCertificato(valoreDiRitorno.risultatoRitorno[0].FLAG_RICHIESTA_CERTIFICATO==1);
                    setFlagRichiestaPrivacy(valoreDiRitorno.risultatoRitorno[0].FLAG_RICHIESTA_PRIVACY==1);
                    setFlagRichiestaDoc(valoreDiRitorno.risultatoRitorno[0].FLAG_RICHIESTA_DOC==1);
                    setFlagAmministrazione(valoreDiRitorno.risultatoRitorno[0].FLAG_AMMINISTRAZIONE==1);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
                    setNoteMedicheDefault(valoreDiRitorno.risultatoRitorno[0].NOTE_MEDICHE);
                    setNoteIntolleranzeDefault(valoreDiRitorno.risultatoRitorno[0].INTOLLERANZE_ALIMENTARI);    
                    setContenutoFileQrcode(valoreDiRitorno.risultatoRitorno[0].QRCODE);

                    ricercaDatiGruppiDefault();
                    ricercaDatiTuttiGruppi();
                    ricercaDatiPosizioniDefault();
                    ricercaDatiTuttePosizioni();
                    ricercaDatiTipiDocIdentita();
                    ricercaDatiRiferimentiDefault();
                    ricercaVociPrivacy();
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            //ricercaDatiGruppiDefault();
            setStatoTendinaGruppiDefault(true);
            ricercaDatiTuttiGruppi();
            //ricercaDatiPosizioniDefault();
            setFlagModificaPosizioni(1); // se sto inserendo, attiva subito "aggiungi" senza dover cliccare prima "modifica"
            setFlagModificaRaggruppamenti(1); // se sto inserendo, attiva subito "aggiungi" senza dover cliccare prima "modifica"
            setStatoTendinaPosizioniDefault(true);
            setStatoDatiRiferimentiDefault(true);
            ricercaDatiTuttePosizioni();
            ricercaDatiTipiDocIdentita();
            ricercaVociPrivacy();
        }  
    },[props.ope]);

    useEffect(() => { 
        if(flagEliminaFoto === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaFoto]);

    useEffect(() => { 
        if(flagEliminaDocumento === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaDocumento]);

    useEffect(() => { 
        if(flagEliminaCertMedico === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaCertMedico]);

    useEffect(() => { 
        if(flagEliminaManleva === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaManleva]);

    function ricercaVociPrivacy(){
        //vado a fare la fetch di ricerca delle impostazioni del layout tessere:
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            op:"elencoVociPrivacy",
            emak: props.emak,
            sessionId: props.sessionId})
        };
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            
            if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale=[];
                if(valoreDiRitorno.risultatoRitorno[0].VOCE1 !== '') arrLocale[0]=valoreDiRitorno.risultatoRitorno[0].VOCE1;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE2 !== '') arrLocale[1]=valoreDiRitorno.risultatoRitorno[0].VOCE2;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE3 !== '') arrLocale[2]=valoreDiRitorno.risultatoRitorno[0].VOCE3;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE4 !== '') arrLocale[3]=valoreDiRitorno.risultatoRitorno[0].VOCE4;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE5 !== '') arrLocale[4]=valoreDiRitorno.risultatoRitorno[0].VOCE5;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE6 !== '') arrLocale[5]=valoreDiRitorno.risultatoRitorno[0].VOCE6;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE7 !== '') arrLocale[6]=valoreDiRitorno.risultatoRitorno[0].VOCE7;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE8 !== '') arrLocale[7]=valoreDiRitorno.risultatoRitorno[0].VOCE8;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE9 !== '') arrLocale[8]=valoreDiRitorno.risultatoRitorno[0].VOCE9;
                if(valoreDiRitorno.risultatoRitorno[0].VOCE10 !== '') arrLocale[9]=valoreDiRitorno.risultatoRitorno[0].VOCE10;
                setArrayVociPrivacy(arrLocale);
                setStatoRicercaVociPrivacy(true);
            }
        },
        (error) => {console.log("Errore connessione");}
        ); 
    }

    function ricercaDatiTuttiCompensiIscritto(idPersona){
        //ricerco tutte le posizioni in società disponibili:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"controlloCompensiTotaliIscritto",recordPartenza:1,idiscritto:idPersona,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerCompensiTotaliIscritto(valoreDiRitorno.risultatoRitorno);      
                setStatoRicercaDatiTuttiCompensiIscritto(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiAbbonamentiIscritto(idIscritto,tipo) {
        //ricerco gli abbonamenti dell'iscritto:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoAbbonati",ordina_data_inizio:1,dataPartenza:dataPartenzaCalcolataSuDataEsercizioDefault,idIscritto:idIscritto,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    //console.log("ricevo " + valoreDiRitorno.risultatoRitorno.QUOTA_ABBONAMENTO)
                    setRisultatoServerDatiAbbonamentiIscritto(valoreDiRitorno.risultatoRitorno);
                    setStatoRicercaDatiAbbonamentiPersona(true);  
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiDovutoQuoteIscritto(idIscritto) {
        //ricerco il saldo delle quote dovute:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ricercaDovutoQuoteIscritto",idIscritto:idIscritto,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    //console.log("ricevo " + valoreDiRitorno.risultatoRitorno.QUOTA_ABBONAMENTO)
                    setRisultatoServerDatiDovutoQuoteIscritto(valoreDiRitorno.risultatoRitorno);
                    ricercaDatiVersatoQuoteIscritto(idIscritto);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }    
    function ricercaDatiVersatoQuoteIscritto(idIscritto) {
        //ricerco il saldo delle quote versate:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"ricercaVersatoQuoteIscritto",idIscritto:idIscritto,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoServerDatiVersatoQuoteIscritto(valoreDiRitorno.risultatoRitorno); 
                    setStatoRicercaDatiQuotePersona(true);                   
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiTipiDocIdentita() {
        //ricerco tutti i tipi di documento di indentità che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoTipiDocIdentita",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiDocIdentita(valoreDiRitorno.risultatoRitorno);  
                setStatoTendinaTipiDocIdentita(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiGruppiDefault() {
        //ricerco i valori di default da mettere nelle tendine (si tratta dei valori già attualmente assegnati alla persona)
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              op:"elencoPersonaGruppi",
              persona:props.id,
              emak: props.emak,
              sessionId:props.sessionId})
          };
      
          fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale=[];
                for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                    arrLocale[i]=valoreDiRitorno.risultatoRitorno[i].DESCRIZIONE;
                    setArrayGruppiPersona(arrLocale);
                }    
                setStatoTendinaGruppiDefault(true);
            }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiTuttiGruppi() {
        //ricerco tutti i gruppi disponibili che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoGruppi",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setArrayGruppi(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaGruppi(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiPosizioniDefault() {
        //ricerco i valori di default da mettere nelle tendine (si tratta dei valori già attualmente assegnati alla persona)
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              op:"elencoPersonaPosizioni",
              persona:props.id,
              emak: props.emak,
              sessionId:props.sessionId})
          };
      
          fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale=[];
                let arrLocale1=[];
                let arrLocale2=[];
                let arrLocale3=[];
                for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                    arrLocale[i]=valoreDiRitorno.risultatoRitorno[i].DESCRIZIONE;
                    arrLocale1[i]=valoreDiRitorno.risultatoRitorno[i].DATA_INIZIO;
                    arrLocale2[i]=valoreDiRitorno.risultatoRitorno[i].DATA_FINE;
                    arrLocale3[i]=valoreDiRitorno.risultatoRitorno[i].PERIODICITA;
                }    
                setArrayPosizioniPersona(arrLocale);
                setArrayPosizioniPersonaDataInizio(arrLocale1);
                setArrayPosizioniPersonaDataFine(arrLocale2);
                setArrayPosizioniPersonaPeriodicita(arrLocale3);
                setArrayCampiAssociatiPosizioniValoriDefault(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaPosizioniDefault(true);
                if (arrLocale.length==0){
                    setFlagModificaPosizioni(1); // se non ci sono elementi, attiva subito "aggiungi" senza dover cliccare prima "modifica"
                    setFlagModificaRaggruppamenti(1); // se non ci sono elementi, attiva subito "aggiungi" senza dover cliccare prima "modifica"
                } 
            }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiRiferimentiDefault() {
        //ricerco i valori di default da mettere nella pagina relativamente ai riferimenti dell'iscritto
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              op:"elencoPersonaRiferimenti",
              persona:props.id,
              emak: props.emak,
              sessionId:props.sessionId})
          };
      
          fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale1=[];
                let arrLocale2=[];
                let arrLocale3=[];
                let arrLocale4=[];
                let arrLocale5=[];
                let arrLocale6=[];
                let arrLocale7=[];
                let arrLocale8=[];
                let arrLocale9=[];
                let arrLocale10=[];
                let arrLocale11=[];
                let arrLocale12=[];
                let arrLocale13=[];

                for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                    arrLocale1[i]=valoreDiRitorno.risultatoRitorno[i].COGNOME;
                    arrLocale2[i]=valoreDiRitorno.risultatoRitorno[i].NOME;
                    arrLocale3[i]=valoreDiRitorno.risultatoRitorno[i].CODICE_FISCALE;
                    arrLocale4[i]=valoreDiRitorno.risultatoRitorno[i].CELLULARE;
                    arrLocale5[i]=valoreDiRitorno.risultatoRitorno[i].EMAIL;
                    arrLocale6[i]=valoreDiRitorno.risultatoRitorno[i].NOTE;
                    arrLocale7[i]=false;
                    arrLocale8[i]=valoreDiRitorno.risultatoRitorno[i].INDIRIZZO;
                    arrLocale9[i]=valoreDiRitorno.risultatoRitorno[i].CITTA;
                    arrLocale10[i]=valoreDiRitorno.risultatoRitorno[i].PROVINCIA;
                    arrLocale11[i]=valoreDiRitorno.risultatoRitorno[i].CAP;
                    arrLocale12[i]=valoreDiRitorno.risultatoRitorno[i].DATA_NASCITA;
                    arrLocale13[i]=valoreDiRitorno.risultatoRitorno[i].LUOGO_NASCITA;
                }    

                setArrayRiferimentiPersonaCognome(arrLocale1);
                setArrayRiferimentiPersonaNome(arrLocale2);
                setArrayRiferimentiPersonaCodFiscale(arrLocale3);
                setArrayRiferimentiPersonaCellulare(arrLocale4);
                setArrayRiferimentiPersonaEmail(arrLocale5);
                setArrayRiferimentiPersonaEmailPrecedente(arrLocale5);
                setArrayRiferimentiFlagInviaPasswordApp(arrLocale7);
                setArrayRiferimentiPersonaNote(arrLocale6);
                setStatoDatiRiferimentiDefault(true);
                setArrayRiferimentiPersonaIndirizzo(arrLocale8);
                setArrayRiferimentiPersonaCitta(arrLocale9);
                setArrayRiferimentiPersonaProvincia(arrLocale10);
                setArrayRiferimentiPersonaCap(arrLocale11);
                setArrayRiferimentiPersonaDataNascita(arrLocale12);
                setArrayRiferimentiPersonaLuogoNascita(arrLocale13);
            }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function eliminaRiferimento(posizione) {
        //setStatoPagina(STATO_MEMORIZZAZIONE);
        let cognomi = [...arrayRiferimentiPersonaCognome];//popolo l'array locale con i dati dell'array di stato
        cognomi.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaCognome(cognomi);//aggiorno nuovamente la var di stato
        let nomi = [...arrayRiferimentiPersonaNome];//popolo l'array locale con i dati dell'array di stato
        nomi.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaNome(nomi);//aggiorno nuovamente la var di stato
        let codFisc = [...arrayRiferimentiPersonaCodFiscale];//popolo l'array locale con i dati dell'array di stato
        codFisc.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaCodFiscale(codFisc);//aggiorno nuovamente la var di stato
        let cell = [...arrayRiferimentiPersonaCellulare];//popolo l'array locale con i dati dell'array di stato
        cell.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaCellulare(cell);//aggiorno nuovamente la var di stato
        let email = [...arrayRiferimentiPersonaEmail];//popolo l'array locale con i dati dell'array di stato
        email.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaEmail(email);//aggiorno nuovamente la var di stato
        let emailPrecedente = [...arrayRiferimentiPersonaEmailPrecedente];//popolo l'array locale con i dati dell'array di stato
        emailPrecedente.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaEmailPrecedente(emailPrecedente);//aggiorno nuovamente la var di stato
        let flagInviaPasswordApp = [...arrayRiferimentiFlagInviaPasswordApp];//popolo l'array locale con i dati dell'array di stato
        flagInviaPasswordApp.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiFlagInviaPasswordApp(flagInviaPasswordApp);//aggiorno nuovamente la var di stato
        let note = [...arrayRiferimentiPersonaNote];//popolo l'array locale con i dati dell'array di stato
        note.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaNote(note);//aggiorno nuovamente la var di stato
        let indi = [...arrayRiferimentiPersonaIndirizzo];//popolo l'array locale con i dati dell'array di stato
        indi.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaIndirizzo(indi);//aggiorno nuovamente la var di stato
        let citta = [...arrayRiferimentiPersonaCitta];//popolo l'array locale con i dati dell'array di stato
        citta.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaCitta(citta);//aggiorno nuovamente la var di stato
        let prov = [...arrayRiferimentiPersonaProvincia];//popolo l'array locale con i dati dell'array di stato
        prov.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaProvincia(prov);//aggiorno nuovamente la var di stato
        let cap = [...arrayRiferimentiPersonaCap];//popolo l'array locale con i dati dell'array di stato
        cap.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaCap(cap);//aggiorno nuovamente la var di stato
        let datan = [...arrayRiferimentiPersonaDataNascita];//popolo l'array locale con i dati dell'array di stato
        datan.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaDataNascita(datan);//aggiorno nuovamente la var di stato
        let luogo = [...arrayRiferimentiPersonaLuogoNascita];//popolo l'array locale con i dati dell'array di stato
        luogo.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayRiferimentiPersonaLuogoNascita(luogo);//aggiorno nuovamente la var di stato

        //setRivisualizza(rivisualizza+1);
    }
    function aggiungiRiferimento() {
        let cognomi = [...arrayRiferimentiPersonaCognome];//popolo l'array locale con i dati dell'array di stato
        cognomi.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaCognome(cognomi);//aggiorno nuovamente la var di stato
        let nomi = [...arrayRiferimentiPersonaNome];//popolo l'array locale con i dati dell'array di stato
        nomi.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaNome(nomi);//aggiorno nuovamente la var di stato
        let codFisc = [...arrayRiferimentiPersonaCodFiscale];//popolo l'array locale con i dati dell'array di stato
        codFisc.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaCodFiscale(codFisc);//aggiorno nuovamente la var di stato
        let cell = [...arrayRiferimentiPersonaCellulare];//popolo l'array locale con i dati dell'array di stato
        cell.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaCellulare(cell);//aggiorno nuovamente la var di stato
        let email = [...arrayRiferimentiPersonaEmail];//popolo l'array locale con i dati dell'array di stato
        email.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaEmail(email);//aggiorno nuovamente la var di stato
        let emailPrecedente = [...arrayRiferimentiPersonaEmailPrecedente];//popolo l'array locale con i dati dell'array di stato
        emailPrecedente.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaEmailPrecedente(emailPrecedente);//aggiorno nuovamente la var di stato
        let flagInviaPasswordApp = [...arrayRiferimentiFlagInviaPasswordApp];//popolo l'array locale con i dati dell'array di stato
        flagInviaPasswordApp.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiFlagInviaPasswordApp(flagInviaPasswordApp);//aggiorno nuovamente la var di stato
        let note = [...arrayRiferimentiPersonaNote];//popolo l'array locale con i dati dell'array di stato
        note.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaNote(note);//aggiorno nuovamente la var di stato
        let indi = [...arrayRiferimentiPersonaIndirizzo];//popolo l'array locale con i dati dell'array di stato
        indi.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaIndirizzo(indi);//aggiorno nuovamente la var di stato
        let citta = [...arrayRiferimentiPersonaCitta];//popolo l'array locale con i dati dell'array di stato
        citta.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaCitta(citta);//aggiorno nuovamente la var di stato
        let prov = [...arrayRiferimentiPersonaProvincia];//popolo l'array locale con i dati dell'array di stato
        prov.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaProvincia(prov);//aggiorno nuovamente la var di stato
        let cap = [...arrayRiferimentiPersonaCap];//popolo l'array locale con i dati dell'array di stato
        cap.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaCap(cap);//aggiorno nuovamente la var di stato
        let datan = [...arrayRiferimentiPersonaDataNascita];//popolo l'array locale con i dati dell'array di stato
        datan.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaDataNascita(datan);//aggiorno nuovamente la var di stato
        let luogo = [...arrayRiferimentiPersonaLuogoNascita];//popolo l'array locale con i dati dell'array di stato
        luogo.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayRiferimentiPersonaLuogoNascita(luogo);//aggiorno nuovamente la var di stato

    }
    

    function aggiornaRiferimentiCognome(valore,posizione){
//console.log("P="+posizione+" C="+valore)
        let dati = [...arrayRiferimentiPersonaCognome];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaCognome(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiNome(valore,posizione){
        let dati = [...arrayRiferimentiPersonaNome];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaNome(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiCodFiscale(valore,posizione){
        let dati = [...arrayRiferimentiPersonaCodFiscale];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaCodFiscale(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiCell(valore,posizione){
        let dati = [...arrayRiferimentiPersonaCellulare];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaCellulare(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiEmail(valore,posizione){
        let dati = [...arrayRiferimentiPersonaEmail];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaEmail(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiNote(valore,posizione){
        let dati = [...arrayRiferimentiPersonaNote];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaNote(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiIndirizzo(valore,posizione){
        let dati = [...arrayRiferimentiPersonaIndirizzo];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaIndirizzo(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiCitta(valore,posizione){
        let dati = [...arrayRiferimentiPersonaCitta];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaCitta(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiProvincia(valore,posizione){
        let dati = [...arrayRiferimentiPersonaProvincia];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaProvincia(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiCap(valore,posizione){
        let dati = [...arrayRiferimentiPersonaCap];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaCap(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiDataNascita(valore,posizione){
        let dati = [...arrayRiferimentiPersonaDataNascita];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaDataNascita(dati);//aggiorno nuovamente la var di stato
    }
    function aggiornaRiferimentiLuogoNascita(valore,posizione){
        let dati = [...arrayRiferimentiPersonaLuogoNascita];//popolo l'array locale con i dati dell'array di stato
        dati.splice(posizione,1,valore);//modifico l'elemento desiderato
        setArrayRiferimentiPersonaLuogoNascita(dati);//aggiorno nuovamente la var di stato
    }

    function aggiornaImpostazioniPrivacy(valore,posizione){
        //console.log("P="+posizione+" C="+valore)
                let dati = [...impostazioniPrivacy];//popolo l'array locale con i dati dell'array di stato
                //console.log("mi arriva"+valore);
                let val = 0;
                if(valore === true){
                    val = 1;
                    //console.log("CAMBIO in posizione "+posizione);
                } 
                //console.log("calcolo"+val);
                dati.splice(posizione,1,val);//modifico l'elemento desiderato
                setImpostazioniPrivacy(dati);//aggiorno nuovamente la var di stato
            }

    function ricercaDatiTuttePosizioni() {
        //ricerco tutte le posizioni in società disponibili che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPosizioniSocieta",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setArrayPosizioni(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaPosizioni(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagModaleEsitoInvioPassApp(false);
        setFlagModaleInvioPasswordApp(false);
        setFlagModaleRigeneraQrcode(false);
        
        setFlagModaleTesseramenti(false);
        setFlagModaleInfoTesseramenti(false);
        setFlagModaleInfoAbbonamenti(false);

        setFlagModaleRimborsi(false);
        setFlagModaleInfoRimborsi(false);

        setFlagModaleInfoFoto(false);
        setFlagModaleInfoDocumento(false);
        setFlagModaleInfoCertMedico(false);
        setFlagModaleInfoManleva(false);

        setFlagModaleConfermaCancFoto(false);
        setFlagModaleConfermaCancDocumento(false);
        setFlagModaleConfermaCancCertMedico(false);
        setFlagModaleConfermaCancManleva(false);
        setFlagModaleInfoStampaTessera(false);

        setFlagAttivaModalePersonaNonCancellabile(0);
        setFlagAttivaModalePersonaNonModificabile(0);
        setFlagAttivaModalePersonaNonInseribile(0);
        setFlagAttivaModaleDocumentoNonInseribile(0);
        setFlagAttivaModaleInserisciCognome(0);
        setFlagAttivaModalePosizioniNuove(0);
        //setFlagAttivaModaleInserisciTessera(0);
        setFlagAttivaModaleInfoPosizioni(0);
        setFlagAttivaModaleInfoRiferimenti(0);
        setFlagAttivaModaleInfoRaggruppamenti(0);
        setFlagAttivaModaleInfoRiceveNotifiche(0);
        setFlagAttivaModaleDateIngressoUscitaSocNonConsecutive(0);
        setFlagAttivaModaleDateCertMedNonConsecutive(0);
        setFlagAttivaModaleGruppiDoppi(0);
        setFlagAttivaModaleEmailRiferimentiDoppie(0);
        setFlagAttivaModaleDimensioneFoto(0);
        setFlagAttivaModaleDimensioneDocumento(0);
        setFlagAttivaModaleDimensioneCertMedico(0);
        setFlagAttivaModaleDimensioneManleva(0);
        setFlagAttivaModalePosizionePrivaDataInizio(0);

        setPersonaPresentePrimaNota(0);
        setPersonaPresenteFatture(0);
        setPersonaPresenteTesseramenti(0);
        setPersonaPresentePrenCorsi(0);
        setPersonaPresentePrenBeni(0);
        setPersonaPresentePartEventi(0);
        setPersonaPresenteAbbonati(0);
        setFineSpazio(0);
        setFineIscritti(0);
    }

    /*function aggiungiElementoVuotoArrayPosizioniPersona(){
        let d = [...arrayPosizioniPersona];//popolo l'array locale con i dati dell'array di stato
        d.push("");//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayPosizioniPersona(d);//aggiorno nuovamente la var di stato
    }*/

    function callbacksetArrayValoriDefault(arrayGruppiPersona){
        setArrayGruppiPersona(arrayGruppiPersona);
    }

    function callbackAggiornaCampo(numeroRiga,numeroCampo,valore,cancella=0){
//console.log("arrivo per riga"+numeroRiga);
//console.log("Cancella vale"+cancella);
//console.log("arrivo per numero campo"+numeroCampo);
//console.log("arrivo per valore"+valore);
        if(cancella===1){
            //let arr = [...arrayCampiAssociatiPosizioniValoriDefault];//popolo l'array locale con i dati dell'array di stato
            //arr.splice(numeroRiga, 1);//cancello l'elemento desiderato
            //setArrayCampiAssociatiPosizioniValoriDefault(arr);//aggiorno nuovamente la var di stato

            let dati1 = [...arrayPosizioniPersonaDataInizio];//popolo l'array locale con i dati dell'array di stato
            dati1.splice(numeroRiga,1);//cancello l'elemento desiderato
            setArrayPosizioniPersonaDataInizio(dati1);//aggiorno nuovamente la var di stato

            let dati2 = [...arrayPosizioniPersonaDataFine];//popolo l'array locale con i dati dell'array di stato
            dati2.splice(numeroRiga,1);//cancello l'elemento desiderato
            setArrayPosizioniPersonaDataFine(dati2);//aggiorno nuovamente la var di stato
    
            let dati3 = [...arrayPosizioniPersonaPeriodicita];//popolo l'array locale con i dati dell'array di stato
            dati3.splice(numeroRiga,1);//cancello l'elemento desiderato
            setArrayPosizioniPersonaPeriodicita(dati3);//aggiorno nuovamente la var di stato

        }else{
            let nomeCampo = arrayCampiAssociatiPosizioni[numeroCampo];
        
            if(nomeCampo === "dataInizio"){
                let dati = [...arrayPosizioniPersonaDataInizio];//popolo l'array locale con i dati dell'array di stato
                dati.splice(numeroRiga,1,valore);//modifico l'elemento desiderato
                setArrayPosizioniPersonaDataInizio(dati);//aggiorno nuovamente la var di stato
            }
            if(nomeCampo === "dataFine"){
                let dati = [...arrayPosizioniPersonaDataFine];//popolo l'array locale con i dati dell'array di stato
                dati.splice(numeroRiga,1,valore);//modifico l'elemento desiderato
                setArrayPosizioniPersonaDataFine(dati);//aggiorno nuovamente la var di stato
            }
            if(nomeCampo === "periodicita"){ 
                let dati = [...arrayPosizioniPersonaPeriodicita];//popolo l'array locale con i dati dell'array di stato
                dati.splice(numeroRiga,1,valore);//modifico l'elemento desiderato
                setArrayPosizioniPersonaPeriodicita(dati);//aggiorno nuovamente la var di stato
            }
            for (let i=0; i<arrayCampiAssociatiPosizioniValoriDefault.length ; i++) {
                if(i===numeroRiga){
                    arrayCampiAssociatiPosizioniValoriDefault[i][arrayCampiAssociatiPosizioniNomeDatabase[numeroCampo]]=valore;
                }
            }
        }
    }

    function callbacksetArrayValoriPosizioniDefault(arrayPosizioniPersona){
        if(ritornoDaModificaQuote===1){
            setRitornoDaModificaQuote(0);
        }
        if(ritornoDaVisualizzaDettagliQuote===1){
            setRitornoDaVisualizzaDettagliQuote(0);
        }
        
        if(ritornoDaModificaQuote!==1 && ritornoDaVisualizzaDettagliQuote!==1){
            if(statoVisioneQuote === VISUALIZZA_QUOTE){
                //opacizzo perchè ancora le modifiche non sono nel db per cui sarebbe inutile fare un ricalcolo
                setIngrigisciSpecchiettoQuote("main-card mb-3-opaca");
            } 
        }
        //console.log("AGGIORNO"+arrayPosizioniPersona[0]+"-"+arrayPosizioniPersona[1]);
        //console.log("STATO"+statoPagina);
        setArrayPosizioniPersona(arrayPosizioniPersona);
   
    }
    function callbackSetValoreTendEditabileTipiDocIdentita(valore){
        setInsTipoDocIdentitaEdi(valore);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    /*function callbackTermineModifica() {
        //console.log("RITORNO AD ISCRITTI");
        setStatoPagina(STATO_RICERCA_DATI);
    }*/

    function callbackTermineModificaQuote(eseguitoModifica,idIscritto) {
        if(eseguitoModifica === 1){
            //ho eseguito la modifica per cui procedo con un nuovo ricalcolo delle quote da presentare a video aggiornate
            setStatoRicercaDatiQuotePersona(false);
            ricercaDatiDovutoQuoteIscritto(idIscritto);
        }else{
            //non è stata eseguita alcuna modifica
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
        setRitornoDaModificaQuote(1); 
//console.log("eseguitoModifica"+eseguitoModifica)
    }
    function callbackTermineVisualizzazioneDettagliQuote() {
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        setRitornoDaVisualizzaDettagliQuote(1); 
    }
    function callbackTermineStampaTessera() {
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    }

    function attivaModaleInfoPosizioni(){
        setFlagAttivaModaleInfoPosizioni(1);
    }
    function attivaModaleInfoRiferimenti(){
        setFlagAttivaModaleInfoRiferimenti(1);
    }
    function attivaModaleInfoRaggruppamenti(){
        setFlagAttivaModaleInfoRaggruppamenti(1);
    }
    function attivaModaleInfoRiceveNotifiche(){
        setFlagAttivaModaleInfoRiceveNotifiche(1);
    }

    function ricercaDatiTesseramentiPersona(elemento) {
        //ricerco tutte le posizioni in società disponibili:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoTesseramPersona",dataPartenza:dataPartenzaDefault,recordPartenza:1,intervallo:'',idElemento:elemento,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTesseramentiPersona(valoreDiRitorno.risultatoRitorno);  
                setStatoRicercaDatiTesseramentiPersona(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){

            if(noteMediche.current.value.length>64000){
                controlli = 1;
                alert("Il campo Note Mediche contiene troppi caratteri (massimo accettato 64000)");
                setDisabilitaBottoni(false);
            }
            if(noteIntolleranze.current.value.length>64000){
                controlli = 1;
                alert("Il campo Intolleranze contiene troppi caratteri (massimo accettato 64000)");
                setDisabilitaBottoni(false);
            }
            if(note.current.value.length>64000){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 64000)");
                setDisabilitaBottoni(false);
            }
            for (let i=0; i<arrayRiferimentiPersonaNote.length; i++) { 
                if(arrayRiferimentiPersonaNote[i].length>500){
                    controlli = 1;
                    alert("Il campo Note di un Riferimento contiene troppi caratteri (massimo accettato 500)");
                    setDisabilitaBottoni(false);
                }
            }
            
            if(cognome.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciCognome(1);
            }
            //verifico che le date siano consecutive
            if(dataIngresso.current.value!=='' && dataUscita.current.value!==''){
                if(dataIngresso.current.value>dataUscita.current.value){
                    controlli = 1;
                    setFlagAttivaModaleDateIngressoUscitaSocNonConsecutive(1);
                }
            }

            if(dataInizioCertMedico.current.value!=='' && dataFineCertMedico.current.value!==''){
                if(dataInizioCertMedico.current.value>dataFineCertMedico.current.value){
                    controlli = 1;
                    setFlagAttivaModaleDateCertMedNonConsecutive(1);
                }
            }
          
            //controllo univocità raggruppamenti (l'iscritto non può essere legato 2 volte allo stesso raggruppamento):
            for (let i=0; i<arrayGruppiPersona.length ; i++) {
                for (let j=0; j<arrayGruppiPersona.length ; j++) {
                    if(i!==j && arrayGruppiPersona[j]!=='' && arrayGruppiPersona[j]===arrayGruppiPersona[i]){
                        controlli = 1;
                        setFlagAttivaModaleGruppiDoppi(1);
                        break;
                    } 
                }
            }

            //controllo univocità email tra i riferimenti dell'iscritto: (l'iscritto non può essere legato 2 volte a riferimenti con la stessa email):
            for (let i=0; i<arrayRiferimentiPersonaEmail.length ; i++) {
                for (let j=0; j<arrayRiferimentiPersonaEmail.length ; j++) {
                    if(i!==j && arrayRiferimentiPersonaEmail[j]===arrayRiferimentiPersonaEmail[i] && arrayRiferimentiPersonaEmail[j]!==''){
                        controlli = 1;
                        setFlagAttivaModaleEmailRiferimentiDoppie(1);
                        break;
                    } 
                }
            }
        }

        if(controlli===0){ 
            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                //aggiorno con post senza json:
                setLoading(true);
                const formData = new FormData();
                if (props.ope!=='cancella'){
                    formData.append('op', operazione);
                    formData.append('emak', props.emak);
                    formData.append('flagModificaPosizioni', flagModificaPosizioni);
                    formData.append('flagModificaRaggruppamenti', flagModificaRaggruppamenti);
                    formData.append('cognome', cognome.current.value);
                    formData.append('nome', nome.current.value);
                    formData.append('email', email.current.value);                    
                    formData.append('flagInviaPasswordApp', flagInviaPasswordApp&&emailDefault!=emailPrecedente?1:0);
                    formData.append('codFiscale', codFiscale.current.value);
                    if (flagFotoModificata) {
                        formData.append('contenutoFileFoto', contenutoFileFoto);
                        formData.append('typeFileFoto', typeFileFoto);
                        formData.append('estensioneFileFoto', estensioneFileFoto);
                    }
                    formData.append('flagEliminaFoto', flagEliminaFoto);
                    if (flagDocumentoModificato) {
                        formData.append('contenutoFileDocumento', contenutoFileDocumento);
                        formData.append('typeFileDocumento', typeFileDocumento);
                        formData.append('estensioneFileDocumento', estensioneFileDocumento);
                    }
                    formData.append('flagEliminaDocumento', flagEliminaDocumento);
                    if (flagCertMedicoModificato) {
                        formData.append('contenutoFileCertMedico', contenutoFileCertMedico);
                        formData.append('typeFileCertMedico', typeFileCertMedico);
                        formData.append('estensioneFileCertMedico', estensioneFileCertMedico);
                    }
                    formData.append('flagEliminaCertMedico', flagEliminaCertMedico);
                    if (flagManlevaModificata) {
                        formData.append('contenutoFileManleva', contenutoFileManleva);
                        formData.append('typeFileManleva', typeFileManleva);
                        formData.append('estensioneFileManleva', estensioneFileManleva);
                    }
                    formData.append('flagEliminaManleva', flagEliminaManleva);
                    formData.append('sesso', insSesso);
                    formData.append('citta', citta.current.value);
                    formData.append('indirizzo', indirizzo.current.value);
                    formData.append('cap', cap.current.value);
                    formData.append('provincia', provincia.current.value);
                    formData.append('tipoDocIdentita', insTipoDocIdentitaEdi);
                    formData.append('dataDocIdentita', dataScadenzaDocIdentita.current.value);
                    formData.append('numeroDocIdentita', numeroDocIdentita.current.value);
                    formData.append('provinciaNascita', provinciaNascita.current.value);
                    formData.append('comuneNascita', comuneNascita.current.value);
                    formData.append('dataNascita', dataNascita.current.value);
                    formData.append('sitoWeb', sitoWeb.current.value);
                    formData.append('cellulare', cellulare.current.value);
                    formData.append('telefonoFisso', telefonoFisso.current.value);
                    formData.append('tesseraNumero', tesseraNumero.current.value);
                    formData.append('tesseraInternaNumero', tesseraInternaNumero.current.value);
                    formData.append('dataIngresso', dataIngresso.current.value);
                    formData.append('dataUscita', dataUscita.current.value);
                    formData.append('dataInizioCertMedico', dataInizioCertMedico.current.value);
                    formData.append('dataFineCertMedico', dataFineCertMedico.current.value);        
                    if(dataFinePrivacy.current != undefined) formData.append('dataFinePrivacy', dataFinePrivacy.current.value);    
                    for (var i = 0; i < impostazioniPrivacy.length; i++) {
                        formData.append('impostazioniPrivacy[]', impostazioniPrivacy[i]);
                    }
                    //formData.append('flagRiceveNotifiche', flagRiceveNotifiche.current.checked);      
                    formData.append('flagStaff', flagStaff?"1":"0"); 
                    formData.append('flagRichiestaCertificato',  flagRichiestaCertificato?"1":"0");  
                    formData.append('flagRichiestaPrivacy',  flagRichiestaPrivacy?"1":"0");  
                    formData.append('flagRichiestaDoc', flagRichiestaDoc?"1":"0");   
                    formData.append('flagAmministrazione', flagAmministrazione?"1":"0"); 
                    formData.append('note', note.current.value); 
                    formData.append('noteMediche', noteMediche.current.value); 
                    formData.append('noteIntolleranze', noteIntolleranze.current.value); 
                    //formData.append('gruppiPersona', arrayGruppiPersona);
                    for (var i = 0; i < arrayGruppiPersona.length; i++) {
                        formData.append('gruppiPersona[]', arrayGruppiPersona[i]);
                    }
                    for (var i1 = 0; i1 < arrayPosizioniPersona.length; i1++) {
                        formData.append('posizioniPersona[]', arrayPosizioniPersona[i1]);
                    }
                    for (var i2 = 0; i2 < arrayPosizioniPersonaDataInizio.length; i2++) {
                        formData.append('dataInizio[]', arrayPosizioniPersonaDataInizio[i2]);
                    }
                    for (var i3 = 0; i3 < arrayPosizioniPersonaDataFine.length; i3++) {
                        formData.append('dataFine[]', arrayPosizioniPersonaDataFine[i3]);
                    }
                    for (var i4 = 0; i4 < arrayPosizioniPersonaPeriodicita.length; i4++) {
                        formData.append('periodicita[]', arrayPosizioniPersonaPeriodicita[i4]);
                    }
                    for (var i5 = 0; i5 < arrayRiferimentiPersonaCognome.length; i5++) {
                        formData.append('riferimentiPersonaCognome[]', arrayRiferimentiPersonaCognome[i5]);
                    }
                    for (var i6 = 0; i6 < arrayRiferimentiPersonaNome.length; i6++) {
                        formData.append('riferimentiPersonaNome[]', arrayRiferimentiPersonaNome[i6]);
                    }
                    for (var i7 = 0; i7 < arrayRiferimentiPersonaCodFiscale.length; i7++) {
                        formData.append('riferimentiPersonaCodFiscale[]', arrayRiferimentiPersonaCodFiscale[i7]);
                    }
                    for (var i8 = 0; i8 < arrayRiferimentiPersonaCellulare.length; i8++) {
                        formData.append('riferimentiPersonaCellulare[]', arrayRiferimentiPersonaCellulare[i8]);
                    }
                    for (var i9 = 0; i9 < arrayRiferimentiPersonaEmail.length; i9++) {
                        formData.append('riferimentiPersonaEmail[]', arrayRiferimentiPersonaEmail[i9]);
                    }
                    for (var i10 = 0; i10 < arrayRiferimentiPersonaNote.length; i10++) {
                        formData.append('riferimentiPersonaNote[]', arrayRiferimentiPersonaNote[i10]);
                    }
                    for (var i11 = 0; i11 < arrayRiferimentiFlagInviaPasswordApp.length; i11++) {
                        formData.append('riferimentiFlagInviaPasswordApp[]', arrayRiferimentiFlagInviaPasswordApp[i11]&&arrayRiferimentiPersonaEmail[i11]!==arrayRiferimentiPersonaEmailPrecedente[i11]?1:0);
                    }
                    for (var i12 = 0; i12 < arrayRiferimentiPersonaIndirizzo.length; i12++) {
                        formData.append('riferimentiPersonaIndirizzo[]', arrayRiferimentiPersonaIndirizzo[i12]);
                    }
                    for (var i13 = 0; i13 < arrayRiferimentiPersonaCitta.length; i13++) {
                        formData.append('riferimentiPersonaCitta[]', arrayRiferimentiPersonaCitta[i13]);
                    }
                    for (var i14 = 0; i14 < arrayRiferimentiPersonaProvincia.length; i14++) {
                        formData.append('riferimentiPersonaProvincia[]', arrayRiferimentiPersonaProvincia[i14]);
                    }
                    for (var i15 = 0; i15 < arrayRiferimentiPersonaCap.length; i15++) {
                        formData.append('riferimentiPersonaCap[]', arrayRiferimentiPersonaCap[i15]);
                    }
                    for (var i16 = 0; i16 < arrayRiferimentiPersonaDataNascita.length; i16++) {
                        formData.append('riferimentiPersonaDataNascita[]', arrayRiferimentiPersonaDataNascita[i16]);
                    }
                    for (var i17 = 0; i17 < arrayRiferimentiPersonaLuogoNascita.length; i17++) {
                        formData.append('riferimentiPersonaLuogoNascita[]', arrayRiferimentiPersonaLuogoNascita[i17]);
                    }
                    formData.append('idElemento', props.id);
                    formData.append('sessionId', props.sessionId);
                }else{
                    formData.append('op', operazione);
                    formData.append('idElemento', props.id);
                    formData.append('sessionId', props.sessionId);
                }
                formData.append('emak',props.emak);

                const requestOptions = {
                    method: 'POST',
                    body: formData
                }
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in prima nota") !== -1 ||
                                valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in fatture") !== -1 ||
                                valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in tesseramenti") !== -1 ||
                                valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in prenotazioni corsi") !== -1 ||
                                valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in prenotazioni beni") !== -1 ||
                                valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in partecipazione eventi") !== -1 ||
                                valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in abbonati") !== -1 
                                ){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModalePersonaNonCancellabile(1);
                                    if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in prima nota")) setPersonaPresentePrimaNota(1);
                                    if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in fatture")) setPersonaPresenteFatture(1);
                                    if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in tesseramenti")) setPersonaPresenteTesseramenti(1);
                                    if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in prenotazioni corsi")) setPersonaPresentePrenCorsi(1);
                                    if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in prenotazioni beni")) setPersonaPresentePrenBeni(1);
                                    if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in partecipazione eventi")) setPersonaPresentePartEventi(1);
                                    if(valoreDiRitorno.mess.indexOf("Persona non cancellabile: presente in abbonati")) setPersonaPresenteAbbonati(1);

                                } 
                                if(valoreDiRitorno.mess.indexOf("Persona non modificabile: presente altro record uguale") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModalePersonaNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Persona non inseribile: presente altro record uguale") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModalePersonaNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Documento identita non inseribile: presente altro record uguale") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleDocumentoNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Cognome non valorizzato") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleInserisciCognome(1);
                                }
                                /*if(valoreDiRitorno.mess.indexOf("Tessera non valorizzata") !== -1){
                                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModaleInserisciTessera(true);
                                    setFlagAttivaModaleInserisciTessera(1);
                                }*/
                                if(valoreDiRitorno.mess.indexOf("Date Ingresso Uscita non consecutive") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleDateIngressoUscitaSocNonConsecutive(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Date Cert Medico non consecutive") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleDateCertMedNonConsecutive(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Gruppi doppi") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleGruppiDoppi(1);
                                }
                  
                                if(valoreDiRitorno.mess.indexOf("Email riferimenti doppie") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleEmailRiferimentiDoppie(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione eccessiva della foto iscritto") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleDimensioneFoto(1);
                                    setDimensioneFoto(valoreDiRitorno.dimFoto);
                                    setDimensioneMassimaFoto(valoreDiRitorno.dimMaxFoto);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione eccessiva del documento di identità") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleDimensioneDocumento(1);
                                    setDimensioneDocumento(valoreDiRitorno.dimDocumento);
                                    setDimensioneMassimaDocumento(valoreDiRitorno.dimMaxDocumento);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione eccessiva del certificato medico") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleDimensioneCertMedico(1);
                                    setDimensioneCertMedico(valoreDiRitorno.dimCertMedico);
                                    setDimensioneMassimaCertMedico(valoreDiRitorno.dimMaxCertMedico);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione eccessiva della manleva") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setFlagAttivaModaleDimensioneManleva(1);
                                    setDimensioneManleva(valoreDiRitorno.dimManleva);
                                    setDimensioneMassimaManleva(valoreDiRitorno.dimMaxManleva);
                                }
                                if(valoreDiRitorno.mess.indexOf("Posizione priva di data inizio") !== -1){
                                    setFlagAttivaModalePosizionePrivaDataInizio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Posizioni nuove") !== -1){
                                    setFlagAttivaModalePosizioniNuove(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Spazio Terminato") !== -1){
                                    setFineSpazio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iscritti Terminati") !== -1){
                                    setFineIscritti(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    function inviaPasswordApp() {
        setLoading(true);
        setFlagModaleInvioPasswordApp(false);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"personeInviaPassword",
                idPersona:props.id,
                email:emailInvioPasswordApp,
                emak: props.emak,
                sessionId:props.sessionId
            })
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if (valoreDiRitorno.risultatoOperazione===1) {
                    setEsitoInvioPassApp("Password inviata a " + emailInvioPasswordApp);
                } else {
                    if(valoreDiRitorno.risultatoOperazione===2) {
                        props.setPagina(props.PAGINE.tornaLogin);
                    } else {
                        setEsitoInvioPassApp("Password non inviata, si è verificato un errore: verificare di aver inserito un indirizzo email corretto per questo utente e se l'errore persiste, contattare l'assistenza");
                    }
                }                
                setFlagModaleEsitoInvioPassApp(true);
                setDisabilitaBottoni(false);
            },
            (error) => {
                setEsitoInvioPassApp("Password non inviata, si è verificato un errore: contattare l'assistenza");
                setFlagModaleEsitoInvioPassApp(true);
                setDisabilitaBottoni(false);
            }
        );         
    }

    function rigeneraQrcode() {
        setLoading(true);
        setFlagModaleRigeneraQrcode(false);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"rigeneraQrcode",
                idPersona:props.id,
                emak: props.emak,
                sessionId:props.sessionId
            })
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
            (valoreDiRitorno) => {
                setLoading(false);
                setFlagModaleRigeneraQrcode(false);
                if (valoreDiRitorno.risultatoOperazione!==1) {
                    alert("Errore: QRCODE non rigenerato. Riprovare, se l'errore persiste contattare l'assistenza");
                } else {
                    alert("Operazione effettuata, QRCODE rigenerato");
                }                
                setDisabilitaBottoni(false);
                tornaScheda();
            },
            (error) => {
                setLoading(false);
                setFlagModaleRigeneraQrcode(false);
                alert("Errore: QRCODE non rigenerato. Riprovare, se l'errore persiste contattare l'assistenza");
                setDisabilitaBottoni(false);
                tornaScheda();
            }
        );      
    }

    function modificaTesseramenti(){
        if(variazioneCampi === 1){
            setFlagModaleTesseramenti(true);
        }else{
            setStatoPagina(STATO_VAI_IN_MODIFICA_TESSERAMENTI);
        }
    }
    function modificaRimborsi(){
        if(variazioneCampi === 1){
            setFlagModaleRimborsi(true);
        }else{
            setStatoPagina(STATO_VAI_IN_MODIFICA_RIMBORSI);
        }
    }

    function aggiornaFile(nomeElemento,valore){
        setVariazioneCampi(1);
        let typeFile = '';
        if (valore.type.substring(0, 5) === "image") {
            var nuovoTipo = valore.type.substring(6);
            if (nuovoTipo.toUpperCase()!="JPG" && nuovoTipo.toUpperCase()!="JPEG" && nuovoTipo.toUpperCase()!="PNG") {
                nuovoTipo = "JPEG";
                typeFile ="image/jpeg";
            }else{
                typeFile = valore.type;
            }
            try {
                Resizer.imageFileResizer(
                    valore,
                    600,
                    600,
                    nuovoTipo,
                    100,
                    0,
                    (uri) => {
                        switch (nomeElemento) {
                            case "foto": setContenutoFileFoto(uri); break;
                            case "documento": setContenutoFileDocumento(uri); break;
                            case "certMedico": setContenutoFileCertMedico(uri); break;
                            case "manleva": setContenutoFileManleva(uri); break;
                        }
                    },
                    "base64"
                );                
            } catch (err) {
                // console.log(err);
                alert("Si è verificato un errore nel ridimensionamento dell'immagine, contattare l'assistenza tecnica");
            }
        } else {            
            switch (nomeElemento) {                
                case "foto": setContenutoFileFoto(valore); break;
                case "documento": setContenutoFileDocumento(valore); break;
                case "certMedico": setContenutoFileCertMedico(valore); break;
                case "manleva": setContenutoFileManleva(valore); break;
            }
        }
        const estensione = valore.name.split('.').pop();
        switch (nomeElemento) {
            case "foto": setFlagFotoModificata(true); setFlagEliminaFoto(""); setTypeFileFoto(typeFile); setEstensioneFileFoto(estensione); break;
            case "documento": setFlagDocumentoModificato(true); setFlagEliminaDocumento(""); setTypeFileDocumento(typeFile); setEstensioneFileDocumento(estensione); break;
            case "certMedico": setFlagCertMedicoModificato(true); setFlagEliminaCertMedico(""); setTypeFileCertMedico(typeFile); setEstensioneFileCertMedico(estensione); break;
            case "manleva": setFlagManlevaModificata(true); setFlagEliminaManleva(""); setTypeFileManleva(typeFile); setEstensioneFileManleva(estensione); break;
        }
    }

    function funzioneEliminaFoto(valore){
        setVariazioneCampi(1);
        setFlagEliminaFoto(valore);
        setContenutoFileFoto('');
        setTypeFileFoto('');
    }
    
    function funzioneEliminaDocumento(valore){
        setVariazioneCampi(1);
        setFlagEliminaDocumento(valore);
        setContenutoFileDocumento('');
        setTypeFileDocumento('');
    }

    function funzioneEliminaCertMedico(valore){
        setVariazioneCampi(1);
        setFlagEliminaCertMedico(valore);
        setContenutoFileCertMedico('');
        setTypeFileCertMedico('');
    }

    function funzioneEliminaManleva(valore){
        setVariazioneCampi(1);
        setFlagEliminaManleva(valore);
        setContenutoFileManleva('');
        setTypeFileManleva('');
    }

    function scaricaFile(nomeFile, contenuto) {
          const link = document.createElement('a');
          link.href = contenuto;
          link.setAttribute('download',nomeFile);     
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();      
          // Clean up and remove the link
          link.parentNode.removeChild(link);
    }

    function RichiestaConfermaModificaPermessi() {
        var messaggio="";
        switch (richiestaModificaPermesso) {
            case 0: 
                // nessuna checkbox cliccata
                return;
            break;
            case 1:
                // cliccata checkbox 2 (staff)
                if (flagStaff) {
                    messaggio="Confermi di voler togliere l'accesso come Staff alla persona selezionata?";
                } else {
                    messaggio="Confermi di voler dare l'accesso come Staff alla persona selezionata?";
                }        
            break;
            case 2:
                // cliccata checkbox 3 (amministrazione)
                if (flagAmministrazione) {
                    messaggio="Confermi di voler togliere l'accesso come Amministrazione alla persona selezionata?";
                } else {
                    messaggio="Confermi di voler dare l'accesso come Amministrazione alla persona selezionata?";
                }        
            break;
        }
        return (
            <Modale 
                titolo="Richiesta conferma"
                flagErrore={true}
                contenuto={messaggio}
                bottoni={[
                    {
                        "etichetta":"OK",
                        "tipo":"primary",
                        callback:() => {
                            switch (richiestaModificaPermesso) {
                                case 1:
                                    setFlagStaff(!flagStaff);
                                break;
                                case 2:
                                    setFlagAmministrazione(!flagAmministrazione);
                                break;
                            }
                            setRichiestaModificaPermesso(0);
                            handleCambiamentoMod();
                        }
                    },
                    {
                        "etichetta":"Annulla",
                        "tipo":"primary",
                        callback: () => {setRichiestaModificaPermesso(0)}
                    }
                ]}
            />            
        )
    }

    function ProspettoQuote() {
        var saldoTotaleQuoteAss=risultatoServerDatiVersatoQuoteIscritto.QUOTA_ASSOCIATIVA-risultatoServerDatiDovutoQuoteIscritto.QUOTA_ASSOCIATIVA;
        var saldoTotaleQuoteAbb=risultatoServerDatiVersatoQuoteIscritto.QUOTA_ABBONAMENTO-risultatoServerDatiDovutoQuoteIscritto.QUOTA_ABBONAMENTO;
        var saldoTotaleQuote=saldoTotaleQuoteAss+saldoTotaleQuoteAbb;
        return (
            <>
            <Table>
                <tbody>
                <tr>
                    <td style={{borderLeft:0}}></td>
                    <td className="titoloTabella">QUOTE ASSOCIATIVE</td> 
                    <td className="titoloTabella">ABBONAMENTI</td>
                </tr>

                <tr>
                    <td>Dovuto</td>
                        <td>{FormattaImportoVideo(risultatoServerDatiDovutoQuoteIscritto.QUOTA_ASSOCIATIVA)} €</td>
                        <td>{FormattaImportoVideo(risultatoServerDatiDovutoQuoteIscritto.QUOTA_ABBONAMENTO)} €</td>
                </tr>

                <tr>
                    <td>Versato</td>
                        <td>{FormattaImportoVideo(risultatoServerDatiVersatoQuoteIscritto.QUOTA_ASSOCIATIVA)} €</td>
                        <td>{FormattaImportoVideo(risultatoServerDatiVersatoQuoteIscritto.QUOTA_ABBONAMENTO)} €</td>
                </tr>
                
                <tr>
                    <td>Totale</td>
                    
                        {saldoTotaleQuoteAss>=0 ? 
                            <td style={{color: coloreVerde}}>CREDITO{"\n"+FormattaImportoVideo(saldoTotaleQuoteAss)} €</td>
                        :
                            <td style={{color: coloreRosso}}>DEBITO{"\n"+FormattaImportoVideo(-saldoTotaleQuoteAss)} €</td>
                        }
                    
                    
                   
                        {saldoTotaleQuoteAbb>=0 ? 
                            <td style={{color: coloreVerde}}>CREDITO{"\n"+FormattaImportoVideo(saldoTotaleQuoteAbb)} €</td>
                        :
                            <td style={{color: coloreRosso}}>DEBITO{"\n"+FormattaImportoVideo(-saldoTotaleQuoteAbb)} €</td>
                        }
                    
                    
                </tr>
                </tbody>
            </Table>    
            
            {saldoTotaleQuote >0 ? 
                <div style={{color: "#F0F0F0", fontWeight:'bold', backgroundColor:coloreVerde, paddingLeft:5}}>
                    Credito totale dell'iscritto (quote associative e abbonamenti) : {FormattaImportoVideo(saldoTotaleQuote)}
                </div> 
            :""}
            {saldoTotaleQuote <0 ? 
                <div style={{color: "#F0F0F0", fontWeight:'bold', backgroundColor:coloreRosso, paddingLeft:5}}>
                    Debito totale dell'iscritto (quote associative e abbonamenti) : {FormattaImportoVideo(-saldoTotaleQuote)}
                </div> 
            :""}  
            </>          
        )
    }

    function ProspettoAbbonamenti() {
        //risultatoServerDatiDovutoQuoteIscritto.NOME;
        
        return (
            <>
            <Table>
                <tbody>
                <tr>
                    {/*<td style={{borderLeft:0}}></td>*/}
                    <td className="titoloTabella">Abbonamento</td> 
                    <td className="titoloTabella">Data Inizio</td>
                    <td className="titoloTabella">Data Fine</td>
                    <td className="titoloTabella">Importo</td>
                    <td className="titoloTabella">Numero Accessi Residui</td>
                </tr>

                {risultatoServerDatiAbbonamentiIscritto.map((elemento,index) => <tr key={index}>
                    <td>{risultatoServerDatiAbbonamentiIscritto[index].NOMEABBONAMENTO}</td>
                    <td><TrasformaDateAUmano data={risultatoServerDatiAbbonamentiIscritto[index].DATA_INIZIO}></TrasformaDateAUmano></td>
                    <td><TrasformaDateAUmano data={risultatoServerDatiAbbonamentiIscritto[index].DATA_FINE}></TrasformaDateAUmano></td>
                    <td>{FormattaImportoVideo(risultatoServerDatiAbbonamentiIscritto[index].IMPORTO)}</td>
                    {risultatoServerDatiAbbonamentiIscritto[index].ACCESSI_RESIDUI == -1?
                        <td>Infiniti</td>
                    :
                        <td>{risultatoServerDatiAbbonamentiIscritto[index].ACCESSI_RESIDUI}</td>
                    }
                    </tr>)
                }
                
                </tbody>
            </Table>    
            
            {saldoTotaleQuote >0 ? 
                <div style={{color: "#F0F0F0", fontWeight:'bold', backgroundColor:coloreVerde, paddingLeft:5}}>
                    Credito totale dell'iscritto (quote associative e abbonamenti) : {FormattaImportoVideo(saldoTotaleQuote)}
                </div> 
            :""}
            {saldoTotaleQuote <0 ? 
                <div style={{color: "#F0F0F0", fontWeight:'bold', backgroundColor:coloreRosso, paddingLeft:5}}>
                    Debito totale dell'iscritto (quote associative e abbonamenti) : {FormattaImportoVideo(-saldoTotaleQuote)}
                </div> 
            :""}  
            </>          
        )
    }

    //JSON.stringify(risultatoServer)
    let componiPersona="";
    if(cognomeDefault !== '' && cognomeDefault !== undefined) componiPersona = componiPersona + cognomeDefault;
    if(nomeDefault !== '' && nomeDefault !== undefined && componiPersona !== "") componiPersona = componiPersona + " " ;
    if(nomeDefault !== '' && nomeDefault !== undefined) componiPersona = componiPersona + nomeDefault ;
    if(tesseraNumeroDefault !== '' && tesseraNumeroDefault !== undefined && componiPersona !== "") componiPersona = componiPersona + " " ;
    if(tesseraNumeroDefault !== '' && tesseraNumeroDefault !== undefined) componiPersona = componiPersona + "("+tesseraNumeroDefault+")" ;

    //console.log("PRIMA pag ", statoPagina)
    return (
        
        <Fragment>

            
            {/*eccola {props.sessionId}*/}
        
            {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Iscr {error.message}</div> :""}
            {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
            {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

            {statoPagina === STATO_VAI_STAMPA_TESSERA ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento layout tessera in corso...</h6></div>
                    </div>
                }>
                <StampaTessera
                    {...props}
                    //callbackTermineStampaTessera={callbackTermineStampaTessera} 
                    sessionId={props.sessionId} 
                    cognome={cognomeDefault}
                    nome={nomeDefault}
                    dataNascita={dataNascitaDefault}
                    sesso={sessoDefault}
                    tesseraNumero={tesseraNumeroDefault}
                    contenutoFileFoto={contenutoFileFoto}
                    contenutoFileQrcode={contenutoFileQrcode}
                    typeFileFoto={typeFileFoto}
                    estensioneFileFoto={estensioneFileFoto}
                    arrayGruppiPersona={arrayGruppiPersona}
                    arrayPosizioniPersona={arrayPosizioniPersona}
                    arrayCampiAssociatiPosizioniValoriDefault={arrayCampiAssociatiPosizioniValoriDefault}
                    arrayCampiAssociatiPosizioniNomeDatabase={arrayCampiAssociatiPosizioniNomeDatabase}
                    >
            </StampaTessera>
                </Suspense>
                </div>
            :""}

            {statoPagina === STATO_VAI_IN_INSERISCI_QUOTA_ASS ? 
                <div>
                <Suspense fallback={
                <div className="loader-container">
                <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda incasso quota associativa in corso...</h6></div>
                </div>
            }>

            <FormGridFormRowAss 
                {...props}
                ope={"inserisci"} 
                callbackTermineModifica={callbackTermineModificaQuote} 
                sessionId={props.sessionId} 
                idIscritto={props.id} 
                cognome={cognomeDefault} 
                nome={nomeDefault} 
                tessera={tesseraNumeroDefault}
                tipo={"associativa"}
                >
            </FormGridFormRowAss>
            </Suspense>
            </div>
            :""}


            {statoPagina === STATO_VAI_DETTAGLIO_QUOTE ? 
                    <div>
                    <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento dettagli quote in corso...</h6></div>
                    </div>
                }>

                <TabellaInsolventi
                    {...props}
                    callbackTermineVisualizzazioneDettagliQuote={callbackTermineVisualizzazioneDettagliQuote} 
                    sessionId={props.sessionId} 
                    idIscritto={props.id} 
                    cognome={cognomeDefault} 
                    nome={nomeDefault} 
                    tessera={tesseraNumeroDefault}>
                </TabellaInsolventi>
                </Suspense>
                </div>
            :""}

            {statoPagina === STATO_VAI_IN_INSERISCI_QUOTA_ABB ? 
                <div>
                <Suspense fallback={
                <div className="loader-container">
                <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda incasso quota abbonamento in corso...</h6></div>
                </div>
            }>
            <FormGridFormRowAbb 
                {...props}
                ope={"inserisci"} 
                callbackTermineModifica={callbackTermineModificaQuote} 
                sessionId={props.sessionId} 
                idIscritto={props.id} 
                cognome={cognomeDefault} 
                nome={nomeDefault} 
                tessera={tesseraNumeroDefault}
                tipo={"abbonamento"}>
            </FormGridFormRowAbb>
            </Suspense>
            </div>
            :""}


            {statoPagina === STATO_VAI_IN_MODIFICA_TESSERAMENTI ?
                <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento tabella tesseramenti in corso...</h6></div>
                    </div>
                }>
                <TabellaTesseram {...props} idPersona={props.id} sessionId={props.sessionId}></TabellaTesseram>
                </Suspense>
            :""} 

            {statoPagina === STATO_VAI_IN_MODIFICA_RIMBORSI ?
                <Suspense fallback={
                    <div className="loader-container">
                    <div className="loader-container-inner"><h6 className="mt-5">Caricamento tabella rimborsi in corso...</h6></div>
                    </div>
                }>
                <TabellaRimborsi {...props} idPersona={props.id} sessionId={props.sessionId}></TabellaRimborsi>
                </Suspense>
            :""} 

            {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                <RichiestaConfermaModificaPermessi/>

                {flagAttivaModaleDimensioneFoto === 1 ?
                    <Modale 
                        titolo="Dimensione eccessiva foto"
                        flagErrore={true}
                        contenuto={<div>La foto ha una dimensione troppo elevata:<br/>rilevata {dimensioneFoto} KB, massima accettata {dimensioneMassimaFoto} KB</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleDimensioneDocumento === 1 ?
                    <Modale 
                        titolo="Dimensione eccessiva documento"
                        flagErrore={true}
                        contenuto={<div>Il documento d'identità ha una dimensione troppo elevata:<br/>rilevata {dimensioneDocumento} KB, massima accettata {dimensioneMassimaDocumento} KB</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleDimensioneCertMedico === 1 ?
                    <Modale 
                        titolo="Dimensione eccessiva documento"
                        flagErrore={true}
                        contenuto={<div>Il certificato medico ha una dimensione troppo elevata:<br/>rilevata {dimensioneCertMedico} KB, massima accettata {dimensioneMassimaCertMedico} KB</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleDimensioneManleva === 1 ?
                    <Modale 
                        titolo="Dimensione eccessiva documento"
                        flagErrore={true}
                        contenuto={<div>La manleva ha una dimensione troppo elevata:<br/>rilevata {dimensioneManleva} KB, massima accettata {dimensioneMassimaManleva} KB</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}
                
                {flagAttivaModaleGruppiDoppi === 1 ?
                    <Modale 
                        titolo="Gruppi doppi"
                        flagErrore={true}
                        contenuto={"L'iscritto risulta associato più volte allo stesso ragguppamento"}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleEmailRiferimentiDoppie === 1 ?
                    <Modale 
                        titolo="Email duplicate"
                        flagErrore={true}
                        contenuto={"L'iscritto risulta associato a riferimenti diversi che hanno la stessa email"}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />                    
                :""}

                {flagAttivaModaleDateIngressoUscitaSocNonConsecutive === 1 ?
                    <Modale 
                        titolo="Date errate"
                        flagErrore={true}
                        contenuto={"La data di ingresso non può essere successiva alla data di uscita"}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleDateCertMedNonConsecutive === 1 ?
                    <Modale 
                        titolo="Date errate"
                        flagErrore={true}
                        contenuto={"La data di emissione del certificato medico non può essere successiva alla data di scadenza"}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleInfoPosizioni === 1 ?
                    <Modale 
                        titolo="Informativa sulle posizioni sociali"
                        flagErrore={false}
                        contenuto={<div>
                        Ogni iscritto può essere associato ad una o più posizioni sociali (es: Presidente, Tesoriere, Socio onorario, Socio standard ecc...).<br/>
                        Le posizioni sono inseribili/modificabili/cancellabili tramite il menù a destra in Impostazioni - Posizioni in Società.<br/>
                        Ad ogni posizione è possibile associare una o più quote associative, tramite il menù a destra in Quote Associative - Tabella Quote Associative.
                        Il socio dovrà quindi versare alla società le quote associative previste, secondo la rateizzazione (annuale, semestrale, ...) impostabile nella tabella.<br/><br/>
                        Se la Posizione appartiene al Libro Soci, l'Iscritto in esame risulterà nel Libro Soci per il periodo di assegnazione indicato.<br/>
                        Se la Posizione appartiene al Direttivo, l'Iscritto in esame risulterà nell'elenco del Direttivo per il periodo di assegnazione indicato.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleInfoRiferimenti === 1 ?
                    <Modale 
                        titolo="Informativa sui riferimenti"
                        flagErrore={false}
                        contenuto={<div>
                        Ad ogni iscritto è possibile associare uno o più riferimenti utili per poter avere degli estremi da contattare, 
                        ad esempio in caso di emergenza o nel caso in cui l'iscritto sia un minore e si voglia tenere memoria dei contatti di chi ne esercita la patria potestà.<br/>
                        I riferimenti di un iscritto possono accedere tramite APP e consultare o modificare i dati dell'iscritto stesso, come le convocazioni agli eventi,
                        le prenotazioni, le scadenze, ecc.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleInfoRaggruppamenti === 1 ?
                    <Modale 
                        titolo="Informativa sui raggruppamenti"
                        flagErrore={false}
                        contenuto={<div>
                        Ogni iscritto può essere associato ad uno o più raggruppamenti (es. U14, U16, U18...). Tale associazione è utile ad esempio per agevolare la selezione nelle 
                        convocazioni massive per un evento. I raggruppamenti sono inseribili/modificabili/cancellabili tramite menù a destra in Anagrafiche - Raggruppamenti
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleInfoRiceveNotifiche === 1 ?
                    <Modale 
                        titolo="Informativa sulla ricezione notifiche"
                        flagErrore={false}
                        contenuto={<div>
                        L'iscritto cui viene abilitato il flag 'Riceve notifiche' avrà la possibilità di ricevere sulla app informazioni dalla società quali convocazioni ad eventi, news, ecc.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleInfoTesseramenti === true ?
                    <Modale 
                        titolo="Informativa sui tesseramenti"
                        flagErrore={false}
                        contenuto={<div>
                        L'iscritto può essere tesserato ad uno o più enti di tesseramento.<br/>
                        E' possibile accedere all'ambiente tesseramenti tramite l'apposita voce di menu a sinistra. 
                        Inoltre è possibile eseguire operazioni sui tesseramenti dell'iscritto tramite il bottone Modifica Tesseramenti, dopo aver salvato eventuali modifiche fatte su altri campi della Scheda Iscritto.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />                    
                :""}

                {flagModaleInfoAbbonamenti === true ?
                    <Modale 
                        titolo="Informativa sugli abbonamenti dell'iscritto"
                        flagErrore={false}
                        contenuto={<div>
                            In questa sezione vengono riportati tutti gli abbonamenti dell'iscritto, attivi nell'anno di esercizio selezionato, compresi quelli con numero di accessi pari a 0. 
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />                    
                :""}

                {flagModaleInfoRimborsi === true ?
                    <Modale 
                        titolo="Informativa sui rimborsi"
                        flagErrore={false}
                        contenuto={<div>
                        Il totale dei rimborsi pagati all'iscritto selezionato viene calcolato su base annua tenendo come riferimento l'anno solare.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleTesseramenti=== true ?
                    <Modale 
                        titolo="Passaggio all'ambiente tesseramenti"
                        flagErrore={true}
                        contenuto={<div>
                            Passando all'ambiente tesseramenti verranno perse le modifiche eseguite sulla scheda dell'iscritto.<br/>
                            Per memorizzare le modifiche, premere Annulla e poi il tasto Memorizza Modifiche in fondo alla pagina
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);setStatoPagina(STATO_VAI_IN_MODIFICA_TESSERAMENTI)}
                            },    
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            },    
                        ]}
                    />                     
                :""}

                {flagModaleRimborsi=== true ?
                    <Modale 
                        titolo="Passaggio all'ambiente rimborsi"
                        flagErrore={true}
                        contenuto={<div>
                            Passando all'ambiente rimborsi verranno perse le modifiche eseguite sulla scheda dell'iscritto.<br/>
                            Per memorizzare le modifiche, premere Annulla e poi il tasto Memorizza Modifiche in fondo alla pagina
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);setStatoPagina(STATO_VAI_IN_MODIFICA_RIMBORSI)}
                            },    
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            },    

                        ]}
                    />                    
                :""}

                {flagAttivaModalePosizionePrivaDataInizio === 1 ?
                    <Modale 
                        titolo="Posizione sociale senza data"
                        flagErrore={true}
                        contenuto={<div>
                            E' presente una posizione per la quale non è stata indicata la data di inizio, che è necessaria
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />                    
                :""}

                {flagAttivaModalePosizioniNuove === 1 ?
                    <Modale 
                        titolo="Posizioni sociali errate"
                        flagErrore={true}
                        contenuto={<div>
                            Le posizioni sociali vanno selezionate tra quelle già presenti in tendina. Le posizioni sono inseribili/modificabili/cancellabili tramite il menù a destra in Impostazioni Generali - Posizioni in Società
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />                    
                :""}



                {flagAttivaModaleInserisciCognome===1 ?
                    <Modale 
                        titolo="Cognome non inserito"
                        flagErrore={true}
                        contenuto={props.ope === "inserisci" ? 
                            "Il cognome non è stato inserito, è necessario valorizzarlo per poter inserire l'iscritto"
                        :
                            "Il cognome non è stato inserito, è necessario valorizzarlo per poter modificare l'iscritto"
                        }
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModalePersonaNonCancellabile=== 1 ?
                    <Modale 
                        titolo="Iscritto non eliminabile"
                        flagErrore={true}
                        contenuto={
                            personaPresentePrimaNota===1 ? "L'iscritto selezionato non è cancellabile in quanto ha quote (associative e/o abbbonamento) e/o ricevuto/eseguito pagamenti (incassi diversi da iscritti e/o rimborsi)" : 
                            personaPresenteFatture===1 ? "L'iscritto selezionato non è cancellabile in quanto ha quote (associative e/o abbbonamento) e/o ricevuto/eseguito pagamenti (incassi diversi da iscritti e/o rimborsi)" : 
                            personaPresenteTesseramenti===1 ? "L'iscritto selezionato non è cancellabile in quanto associato ad uno o più tesseramenti" : 
                            personaPresentePrenCorsi===1 ? "L'iscritto selezionato non è cancellabile in quanto associato ad uno o più prenotazioni di corsi" : 
                            personaPresentePrenBeni===1 ? "L'iscritto selezionato non è cancellabile in quanto associato ad uno o più prenotazioni di attrezzature" : 
                            personaPresentePartEventi===1 ? "L'iscritto selezionato non è cancellabile in quanto associato ad uno o più partecipazioni di eventi" : 
                            personaPresenteAbbonati===1 ? "L'iscritto selezionato non è cancellabile in quanto associato ad uno o più record di abbonati" : 
                            ""  
                        }
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />                    
                :""}

                {flagAttivaModalePersonaNonModificabile=== 1 ?
                    <Modale 
                        titolo="Iscritto non memorizzabile"
                        flagErrore={true}
                        contenuto={"L'iscritto "+cognome.current.value+" "+nome.current.value+" ("+tesseraNumero.current.value+") non è modificabile con i nuovi dati inseriti, poichè è già presente un iscritto con stesso cognome, nome e numero di tessera"}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
            :""}

                {flagAttivaModalePersonaNonInseribile=== 1 ?
                    <Modale 
                        titolo="Iscritto non inseribile"
                        flagErrore={true}
                        contenuto={"L'iscritto "+cognome.current.value+" "+nome.current.value+" ("+tesseraNumero.current.value+") non è inseribile con i dati indicati, poichè è già presente un iscritto con stesso cognome, nome e numero di tessera"}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagAttivaModaleDocumentoNonInseribile=== 1 ?
                    <Modale 
                        titolo="Documento non inseribile"
                        flagErrore={true}
                        contenuto={tipoDocIdentita.current ? 
                            "Il documento di identita "+tipoDocIdentita.current.value+" non è inseribile in poichè nel database è già presente un documento di identità uguale"
                        :
                            /* questo testo non c'era, c'era stringa vuota, ma verrebbe una modale senza messaggio? */                                
                            "Il documento di identita non è inseribile in poichè nel database è già presente un documento di identità uguale"
                        }
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />                
                :""}

                {flagModaleInvioPasswordApp ?
                    <Modale 
                        titolo="Invio Password di accesso alla APP"
                        flagErrore={false}
                        contenuto={
                            <div>
                                Confermi di voler resettare la password per accedere alla APP<br/>
                                e inviarla all'indirizzo {emailInvioPasswordApp}?
                                <br/><br/>
                                La richiesta verrà eseguita solo se non sono stati superati i limiti giornalieri e mensili di reset password per questa email.
                            </div>
                        }
                        bottoni={[
                            {
                                "etichetta":"Conferma",
                                "tipo":"primary",
                                callback:() => {inviaPasswordApp()}
                            },    
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleRigeneraQrcode ?
                    <Modale 
                        titolo="Rigenerazione QRCODE"
                        flagErrore={false}
                        contenuto={
                            <div>
                                <b>Confermi di voler generare un nuovo QRCODE per questo utente?</b><br/><br/>
                                Questa operazione non è normalmente necessaria, serve per cambiare il QRCODE ad un iscritto nel caso in cui il vecchio codice sia stato diffuso a persone diverse dall'interessato e sia quindi necessario generarne uno nuovo. Dopo la generazione, l'iscritto avrà immediatamente il nuovo QRCODE disponibile nella APP sul proprio telefono.
                            </div>
                        }
                        bottoni={[
                            {
                                "etichetta":"Conferma",
                                "tipo":"primary",
                                callback:() => {rigeneraQrcode()}
                            },    
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleEsitoInvioPassApp ?
                    <Modale 
                        titolo="Invio Password di accesso alla APP"
                        flagErrore={false}
                        contenuto={esitoInvioPassApp}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleInfoFoto === true ?
                    <Modale 
                        titolo="Foto dell'iscritto"
                        flagErrore={false}
                        contenuto="La foto può essere inserita in formato: jpg, jpeg, gif, png, webp"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleInfoDocumento === true ?
                    <Modale 
                        titolo="Documento d'identità"
                        flagErrore={false}
                        contenuto="Il documento può essere inserito in formato: jpg, jpeg, gif, png, webp, pdf, odt, doc, docx"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}
                
                {flagModaleInfoCertMedico === true ?
                    <Modale 
                        titolo="Certificato medico"
                        flagErrore={false}
                        contenuto="Il documento può essere inserito in formato: jpg, jpeg, gif, png, webp, pdf, odt, doc, docx"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleInfoManleva === true ?
                    <Modale 
                        titolo="Manleva"
                        flagErrore={false}
                        contenuto="Il documento può essere inserito in formato: jpg, jpeg, gif, png, webp, pdf, odt, doc, docx"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleConfermaCancFoto === true ?
                <Modale 
                    titolo="Conferma eliminazione"
                    flagErrore={false}
                    contenuto="Confermi l'eliminazione della foto?"
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setFlagModaleConfermaCancFoto(false); funzioneEliminaFoto("on")}
                        },
                        {
                            "etichetta":"Annulla",
                            "tipo":"primary",
                            callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                        }
                    ]}
                />
                :""}

                {flagModaleConfermaCancDocumento === true ?
                <Modale 
                    titolo="Conferma eliminazione"
                    flagErrore={false}
                    contenuto="Confermi l'eliminazione del documento di identità?"
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setFlagModaleConfermaCancDocumento(false); funzioneEliminaDocumento("on")}
                        },
                        {
                            "etichetta":"Annulla",
                            "tipo":"primary",
                            callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                        }
                    ]}
                />
                :""}

                {flagModaleConfermaCancCertMedico === true ?
                <Modale 
                    titolo="Conferma eliminazione"
                    flagErrore={false}
                    contenuto="Confermi l'eliminazione del certificato medico?"
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setFlagModaleConfermaCancCertMedico(false); funzioneEliminaCertMedico("on")}
                        },
                        {
                            "etichetta":"Annulla",
                            "tipo":"primary",
                            callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                        }
                    ]}
                />
                :""}

                {flagModaleConfermaCancManleva === true ?
                <Modale 
                    titolo="Conferma eliminazione"
                    flagErrore={false}
                    contenuto="Confermi l'eliminazione della manleva?"
                    bottoni={[
                        {
                            "etichetta":"OK",
                            "tipo":"primary",
                            callback:() => {setFlagModaleConfermaCancManleva(false); funzioneEliminaManleva("on")}
                        },
                        {
                            "etichetta":"Annulla",
                            "tipo":"primary",
                            callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                        }
                    ]}
                />
                :""}

                {flagModaleInfoStampaTessera === true ?
                    <Modale 
                        titolo="Modifica Layout Tessere Sociali"
                        flagErrore={false}
                        contenuto="Il layout delle tessere sociali può essere modificato recandosi in Impostazioni -> Impostazioni Società e premendo il bottone 'Modifica Layout Tessere Sociali'"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}
                
                {fineSpazio === 1 ?
                        <Modale 
                        titolo="Informativa spazio terminato"
                        flagErrore={true}
                        contenuto={<div>Lo spazio dati è terminato</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                {fineIscritti=== 1 ?
                        <Modale 
                        titolo="Informativa numero massimo iscritti"
                        flagErrore={true}
                        contenuto={<div>E' stato raggiunto il numero massimo di iscritti</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}


                <div className="schedaSpazioCentrale">
                    {props.ope === "inserisci" ? <CardTitle>Inserimento Iscritto</CardTitle> :""}
                    {props.ope === "modifica" ? variazioneCampi === 1 ? <CardTitle>Modifica Iscritto</CardTitle> : <CardTitle>Scheda {cognomeDefault} {nomeDefault}</CardTitle> :""}
                        
                    
                    <Clessidra loading={loading}/>

                    {props.ope === "cancella" ?
                        <FinestraDialogo 
                            titolo="Conferma eliminazione"
                            flagErrore={true}                                
                            contenuto={<div>
                                Confermi l'eliminazione dell'iscritto <b>{cognomeDefault} {nomeDefault}</b>
                                {tesseraNumeroDefault!=="" ? <> (tessera {tesseraNumeroDefault})</>:<></>}
                                ?<br/>                                    
                            </div>}
                            testoCasellaConferma="Seleziona la casella qui a fianco e poi premi il bottone Conferma per completare l'eliminazione"
                            bottoni={[
                                {
                                    "etichetta":"Conferma",
                                    "tipo":"primary",
                                    "necessariaCasellaConferma": true,
                                    callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                },
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback: () => {tornaElenco()}
                                }
                            ]}
                        />                        

                    :
                    <>                 
                        {/* div contenente il titolo della sezione dati anagrafici */}
                        <div className='schedaSottotitolo'>
                            Dati Anagrafici
                        </div>
                                                            
                        {/* div contenente dati anagrafici, foto e immagine del doc di identità */}                                
                        <div style={{display:"flex", flexDirection:"row"}}>

                            {/* div contenente dati anagrafici */}
                            <div style={{flex:1}}>
                                <Row form>  
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cognome">Cognome*</Label>
                                                <Input maxLength={50} type="text" name="cognome" id="cognome" defaultValue={cognomeDefault} innerRef={cognome} 
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setCognomeDefault(cognome.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="nome">Nome</Label>
                                            <Input maxLength={50} type="text" name="nome" id="nome" defaultValue={nomeDefault} innerRef={nome} 
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNomeDefault(nome.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                <Row form> 
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="tesseraNumero">Tessera Numero</Label>
                                            <Input maxLength={100} type="text" name="tesseraNumero" id="tesseraNumero" defaultValue={tesseraNumeroDefault} innerRef={tesseraNumero}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setTesseraNumeroDefault(tesseraNumero.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="tesseraInternaNumero">Tessera Interna Numero</Label>
                                            <Input maxLength={100} type="text" name="tesseraInternaNumero" id="tesseraInternaNumero" defaultValue={tesseraInternaNumeroDefault} innerRef={tesseraInternaNumero}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setTesseraInternaNumeroDefault(tesseraInternaNumero.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form> 
                                    <Col md={12}>                                            
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <div style={{flex:1}}>
                                                    <Input maxLength={100} type="email" name="email" id="email" defaultValue={emailDefault} innerRef={email}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setEmailDefault(email.current.value)}}/> 
                                                    {emailDefault!==emailPrecedente ?
                                                        <FormGroup>
                                                            <Input className="ml-2 mt-3" type="checkbox" id="IDFlagInviaPasswordApp"                                                                    
                                                                checked = {flagInviaPasswordApp}
                                                                placeholder=""
                                                                onInput={(e)=>{handleCambiamentoMod(e);setFlagInviaPasswordApp(!flagInviaPasswordApp)}}/> 
                                                            <Label 
                                                                className="ml-4 mt-2"
                                                                for="IDFlagInviaPasswordApp" 
                                                                style={{backgroundColor:flagInviaPasswordApp?"#FF6060":"", color:flagInviaPasswordApp?"#FFFFFF":"", paddingLeft:5, paddingRight:5}}>
                                                                {props.ope==="modifica" ? 
                                                                    flagStaff||flagAmministrazione ?
                                                                        "Invia a questo nuovo indirizzo la password per accedere alla APP e al servizio Web" 
                                                                    :
                                                                        "Invia a questo nuovo indirizzo la password per accedere alla APP" 
                                                                : 
                                                                    flagStaff||flagAmministrazione ?
                                                                        "Invia a questo indirizzo la password per accedere alla APP e al servizio Web" 
                                                                    :
                                                                        "Invia a questo indirizzo la password per accedere alla APP" 
                                                                }
                                                            </Label>
                                                        </FormGroup>                                                                
                                                            
                                                    :""}
                                                </div>
                                                {props.ope === "modifica" && variazioneCampi !== 1 ?
                                                    <div style={{paddingLeft:10}}> 
                                                        <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);setEmailInvioPasswordApp(emailDefault);setFlagModaleInvioPasswordApp(true)}}>
                                                            Invia Password
                                                        </Button>
                                                        <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);setFlagModaleRigeneraQrcode(true)}}>
                                                            Rigenera Qrcode
                                                        </Button>
                                                        <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => {setDisabilitaBottoni(true);setStatoPagina(STATO_VAI_STAMPA_TESSERA)}}>
                                                            Stampa Tessera
                                                        </Button><img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoStampaTessera(true)}/>
                                                    </div>
                                                : ""}
                                            </div>
                                        </FormGroup>                                                
                                    </Col>
                                </Row> 

                                <Row form>  
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="cellulare">Telefono Cellulare</Label>
                                            <Input maxLength={30} type="text" name="cellulare" id="cellulare" defaultValue={cellulareDefault} innerRef={cellulare}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setCellulareDefault(cellulare.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                    <FormGroup>
                                        <Label for="telefonoFisso">Telefono Fisso</Label>
                                        <Input maxLength={30} type="text" name="telefonoFisso" id="telefonoFisso" defaultValue={telefonoFissoDefault} innerRef={telefonoFisso}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setTelefonoFissoDefault(telefonoFisso.current.value)}}/> 
                                    </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>  
                                    <Col md={3}>
                                        <Label for="sesso">Sesso</Label>
                                        <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="sesso" 
                                            innerRef={sesso} 
                                            defaultValue={sessoDefault}
                                            defaultChecked = {props.ope === "inserisci" || sessoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setSesso(e.target.value);handleCambiamentoMod(e);}}/> {'Maschio'}
                                        </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="sesso" 
                                            innerRef={sesso} 
                                            defaultValue={sessoDefault}
                                            defaultChecked = {sessoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setSesso(e.target.value);handleCambiamentoMod(e);}}/> {'Femmina'}
                                        </Label>
                                        </FormGroup>
                                    </Col>
                                
                                    <Col md={9}>
                                        <FormGroup>
                                            <Label for="citta">Comune</Label>
                                            <Input maxLength={50} type="text" name="citta" id="citta" defaultValue={cittaDefault} innerRef={citta}
                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setCittaDefault(citta.current.value)}}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form> 
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="indirizzo">Indirizzo</Label>
                                            <Input maxLength={255} type="text" name="indirizzo" id="indirizzo" defaultValue={indirizzoDefault} innerRef={indirizzo}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setIndirizzoDefault(indirizzo.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form> 
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cap">CAP</Label>
                                            <Input maxLength={10} type="text" name="cap" id="cap"  defaultValue={capDefault} innerRef={cap}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setCapDefault(cap.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="provincia">Provincia o Stato Estero</Label>
                                            <Input  maxLength={50} type="text" name="provincia" id="provincia"  defaultValue={provinciaDefault} innerRef={provincia}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setProvinciaDefault(provincia.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="codFiscale">Codice Fiscale</Label>
                                            <Input maxLength={20} type="text" name="codFiscale" id="codFiscale" defaultValue={codFiscaleDefault} innerRef={codFiscale} 
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setCodFiscaleDefault(codFiscale.current.value)}}/> 
                                    </FormGroup>
                                    </Col>
                                </Row> 
                                
                                <Row form>  
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="dataNascita">Data di Nascita</Label>
                                            <Input maxLength={10} type="date" name="dataNascita" id="dataNascita" defaultValue={dataNascitaDefault} innerRef={dataNascita}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataNascitaDefault(dataNascita.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="comuneNascita">Comune di Nascita</Label>
                                            <Input maxLength={50} type="text" name="comuneNascita" id="comuneNascita" defaultValue={comuneNascitaDefault} innerRef={comuneNascita}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setComuneNascitaDefault(comuneNascita.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="provinciaNascita">Provincia o Stato Estero di Nascita</Label>
                                            <Input maxLength={50} type="text" name="provinciaNascita" id="provinciaNascita" defaultValue={provinciaNascitaDefault} innerRef={provinciaNascita}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setProvinciaNascitaDefault(provinciaNascita.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form> 
                                    <Col md={3}>
                   
                                    <Input className="ml-2 mt-2" type="checkbox" 
                                                    checked = {flagRichiestaDoc ? 'checked' :""}
                                                    onInput={(e)=>{setFlagRichiestaDoc(!flagRichiestaDoc);setVariazioneCampi(1)}}/>  
                                                <Label className="ml-4 mt-1">
                                                Documento di Identità Richiesto
                                                </Label>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                        <Label>Tipo di Doc. di Identità</Label>
                                        <TendinaEditabile
                                            //titolo={"Tipo di Documento di Identità"}
                                            nomeCampo="DESCRIZIONE"
                                            valoreDefault={insTipoDocIdentitaEdi}
                                            arrayValori={risultatoServerDatiDocIdentita}
                                            callbackSetVariazioneCampi={setVariazioneCampi}
                                            callbackSetValoreTendEditabile={callbackSetValoreTendEditabileTipiDocIdentita}
                                        ></TendinaEditabile>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="dataScadenzaDocIdenetita">Scadenza Doc. di Identità</Label>
                                            <Input  maxLength={10} type="date" name="dataScadenzaDocIdentita" id="dataScadenzaDocIdentita"  defaultValue={dataScadenzaDocIdentitaDefault} innerRef={dataScadenzaDocIdentita}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataScadenzaDocIdentitaDefault(dataScadenzaDocIdentita.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                                <Label for="numeroDocIdentita">N. Doc. di Identità</Label>
                                                <Input maxLength={100} type="text" name="numeroDocIdentita" id="numeroDocIdentita"  defaultValue={numeroDocIdentitaDefault} innerRef={numeroDocIdentita}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNumeroDocIdentitaDefault(numeroDocIdentita.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form> 
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="sitoWeb">Sito Web</Label>
                                            <Input maxLength={100} type="text" name="sitoWeb" id="sitoWeb" defaultValue={sitoWebDefault} innerRef={sitoWeb}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setSitoWebDefault(sitoWeb.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="dataIngresso">Data Ingresso</Label>
                                            <Input maxLength={10} type="date" name="dataIngresso" id="dataIngresso"  defaultValue={dataIngressoDefault} innerRef={dataIngresso}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataIngressoDefault(dataIngresso.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="dataUscita">Data Uscita</Label>
                                            <Input maxLength={10} type="date" name="dataUscita" id="dataUscita"  defaultValue={dataUscitaDefault} innerRef={dataUscita}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataUscitaDefault(dataUscita.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div> {/* fine div contenente dati anagrafici */}                        

                            <div style={{paddingLeft:10}}> {/* div contenente foto e immagine doc d'identità */}

                                <Label for="foto">
                                    Foto Iscritto
                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoFoto(true)}/>
                                </Label>
                                <VisualizzaAllegato
                                    cssContenitore="btnimg"
                                    cssImmagine="immagineAnagrafica"
                                    cssPdf="pdfAnagrafica"
                                    cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                    scalaPdf={props.dimensioniApp.x/3000}
                                    contenutoFile={contenutoFileFoto}
                                    tipoFile={typeFileFoto}
                                    estensioneFile={estensioneFileFoto}
                                    estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp"}
                                    flagContenutoModificato={flagFotoModificata}
                                    callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile("foto",e.target.files[0]);}}
                                    callbackScarica={() => scaricaFile("Foto " + componiPersona + "." + estensioneFileFoto,contenutoFileFoto)}
                                    callbackElimina={() => setFlagModaleConfermaCancFoto(true)}
                                />                                    

                                <div style={{height:10}}/>

                                <Label for="documento identita">
                                    Documento d'identità
                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoDocumento(true)}/>
                                </Label>                            
                                <VisualizzaAllegato
                                    cssContenitore="btnimg"
                                    cssImmagine="immagineAnagrafica"
                                    cssPdf="pdfAnagrafica"
                                    cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                    scalaPdf={props.dimensioniApp.x/3000}
                                    contenutoFile={contenutoFileDocumento}
                                    tipoFile={typeFileDocumento}
                                    estensioneFile={estensioneFileDocumento}
                                    estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp,.pdf,.odt,.doc,.docx"}
                                    flagContenutoModificato={flagDocumentoModificato}
                                    callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile("documento",e.target.files[0]);}}
                                    callbackScarica={() => scaricaFile("Documento Identita " + componiPersona + "." + estensioneFileDocumento,contenutoFileDocumento)}
                                    callbackElimina={() => setFlagModaleConfermaCancDocumento(true)}
                                />
                            </div> {/* fine div contenente foto e immagine doc d'identità */}
                        </div> {/* fine div contenente dati anagrafici, foto e immagine doc d'identità */}

                        <div className='schedaSottotitolo'>
                            Permessi di accesso aggiuntivi                               
                        </div>

                        <Row form>
                            <Col md={12}>                                    
                                <Input className="ml-2 mt-2" type="checkbox" 
                                    checked = {flagStaff ? 'checked' :""}
                                    onInput={(e)=>{setRichiestaModificaPermesso(1);}}/>  
                                <Label className="ml-4 mt-1">
                                    accesso come Staff
                                </Label>
                                &nbsp;&nbsp;
                                <Input className="ml-2 mt-2" type="checkbox" 
                                    checked = {flagAmministrazione ? 'checked' :""}
                                    onInput={(e)=>{setRichiestaModificaPermesso(2);}}/>  
                                <Label className="ml-4 mt-1">
                                    accesso come Amministrazione
                                </Label>

                            </Col>
                        </Row>

                        <div className='schedaSottotitolo'>
                            Tesseramenti
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoTesseramenti(true)}/>
                        </div>

                        {/*<Button color="success" className="mt-2" onClick={() => ricercaDatiTesseramentiPersona(props.id)}>Visualizza Tesseramenti</Button> */}
                        {(props.ope === "modifica") && statoVisioneTesseramenti !== VISUALIZZA_TESSERAMENTI   ?
                            <Row form><Col md={12}>
                                <a className="testoComeLink" onClick={() => ricercaDatiTesseramentiPersona(props.id)}>Visualizza Tesseramenti</a>
                            </Col></Row>
                        :"I tesseramenti possono essere inseriti solo dopo aver completato l'inserimento dell'iscritto"}
                        
                        {(props.ope === "modifica") && statoVisioneTesseramenti === VISUALIZZA_TESSERAMENTI   ?
                        <Fragment>
                        <Row form>  
                            <Col md={12}>
                                {risultatoServerDatiTesseramentiPersona.length>0 ?
                                <>
                                <a className="testoComeLink" onClick={() => {setStatoVisioneTesseramenti(NASCONDI_TESSERAMENTI);
                                    setStatoRicercaDatiTesseramentiPersona(false);}}>Nascondi Tesseramenti</a>

                                <Table>
                                    <tbody>
                                    {/*<Button color="success" className="mt-2" onClick={() => {setStatoVisioneTesseramenti(NASCONDI_TESSERAMENTI);
                                    setStatoRicercaDatiTesseramentiPersona(false);}}>Nascondi Tesseramenti</Button>*/}
                                    <tr>
                                        <td className="titoloTabella">Ente</td>
                                        <td className="titoloTabella">Num. Tessera Ente</td>
                                        <td className="titoloTabella">Data Inizio</td>
                                        <td className="titoloTabella">Data Fine</td>
                                        <td className="titoloTabella">Tipo</td>
                                        <td className="titoloTabella">Categoria</td>
                                    </tr>

                                    {risultatoServerDatiTesseramentiPersona.map((elemento,index) => <tr key={index}>

                                        <td>{risultatoServerDatiTesseramentiPersona[index].ENTE}</td>
                                        <td>{risultatoServerDatiTesseramentiPersona[index].NUMERO_TESSERA_ENTE}</td>
                                        <td><TrasformaDateAUmano data={risultatoServerDatiTesseramentiPersona[index].DATA_INIZIO}></TrasformaDateAUmano></td>
                                        <td><TrasformaDateAUmano data={risultatoServerDatiTesseramentiPersona[index].DATA_FINE}></TrasformaDateAUmano></td>
                                        <td>{risultatoServerDatiTesseramentiPersona[index].DESCRIZIONE_TIPO}</td>
                                        <td>{risultatoServerDatiTesseramentiPersona[index].DESCRIZIONE_CATEGORIA}</td>
                                        </tr>)
                                    }
                                    </tbody>
                                </Table>
                                </>
                                :
                                <>L'iscritto non ha alcun tesseramento nell'anno in corso<br/></>
                                }
                                <Button color="success" className="mt-2" onClick={() => modificaTesseramenti()}>Modifica Tesseramenti</Button>
                            </Col>
                        </Row>
                        </Fragment>
                        : ""}

                        {(props.ope === "modifica") ?
                            <div className='schedaSottotitolo'>Abbonamenti
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoAbbonamenti(true)}/>
                            </div>
                        :<></>}                        

                        <Row form>  
                        {props.ope === "modifica" ?
                            <Col md={12}>
                            {statoVisioneAbbonamenti === VISUALIZZA_ABBONAMENTI ? 
                                <>
                                    <>
                                    <a className="testoComeLink" onClick={() => setStatoRicercaDatiAbbonamentiPersona(false)}>Nascondi Prospetto Abbonamenti Attivi nell'anno di esercizio {dataPartenzaCalcolataSuDataEsercizioDefault}</a>
                                    <ProspettoAbbonamenti/>                                    
                                    </>
                                </>
                            :
                            <div>                                 
                                <Row form><Col md={12}>
                                    <a className="testoComeLink" onClick={() => ricercaDatiAbbonamentiIscritto(props.id)}>Visualizza Prospetto Abbonamenti Attivi nell'anno di esercizio &nbsp;
                                        
                                    </a>
                                    <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenzaCalcolataSuDataEsercizio} onChange={(e)=>{setDataPartenzaCalcolataSuDataEsercizioDefault(e.target.value)}}>
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio ? <option selected value={annoOggiCalcolatoSuDataEsercizio}>{annoOggiCalcolatoSuDataEsercizio}</option> : <option value={annoOggiCalcolatoSuDataEsercizio}>{annoOggiCalcolatoSuDataEsercizio}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-1 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-1}>{annoOggiCalcolatoSuDataEsercizio-1}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-1}>{annoOggiCalcolatoSuDataEsercizio-1}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-2 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-2}>{annoOggiCalcolatoSuDataEsercizio-2}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-2}>{annoOggiCalcolatoSuDataEsercizio-2}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-3 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-3}>{annoOggiCalcolatoSuDataEsercizio-3}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-3}>{annoOggiCalcolatoSuDataEsercizio-3}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-4 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-4}>{annoOggiCalcolatoSuDataEsercizio-4}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-4}>{annoOggiCalcolatoSuDataEsercizio-4}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-5 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-5}>{annoOggiCalcolatoSuDataEsercizio-5}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-5}>{annoOggiCalcolatoSuDataEsercizio-5}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-6 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-6}>{annoOggiCalcolatoSuDataEsercizio-6}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-6}>{annoOggiCalcolatoSuDataEsercizio-6}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-7 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-7}>{annoOggiCalcolatoSuDataEsercizio-7}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-7}>{annoOggiCalcolatoSuDataEsercizio-7}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-8 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-8}>{annoOggiCalcolatoSuDataEsercizio-8}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-8}>{annoOggiCalcolatoSuDataEsercizio-8}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-9 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-9}>{annoOggiCalcolatoSuDataEsercizio-9}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-9}>{annoOggiCalcolatoSuDataEsercizio-9}</option>}
                                            {parseInt(dataPartenzaCalcolataSuDataEsercizioDefault) === annoOggiCalcolatoSuDataEsercizio-10 ? <option selected value={annoOggiCalcolatoSuDataEsercizio-10}>{annoOggiCalcolatoSuDataEsercizio-10}</option> : <option value={annoOggiCalcolatoSuDataEsercizio-10}>{annoOggiCalcolatoSuDataEsercizio-10}</option>}
                                        </select> 
                                </Col></Row>
                            </div> 
                            }

                            </Col>
                        :""}
                        </Row>



                        {(props.ope === "modifica") ?
                            <div className='schedaSottotitolo'>Quote Associative e Abbonamenti</div>
                        :<></>}                        

                        <Row form>  
                        {props.ope === "modifica" ?
                            <Col md={12}>
                            {statoVisioneQuote === VISUALIZZA_QUOTE ? 
                                <>
                                    <>
                                    <a className="testoComeLink" onClick={() => setStatoRicercaDatiQuotePersona(false)}>Nascondi Prospetto Quote</a>
                                    <ProspettoQuote/>                                    
                                    </>
                                </>
                            :
                            <div>                                 
                                <Row form><Col md={12}>
                                    <a className="testoComeLink" onClick={() => ricercaDatiDovutoQuoteIscritto(props.id)}>Visualizza Prospetto Quote</a>
                                </Col></Row>
                            </div> 
                            }
                            <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI_QUOTA_ASS)}>Pagamento Quota Associativa</Button>
                            <Button color="success" className="mt-2" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI_QUOTA_ABB)}>Pagamento Quota Abbonamento</Button>
                            <Button color="success" className="mt-2 bottoneMargineASinistra" onClick={() => setStatoPagina(STATO_VAI_DETTAGLIO_QUOTE)}>Dettagli Quote</Button>
                            </Col>
                        :""}
                        </Row>

                        {(props.ope === "modifica") ?
                        <>
                            <div className='schedaSottotitolo'>
                                Rimborsi
                                <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoRimborsi(true)}/>
                            </div>

                            {statoVisioneRimborsi !== VISUALIZZA_RIMBORSI ?
                                <Row form><Col md={12}>
                                    <a className="testoComeLink" onClick={() => ricercaDatiTuttiCompensiIscritto(props.id)}>Visualizza Totali Rimborsi Pagati all'Iscritto in Tutte le Annate</a>
                                </Col></Row>
                            :
                                <Row form>  
                                    <Col md={12}>
                                        {risultatoServerCompensiTotaliIscritto.length>0 ?
                                            <>
                                                <a className="testoComeLink" onClick={() => {setStatoVisioneRimborsi(NASCONDI_RIMBORSI);setStatoRicercaDatiTuttiCompensiIscritto(false)}}>Nascondi Totali Rimborsi Pagati all'Iscritto in Tutte le Annate</a>
                                                <Table>
                                                <tbody>                                                                                           
                                                <tr>
                                                    <td className="titoloTabella">Anno</td>
                                                    <td className="titoloTabella">Importo Netto</td>
                                                    <td className="titoloTabella">Importo Iva</td>
                                                    <td className="titoloTabella">Importo Lordo</td>
                                                </tr>
                                            
                                                {risultatoServerCompensiTotaliIscritto.map((elemento,index) => <tr key={index}> 
                                                    <td>{risultatoServerCompensiTotaliIscritto[index].anno}</td>
                                                    <td>{FormattaImportoVideo(risultatoServerCompensiTotaliIscritto[index].importoNetto)}</td>
                                                    <td>{FormattaImportoVideo(risultatoServerCompensiTotaliIscritto[index].importoIva)}</td>
                                                    <td>{FormattaImportoVideo(risultatoServerCompensiTotaliIscritto[index].importo)}</td>
                                                    
                                                    </tr>)
                                                }                                            
                                                </tbody>
                                                </Table>
                                            </>
                                        :
                                            <>L'iscritto non ha alcun rimborso<br/></>
                                        }
                                        <Button color="success" className="mt-2" onClick={() => modificaRimborsi()}>Modifica Rimborsi</Button>
                                    </Col>
                                </Row>
                            }
                        </>
                        :""}                                

                        <div className='schedaSottotitolo'>
                            Persone di Riferimento
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoRiferimenti()}/>
                        </div>

                        <Row form>  
                            <Col md={12}>
                                <FormGroup>
                                {/*<Table responsive bordered hover className="mb-0">*/}
                                <Table>
                                    <tbody>
                                    
                                    {arrayRiferimentiPersonaCognome.length > 0 ?
                                    <tr>
                                        <td style={{border:0}}></td>
                                        <td className="titoloTabella">Riferimento</td>
                                        <td className="titoloTabella">Email</td>
                                        <td className="titoloTabella">Note</td>
                                    </tr>
                                    :""}

                                    {arrayRiferimentiPersonaCognome.map((elemento,index) => <tr key={index}>

                                        <td style={{border:0}}>
                                            <img onClick={(e) => { handleCambiamentoMod(e); eliminaRiferimento(index)}} src={Cestino}/>
                                        </td>

                                        <td style={{padding:5}}>
                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <div style={{flex:1, paddingRight:5}}>
                                                    Cognome:<br/>
                                                    <Input maxLength={50} type="text" name="cognomeRif" id="cognomeRif" value={elemento}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiCognome(e.target.value,index)}}/>
                                                </div>
                                                <div style={{flex:1}}>
                                                    Nome:
                                                    <Input maxLength={50} type="text" name="nomeRif" id="nomeRif" value={arrayRiferimentiPersonaNome[index]}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiNome(e.target.value,index)}}/>
                                                </div>
                                            </div>
                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <div style={{paddingRight:5, flex:1}}>
                                                    Telefono:<br/>
                                                    <Input maxLength={30} type="text" name="cellRif" id="cellRif" value={arrayRiferimentiPersonaCellulare[index]}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiCell(e.target.value,index)}}/>
                                                </div>
                                                <div style={{flex:1}}>
                                                    Codice Fiscale:
                                                    <Input maxLength={20} type="text" name="codFiscaleRif" id="codFiscaleRif" value={arrayRiferimentiPersonaCodFiscale[index]}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiCodFiscale(e.target.value,index)}}/>
                                                </div>
                                            </div>

                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <div style={{paddingRight:5, flex:1}}>
                                                    Indirizzo:<br/>
                                                    <Input maxLength={255} type="text" name="indirizzoRif" id="indirizzoRif" value={arrayRiferimentiPersonaIndirizzo[index]} placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiIndirizzo(e.target.value,index)}}/>
                                                </div>
                                                <div style={{flex:1}}>
                                                    Comune:
                                                    <Input maxLength={50} type="text" name="cittaRif" id="cittaRif" value={arrayRiferimentiPersonaCitta[index]} placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiCitta(e.target.value,index)}}/>
                                                </div>
                                            </div>

                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <div style={{paddingRight:5, flex:1}}>
                                                    Provincia:<br/>
                                                    <Input maxLength={50} type="text" name="provinciaRif" id="provinciaRif" value={arrayRiferimentiPersonaProvincia[index]} placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiProvincia(e.target.value,index)}}/>
                                                </div>
                                                <div style={{flex:1}}>
                                                    CAP:
                                                    <Input maxLength={20} type="text" name="capRif" id="capRif" value={arrayRiferimentiPersonaCap[index]} placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiCap(e.target.value,index)}}/>
                                                </div>
                                            </div>

                                            <div style={{display:"flex", flexDirection:"row"}}>
                                                <div style={{paddingRight:5, flex:1}}>
                                                    Data di Nascita:<br/>
                                                    <Input maxLength={10} type="date" name="dataNascitaRif" id="dataNascitaRif"  value={arrayRiferimentiPersonaDataNascita[index]} innerRef={dataNascitaRif}
                                                    placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiDataNascita(e.target.value,index)}}/>
                                                </div>
                                                <div style={{flex:1}}>
                                                    Luogo di Nascita:
                                                    <Input maxLength={200} type="text" name="luogoNascitaoRif" id="luogoNascitaoRif" value={arrayRiferimentiPersonaLuogoNascita[index]} placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiLuogoNascita(e.target.value,index)}}/>
                                                </div>
                                            </div>
                                        </td>

                                        <td style={{padding:5,textAlign:"center"}}>
                                            <Input 
                                                maxLength={255}
                                                type="email" 
                                                name="emailRif" 
                                                id="emailRif" 
                                                value={arrayRiferimentiPersonaEmail[index]}
                                                placeholder=""
                                                onInput={(e)=>{
                                                    handleCambiamentoMod(e);
                                                    aggiornaRiferimentiEmail(e.target.value,index);
                                                    
                                                    /* PER INVIARE LA PASSWORD AI RIFERIMENTI, disabilitato:
                                                    // accende la checkbox ad ogni variazione, la spegne se la variazione del testo riporta la mail alla precedente
                                                    let flagInviaPasswordApp = [...arrayRiferimentiFlagInviaPasswordApp];
                                                    if (e.target.value!==arrayRiferimentiPersonaEmailPrecedente[index]) {
                                                        flagInviaPasswordApp[index] = true;
                                                    } else {
                                                        flagInviaPasswordApp[index] = false;
                                                    }
                                                    */
                                                    
                                                    flagInviaPasswordApp[index] = false;
                                                    
                                                    setArrayRiferimentiFlagInviaPasswordApp(flagInviaPasswordApp)
                                                }}
                                            />

                                            {/* PER INVIARE LA PASSWORD AI RIFERIMENTI, disabilitato:

                                            {arrayRiferimentiPersonaEmail[index]!==arrayRiferimentiPersonaEmailPrecedente[index] ?
                                                <FormGroup>
                                                    <Input className="ml-2 mt-3" type="checkbox" name={"flagInviaPasswordApp"+index} id={"flagInviaPasswordApp"+index}
                                                        checked = {arrayRiferimentiFlagInviaPasswordApp[index] ? 'checked' : ''}
                                                        placeholder=""
                                                        onInput={(e)=>{
                                                            handleCambiamentoMod(e);
                                                            let flagInviaPasswordApp = [...arrayRiferimentiFlagInviaPasswordApp];
                                                            flagInviaPasswordApp[index] = !flagInviaPasswordApp[index];
                                                            setArrayRiferimentiFlagInviaPasswordApp(flagInviaPasswordApp)
                                                        }}/> 
                                                    <Label 
                                                        className="ml-4 mt-2"
                                                        for={"flagInviaPasswordApp"+index}
                                                        style={{backgroundColor:arrayRiferimentiFlagInviaPasswordApp[index]?"#FF6060":"", color:arrayRiferimentiFlagInviaPasswordApp[index]?"#FFFFFF":"", paddingLeft:5, paddingRight:5}}
                                                    >
                                                        Invia a questo indirizzo la password<br/>per accedere alla APP                                                                
                                                    </Label>
                                                </FormGroup>                                                                
                                            :
                                                ""
                                            }
                                            
                                            {variazioneCampi==1 ? <></> :
                                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" 
                                                onClick={() => {setDisabilitaBottoni(true);setEmailInvioPasswordApp(arrayRiferimentiPersonaEmail[index]);setFlagModaleInvioPasswordApp(true)}} >Invia Password per APP</Button>
                                            }
                                            */}
                                        </td>

                                        <td style={{padding:5}}><Input type="textarea" rows={4} name="noteRif" id="noteRif" value={arrayRiferimentiPersonaNote[index]}
                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaRiferimentiNote(e.target.value,index)}}/></td>

                                        </tr>)
                                    }
                                    </tbody>
                                </Table>
                                <Button color="success" className="mt-2" onClick={(e) => {
                                        handleCambiamentoMod(e);
                                        aggiungiRiferimento()}} 
                                >Aggiungi Riferimento</Button>
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className='schedaSottotitolo'>
                            Posizioni e Cariche Sociali
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoPosizioni()}/>
                        </div>

                        <Row form>  
                            <Col md={12}>
                                                                                    
                                    <TabellaTendine
                                        titoloTendine="Posizione in Società"
                                        nomeCampoTendine="DESCRIZIONE"
                                        nomeCampoConDefaultLegatoValoreTendina="PERIODICITA"
                                        labelBottoneAggiungi="Aggiungi Posizione"
                                        callbacksetArrayValoriDefault={callbacksetArrayValoriPosizioniDefault} 
                                        callbackSetVariazioneCampi={setVariazioneCampi} 
                                        arrayValoriDefault={arrayPosizioniPersona}
                                        arrayDatiTendine={arrayPosizioni}
                                        arrayCampiAssociati={arrayCampiAssociatiPosizioni}
                                        arrayCampiAssociatiType={arrayCampiAssociatiPosizioniType}
                                        arrayCampiAssociatiDescrizione={arrayCampiAssociatiPosizioniDescrizione}
                                        arrayCampiAssociatiValoriDefault={arrayCampiAssociatiPosizioniValoriDefault}
                                        arrayCampiAssociatiNomeDatabase={arrayCampiAssociatiPosizioniNomeDatabase}
                                        arrayCampiAssociatiValoriPossibili={arrayCampiAssociatiPosizioniValoriPossibili}
                                        callbackAggiornaCampo={callbackAggiornaCampo}
                                        flagModifica = {flagModificaPosizioni}
                                    ></TabellaTendine>

                                    {flagModificaPosizioni === 0 ?
                                        <Button color="success" className="mt-2" onClick={() => {setFlagModificaPosizioni(1)}}>Modifica Posizioni</Button>
                                    :""}

                            </Col>
                        </Row>

                        <Row form> 
                            <Col md={12}>
                                <div className='schedaSottotitolo'>
                                    Raggruppamenti di Appartenenza
                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoRaggruppamenti()}/>
                                </div>

                                <TabellaTendine        
                                    titoloTendine="Raggruppamenti"                                
                                    nomeCampoTendine="DESCRIZIONE"
                                    labelBottoneAggiungi="Aggiungi Raggruppamento"
                                    callbacksetArrayValoriDefault={callbacksetArrayValoriDefault} 
                                    callbackSetVariazioneCampi={setVariazioneCampi} 
                                    arrayValoriDefault={arrayGruppiPersona}
                                    arrayDatiTendine={arrayGruppi}
                                    arrayCampiAssociatiDescrizione={[]}    
                                    flagModifica = {flagModificaRaggruppamenti}                                
                                ></TabellaTendine>
                                {flagModificaRaggruppamenti === 0 ?
                                    <Button color="success" className="mt-2" onClick={() => {setFlagModificaRaggruppamenti(1)}}>Modifica Raggruppamenti</Button>
                                :""}
                            </Col>
                        </Row>
                        
                        <div className='schedaSottotitolo'>
                            Informazioni Mediche
                        </div>
                        {/* div contenente informazioni mediche e certificato medico */}
                        <div style={{display:"flex", flexDirection:"row"}}>
                            {/* div contenente informazioni mediche */}
                            <div style={{flex:1}}>
                                <Row form>
                                    <Col md={12}>
                                        <Row form> 
                                            <Col md={4}>
                                                <Input className="ml-2 mt-2" type="checkbox" 
                                                    checked = {flagRichiestaCertificato ? 'checked' :""}
                                                    onInput={(e)=>{setFlagRichiestaCertificato(!flagRichiestaCertificato);setVariazioneCampi(1)}}/>  
                                                <Label className="ml-4 mt-1">
                                                Certificato Medico Richiesto
                                                </Label>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="dataInizioCertMedico">Data Emissione Certificato Medico</Label>
                                                    {props.ope === "inserisci" || props.ope === "modifica" ?
                                                    <Input maxLength={10} type="date" name="dataInizioCertMedico" id="dataInizioCertMedico"  defaultValue={dataInizioCertMedicoDefault} innerRef={dataInizioCertMedico}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataInizioCertMedicoDefault(dataInizioCertMedico.current.value)}}/> 
                                                        :""}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="dataFineCertMedico">Data Scadenza Certificato Medico</Label>
                                                    {props.ope === "inserisci" || props.ope === "modifica" ?
                                                    <Input  maxLength={10} type="date" name="dataFineCertMedico" id="dataFineCertMedico"  defaultValue={dataFineCertMedicoDefault} innerRef={dataFineCertMedico}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataFineCertMedicoDefault(dataFineCertMedico.current.value)}}/> 
                                                        :""}
                                                </FormGroup>
                                            </Col>
                                        </Row>   


                                        <Row form>  
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="noteMediche">Note Mediche</Label>
                                                    {props.ope === "inserisci" || props.ope === "modifica" ?
                                                    <Input type="textarea" rows={4} name="noteMediche" id="noteMediche"  defaultValue={noteMedicheDefault} innerRef={noteMediche}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNoteMedicheDefault(noteMediche.current.value)}}/> 
                                                        :""}
                                                </FormGroup>
                                            </Col>
                                        </Row>    
                                        <Row form> 
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="noteIntolleranze">Intolleranze</Label>
                                                    {props.ope === "inserisci" || props.ope === "modifica" ?
                                                    <Input type="textarea" rows={4} name="noteIntolleranze" id="noteIntolleranze"  defaultValue={noteIntolleranzeDefault} innerRef={noteIntolleranze}
                                                        placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNoteIntolleranzeDefault(noteIntolleranze.current.value)}}/> 
                                                        :""}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>   
                                </Row>
                            </div> {/* fine div contenente informazioni mediche */}

                            <div style={{paddingLeft:10}}> {/* div contenente certificato medico */}
                                <Label for="certificato medico">
                                    Certificato medico
                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoCertMedico(true)}/>
                                </Label>
                                <VisualizzaAllegato
                                    cssContenitore="btnimg"
                                    cssImmagine="immagineAnagrafica"
                                    cssPdf="pdfAnagrafica"
                                    cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                    scalaPdf={props.dimensioniApp.x/3000}
                                    contenutoFile={contenutoFileCertMedico}
                                    tipoFile={typeFileCertMedico}
                                    estensioneFile={estensioneFileCertMedico}
                                    estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp,.pdf,.odt,.doc,.docx"}
                                    flagContenutoModificato={flagCertMedicoModificato}
                                    callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile("certMedico",e.target.files[0]);}}
                                    callbackScarica={() => scaricaFile("Certificato Medico " + componiPersona + "." + estensioneFileCertMedico,contenutoFileCertMedico)}
                                    callbackElimina={() => setFlagModaleConfermaCancCertMedico(true)}
                                />                                                                     
                            </div> {/* fine div contenente certificato medico */}
                        </div> {/* fine div contenente informazioni mediche e certificato medico */}


                            <div className='schedaSottotitolo'>
                            Informazioni Privacy
                            </div>
                            
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <div style={{flex:1}}>
                                    <Row form>
                                        <Col md={12}>
                                            <Row form> 
                                                <Col md={5}>
                                                    <Input className="ml-2 mt-2" type="checkbox" 
                                                        checked = {flagRichiestaPrivacy ? 'checked' :""}
                                                        onInput={(e)=>{setFlagRichiestaPrivacy(!flagRichiestaPrivacy);setVariazioneCampi(1)}}/>  
                                                    <Label className="ml-4 mt-1">
                                                    Documento Privacy Richiesto
                                                    </Label>
                                                </Col>
                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Label for="dataFinePrivacy">Data Scadenza Documento Privacy</Label>
                                                        {props.ope === "inserisci" || props.ope === "modifica" ?
                                                        <Input  maxLength={10} type="date" name="dataFinePrivacy" id="dataFinePrivacy"  defaultValue={dataFinePrivacyDefault} innerRef={dataFinePrivacy}
                                                            placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setDataFinePrivacyDefault(dataFinePrivacy.current.value)}}/> 
                                                            :""}
                                                    </FormGroup>
                                                </Col>
                                            </Row>   
                                        </Col>   
                                    </Row>
                                    </div>
                                   

                                    {arrayVociPrivacy.length>0 ?
                                        <div style={{paddingLeft:10}}> 
                                        {arrayVociPrivacy.map((elemento,index) => <div key={index}>
                                        <Row form> 
                                            <Col md={12}>
                                            <Input className="ml-2 mt-2" type="checkbox" defaultChecked = {parseInt(impostazioniPrivacy[index]) == 1 ? 'checked' :""} 
                                                onInput={(e)=>{aggiornaImpostazioniPrivacy(e.target.checked,index);setVariazioneCampi(1)}}/>
                                            <Label className="ml-4 mt-1">{arrayVociPrivacy[index]}</Label>
                                            </Col>
                                        </Row>
                                        </div>)
                                        }
                                        </div>
                                    :""}
                            </div>

                        <div className='schedaSottotitolo'>
                                Informazioni Generiche
                        </div>
                        {/* div contenente informazioni generiche e manleva */}
                        <div style={{display:"flex", flexDirection:"row"}}>
                            {/* div contenente informazioni generiche */}
                            <div style={{flex:1}}>
                            {/*<Row form>
                                <Col md={12}>
                                    <FormGroup>
                                    <Input className="ml-2 mt-2" type="checkbox" name="flagRiceveNotifiche" id="flagRiceveNotifiche"
                                        defaultChecked = {props.ope === "inserisci" || flagRiceveNotificheDefault === "1" ?  'checked' :""}
                                        innerRef={flagRiceveNotifiche}                                 
                                        placeholder=""
                                        onInput={(e)=>{handleCambiamentoMod(e);setFlagRiceveNotificheDefault(e.target.value)}}/>  
                                    <Label className="ml-4 mt-1" for="flagRiceveNotifiche">
                                        Riceve notifiche su APP
                                    </Label>
                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoRiceveNotifiche()}/>
                                    </FormGroup>                                        
                                </Col>
                            </Row>*/}                                    
                            <Row form>
                                <Col md={12}>
                                    <Row form>  
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="note">Note</Label>
                                                
                                                <Input type="textarea" rows={15} name="note" id="note"  defaultValue={noteDefault} innerRef={note}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value)}}/> 
                                            
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                </Col>
                            </Row>
                            </div> {/* fine div contenente informazioni generiche */}

                            <div style={{paddingLeft:10}}> {/* div contenente manleva */}
                                <Label for="manleva">
                                    Manleva per minore
                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoManleva(true)}/>
                                </Label>
                                <VisualizzaAllegato
                                    cssContenitore="btnimg"
                                    cssImmagine="immagineAnagrafica"
                                    cssPdf="pdfAnagrafica"
                                    cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                    scalaPdf={props.dimensioniApp.x/3000}
                                    contenutoFile={contenutoFileManleva}
                                    tipoFile={typeFileManleva}
                                    estensioneFile={estensioneFileManleva}
                                    estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp,.pdf,.odt,.doc,.docx"}
                                    flagContenutoModificato={flagManlevaModificata}
                                    callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile("manleva",e.target.files[0]);}}
                                    callbackScarica={() => scaricaFile("Manleva " + componiPersona + "." + estensioneFileManleva,contenutoFileManleva)}
                                    callbackElimina={() => setFlagModaleConfermaCancManleva(true)}
                                />
                            </div> {/* fine div contenente manleva */}
                        </div> {/* fine div contenente informazioni generiche e manleva */}                                
                            
                    </>
                    }
                    
                </div>

                <div className="schedaSpazioInferiore">
                    {props.ope === "inserisci" ? 
                        <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>
                            Inserisci
                        </Button>
                    :""}
                    {props.ope === "modifica" && variazioneCampi === 1? 
                        <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>
                            Memorizza Modifiche
                        </Button>
                    :""}

                    <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                    {(variazioneCampi === 1 && props.ope === "modifica") ? <Fragment> Annulla </Fragment>
                    : 
                        props.provvenienza && props.provvenienza === "schedaAbbonato" ?
                            <Fragment>Torna indietro</Fragment>
                        :
                            <Fragment>Torna all'elenco</Fragment>
                    }
                    </Button>
                </div>

            </div>
            :""}
        </Fragment>
    );
}
export default FormGridFormRow;

