import React, {useEffect, useState} from 'react';
import { Button } from 'reactstrap';
import Parametri from '../../parametri';
import TrasformaDateAUmano from '../TrasformaDateAUmano';
import FormattaImportoVideo from '../FormattaImportoVideo';
import Clessidra from '../Clessidra.js';

const STATO_RICERCA_DATI = 0;
const STATO_VISUALIZZA = 1;
const STATO_OPERAZIONE_FALLITA = 2;
const STATO_ATTESA_ALTRO_CLIENT = 3;
const STATO_ATTESA_SCANSIONE = 4;
const STATO_ASSEGNAZIONE_RIUSCITA = 5;
const STATO_ASSEGNAZIONE_SCADUTA = 6;

function SchedaAssociaTessera(props) {
    const [statoPagina,setStatoPagina]=useState(STATO_VISUALIZZA);
    const [idpersona,setIdpersona]=useState(-1);
    const [cognomeNome,setCognomeNome]=useState("");
    const [numeroTessera,setNumeroTessera]=useState("");
    const [elenco,setElenco]=useState([]);
    const [countdown,setCountdown]=useState(-1);

    useEffect(() => {
        elencoPersone();
    },[]);

    useEffect(() => {
        console.log("cambio pagina",statoPagina)
        if (statoPagina == STATO_ATTESA_SCANSIONE) {
            verificaAssegnazioneTessera();
        }
    }, [statoPagina])

    function richiediAssegnazioneTessera() {
        setStatoPagina(STATO_RICERCA_DATI);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                JSON.stringify({op:"richiediAssegnazioneTessera",
                idpersona: idpersona,
                emak: props.emak,
                sessionId:props.sessionId
            })       
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===undefined) { 
                    alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione==-1) {
                    alert("Persona non selezionata, selezionare la persona e poi premere Assegna");
                    setStatoPagina(STATO_VISUALIZZA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione<=0) {
                    alert("Impossibile effettuare l'operazione richiedi_assegnazione_tessera, riprovare o contattare l'assistenza tecnica "+valoreDiRitorno.risultatoOperazione);
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===2) {
                    setStatoPagina(STATO_ATTESA_ALTRO_CLIENT);
                    return;
                }
                setElenco(valoreDiRitorno.risultatoRitorno);
                setCountdown(20);
                setStatoPagina(STATO_ATTESA_SCANSIONE);
            },
            (error) => {
                alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                setStatoPagina(STATO_OPERAZIONE_FALLITA);
            }
        );
    }

    function verificaAssegnazioneTessera() {
        console.log("verificaAssegnazioneTessera")        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                JSON.stringify({op:"verificaAssegnazioneTessera",
                idpersona: idpersona,
                emak: props.emak,
                sessionId:props.sessionId
            })       
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===undefined) { 
                    alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione==-1) {                    
                    setStatoPagina(STATO_ASSEGNAZIONE_SCADUTA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione<=0) {
                    alert("Impossibile effettuare l'operazione richiedi_assegnazione_tessera, riprovare o contattare l'assistenza tecnica "+valoreDiRitorno.risultatoOperazione);
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1) {
                    setNumeroTessera(valoreDiRitorno.numero_tessera);
                    setStatoPagina(STATO_ASSEGNAZIONE_RIUSCITA);
                    return;
                }
                // fa di nuovo questa funzione tra 3 secondi, se sono passati meno di 30 secondi (su backend scade entro 20)
                if (countdown>0) {
                    setCountdown(countdown-3);
                    setTimeout(verificaAssegnazioneTessera, 3000);
                    return;
                } else {
                    setStatoPagina(STATO_ASSEGNAZIONE_SCADUTA);
                    return;
                }
            },
            (error) => {
                alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                setStatoPagina(STATO_OPERAZIONE_FALLITA);
            }
        );
    }

    function elencoPersone() {
        setStatoPagina(STATO_RICERCA_DATI);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                JSON.stringify({op:"elencoPersone",
                ridotto: 1,
                emak: props.emak,
                sessionId:props.sessionId
            })       
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===undefined) { 
                    alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione<=0) {
                    alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                    setStatoPagina(STATO_OPERAZIONE_FALLITA);
                    return;
                }            
                if(valoreDiRitorno.risultatoOperazione===2) {
                    setStatoPagina(STATO_ATTESA_ALTRO_CLIENT);
                    return;
                }
                setElenco(valoreDiRitorno.risultatoRitorno);                
                setStatoPagina(STATO_VISUALIZZA);
            },
            (error) => {
                alert("Impossibile effettuare l'operazione, riprovare o contattare l'assistenza tecnica");
                setStatoPagina(STATO_OPERAZIONE_FALLITA);
            }
        );
    }

    if (statoPagina===STATO_RICERCA_DATI) {
        return (<Clessidra loading={true}/>)
    }

    if (statoPagina===STATO_OPERAZIONE_FALLITA) {
        return (<div>Operazione fallita</div>)
    }

    if (statoPagina==STATO_VISUALIZZA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Assegnazione Tessera per Accesso
                    </div>

                    Seleziona a chi assegnare la tessera e premi "Assegna" in fondo alla pagina:<br/>

                    <select onChange={(e) => {
                        const parts = e.target.value.split(',');
                        setIdpersona(parts[0]);
                        setCognomeNome(parts[1]);
                    }}><option value="-1,"></option>
                    {elenco.map((elemento, indice)=> (
                        <option key={indice} value={elemento.ID+","+elemento.COGNOME+" "+elemento.NOME}>{elemento.COGNOME} {elemento.NOME} ({elemento.NUMERO_TESSERA})</option>
                    ))}
                    </select>
                </div>

                <div className="schedaSpazioInferiore">
                    <Button className="mt-2" color="primary" onClick={() => { richiediAssegnazioneTessera() }}>Assegna</Button>
                </div>
            </div>             
        )
    }        

    if (statoPagina===STATO_ATTESA_ALTRO_CLIENT) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Assegnazione Tessera per Accesso
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:"#FF8080", border:"1px solid #905050", textAlign:'center', padding:"10px"}}>Un altro client sta effettuando la stessa operazione, riprova tra un minuto</div>
                </div>
            </div>
        )
    }

    if (statoPagina===STATO_ATTESA_SCANSIONE) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Assegnazione Tessera per Accesso
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:"#C6F0F0", border:"1px solid #509090", textAlign:'center', padding:"10px"}}>
                        <div style={{backgroundColor:"#FFA500"}}>Passa la tessera</div>
                        da assegnare a {cognomeNome}<br/>sul lettore entro 20 secondi
                        <div style={{backgroundColor:"#FFA500"}}>Non chiudere questa finestra,<br/>attendi comunque l'esito</div>
                    </div>
                </div>
            </div>
        )
    }
    
    if (statoPagina===STATO_ASSEGNAZIONE_RIUSCITA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Assegnazione Tessera per Accesso
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:"#80F080", border:"1px solid #509050", textAlign:'center', padding:"10px"}}>
                        <div style={{backgroundColor:"#206020", color:"#FFFFFF"}}>Assegnazione completata</div>
                        Tessera {numeroTessera}<br/>assegnata a {cognomeNome}
                    </div>
                </div>
            </div>
        )
    }

    if (statoPagina===STATO_ASSEGNAZIONE_SCADUTA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale">
                    <div className='schedaSottotitolo'>
                        Assegnazione Tessera per Accesso
                    </div>
                    <br/><br/>
                    <div style={{backgroundColor:"#F08080", border:"1px solid #509050", textAlign:'center', padding:"10px"}}>
                        <div style={{backgroundColor:"#602020", color:"#FFFFFF"}}>ASSEGNAZIONE NON EFFETTUATA</div>
                        per tempo scaduto, riprova
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div>Errore 1 SchedaAssociaTessera</div>
    )
}
export default SchedaAssociaTessera;
