import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Input } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaIscritto/FormGridFormRow';
import FormGridFormRow2 from '../SchedaRinnovamentoMassivoTesseram/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';

import Clessidra from '../Clessidra.js';

import controllaDate from '../controllaDate';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
//const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_RINNOVA= 6;


function TabellaReportTesseramenti(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;

    let SMeseInf=parseInt(sMese)-3;
    let SAnnoInf=today.getFullYear();
    if(SMeseInf<1){
      //ho cambiato anno:
      SMeseInf=12+SMeseInf;
      SAnnoInf = SAnnoInf-1;
    }
    SMeseInf = String(SMeseInf);
    if(SMeseInf.length === 1) SMeseInf = "0"+SMeseInf;
    let dataCorretta = controllaDate(SAnnoInf,SMeseInf,sGiorno);
    const dataOggiMeno3Mesi = dataCorretta;

    let sMese1 = String(today.getMonth()+1);
    if(sMese1.length === 1) sMese1 = "0"+sMese1;
    let sGiorno1 = String(today.getDate());
    if(sGiorno1.length === 1) sGiorno1 = "0"+sGiorno1;
    let SMeseSup=parseInt(sMese1)+3;

    let SAnnoSup=today.getFullYear();
    if(SMeseSup>12){
      SMeseSup=SMeseSup-12;
      SAnnoSup = SAnnoSup+1;
    }
    SMeseSup = String(SMeseSup);
    if(SMeseSup.length === 1) SMeseSup = "0"+SMeseSup;
    let dataCorretta1 = controllaDate(SAnnoSup,SMeseSup,sGiorno1);

    const dataOggiPiu3Mesi = dataCorretta1;

    const operazione = "reportTesseramenti";
    const  dataPartenza = useRef();
    const  dataArrivo = useRef();
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
    const [idModifica,setIdModifica]=useState('');
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(dataOggiMeno3Mesi);
    const [dataArrivoDefault,setDataArrivoDefault]=useState(dataOggiPiu3Mesi);

    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME'
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME'
      },
      {
        etichetta: 'N. Tessera',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Ente',
        accessor: 'ENTE'
      },
      {
        etichetta: 'N. Tessera Ente',
        accessor: 'NUMERO_TESSERA_ENTE'
      },
      {
        etichetta: 'Tipo',
        accessor: 'TIPO_TESSERAMENTO'
      },
      {
        etichetta: 'Categoria',
        accessor: 'CATEGORIA'
      },
      {
        etichetta: 'Data Inizio',
        accessor: 'DATA_INIZIO'
      },
      {
        etichetta: 'Data Fine',
        accessor: 'DATA_FINE'
      },
    ];
    const arrayTipiDati=[];
    arrayTipiDati[8]="data";
    arrayTipiDati[9]="data";

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    function callbackRinnTesseramenti() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            dataPartenza:dataPartenzaDefault,
            dataArrivo:dataArrivoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                setStatoPagina(STATO_OPERAZIONE_CORRETTA);
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])
    //},[props.numPagina])

    useEffect(() => {
      setStatoPagina(STATO_RICERCA_DATI);  
    },[dataPartenzaDefault,dataArrivoDefault])

  return (
      <div>
     
      {statoPagina === STATO_VAI_IN_RINNOVA ? 
      
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda inserimento massivo e/o rinnovamento tesseramenti in corso...</h6></div>
          </div>
        }>
  
        <FormGridFormRow2 
        {...props}
        ope={"rinnova"} 
        callbackRinnTesseramenti={callbackRinnTesseramenti} 
        sessionId={props.sessionId}></FormGridFormRow2>
        </Suspense>
      </div>
      :""} 
      

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda iscritto con tesseramento scaduto o in via di scadenza in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_TesScad</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          <CardBody>
            <CardTitle>Elenco Iscritti Con Tesseramenti in scadenza dal&nbsp;
            <input type="date" defaultValue={dataPartenzaDefault} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}/>
            &nbsp;al&nbsp;
            <input type="date" defaultValue={dataArrivoDefault} onChange={(e)=>{setDataArrivoDefault(e.target.value)}}/>
              &nbsp;

              {/*<Input type="date" name="dataPartenza" id="dataPartenza"  value={dataPartenzaDefault} innerRef={dataPartenza}
                placeholder="" onInput={(e)=>{setDataPartenzaDefault(dataPartenza.current.value)}}/> 
              &nbsp;e&nbsp;
              <Input type="date" name="dataArrivo" id="dataArrivo"  value={dataArrivoDefault} innerRef={dataArrivo}
      placeholder="" onInput={(e)=>{setDataArrivoDefault(dataArrivo.current.value)}}/> */}
            </CardTitle>
                              
            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_RINNOVA)}>Inserimento e/o Rinnovamento Massivo</Button>
          </CardBody> 
                            
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca columns={colonne} data={risultatoServer} arrayTipiDati={arrayTipiDati}  campoChiave={0} campoOrdinamento={9} nascondiColonna={0} callbackSelezionaElemento={VaiModifica}/>
          : 
            <Table responsive bordered hover striped className="mb-0"><thead><tr><th>Nessun dato presente</th></tr></thead></Table>
          }   
        </div>
      :""}
    </div>
   );
}
export default TabellaReportTesseramenti;