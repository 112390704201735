import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaNumeroVideo from '../FormattaNumeroVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';
import FormattaNumeroDb from '../FormattaNumeroDb.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import VisualizzaAllegato from '../VisualizzaAllegato.js';

import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Resizer from "react-image-file-resizer";
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagModaleInfoFoto, setFlagModaleInfoFoto] = React.useState(false);
    const [flagModaleConfermaCancFoto, setFlagModaleConfermaCancFoto] = React.useState(false);
    const [flagAttivaModaleArticoloNonModificabile, setFlagAttivaModaleArticoloNonModificabile] = useState(0);
    const [flagAttivaModaleArticoloNonInseribile, setFlagAttivaModaleArticoloNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleDimensioneFoto, setFlagAttivaModaleDimensioneFoto] = useState(0);
    const [flagAttivaModaleImponibileErrato, setFlagAttivaModaleImponibileErrato] = useState(0);
    const [flagAttivaModaleIvaErrata, setFlagAttivaModaleIvaErrata] = useState(0);
    const [flagAttivaModaleNumeroErrato, setFlagAttivaModaleNumeroErrato] = useState(0);
    const [fineSpazio, setFineSpazio] = useState(0);

    const numero   = useRef();
    const prezzo   = useRef();
    const iva   = useRef();
    //const categoria   = useRef();
    const descrizione   = useRef();
    const flagVendita   = useRef();
    //const foto  = useRef();
    const flagEliminaFotoRif   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaCategoria,setStatoTendinaCategoria]=useState(false);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    const [risultatoServerDatiCategorie,setRisultatoServerDatiCategorie]=useState('');

    const [numeroDefault,setNumeroDefault]=useState('');
    const [prezzoDefault,setPrezzoDefault]=useState('');
    const [ivaDefault,setIvaDefault]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [flagVenditaDefault,setFlagVenditaDefault]=useState('');
    const [fotoDefault,setFotoDefault]=useState('');

    const [typeFoto,setTypeFoto]=useState('');

    const [insCategoriaEdi,setInsCategoriaEdi]=useState('');

    //per le foto:
    const [contenutoFile,setContenutoFile]=useState('');
    const [dimensioneFoto,setDimensioneFoto]=useState('');
    const [dimensioneMassimaFoto,setDimensioneMassimaFoto]=useState('');
    const [flagEliminaFoto,setFlagEliminaFoto]=useState('');
    const [estensioneFileFoto, setEstensioneFileFoto] = useState('');
    const [flagFotoModificata, setFlagFotoModificata] = useState(false);

    const [anteprima,setAnteprima]=useState('');
    const [typeAnteprima,setTypeAnteprima]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaCategoria(false);
    },[]);

    useEffect(() => {
        if(statoTendinaCategoria === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaCategoria]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("articoliIns");
        if(props.ope==='modifica') setOperazione("articoliMod");
        if(props.ope==='cancella') setOperazione("articoliCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"articoliRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setLoading(true);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setInsCategoriaEdi(valoreDiRitorno.risultatoRitorno[0].CATEGORIA);
                    setNumeroDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO);
                    setPrezzoDefault(valoreDiRitorno.risultatoRitorno[0].PREZZO);
                    setIvaDefault(valoreDiRitorno.risultatoRitorno[0].IVA);
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setFlagVenditaDefault(valoreDiRitorno.risultatoRitorno[0].FLAG_DISP_VENDITA);
                    setFotoDefault(valoreDiRitorno.risultatoRitorno[0].FOTO);
                    setEstensioneFileFoto(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FOTO);
                    setContenutoFile(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeFoto(valoreDiRitorno.risultatoRitorno[0].TYPE);

                    ricercaDatiCategorie();
                    }
                    },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiCategorie();
        }
    },[props.ope]);

    useEffect(() => { 
        if(flagEliminaFoto === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaFoto]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagModaleInfoFoto(false);
        setFlagModaleConfermaCancFoto(false);
        setFlagAttivaModaleArticoloNonModificabile(0);
        setFlagAttivaModaleArticoloNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleDimensioneFoto(0);
        setFlagAttivaModaleImponibileErrato(0);
        setFlagAttivaModaleIvaErrata(0);
        setFlagAttivaModaleNumeroErrato(0);
        setFineSpazio(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
            //console.log("Inizio"+prezzo.current.value)
            //console.log("ela"+FormattaImportoDb(prezzo.current.value))
            if(prezzo.current.value===''){
                prezzo.current.value = 0;
            }else{
                if(isNaN(FormattaImportoDb(prezzo.current.value))){
                    controlli = 1;
                    setFlagAttivaModaleImponibileErrato(1);
                }
            }
            
            if(iva.current.value===''){
                iva.current.value = 0;
            }else{
                if(isNaN(FormattaImportoDb(iva.current.value))){
                    controlli = 1;
                    setFlagAttivaModaleIvaErrata(1);
                }
            }
            if(numero.current.value===''){
                numero.current.value = 0;
            }else{
                if(isNaN(FormattaImportoDb(numero.current.value))){
                    controlli = 1;
                    setFlagAttivaModaleNumeroErrato(1);
                }
            }
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                /*const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? JSON.stringify({op:operazione,
                        numero:numero.current.value,
                        soglia:soglia.current.value,
                        prezzo:FormattaImportoDb(prezzo.current.value),
                        iva:FormattaImportoDb(iva.current.value),
                        categoria:insCategoriaEdi,
                        descrizione:descrizione.current.value,
                        flagVendita:flagVendita.current.checked,
                        flagAttivo:flagAttivo.current.checked,
                        foto:foto.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };*/
                //aggiorno con post senza json:

                setLoading(true);
                const formData = new FormData();
                if (props.ope!=='cancella'){
                    formData.append('op', operazione);
                    formData.append('numero',FormattaNumeroDb(numero.current.value));
                    formData.append('prezzo',FormattaImportoDb(prezzo.current.value));
                    formData.append('iva',FormattaImportoDb(iva.current.value));
                    formData.append('categoria',insCategoriaEdi);
                    formData.append('descrizione',descrizione.current.value);
                    formData.append('flagVendita',flagVendita.current.checked);
                    if (flagFotoModificata) {
                        formData.append('contenutoFileFoto', contenutoFile);
                        formData.append('estensioneFileFoto', estensioneFileFoto);
                        formData.append('typeFileFoto', typeFoto);
                    }
                    //formData.append('contenutoFile', contenutoFile);
                    formData.append('flagEliminaFoto', flagEliminaFoto);

                    formData.append('idElemento', props.id);
                    formData.append('emak', props.emak);
                    formData.append('sessionId', props.sessionId); 
                }else{
                    formData.append('op', operazione);
                    formData.append('idElemento', props.id);
                    formData.append('emak', props.emak);
                    formData.append('sessionId', props.sessionId);
                }

                const requestOptions = {
                    method: 'POST',
                    body: formData
                }
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                /*if(valoreDiRitorno.mess.indexOf("Dato non cancellabile: presente in ") !== -1){
                                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModaleClasseNonCancellabile(true);
                                } */
                                if(valoreDiRitorno.mess.indexOf("Dato non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleArticoloNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleArticoloNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dimensione foto articolo troppo elevata") !== -1){
                                    setFlagAttivaModaleDimensioneFoto(1);
                                    setDimensioneFoto(valoreDiRitorno.dimFoto);
                                    setDimensioneMassimaFoto(valoreDiRitorno.dimMaxFoto);
                                    setContenutoFile('');
                                    setTypeFoto('');
                                    setFotoDefault('');
                                    setContenutoFile('');
                                    setAnteprima('');
                                    setTypeAnteprima('');
                                    setFlagEliminaFoto("");
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo errato") !== -1){
                                    setFlagAttivaModaleImponibileErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iva errata") !== -1){
                                    setFlagAttivaModaleIvaErrata(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Numero errato") !== -1){
                                    setFlagAttivaModaleNumeroErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Spazio Terminato") !== -1){
                                    setFineSpazio(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    function ricercaDatiCategorie() {
        //ricerco i dati delle categorie che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoCatArticoli",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiCategorie(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaCategoria(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function scaricaFile(nomeFile, contenuto) {
        const link = document.createElement('a');
        link.href = contenuto;
        link.setAttribute('download',nomeFile);     
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();      
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    function aggiornaFile(valore){
        setVariazioneCampi(1);
        let typeFile = '';
        if (valore.type.substring(0, 5) === "image") {
            var nuovoTipo = valore.type.substring(6);
            if (nuovoTipo.toUpperCase()!="JPG" && nuovoTipo.toUpperCase()!="JPEG" && nuovoTipo.toUpperCase()!="PNG") {
                nuovoTipo = "JPEG";
                typeFile = "image/jpeg";
            }else{
                typeFile = valore.type;
            }

            try {
                Resizer.imageFileResizer(
                    valore,
                    600,
                    600,
                    nuovoTipo,
                    100,
                    0,
                    (uri) => {
                        setContenutoFile(uri);
                    },
                    "base64"
                );                
            } catch (err) {
                // console.log(err);
                alert("Si è verificato un errore nel ridimensionamento dell'immagine, contattare l'assistenza tecnica");
            }
        } else {            
            setContenutoFile(valore)
        }
        const estensione = valore.name.split('.').pop();
        setFlagFotoModificata(true); setFlagEliminaFoto(""); setTypeFoto(typeFile); setEstensioneFileFoto(estensione);
    }
    function funzioneEliminaFoto(valore){
        //setVariazioneCampi(1);
        handleCambiamentoMod();
        setFlagEliminaFoto(valore);
        setTypeFoto('');
        setContenutoFile('');
    }

    /*function aggiornaFile(valore){
        setVariazioneCampi(1);
        setContenutoFile(valore);
        setFotoDefault("");
        setTypeFoto('');
        setFlagEliminaFoto("");
        setAnteprima(URL.createObjectURL(valore));
        setTypeAnteprima(valore.type);
    }
    function funzioneEliminaFoto(valore){
        setFlagEliminaFoto(valore);
        setContenutoFile('');
        setTypeFoto('');
        setFotoDefault('');
        setContenutoFile('');
        setAnteprima('');
        setTypeAnteprima('');
        setStatoPagina(STATO_MEMORIZZAZIONE);
    }*/
   
    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
   
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Art {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleDimensioneFoto === 1 ?
                        <Modale 
                            titolo="Dimensione eccessiva foto"
                            flagErrore={true}
                            contenuto={<div>La foto ha una dimensione troppo elevata:<br/>rilevata {dimensioneFoto} KB, massima accettata {dimensioneMassimaFoto} KB</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleArticoloNonModificabile === 1 ?
                        <Modale 
                            titolo="Informativa articolo non modificabile"
                            flagErrore={true}
                            contenuto={<div>La descrizione dell'articolo non è modificabile in {descrizione.current.value} poichè nel database è già presente un articolo con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleArticoloNonInseribile === 1 ?
                        <Modale 
                            titolo="Informativa articolo non inseribile"
                            flagErrore={true}
                            contenuto={<div>L'articolo {descrizione.current.value} non è inseribile in poichè nel database è già presente un articolo con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}                

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleImponibileErrato===1 ?
                        <Modale 
                            titolo="Informativa imponibile errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'imponibile non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleIvaErrata===1 ?
                        <Modale 
                            titolo="Informativa iva errata"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'iva non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}
                    {flagAttivaModaleNumeroErrato===1 ?
                        <Modale 
                            titolo="Informativa numero errato"
                            flagErrore={true}
                            contenuto={<div>Il numero di articoli inserito non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleInfoFoto === true ?
                        <Modale 
                            titolo="Foto dell'articolo"
                            flagErrore={false}
                            contenuto="La foto può essere inserita in formato: jpg, jpeg, gif, png, webp"
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleConfermaCancFoto === true ?
                    <Modale 
                        titolo="Conferma eliminazione"
                        flagErrore={false}
                        contenuto="Confermi l'eliminazione della foto?"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setFlagModaleConfermaCancFoto(false); funzioneEliminaFoto("on")}
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                            }
                        ]}
                    />
                    :""}
                    {fineSpazio === 1 ?
                        <Modale 
                        titolo="Informativa spazio terminato"
                        flagErrore={true}
                        contenuto={<div>Lo spazio dati è terminato</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Articolo</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Articolo</CardTitle> :""}
                
                       
                                <Clessidra loading={loading}/>

                                {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione dell'articolo <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :


                                <>
                                {/* div contenente dati esercizio e foto */}
                                <div style={{display:"flex", flexDirection:"row"}}>
                                    {/* div contenente dati esercizio */}
                                    <div style={{flex:1}}>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="descrizione">Descrizione*</Label>
                                                        <Input maxLength={200} type="textarea" name="descrizione" id="descrizione"
                                                        innerRef={descrizione} 
                                                        placeholder=""
                                                        defaultValue={descrizioneDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>   
                                            <Col md={12}>
                                                <FormGroup>
                                                <Label>Categoria</Label>    
                                                <TendinaEditabile
                                                    //titolo={"Categoria"}
                                                    nomeCampo="DESCRIZIONE"
                                                    labelCampoVuoto=""
                                                    valoreDefault={insCategoriaEdi}
                                                    arrayValori={risultatoServerDatiCategorie}
                                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                                    callbackSetValoreTendEditabile={setInsCategoriaEdi}
                                                ></TendinaEditabile>
                                                </FormGroup>
                                            </Col>
                                        </Row>    
                                        <Row form> 
                                            <Col md={6}>
                                                <FormGroup>
                                                {/*checked= {props.ope === "inserisci" || (props.ope === "modifica" && attivoDefault ==1) ?  'checked' :""}  */}
                                                    <Input className="ml-2 mt-2" type="checkbox" name="flagVendita" id="flagVendita"
                                                    defaultChecked = {props.ope === "inserisci" || flagVenditaDefault === "1" ?  'checked' :""}
                                                    innerRef={flagVendita}                                 
                                                    placeholder=""
                                                    onInput={(e)=>{handleCambiamentoMod(e);setFlagVenditaDefault(e.target.value);}}/>  
                                                <Label className="ml-4 mt-2" for="flagVendita">Disponibile alla vendita</Label>
                                                
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="numero">Numero di oggetti in giacenza</Label>
                                                        <Input maxLength={12} type="text" name="numero" id="numero"
                                                        innerRef={numero} 
                                                        placeholder=""
                                                        defaultValue={FormattaNumeroVideo(numeroDefault)}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setNumeroDefault(numero.current.value)}}/>  
                                                </FormGroup>
                                            </Col>
                                        </Row> 

                                        <Row form>  
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="prezzo">Prezzo</Label>
                                                        <Input maxLength={12} type="text" name="prezzo" id="prezzo"
                                                        innerRef={prezzo} 
                                                        placeholder=""
                                                        defaultValue={FormattaImportoVideo(prezzoDefault,1)}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setPrezzoDefault(prezzo.current.value)}}/>  
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="iva">IVA</Label>
                                                        <Input maxLength={12} type="text" name="iva" id="iva"
                                                        innerRef={iva} 
                                                        placeholder=""
                                                        defaultValue={FormattaImportoVideo(ivaDefault,1)}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setIvaDefault(iva.current.value)}}/>  
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    
                                    </div> {/* fine div contenente dati articolo */}

                                        <div style={{paddingLeft:10}}> {/* div contenente foto*/}
                                                <Label for="foto">
                                                    Foto Articolo
                                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoFoto(true)}/>
                                                </Label>
                                                <VisualizzaAllegato
                                                    cssContenitore="btnimg"
                                                    cssImmagine="immagineAnagrafica"
                                                    cssPdf="pdfAnagrafica"
                                                    cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                                    scalaPdf={props.dimensioniApp.x/3000}
                                                    contenutoFile={contenutoFile}
                                                    tipoFile={typeFoto}
                                                    estensioneFile={estensioneFileFoto}
                                                    estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp"}
                                                    flagContenutoModificato={flagFotoModificata}
                                                    callbackAggiornaFile={(e) => {handleCambiamentoMod(e);aggiornaFile(e.target.files[0]);}}
                                                    callbackScarica={() => scaricaFile("Foto." + estensioneFileFoto,contenutoFile)}
                                                    callbackElimina={() => setFlagModaleConfermaCancFoto(true)}
                                                /> 
                                        </div>
                                    </div>    

                                    {/*
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={12}>
                                            <div class="wrapper">
                                                <Fragment>
                                                <div class="btnimg">
                                                    
                                                    {typeFoto === "application/pdf" || typeFoto === "text/plain" ? 
                                                        <embed className="immagineM" src={"data:" + typeFoto + ";base64," + contenutoFile}  type={typeFoto}/>  
                                                    :""}
                                                    {typeFoto.substring(0, 5) === "image" ?
                                                        <img className="immagineM" src={"data:" + typeFoto + ";base64," + contenutoFile} />
                                                    :""}
                                                   
                                                    {typeAnteprima.substring(0, 5) === "image" ?
                                                        <img className="immagineM" src={anteprima}/>
                                                    :""}
                                                    {typeAnteprima === "application/pdf" || typeAnteprima === "text/plain" ? 
                                                        <embed className="immagineM" src={anteprima}/>
                                                    :""}
                                                </div>
                                                <Label for="foto">(formati ammessi: jpg, jpeg, gif, png, webp)</Label>
                                                
                                                <input type='file' className='bottone' accept=".jpg,.jpeg,.gif,.png,.webp" 
                                                    
                                                    onChange={(e) => {aggiornaFile(e.target.files[0]);}}/>
                                                </Fragment>
                                            </div>
                                            </Col>
                                        </Row> 
                                        <Row form> 
                                            {fotoDefault !== "" ? 
                                                <Col md={6}>
                                                <a download={"Articolo " + descrizioneDefault} href={"data:" + typeFoto + ";base64," + contenutoFile}>Scarica file</a>
                                                </Col>
                                            :""}
                                        
                                            <Col md={6}>
                                                {fotoDefault !== '' || contenutoFile!=='' ?
                                                <Fragment>
                                                <Input className="ml-2 mt-2" type="checkbox" name="flagEliminaFoto" id="flagEliminaFoto"
                                                    innerRef={flagEliminaFotoRif}                                 
                                                    placeholder=""
                                                    onInput={(e)=>{handleCambiamentoMod(e);funzioneEliminaFoto(e.target.value)}}/>
                                                    <Label className="ml-4 mt-2">Elimina foto</Label></Fragment>
                                                :""}
                                            </Col>
                                        </Row>      
                                    </Col>
                                </Row>
                                </Fragment>*/}
                                </>
                                }
                                
                                
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                               
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            


                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
