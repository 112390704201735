import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Form } from 'reactstrap';

const ModaleRicerca = ({ campiRicercaAvanzata, criteriIniziali, onConferma, onAnnulla }) => {
    const [criteri, setCriteri] = useState([
        { campoDatabase: '', criterio: 'contiene', dato: '' },
        { campoDatabase: '', criterio: 'contiene', dato: '' },
        { campoDatabase: '', criterio: 'contiene', dato: '' }
    ]);

    const handleCriterioChange = (index, key, value) => {
        const newCriteri = [...criteri];
        newCriteri[index][key] = value;
        setCriteri(newCriteri);
    };

    const handleConferma = () => {
        onConferma(criteri);
    };

    const handleAnnulla = () => {
        onAnnulla();
    };

    useEffect (() => {
        if (criteriIniziali) setCriteri(criteriIniziali);
    }, [criteriIniziali])

    return (
        <Modal isOpen={true}>
            <ModalHeader>Ricerca Avanzata</ModalHeader>
            <ModalBody style={{backgroundColor:"#F0F0F0"}}>
                <Form>
                    {criteri.map((criterio, index) => (
                        <FormGroup key={index}>
                            <b>CONDIZIONE {index+1}</b><br/>
                            <Input
                                type="select"
                                style={{backgroundColor:"#FFFFFF"}}
                                className="form-control mt-2" 
                                id={`campoDatabase-${index}`}
                                value={criterio.campoDatabase}
                                onChange={(e) => handleCriterioChange(index, 'campoDatabase', e.target.value)}
                            >
                                <option value="">Seleziona un campo</option>
                                {campiRicercaAvanzata.map((campo) => (
                                    <option key={campo.campoDatabase} value={campo.campoDatabase}>
                                        {campo.etichetta}
                                    </option>
                                ))}
                            </Input>
                            <Input
                                type="select"
                                style={{backgroundColor:"#FFFFFF"}}
                                className="form-control mt-2" 
                                id={`criterio-${index}`}
                                value={criterio.criterio}
                                onChange={(e) => handleCriterioChange(index, 'criterio', e.target.value)}
                            >
                                <option value="contiene">contiene</option>
                                <option value="maggiore di">maggiore di</option>
                                <option value="minore di">minore di</option>
                            </Input>
                            <div className="mt-2" style={{display:"flex"}}>
                            <Label for={`valore-${index}`} className="mt-2">Valore:&nbsp;</Label>
                            <Input
                                type="text"
                                style={{backgroundColor:"#FFFFFF"}}
                                id={`dato-${index}`}
                                value={criterio.dato}
                                onChange={(e) => handleCriterioChange(index, 'dato', e.target.value)}
                            />
                            </div>
                        </FormGroup>
                    ))}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleConferma}>Conferma</Button>{' '}
                <Button color="secondary" onClick={handleAnnulla}>Annulla ricerca</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModaleRicerca;