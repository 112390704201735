import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';


const STATO_INDEFINITO = 0;
const STATO_SCARICA_PDF = 1;
const STATO_PREPARA_PDF = 2;
const STATO_ERRORE_CONNESSIONE = 3;

// fornire props.tipo: "associativa" o "abbonamento"
function StampaTutteLeTessere(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [loading,setLoading]= useState(false);

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    //const [nomeSocieta,setNomeSocieta]=useState('');
    const [nomeSocieta1,setNomeSocieta1]=useState('');
    const [nomeSocieta2,setNomeSocieta2]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');

    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocietaIntestazione,setIndirizzoSocietaIntestazione]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [ricercaLayoutTessere,setRicercaLayoutTessere]=useState('');
    const [arrayLayoutTessere,setArrayLayoutTessere]=useState([]);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);

    const [stringaGruppiPersona,setStringaGruppiPersona]=useState('');
    const [stringaPosizioniPersona,setStringaPosizioniPersona]=useState('');

    const [settataStringaPosizioniPersona,setSettataStringaPosizioniPersona]=useState(0);
    const [settataStringaGruppiPersona,setSettataStringaGruppiPersona]=useState(0);
    const [settataTesseraNumero,setSettataTesseraNumero]=useState(0);
    
    const [coloreSfondo,setColoreSfondo]=useState('');
    const [coloreTesto,setColoreTesto]=useState('');
    const [radiusBordo,setRadiusBordo]=useState('');

    const [tesseraNumero,setTesseraNumero]=useState('');

    const [cognomeNome,setCognomeNome]=useState('');

    const [accediAScarica,setAccediAScarica]=useState(0);

    const [arrayCognomeNome,setArrayCognomeNome]=useState([]);
    const [arrayCognome,setArrayCognome]=useState([]);
    const [arrayNome,setArrayNome]=useState([]);
    const [arrayDataNascita,setArrayDataNascita]=useState([]);
    const [arrayGenere,setArrayGenere]=useState([]);
    const [arrayNumeroTessera,setArrayNumeroTessera]=useState([]);
    const [arrayTypeFoto,setArrayTypeFoto]=useState([]);
    const [arrayContenutoFoto,setArrayContenutoFoto]=useState([]);
    const [arrayPosizioni,setArrayPosizioni]=useState([]);
    const [arrayRaggruppamenti,setArrayRaggruppamenti]=useState([]);
    const [arrayQrcode,setArrayQrcode]=useState([]);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
        if(statoPagina === STATO_PREPARA_PDF && ricercaLayoutTessere === 1 && ricercaLogo=== 1){
            let massimaLunghezzaStringaNumeroTessera = 38;
            let massimaLunghezzaStringaCognomeNome = 40;
            let massimaLunghezzaStringaGruppi = 110;
            let massimaLunghezzaStringaPosizioni = 55;

            if(arrayLayoutTessere[10] === 1){
                //se ho scelto di vedere la foto devo ridurre ulteriormente i dati che compariranno vicino, quindi
                massimaLunghezzaStringaGruppi = 70;
                massimaLunghezzaStringaPosizioni = 37;
                massimaLunghezzaStringaNumeroTessera = 21;
            } 

            //qui entro per settare le ultime cose che derivano dalla lettura dei dati nel db e del completamento conseguente di arrayLayoutTessere:

            setColoreSfondo(arrayLayoutTessere[13]);
            setColoreTesto(arrayLayoutTessere[16]);

            if(arrayLayoutTessere[14] === 0) setRadiusBordo("0px");
            if(arrayLayoutTessere[14] === 1) setRadiusBordo("8px");
            if(arrayLayoutTessere[14] === 2) setRadiusBordo("20px");

            //se ho nel layout anche i gruppi e/o le posizioni devo per forza fare una nuova query e pure lunga perche mette in relazione le
            //persone con le tabelle contenente tali dati, in caso constrario recupero i dati dalle props che mi derivano dall'elenco persone

            let arrLocaleCognome=[];
            let arrLocaleNome=[];
            let arrLocalestringaCognomeNome=[];
            let arrLocaleDataNascita=[];
            let arrLocaleGenere=[];
            let arrLocaleTesseraNumero=[];
            let arrLocaleTypeFoto=[];
            let arrLocaleContenutoFoto=[];
            let arrLocalePosizioni=[];
            let arrLocaleRaggruppamenti=[];
            let arrLocaleQrcode=[];
        
            //if(arrayLayoutTessere[7] === 1 || arrayLayoutTessere[9] === 1 || arrayLayoutTessere[10] === 1 || arrayLayoutTessere[5] === 1 || arrayLayoutTessere[6] === 1)  {
                //necessaria nuova fetch per il recupero anche dei dati di raggruppamenti e posizioni in società e foto e data di nascita e genere
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                    op:"elencoIscrittiConGruppiEPosizioni",
                    dataOggi : dataOggi,
                    emak: props.emak,
                    sessionId:props.sessionId})
                };
      
                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
//console.log("Fine richiesta")                      
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione===1){
//console.log("OK")
                        
                        
                        for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                            arrLocaleCognome[i]=valoreDiRitorno.risultatoRitorno[i].COGNOME;
                            setArrayCognome(arrLocaleCognome);
                            arrLocaleNome[i]=valoreDiRitorno.risultatoRitorno[i].NOME;
                            setArrayNome(arrLocaleNome);

                            arrLocaleDataNascita[i]=valoreDiRitorno.risultatoRitorno[i].DATA_NASCITA;
                            setArrayDataNascita(arrLocaleDataNascita);
                            
                            if(valoreDiRitorno.risultatoRitorno[i].SESSO==='1') arrLocaleGenere[i]="M";
                            else arrLocaleGenere[i]="F";
                            setArrayGenere(arrLocaleGenere);

                            if(valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA!==undefined && valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA.length>massimaLunghezzaStringaNumeroTessera){
                                arrLocaleTesseraNumero[i]=valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA.substring(0,massimaLunghezzaStringaNumeroTessera) + '...'
                            }else arrLocaleTesseraNumero[i]=valoreDiRitorno.risultatoRitorno[i].NUMERO_TESSERA;
                            setArrayNumeroTessera(arrLocaleTesseraNumero);

                            arrLocaleTypeFoto[i]=valoreDiRitorno.risultatoRitorno[i].TYPE_FOTO;
                            setArrayTypeFoto(arrLocaleTypeFoto);
                            arrLocaleContenutoFoto[i]=valoreDiRitorno.risultatoRitorno[i].FOTO;
                            setArrayContenutoFoto(arrLocaleContenutoFoto);

                            //ed ora i raggruppamenti e le posizioni:
                            if(valoreDiRitorno.risultatoRitorno[i].RAGGRUPPAMENTI!==undefined){
                                for (let j=0; j<valoreDiRitorno.risultatoRitorno[i].RAGGRUPPAMENTI.length ; j++) {
                                    if(arrLocaleRaggruppamenti[i] === undefined) arrLocaleRaggruppamenti[i] = valoreDiRitorno.risultatoRitorno[i].RAGGRUPPAMENTI[j];
                                    else arrLocaleRaggruppamenti[i] = arrLocaleRaggruppamenti[i]+","+valoreDiRitorno.risultatoRitorno[i].RAGGRUPPAMENTI[j];
                                }
                            }

                            if(valoreDiRitorno.risultatoRitorno[i].POSIZIONI!==undefined){
                                for (let j=0; j<valoreDiRitorno.risultatoRitorno[i].POSIZIONI.length ; j++) {
                                    if(arrLocalePosizioni[i] === undefined) arrLocalePosizioni[i] = valoreDiRitorno.risultatoRitorno[i].POSIZIONI[j];
                                    else arrLocalePosizioni[i] = arrLocalePosizioni[i]+","+valoreDiRitorno.risultatoRitorno[i].POSIZIONI[j];
                                }
                            }
                            arrLocaleQrcode[i]=valoreDiRitorno.risultatoRitorno[i].QRCODE;
                            //console.log("go"+valoreDiRitorno.risultatoRitorno[i].QRCODE)
                            setArrayQrcode(arrLocaleQrcode);
                        } 
                        
                        
                        //elaboro i dati
                        if(arrLocaleCognome !== undefined){
                            for (let i=0; i<arrLocaleCognome.length ; i++) {
                                let stringaCognomeNome = '';
                                stringaCognomeNome = arrLocaleCognome[i];
                                if(stringaCognomeNome !== undefined){
                                    if(arrLocaleNome[i] !== '') stringaCognomeNome = arrLocaleCognome[i]+" "+arrLocaleNome[i];
                                    if(stringaCognomeNome.length>massimaLunghezzaStringaCognomeNome){
                                        arrLocalestringaCognomeNome[i] = stringaCognomeNome.substring(0,massimaLunghezzaStringaCognomeNome) + '...';
                                    }else arrLocalestringaCognomeNome[i] = stringaCognomeNome;
                                    setArrayCognomeNome(arrLocalestringaCognomeNome);
                                }

                                //elaboro anche la stringa dei raggruppamenti e delle posizioni:
                                
                                if(arrLocaleRaggruppamenti[i] !== undefined){
                                    let stringaGruppiCorretta = arrLocaleRaggruppamenti[i];
                                    if(stringaGruppiCorretta.length>massimaLunghezzaStringaGruppi)
                                        arrLocaleRaggruppamenti[i] = stringaGruppiCorretta.substring(0,massimaLunghezzaStringaGruppi) + '...';
                                    else arrLocaleRaggruppamenti[i] = stringaGruppiCorretta;

                                    setArrayRaggruppamenti(arrLocaleRaggruppamenti);
                                }

                                if(arrLocalePosizioni[i] !== undefined){
                                    let stringaPosizioniCorretta = arrLocalePosizioni[i];
                                    if(stringaPosizioniCorretta.length>massimaLunghezzaStringaPosizioni)
                                        arrLocalePosizioni[i] = stringaPosizioniCorretta.substring(0,massimaLunghezzaStringaPosizioni) + '...';
                                    else arrLocalePosizioni[i] = stringaPosizioniCorretta;
                                    setArrayPosizioni(arrLocalePosizioni);
                                }

                            }
                        }
                        setAccediAScarica(1);
                    }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            /*}else{
                //non avendo nel layout i raggruppamanti o le posizioni o foto o data di nascita o genere, ho già tutto il necessario:

                for (let j=0; j<props.columns.length ; j++) {
                    //console.log("Colonna"+props.columns[j].accessor);
                    for (let i=0; i<props.data.length ; i++) {
                        if(props.columns[j].accessor==='COGNOME'){
                            arrLocaleCognome[i]=props.data[i].COGNOME;
                            setArrayCognome(arrLocaleCognome);
                        } 
                        if(props.columns[j].accessor==='NOME'){
                            arrLocaleNome[i]=props.data[i].NOME;
                            setArrayNome(arrLocaleNome);
                        } 
                        if(props.columns[j].accessor==='NUMERO_TESSERA'){
                            if(props.data[i].NUMERO_TESSERA!==undefined && props.data[i].NUMERO_TESSERA.length>massimaLunghezzaStringaNumeroTessera){
                                arrLocaleTesseraNumero[i]=props.data[i].NUMERO_TESSERA.substring(0,massimaLunghezzaStringaNumeroTessera) + '...'
                            }else arrLocaleTesseraNumero[i]=props.data[i].NUMERO_TESSERA;
                            setArrayNumeroTessera(arrLocaleTesseraNumero);
                        } 
                    }
                }
                //elaboro i dati
                if(arrLocaleCognome !== undefined){
                    for (let i=0; i<arrLocaleCognome.length ; i++) {
                        let stringaCognomeNome = '';
                        stringaCognomeNome = arrLocaleCognome[i];
                        if(stringaCognomeNome!== undefined){
                            if(arrLocaleNome[i] !== '') stringaCognomeNome = arrLocaleCognome[i]+" "+arrLocaleNome[i];
                            if(stringaCognomeNome.length>massimaLunghezzaStringaCognomeNome){
                                arrLocalestringaCognomeNome[i] = stringaCognomeNome.substring(0,massimaLunghezzaStringaCognomeNome) + '...';
                            }else arrLocalestringaCognomeNome[i] = stringaCognomeNome;
                            setArrayCognomeNome(arrLocalestringaCognomeNome);
                        }
                    }
                }
                setAccediAScarica(1);
            }*/
        }
        if(statoPagina === STATO_PREPARA_PDF && accediAScarica===1){
            //ultimo step prima di arrivare al pdf
            //console.log(props.contenutoFileFoto)
            setStatoPagina(STATO_SCARICA_PDF);
            //console.log("Foto"+props.contenutoFileFoto)
        } 
    },[statoPagina,ricercaLayoutTessere,ricercaLogo,settataStringaPosizioniPersona,settataStringaGruppiPersona,settataTesseraNumero,accediAScarica]);

    useEffect(() => {
        setStatoPagina(STATO_PREPARA_PDF);
        scaricaLogo();
        scaricaLayoutTessere();
    },[]);

    function scaricaLogo() {
        let massimaLunghezzaStringaIndirizzoSocieta = 40;
        let massimaLunghezzaStringaNomeSocieta = 48;
        let massimaLunghezzaStringaNomeSocietaPrimaRiga = 30;
        let massimaLunghezzaStringaNomeSocietaSecondaRiga = 30;
        let arrayNomeSocieta=[];
        let stringaPrimaRigaNomeSocieta='';
        let stringaSecondaRigaNomeSocieta='';
        let rigaAttuale = 1;
        let finito = 0;

        //console.log("scaricaLogo");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {          
              if(valoreDiRitorno.risultatoOperazione===1){
                  if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                    let indirizzoLoc = '';
                    setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);

                    if(valoreDiRitorno.risultatoRitorno[0].NOME!== undefined && valoreDiRitorno.risultatoRitorno[0].NOME.length>massimaLunghezzaStringaNomeSocietaPrimaRiga){
                        //taglio il nome in modo da non troncare le parole:
                        arrayNomeSocieta = valoreDiRitorno.risultatoRitorno[0].NOME.split(" ");
                        //ora aggiungo pezzi alla prima riga fino ad arrivare al massimo concesso:
                        for (let i=0; i<arrayNomeSocieta.length ; i++) {
                            if(i===0 && stringaPrimaRigaNomeSocieta.length+arrayNomeSocieta[i].length>massimaLunghezzaStringaNomeSocietaPrimaRiga){
                                //devo per forza troncare perchè ho solo una parola che supera la lunghezza max riga
                                setNomeSocieta1(arrayNomeSocieta[i].substring(0,massimaLunghezzaStringaNomeSocieta) + '...');
                                finito = 1;
                            }
                            if(((i>0 && stringaPrimaRigaNomeSocieta.length+arrayNomeSocieta[i].length>massimaLunghezzaStringaNomeSocietaPrimaRiga) || rigaAttuale===2) && finito === 0){
                                //passo alla riga 2:
                                rigaAttuale = 2;
                                //controllo di non suparare il limite della seconda riga:
                                if(stringaSecondaRigaNomeSocieta.length+arrayNomeSocieta[i].length<=massimaLunghezzaStringaNomeSocietaSecondaRiga){
                                    stringaSecondaRigaNomeSocieta = stringaSecondaRigaNomeSocieta+" "+arrayNomeSocieta[i];
                                }else{
                                    finito = 1;
                                }
                            }else{
                                if(finito === 0){
                                    //continuo ad aggiungere alla prima riga:
                                    stringaPrimaRigaNomeSocieta = stringaPrimaRigaNomeSocieta+" "+arrayNomeSocieta[i];
                                }
                            }
                        }
                        setNomeSocieta1(stringaPrimaRigaNomeSocieta);
                        setNomeSocieta2(stringaSecondaRigaNomeSocieta);
                    } else{
                        setNomeSocieta1(valoreDiRitorno.risultatoRitorno[0].NOME);
                    } 
                    setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoSocietaIntestazione(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                    setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);


                    if(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO !== ''){
                        indirizzoLoc = valoreDiRitorno.risultatoRitorno[0].INDIRIZZO;
                    }
                    if(valoreDiRitorno.risultatoRitorno[0].CITTA !== ''){
                        if(indirizzoLoc!= undefined && indirizzoLoc.length>0) indirizzoLoc = indirizzoLoc + " ";
                        indirizzoLoc = indirizzoLoc+valoreDiRitorno.risultatoRitorno[0].CITTA;
                    }

                    if(indirizzoLoc!= undefined && indirizzoLoc.length>massimaLunghezzaStringaIndirizzoSocieta && indirizzoLoc!==undefined) setIndirizzoSocieta(indirizzoLoc.substring(0,massimaLunghezzaStringaIndirizzoSocieta) + '...');
                    else setIndirizzoSocieta(indirizzoLoc);
                  }
                  setRicercaLogo(1);
              }
          },
          (error) => {console.log("Errore connessione");}
        );        
    }
   
    function scaricaLayoutTessere(){
        //vado a fare la fetch di ricerca delle impostazioni del layout tessere:
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            op:"impostazioniLayoutTessere",
            ricercaImpostazioni : 1,
            emak: props.emak,
            sessionId: props.sessionId})
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          

            if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale=[];
                arrLocale[0]=parseInt(valoreDiRitorno.risultatoRitorno[0].LOGO_SOCIETA);
                arrLocale[1]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_LOGO_SOCIETA);
                arrLocale[2]=parseInt(valoreDiRitorno.risultatoRitorno[0].DENOMINAZIONE_SOCIETA);
                arrLocale[3]=parseInt(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO_SOCIETA);
                arrLocale[4]=parseInt(valoreDiRitorno.risultatoRitorno[0].COGNOME_NOME_ISCRITTO);
                arrLocale[5]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_NASCITA_ISCRITTO);
                arrLocale[6]=parseInt(valoreDiRitorno.risultatoRitorno[0].GENERE_ISCRITTO);
                arrLocale[7]=parseInt(valoreDiRitorno.risultatoRitorno[0].RAGGRUPPAMENTI_ISCRITTO);
                arrLocale[8]=parseInt(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                arrLocale[9]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONI_SOCIALI_ISCRITTO);
                arrLocale[10]=parseInt(valoreDiRitorno.risultatoRitorno[0].FOTO_ISCRITTO);
                arrLocale[11]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_FOTO_ISCRITTO);
                arrLocale[12]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_RILASCIO_TESSERA);
                arrLocale[13]=valoreDiRitorno.risultatoRitorno[0].COLORE_SFONDO;
                arrLocale[14]=parseInt(valoreDiRitorno.risultatoRitorno[0].BORDO_ARROTONDATO);
                arrLocale[15]=parseInt(valoreDiRitorno.risultatoRitorno[0].ELIMINA_INTESTAZIONE);
                arrLocale[16]=valoreDiRitorno.risultatoRitorno[0].COLORE_TESTO;
                console.log("Y"+valoreDiRitorno.risultatoRitorno[0].COLORE_TESTO)
                setArrayLayoutTessere(arrLocale);
                setRicercaLayoutTessere(1);
            }
        },
        (error) => {console.log("Errore connessione");}
        ); 
    }

    return (
        <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>
            {statoPagina === STATO_SCARICA_PDF ? 

                    <div>
                    {/*<PDFViewer style={styles.viewer}>*/}
                    <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
                        <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
                        <Document>
                        <Page size="A4" style={styles.page}> 
                        <View style={{flexDirection: "column"}}>
                        {arrayCognomeNome.map((elemento,index) => <Fragment key={index}>
                            
                        {index > 0 ? <View break><Text> </Text></View> : <View><Text> </Text></View>}{/*serve per cambio pagina: nota senon non metti lo spazio tra i text non funziona più fino in fondo*/}
                       
                        {arrayLayoutTessere[15] === 0 ?
                            <View style={styles.tableIntestazione}> 
                                <View wrap={false} style={styles.tableRowIntestazione}> 
                                    <View style={styles.tableColIntestazioneSx}> 
                                    <View style={styles.tableCell}> 
                                        {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                    </View> 
                                    </View> 
                                <View style={styles.tableColIntestazioneDx}>   
                                    <View style={styles.tableCellIntestazione}> 
                                    {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                                    {indirizzoSocietaIntestazione !== "" ?
                                    <Text style={styles.datisocieta}>{indirizzoSocietaIntestazione}
                                    {cittaSocieta !== "" ?
                                    capSocieta !== "" ?
                                    " "+capSocieta+" "+cittaSocieta
                                    :" "+cittaSocieta
                                    :""}
                                    </Text>
                                    :<Text></Text>}
                                    
                                    {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                    {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                    
                                    </View> 
                                </View> 
                                </View> 
                                <View style={{marginTop: 20,marginLeft: 40}}>
                                    <Text style={styles.titolo}>Tessera Sociale di {arrayCognome[index]} {arrayNome[index]}</Text>
                                </View>
                            </View> 
                        :""}


                            <View style={{marginTop: 100,marginLeft: 170,backgroundColor:coloreSfondo,color:coloreTesto,width:230,height:140,flexDirection: "column",border : "1px solid black",borderRadius : radiusBordo}}>
                                <View style={{flex:2,marginLeft: 3,marginRight: 3}}>
                                    {arrayLayoutTessere[0] === 1 && arrayLayoutTessere[1] === 0 ?
                                        <View style={{flexDirection: "row"}}>
                                            <View style={{flex:2}}>
                                                {fileLogo !== "" ? <Image style={styles.tesseraLogo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                            </View>  
                                            <View style={{flex:5,marginTop: 7}}> 
                                                {nomeSocieta1 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta1}</Text>:""}
                                                {nomeSocieta2 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta2}</Text>:""}
                                                {arrayLayoutTessere[3] === 1 ? <Text style={styles.tesseraIndirizzoSocieta}>{indirizzoSocieta}</Text>:""}   
                                            </View>
                                        </View> 
                                    :""}

                                    {arrayLayoutTessere[0] === 1 && arrayLayoutTessere[1] === 1 ?
                                        <View style={{flexDirection: "row"}}>
                                            <View style={{flex:5,marginTop: 7}}> 
                                                {nomeSocieta1 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta1}</Text>:""}
                                                {nomeSocieta2 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta2}</Text>:""}
                                                {arrayLayoutTessere[3] === 1 ? <Text style={styles.tesseraIndirizzoSocieta}>{indirizzoSocieta}</Text>:""}   
                                            </View>
                                            <View style={{flex:2}}>
                                                {fileLogo !== "" ? <Image style={styles.tesseraLogo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                            </View>  
                                        </View> 
                                    :""}

                                    {arrayLayoutTessere[0] === 0 ?
                                        <View style={{marginTop: 7}}> 
                                            {nomeSocieta1 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta1}</Text>:""}
                                            {nomeSocieta2 !== "" ? <Text style={styles.tesseraNomesocieta}>{nomeSocieta2}</Text>:""}
                                            {arrayLayoutTessere[3] === 1 ? <Text style={styles.tesseraIndirizzoSocieta}>{indirizzoSocieta}</Text>:""}   
                                        </View>
                                    :""}
                                </View>
    
                                <View style={{flex:4,marginLeft: 3,marginRight: 3}}>

                                    <View><Text style={styles.tesseraDatiIscrittoGrassetto}>{arrayCognomeNome[index]}</Text></View>

                                    {arrayLayoutTessere[10] === 1 ?
                                        arrayLayoutTessere[11] === 0 ?
                                            <View style={{flexDirection: "row"}}>
                                                <View style={{flex:1}}>
                                                    {arrayTypeFoto[index]!==undefined && arrayTypeFoto[index].substring(0, 5) === "image" && arrayContenutoFoto[index]!=='' && arrayTypeFoto[index]!==undefined?
                                                        <Image style={styles.tesseraFoto} src={arrayContenutoFoto[index]} />
                                                    :<Text></Text>}
                                                </View>
                                                <View style={{flex:2}}>
                                                {arrayLayoutTessere[5] === 1 && arrayDataNascita[index]!==''? <View><Text style={styles.tesseraDatiIscritto}>Data di Nascita: <TrasformaDateAUmano data={arrayDataNascita[index]}></TrasformaDateAUmano></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[6] === 1 ? <View> {arrayGenere[index]==='M' ?<Text style={styles.tesseraDatiIscritto}>Genere: M</Text>:<Text style={styles.tesseraDatiIscritto}>Genere: F</Text>}</View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[7] === 1 && arrayRaggruppamenti[index]!== undefined && arrayRaggruppamenti[index].length!==0? <View><Text style={styles.tesseraDatiIscritto}>{arrayRaggruppamenti[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[9] === 1 && arrayPosizioni[index]!== undefined && arrayPosizioni[index].length!==0? <View><Text style={styles.tesseraDatiIscritto}>{arrayPosizioni[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[8] === 1 && arrayNumeroTessera[index]!=='' ? <View><Text style={styles.tesseraDatiIscritto}>Tessera n. {arrayNumeroTessera[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}                   
                                                {arrayLayoutTessere[12] === 1 ? <View><Text style={styles.tesseraDatiIscritto}>Data rilascio: <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                </View>
                                            </View>
                                        :
                                            <View style={{flexDirection: "row"}}>
                                                <View style={{flex:2}}>
                                                {arrayLayoutTessere[5] === 1 && arrayDataNascita[index]!==''? <View><Text style={styles.tesseraDatiIscritto}>Data di Nascita: <TrasformaDateAUmano data={arrayDataNascita[index]}></TrasformaDateAUmano></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[6] === 1 ? <View> {arrayGenere[index]==='M' ?<Text style={styles.tesseraDatiIscritto}>Genere: M</Text>:<Text style={styles.tesseraDatiIscritto}>Genere: F</Text>}</View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[7] === 1 && arrayRaggruppamenti[index]!== undefined && arrayRaggruppamenti[index].length!==0? <View><Text style={styles.tesseraDatiIscritto}>{arrayRaggruppamenti[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[9] === 1 && arrayPosizioni[index]!== undefined && arrayPosizioni[index].length!==0? <View><Text style={styles.tesseraDatiIscritto}>{arrayPosizioni[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[8] === 1 && arrayNumeroTessera[index]!=='' ? <View><Text style={styles.tesseraDatiIscritto}>Tessera n. {arrayNumeroTessera[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}                   
                                                {arrayLayoutTessere[12] === 1 ? <View><Text style={styles.tesseraDatiIscritto}>Data rilascio: <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                </View>
                                                <View style={{flex:1}}>
                                                    {arrayTypeFoto[index]!==undefined && arrayTypeFoto[index].substring(0, 5) === "image" && arrayContenutoFoto[index]!=='' && arrayTypeFoto[index]!==undefined?
                                                        <Image style={styles.tesseraFoto} src={arrayContenutoFoto[index]} />
                                                    :<Text></Text>}
                                                </View>
                                            </View>
                                    :
                                        <>
                                        {arrayLayoutTessere[5] === 1 && arrayDataNascita[index]!==''? <View><Text style={styles.tesseraDatiIscritto}>Data di Nascita: <TrasformaDateAUmano data={arrayDataNascita[index]}></TrasformaDateAUmano></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[6] === 1 ? <View> {arrayGenere[index]==='M' ?<Text style={styles.tesseraDatiIscritto}>Genere: M</Text>:<Text style={styles.tesseraDatiIscritto}>Genere: F</Text>}</View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[7] === 1 && arrayRaggruppamenti[index]!== undefined && arrayRaggruppamenti[index].length!==0? <View><Text style={styles.tesseraDatiIscritto}>{arrayRaggruppamenti[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[9] === 1 && arrayPosizioni[index]!== undefined && arrayPosizioni[index].length!==0? <View><Text style={styles.tesseraDatiIscritto}>{arrayPosizioni[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                                {arrayLayoutTessere[8] === 1 && arrayNumeroTessera[index]!=='' ? <View><Text style={styles.tesseraDatiIscritto}>Tessera n. {arrayNumeroTessera[index]}</Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}                   
                                                {arrayLayoutTessere[12] === 1 ? <View><Text style={styles.tesseraDatiIscritto}>Data rilascio: <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>:<Text></Text></View>:<View><Text style={styles.tesseraDatiIscritto}> </Text></View>}
                                        </>
                                    }
                                </View> 
                                </View>

                                {/*qui lato b della tessera*/}
                                <View style={{marginTop: 100,marginLeft: 170,backgroundColor:coloreSfondo,color:coloreTesto,width:230,height:140,flexDirection: "column",border : "1px solid black",borderRadius : radiusBordo}}>
                                <View style={{flex:1}}>
                                    <View style={{flex:1,marginTop: 19,marginLeft: 64}}>
                                        {/*{arrayContenutoFoto[index] !== '' ? <Image style={styles.tesseraFoto} src={arrayContenutoFoto[index]} />:""}*/}
                                        {/*<Text>{arrayQrcode[index]}</Text>*/}
                                        <Image style={styles.tesseraQrcode} src={"data:image/png;base64," + arrayQrcode[index]} />
                                    
                                    </View>
                                </View>
                            </View>
         
                              
                                </Fragment>
                                )}  
                                
                            </View> 
                        </Page>

                    </Document>
                    </PDFViewer>
                </div>

            :""}
        </div>
    );
}
export default StampaTutteLeTessere;
