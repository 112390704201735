import React, {useState} from 'react';
import { Button, Input } from 'reactstrap';
import Clessidra from './Pagine/Clessidra.js';
import Parametri from './parametri';

const STATO_INS_DATI = 1;
const STATO_ATTESA = 2;
const STATO_ESITO = 3;

function CambioPassword(props) {
    const [stato, setStato] = useState(STATO_INS_DATI);    
    const [vecchiaPassword, setVecchiaPassword] = useState("");
    const [nuovaPassword1, setNuovaPassword1] = useState("");
    const [nuovaPassword2, setNuovaPassword2] = useState("");
    const [esito, setEsito] = useState("");

    function inviaDati(){        
        setStato(STATO_ATTESA);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"personaCambioPassword",vecchia:vecchiaPassword,nuova:nuovaPassword1,emak:props.emak,sessionId:props.sessionId})
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if(valoreDiRitorno.risultatoOperazione===-1) {
                    alert("Vecchia password errata: cambio password NON ESEGUITO. Correggere la vecchia password e riprovare") 
                    setStato(STATO_INS_DATI)
                    return; 
                }
                if(valoreDiRitorno.risultatoOperazione!==1){
                    alert("Operazione fallita. Riprovare e, se l'errore persiste, contattare l'assistenza tecnica");
                    setStato(STATO_INS_DATI)
                    return;
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setEsito("Cambio password effettuato");
                    setStato(STATO_ESITO);
                }
            },
                (error) => {alert("Operazione fallita. Riprovare e, se l'errore persiste, contattare l'assistenza tecnica");setStato(STATO_INS_DATI);}
            );
    }

    if (stato===STATO_ATTESA) return (<div><Clessidra loading={true}/></div>);
    if (stato===STATO_ESITO) return (
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale">
                <div className='schedaSottotitolo'>
                    Cambio Password
                </div>    
                {esito}
            </div>
        </div>
    );
    if (stato===STATO_INS_DATI) return (
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
            <div className="schedaSpazioCentrale">
                <div className='schedaSottotitolo'>
                    Cambio Password
                </div>                
                Vecchia password:
                <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="password" onInput={(e)=>{setVecchiaPassword(e.target.value)}}/></div>
                <br/>
                Nuova password (minimo 8 caratteri):
                <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="password" onInput={(e)=>{setNuovaPassword1(e.target.value)}}/></div>
                <br/>
                Nuova password (conferma):
                <div style={{paddingRight:5}}><Input maxLength={20} className="inputStandard" type="password" onInput={(e)=>{setNuovaPassword2(e.target.value)}}/></div>
                <br/>
                Compila i campi e poi premi il bottone in fondo alla pagina
            </div>

            <div className="schedaSpazioInferiore">
                <Button className="mt-2" color="primary" disabled={nuovaPassword1!==nuovaPassword2 || nuovaPassword1.length<8 || vecchiaPassword.length<1} onClick={() => { inviaDati()}}>Memorizza la nuova password</Button>
            </div>
        </div> 
    )      

    return (<div>Cambio Password: stato errato</div>)
}                    

export default CambioPassword;