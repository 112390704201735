import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setvariazioneCampi]=useState(0);

    const [flagAttivaModalePosizioneNonCancellabile, setFlagAttivaModalePosizioneNonCancellabile] = useState(0);
    const [flagAttivaModalePosizioneNonModificabile, setFlagAttivaModalePosizioneNonModificabile] = useState(0);
    const [flagAttivaModalePosizioneNonInseribile, setFlagAttivaModalePosizioneNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagModaleLibroSoci, setFlagModaleLibroSoci] = useState(0);
    const [flagModaleDirettivo, setFlagModaleDirettivo] = useState(0);
    

    const [presenteInQuoteAssociative,setPresenteInQuoteAssociative]=useState(0);
    const [presenteInPersone,setPresenteInPersone]=useState(0);

    const descrizione   = useRef();
    const flagDirettivo   = useRef();
    const flagLibroSoci   = useRef();
    
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');

    const [descrizioneDefault,setDescrizioneDefault] = useState('');
    const [periodicitaDefault,setPeriodicitaDefault] = useState('');
    const [flagDirettivoDefault,setFlagDirettivoDefault] = useState('');
    const [flagLibroSociDefault,setFlagLibroSociDefault] = useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const periodicitaPossibili=["Annuale","Semestrale","Quadrimestrale","Trimestrale","Bimestrale","Mensile"];

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        //___per radio:
        //setValueRadio("female");
    },[]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("posizioniSocietaIns");
        if(props.ope==='modifica') setOperazione("posizioniSocietaMod");
        if(props.ope==='cancella') setOperazione("posizioniSocietaCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"posizioniSocietaRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setPeriodicitaDefault(valoreDiRitorno.risultatoRitorno[0].PERIODICITA);
                    setFlagDirettivoDefault(valoreDiRitorno.risultatoRitorno[0].FLAG_DIRETTIVO);
                    setFlagLibroSociDefault(valoreDiRitorno.risultatoRitorno[0].FLAG_LIBRO_SOCI);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setvariazioneCampi(1);
    }

    function tornaScheda() {
        setFlagAttivaModalePosizioneNonCancellabile(0);
        setFlagAttivaModalePosizioneNonModificabile(0);
        setFlagAttivaModalePosizioneNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagModaleLibroSoci(0);
        setFlagModaleDirettivo(0);

        setPresenteInQuoteAssociative(0);
        setPresenteInPersone(0);
    }

    function fetchOperazioneServer(permessoCanc=0,bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
        }
        
        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        periodicita:periodicitaDefault,
                        flagDirettivo:flagDirettivo.current.checked,
                        flagLibroSoci:flagLibroSoci.current.checked,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        permessoCanc:permessoCanc,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Posizione non cancellabile: presente in quote associative") !== -1){
                                    setPresenteInQuoteAssociative(1);
                                    setFlagAttivaModalePosizioneNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Posizione non cancellabile: presente in persone") !== -1){
                                    setPresenteInPersone(1);
                                    setFlagAttivaModalePosizioneNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Posizione non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModalePosizioneNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Posizione non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModalePosizioneNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
    //{JSON.stringify(risultatoServerDatiQuoteAss)}
        return (
            <Fragment>
                
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1PosSoc{error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModalePosizioneNonCancellabile===1 ?
                        <Modale 
                            titolo="Informativa posizione non cancellabile"
                            flagErrore={true}
                            contenuto={<div>
                                {presenteInQuoteAssociative === 1 ?
                                <>La posizione selezionata non è cancellabile in quanto associata ad una o più quote associative</> :""}
                                {presenteInQuoteAssociative === 1 && presenteInPersone===1 ?
                                <>ed associata ad uno o più iscritti</> :""}
                                {presenteInQuoteAssociative === 0 && presenteInPersone===1 ?
                                <>La posizione selezionata non è cancellabile in quanto associata ad uno o più iscritti</> :""}
                                </div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModalePosizioneNonModificabile===1 ?
                        <Modale 
                            titolo="Informativa posizione non modificabile"
                            flagErrore={true}
                            contenuto={<div>La descrizione della posizione non è modificabile in {descrizione.current.value} poichè nel database è già presente una posizione con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModalePosizioneNonInseribile===1 ?
                        <Modale 
                            titolo="Informativa posizione non inseribile"
                            flagErrore={true}
                            contenuto={<div>La posizione {descrizione.current.value} non è inseribile in poichè nel database è già presente una posizione con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleDirettivo=== 1 ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Utilità del checkbox 'direttivo'"
                        flagErrore={false}
                        contenuto={<div>
                        Nella scheda della Posizione è possibile indicare se appartenente al Direttivo o meno, tramite l'apposito checkbox.<br/>
                        Successivamente, recandosi sulla scheda di un Iscritto (menu principale, Anagrafiche, Elenco Iscritti), nella sezione 'Posizioni e Cariche Sociali'
                        sarà possibile assegnare la Posizione all'Iscritto indicando le date di inizio ed eventualmente di fine assegnazione. <br/>
                        Se la Posizione appartiene al Direttivo, l'Iscritto in esame risulterà nell'elenco del Direttivo per il periodo di assegnazione indicato.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                :""}

                {flagModaleLibroSoci=== 1 ?
                        <Modale 
                            larghezza = "lg"
                            titolo="Utilità del checkbox 'Appartenente al libro soci'"
                            flagErrore={false}
                            contenuto={<div>
                            Nella scheda della Posizione è possibile indicare se appartenente al Libro Soci o meno, tramite l'apposito checkbox.<br/>
                            Successivamente, recandosi sulla scheda di un Iscritto (menu principale, Anagrafiche, Elenco Iscritti), nella sezione 'Posizioni e Cariche Sociali'
                            sarà possibile assegnare la Posizione all'Iscritto indicando le date di inizio ed eventualmente di fine assegnazione.<br/> 
                            Se la Posizione appartiene al Libro Soci, l'Iscritto in esame risulterà nel Libro Soci per il periodo di assegnazione indicato.</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}
                  
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Posizione in Società</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Posizione in Società</CardTitle> :""}
                       
                            
                            <Clessidra loading={loading}/>
                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della posizione in società <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                            

                                
                                <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                            
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value)}}/> 
              
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                <Row form> 
                                    
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label>Rateizzazione</Label>
                                            <select defaultValue={periodicitaDefault} className="form-control"  
                                                onInput={(e)=>{handleCambiamentoMod(e);setPeriodicitaDefault(e.target.value);}}>
                                                    {periodicitaPossibili.map((elementoPossibile,indexPossibile) =>
                                                        <option key={indexPossibile} value={periodicitaPossibili[indexPossibile]} >
                                                            {periodicitaPossibili[indexPossibile]}
                                                        </option>                                                   
                                                    )};
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                    <FormGroup>
                                        {/*{flagAllenatore.current ? <Label>eccolo {flagAllenatore.current.value}</Label> : ""}*/}
                                        <center>
                        
                                            <Input type="checkbox" name="flagDirettivo" id="flagDirettivo"
                                            defaultChecked = {parseInt(flagDirettivoDefault) === 1 ?  'checked' :""}
                                            innerRef={flagDirettivo}                                 
                                            placeholder=""
                                            onInput={(e)=>{handleCambiamentoMod(e);setFlagDirettivoDefault(e.target.value)}}/>  
                                   
                                        <Label for="flagDirettivo">Direttivo</Label>
                                        <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleDirettivo(1)}/>
                               
                                        </center>
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                        <center>
                        
                                            <Input type="checkbox" name="flagLibroSoci" id="flagLibroSoci"
                                            defaultChecked = {parseInt(flagLibroSociDefault) === 1 ?  'checked' :""}
                                            innerRef={flagLibroSoci}                                 
                                            placeholder=""
                                            onInput={(e)=>{handleCambiamentoMod(e);setFlagLibroSociDefault(e.target.value)}}/>  
                                   
                                        <Label for="flagLibroSoci">Appartenente al Libro Soci</Label>
                                        <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleLibroSoci(1)}/>
                                        </center>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }
                                
                                    
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            


                    </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
