import React, {Fragment,useState,useEffect,Suspense,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,Input } from 'reactstrap';
import Parametri from '../../parametri';
import Clessidra from '../Clessidra.js';
import TendinaEditabile from '../TendinaEditabile.js';
import { calcolaAnnoOggi } from '../../funzioni.js';
import Modale from '../Modale.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';


//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_SCEGLI_ABBONAMENTO = 2;
const STATO_SCEGLI_ABBONATI = 3;
const STATO_ERRORE_CONNESSIONE= 4;
const STATO_OPERAZIONE_FALLITA= 5;
const STATO_MOSTRA_REPORT = 6;
const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;

function TabellaAccessi(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;
    const dataOggiSoloAnnoMese = today.getFullYear()+'-'+sMese;
  
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
    const [loading,setLoading]= useState(false);
    const [testoRicercaPersone, setTestoRicercaPersone] = useState("");

    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [insAbbonamentoEdi,setInsAbbonamentoEdi]=useState('');//estremi abbonamento
    const [insIdAbbonamentoEdi,setInsIdAbbonamentoEdi]=useState('');//id abbonamento
    const [risultatoServerDatiAbbonamento,setRisultatoServerDatiAbbonamento]=useState('');
  
    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [risultatoServerDatiAbbonati,setRisultatoServerDatiAbbonati]=useState('');

    const [numeroPersoneSelezionate, setNumeroPersoneSelezionate] = useState(0);
    // array di boolean con tanti elementi quante sono tutte le persone: true per le persone selezionate da utente, per aggiungerle o rimuoverle
    const [personeSelezionate, setPersoneSelezionate] = useState([]);

    const [modaleNoSelezionati, setModaleNoSelezionati] = useState(false);
    const [modaleInfoSceltaAbbonamento, setModaleInfoSceltaAbbonamento] = useState(false);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(true);

    const [dataInizioDefault,setDataInizioDefault]=useState(dataOggiSoloAnnoMese);
    const dataInizio   = useRef();

    const [risultatoServerRegistrazione,setRisultatoServerRegistrazione]=useState([]);

    //per il pdf:
    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);
    
    useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI);
        ricercaDatiAbbonamento();
    }, [])

    useEffect(() => {
      if(statoPagina === STATO_PREPARA_PDF && ricercaLogo === 1){
          setStatoPagina(STATO_SCARICA_PDF);
      }
  },[statoPagina,ricercaLogo]);

    useEffect(() => {
      //ogni volta che cambi abbonamento fai ricerca degli abbonati che lo abbiano attivo
      if(insAbbonamentoEdi != ''){
        setStatoPagina(STATO_RICERCA_DATI);
        ricercaDatiAbbonati();
      }
  },[insAbbonamentoEdi]);

  function scaricaLogo() {
    //console.log("scaricaLogo");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
              }
              
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }

    function ricercaDatiAbbonamento() {
      //ricerco tra i Abbonamenti che mi servono per popolare la tendina:
      setLoading(true);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"elencoAbbonamenti",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServerDatiAbbonamento(valoreDiRitorno.risultatoRitorno);
              setStatoPagina(STATO_SCEGLI_ABBONAMENTO);
              }
          },
          (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
    }
    function ricercaDatiAbbonati() {
      //ricerco gli abbonati al corso selezionato:
      setLoading(true);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"elencoAbbonati",ordina_cognome_nome:1,dataPartenza:dataPartenzaDefault,filtro_accessi_residui:1,id_abbonamento:insIdAbbonamentoEdi,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServerDatiAbbonati(valoreDiRitorno.risultatoRitorno);
              setStatoPagina(STATO_SCEGLI_ABBONATI);
              }
          },
          (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
    }

    function callbackSetVariazioneCampi(variazioneCampi){
      setVariazioneCampi(variazioneCampi);
    }

    function callbackSetValoreTendEditabileAbbonamento(valore,posizioneTendina,valoreNonVisibile){
      if(valoreNonVisibile !== undefined &&  valoreNonVisibile !== '' && valoreNonVisibile !== -1){
          setInsAbbonamentoEdi(valore);
          setInsIdAbbonamentoEdi(valoreNonVisibile);
      }
  }

  function fetchOperazioneServer() {
    //invio l'azione da fare sul server

      var elencoID = [];
      var elencoCOGNOME = [];
      var elencoNOME = [];
      var elencoTESSERA = [];
      for (let n=0; n<personeSelezionate.length; n++) {
        if (personeSelezionate[n]) elencoID.push(risultatoServerDatiAbbonati[n].IDPERSONA);
        if (personeSelezionate[n]) elencoCOGNOME.push(risultatoServerDatiAbbonati[n].COGNOME);
        if (personeSelezionate[n]) elencoNOME.push(risultatoServerDatiAbbonati[n].NOME);
        if (personeSelezionate[n]) elencoTESSERA.push(risultatoServerDatiAbbonati[n].NUMERO_TESSERA);
      }
      
      if(elencoID.length>0){
        setLoading(true);
        // parametri per inviare i dati al server:
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: 
                      JSON.stringify({op:"memorizzaAccessiManuali",
                      idpersone:elencoID,
                      cognomi:elencoCOGNOME,
                      nomi:elencoNOME,
                      tessere:elencoTESSERA,
                      data:dataInizioDefault,
                      idabbonamento:insIdAbbonamentoEdi,
                      emak: props.emak,
                      sessionId:props.sessionId})
              };
          
              // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
              // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
              // variabile di stato "risultato_server"
              //setStatoPagina(STATO_MEMORIZZAZIONE);

              fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                  .then(risposta => risposta.json())
                  .then(
                  (valoreDiRitorno) => {
                      setLoading(false);
                      if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServerRegistrazione(valoreDiRitorno.risultatoRitorno);
                        setStatoPagina(STATO_MOSTRA_REPORT);
                        //setInsAbbonamentoEdi('');
                        //setInsIdAbbonamentoEdi('');
                        setPersoneSelezionate([]);
                        setDisabilitaBottoni(true);
                      } 
                      if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                      if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                          if(valoreDiRitorno.mess !== undefined){
                              
                          }else{
                              //altrimenti vado in errore generico
                              setStatoPagina(STATO_OPERAZIONE_FALLITA);
                          }
                      }
                  },
                  (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
              );   
    }else{
      setModaleNoSelezionati(true);
    }
}

  var coloreEvidenziato="#84b352";

  return (
    <Fragment>
      <div id="cont" className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
        {statoPagina === STATO_SCEGLI_ABBONAMENTO ?
                <>
                {modaleInfoSceltaAbbonamento=== true ?
                  <Modale 
                  titolo="Informativa Registrazione Manuale Accessi"
                  flagErrore={true}
                  contenuto={<div>Selezionando un abbonamento, si accede in automatico alla pagina successiva nella quale verrà proposta una lista degli iscritti che abbiano l'abbonamento scelto attivo ed un numero di accessi residui maggiore di 0. Si potrà quindi selezionare uno o più iscritti della lista e registrarne l'accesso in data odierna.</div>}
                  bottoni={[
                      {
                          "etichetta":"OK",
                          "tipo":"primary",
                          callback:() => {setModaleInfoSceltaAbbonamento(false)}
                      }    
                  ]}
                />
                :""}
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                <Clessidra loading={loading}/>
                <Card className="main-card mb-3"> 
                            <CardBody>
                                <Col md={12}>    
                                <CardTitle>Registrazione Manuale Accessi</CardTitle> 
                                Seleziona un abbonamento:<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setModaleInfoSceltaAbbonamento(true)}/>      
                                <TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="NOME"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={insAbbonamentoEdi}
                                    arrayValori={risultatoServerDatiAbbonamento}
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabileAbbonamento}
                                ></TendinaEditabile>
                                </Col>      
                            </CardBody>
                        </Card>
                </div>
                </>
            :""}
        {statoPagina === STATO_SCEGLI_ABBONATI ?
          <>
        
          {modaleNoSelezionati=== true ?
                        <Modale 
                        titolo="Informativa Iscritti Selezionati"
                        flagErrore={true}
                        contenuto={<div>Non è stato selezionato alcun iscritto.</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setModaleNoSelezionati(false)}
                            }    
                        ]}
                    />
          :""}

          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
              <Clessidra loading={loading}/>
              <Card className="main-card mb-3"> 
                <CardBody>
                  <Col md={12}>    
                    <CardTitle>Registrazione Manuale Accessi</CardTitle>  
                    {risultatoServerDatiAbbonati.length==0 ?
                      <><b>Non risultano iscritti con abbonamento {insAbbonamentoEdi} aventi numero di accessi diverso da 0.</b></>
                    :
                      <>
                      <div className='schedaSottotitolo'>Elenco Abbonati a {insAbbonamentoEdi}</div>  

                      Selezionare uno o più iscritti per registrarne gli accessi in data odierna:
                          {/*Selezionare uno o pù iscritti per memorizzarne gli accessi in data: 
                        
                                              <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                                  innerRef={dataInizio} 
                                                  placeholder=""
                                                  defaultValue={dataInizioDefault}
                                                  value={dataInizioDefault}
                                                  onInput={(e)=>{setDataInizioDefault(dataInizio.current.value);}}/> */}
                                            
                          


                          {/*Cerca: <input type='text' style={{width:"100%", border: "1px solid gray"}} onChange={(e) => setTestoRicercaPersone(e.target.value.toLowerCase())}/>*/}
                          <div style={{display:"flex", flexDirection:"row",marginTop:5}}>
                                    <div style={{flex:1, marginRight:5}}>
                                    <b>Iscritto</b>
                                    </div>
                                    <div style={{flex:1, marginRight:5}}>
                                    <b>Numero Accessi Residui</b>
                                    </div>
                          </div> 
                          
                          
                          {/*<div style={{ backgroundColor:"#EEEEEE", overflow:"auto"}}>*/}
                          <div>  
                              {risultatoServerDatiAbbonati.map((x,i) =>
                                  (testoRicercaPersone==="" || x.toLowerCase().includes(testoRicercaPersone)) 
                              &&  
                                  <div 
                                      key={i}
                                      style={{
                                          marginLeft:5, 
                                          backgroundColor:personeSelezionate[i]?coloreEvidenziato:"transparent", 
                                          color:personeSelezionate[i]?"#FFFFFF":"#000000",
                                          cursor: 'pointer'
                                      }}
                                      onClick={() => {
                                          if (personeSelezionate[i]) setNumeroPersoneSelezionate(numeroPersoneSelezionate-1); else setNumeroPersoneSelezionate(numeroPersoneSelezionate+1);                                         
                                          var ps=[...personeSelezionate];                                        
                                          ps[i]=!ps[i];
                                          setPersoneSelezionate(ps);
                                          setDisabilitaBottoni(false)
                                      }}
                                  >
                                  <div style={{display:"flex", flexDirection:"row",}}>
                                    <div style={{flex:1, marginRight:5}}>
                                      {risultatoServerDatiAbbonati[i].COGNOME} {risultatoServerDatiAbbonati[i].NOME}
                                      {risultatoServerDatiAbbonati[i].NUMERO_TESSERA !='' ?
                                        <> ({risultatoServerDatiAbbonati[i].NUMERO_TESSERA})</>
                                      :""}
                                    </div>
                                    <div style={{flex:1, marginRight:5}}>
                                      {risultatoServerDatiAbbonati[i].ACCESSI_RESIDUI !='-1' ?
                                        <>{risultatoServerDatiAbbonati[i].ACCESSI_RESIDUI}</>
                                      :<>Infiniti</>}
                                    </div>
                                  </div>    
                                  </div>                               
                              )}   
                          </div>  
                          </>                     
                        }
                  </Col>  
                      
              </CardBody>
              </Card>
          </div>
          
            <div className="schedaSpazioInferiore">   
              <div style={{display:"flex",flexDirection:"row"}}>
              <div style={{flex:1}}>
              <Button color="warning" disabled={disabilitaBottoni}  className="mt-2 bottoneMargineADestra" onClick={() => {fetchOperazioneServer(false)}}>
                Registra Accessi Selezionati</Button>
              </div> 
              <div style={{flex:1,marginTop:10,marginRight:40,textAlign: "right"}}>
                {numeroPersoneSelezionate > 0 ?
                  numeroPersoneSelezionate > 1 ?
                  <>{numeroPersoneSelezionate} elementi selezionati</>
                  :
                  <>{numeroPersoneSelezionate} elemento selezionato</>
                :""}
              </div>
            </div> 
          </div>
        </> 
        :""}

      {statoPagina === STATO_MOSTRA_REPORT ?
        <>
        <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
          <><b>Numero accessi registrati: {risultatoServerRegistrazione.length}</b>
         
          {risultatoServerRegistrazione.map((elemento,indice) =>
            <div key={indice}>
              {elemento.cognome} {elemento.nome}
              {elemento.tessera !='' ? " ("+elemento.tessera+")" : ""}
            </div>                                                   
          )
          }
          </>
        </div>
        <div className="schedaSpazioInferiore">   
        <div style={{display:"flex",flexDirection:"row"}}>
        <div style={{flex:1}}>
        <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setStatoPagina(STATO_PREPARA_PDF);scaricaLogo()}}>
          Esporta il PDF </Button>
        </div> 
      </div>
    </div>
    </>
    :""}

    {statoPagina === STATO_SCARICA_PDF ? 
    
      <div>
        <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
                        
          <div style={{position:"absolute",top:props.dimensioniApp.yTitolo}}>
                        
                            
                                <PDFViewer style={
                                    {flex:1, height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent"}   
                                }>
          
                                <Document>
                                <Page size="A4" style={styles.page}>
                                <View style={{width:"100%"}} >
                                    <View style={styles.tableIntestazione} > 
                                        <View wrap={false} style={styles.tableRowIntestazione}> 
                                            <View style={styles.tableColIntestazioneSx}> 
                                                <View style={styles.tableCell}> 
                                                    {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                                </View> 
                                            </View> 
                                            <View style={styles.tableColIntestazioneDx}>   
                                                <View style={styles.tableCellIntestazione}> 
                                                    {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}

                                                    {indirizzoSocieta !== "" ?
                                                    <Text style={styles.datisocieta}>{indirizzoSocieta}
                                                    {cittaSocieta !== "" ?
                                                        capSocieta !== "" ?
                                                            " "+capSocieta+" "+cittaSocieta
                                                        :" "+cittaSocieta
                                                    :""}
                                                    </Text>
                                                    :<Text></Text>}
                                                    {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                                    {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                              
                                                    <Text style={styles.titolo}>Riepilogo Registrazioni Manuali Accessi</Text>
                            
                                                </View> 
                                            </View> 
                                        </View> 
                                        </View>
                                        <View>
                              
                                            <Text style={styles.titoloPiccolo}> Data <TrasformaDateAUmano data={dataOggi}></TrasformaDateAUmano></Text>
                                            <Text style={styles.titoloPiccolo}> Abbonamento {insAbbonamentoEdi}</Text>
                                          
                                        </View>



                                        <View style={styles.table}>{/*apre table dati*/}
                                        <View wrap={false} style={styles.tableRowBlu}> 
                                           <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Cognome</Text> 
                                          </View> 
                                          <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Nome</Text> 
                                          </View> 
                                          <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Numero Tessera</Text> 
                                          </View>
                                        </View>

                                          {risultatoServerRegistrazione.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                                                      <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                          <Text style={styles.tableCellAllineatoSinistra}>{elemento.cognome}</Text>                        
                                                      </View>
                                                      <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                          <Text style={styles.tableCellAllineatoSinistra}>{elemento.nome}</Text>                        
                                                      </View>
                                                      {elemento.tessera !='' ? 
                                                        <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                          <Text style={styles.tableCellAllineatoSinistra}>{elemento.tessera}</Text>                        
                                                        </View>
                                                      : <View style={{width: 100/3+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                                                      <Text style={styles.tableCellAllineatoSinistra}></Text>                        
                                                    </View>}
                                              
                                                  </View>)}
                                        </View> 
                                          
                          </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                    </Page>
                    </Document>
                    </PDFViewer>


                                
                    </div>
                    </div>//div
                  
                :""}
    </div>
                     
    </Fragment>
   );


}
export default TabellaAccessi