import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setvariazioneCampi]=useState(0);

    const [flagAttivaModaleEnteNonCancellabile, setFlagAttivaModaleEnteNonCancellabile] = useState(0);
    const [flagAttivaModaleEnteNonModificabile, setFlagAttivaModaleEnteNonModificabile] = useState(0);
    const [flagAttivaModaleEnteNonInseribile, setFlagAttivaModaleEnteNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciCognome, setFlagAttivaModaleInserisciCognome] = useState(0);

    const cognome  = useRef();
    const nome   = useRef();
    const codFiscale   = useRef();
    const partitaIVA  = useRef();
    const citta   = useRef();
    const indirizzo   = useRef();
    const provincia  = useRef();
    const email   = useRef();
    const sitoWeb   = useRef();
    const cellulare   = useRef();
    const telefonoFisso   = useRef();
    const cognomeRef   = useRef();
    const nomeRef   = useRef();
    const cellulareRef   = useRef();
    const note   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    /*let cognomeDefault = "";
    let nomeDefault = "";
    let codFiscaleDefault = "";
    let partitaIVADefault = "";;
    let cittaDefault = "";
    let indirizzoDefault = "";
    let provinciaDefault = "";
    let emailDefault = "";
    let sitoWebDefault = "";
    let cellulareDefault = "";
    let telefonoFissoDefault = "";
    let cognomeRefDefault = "";
    let nomeRefDefault = "";
    let cellulareRefDefault = "";
    let noteDefault = "";*/


    const [cognomeDefault,setCognomeDefault]=useState('');
    const [nomeDefault,setNomeDefault]=useState('');
    const [codFiscaleDefault,setCodFiscaleDefault]=useState('');
    const [partitaIVADefault,setPartitaIVADefault]=useState('');
    const [cittaDefault,setCittaDefault]=useState('');
    const [indirizzoDefault,setIndirizzoDefault]=useState('');
    const [provinciaDefault,setProvinciaDefault]=useState('');
    const [emailDefault,setEmailDefault]=useState('');
    const [sitoWebDefault,setSitoWebDefault]=useState('');
    const [cellulareDefault,setCellulareDefault]=useState('');
    const [telefonoFissoDefault,setTelefonoFissoDefault]=useState('');
    const [cognomeRefDefault,setCognomeRefDefault]=useState('');
    const [nomeRefDefault,setNomeRefDefault]=useState('');
    const [cellulareRefDefault,setCellulareRefDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
    },[]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("enteConvIns");
        if(props.ope==='modifica') setOperazione("enteConvMod");
        if(props.ope==='cancella') setOperazione("enteConvCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"enteConvRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);

                    setCognomeDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCodFiscaleDefault(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                    setPartitaIVADefault(valoreDiRitorno.risultatoRitorno[0].P_IVA);
                    setCittaDefault(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoDefault(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setProvinciaDefault(valoreDiRitorno.risultatoRitorno[0].PROVINCIA);
                    setEmailDefault(valoreDiRitorno.risultatoRitorno[0].EMAIL);
                    setSitoWebDefault(valoreDiRitorno.risultatoRitorno[0].SITO_WEB);
                    setCellulareDefault(valoreDiRitorno.risultatoRitorno[0].CELLULARE);
                    setTelefonoFissoDefault(valoreDiRitorno.risultatoRitorno[0].TELEFONO);
                    setCognomeRefDefault(valoreDiRitorno.risultatoRitorno[0].COGNOME_REF);
                    setNomeRefDefault(valoreDiRitorno.risultatoRitorno[0].NOME_REF);
                    setCellulareRefDefault(valoreDiRitorno.risultatoRitorno[0].CELLULARE_REF);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
    
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {

        setFlagAttivaModaleEnteNonCancellabile(0);
        setFlagAttivaModaleEnteNonModificabile(0);
        setFlagAttivaModaleEnteNonInseribile(0);
        setFlagAttivaModaleInserisciCognome(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setvariazioneCampi(1);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }
            if(cognome.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciCognome(1);
            }
        }

        if(controlli===0){ 
            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{

                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        cognome:cognome.current.value,
                        nome:nome.current.value,
                        codFiscale:codFiscale.current.value,
                        pIva:partitaIVA.current.value,
                        citta:citta.current.value,
                        indirizzo:indirizzo.current.value,
                        provincia:provincia.current.value,
                        email:email.current.value,
                        sitoWeb:sitoWeb.current.value,
                        cellulare:cellulare.current.value,
                        telefonoFisso:telefonoFisso.current.value,
                        cognomeRef:cognomeRef.current.value,
                        nomeRef:nomeRef.current.value,
                        cellulareRef:cellulareRef.current.value,
                        note:note.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Dato non cancellabile: presente in convenzioni") !== -1){
                                    setFlagAttivaModaleEnteNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Dato non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleEnteNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleEnteNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Cognome non valorizzato") !== -1){
                                    setFlagAttivaModaleInserisciCognome(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }
    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
      
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1EnConv {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciCognome===1 ?
                        <Modale 
                            titolo="Richiesta denominazione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la denominazione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleEnteNonCancellabile===1 ?
                        <Modale 
                            titolo="Informativa ente non cancellabile"
                            flagErrore={true}
                            contenuto={<div>L'ente di convenzione selezionato non è cancellabile in quanto associato ad uno o più convenzioni</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleEnteNonModificabile===1 ?
                        <Modale 
                            titolo="Informativa ente non modificabile"
                            flagErrore={true}
                            contenuto={<div>La denominazione dell'ente di convenzione non è modificabile in {cognome.current.value} poichè nel database è già presente un ente di convenzione con la stessa denominazione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleEnteNonInseribile===1 ?
                        <Modale 
                            titolo="Informativa ente non inseribile"
                            flagErrore={true}
                            contenuto={<div>L'ente di convenzione {cognome.current.value} non è inseribile in poichè nel database è già presente un ente di convenzione con la stessa denominazione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                     
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Ente Convenzione</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Ente Convenzione</CardTitle> :""}

                         
                            <Clessidra loading={loading}/>

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione dell' ente di convenzione <b>{cognomeDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :

                            <>
                            <Row form>  
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="cognome">Denominazione*</Label>
                                                <Input maxLength={50} type="text" name="cognome" id="cognome"
                                                innerRef={cognome} 
                                                placeholder="" 
                                                defaultValue={cognomeDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setCognomeDefault(cognome.current.value)}}/>  
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="nome">Descrizione</Label>
                                                <Input maxLength={50} type="text" name="nome" id="nome"
                                                innerRef={nome}
                                                placeholder="" 
                                                defaultValue={nomeDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNomeDefault(nome.current.value)}}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="codFiscale">Codice Fiscale</Label>
                                                <Input maxLength={20} type="text" name="codFiscale" id="codFiscale"
                                                innerRef={codFiscale}
                                                placeholder="" 
                                                defaultValue={codFiscaleDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setCodFiscaleDefault(codFiscale.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="partitaIVA">Partita IVA</Label>
                                                <Input maxLength={50} type="text" name="partitaIVA" id="partitaIVA"
                                                innerRef={partitaIVA}
                                                placeholder="" 
                                                defaultValue={partitaIVADefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setPartitaIVADefault(partitaIVA.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>
                                <Row form>  
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="citta">Città</Label>
                                                <Input maxLength={50} type="text" name="citta" id="citta"
                                                innerRef={citta}
                                                placeholder="" 
                                                defaultValue={cittaDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setCittaDefault(citta.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="indirizzo">Indirizzo</Label>
                                                <Input maxLength={255} type="text" name="indirizzo" id="indirizzo"
                                                innerRef={indirizzo}
                                                defaultValue={indirizzoDefault}
                                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);setIndirizzoDefault(indirizzo.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="provincia">Provincia</Label>
                                                <Input maxLength={50} type="text" name="provincia" id="provincia"
                                                innerRef={provincia}
                                                placeholder="" 
                                                defaultValue={provinciaDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setProvinciaDefault(provincia.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                                <Input maxLength={100} type="text" name="email" id="email"
                                                innerRef={email}
                                                placeholder="" 
                                                defaultValue={emailDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setEmailDefault(email.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="sitoWeb">Sito WEB</Label>
                                                <Input maxLength={100} type="text" name="sitoWeb" id="sitoWeb"
                                                innerRef={sitoWeb}
                                                placeholder="" 
                                                defaultValue={sitoWebDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setSitoWebDefault(sitoWeb.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cellulare">Cellulare</Label>
                                                <Input maxLength={30} type="text" name="cellulare" id="cellulare"
                                                innerRef={cellulare}
                                                placeholder="" 
                                                defaultValue={cellulareDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setCellulareDefault(cellulare.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="telefonoFisso">Telefono Fisso</Label>
                                                <Input maxLength={30} type="text" name="telefonoFisso" id="telefonoFisso"
                                                innerRef={telefonoFisso}
                                                placeholder="" 
                                                defaultValue={telefonoFissoDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setTelefonoFissoDefault(telefonoFisso.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cognomeRef">Cognome Referente</Label>
                                                <Input maxLength={50} type="text" name="cognomeRef" id="cognomeRef"
                                                innerRef={cognomeRef}
                                                placeholder="" 
                                                defaultValue={cognomeRefDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setCognomeRefDefault(cognomeRef.current.value);}}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nomeRef">Nome Referente</Label>
                                                <Input maxLength={50} type="text" name="nomeRef" id="nomeRef"
                                                innerRef={nomeRef}
                                                placeholder="" 
                                                defaultValue={nomeRefDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNomeRefDefault(nomeRef.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cellulareRef">Cellulare Referente</Label>
                                                <Input maxLength={30} type="text" name="cellulareRef" id="cellulareRef"
                                                innerRef={cellulareRef}
                                                placeholder="" 
                                                defaultValue={cellulareRefDefault}
                                               onInput={(e)=>{handleCambiamentoMod(e);setCellulareRefDefault(cellulareRef.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>   
                                <Row form>  
                                <Col md={12}>
                                <FormGroup>
                                    <Label for="note">Note</Label>
                                        <Input type="textarea" name="note" id="note"
                                        innerRef={note}
                                        placeholder="" 
                                        defaultValue={noteDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value);}}/> 
                                </FormGroup>
                                </Col>
                                </Row>
                                </>
                                }
                              
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
