import React from 'react';

import TabellaRisorse from './Pagine/TabellaRisorse/Tabella.js';
import TabellaIscritti from './Pagine/TabellaIscritti/Tabella.js';
import TabellaTesseram from './Pagine/TabellaTesseram/Tabella.js';
import TabellaInsolventi from './Pagine/TabellaInsolventi/Tabella.js';
import TabellaDirettivo from './Pagine/TabellaDirettivo/Tabella.js';
import TabellaLibroSoci from './Pagine/TabellaLibroSoci/Tabella.js';
import TabellaGruppi from './Pagine/TabellaGruppi/Tabella.js';
import TabellaCliFor from './Pagine/TabellaCliFor/Tabella.js';
import TabellaSocAnaloghe from './Pagine/TabellaSocAnaloghe/Tabella.js';
import TabellaContattiUtili from './Pagine/TabellaContattiUtili/Tabella.js';
import TabellaAbbonati from './Pagine/TabellaAbbonati/Tabella.js';
import TabellaAbbonamenti from './Pagine/TabellaAbbonamenti/Tabella.js';
import TabellaAccessi from './Pagine/TabellaAccessi/Tabella.js';
import TabellaAdempimenti from './Pagine/TabellaAdempimenti/Tabella.js';
import TabellaCorsi from './Pagine/TabellaCorsi/Tabella.js';
//import TabellaPrenotazioneCorsi  from './Pagine/TabellaPrenotazioneCorsi/Tabella.js';
import TabellaPrenotazioneCorsi2  from './Pagine/TabellaPrenotazioneCorsi2/Tabella.js';
import TabellaCatArticoli from './Pagine/TabellaCatArticoli/Tabella.js';
import TabellaArticoli from './Pagine/TabellaArticoli/Tabella.js';
import TabellaFederEntiTesseram  from './Pagine/TabellaFederEntiTesseram/Tabella.js';
import TabellaTipiTesseram  from './Pagine/TabellaTipiTesseram/Tabella.js';
import TabellaCatTesseram from './Pagine/TabellaCatTesseram/Tabella.js';
import TabellaSponsor  from './Pagine/TabellaSponsor/Tabella.js';
import TabellaCampagneSponsor  from './Pagine/TabellaCampagneSponsor/Tabella.js';
import TabellaConvenzioni  from './Pagine/TabellaConvenzioni/Tabella.js';
import TabellaEntiConvenzioni  from './Pagine/TabellaEntiConvenzioni/Tabella.js';
import TabellaTipiEventi  from './Pagine/TabellaTipiEventi/Tabella.js';
import TabellaEventi  from './Pagine/TabellaEventi/Tabella.js';
import TabellaEsercizi from './Pagine/TabellaEsercizi/Tabella.js';
import TabellaClassiEsercizi from './Pagine/TabellaClassiEsercizi/Tabella.js';
import TabellaSchedeAllenamento from './Pagine/TabellaSchedeAllenamento/Tabella.js';
import TabellaAssegnazioniSchede from './Pagine/TabellaAssegnazioniSchede/Tabella.js';
import TabellaPrimaNota  from './Pagine/TabellaPrimaNota/Tabella.js';
import TabellaSpese  from './Pagine/TabellaSpese/Tabella.js';
import TabellaRimborsi from './Pagine/TabellaRimborsi/Tabella.js';
import TabellaIncassiDiversi  from './Pagine/TabellaIncassiDiversi/Tabella.js';
import TabellaIncassiDiversiIscritti  from './Pagine/TabellaIncassiDiversiIscritti/Tabella.js';
import TabellaIncassiQuoteAbb from './Pagine/TabellaIncassiQuoteAbb/Tabella.js';
import TabellaIncassiQuoteAss from './Pagine/TabellaIncassiQuoteAss/Tabella.js';
import TabellaVociDiBilancio from './Pagine/TabellaVociDiBilancio/Tabella.js';
import TabellaQuoteAssociative from './Pagine/TabellaQuoteAssociative/Tabella.js';
import TabellaDocumenti from './Pagine/TabellaDocumenti/Tabella.js';
import TabellaTipiDocumenti from './Pagine/TabellaTipiDocumenti/Tabella.js';
import SchedaMioAbbonamento from './Pagine/SchedaMioAbbonamento/FormGridFormRow.js';
import TabellaPersonePermessi from './Pagine/TabellaPersonePermessi/Tabella.js';
import TabellaImpostazioniSocieta from './Pagine/SchedaImpostazioniSocieta/Tabella.js';
import TabellaGiorniChiusura from './Pagine/TabellaGiorniChiusura/Tabella.js';
import TabellaPosizioniSocieta from './Pagine/TabellaPosizioniSocieta/Tabella.js';
import TabellaTipiDocIdentita from './Pagine/TabellaTipiDocIdentita/Tabella.js';
import ImportazioneMassiva  from './Pagine/ImportazioneMassiva/Tabella.js';
import TabellaScadenziario  from './Pagine/TabellaScadenziario/Tabella.js';
import TabellaNews from './Pagine/TabellaNews/Tabella.js';
import ReportElencoPrenotazioni from './Pagine/ReportElencoPrenotazioni/Tabella.js';
import ReportElencoArticoli from './Pagine/ReportElencoArticoli/Tabella.js';
import ReportPrenotazioniCorsi from './Pagine/ReportPrenotazioniCorsi/Tabella.js';
import TabellaBilancio from './Pagine/TabellaBilancio/Tabella.js';
import TabellaCertScad from './Pagine/TabellaCertScad/Tabella.js';
import TabellaReportTesseramenti from './Pagine/TabellaReportTesseramenti/Tabella.js';
import TabellaReportVincoli from './Pagine/TabellaReportVincoli/Tabella.js';
import TabellaReportEconPersone from './Pagine/TabellaReportEconPersone/Tabella.js';
import TabellaReportEconEsterni from './Pagine/TabellaReportEconEsterni/Tabella.js';
import TabellaReportSponsor from './Pagine/TabellaReportSponsor/Tabella.js';
import TabellaReportRimborsi from './Pagine/TabellaReportRimborsi/Tabella.js';
import ReportEventi from './Pagine/ReportEventi/ReportEventi.js';
import CambioPassword from './CambioPassword.js';
import TabellaFaq from './Pagine/TabellaFaq/Tabella.js';
import TabellaControlloAccessi from './Pagine/TabellaControlloAccessi/Tabella.js';
import ReportAccessi from './Pagine/ReportAccessi/Tabella.js';
import SchedaAssociaTessera from './Pagine/SchedaAssociaTessera/SchedaAssociaTessera.js';
import SchedaInviaMessaggio from './Pagine/SchedaInviaMessaggio/SchedaInviaMessaggio.js';
import TabellaSettaggiCrm from './Pagine/TabellaSettaggiCrm/Tabella.js';
import TabellaMezziComunicazCrm from './Pagine/TabellaMezziComunicazCrm/Tabella.js';
import TabellaComunicazioniCrm from './Pagine/TabellaComunicazioniCrm/Tabella.js';
import TabellaDatiCrm from './Pagine/TabellaDatiCrm/Tabella.js';
import TabellaProspectCrm from './Pagine/TabellaProspectCrm/Tabella.js';
import TabellaContattatiCrm from './Pagine/TabellaContattatiCrm/Tabella.js';
import TabellaFattureElettroniche from './Pagine/TabellaFattureElettroniche/TabellaFattureElettroniche.js';

import Home from './Pagine/Home.js';

function AppMain(props) {    
    if(props.pagina === undefined) return(<div>errMain</div>);    
    return (
        <div style={{backgroundColor:"#E0E0E0", height: props.dimensioniApp.yMain}}>
            {props.pagina === props.PAGINE.benvenuto && props.identitaSelezionata.flag_amministrazione === 1 ? <Home {...props} /> : ""}           
            {props.pagina === props.PAGINE.risorse && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaRisorse {...props} /> : ""}
            {props.pagina === props.PAGINE.iscritti && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaIscritti {...props} /> : ""}
            {props.pagina === props.PAGINE.tesseram &&  props.identitaSelezionata.flag_amministrazione === 1? <TabellaTesseram {...props} /> : ""}
            {props.pagina === props.PAGINE.insolventi &&  props.identitaSelezionata.flag_amministrazione === 1? <TabellaInsolventi {...props} /> : ""}
            {props.pagina === props.PAGINE.direttivo &&  props.identitaSelezionata.flag_amministrazione === 1? <TabellaDirettivo {...props} /> : ""}
            {props.pagina === props.PAGINE.libroSoci &&  props.identitaSelezionata.flag_amministrazione === 1? <TabellaLibroSoci {...props} /> : ""}
            {props.pagina === props.PAGINE.gruppi && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaGruppi {...props} /> : ""}
            {props.pagina === props.PAGINE.clientiFornitori && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaCliFor {...props} /> : ""}
            {props.pagina === props.PAGINE.socAnaloghe && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaSocAnaloghe {...props} /> : ""}   
            {props.pagina === props.PAGINE.contattiUtili && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaContattiUtili {...props} /> : ""}   
            {props.pagina === props.PAGINE.abbonati && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaAbbonati {...props} /> : ""}
            {props.pagina === props.PAGINE.abbonamenti && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaAbbonamenti {...props} /> : ""}
            {props.pagina === props.PAGINE.accessi && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaAccessi {...props} /> : ""}
            {props.pagina === props.PAGINE.adempimenti && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaAdempimenti {...props} /> : ""}
            {props.pagina === props.PAGINE.corsi && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaCorsi {...props} /> : ""}
            {props.pagina === props.PAGINE.prenotazioneCorsi2 && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaPrenotazioneCorsi2 {...props} /> : ""}
            {props.pagina === props.PAGINE.catArticoli && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaCatArticoli {...props} /> : ""}
            {props.pagina === props.PAGINE.articoli && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaArticoli {...props} /> : ""}
            {props.pagina === props.PAGINE.federEntiTesseram && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaFederEntiTesseram {...props} /> : ""}
            {props.pagina === props.PAGINE.tipiTesseram && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaTipiTesseram {...props} /> : ""}
            {props.pagina === props.PAGINE.catTesseram && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaCatTesseram {...props} /> : ""}
            {props.pagina === props.PAGINE.sponsor && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaSponsor {...props} /> : ""}
            {props.pagina === props.PAGINE.campagneSponsor && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaCampagneSponsor {...props} /> : ""}
            {props.pagina === props.PAGINE.convenzioni && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaConvenzioni {...props} /> : ""}
            {props.pagina === props.PAGINE.entiConvenzioni && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaEntiConvenzioni {...props} /> : ""}
            {props.pagina === props.PAGINE.eventi && (props.identitaSelezionata.flag_staff === 1) ? <TabellaEventi {...props} /> : ""}
            {props.pagina === props.PAGINE.tipiEventi  && (props.identitaSelezionata.flag_staff === 1) ? <TabellaTipiEventi {...props} /> : ""}
            {props.pagina === props.PAGINE.esercizi && props.identitaSelezionata.flag_staff === 1 ? <TabellaEsercizi {...props} /> : ""}
            {props.pagina === props.PAGINE.classiEsercizi && props.identitaSelezionata.flag_staff === 1 ? <TabellaClassiEsercizi {...props} /> : ""}
            {props.pagina === props.PAGINE.schedeAllenamento && props.identitaSelezionata.flag_staff === 1 ? <TabellaSchedeAllenamento {...props} /> : ""}
            {props.pagina === props.PAGINE.assegnazioniSchede && props.identitaSelezionata.flag_staff === 1 ? <TabellaAssegnazioniSchede {...props} /> : ""}
            {props.pagina === props.PAGINE.primaNota && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaPrimaNota {...props} /> : ""}
            {props.pagina === props.PAGINE.spese && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaSpese {...props} /> : ""}
            {props.pagina === props.PAGINE.rimborsi && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaRimborsi {...props} /> : ""}
            {props.pagina === props.PAGINE.incassiDiversi && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaIncassiDiversi {...props} /> : ""}
            {props.pagina === props.PAGINE.incassiDiversiIscritti && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaIncassiDiversiIscritti {...props} /> : ""}
            {props.pagina === props.PAGINE.incassiQuoteAbb && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaIncassiQuoteAbb {...props} /> : ""}
            {props.pagina === props.PAGINE.incassiQuoteAss && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaIncassiQuoteAss {...props} /> : ""}
            {props.pagina === props.PAGINE.vociDiBilancio && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaVociDiBilancio {...props} /> : ""}
            {props.pagina === props.PAGINE.quoteAssociative && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaQuoteAssociative {...props} /> : ""}
            {props.pagina === props.PAGINE.documenti && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaDocumenti {...props} /> : ""}
            {props.pagina === props.PAGINE.tipiDocumenti && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaTipiDocumenti {...props} /> : ""}
            {props.pagina === props.PAGINE.mioAbbonamento && props.identitaSelezionata.flag_amministrazione === 1 ? <SchedaMioAbbonamento {...props} /> : ""}
            {props.pagina === props.PAGINE.personePermessi && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaPersonePermessi {...props} /> : ""}
            {props.pagina === props.PAGINE.impostazioniSocieta && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaImpostazioniSocieta {...props} /> : ""}
            {props.pagina === props.PAGINE.giorniChiusura && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaGiorniChiusura {...props} /> : ""}
            {props.pagina === props.PAGINE.posizioniSocieta && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaPosizioniSocieta {...props} /> : ""}
            {props.pagina === props.PAGINE.tipiDocIdentita && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaTipiDocIdentita {...props} /> : ""}
            {props.pagina === props.PAGINE.importazioneMassiva && props.identitaSelezionata.flag_amministrazione === 1 ? <ImportazioneMassiva {...props} /> : ""}
            {props.pagina === props.PAGINE.scadenziario && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaScadenziario {...props} /> : ""}
            {props.pagina === props.PAGINE.news  && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaNews {...props} /> : ""}
            {props.pagina === props.PAGINE.reportElencoPrenotazioni ? <ReportElencoPrenotazioni {...props} /> : ""}
            {props.pagina === props.PAGINE.reportElencoArticoli && props.identitaSelezionata.flag_amministrazione === 1 ? <ReportElencoArticoli {...props} /> : ""}
            {props.pagina === props.PAGINE.reportPrenotazioniCorsi ? <ReportPrenotazioniCorsi {...props} /> : ""}
            {props.pagina === props.PAGINE.bilancio && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaBilancio {...props} /> : ""}
            {props.pagina === props.PAGINE.certScad && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaCertScad {...props} tipo="cert"/> : ""}
            {props.pagina === props.PAGINE.docScad && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaCertScad {...props} tipo="doc"/> : ""}
            {props.pagina === props.PAGINE.privacyScad && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaCertScad {...props} tipo="privacy"/> : ""}
            {props.pagina === props.PAGINE.abbonamentiScad && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <TabellaCertScad {...props} tipo="abbonamenti"/> : ""}
            {props.pagina === props.PAGINE.reportTesseramenti && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaReportTesseramenti {...props} /> : ""}
            {props.pagina === props.PAGINE.reportVincoli && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaReportVincoli {...props} /> : ""}
            {props.pagina === props.PAGINE.reportEconPersone && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaReportEconPersone {...props} /> : ""}
            {props.pagina === props.PAGINE.reportEconEsterni && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaReportEconEsterni {...props} /> : ""}
            {props.pagina === props.PAGINE.reportSponsor && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaReportSponsor {...props} /> : ""}
            {props.pagina === props.PAGINE.reportRimborsi && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaReportRimborsi {...props} /> : ""}
            {props.pagina === props.PAGINE.reportEventi && (props.identitaSelezionata.flag_amministrazione === 1 || props.identitaSelezionata.flag_staff === 1) ? <ReportEventi {...props} /> : ""}
            {props.pagina === props.PAGINE.cambioPassword ? <CambioPassword {...props} /> : ""}
            {props.pagina === props.PAGINE.faq ? <TabellaFaq {...props} /> : ""}
            {props.pagina === props.PAGINE.controlloAccessi  && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaControlloAccessi {...props} /> : ""}
            {props.pagina === props.PAGINE.reportAccessi  && props.identitaSelezionata.flag_amministrazione === 1 ? <ReportAccessi {...props} /> : ""}
            {props.pagina === props.PAGINE.associaTessera && props.identitaSelezionata.flag_amministrazione === 1 ? <SchedaAssociaTessera {...props} /> : ""}    
            {props.pagina === props.PAGINE.inviaMessaggio && props.identitaSelezionata.flag_amministrazione === 1 ? <SchedaInviaMessaggio {...props} /> : ""}       
            {props.pagina === props.PAGINE.settaggiCrm && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaSettaggiCrm {...props} /> : ""} 
            {props.pagina === props.PAGINE.mezziComunicazCrm && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaMezziComunicazCrm {...props} /> : ""} 
            {props.pagina === props.PAGINE.comunicazioniCrm && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaComunicazioniCrm {...props} /> : ""} 
            {props.pagina === props.PAGINE.datiCrm && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaDatiCrm {...props} /> : ""} 
            {props.pagina === props.PAGINE.prospectCrm && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaProspectCrm {...props} /> : ""} 
            {props.pagina === props.PAGINE.contattatiCrm && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaContattatiCrm {...props} /> : ""} 
            {props.pagina === props.PAGINE.fattureElettroniche && props.identitaSelezionata.flag_amministrazione === 1 ? <TabellaFattureElettroniche {...props} /> : ""}

        </div>
    )
}

export default AppMain;