
import React, {Fragment,useState} from 'react';
import { useEffect } from 'react';
import {
    Col, Row, Card, CardBody,
    Form,Label,CardTitle,Button
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';

//https://fullcalendar.io/demos utile per dimostrazioni e manuale

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import itLocale from '@fullcalendar/core/locales/it';//serve per lingua italiana
import timeGridPlugin from '@fullcalendar/timegrid';

import Clessidra from '../Clessidra.js';

const STATO_INIZIO = 0;
const STATO_RICERCA_DATI_CORRETTA = 1;
const STATO_ERRORE_CONNESSIONE = 2;
const STATO_RICERCA = 3;

function ReportPrenotazioniCorsi(props) {

    const [statoPagina,setStatoPagina]=useState(STATO_INIZIO);
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    const [eventiCalendario,setEventiCalendario]=useState([]);
    const [statoTendinaCorso,setStatoTendinaCorso]=useState(false);
    const [statoTendinaIscritto,setStatoTendinaIscritto]=useState(false);
    const [risultatoServerDatiCorso,setRisultatoServerDatiCorso]=useState('');
    const [risultatoServerDatiPersona,setRisultatoServerDatiPersona]=useState('');
    const [defaultCorso,setDefaultCorso]=useState('Tutti');
    const [defaultPersona,setDefaultPersona]=useState('Tutti');
    const [insCorsoEdi,setInsCorsoEdi]=useState('');
    const [insIdCorsoEdi,setInsIdCorsoEdi]=useState('');
    const [insPersonaEdi,setInsPersonaEdi]=useState('');
    const [insIdPersonaEdi,setInsIdPersonaEdi]=useState('');
    const [loading,setLoading]= useState(false);

    const [error,setError]=useState('');

    const [zoom,setZoom]=useState([]);
    const [chiaveZoom,setChiaveZoom]=useState(1);
    
    useEffect(() => {
        setStatoPagina()
        setStatoTendinaIscritto(false);
        ricercaDatiPersona();
        setDefaultCorso("Tutti");
        setDefaultPersona("Tutti");
        cercaDatiCalendario();

        //possibili zoom:
        //0 - ora
        //1 - mezzora
        //2 - 15 minuti
        //3 - 5 minuti
        //4 - 1 minuti
        let arr=[];
        for (let i=0; i<5; i++) {
            if(i===0) arr[i]="01:00:00";
            if(i===1) arr[i]="00:30:00";
            if(i===2) arr[i]="00:15:00";
            if(i===3) arr[i]="00:05:00";
            if(i===4) arr[i]="00:01:00";
            setZoom(arr);
        }
        setChiaveZoom(1);
    },[]);

    
    useEffect(() => {
        if(statoTendinaCorso=== true && statoTendinaIscritto === true){
            //console.log("RICERCA");
            cercaDatiCalendario();
        }
    },[insCorsoEdi,insPersonaEdi]);


    function ricercaDatiPersona() {
        //ricerco tra gli iscritti che mi servono per popolare la tendina delle persone che possono prenotare un corso:
        setLoading(true);
        setStatoPagina(STATO_RICERCA);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaIscritto(true);
                ricercaDatiCorsi();
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiCorsi() {
        //ricerco tra i corsi che mi servono per popolare la tendina ed avere quando è attivo:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoCorsi",emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServerDatiCorso(valoreDiRitorno.risultatoRitorno);
                        setStatoTendinaCorso(true);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                  }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }


    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
    }
    function callbackSetValoreTendEditabilePersona(valore,pos,valoreNonVisibile){
        if(valore!==''){
            setInsPersonaEdi(valore);
            setInsIdPersonaEdi(valoreNonVisibile);
        }
    }
    function callbackSetValoreTendEditabileCorso(valore,pos,valoreNonVisibile){
        if(valore!==''){
            setInsCorsoEdi(valore);
            setInsIdCorsoEdi(valoreNonVisibile);
        }
    }

    function tornaPrenotazioni() {
        props.callbackTermineEscursioneInReport();
    }


    function cercaDatiCalendario(){
        //ricerco le prenotazioni già in calendario per la persona in esame:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPrenotazCorsiPersona",idPersona:insIdPersonaEdi,idCorso:insIdCorsoEdi,emak: props.emak,sessionId:props.sessionId})
        };
    
    

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                        setEventiCalendario(valoreDiRitorno.risultatoRitorno);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                  }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function regolaZoom (direzione){
        //direzione === 1 -> aumenta zoom
        //direzione === 0 -> diminuisci zoom
        //cinque step di zoom a seconda del valore della chiave:
        //0 - ora
        //1 - mezzora
        //2 - 15 minuti
        //3 - 5 minuti
        //4 - 1 minuti
        if(direzione === 1 && chiaveZoom<4) setChiaveZoom(parseInt(chiaveZoom)+1);
        if(direzione === 0 && chiaveZoom>1) setChiaveZoom(parseInt(chiaveZoom)-1);
    } 

    //const [flagResetPagine, setFlagResetPagine] = useState(false);

    //function callbackFlagResetPagine(f) {
    //    setFlagResetPagine(f);
    //}
    return(
        <Fragment>
        <Clessidra loading={loading}/>
        <div className="app-main">

            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Art {error.message}</div> :""}
            {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
            <div className="app-main__outer">
                <Card className="main-card mb-3"> 
                            <CardBody>
                                <CardTitle>Report Prenotazioni</CardTitle>
                               
                                <div style={{display:"flex", flexDirection:"row"}}>  
                                <div style={{flex:5}}>  
                                <Label for="iscritto">Iscritto</Label>         
                                <TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="COGNOME"
                                    nomeCampo2="NOME"
                                    nomeCampoParentesi="NUMERO_TESSERA"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={defaultPersona}
                                    arrayValori={risultatoServerDatiPersona}
                                    inserisciCampoTutti = "1"
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabilePersona}
                                ></TendinaEditabile>
                                </div>  

                                {props.provvenienza === "tabellaPrenotazioniCorsi" ?
                                    <div style={{flex:4,marginLeft: 7}}>
                                    <Label for="corso">Prestazione / Attrezzatura</Label>
                                    <TendinaEditabile
                                        //titolo={"Corso"}
                                        nomeCampo="NOME"
                                        nomeCampoNonVisibile="ID"
                                        bloccoInserimento = "1"
                                        valoreDefault={defaultCorso}
                                        arrayValori={risultatoServerDatiCorso}
                                        inserisciCampoTutti = "1"
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCorso}
                                    ></TendinaEditabile>
                                    </div>
                                :
                                    <div style={{flex:5,marginLeft: 7}}>
                                    <Label for="corso">Prestazione / Attrezzatura</Label>
                                    <TendinaEditabile
                                        //titolo={"Corso"}
                                        nomeCampo="NOME"
                                        nomeCampoNonVisibile="ID"
                                        bloccoInserimento = "1"
                                        valoreDefault={defaultCorso}
                                        arrayValori={risultatoServerDatiCorso}
                                        inserisciCampoTutti = "1"
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCorso}
                                    ></TendinaEditabile>
                                    </div>
                                }

                                <div style={{marginLeft: 7}}>
                                <Button color="primary" className="mt-3" onClick={() => {regolaZoom(1)}}>Zoom +</Button>
                                </div>
                                <div style={{marginLeft: 7}}>
                                <Button color="primary" className="mt-3" onClick={() => {regolaZoom(0)}}>Zoom -</Button>
                                </div>
                                {props.provvenienza === "tabellaPrenotazioniCorsi" ?
                                    <div style={{marginLeft: 7}}>
                                    <Button color="success" className="mt-2" onClick={() => tornaPrenotazioni()}>Torna alle Prenotazioni</Button>
                                    </div>
                                :""}
                                </div>
                                
                                    <Row form style={{marginTop:13}}>
                                        <Col md={12}>
                                       
                                        <FullCalendar
                                            slotDuration={zoom[chiaveZoom]}//The slotDuration changes the grid display to 30 minutes. 
                                            //snapDuration={"00:01:00"}
                                            plugins={[ dayGridPlugin, interactionPlugin ,timeGridPlugin ]}
                                            allDaySlot={false} //togli la cella iniziale che individua tutto il giorno
                                            //disegno la toolbar:
                                            headerToolbar={{
                                              start: 'title', // will normally be on the left. if RTL, will be on the right
                                              center: 'dayGridMonth,timeGridWeek,timeGridDay',
                                              end: 'prev,next', // will normally be on the right. if RTL, will be on the left
                                            }}
                                            
                                            //a seconda di che vista ho scelto tramite bottoni la propongo:
                                            initialView='timeGridWeek'//ti parte facendoti vedere la settimana e le ore a sx se metti ad es 'timeGridWeek'
                                            
                                            locale={itLocale} //serve per lingua italiana
                                            editable={false}
                                            eventOverlap={true} //true-> eventi sovrapponibili, false -> eventi non sovrapponibili

                                            selectable={true}//rende il giorno selezionabile
                                            dragScroll={true}//rende l'evento trascinabile

                                            eventMinHeight="15"//altezza minima di ogni evento (default = 15)

                                            weekends={true}//inserisce anche i weekends nel calendario
                                            displayEventEnd={true}//fà vedere l'ora di fine evento

                                            events={eventiCalendario}//inserisco i dati degli eventi attualmente già presenti

                                            slotLabelFormat={{
                                                hour: 'numeric',
                                                minute: '2-digit',
                                                omitZeroMinute: false,
                                                meridiem: 'short'
                                            
                                               }}
                                            
                                        />
                                        </Col>
                                    </Row> 
                                             
                            </CardBody>
                        </Card>
                {/*<AppFooter/>*/}
            </div>
            :""}
        </div>
    
        
    </Fragment>
    );
};

export default ReportPrenotazioniCorsi;