/* Modale.js 1.0 */

import React from 'react';
import { Button, CardBody, CardTitle, Modal } from 'reactstrap';

function Modale (props) {  
    return (
        <Modal size={props.larghezza} isOpen={true} contentLabel={props.titolo}>
            <CardTitle style={{backgroundColor:props.flagErrore ? "#D00000" : ""}}>
                {props.titolo}
            </CardTitle>
            <CardBody style={{textAlign:'center'}}>            
                {props.contenuto}
                <div style={{marginTop:20}}>
                    {props.bottoni.map((bottone, indice) =>
                        <div key={indice} style={{display:"inline-block",marginLeft:10, marginRight:10}}>
                            <Button color={bottone.tipo} onClick={bottone.callback}>{bottone.etichetta}</Button>                            
                        </div>
                    )}
                </div>                
            </CardBody>                        
        </Modal>
    )
}

export default Modale;