import React, {useState,useEffect,Suspense} from 'react';
import { Table,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import TabellaRicerca from '../tabellaricerca.js';

import Clessidra from '../Clessidra.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;

function TabellaFaq(props) {
    
    const operazione = "elencoFaq";
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: 'Numero',
        accessor: 'NUMERO'
      },
      {
        etichetta: 'Argomento',
        accessor: 'ARGOMENTO'
      },
      {
        etichetta: 'Domanda',
        accessor: 'DOMANDA'
      },
    ];

    function VaiModifica(risposta) {
      //let link ="http://192.168.1.8/risposte_faq/visualizzatore.php?posizione="+risposta;
      let link ="https://app.gestionesportiva.it/risposte_faq/visualizzatore.php?posizione="+risposta;
      window.open(link);
      //setStatoPagina(STATO_VAI_IN_MODIFICA);
      //setVisualizzaDomanda(domanda);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);

              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

  return (
      <div>
      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_FAQ</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          <CardBody>
          <CardTitle>Elenco FAQ</CardTitle>
          I filtri per Argomento o per Domanda possono aiutarti nella ricerca (indicando anche solo una parte della parola che stai cercando). 
          Cliccando su un record contenente la domanda, si aprirà una nuova pagina con la relativa risposta.
          </CardBody>
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca {...props} columns={colonne} data={risultatoServer} campoChiave={0} nascondiColonna={0} campoOrdinamento={0}  callbackSelezionaElemento={VaiModifica}/>
          : 

            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr>
                <th>Nessun dato presente</th>
              </tr>
            </thead>
            </Table>
          }
          
        </div>

      :""}
              
    </div>
   );
}
export default TabellaFaq;