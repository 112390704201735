import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaRimborsi/FormGridFormRow';


import TabellaRicerca from '../tabellaricerca.js';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';

import { calcolaAnnoOggi } from '../../funzioni.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaRimborsi(props) {
    
    const operazione = "elencoRimborsi";

    const  dataPartenza = useRef();

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');
    
    let idPersona = '';
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
    
    const [datiPersonaFiltrata, setDatiPersonaFiltrata]=useState('');

    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome Iscritto',
        accessor: 'COGNOME_ISCRITTO'
      },
      {
        etichetta: 'Nome Iscritto',
        accessor: 'NOME_ISCRITTO'
      },
      {
        etichetta: 'Numero Tessera Iscritto',
        accessor: 'NUMERO_TESSERA'
      },
      {
        etichetta: 'Descrizione',
        accessor: 'DESCRIZIONE'
      },
      {
        etichetta: 'Importo',
        accessor: 'IMPORTO'
      },
      {
        etichetta: 'Data',
        accessor: 'DATA_EMISSIONE'
      },
    ];
    const arrayTipiDati=[];
    arrayTipiDati[5]="importo";
    arrayTipiDati[6]="data";

    const colonnePaginaFiltrata = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Descrizione',
        accessor: 'DESCRIZIONE'
      },
      {
        etichetta: 'Importo',
        accessor: 'IMPORTO'
      },
      {
        etichetta: 'Data',
        accessor: 'DATA_EMISSIONE'
      },
    ];
    const arrayTipiDatiPaginaFiltrata=[];
    arrayTipiDatiPaginaFiltrata[2]="importo";
    arrayTipiDatiPaginaFiltrata[3]="data";

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //resetto tutte le var di stato:
      setRisultatoServer(['']);
      setStatoPagina(STATO_RICERCA_DATI);    
    },[dataPartenzaDefault])
    
    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){

        if(props.idPersona !== undefined) idPersona = props.idPersona;
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,idElemento:idPersona,dataPartenza:dataPartenzaDefault,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                setInizioPeriodo(valoreDiRitorno.periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.periodo[0].DATA_FINE_PERIODO);
                if(props.idPersona>0 && valoreDiRitorno.risultatoRitorno !== undefined && valoreDiRitorno.risultatoRitorno !== ""){
                  let componiPersona = "";
                  if(valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO;
                  if(valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                  if(valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO;
                  if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                  if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined) componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                  setDatiPersonaFiltrata(componiPersona);
                }
                setStatoPagina(STATO_OPERAZIONE_CORRETTA);
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      } 
    },[statoPagina])
   
  return (
      <div>
      
      {statoPagina === STATO_VAI_IN_INSERISCI ? 
      
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda rimborso in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} idPersona={props.idPersona} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda rimborso in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda rimborso in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Rim</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                    <CardBody>
                            <CardTitle>Elenco Rimborsi
                            {props.idPersona>0 ? <div>(filtrato per l'iscritto {datiPersonaFiltrata})
                            </div> :""}

                            &nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}>
                              {parseInt(dataPartenzaDefault) === annoOggi ? <option selected value={annoOggi}>{annoOggi}</option> : <option value={annoOggi}>{annoOggi}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-1 ? <option selected value={annoOggi-1}>{annoOggi-1}</option> : <option value={annoOggi-1}>{annoOggi-1}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-2 ? <option selected value={annoOggi-2}>{annoOggi-2}</option> : <option value={annoOggi-2}>{annoOggi-2}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-3 ? <option selected value={annoOggi-3}>{annoOggi-3}</option> : <option value={annoOggi-3}>{annoOggi-3}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-4 ? <option selected value={annoOggi-4}>{annoOggi-4}</option> : <option value={annoOggi-4}>{annoOggi-4}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-5 ? <option selected value={annoOggi-5}>{annoOggi-5}</option> : <option value={annoOggi-5}>{annoOggi-5}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-6 ? <option selected value={annoOggi-6}>{annoOggi-6}</option> : <option value={annoOggi-6}>{annoOggi-6}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-7 ? <option selected value={annoOggi-7}>{annoOggi-7}</option> : <option value={annoOggi-7}>{annoOggi-7}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-8 ? <option selected value={annoOggi-8}>{annoOggi-8}</option> : <option value={annoOggi-8}>{annoOggi-8}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-9 ? <option selected value={annoOggi-9}>{annoOggi-9}</option> : <option value={annoOggi-9}>{annoOggi-9}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-10 ? <option selected value={annoOggi-10}>{annoOggi-10}</option> : <option value={annoOggi-10}>{annoOggi-10}</option>}
                            </select>    
                            &nbsp;Data dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>



                            </CardTitle>
                            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
                            </CardBody>
                        
                            {risultatoServer !== undefined && risultatoServer !== "" ?
                              props.idPersona>0 ?
                                <TabellaRicerca columns={colonnePaginaFiltrata} data={risultatoServer} arrayTipiDati={arrayTipiDatiPaginaFiltrata} campoChiave={0} campoOrdinamento={3} ordinamentoDecrescente={true} nascondiColonna={0} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
                                :
                                <TabellaRicerca columns={colonne} data={risultatoServer} arrayTipiDati={arrayTipiDati} campoChiave={0} nascondiColonna={0} campoOrdinamento={6} ordinamentoDecrescente={true} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
                              : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      
        </div>
      :""}
    </div>
   );
}
export default TabellaRimborsi;