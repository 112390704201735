function TrasformaDateDaUmano(props) {
    //da formato g/m/a a a-m-g oppure da g/m a m-g oppure da m/a a a-m
    
    let dataOutput='';
    if(props.data){
        var arrdata = props.data.split('/');
        
        /*
        for (var n=2; n>=0; n--) {
            if (arrdata[n] !== undefined) {
                if (dataOutput != "") dataOutput += "-";
                if (arrdata[n].length < 2) dataOutput += "0";
                dataOutput += arrdata[n].padStart(2, '0');
            }
        }
*/

        if (arrdata[2] !== undefined && arrdata[2]!="") {
            if (String(arrdata[2]).length < 2) dataOutput += "0";
            dataOutput += arrdata[2];
        }

        if (arrdata[1] !== undefined && arrdata[1]!="") {
            if (dataOutput != "") dataOutput += "-";
            if (String(arrdata[1]).length < 2) dataOutput += "0";            
            dataOutput += arrdata[1];
        }

        if (arrdata[0] !== undefined && arrdata[0]!="") {
            if (dataOutput != "") {
                dataOutput += "-";
                if (String(arrdata[0]).length < 2) dataOutput += "0";
            }
            dataOutput += arrdata[0];
        }

    }
    return dataOutput;
 
}
export default TrasformaDateDaUmano;
