import React, {Fragment,useState,useEffect,useRef} from 'react';
import {
    Table,Col, Row, 
    CardTitle, Button, FormGroup, Label, Input
} from 'reactstrap';
import Parametri from '../../parametri';

import RequisitiAggiungiRimuovi from './RequisitiAggiungiRimuovi.js';

import Clessidra from '../Clessidra.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

function FormGridFormRow(props) {
    
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);
    const [ricercaRequisitiTerminata,setRicercaRequisitiTerminata]= useState(0);

    const [ricercaAbbonamentiPossibili,setRicercaAbbonamentiPossibili]= useState(0);
    const [ricercaPosizioniPossibili,setRicercaPosizioniPossibili]= useState(0);

    const [azioneRequisiti, setAzioneRequisiti] = React.useState("");

    const [modaleRequisitiAggiungiRimuovi, setModaleRequisitiAggiungiRimuovi] = React.useState(false);

    const [abbonamentiRichiesti,setAbbonamentiRichiesti]=useState(['']);
    const [idAbbonamentiRichiesti,setIdAbbonamentiRichiesti]=useState(['']);
    const [posizioniRichieste,setPosizioniRichieste]=useState(['']);
    const [idPosizioniRichieste,setIdPosizioniRichieste]=useState(['']);

    const [abbonamentiPossibili,setAbbonamentiPossibili]=useState('');
    const [posizioniPossibili,setPosizioniPossibili]=useState('');

    useEffect(() => {
        if(ricercaRequisitiTerminata == 1 && ricercaPosizioniPossibili == 1 && ricercaAbbonamentiPossibili == 1) setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[ricercaRequisitiTerminata,ricercaPosizioniPossibili,ricercaAbbonamentiPossibili]);

    useEffect(() => {
      ricercaRequisitiAccessi();
      ricercaAbbonamenti();
      ricercaPosizioni();
    },[]);

  function ricercaAbbonamenti() {
      //ricerco tra i Abbonamenti che sono possibili da selezionare:
      setLoading(true);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"elencoAbbonamenti",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setAbbonamentiPossibili(valoreDiRitorno.risultatoRitorno);
                setRicercaAbbonamentiPossibili(1);
              }
          },
          (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
  }

  function ricercaPosizioni() {
    //ricerco tra le posizioni che sono possibili da selezionare:
    setLoading(true);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"elencoPosizioniSocieta",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {
            setLoading(false);
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
            }
            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
            if(valoreDiRitorno.risultatoOperazione===1){
              setPosizioniPossibili(valoreDiRitorno.risultatoRitorno);
              setRicercaPosizioniPossibili(1);
            }
        },
        (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
}

  function ricercaRequisitiAccessi() {        
    //cerco gli attuali requisi che servono per entrare nella struttura:
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"elencoRequisitiAccesso",
          emak: props.emak,
          sessionId:props.sessionId
      })
    };
    setStatoPagina(STATO_RICERCA_DATI);
    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
    .then(risposta => risposta.json())
    .then(
        (valoreDiRitorno) => {
            setLoading(false);
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
            }
            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
            if(valoreDiRitorno.risultatoOperazione===1){
            //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
            let arrLocaleAbb=[];
            let arrLocalePos=[];
            let arrLocaleIdAbb=[];
            let arrLocaleIdPos=[];
            for (let i=0; i<valoreDiRitorno.abbonamenti.length ; i++) {
              arrLocaleAbb[i]=valoreDiRitorno.abbonamenti[i].NOME;  
              arrLocaleIdAbb[i]=valoreDiRitorno.abbonamenti[i].ID;  
            }
            for (let i=0; i<valoreDiRitorno.posizioni.length ; i++) {
              arrLocalePos[i]=valoreDiRitorno.posizioni[i].DESCRIZIONE; 
              arrLocaleIdPos[i]=valoreDiRitorno.posizioni[i].ID;   
            }
            setAbbonamentiRichiesti(arrLocaleAbb);
            setIdAbbonamentiRichiesti(arrLocaleIdAbb);
            setPosizioniRichieste(arrLocalePos);
            setIdPosizioniRichieste(arrLocaleIdPos);
            //setAbbonamentiRichiesti(valoreDiRitorno.abbonamenti);
            //setPosizioniRichieste(valoreDiRitorno.posizioni);
            setRicercaRequisitiTerminata(1);
        }
      },
      (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
    ); 
  }

  function inviaVariazioneRequisiti(elencoID, azione) {
    setStatoPagina(STATO_MEMORIZZAZIONE);
    setRicercaRequisitiTerminata(0);
    var op = "requisitiAccessoModifica";
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ op: op, elenco: elencoID,azione: azione, emak: props.emak, sessionId:props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
            (valore_di_ritorno) => {
                if (valore_di_ritorno.risultatoOperazione === 2) { props.setPagina(props.PAGINE.tornaLogin); return; }
                if (valore_di_ritorno.risultatoOperazione !== 1) { alert("Operazione fallita: Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); return; }
                // scarica nuovamente tutti gli abbonamenti e le posizioni richiesti per l'accesso, eventualmente recependo anche variazioni fatte da altri nel frattempo
                ricercaRequisitiAccessi();
            },
            (error) => {
                console.log("ERRORE DA BACKEND NON GESTITO (catch)");
                props.callback(props.PAGINE.login);
                return;
            }
        );
}


  function callbackRequisitiAggiungiRimuovi(elenco,azione) {        
    setModaleRequisitiAggiungiRimuovi(false);
    inviaVariazioneRequisiti(elenco,azione);
  }

    if (statoPagina === STATO_MEMORIZZAZIONE) return (<div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
    </div>
    </div>);
    if (statoPagina === STATO_ERRORE_CONNESSIONE) return (<Fragment><div>Errore: 1CoAcc {error.message}</div></Fragment>);
    if (statoPagina === STATO_OPERAZIONE_FALLITA) return (<Fragment><div>Errore per operazione errata</div></Fragment>);
    if (statoPagina === STATO_RICERCA_DATI) return (<div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
    
    </div>
    </div>);   
  
    if (statoPagina === STATO_RICERCA_DATI_CORRETTA) {
        return (
            <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                {modaleRequisitiAggiungiRimuovi &&  
                    <RequisitiAggiungiRimuovi 
                        azioneRequisiti={azioneRequisiti}
                        setPagina={props.setPagina}
                        PAGINE={props.PAGINE}
                        abbonamentiRichiesti={abbonamentiRichiesti}
                        idAbbonamentiRichiesti={idAbbonamentiRichiesti}
                        posizioniRichieste={posizioniRichieste}
                        idPosizioniRichieste={idPosizioniRichieste}
                        abbonamentiPossibili={abbonamentiPossibili}
                        posizioniPossibili={posizioniPossibili}
                        mioId={props.identitaSelezionata.idpersona}
                        callbackRequisitiAggiungiRimuovi={callbackRequisitiAggiungiRimuovi}
                    /> 
                }
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}> 
                <CardTitle>Impostazioni Controlli Accesso Struttura</CardTitle> 
                {((abbonamentiRichiesti === undefined || abbonamentiRichiesti ==='' || abbonamentiRichiesti.length===0) &&
                  (posizioniRichieste === undefined || posizioniRichieste ==='' || posizioniRichieste.length===0))?
                  <b>L'Accesso alla struttura é riservato a tutti gli iscritti quindi non vincolato ad alcun Abbonamento e ad alcuna Posizione Sociale</b>
                :""}

                    {abbonamentiRichiesti !== undefined && abbonamentiRichiesti !=='' && abbonamentiRichiesti.length>0 ?
                      <b>Per poter accedere alla struttura è necessario avere un Abbonamento tra i seguenti in corso di validità:</b>
                    :""}
                    {abbonamentiRichiesti !== undefined && abbonamentiRichiesti !=='' && abbonamentiRichiesti.length>0 ?
                          abbonamentiRichiesti.map((elemento,index) => <div key={index}>
                            {abbonamentiRichiesti[index]}
                          </div>) 
                    :""}

                  {posizioniRichieste !== undefined && posizioniRichieste !=='' && posizioniRichieste.length>0 ?
                    abbonamentiRichiesti !== undefined && abbonamentiRichiesti !=='' && abbonamentiRichiesti.length>0 ?
                        <b>oppure è necessario avere una tra le seguenti Posizioni in Società:</b>
                      :<b>Per poter accedere alla struttura è necessario avere una tra le seguenti Posizioni in Società:</b>
                    :""}
                    
                    {posizioniRichieste !== undefined && posizioniRichieste !=='' && posizioniRichieste.length>0 ?
                          posizioniRichieste.map((elemento,index1) => <div key={index1}>
                            {posizioniRichieste[index1]}
                          </div>) 
                    :""}
                </div>
                <div className="schedaSpazioInferiore">
                        <>
                            <Button disabled={disabilitaBottoni} className="mt-2 bottoneMargineASinistra" color='primary' onClick={() => {setModaleRequisitiAggiungiRimuovi(true); setAzioneRequisiti("aggiungiRimuoviAbbonamento")}}>Aggiungi/Rimuovi Abbonamento</Button>
                            <Button disabled={disabilitaBottoni} className="mt-2 bottoneMargineASinistra" color='primary' onClick={() => {setModaleRequisitiAggiungiRimuovi(true); setAzioneRequisiti("aggiungiRimuoviPosizione")}}>Aggiungi/Rimuovi Posizione</Button>
                        </>
                                                                             
                </div>                            
            </div>
        );
    }

    // default, stato sbagliato
    return <Fragment><div>Errore SE-P{statoPagina}</div></Fragment>
}
export default FormGridFormRow;
