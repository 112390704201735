import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Button,Label,Input} from 'reactstrap';
import TendinaOrariAStep from '../Pagine/TendinaOrariAStep.js';

import Cestino from "../immagini/cestino.png"

const STATO_VISUALIZZA = 0;
const STATO_NON_VISUALIZZA = 1;

function TabellaOrari(props) {
    
    //in input ho:
    //props.orariGiorniDefault props.orariDalleDefault props.orariAlleDefault props.orariPostiDefault
    //contenenti tutti i valori di default per ogni singolo campo
  
    //in output fornisco:
    //props.callbackSetVariazioneCampi(1); qualora andassi a modificare (anche cancellandolo) o ad aggiungere un valore
    //props.callbacksetOrari mi darà il nuovo array con i valori
    const [statoPagina,setStatoPagina]=useState(STATO_NON_VISUALIZZA);
    const [rivisualizza,setRivisualizza]=useState(0);

    const [orariGiorni,setOrariGiorni]=useState([]);
    const [orariDalle,setOrariDalle]=useState([]);
    const [orariAlle,setOrariAlle]=useState([]);
    const [orariPosti,setOrariPosti]=useState([]);
    const [prenotazioniMin,setPrenotazioniMin]=useState([]);

    const giorniDisponibilita   = useRef([]);
    //const orarioInizioDisponibilita   = useRef([]);
    //const orarioFineDisponibilita   = useRef([]);
    const disponibilita   = useRef([]);
    const prenMin   = useRef([]);

    const [durataMinima,setDurataMinima]=useState(props.durataMinima);

    const [intervalloTendina,setIntervalloTendina]=useState(0);
    
    useEffect(() => {
        if(props.orariGiorniDefault!== undefined) setOrariGiorni(props.orariGiorniDefault);
        if(props.orariDalleDefault!== undefined) setOrariDalle(props.orariDalleDefault);
        if(props.orariAlleDefault!== undefined) setOrariAlle(props.orariAlleDefault);
        if(props.orariPostiDefault!== undefined) setOrariPosti(props.orariPostiDefault);
        if(props.prenMinDefault!== undefined) setPrenotazioniMin(props.prenMinDefault);
        setStatoPagina(STATO_VISUALIZZA);
        if(props.setDurataMinima!== undefined) setDurataMinima(props.durataMinima);
        setIntervalloTendina(5);
    },[])

    useEffect(() => {
        setStatoPagina(STATO_VISUALIZZA);
    },[rivisualizza])

    function handleCambiamentoMod(event) {
        props.callbackSetVariazioneCampi(1);
    }

    function callbackSetVariazioneCampiOrari(){
        //setStatoPagina(STATO_NON_VISUALIZZA);
        props.callbackSetVariazioneCampi(1);
    }
    function callbacksetOrarioSelezionatoInizio(valore,props){
        //console.log("indice dalle"+props.indice);
        //console.log("valore dalle"+valore);
        aggiornaDalle(props.indice,valore)
    }
    function callbacksetOrarioSelezionatoFine(valore,props){
        //console.log("indice alle"+props.indice);
        //console.log("valore alle"+valore);
        //console.log(valore);
        aggiornaAlle(props.indice,valore)
    }
    

    function aggiornaGiorni(index,dato){
        let dati = [...orariGiorni];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setOrariGiorni(dati);//aggiorno nuovamente la var di stato
        props.callbacksetOrari(dati,orariDalle,orariAlle,orariPosti,prenotazioniMin);
        setRivisualizza(rivisualizza+1);
    }
    function aggiornaDalle(index,dato){
        let dati = [...orariDalle];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setOrariDalle(dati);//aggiorno nuovamente la var di stato
        props.callbacksetOrari(orariGiorni,dati,orariAlle,orariPosti,prenotazioniMin);
        setRivisualizza(rivisualizza+1);
    }
    function aggiornaAlle(index,dato){
        let dati = [...orariAlle];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setOrariAlle(dati);//aggiorno nuovamente la var di stato
        props.callbacksetOrari(orariGiorni,orariDalle,dati,orariPosti,prenotazioniMin);
        setRivisualizza(rivisualizza+1);
    }
    function aggiornaDisponibilita(index,dato){
        let dati = [...orariPosti];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setOrariPosti(dati);//aggiorno nuovamente la var di stato
        props.callbacksetOrari(orariGiorni,orariDalle,orariAlle,dati,prenotazioniMin);
        setRivisualizza(rivisualizza+1);
    }
    function aggiornaPrenMin(index,dato){
        let dati = [...prenotazioniMin];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setPrenotazioniMin(dati);//aggiorno nuovamente la var di stato
        props.callbacksetOrari(orariGiorni,orariDalle,orariAlle,orariPosti,dati);
        setRivisualizza(rivisualizza+1);
    }
     
    function aggiungiOrario(props) {
        let giorni = [...orariGiorni];//popolo l'array locale con i dati dell'array di stato
        giorni.push("1");//aggiungo un elemento all'array locale
        setOrariGiorni(giorni);//aggiorno nuovamente la var di stato

        let dalle = [...orariDalle];//popolo l'array locale con i dati dell'array di stato
        dalle.push("00:00");//aggiungo un elemento all'array locale
        setOrariDalle(dalle);//aggiorno nuovamente la var di stato

        //alle và calcolato in base alla durata minima:
        let alleLocale = calcolaAlle("00:00",intervalloTendina);
    
        let alle = [...orariAlle];//popolo l'array locale con i dati dell'array di stato
        alle.push(alleLocale);//aggiungo un elemento all'array locale
        setOrariAlle(alle);//aggiorno nuovamente la var di stato

        let posti = [...orariPosti];//popolo l'array locale con i dati dell'array di stato
        posti.push(props.maxPartecipantiDefault);//aggiungo un elemento (per ora numero max partecipanti default) all'array locale
        setOrariPosti(posti);//aggiorno nuovamente la var di stato

        let prenMin = [...prenotazioniMin];//popolo l'array locale con i dati dell'array di stato
        prenMin.push(props.minPartecipantiDefault);//aggiungo un elemento (per ora numero max partecipanti default) all'array locale
        setPrenotazioniMin(prenMin);//aggiorno nuovamente la var di stato

        //setAggiorna(aggiorna+1);
        props.callbacksetOrari(giorni,dalle,alle,posti,prenMin);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 
        props.callbackSetVariazioneCampi(1);
        setRivisualizza(rivisualizza+1);
        
    }

    function calcolaAlle(partenza,intervalloTendina){
        //fornendo partenza in formato hh:ss e durata minima in minuti, ti restituisce la data di fine giornata ovvero alle

        //trasformo la partenza in minuti:
        let partenzaMinuti = 0;
        const myArray = partenza.split(":");
        partenzaMinuti = parseInt(myArray[1]) + parseInt(myArray[0])*60;

        //imposto un ciclo nel quale, partendo dalla 'partenza', incemento tante volte fino ad arrivare all'ultimo valore utile a fine giornata:
        let blocca = 0;
        let step = partenzaMinuti;
        let stepPrecedente = 0;
        let contatoreSicurezza = 0;
        while (blocca === 0 && contatoreSicurezza < 1500) {
            stepPrecedente = step;
            //console.log("step"+step);
            step = step + parseInt(intervalloTendina);//incremento di tenti minuti quanti sono quelli della durata minima
            //condizione di uscita: se sono arrivato oltre i 1440 minuti (24 ore) blocco!
            if(step>1440) blocca = 1;
            contatoreSicurezza = contatoreSicurezza + 1;
        }
        //console.log("stepPrecedente"+stepPrecedente);
        //ora ho l'ultimo appuntamento prendibile in minuti: trasfrormo in hh:mm
        let stepPrecedenteOre = 0;
        let stepPrecedenteMinuti = 0;
        let stringaOre = '';
        let stringaMinuti = '';
        stepPrecedenteOre = Math.floor(stepPrecedente/60);
        //console.log("stepPrecedenteOre"+stepPrecedenteOre);
        stepPrecedenteMinuti = stepPrecedente - stepPrecedenteOre * 60;
        //console.log("stepPrecedenteMinuti"+stepPrecedenteMinuti);
        if (stepPrecedenteOre<=9) stringaOre = "0"+stepPrecedenteOre.toString();
        else stringaOre = stepPrecedenteOre.toString();
        //console.log("stringaOre"+stringaOre);
        if (stepPrecedenteMinuti<=9) stringaMinuti = "0"+stepPrecedenteMinuti.toString();
        else stringaMinuti = stepPrecedenteMinuti.toString();
        //console.log("stringaMinuti"+stringaMinuti);
        return stringaOre+":"+stringaMinuti;
    }


    function eliminaOrario(posizione) {
        let giorni = [...orariGiorni];//popolo l'array locale con i dati dell'array di stato
        giorni.splice(posizione, 1);//cancello l'elemento desiderato
        setOrariGiorni(giorni);//aggiorno nuovamente la var di stato
       
        let dalle = [...orariDalle];//popolo l'array locale con i dati dell'array di stato
        dalle.splice(posizione, 1);//cancello l'elemento desiderato
        setOrariDalle(dalle);//aggiorno nuovamente la var di stato

        let alle = [...orariAlle];//popolo l'array locale con i dati dell'array di stato
        alle.splice(posizione, 1);//cancello l'elemento desiderato
        setOrariAlle(alle);//aggiorno nuovamente la var di stato

        let posti = [...orariPosti];//popolo l'array locale con i dati dell'array di stato
        posti.splice(posizione, 1);//cancello l'elemento desiderato
        setOrariPosti(posti);//aggiorno nuovamente la var di stato
      
        let prenMin= [...prenotazioniMin];//popolo l'array locale con i dati dell'array di stato
        prenMin.splice(posizione, 1);//cancello l'elemento desiderato
        setPrenotazioniMin(prenMin);//aggiorno nuovamente la var di stato

        props.callbacksetOrari(giorni,dalle,alle,posti,prenMin);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 
        props.callbackSetVariazioneCampi(1);
        setRivisualizza(rivisualizza+1);
        
    }
    function funzionePrenotabileSempre() {
        
        //lo rendo prenotabile 0-24 tutti i giorni con il numero max di posti:
        setOrariGiorni(['1','2','3','4','5','6','0']);
        setOrariDalle(["00:00","00:00","00:00","00:00","00:00","00:00","00:00"]);
        setOrariAlle([calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),
        calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina)]);
        setOrariPosti([props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault]);
        setPrenotazioniMin([props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault]);
        props.callbacksetOrari(['1','2','3','4','5','6','0'],
                ["00:00","00:00","00:00","00:00","00:00","00:00","00:00"],
                [calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),
                calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina),calcolaAlle("00:00",intervalloTendina)],
                [props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault,props.maxPartecipantiDefault],
                [props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault,props.minPartecipantiDefault]
            );//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 
        props.callbackSetVariazioneCampi(1);
        setRivisualizza(rivisualizza+1);  
    }
    function funzioneResetta() {
        //resetto tutte le prenotabilità:
        let giorni = [];
        let dalle = [];
        let alle = [];
        let posti = [];
        let prenMin = [];
        setOrariGiorni(giorni);
        setOrariDalle(dalle);
        setOrariAlle(alle);
        setOrariPosti(posti);
        setPrenotazioniMin(prenMin);
        props.callbacksetOrari(giorni,dalle,alle,posti,prenMin);//passo alla pagina principale la var locale perchè quella di stato ancora non è pronta 
        props.callbackSetVariazioneCampi(1);
        setRivisualizza(rivisualizza+1);
    }

    return (
        
    
      <div>
          
        {statoPagina === STATO_NON_VISUALIZZA ? <div>Memorizzazione</div> :""}
        {statoPagina === STATO_VISUALIZZA ?
        <Fragment>
  
     
            <Row>
                <Col md="12">
                   
                        
                        {orariGiorni.map((elemento,index) => <div style={{display:"flex", flexDirection:"row"}} key={index}>
                            
                            <div style={{minWidth: 40,paddingRight:10,paddingTop:30}}>
                            {/*<Button color="danger" className="mt-2" onClick={() => {props.callbackSetVariazioneCampi(1);eliminaOrario(index)}} >Elimina</Button>*/}
                            <img onClick={() => {props.callbackSetVariazioneCampi(1);eliminaOrario(index)}} src={Cestino}/>
                            </div>
                            

                            <div style={{flex:1.2,minWidth: 100,paddingRight:10}}>
                            <Label for="giorno">Giorno&nbsp;</Label><br></br>   

                            {/*<Input type="select" style={{width: 'auto',height: 36,borderRadius: '5px 5px 5px 5px'}} name="giorniDisponibilita[index]" id="giorniDisponibilita[index]" innerRef={giorniDisponibilita[index]}  onInput={(e)=>{handleCambiamentoMod(e);aggiornaGiorni(index,e.target.value)}}>
*/}
                            <select 
                                onInput={(e)=>{handleCambiamentoMod(e);aggiornaGiorni(index,e.target.value)}}
                                className="inputStandard">
                                {orariGiorni[index] === '1' || orariGiorni[index] === '' ? <option selected value="1">Lunedì</option> : <option value="1">Lunedì</option> }
                                {orariGiorni[index] === '2' ? <option selected value="2">Martedì</option> : <option value="2">Martedì</option> }
                                {orariGiorni[index] === '3' ? <option selected value="3">Mercoledì</option> : <option value="3">Mercoledì</option> }
                                {orariGiorni[index] === '4' ? <option selected value="4">Giovedì</option> : <option value="4">Giovedì</option> }
                                {orariGiorni[index] === '5' ? <option selected value="5">Venerdì</option> : <option value="5">Venerdì</option> }
                                {orariGiorni[index] === '6' ? <option selected value="6">Sabato</option> : <option value="6">Sabato</option> }
                                {orariGiorni[index] === '0' ? <option selected value="0">Domenica</option> : <option value="0">Domenica</option> }
                            
                            </select>
                            {/*</Input>*/}
                            </div>
                            {/*secondo campo: orario di inizio*/}

                            
                            <div style={{flex:0.5,minWidth: 100,paddingRight:10}}>
                            <Label for="orarioInizio">Orario Inizio&nbsp;</Label>
                            
                            <TendinaOrariAStep
                                step = {intervalloTendina} //step espresso in minuti tra un orario ed il successivo
                                orarioDefault= {orariDalle[index]}
                                orarioInizio = "00:00"
                                orarioFine= "24:00"
                                indice = {index}
                                callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                callbacksetOrarioSelezionato={callbacksetOrarioSelezionatoInizio}
                            ></TendinaOrariAStep>
                            </div>
                            

                            
                            <div style={{flex:0.5,minWidth: 100,paddingRight:10}}>
                            <Label for="orarioFineDisponibilita">Orario Fine&nbsp;</Label>
                            <TendinaOrariAStep
                                step = {intervalloTendina} //step espresso in minuti tra un orario ed il successivo
                                orarioDefault= {orariAlle[index]}
                                orarioInizio = "00:00"
                                orarioFine= "24:00"
                                indice = {index}
                                callbackSetVariazioneCampi={callbackSetVariazioneCampiOrari}
                                callbacksetOrarioSelezionato={callbacksetOrarioSelezionatoFine}
                            ></TendinaOrariAStep>
                            </div>

                            {props.nomedb == 'EMA18' || props.nomedb == 'EMA48' ?
                                <div style={{flex:2,minWidth: 100,paddingRight:10}}>
                                <Label for="prenMin">Num. Min. Prenotazioni</Label>
                                <Input maxLength={11} type="text" name="prenMin[index]" id="prenMin[index]" value={prenotazioniMin[index]} innerRef={prenMin[index]} 
                                placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaPrenMin(index,e.target.value)}}/> 
                                </div>
                            :""}

                            <div style={{flex:12}}>
                            <Label for="disponibilita">Posti</Label>
                            <Input maxLength={11} type="text" name="disponibilita[index]" id="disponibilita[index]" value={orariPosti[index]} innerRef={disponibilita[index]} 
                             placeholder="" onInput={(e)=>{handleCambiamentoMod(e);aggiornaDisponibilita(index,e.target.value)}}/> 
                            </div>

                            
                            </div>)
                        }

                        <Row><Col md={12}><Button color="primary" className="mt-2 bottoneMargineADestra" onClick={() => {
                            //setStatoPagina(STATO_NON_VISUALIZZA);
                            props.callbackSetVariazioneCampi(1);
                            aggiungiOrario(props)}} 
                        >Aggiungi Orario</Button>
        
                        {orariGiorni.length > 0 ?
                        <Button color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {funzioneResetta()}}>Resetta</Button>
                        :""}
                        <Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => {funzionePrenotabileSempre()}}>Rendi Sempre Prenotabile</Button>
                      
                        </Col></Row>

                    
                     
                </Col>   
            </Row>
          

        </Fragment>
        :""}
       
    </div>
   );
}
export default TabellaOrari;
