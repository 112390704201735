import React, {Fragment,useState,useEffect,useRef} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button,FormGroup,Label,Input,Modal } from 'reactstrap';
import Parametri from '../../parametri';
import arrayColoriGrafico from '../../coloriGrafici';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

//per grafici:
//import {Bar} from 'react-chartjs-2';
//import {Pie} from 'react-chartjs-2';

import {Bar} from 'react-chartjs-2';//istogramma a barre verticali
import {Doughnut} from 'react-chartjs-2';//un grafico a torta con stile ciambella
//import {HorizontalBar} from 'react-chartjs-2';//istogramma a barre orizzontali
import {Line} from 'react-chartjs-2';//grafico con linea su piano cartesiano
import {Pie} from 'react-chartjs-2';//la classica torta
import {PolarArea} from 'react-chartjs-2';//tipo torta ma con un aspetto grafico più ricercato e tecnologico
import {Radar} from 'react-chartjs-2';//tipo radar con ai vertici le voci; è possibile rappresentare più dataset
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

//per pdf:
//import html2canvas from "html2canvas";//(tentativo 1)
//import { jsPDF } from "jspdf";//(tentativo 1)

//import html2pdf from 'html2pdf.js'//npm install --save html2pdf.js (https://github.com/eKoopmans/html2pdf.js) (tentativo 2)

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import styles from '../stiliPdf.js'

import { calcolaAnnoOggi } from '../../funzioni.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;


const COLORE_TITOLI_REPORT="#a5a5a4";

function TabellaReportEconEsterni(props) {

    const [ricercaCompletata,setRicercaCompletata]=useState(0);

    const speseGraficoNettiCanvas = useRef();
    const speseGraficoIvaCanvas = useRef();
    const speseGraficoCanvas = useRef();
    const incassiDiversiGraficoNettiCanvas = useRef();
    const incassiDiversiGraficoIvaCanvas = useRef();
    const incassiDiversiGraficoCanvas = useRef();
    const totaleGraficoNettiCanvas = useRef();
    const totaleGraficoIvaCanvas = useRef();
    const totaleGraficoCanvas = useRef();
    
    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);
    const [topDefault,setTopDefault]=useState('10');
    const top = useRef();

    const coloriGraficoSfondo=arrayColoriGrafico;
    const coloriGraficoSfondoIva=arrayColoriGrafico;
    const coloriGraficoSfondoNetti=arrayColoriGrafico;
    
    const coloriGraficoBordo='rgb(0,0,0)';
    const coloriGraficoBordoIva='rgb(0,0,0)';
    const coloriGraficoBordoNetti='rgb(0,0,0)';

    const larghezzaBordoGrafico=1;

    const larghColonnaDati=1;
    const [LarghColonnaEnte,setLarghColonnaEnte]=useState(12);
    
    const flagVisualizzaImportiNetti   = useRef();
    const flagVisualizzaImportiIva   = useRef();
    const flagVisualizzaImportiLordi   = useRef();
    const [flagVisualizzaImportiNettiDefault,setflagVisualizzaImportiNettiDefault]=useState(1);
    const [flagVisualizzaImportiIvaDefault,setflagVisualizzaImportiIvaDefault]=useState(1);
    const [flagVisualizzaImportiLordiDefault,setflagVisualizzaImportiLordiDefault]=useState(1);

    const  radioPeriodo = useRef();
    const [radioPeriodoDefault,setRadioPeriodoDefault]=useState("1");

    const operazione = "reportSituazioneEconomicaEsterni";

    //const  intervallo = useRef();
    const  dataPartenza = useRef();
    
    const [risultatoServer,setRisultatoServer]=useState('');
    //const [entiScadenza,setEntiScadenza]=useState('');
    
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
   
    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    
    //per paginato:
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    //per grafici:
    const [dataSpeseNetti,setDataSpeseNetti]=useState([]);
    const [dataSpeseIva,setDataSpeseIva]=useState([]);
    const [dataSpese,setDataSpese]=useState([]);
 
    const [dataIncassiDiversiNetti,setDataIncassiDiversiNetti]=useState([]);
    const [dataIncassiDiversiIva,setDataIncassiDiversiIva]=useState([]);
    const [dataIncassiDiversi,setDataIncassiDiversi]=useState([]);

    const [dataTotaleNetti,setDataTotaleNetti]=useState([]);
    const [dataTotaleIva,setDataTotaleIva]=useState([]);
    const [dataTotale,setDataTotale]=useState([]);

    //DATI DA VISIONARE:
    const [arrayPersone,setArrayPersone]=useState([]);
    const [arraySpeseImporti,setArraySpeseImporti]=useState([]);
    const [arraySpeseImportiNetto,setArraySpeseImportiNetto]=useState([]);
    const [arraySpeseImportiIva,setArraySpeseImportiIva]=useState([]);
    const [arrayIncassiDiversiImporti,setArrayIncassiDiversiImporti]=useState([]);
    const [arrayIncassiDiversiImportiNetto,setArrayIncassiDiversiImportiNetto]=useState([]);
    const [arrayIncassiDiversiImportiIva,setArrayIncassiDiversiImportiIva]=useState([]);

    const [statoTotaleSpeseAnno,setStatoTotaleSpeseAnno]=useState(0);
    const [statoTotaleSpeseAnnoNetto,setStatoTotaleSpeseAnnoNetto]=useState(0);
    const [statoTotaleSpeseAnnoIva,setStatoTotaleSpeseAnnoIva]=useState(0);
    const [statoTotaleIncassiDiversiAnno,setStatoTotaleIncassiDiversiAnno]=useState(0);
    const [statoTotaleIncassiDiversiAnnoNetto,setStatoTotaleIncassiDiversiAnnoNetto]=useState(0);
    const [statoTotaleIncassiDiversiAnnoIva,setStatoTotaleIncassiDiversiAnnoIva]=useState(0);
    
    //consensi visualizzazione grafici:
    const [visualizzaSpese,setVisualizzaSpese]=useState(1);
    const [visualizzaIncassiDiversi,setVisualizzaIncassiDiversi]=useState(1);
    const [visualizzaTotale,setVisualizzaTotale]=useState(1);

    //tipologie di grafici per ogni sezione:
    const [tipoGraficoSpese,setTipoGraficoSpese]=useState("Bar");
    const [tipoGraficoIncassiDiversi,setTipoGraficoIncassiDiversi]=useState("Bar");
    const [tipoGraficoTotale,setTipoGraficoTotale]=useState("Bar");

    //consensi visualizzazione tabelle:
    const [visualizzaTab,setVisualizzaTab]=useState(1);

    const [arrayFornitoriTopSpese,setArrayFornitoriTopSpese]=useState([]);
    const [arraySpeseTopImporti,setArraySpeseTopImporti]=useState([]);
    const [arrayFornitoriTopSpeseNetto,setArrayFornitoriTopSpeseNetto]=useState([]);
    const [arraySpeseNettoTopImporti,setArraySpeseNettoTopImporti]=useState([]);
    const [arrayFornitoriTopSpeseIva,setArrayFornitoriTopSpeseIva]=useState([]);
    const [arraySpeseIvaTopImporti,setArraySpeseIvaTopImporti]=useState([]);

    const [arrayFornitoriTopIncassiFornitori,setArrayFornitoriTopIncassiFornitori]=useState([]);
    const [arrayIncassiFornitoriTopImporti,setArrayIncassiFornitoriTopImporti]=useState([]);
    const [arrayFornitoriTopIncassiFornitoriNetto,setArrayFornitoriTopIncassiFornitoriNetto]=useState([]);
    const [arrayIncassiFornitoriNettoTopImporti,setArrayIncassiFornitoriNettoTopImporti]=useState([]);
    const [arrayFornitoriTopIncassiFornitoriIva,setArrayFornitoriTopIncassiFornitoriIva]=useState([]);
    const [arrayIncassiFornitoriIvaTopImporti,setArrayIncassiFornitoriIvaTopImporti]=useState([]);

    const [arrayFornitoriTopTotale,setArrayFornitoriTopTotale]=useState([]);
    const [arrayTotaleTopImporti,setArrayTotaleTopImporti]=useState([]);
    const [arrayFornitoriTopTotaleNetto,setArrayFornitoriTopTotaleNetto]=useState([]);
    const [arrayTotaleNettoTopImporti,setArrayTotaleNettoTopImporti]=useState([]);
    const [arrayFornitoriTopTotaleIva,setArrayFornitoriTopTotaleIva]=useState([]);
    const [arrayTotaleIvaTopImporti,setArrayTotaleIvaTopImporti]=useState([]);

    const [graficoSpeseImporti,setGraficoSpeseImporti]=useState(0);
    const [graficoSpeseIva,setGraficoSpeseIva]=useState(0);
    const [graficoSpeseNetti,setGraficoSpeseNetti]=useState(0);

    const [graficoIncassiImporti,setGraficoIncassiImporti]=useState(0);
    const [graficoIncassiIva,setGraficoIncassiIva]=useState(0);
    const [graficoIncassiNetti,setGraficoIncassiNetti]=useState(0);

    const [graficoTotaleImporti,setGraficoTotaleImporti]=useState(0);
    const [graficoTotaleIva,setGraficoTotaleIva]=useState(0);
    const [graficoTotaleNetti,setGraficoTotaleNetti]=useState(0);

    const [visualizzaAltro,setVisualizzaAltro]=useState(0);
    const  visualizzaAltroRif = useRef();

    //nomi dei tipi di grafici: (se cambi vai a modificare anche le select dei tipi che ora sono in ordine alfabetico secondo quete deciture)
    let descriDoughnut = "Ciambella";
    let descriLine = "Funzione";
    let descriBar = "Istogramma verticale";
    let descriPolarArea = "Polare";
    let descriRadar = "Radar";
    let descriPie =  "Torta";

    const  TGSpese = useRef();
    const  TGIncassi = useRef();
    const  TGSpeseIncassi = useRef();
    //modale per attivar le impostazioni:
    const [modaleImpostazioni, setModaleImpostazioni] = React.useState(false);

    const [tableColStato,setTableColStato]=useState(0);
    /*const styles = StyleSheet.create({
      page: {flexDirection: 'row',paddingBottom: 50,paddingTop: 50,},
      titolo: {fontFamily: 'Helvetica-Bold',marginLeft: 1, fontSize: 18},
      titoloPiccolo: {fontFamily: 'Helvetica-Bold',marginLeft: 10, fontSize: 10},
      image: {height: 150,width: 200},
      logo: {height: 60,width: 80,objectFit: 'contain'},
      nomesocieta: {fontFamily: 'Helvetica-Bold',marginLeft: 1,marginBottom: 5, fontSize: 22,},
      viewer: {height: '100vh',width: '150vh'},

      table: {display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0,margin: 10, }, 
      tableIntestazione: { display: "table", width: "auto", borderRightWidth: 0, borderBottomWidth: 0,margin: 10,}, 
      tableRowGrigia: { margin: "auto", flexDirection: "row",height: "auto",backgroundColor : "silver",}, 
      tableRow: { margin: "auto", flexDirection: "row",height: "auto",}, 
      tableRowIntestazione: { margin: "auto", flexDirection: "row",height: 60,}, 
      tableRowBlu: { margin: "auto", flexDirection: "row",backgroundColor : "#99cbff",height: "auto",}, 
      tableColIntestazioneSx: { width: 100/3+"%", borderLeftWidth: 0, borderTopWidth: 0},
      tableColIntestazioneDx: { width: 2*100/3+"%", borderLeftWidth: 0, borderTopWidth: 0 },
      tableCol: { width: 100/4+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, }, 
      tableCell: { margin: "auto", fontSize: 10, },
      tableCellIntestazione: { marginLeft: 0,fontSize: 10},
      tableCellGrassettoAllineatoSinistra: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoSinistraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'left',marginLeft: 5, fontSize: 10,marginBottom: 3},
      tableCellGrassettoAllineatoDestra: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellGrassettoAllineatoDestraNoBorderTop: {fontFamily: 'Helvetica-Bold',textAlign: 'right',marginRight: 5, fontSize: 10,marginBottom: 3},
      tableCellAllineatoSinistra: {textAlign: 'left',marginLeft: 5, fontSize: 10,marginTop: 3,marginBottom: 3},
      tableCellAllineatoDestra: {textAlign: 'right',marginRight: 5, fontSize: 10,marginTop: 3,marginBottom: 3},

      grafici: {width: 300,height: 300,},
      graficiLunghi: {width: 400,height: 200,},
      pageNumber:{position: "absolute",fontSize: 12,bottom:30,left:0,right:0,textAlign:'center',color:"gray"}
    });*/

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [cambiamento,setCambiamento] = useState(0);
    const [ricercaImpostazioniReport,setRicercaImpostazioniReport] = useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
      let sommaColonne = 1;
      let larghezza_colonna_ente_locale = 12;
      if(flagVisualizzaImportiNettiDefault === 1){
        sommaColonne = sommaColonne + 3;
        larghezza_colonna_ente_locale = larghezza_colonna_ente_locale - 3;
      }
      if(flagVisualizzaImportiIvaDefault === 1){
        sommaColonne = sommaColonne + 3;
        larghezza_colonna_ente_locale = larghezza_colonna_ente_locale - 3;
      }
      if(flagVisualizzaImportiLordiDefault === 1){
        sommaColonne = sommaColonne + 3;
        larghezza_colonna_ente_locale = larghezza_colonna_ente_locale - 3;
      }
      
      setLarghColonnaEnte(larghezza_colonna_ente_locale);
      setTableColStato(sommaColonne);
      if(statoPagina === STATO_PREPARA_PDF){
        setStatoPagina(STATO_SCARICA_PDF);
        /*setTimeout(() => {
          setStatoPagina(STATO_SCARICA_PDF);
        }, 1000);*/
      }
    },[statoPagina,flagVisualizzaImportiNettiDefault,flagVisualizzaImportiIvaDefault,flagVisualizzaImportiLordiDefault]);

    useEffect(() => {
      if(ricercaCompletata === 1
        && dataSpeseNetti
        && dataSpeseIva
        && dataSpeseNetti
        && dataIncassiDiversiNetti
        && dataIncassiDiversiIva
        && dataIncassiDiversi
        && dataTotaleNetti
        && dataTotaleIva
        && dataTotale
        && ricercaLogo === 1
        && ricercaImpostazioniReport === 1
        ){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
        setRicercaCompletata(0);
      }
    },[ricercaCompletata,ricercaLogo,ricercaImpostazioniReport])

    useEffect(() => { 
      //grafico spese
      setDataSpeseNetti({labels: [''],
          datasets: [{label: 'Spese (Imponibili)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataSpeseIva({labels: [''],
          datasets: [{label: 'Spese (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataSpeseNetti({labels: [''],
          datasets: [{label: 'Spese (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      //grafico incassi
      setDataIncassiDiversiNetti({labels: [''],
          datasets: [{label: 'Incassi (Imponibili)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataIncassiDiversiIva({labels: [''],
          datasets: [{label: 'Incassi (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataIncassiDiversi({labels: [''],
          datasets: [{label: 'Incassi (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

      //grafico totale
      setDataTotaleNetti({labels: [''],
          datasets: [{label: 'Totale (Imponibili)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataTotaleIva({labels: [''],
          datasets: [{label: 'Totale (IVA)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});
      setDataTotale({labels: [''],
          datasets: [{label: 'Totale (Importi)',backgroundColor: '',borderColor: '',borderWidth: 1,
          hoverBackgroundColor: '',hoverBorderColor: '',borderCapStyle: '',data: ['']}]});

          scaricaLogo();
          trovaImpostazioniReport();

      },[])

    useEffect(() => {
      //grafico Spese Netti
      if(arrayFornitoriTopSpeseNetto.length>0){
      setDataSpeseNetti({labels: arrayFornitoriTopSpeseNetto,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arraySpeseNettoTopImporti
      }
      ]});
    }
    },[arraySpeseNettoTopImporti])

    useEffect(() => {
      //grafico Spese IVA
      if(arrayFornitoriTopSpeseIva.length>0){
      setDataSpeseIva({labels: arrayFornitoriTopSpeseIva,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoIva,
          borderColor: coloriGraficoBordoIva,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arraySpeseIvaTopImporti
      }
      ]});
    }
    },[arraySpeseIvaTopImporti])

    useEffect(() => {
      //grafico Spese Lordi
      if(arrayFornitoriTopSpese.length>0){
      setDataSpese({labels: arrayFornitoriTopSpese,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arraySpeseTopImporti
      }
      ]});
    }
    },[arraySpeseTopImporti])

    useEffect(() => {
      //grafico incassi diversi netti
      if(arrayFornitoriTopIncassiFornitoriNetto.length>0){
      setDataIncassiDiversiNetti({labels: arrayFornitoriTopIncassiFornitoriNetto,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayIncassiFornitoriNettoTopImporti
      }
      ]});
    }
    },[arrayIncassiFornitoriNettoTopImporti])

    useEffect(() => {
      //grafico incassi diversi iva
      if(arrayFornitoriTopIncassiFornitoriIva.length>0){
      setDataIncassiDiversiIva({labels: arrayFornitoriTopIncassiFornitoriIva,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoIva,
          borderColor: coloriGraficoBordoIva,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayIncassiFornitoriIvaTopImporti
      }
      ]});
    }
    },[arrayIncassiFornitoriIvaTopImporti])

    useEffect(() => {
      //grafico incassi diversi lordi
      if(arrayFornitoriTopIncassiFornitori.length>0){
      setDataIncassiDiversi({labels: arrayFornitoriTopIncassiFornitori,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayIncassiFornitoriTopImporti
      }
      ]});
    }
    },[arrayIncassiFornitoriTopImporti])

    //TOTALE

    useEffect(() => {
      //grafico Totale Netti
      if(arrayFornitoriTopTotaleNetto.length>0){
      setDataTotaleNetti({labels: arrayFornitoriTopTotaleNetto,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoNetti,
          borderColor: coloriGraficoBordoNetti,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTotaleNettoTopImporti
      }
      ]});
    }
    },[arrayTotaleNettoTopImporti])

    useEffect(() => {
      //grafico Totale IVA
      if(arrayFornitoriTopTotaleIva.length>0){
      setDataTotaleIva({labels: arrayFornitoriTopTotaleIva,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondoIva,
          borderColor: coloriGraficoBordoIva,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTotaleIvaTopImporti
      }
      ]});
    }
    },[arrayTotaleIvaTopImporti])

    useEffect(() => {
      //grafico Totale Lordi
      if(arrayFornitoriTopTotale.length>0){
      setDataTotale({labels: arrayFornitoriTopTotale,
      datasets: [
        {
          label: '',
          backgroundColor: coloriGraficoSfondo,
          borderColor: coloriGraficoBordo,
          borderWidth: larghezzaBordoGrafico,
          hoverBackgroundColor: arrayColoriGrafico,
          hoverBorderColor: 'rgba(255,99,132,1)',
          borderCapStyle: 'round',
          data: arrayTotaleTopImporti
      }
      ]});
    }
    },[arrayTotaleTopImporti])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI && ricercaImpostazioniReport===1){

        setGraficoSpeseImporti(0);
        setGraficoSpeseIva(0);
        setGraficoSpeseNetti(0);
        setGraficoIncassiImporti(0);
        setGraficoIncassiIva(0);
        setGraficoIncassiNetti(0);
        setGraficoTotaleImporti(0);
        setGraficoTotaleIva(0);
        setGraficoTotaleNetti(0);

        // parametri per inviare i dati al server:
        //console.log("RICERCA");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            op:operazione,
            sogliaTop:topDefault,
            visualizzaAltro:visualizzaAltro,
            dataPartenza:dataPartenzaDefault,
            radioPeriodo:radioPeriodoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              
              //console.log("RISULTATO"+valoreDiRitorno.risultatoOperazione);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale1=[];//spese -> persone
                let arrLocale2=[];//spese -> importi
                let arrLocale3=[];//incassi diversi -> importi
                let arrLocale4=[];//spese -> importi netti
                let arrLocale5=[];//spese -> importi iva
                let arrLocale6=[];//spese -> importi netti
                let arrLocale7=[];//spese -> importi iva
                
                let totaleSpeseAnno = 0;
                let totaleSpeseAnnoNetto = 0;
                let totaleSpeseAnnoIva = 0;
                let totaleIncassiDiversiAnno = 0;
                let totaleIncassiDiversiAnnoNetto = 0;
                let totaleIncassiDiversiAnnoIva = 0;

                let arrLocale1TopSpese =[];
                let arrLocale5TopSpese =[];
                let arrLocale1TopSpeseNetto =[];
                let arrLocale5TopSpeseNetto =[];
                let arrLocale1TopSpeseIva =[];
                let arrLocale5TopSpeseIva =[];

                let arrLocale1TopIncassiFornitori =[];
                let arrLocale5TopIncassiFornitori =[];
                let arrLocale1TopIncassiFornitoriNetto =[];
                let arrLocale5TopIncassiFornitoriNetto =[];
                let arrLocale1TopIncassiFornitoriIva =[];
                let arrLocale5TopIncassiFornitoriIva =[];

                let arrLocale1TopTotale =[];
                let arrLocale5TopTotale =[];
                let arrLocale1TopTotaleNetto =[];
                let arrLocale5TopTotaleNetto =[];
                let arrLocale1TopTotaleIva =[];
                let arrLocale5TopTotaleIva =[];
    
                for (let i=0; i<valoreDiRitorno.risultatoRitorno[0].dati.length ; i++) {
                  arrLocale1[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].PERSONA;
                  setArrayPersone(arrLocale1);
                  arrLocale2[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_SPESE;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_SPESE) !== 0){
                     setGraficoSpeseImporti(1);
                     setGraficoTotaleImporti(1);
                  }
                  setArraySpeseImporti(arrLocale2);
                  arrLocale3[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_DIVERSI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_DIVERSI) !== 0){
                    setGraficoIncassiImporti(1);
                    setGraficoTotaleImporti(1);
                  } 
                  setArrayIncassiDiversiImporti(arrLocale3);
                  arrLocale4[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_SPESE;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_SPESE) !== 0){
                    setGraficoSpeseNetti(1);
                    setGraficoTotaleNetti(1);
                  }
                  setArraySpeseImportiNetto(arrLocale4);
                  arrLocale5[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_SPESE;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_SPESE) !== 0){
                    setGraficoSpeseIva(1);
                    setGraficoTotaleIva(1);
                  }
                  setArraySpeseImportiIva(arrLocale5);
                  arrLocale6[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_INCASSI_DIVERSI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_INCASSI_DIVERSI) !== 0){
                    setGraficoIncassiNetti(1);
                    setGraficoTotaleNetti(1);
                  }
                  setArrayIncassiDiversiImportiNetto(arrLocale6);
                  arrLocale7[i]=valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_INCASSI_DIVERSI;
                  if(parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_INCASSI_DIVERSI) !== 0){
                    setGraficoIncassiIva(1);
                    setGraficoTotaleIva(1);
                  } 
                  setArrayIncassiDiversiImportiIva(arrLocale7);

                  for (let i1=0; i1<valoreDiRitorno.risultatoRitorno[1].topSpese.length ; i1++) {
                    arrLocale1TopSpese[i1]=valoreDiRitorno.risultatoRitorno[1].topSpese[i1].FORNITORE;
                    setArrayFornitoriTopSpese(arrLocale1TopSpese);
                    arrLocale5TopSpese[i1]=parseFloat(valoreDiRitorno.risultatoRitorno[1].topSpese[i1].IMPORTI);
                    setArraySpeseTopImporti(arrLocale5TopSpese);
                  }
                  for (let i2=0; i2<valoreDiRitorno.risultatoRitorno[2].topSpeseNetto.length ; i2++) {
                    arrLocale1TopSpeseNetto[i2]=valoreDiRitorno.risultatoRitorno[2].topSpeseNetto[i2].FORNITORE;
                    setArrayFornitoriTopSpeseNetto(arrLocale1TopSpeseNetto);
                    arrLocale5TopSpeseNetto[i2]=parseFloat(valoreDiRitorno.risultatoRitorno[2].topSpeseNetto[i2].IMPORTI);
                    setArraySpeseNettoTopImporti(arrLocale5TopSpeseNetto);
                  }
                  for (let i3=0; i3<valoreDiRitorno.risultatoRitorno[3].topSpeseIva.length ; i3++) {
                    arrLocale1TopSpeseIva[i3]=valoreDiRitorno.risultatoRitorno[3].topSpeseIva[i3].FORNITORE;
                    setArrayFornitoriTopSpeseIva(arrLocale1TopSpeseIva);
                    arrLocale5TopSpeseIva[i3]=parseFloat(valoreDiRitorno.risultatoRitorno[3].topSpeseIva[i3].IMPORTI);
                    setArraySpeseIvaTopImporti(arrLocale5TopSpeseIva);
                  }
                  for (let i4=0; i4<valoreDiRitorno.risultatoRitorno[4].topIncassiFornitori.length ; i4++) {
                    arrLocale1TopIncassiFornitori[i4]=valoreDiRitorno.risultatoRitorno[4].topIncassiFornitori[i4].FORNITORE;
                    setArrayFornitoriTopIncassiFornitori(arrLocale1TopIncassiFornitori);
                    arrLocale5TopIncassiFornitori[i4]=parseFloat(valoreDiRitorno.risultatoRitorno[4].topIncassiFornitori[i4].IMPORTI);
                    setArrayIncassiFornitoriTopImporti(arrLocale5TopIncassiFornitori);
                  }
                  for (let i5=0; i5<valoreDiRitorno.risultatoRitorno[5].topIncassiFornitoriNetto.length ; i5++) {
                    arrLocale1TopIncassiFornitoriNetto[i5]=valoreDiRitorno.risultatoRitorno[5].topIncassiFornitoriNetto[i5].FORNITORE;
                    setArrayFornitoriTopIncassiFornitoriNetto(arrLocale1TopIncassiFornitoriNetto);
                    arrLocale5TopIncassiFornitoriNetto[i5]=parseFloat(valoreDiRitorno.risultatoRitorno[5].topIncassiFornitoriNetto[i5].IMPORTI);
                    setArrayIncassiFornitoriNettoTopImporti(arrLocale5TopIncassiFornitoriNetto);
                  }
                  for (let i6=0; i6<valoreDiRitorno.risultatoRitorno[6].topIncassiFornitoriIva.length ; i6++) {
                    arrLocale1TopIncassiFornitoriIva[i6]=valoreDiRitorno.risultatoRitorno[6].topIncassiFornitoriIva[i6].FORNITORE;
                    setArrayFornitoriTopIncassiFornitoriIva(arrLocale1TopIncassiFornitoriIva);
                    arrLocale5TopIncassiFornitoriIva[i6]=parseFloat(valoreDiRitorno.risultatoRitorno[6].topIncassiFornitoriIva[i6].IMPORTI);
                    setArrayIncassiFornitoriIvaTopImporti(arrLocale5TopIncassiFornitoriIva);
                  }

                  for (let i7=0; i7<valoreDiRitorno.risultatoRitorno[7].topTotale.length ; i7++) {
                    arrLocale1TopTotale[i7]=valoreDiRitorno.risultatoRitorno[7].topTotale[i7].FORNITORE;
                    setArrayFornitoriTopTotale(arrLocale1TopTotale);
                    arrLocale5TopTotale[i7]=parseFloat(valoreDiRitorno.risultatoRitorno[7].topTotale[i7].IMPORTI);
                    setArrayTotaleTopImporti(arrLocale5TopTotale);
                  }
                  for (let i8=0; i8<valoreDiRitorno.risultatoRitorno[8].topTotaleNetto.length ; i8++) {
                    arrLocale1TopTotaleNetto[i8]=valoreDiRitorno.risultatoRitorno[8].topTotaleNetto[i8].FORNITORE;
                    setArrayFornitoriTopTotaleNetto(arrLocale1TopTotaleNetto);
                    arrLocale5TopTotaleNetto[i8]=parseFloat(valoreDiRitorno.risultatoRitorno[8].topTotaleNetto[i8].IMPORTI);
                    setArrayTotaleNettoTopImporti(arrLocale5TopTotaleNetto);
                  }
                  for (let i9=0; i9<valoreDiRitorno.risultatoRitorno[9].topTotaleIva.length ; i9++) {
                    arrLocale1TopTotaleIva[i9]=valoreDiRitorno.risultatoRitorno[9].topTotaleIva[i9].FORNITORE;
                    setArrayFornitoriTopTotaleIva(arrLocale1TopTotaleIva);
                    arrLocale5TopTotaleIva[i9]=parseFloat(valoreDiRitorno.risultatoRitorno[9].topTotaleIva[i9].IMPORTI);
                    setArrayTotaleIvaTopImporti(arrLocale5TopTotaleIva);
                  }
                
                  //totali:
                  totaleSpeseAnno = totaleSpeseAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_SPESE);
                  totaleIncassiDiversiAnno = totaleIncassiDiversiAnno + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_INCASSI_DIVERSI);
                  totaleSpeseAnnoNetto = totaleSpeseAnnoNetto + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_SPESE);
                  totaleSpeseAnnoIva = totaleSpeseAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_SPESE);
                  totaleIncassiDiversiAnnoNetto = totaleIncassiDiversiAnnoNetto + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_NETTO_INCASSI_DIVERSI);
                  totaleIncassiDiversiAnnoIva = totaleIncassiDiversiAnnoIva + parseFloat(valoreDiRitorno.risultatoRitorno[0].dati[i].IMPORTO_IVA_INCASSI_DIVERSI);
                }

                
                setInizioPeriodo(valoreDiRitorno.risultatoRitorno[10].periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.risultatoRitorno[10].periodo[0].DATA_FINE_PERIODO);
                
                //ora setto le var di stato dei totali:
                setStatoTotaleSpeseAnno(totaleSpeseAnno);
                setStatoTotaleIncassiDiversiAnno(totaleIncassiDiversiAnno);
                setStatoTotaleSpeseAnnoNetto(totaleSpeseAnnoNetto);
                setStatoTotaleSpeseAnnoIva(totaleSpeseAnnoIva);
                setStatoTotaleIncassiDiversiAnnoNetto(totaleIncassiDiversiAnnoNetto);
                setStatoTotaleIncassiDiversiAnnoIva(totaleIncassiDiversiAnnoIva);

                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
                setRicercaCompletata(1);
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina,ricercaImpostazioniReport])
    //},[props.numPagina])

    useEffect(() => {
      if(necessitaRicalcolo===1){
        //resetto tutte le var di stato:
        setArrayPersone([]);
        setArraySpeseImporti([]);
        setArrayIncassiDiversiImporti([]);

        setArraySpeseImportiNetto([]);
        setArraySpeseImportiIva([]);

        setArrayIncassiDiversiImportiNetto([]);
        setArrayIncassiDiversiImportiIva([]);

        setStatoTotaleSpeseAnno(0);
        setStatoTotaleIncassiDiversiAnno(0);

        setStatoTotaleSpeseAnnoNetto(0);
        setStatoTotaleSpeseAnnoIva(0);

        setStatoTotaleIncassiDiversiAnnoNetto(0);
        setStatoTotaleIncassiDiversiAnnoIva(0);

        setStatoPagina(STATO_RICERCA_DATI); 

        setNecessitaRicalcolo(0);   
      }
    },[necessitaRicalcolo])

    function scaricaLogo() {
      //console.log("scaricaLogo");
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
                if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                  setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                  setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                  setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                  setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                  setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                  setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                  setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                  setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                }
                setRicercaLogo(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      );        
    }
    function trovaImpostazioniReport() {
      //vado a fare la fetch di ricerca delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          ricercaImpostazioni : 1,
          report : 3,
          emak: props.emak,
          sessionId: props.sessionId})
      };
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1){
                setVisualizzaTab(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB));
                setVisualizzaSpese(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_SPESE));
                setTipoGraficoSpese(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_SPESE);
                setVisualizzaIncassiDiversi(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_INCASSI_DIVERSI));
                setTipoGraficoIncassiDiversi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_INCASSI_DIVERSI);
                setVisualizzaTotale(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOTALE));
                setTipoGraficoTotale(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOTALE);
                setflagVisualizzaImportiNettiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_NETTI));
                setflagVisualizzaImportiIvaDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_IVA));
                setflagVisualizzaImportiLordiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_LORDI));
                setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
                if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
                setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
                setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
                setRicercaImpostazioniReport(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      ); 
    }
    function aggiornaImpostazioniReport() {
      setCambiamento(0);
      //vado a fare la fetch di aggiornamento delle impostazioni del report per la persona in esame:
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniReport",
          modificaImpostazioni : 1,
          report : 3,
          visualizzaTabella:visualizzaTab,
          visualizzaGraficiSpese:visualizzaSpese,
          tipoGraficiSpese:tipoGraficoSpese,
          visualizzaGraficiIncassiDiversi:visualizzaIncassiDiversi,
          tipoGraficiIncassiDiversi:tipoGraficoIncassiDiversi,
          visualizzaGraficiTotale:visualizzaTotale,
          tipoGraficiTotale:tipoGraficoTotale,
          importiNetti:flagVisualizzaImportiNettiDefault,
          importiIva:flagVisualizzaImportiIvaDefault,
          importiLordi:flagVisualizzaImportiLordiDefault,
          periodoConsiderato:radioPeriodoDefault,
          elementiSignificativi:topDefault,
          visualizzaAltro:visualizzaAltro,
          emak: props.emak,
          sessionId: props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            if(valoreDiRitorno.risultatoOperazione===1 && valoreDiRitorno.risultatoRitorno){
                setVisualizzaTab(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TAB));
                setVisualizzaSpese(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_SPESE));
                setTipoGraficoSpese(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_SPESE);
                setVisualizzaIncassiDiversi(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_INCASSI_DIVERSI));
                setTipoGraficoIncassiDiversi(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_INCASSI_DIVERSI);
                setVisualizzaTotale(parseInt(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_TOTALE));
                setTipoGraficoTotale(valoreDiRitorno.risultatoRitorno[0].TIPO_GRAFICO_TOTALE);
                setflagVisualizzaImportiNettiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_NETTI));
                setflagVisualizzaImportiIvaDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_IVA));
                setflagVisualizzaImportiLordiDefault(parseInt(valoreDiRitorno.risultatoRitorno[0].IMPORTI_LORDI));
                setRadioPeriodoDefault(valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO);
                if (valoreDiRitorno.risultatoRitorno[0].RADIO_PERIODO==2) setDataPartenzaDefault(new Date().getFullYear()); else setDataPartenzaDefault(annoOggi);
                setTopDefault(valoreDiRitorno.risultatoRitorno[0].TOP_DEFAULT);
                setVisualizzaAltro(valoreDiRitorno.risultatoRitorno[0].VISUALIZZA_ALTRO);
              
                //setRicercaImpostazioniReport(1);
            }
        },
        (error) => {console.log("Errore connessione");}
      );
    }

    function chiudiModaleImpostazioni() {
      setModaleImpostazioni(false);
      //setStatoPagina(STATO_OPERAZIONE_CORRETTA);
      if(necessitaRicalcolo === 2) setNecessitaRicalcolo(1);
      if(cambiamento === 1) aggiornaImpostazioniReport();
    }
    function ResettaImpostazioni() {
      setTipoGraficoSpese("Bar");
      setTipoGraficoIncassiDiversi("Bar");
      setTipoGraficoTotale("Bar");
    }

    function funzioneTrasformaDateAUmano(data) {
      var dataOutput='';
      //da formato aaaa-mm-gg a gg/mm/aaaa
      if(data){
          const arrdata = data.split('-');
          let anno = arrdata[0];
          let mese = arrdata[1];
          let giorno = arrdata[2];
          dataOutput = giorno+"/"+mese+"/"+anno;
      }else dataOutput = '';
  
      return dataOutput;
   
  }

  function aggiornaVistaImporti(tipo){

    if(tipo === 'netti'){
        if(flagVisualizzaImportiNettiDefault === 1){
          
          setflagVisualizzaImportiNettiDefault(0); 
          setLarghColonnaEnte(LarghColonnaEnte+3);  
        }  
        if(flagVisualizzaImportiNettiDefault === 0){
         
          setflagVisualizzaImportiNettiDefault(1); 
          setLarghColonnaEnte(LarghColonnaEnte-3);  
        } 
    }
    if(tipo === 'iva'){
      if(flagVisualizzaImportiIvaDefault === 1){
        
        setflagVisualizzaImportiIvaDefault(0); 
        setLarghColonnaEnte(LarghColonnaEnte+3); 
      } 
      if(flagVisualizzaImportiIvaDefault === 0){
      
        setflagVisualizzaImportiIvaDefault(1); 
        setLarghColonnaEnte(LarghColonnaEnte-3);
      } 
    }
    if(tipo === 'lordi'){
      if(flagVisualizzaImportiLordiDefault === 1){
       
        setflagVisualizzaImportiLordiDefault(0);
        setLarghColonnaEnte(LarghColonnaEnte+3); 
      }  
      if(flagVisualizzaImportiLordiDefault === 0){
        
        setflagVisualizzaImportiLordiDefault(1);
        setLarghColonnaEnte(LarghColonnaEnte-3);
      }
    }
  }

  return (
    <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>
      {statoPagina === STATO_SCARICA_PDF ? 
        <div>
          {/*<PDFViewer style={styles.viewer}>*/}
          <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
          <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
            <Document>
              <Page orientation={tableColStato >4 ? "landscape" : "portrait"} size="A4" style={styles.page}>
              
                <View style={{width:"100%"}} >
                <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                    <View wrap={false} style={styles.tableRowIntestazione}> 
                      <View style={styles.tableColIntestazioneSx}> 
                        <View style={styles.tableCell}> 
                          {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}
                          
                        </View> 
                      </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                          capSocieta !== "" ?
                          " "+capSocieta+" "+cittaSocieta
                          :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                          <Text style={styles.titolo}>
                          Report Situazione Economica Esterni {dataPartenzaDefault}</Text> 
                        </View> 
                      </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View>
                    <Text style={styles.titoloPiccolo}>
                      Periodo dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
                    </Text>
                  </View>

                  {visualizzaTab === 1 ?
                  <View style={styles.table}>{/*apre table dati*/}
                    <View wrap={false} style={styles.tableRowBlu}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>Ente Esterno</Text> 
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo Netto</Text>  
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Spese</Text> 
                          </View>
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>IVA</Text> 
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Spese</Text> 
                          </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo Lordo</Text>
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Spese</Text> 
                          </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiNettiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo Netto</Text>
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Incassi</Text> 
                          </View>
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>IVA</Text>
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Incassi</Text> 
                          </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>Importo Lordo</Text> 
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Incassi</Text>
                          </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiNettiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>TOTALE</Text> 
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Importo Netto</Text> 
                          </View>
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>TOTALE</Text>
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>IVA</Text>  
                          </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}> 
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>TOTALE</Text> 
                            <Text style={styles.tableCellGrassettoAllineatoDestraNoBorderTop}>Importo Lordo</Text>
                          </View> 
                        :<Text></Text>}
                    </View>

                    {arrayPersone.map((elemento,index) => <View wrap={false} style={styles.tableRow} key={index}>
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>                   
                            <Text style={styles.tableCellAllineatoSinistra}>{arrayPersone[index]}</Text>                        
                        </View>
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arraySpeseImportiNetto[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arraySpeseImportiIva[index])}</Text>
                        </View> 
                        :<Text></Text>} 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arraySpeseImporti[index])}</Text>
                          </View>  
                        :<Text></Text>}  

                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayIncassiDiversiImportiNetto[index])}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayIncassiDiversiImportiIva[index])}</Text>
                        </View> 
                        :<Text></Text>} 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(arrayIncassiDiversiImporti[index])}</Text>
                          </View>  
                        :<Text></Text>} 

                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(parseFloat(arraySpeseImportiNetto[index])+parseFloat(arrayIncassiDiversiImportiNetto[index]))}</Text>
                         </View>
                         :<Text></Text>}
                         {flagVisualizzaImportiIvaDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(parseFloat(arraySpeseImportiIva[index])+parseFloat(arrayIncassiDiversiImportiIva[index]))}</Text>
                        </View> 
                        :<Text></Text>} 
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellAllineatoDestra}>{FormattaImportoVideo(parseFloat(arraySpeseImporti[index])+parseFloat(arrayIncassiDiversiImporti[index]))}</Text>
                          </View>  
                        :<Text></Text>} 
                    </View>)}

                    <View wrap={false} style={styles.tableRowGrigia}> 
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoSinistra}>TOTALE</Text>
                        </View> 
                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleSpeseAnnoNetto)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleSpeseAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}   
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleSpeseAnno)}</Text>
                          </View> 
                        :<Text></Text>}    

                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleIncassiDiversiAnnoNetto)}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleIncassiDiversiAnnoIva)}</Text>
                          </View> 
                        :<Text></Text>}   
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(statoTotaleIncassiDiversiAnno)}</Text>
                          </View> 
                        :<Text></Text>}    

                        {flagVisualizzaImportiNettiDefault === 1 ?
                        <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                          <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(parseFloat(statoTotaleSpeseAnnoNetto)+parseFloat(statoTotaleIncassiDiversiAnnoNetto))}</Text>
                        </View> 
                        :<Text></Text>}
                        {flagVisualizzaImportiIvaDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(parseFloat(statoTotaleSpeseAnnoIva)+parseFloat(statoTotaleIncassiDiversiAnnoIva))}</Text>
                          </View> 
                        :<Text></Text>}   
                        {flagVisualizzaImportiLordiDefault === 1 ?
                          <View style={{width: 100/tableColStato+"%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0,}}>
                            <Text style={styles.tableCellGrassettoAllineatoDestra}>{FormattaImportoVideo(parseFloat(statoTotaleSpeseAnno)+parseFloat(statoTotaleIncassiDiversiAnno))}</Text>
                          </View> 
                        :<Text></Text>} 
                    </View>
                    </View>:""}

                  {flagVisualizzaImportiNettiDefault === 1 && speseGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoSpese === "Line" || tipoGraficoSpese === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + speseGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {flagVisualizzaImportiIvaDefault === 1 && speseGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoSpese === "Line" || tipoGraficoSpese === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + speseGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {flagVisualizzaImportiIvaDefault === 1 && speseGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoSpese === "Line" || tipoGraficoSpese === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + speseGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}


                {flagVisualizzaImportiNettiDefault === 1 && incassiDiversiGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoIncassiDiversi === "Line" || tipoGraficoIncassiDiversi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + incassiDiversiGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                
                {flagVisualizzaImportiIvaDefault === 1 && incassiDiversiGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoIncassiDiversi === "Line" || tipoGraficoIncassiDiversi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + incassiDiversiGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {flagVisualizzaImportiIvaDefault === 1 && incassiDiversiGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                  <Image style={tipoGraficoIncassiDiversi === "Line" || tipoGraficoIncassiDiversi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + incassiDiversiGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {flagVisualizzaImportiNettiDefault === 1 && totaleGraficoNettiCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoIncassiDiversi === "Line" || tipoGraficoIncassiDiversi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + totaleGraficoNettiCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                {flagVisualizzaImportiIvaDefault === 1 && totaleGraficoIvaCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                    <Image style={tipoGraficoIncassiDiversi === "Line" || tipoGraficoIncassiDiversi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + totaleGraficoIvaCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}
                
                {flagVisualizzaImportiIvaDefault === 1 && totaleGraficoCanvas.current?
                  <View wrap={false} style={{alignItems:'center', width: "100%"}}>
                  <Image style={tipoGraficoIncassiDiversi === "Line" || tipoGraficoIncassiDiversi === "Bar" ? styles.graficiLunghi : styles.grafici} source={"data:" + "image/png"+ ";" + totaleGraficoCanvas.current.canvas.toDataURL("image/png")}/>
                </View>:<Text></Text>}

                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                  </Page>
                </Document>
            </PDFViewer>
          </div>

      :""}


   
    
      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Sit_Est</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? 
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
          </div>
        </div> :""}
      {statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
            <Clessidra loading={true}/>
          </div> 
        </div> :""}
 

      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
        <div className="schedaSpazioCentrale" style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
            
            <Row>
              <Col lg="12">
                    <Card className="main-card mb-3">
                    <CardBody>
                            <FormGroup>
                            <div id="reportIntestazione">
                            <CardTitle>
                            Report Situazione Economica Esterni &nbsp;
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                              {[...Array(11)].map((elemento, indice) => (
                                <option key={indice} value={annoOggi + 1 - indice}>
                                  {annoOggi + 1 - indice}
                                </option>
                              ))}                              
                            </select>    
                            &nbsp; dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>                  
                            </CardTitle>  
                              <Button color="success" className="bottoneMargineASinistra" onClick={() => {setModaleImpostazioni(true);}}>Impostazioni</Button>
                              <Button color="success" className="bottoneMargineASinistra" onClick={(e)=>{setStatoPagina(STATO_PREPARA_PDF);}}>Esporta il PDF</Button>
                            

                            <div id='idDivGenerazionePdf' style={{display:'none'}}>Generazione PDF in corso...</div>

                            {modaleImpostazioni === true ?
                            <Modale 
                            larghezza = "lg"
                                titolo="Impostazioni layout report situazione economica esterni"
                                flagErrore={false}
                                contenuto={<div>
                                <CardBody>
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center></center></b></Col>  
                                <Col><b><center>Tabella</center></b></Col>
                                <Col><b><center>Grafici</center></b></Col>
                                <Col><b><center>Tipo Grafici</center></b></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Spese, Incassi e Totale</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTab" 
                                      defaultChecked = {visualizzaTab === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTab === 1 ? setVisualizzaTab(0) : setVisualizzaTab(1);setCambiamento(1);}}/></center></Col>
                                <Col><center></center></Col>
                                <Col></Col>
                                </Row>

                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Spese</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaSpese" 
                                      defaultChecked = {visualizzaSpese === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaSpese === 1 ? setVisualizzaSpese(0) : setVisualizzaSpese(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGSpese" id="TGSpese" innerRef={TGSpese} onChange={(e)=>{setTipoGraficoSpese(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoSpese === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoSpese === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoSpese === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoSpese === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoSpese === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoSpese === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Incassi</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaIncassiDiversi" 
                                      defaultChecked = {visualizzaIncassiDiversi === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaIncassiDiversi === 1 ? setVisualizzaIncassiDiversi(0) : setVisualizzaIncassiDiversi(1);;setCambiamento(1)}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGIncassi" id="TGIncassi" innerRef={TGIncassi} onChange={(e)=>{setTipoGraficoIncassiDiversi(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoIncassiDiversi === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoIncassiDiversi === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoIncassiDiversi === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoIncassiDiversi === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoIncassiDiversi === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoIncassiDiversi === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>
                                
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Importi Totale</Col>
                                <Col><center></center></Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaTotale" 
                                      defaultChecked = {visualizzaTotale === 1 ?  'checked' :""}                               
                                      placeholder=""
                                      onClick={(e)=>{visualizzaTotale === 1 ? setVisualizzaTotale(0) : setVisualizzaTotale(1);setCambiamento(1);}}/></center></Col>
                                <Col><center><select style={{marginTop:10}} name="TGSpeseIncassi" id="TGSpeseIncassi" innerRef={TGSpeseIncassi} onChange={(e)=>{setTipoGraficoTotale(e.target.value);setCambiamento(1)}}>
                                  {tipoGraficoTotale === "Doughnut" ? <option selected value="Doughnut">{descriDoughnut}</option> : <option value="Doughnut">{descriDoughnut}</option>}
                                  {tipoGraficoTotale === "Line" ? <option selected value="Line">{descriLine}</option> : <option value="Line">{descriLine}</option>}
                                  {tipoGraficoTotale === "Bar" ? <option selected value="Bar">{descriBar}</option> : <option value="Bar">{descriBar}</option>}
                                  {tipoGraficoTotale === "PolarArea" ? <option selected value="PolarArea">{descriPolarArea}</option> : <option value="PolarArea">{descriPolarArea}</option>}
                                  {tipoGraficoTotale === "Radar" ? <option selected value="Radar">{descriRadar}</option> : <option value="Radar">{descriRadar}</option>}
                                  {tipoGraficoTotale === "Pie" ? <option selected value="Pie">{descriPie}</option> : <option value="Pie">{descriPie}</option>}
                                </select></center></Col>
                                </Row>

                                </CardBody>
                                
                                <CardBody>
                                {/*settaggio importi da visionare*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Importi da visionare</center></b></Col>
                                </Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiNetti" id="flagVisualizzaImportiNetti"
                                defaultChecked = {flagVisualizzaImportiNettiDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiNetti}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("netti");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiNetti">Visualizza Imponibili</Label>
                                </center> </Col>
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiIva" id="flagVisualizzaImportiIva"
                                defaultChecked = {flagVisualizzaImportiIvaDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiIva}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("iva");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiIva">Visualizza IVA</Label>
                                </center> </Col>
                                <Col><center> 
                                <Input type="checkbox" name="flagVisualizzaImportiLordi" id="flagVisualizzaImportiLordi"
                                defaultChecked = {flagVisualizzaImportiLordiDefault === 1 ?  'checked' :""}
                                innerRef={flagVisualizzaImportiLordi}                                 
                                placeholder=""
                                onInput={(e)=>{aggiornaVistaImporti("lordi");setCambiamento(1)}}/>
                                <Label for="flagVisualizzaImportiLordi">Visualizza Importi</Label>
                                </center> </Col>
                                </Row>
                                </CardBody>

                                <CardBody>
                                {/*settaggio tipo periodo*/}
                                <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border">
                                <Col><b><center>Periodo Considerato</center></b></Col>
                                </Row>
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>
                                <FormGroup check>
                                  <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "1" ?  'checked' :""}
                                            value="1"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(annoOggi)}}/> {'Apertura/Chiusura Esercizio'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                <Col><center>
                                <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="radioPeriodo" 
                                            innerRef={radioPeriodo} 
                                            defaultValue={radioPeriodoDefault}
                                            defaultChecked = {radioPeriodoDefault === "2" ?  'checked' :""}
                                            value="2"
                                            onInput={(e)=>{setRadioPeriodoDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1);setDataPartenzaDefault(new Date().getFullYear())}}/> {'Anno Solare'}
                                  </Label>
                                </FormGroup>
                                </center></Col>
                                </Row>
                                </CardBody>
                                <CardBody>
                                {/*settaggio Top N*/}
                                <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col><center>Nei grafici visualizza i <Input type="select" name="top" id="top" innerRef={top}  onInput={(e)=>{setTopDefault(e.target.value);setNecessitaRicalcolo(2);setCambiamento(1)}}>
                                  {topDefault === '5' ? <option selected value="5">5</option> : <option value="5">5</option> }
                                  {topDefault === '10' || topDefault === '' ? <option selected value="10">10</option> : <option value="10">10</option> }
                                  {topDefault === '20' ? <option selected value="20">20</option> : <option value="20">20</option> }
                                  {topDefault === '30' ? <option selected value="30">30</option> : <option value="30">30</option> }
                                  {topDefault === '40' ? <option selected value="40">40</option> : <option value="40">40</option> }
                                  {topDefault === '50' ? <option selected value="50">50</option> : <option value="50">50</option> }
                                  {topDefault === '60' ? <option selected value="60">60</option> : <option value="60">60</option> }
                                  {topDefault === '70' ? <option selected value="70">70</option> : <option value="70">70</option> }
                                  {topDefault === '80' ? <option selected value="80">80</option> : <option value="80">80</option> }
                                  {topDefault === '90' ? <option selected value="90">90</option> : <option value="90">90</option> }
                                  {topDefault === '100' ? <option selected value="100">100</option> : <option value="100">100</option> }
           
                                </Input> elementi significativi  
                                  
                                </center></Col>
                                <Col>
                                  <Label>Nei grafici visualizza anche dati relativi ad 'Altro'</Label>
                                    <Input className="ml-2 mt-2" type="checkbox" name="visualizzaAltro" id="visualizzaAltro"
                                      defaultChecked = {visualizzaAltro === "1" ?  'checked' :""}
                                      innerRef={visualizzaAltroRif}                                 
                                      placeholder=""
                                      onClick={(e)=>{visualizzaAltro === '1' ? setVisualizzaAltro('0') : setVisualizzaAltro('1');setNecessitaRicalcolo(2);setCambiamento(1)}}/>
                                </Col>
                                </Row>
                                </CardBody>
                                  </div>}
                                bottoni={[
                                  {
                                      "etichetta":"OK",
                                      "tipo":"primary",
                                      callback:() => {chiudiModaleImpostazioni()}
                                  },    
                                  {
                                    "etichetta":"Resetta Impostazioni Grafici",
                                    "tipo":"danger",
                                    callback:() => {ResettaImpostazioni();setCambiamento(1)}
                                },  
                              ]}
                            />
                        :""}
                            </div>
                            </FormGroup>

                            <div id="titolo" style={{display:"none"}}>
                            Report Situazione Economica Esterni {dataPartenzaDefault} calcolato dal {funzioneTrasformaDateAUmano(inizioPeriodo)} al {funzioneTrasformaDateAUmano(finePeriodo)} 
                            </div>

                            {risultatoServer !== undefined && risultatoServer !== "" ?
                            <div id="reportSituazioneEconomicaEsterni">  
                                <div id="situazioneEconomicaEsterni"  style={{width:'100%'}}>
                                {visualizzaTab === 1 || visualizzaSpese === 1 || visualizzaIncassiDiversi === 1?
                                 <Row><Col><CardTitle>Riepilogo</CardTitle></Col></Row>
                                :""}
                                </div>
                                <div id="Tabella"  style={{width:'100%'}}>  
                                  {((arraySpeseImporti.length>0 || arraySpeseImportiNetto.length>0 || arraySpeseImportiIva.length>0)) ||
                                  ((arrayIncassiDiversiImporti.length>0 || arrayIncassiDiversiImportiNetto.length>0 || arrayIncassiDiversiImportiIva.length>0)) 
                                  ?
                                    visualizzaTab === 1 ?
                                    <Fragment>
                                    <Row form className="border"><Col><b>Ente Esterno</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>Importo Netto<br></br>Spese</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>IVA<br></br>Spese</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo Lordo<br></br>Spese</b></Col> :""}
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>Importo Netto<br></br>Incassi</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>IVA<br></br>Incassi</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>Importo Lordo<br></br>Incassi</b></Col> :""}
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>TOTALE<br></br>Importo<br></br>Netto</b></Col> :""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>TOTALE<br></br>IVA</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>TOTALE<br></br>Importo Lordo</b></Col> :""}
                                    </Row>

                                    {arrayPersone.map((elemento,index) => <Row form className="border" key={index} >
                                      {arrayPersone[index]!=='' ? 
                                        <Col>{arrayPersone[index]}</Col>
                                      : <Col>Non specificato</Col>}
                                      
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arraySpeseImportiNetto[index])}</Col>:""}
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arraySpeseImportiIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arraySpeseImporti[index])}</Col> :""}
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayIncassiDiversiImportiNetto[index])}</Col>:""}
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayIncassiDiversiImportiIva[index])}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(arrayIncassiDiversiImporti[index])}</Col> :""}
                                      {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right">{FormattaImportoVideo(parseFloat(arraySpeseImportiNetto[index])+parseFloat(arrayIncassiDiversiImportiNetto[index]))}</Col>:""}
                                      {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right">{FormattaImportoVideo(parseFloat(arraySpeseImportiIva[index])+parseFloat(arrayIncassiDiversiImportiIva[index]))}</Col> :""}
                                      {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right">{FormattaImportoVideo(parseFloat(arraySpeseImporti[index])+parseFloat(arrayIncassiDiversiImporti[index]))}</Col> :""}
                                      
                                      </Row>)
                                    }
                                    {/*riga dei totali in fondo alla tabella*/}
                                    <Row form className="border">
                                    <Col><b>TOTALE</b></Col>
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleSpeseAnnoNetto)}</b></Col>:""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleSpeseAnnoIva)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleSpeseAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleIncassiDiversiAnnoNetto)}</b></Col>:""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleIncassiDiversiAnnoIva)}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(statoTotaleIncassiDiversiAnno)}</b></Col> :""}
                                    {flagVisualizzaImportiNettiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(parseFloat(statoTotaleSpeseAnnoNetto)+parseFloat(statoTotaleIncassiDiversiAnnoNetto))}</b></Col>:""}
                                    {flagVisualizzaImportiIvaDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(parseFloat(statoTotaleSpeseAnnoIva)+parseFloat(statoTotaleIncassiDiversiAnnoIva))}</b></Col> :""}
                                    {flagVisualizzaImportiLordiDefault === 1 ? <Col align="right"><b>{FormattaImportoVideo(parseFloat(statoTotaleSpeseAnno)+parseFloat(statoTotaleIncassiDiversiAnno))}</b></Col> :""}
                                    </Row>

                                    </Fragment>
                                    :""
                                  : ""}

                                  {/*GRAFICI HTML*/}
                                  </div>

                                  {flagVisualizzaImportiNettiDefault === 1 && graficoSpeseNetti === 1 && tipoGraficoSpese==="Bar" && arraySpeseNettoTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataSpeseNetti} ref={speseGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoSpeseNetti === 1 && tipoGraficoSpese==="Doughnut" && arraySpeseNettoTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataSpeseNetti} ref={speseGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoSpeseNetti === 1 && tipoGraficoSpese==="Line" && arraySpeseNettoTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataSpeseNetti} ref={speseGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoSpeseNetti === 1 && tipoGraficoSpese==="Pie" && arraySpeseNettoTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataSpeseNetti} ref={speseGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoSpeseNetti === 1 && tipoGraficoSpese==="PolarArea" && arraySpeseNettoTopImporti.length>0  && visualizzaSpese === 1?
                                  <div id="speseGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataSpeseNetti} ref={speseGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoSpeseNetti === 1 && tipoGraficoSpese==="Radar" && arraySpeseNettoTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataSpeseNetti} ref={speseGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto Spese (Top '+topDefault+')'}},}} /></div>: ""}
                            
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoSpeseIva === 1 && tipoGraficoSpese==="Bar" && arraySpeseIvaTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataSpeseIva}  ref={speseGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoSpeseIva === 1 && tipoGraficoSpese==="Doughnut" && arraySpeseIvaTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataSpeseIva}  ref={speseGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoSpeseIva === 1 && tipoGraficoSpese==="Line" && arraySpeseIvaTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataSpeseIva}  ref={speseGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoSpeseIva === 1 && tipoGraficoSpese==="Pie" && arraySpeseIvaTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataSpeseIva}  ref={speseGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoSpeseIva === 1 && tipoGraficoSpese==="PolarArea" && arraySpeseIvaTopImporti.length>0  && visualizzaSpese === 1?
                                  <div id="speseGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataSpeseIva}  ref={speseGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoSpeseIva === 1 && tipoGraficoSpese==="Radar" && arraySpeseIvaTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataSpeseIva}  ref={speseGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoSpeseImporti === 1 && tipoGraficoSpese==="Bar" && arraySpeseTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataSpese}  ref={speseGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoSpeseImporti === 1 && tipoGraficoSpese==="Doughnut" && arraySpeseTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataSpese}  ref={speseGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoSpeseImporti === 1 && tipoGraficoSpese==="Line" && arraySpeseTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataSpese}  ref={speseGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoSpeseImporti === 1 && tipoGraficoSpese==="Pie" && arraySpeseTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataSpese}  ref={speseGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoSpeseImporti === 1 && tipoGraficoSpese==="PolarArea" && arraySpeseTopImporti.length>0  && visualizzaSpese === 1?
                                  <div id="speseGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataSpese}  ref={speseGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoSpeseImporti === 1 && tipoGraficoSpese==="Radar" && arraySpeseTopImporti.length>0 && visualizzaSpese === 1?
                                  <div id="speseGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataSpese}  ref={speseGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo Spese (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                                
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoIncassiNetti === 1 && tipoGraficoIncassiDiversi==="Bar" && arrayIncassiFornitoriNettoTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataIncassiDiversiNetti} ref={incassiDiversiGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoIncassiNetti === 1 && tipoGraficoIncassiDiversi==="Doughnut" && arrayIncassiFornitoriNettoTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataIncassiDiversiNetti} ref={incassiDiversiGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoIncassiNetti === 1 && tipoGraficoIncassiDiversi==="Line" && arrayIncassiFornitoriNettoTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataIncassiDiversiNetti} ref={incassiDiversiGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoIncassiNetti === 1 && tipoGraficoIncassiDiversi==="Pie" && arrayIncassiFornitoriNettoTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataIncassiDiversiNetti} ref={incassiDiversiGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoIncassiNetti === 1 && tipoGraficoIncassiDiversi==="PolarArea" && arrayIncassiFornitoriNettoTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataIncassiDiversiNetti} ref={incassiDiversiGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Netto Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoIncassiNetti === 1 && tipoGraficoIncassiDiversi==="Radar" && arrayIncassiFornitoriNettoTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataIncassiDiversiNetti} ref={incassiDiversiGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Netto Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  

                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIncassiIva === 1 && tipoGraficoIncassiDiversi==="Bar" && arrayIncassiFornitoriIvaTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataIncassiDiversiIva} ref={incassiDiversiGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIncassiIva === 1 && tipoGraficoIncassiDiversi==="Doughnut" && arrayIncassiFornitoriIvaTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataIncassiDiversiIva} ref={incassiDiversiGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIncassiIva === 1 && tipoGraficoIncassiDiversi==="Line" && arrayIncassiFornitoriIvaTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataIncassiDiversiIva} ref={incassiDiversiGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIncassiIva === 1 && tipoGraficoIncassiDiversi==="Pie" && arrayIncassiFornitoriIvaTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataIncassiDiversiIva} ref={incassiDiversiGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIncassiIva === 1 && tipoGraficoIncassiDiversi==="PolarArea" && arrayIncassiFornitoriIvaTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                   <div id="incassiDiversiGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataIncassiDiversiIva} ref={incassiDiversiGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'IVA Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1 && graficoIncassiIva === 1 && tipoGraficoIncassiDiversi==="Radar" && arrayIncassiFornitoriIvaTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataIncassiDiversiIva} ref={incassiDiversiGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'IVA Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoIncassiImporti === 1 && tipoGraficoIncassiDiversi==="Bar" && arrayIncassiFornitoriTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoIncassiImporti === 1 && tipoGraficoIncassiDiversi==="Doughnut" && arrayIncassiFornitoriTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoIncassiImporti === 1 && tipoGraficoIncassiDiversi==="Line" && arrayIncassiFornitoriTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoIncassiImporti === 1 && tipoGraficoIncassiDiversi==="Pie" && arrayIncassiFornitoriTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoIncassiImporti === 1 && tipoGraficoIncassiDiversi==="PolarArea" && arrayIncassiFornitoriTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Importo Lordo Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoIncassiImporti === 1 && tipoGraficoIncassiDiversi==="Radar" && arrayIncassiFornitoriTopImporti.length>0 && visualizzaIncassiDiversi === 1?
                                  <div id="incassiDiversiGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataIncassiDiversi} ref={incassiDiversiGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Importo Lordo Incassi (Top '+topDefault+')'}},}} /></div>: ""}
                              
                                    {flagVisualizzaImportiNettiDefault === 1 && graficoTotaleNetti === 1 && tipoGraficoTotale==="Bar" && arrayTotaleNettoTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataTotaleNetti} ref={totaleGraficoNettiCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo Netto (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoTotaleNetti === 1 && tipoGraficoTotale==="Doughnut" && arrayTotaleNettoTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataTotaleNetti} ref={totaleGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo Netto (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoTotaleNetti === 1 && tipoGraficoTotale==="Line" && arrayTotaleNettoTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoNetti" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataTotaleNetti} ref={totaleGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo Netto (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoTotaleNetti === 1 && tipoGraficoTotale==="Pie" && arrayTotaleNettoTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataTotaleNetti} ref={totaleGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo Netto (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoTotaleNetti === 1 && tipoGraficoTotale==="PolarArea" && arrayTotaleNettoTopImporti.length>0  && visualizzaTotale === 1?
                                  <div id="totaleGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataTotaleNetti} ref={totaleGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo Netto (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiNettiDefault === 1 && graficoTotaleNetti === 1 && tipoGraficoTotale==="Radar" && arrayTotaleNettoTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoNetti" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataTotaleNetti} ref={totaleGraficoNettiCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo Netto (Top '+topDefault+')'}},}} /></div>: ""}
                            
                                  {flagVisualizzaImportiIvaDefault === 1  && graficoTotaleIva === 1 && tipoGraficoTotale==="Bar" && arrayTotaleIvaTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataTotaleIva} ref={totaleGraficoIvaCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale IVA (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1  && graficoTotaleIva === 1 && tipoGraficoTotale==="Doughnut" && arrayTotaleIvaTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataTotaleIva} ref={totaleGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale IVA (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1  && graficoTotaleIva === 1 && tipoGraficoTotale==="Line" && arrayTotaleIvaTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoIva" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataTotaleIva} ref={totaleGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale IVA (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1  && graficoTotaleIva === 1 && tipoGraficoTotale==="Pie" && arrayTotaleIvaTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataTotaleIva} ref={totaleGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale IVA (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1  && graficoTotaleIva === 1 && tipoGraficoTotale==="PolarArea" && arrayTotaleIvaTopImporti.length>0  && visualizzaTotale === 1?
                                  <div id="totaleGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataTotaleIva} ref={totaleGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale IVA (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiIvaDefault === 1  && graficoTotaleIva === 1 && tipoGraficoTotale==="Radar" && arrayTotaleIvaTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGraficoIva" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataTotaleIva} ref={totaleGraficoIvaCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale IVA (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleImporti === 1 && tipoGraficoTotale==="Bar" && arrayTotaleTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Bar data={dataTotale} ref={totaleGraficoCanvas}  options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo Lordo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleImporti === 1 && tipoGraficoTotale==="Doughnut" && arrayTotaleTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Doughnut data={dataTotale} ref={totaleGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo Lordo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleImporti === 1 && tipoGraficoTotale==="Line" && arrayTotaleTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGrafico" style={{width:'99vh',margin: 'auto'}}>
                                    <Line data={dataTotale} ref={totaleGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo Lordo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleImporti === 1 && tipoGraficoTotale==="Pie" && arrayTotaleTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Pie data={dataTotale} ref={totaleGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo Lordo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleImporti === 1 && tipoGraficoTotale==="PolarArea" && arrayTotaleTopImporti.length>0  && visualizzaTotale === 1?
                                  <div id="totaleGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <PolarArea data={dataTotale} ref={totaleGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend:{position: 'right'},title:{display: true,text: 'Totale Importo Lordo (Top '+topDefault+')'}},}} /></div>: ""}
                                  {flagVisualizzaImportiLordiDefault === 1 && graficoTotaleImporti === 1 && tipoGraficoTotale==="Radar" && arrayTotaleTopImporti.length>0 && visualizzaTotale === 1?
                                  <div id="totaleGrafico" style={{width:'70vh',margin: 'auto'}}>
                                    <Radar data={dataTotale} ref={totaleGraficoCanvas} options={{maintainAspectRatio: true,plugins:{legend: false,title:{display: true,text: 'Totale Importo Lordo (Top '+topDefault+')'}},}} /></div>: ""}
                                  
                              </div>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
 
      :""}
    </div>
   );
}
export default TabellaReportEconEsterni;