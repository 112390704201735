export function interoStringaCifre(numero, cifre) {
    let s = String(numero);
    while (s.length<cifre) s = "0" + s;
    return s;
}

// param="" -> converte date dal formato YYYY-MM-DD al formato DD/MM/YYYY
// param="gSett" -> converte date dal formato YYYY-MM-DD al formato GIORNO DD/MM/YYYY con GIORNO = lunedì, martedì,...
// param="gSettAbbr" -> converte date dal formato YYYY-MM-DD al formato GIORNO DD/MM/YYYY con GIORNO = lun, mar, mer,...
export function convertiDataComputer2Umano(d, param) {
    if (d==""||d==null) return "";
    const ds = d.split("-");
    if (param=="") return ds[2]+"/"+ds[1]+"/"+ds[0];
    const giornoSettimana = new Date(d).getDay();
    const giorni = ["domenica","lunedì","martedì","mercoledì","giovedì","venerdì","sabato"];
    const giorniAbbr = ["dom","lun","mar","mer","gio","ven","sab"];
    if (param=="gSett") return giorni[giornoSettimana] + " " + ds[2]+"/"+ds[1]+"/"+ds[0];
    return giorniAbbr[giornoSettimana] + " " + ds[2]+"/"+ds[1]+"/"+ds[0];
}

export function oggiData() {
    return new Date().toISOString().split('T')[0];
}

export function oggiDataOra() {
    return new Date().toISOString();
}

// calcola l'anno da presentare come anno attuale in funzione della data di chiusura esercizio
// esempio: se oggi è il 01/02/2024: se l'esercizio chiude il 31/12 allora siamo nel 2024, ma se chiude il 31/03 siamo ancora nell'anno 2023
export function calcolaAnnoOggi(stringaDataChiusuraEsercizio) {
    const today = new Date();
    var annoOggi = today.getFullYear();
    // se l'esercizio non finisce il 31/12 allora annoOggi potrebbe non essere l'anno della data di oggi ma ancora l'anno scorso
    var componentiData = stringaDataChiusuraEsercizio.split("-");
    var meseChiusuraEsercizio = parseInt(componentiData[1]);
    var giornoChiusuraEsercizio = parseInt(componentiData[2]);
    if (!(meseChiusuraEsercizio==12 && giornoChiusuraEsercizio==31)) {
      var giornoOggi = today.getDate(); // giorno del mese
      var meseOggi = today.getMonth()+1; // mese, gennaio è zero    
      if (meseOggi<meseChiusuraEsercizio || (meseOggi==meseChiusuraEsercizio && giornoOggi<giornoChiusuraEsercizio)) annoOggi = today.getFullYear() - 1;  
    }   
    return annoOggi;
}