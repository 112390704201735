import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleCatNonCancellabile, setFlagAttivaModaleCatNonCancellabile] = useState(0);
    const [flagAttivaModaleCatNonModificabile, setFlagAttivaModaleCatNonModificabile] = useState(0);
    const [flagAttivaModaleCatNonInseribile, setFlagAttivaModaleCatNonInseribile] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);

    const descrizione   = useRef();
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
 
    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    //let descrizioneDefault = "";
    const [descrizioneDefault,setDescrizioneDefault]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
    },[]);



    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("catTesseramIns");
        if(props.ope==='modifica') setOperazione("catTesseramMod");
        if(props.ope==='cancella') setOperazione("catTesseramCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"catTesseramRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                   
                    setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    /*function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
    }*/

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {
        setFlagAttivaModaleCatNonCancellabile(0);
        setFlagAttivaModaleCatNonModificabile(0);
        setFlagAttivaModaleCatNonInseribile(0);
        setFlagAttivaModaleInserisciDescrizione(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
        }

        if(controlli===0){ 

            //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  JSON.stringify({op:operazione,
                        descrizione:descrizione.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Cat non cancellabile: presente in tesseramenti") !== -1){
                                    setFlagAttivaModaleCatNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Cat non modificabile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleCatNonModificabile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Cat non inseribile: presente altro record uguale") !== -1){
                                    setFlagAttivaModaleCatNonInseribile(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        }
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }
  
    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
          
                {/*<CardTitle>valore :{insSponsorEdi}</CardTitle>*/}
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1C_CatTesse {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                    
                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""} 

                    {flagAttivaModaleCatNonCancellabile=== 1 ?
                        <Modale 
                            titolo="Informativa categoria non cancellabile"
                            flagErrore={true}
                            contenuto={<div>La categoria di tesseramento selezionata non è cancellabile in quanto associata ad uno o più tesseramenti</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleCatNonModificabile=== 1 ?
                        <Modale 
                            titolo="Informativa categoria non modificabile"
                            flagErrore={true}
                            contenuto={<div>La descrizione della categoria di tesseramento non è modificabile in {descrizione.current.value} poichè nel database è già presente una categoria di tesseramento con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleCatNonInseribile=== 1 ?
                        <Modale 
                            titolo="Informativa categoria non inseribile"
                            flagErrore={true}
                            contenuto={<div>La descrizione della categoria di tesseramento non è inseribile in {descrizione.current.value} poichè nel database è già presente una categoria di tesseramento con la stessa descrizione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Categoria di Tesseramento</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Categoria di Tesseramento</CardTitle> :""}
                            
                      
                                <Clessidra loading={loading}/>

                                {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della categoria di tesseramento <b>{descrizioneDefault}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                                <>
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                            {props.ope === "inserisci" || props.ope === "modifica" ?
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value);}}/> 
                                            :""}
                                            {props.ope === "cancella" ? <Label>: {descrizioneDefault}</Label> : ""}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                            }
                               

                                </div>
                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
