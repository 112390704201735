import React, {Fragment,useState} from 'react';
import { useEffect } from 'react';

//import Parametri from './parametri';

//NOTA:EVENTUALMENTE DA RIMUOVERE:
//import {EditableSelect} from 'react-editable-select';//npm i --save react-editable-select
//import CreatableSelect from "react-select/lib/Creatable";//npm i --save react-select --> Can't resolve 'react-select/lib/Creatable'

//da https://material-ui.com/components/autocomplete/ uso creatable:
import TextField from '@material-ui/core/TextField';//npm install @material-ui/core
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';//npm install @material-ui/lab

function TendinaEditabile(props) {

    /*if(props.posizioneTendina != undefined) console.log("TendinaEditabile:POS"+props.posizioneTendina);
    else console.log("TendinaEditabile:POS INDEFINITA");
    console.log("TendinaEditabile:la mia props vale: " +  props.valoreDefault);*/
    
    const filter = createFilterOptions();//filtro per tendina editabile
    const [valuePrec, setValuePrec] = useState("");

    const [perRerendering, setPerRerendering] = useState(props.valoreDefault);

    useEffect(() => {
        //console.log("entro per"+props.valoreDefault)
        setPerRerendering(props.valoreDefault);
    },[props.valoreDefault])
  
    let composizioneCampi="";

    var valoriPossibili=[];
    
    if(props.labelCampoVuoto !== undefined) valoriPossibili.push({valore:props.labelCampoVuoto,valoreNonVisibile:''});//eventuale valore vuoto in tendina

    if(props.inserisciCampoTutti !== undefined){
        valoriPossibili.push({valore:"Tutti",valoreNonVisibile:''});
    }
    //console.log("VAL"+props.defaultValue+"per"+props.arrayValori.length)

    for (let i=0; i<props.arrayValori.length ; i++) {
       // valoriPossibili.push({valore:props.arrayValori[i][props.nomeCampo]+Math.random()});

        if((props.nomeCampoParentesi !== undefined || props.arrayValori[i][props.nomeCampo2]!==undefined) && (props.nomeCampoParentesi !== '' || props.arrayValori[i][props.nomeCampo2]!=='')){

        
            if(props.arrayValori[i][props.nomeCampo]!==undefined && props.arrayValori[i][props.nomeCampo]!==''){
                //compongo i valori dei campi mettendo prima il primo campo
                composizioneCampi = props.arrayValori[i][props.nomeCampo];
            } 
            if(props.arrayValori[i][props.nomeCampo2]!==undefined && props.arrayValori[i][props.nomeCampo2]!==''){
                //aggiungo eventuale secondo campo:
                if (composizioneCampi!=="") composizioneCampi = composizioneCampi+" ";
                composizioneCampi = composizioneCampi+props.arrayValori[i][props.nomeCampo2];
            } 
            if(props.arrayValori[i][props.nomeCampoParentesi]!==undefined && props.arrayValori[i][props.nomeCampoParentesi]!==''){
                //aggiungo eventuale campo in parentesi:
                if (composizioneCampi!=="") composizioneCampi = composizioneCampi+" ";
                composizioneCampi = composizioneCampi+"("+props.arrayValori[i][props.nomeCampoParentesi]+")";
            }
      
            if (props.valoreDefault==="" || props.valoreDefault===undefined || composizioneCampi.indexOf(props.valoreDefault)>=0 || props.inserisciCampoTutti !== "" || props.inserisciCampoTutti !== undefined) {  
                valoriPossibili.push({valore:composizioneCampi,
                    valoreNonVisibile:props.arrayValori[i][props.nomeCampoNonVisibile],
                });
            
            }
            
       }else{
           if(props.arrayValori[i][props.nomeCampo]!==undefined || props.arrayValori[i][props.nomeCampo]!==''){
                if (props.valoreDefault==="" || props.valoreDefault===undefined || props.arrayValori[i][props.nomeCampo].indexOf(props.valoreDefault)>=0 || props.inserisciCampoTutti !== "" || props.inserisciCampoTutti !== undefined) {      
                    //console.log("METTO"+props.arrayValori[i][props.nomeCampo])
                    valoriPossibili.push({valore:props.arrayValori[i][props.nomeCampo],
                        valoreNonVisibile:props.arrayValori[i][props.nomeCampoNonVisibile],
                    });
                
                }
           } 
       }
       //valoriPossibili.sort();
    }
   
//nota la callback mi da: 
// - valore
// - posizione tendina(optional)
// - campo non visibile (es id. E' optional)
//console.log("TendinaEditabile:sono nella tendina editabile!")

        return (
            
            <Fragment>

            {/*<Label>lunghezza locale={valoriPossibili.length} l={props.arrayValori.length} eccolo"{props.nomeCampo}" {JSON.stringify(props.arrayValori)}</Label>*/}
            <Autocomplete
            autoHighlight={true}
            //disableClearable ={true}  elimina la crocetta che svuota il campo
            style={{backgroundColor:"#FFFFFF"}}
            defaultValue={props.valoreDefault||undefined}                        
            value={props.valoreDefault||undefined}
            inputValue={perRerendering}
            onChange={(event, newValue) => {
                //console.log("onChange:"+newValue)

                if (typeof newValue === 'string') {
                    //console.log("TendinaEditabile:CASO1-");
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile(newValue,props.posizioneTendina,'');
                    else props.callbackSetValoreTendEditabile(newValue,'','');
                } else if (newValue === null ) {
                    //console.log("TendinaEditabile:CASO2-");
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile('',props.posizioneTendina,'');
                    else props.callbackSetValoreTendEditabile('','','');

                } else if (newValue && newValue.inputValue) {
                    //console.log("TendinaEditabile:CASO3-");
                    // Create a new value from the user input
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile(newValue.inputValue,props.posizioneTendina,'');
                    else props.callbackSetValoreTendEditabile(newValue.inputValue,'','');
                } else {
                    //console.log("TendinaEditabile:CASO4-");
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile(newValue.valore,props.posizioneTendina,newValue.valoreNonVisibile);
                    else props.callbackSetValoreTendEditabile(newValue.valore,'',newValue.valoreNonVisibile);
                }
            }}
            
            onInputChange={(event, newValue) => {
                setPerRerendering(newValue)
                if (typeof newValue === 'string') {
                    //console.log("TendinaEditabile:CASO1");
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile(newValue,props.posizioneTendina,'');
                    else props.callbackSetValoreTendEditabile(newValue,'','');
                } else if (newValue === null ) {
                    //console.log("TendinaEditabile:CASO2");
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile('',props.posizioneTendina,'');
                    else props.callbackSetValoreTendEditabile('','','');

                } else if (newValue && newValue.inputValue) {
                    //console.log("TendinaEditabile:CASO3");
                    // Create a new value from the user input
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile(newValue.inputValue,props.posizioneTendina,'');
                    else props.callbackSetValoreTendEditabile(newValue.inputValue,'','');
                } else {
                    //console.log("TendinaEditabile:CASO4");
                    if(props.valoreDefault !== newValue) props.callbackSetVariazioneCampi(1);
                    if(props.posizioneTendina !== undefined) props.callbackSetValoreTendEditabile(newValue.valore,props.posizioneTendina,newValue.valoreNonVisibile);
                    else props.callbackSetValoreTendEditabile(newValue.valore,'',newValue.valoreNonVisibile);
                }                
                
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                //console.log("filtro su:"+params.inputValue.toUpperCase())
                var indice = valoriPossibili.findIndex(elemento => elemento.valore.toUpperCase() === params.inputValue.toUpperCase());
//console.log("ho:"+params.inputValue)
//console.log("e:"+valuePrec)
                if (params.inputValue !== '' && params.inputValue !== valuePrec) {
                    setValuePrec(params.inputValue);
//console.log("TendinaEditabile filter:CASO 5 per"+params.inputValue);
                    if(indice === -1){
                        console.log("TendinaEditabile:CASO 5a ("+params.inputValue+")");
                        //non ho trovato elemento -> l'id non c'è
                        if(props.bloccoInserimento===1){
                            //console.log("TendinaEditabile filter:CASO 5a1 ("+params.inputValue+")");
//console.log("TendinaEditabile:Tendina passa valore"+params.inputValue+" posizione "+props.posizioneTendina)
                            props.callbackSetValoreTendEditabile(params.inputValue,props.posizioneTendina,-1); 
                        }else{
                            //console.log("TendinaEditabile filter:CASO 5a2 ("+params.inputValue+")");
                            props.callbackSetVariazioneCampi(1);
//console.log("TendinaEditabile:CASO 5 valore "+params.inputValue+" posizione"+props.posizioneTendina);
                            const v = "Aggiungi "+params.inputValue;
                            filtered.push({inputValue: params.inputValue, valore: v });
                            props.callbackSetValoreTendEditabile(params.inputValue,props.posizioneTendina,-1); 
                        }
                    }else{
//console.log("TendinaEditabile filter:CASO 6");
                        //ho trovato elemento scritto tra quelli in tendina:
                        props.callbackSetValoreTendEditabile(params.inputValue,props.posizioneTendina,params.valoreNonVisibile);   
                    }
                    
                }
                return filtered;
            }}
            selectOnFocus
            
            handleHomeEndKeys
            //disableClearable con questa props non fai comparire la x che fà clear del campo
            id="free-solo-with-text-demo"
            options={valoriPossibili}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                return option.inputValue;
                }
                // Regular option
                return option.valore;
            }}
            renderOption={(option) => option.valore}
            size="small"//per ridurre dimensioni
            //style={{width: 300}}//in origine: style={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
                <TextField  {...params} label={props.titolo} variant="outlined" />
            )}
        />                                 

            

</Fragment>
        );
}
export default TendinaEditabile;
