import React, {Fragment,useState,useEffect,Suspense,useRef} from 'react';
import { Table,
  Card, CardBody,
  CardTitle,Button,Row, Col,FormGroup,Label,Input} from 'reactstrap';

import Parametri from '../../parametri';
import TabellaRicerca from '../tabellaricerca.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js'

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import moment from 'moment';//npm install moment

import { calcolaAnnoOggi } from '../../funzioni.js';

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 5;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;

const STATO_SCARICA_PDF = 7;
const STATO_PREPARA_PDF = 8;


function TabellaFattureElettroniche(props) {
    
    const operazione = "elencoFattureElettroniche";

    const coloreNuovaRigaPari="#FFFF60";
    const coloreNuovaRigaDispari="#F0F060";
    const coloreErroreRigaPari="#FFB6C1";
    const coloreErroreRigaDispari="#FFB6C1";
  
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');
    
    const  dataPartenza = useRef();

    var today = new Date();
    
    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);

    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);

    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;
    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    const [necessitaRicalcolo,setNecessitaRicalcolo]=useState(0);

    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');

    const [dataInizioPeriodoDefault,setDataInizioPeriodoDefault]=useState(dataOggi);
    const dataOggiPiuUnAnno = moment(dataOggi).add(1, "years").format("YYYY-MM-DD");
    const [dataFinePeriodoDefault,setDataFInePeriodoDefault]=useState(dataOggiPiuUnAnno);

    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);
    
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: '',
        accessor: 'STATO_XML'
      },
      {
        etichetta: '',
        accessor: 'DOWNLOAD_XML'
      },
      {
        etichetta: 'Numero Fattura',
        accessor: 'NUMERO_FATTURA'
      },
      {
        etichetta: 'Data',
        accessor: 'DATA_PAGAMENTO'
      },
      {
        etichetta: 'Importo',
        accessor: 'IMPORTO'
      },
      {
        etichetta: 'Descrizione',
        accessor: 'DESCRIZIONE'
      }
      
    ];
    const arrayTipiDati=[];
    arrayTipiDati[4]="data";
    arrayTipiDati[5]="importo";


    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //resetto tutte le var di stato:
      if(necessitaRicalcolo===1){
        setRisultatoServer('');
        setStatoPagina(STATO_RICERCA_DATI); 
        setNecessitaRicalcolo(0);   
      }
    },[necessitaRicalcolo])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,
            emak: props.emak,
            dataPartenza:dataPartenzaDefault,
            dataInizioPeriodo:dataInizioPeriodoDefault,
            dataFinePeriodo:dataFinePeriodoDefault,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                setInizioPeriodo(valoreDiRitorno.periodo[0].DATA_INIZIO_PERIODO);            
                setFinePeriodo(valoreDiRitorno.periodo[0].DATA_FINE_PERIODO);
                setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])    

    function scaricaNuove() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"scaricaNuoveFattureElettroniche",
          emak: props.emak,
          sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
          (valoreDiRitorno) => {
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
              alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
            }
            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
            if(valoreDiRitorno.risultatoOperazione===1){
              // decodifica ZIP da base64
              const decodedZip = atob(valoreDiRitorno.risultatoRitorno.zip);

              const byteNumbers = new Array(decodedZip.length);
              for (let i = 0; i < decodedZip.length; i++) {
                byteNumbers[i] = decodedZip.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);

              // crea un Blob dal contenuto decodificato
              const blob = new Blob([byteArray], { type: 'application/zip' });
          
              // crea un URL temporaneo per il Blob
              const url = window.URL.createObjectURL(blob);
          
              // crea un link per forzare il download
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', "fatture.zip"); // Imposta il nome del file
          
              // simula il click sul link per avviare il download
              document.body.appendChild(link);
              link.click();
          
              // rimuovi il link e libera l'URL creato
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);

              // ricarica i dati in tabella
              setStatoPagina(STATO_RICERCA_DATI);
            }
          },
        (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
    }

    function dettaglioFattura(id) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({op:"scaricaSingolaFatturaElettronica",
          id: id,
          emak: props.emak,
          sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
          (valoreDiRitorno) => {
            if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
              alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
            }
            if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
            if(valoreDiRitorno.risultatoOperazione===1) {
              if (valoreDiRitorno.risultatoRitorno["errore"]!='') alert("Fattura elettronica non generata per errore: " + valoreDiRitorno.risultatoRitorno["errore"]);
            }
          },
        (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );
    }

    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
  }
  
  return (
      <div>

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_TabFattEle</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?
        <div style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>

        {flagModaleInfoPopolamento=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Fatture Elettroniche"
                        flagErrore={false}
                        contenuto={<div>Qui sono elencate tutte le fatture elettroniche prodotte. Con il bottone Scarica Nuove è possibile scaricare le fatture per inviarle allo SDI attraverso l'interfaccia dell'Agenzia delle Entrate o attraverso altro software di comunicazione.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}



          <CardBody>
            <CardTitle>Fatture Elettroniche&nbsp;
              <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} value={parseInt(dataPartenzaDefault)} onChange={(e)=>{setDataPartenzaDefault(e.target.value);setNecessitaRicalcolo(1);}}>
                {[...Array(11)].map((elemento, indice) => (
                  <option key={indice} value={annoOggi + 1 - indice}>
                    {annoOggi + 1 - indice}
                  </option>
                ))}                              
              </select> 
              &nbsp;dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
            </CardTitle>

            {/*<Button color="success"  onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo Iscritto</Button>*/}
            <Button color="success" onClick={() => {scaricaNuove()}}>Scarica Nuove</Button>            
            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>

            &nbsp;&nbsp;Legenda:
            <span style={{whiteSpace:"pre",marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Scaricata</span>
            <span style={{whiteSpace:"pre",backgroundColor:coloreNuovaRigaDispari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Nuova</span>
            <span style={{whiteSpace:"pre",backgroundColor:coloreErroreRigaDispari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Errore</span>            
            <div style={{marginTop:10}}>Clicca su una fattura rossa per avere la descrizione dell'errore che ne ha impedito la generazione. Per cancellare una fattura, eliminare il movimento che l'ha generata (in Prima Nota, in Incassi, ...)</div>
          </CardBody>
          
          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca 
              columns={colonne} 
              data={risultatoServer} 
              arrayTipiDati={arrayTipiDati} 
              campoOrdinamento={4} 
              campoChiave={0} 
              nascondiColonne={[0,1,2]} 
              coloriPersonalizzati={[
                {"campo":"2","condizione":"==","valore":"0","carattereRigaPari":"#000000","sfondoRigaPari":coloreNuovaRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreNuovaRigaDispari},
                {"campo":"1","condizione":"==","valore":"1","carattereRigaPari":"#000000","sfondoRigaPari":coloreErroreRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreErroreRigaDispari},
              ]}
              callbackSelezionaElemento={dettaglioFattura}               
            />
          : 
            <Table responsive bordered hover striped className="mb-0">
            <thead>
              <tr><th>Nessun dato presente</th></tr>
            </thead>
            </Table>
        }
        </div>
        </div>
      : ""}

      {statoPagina === STATO_INDEFINITO ?
        <Fragment>
          Errore Tabella Fatture Elettroniche, contattare l'assistenza tecnica
        </Fragment>
      :""}
    </div>
   );
}
export default TabellaFattureElettroniche;